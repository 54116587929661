var adminFactory = function($rootScope, $q, httpProvider, localStorageService) {
    //Fields
    var factoryModel = {};
    //Fields - End

    //Function
    factoryModel.isUserAdmin = function(userId) {
      var params = "userId=" + userId;
      return httpProvider.get('admin','isUserAdmin', params);
    };

    factoryModel.getAllAccounts = function() {
      return httpProvider.get('admin','getAllAccounts');
    };

    factoryModel.getAccountDetails = function(accountId) {
      var params = "accountId=" + accountId;
      return httpProvider.get('admin','getAccountDetails', params);
    };

    factoryModel.switchToAccount = function(userId, accountId) {
      var params = "userId=" + userId;
      params += "&accountId=" + accountId;
      return httpProvider.get('admin','switchToAccount', params);
    };

    factoryModel.editAccountSubscription = function(subscription) {
      return httpProvider.post('admin', 'editAccountSubscription', subscription);
    };

    factoryModel.newAccountSubscription = function(subscription) {
      return httpProvider.post('admin', 'newAccountSubscription', subscription);
    };

    factoryModel.getAccountSubscriptions = function(accountId) {
      var params = "accountId=" + accountId;
      return httpProvider.get('admin','getAccountSubscriptions', params);
    };

    factoryModel.getTemplatesByAccountId = function (accountId) {
        var params = "accountId=" + accountId;
        return httpProvider.get('admin', 'getTemplatesByAccountId', params)
    };

    factoryModel.moveTemplate = function(moveSettings) {
      return httpProvider.post('admin', 'moveTemplate', moveSettings);
    };

    factoryModel.getUsersByAccountId = function (accountId) {
        var params = "accountId=" + accountId;
        return httpProvider.get('admin', 'getUsersByAccountId', params)
    };

    factoryModel.getTemplatesByFolderId = function (folderId) {
        var params = "folderId=" + folderId;
        return httpProvider.get('template', 'getTemplatesByFolderId', params);
    };
    //Functions End


    return factoryModel;
};

module.exports = function(app) {
    app.factory('adminFactory', ['$rootScope', '$q', 'httpProvider', 'localStorageService', adminFactory]);
};
