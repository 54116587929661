var shareArchiveFolderWithEmailCtrl = function ($scope, $timeout, $translate, $modalInstance, localStorageService, emailListService, archiveFactory, shareFactory, _, customArchiveId, archiveFiles) {
    //Fields 
    $scope.currentStep = 1;
    $scope.emptyEmailGroupOption = $translate.instant("CUSTOMARCHIVES.YOURARCHIVES.MODALS.SHAREARCHIVEFOLDERWITHEMAIL.SLST_EMAIL_GROUPS.EMPTY_OPTION.TEXT");

    $scope.shareButton = "CUSTOMARCHIVES.YOURARCHIVES.MODALS.SHAREARCHIVEFOLDERWITHEMAIL.BTN_SHARE.TEXT";
    $scope.isProcessing = false;
    //Fields End

    //Functions
    $scope.cancel = function () {
        $modalInstance.dismiss();
    };

    $scope.goToStep2 = function () {
        $scope.errors = [];

        if (($scope.sharingModel.selectedEmailGroup === null || angular.isUndefined($scope.sharingModel.selectedEmailGroup)|| $scope.sharingModel.selectedEmailGroup === $scope.emptyEmailGroupOption) && ($scope.sharingModel.customEmails === null || angular.isUndefined($scope.sharingModel.customEmails) || $scope.sharingModel.customEmails === "")) {
            $scope.errors.push("CUSTOMARCHIVES.YOURARCHIVES.MODALS.SHAREARCHIVEFOLDERWITHEMAIL.ERROR_MESSAGES.ADD_ONE_EMAIL");
        }

        if ($scope.sharingModel.selectedFields !== null) {
            var hasSelectedField = false;
            angular.forEach($scope.sharingModel.selectedFields, function (value, key) {
                if (value)
                    hasSelectedField = true;

            });

            if (hasSelectedField === false) {
                $scope.errors.push("CUSTOMARCHIVES.YOURARCHIVES.MODALS.SHAREARCHIVEFOLDERWITHEMAIL.ERROR_MESSAGES.SELECT_FIELD");
            }
        }

        if ($scope.errors.length > 0) {
            return;
        }

        $scope.sharingModel.archiveFileNameField = $scope.sharingModel.fields[0].name;
        $scope.currentStep = 2;
    };

    $scope.backToStep1 = function () {
        $scope.currentStep = 1;
    };

    $scope.goToStep3 = function () {
        $scope.currentStep = 3;
    };

    $scope.backToStep2 = function () {
        $scope.currentStep = 2;
    };

    $scope.getArchiveFileName = function (archiveFile) {
        if (angular.isDefined($scope.sharingModel.archiveFileNameField))
            return _.find(archiveFile.fields, {
                "text": $scope.sharingModel.archiveFileNameField
            }).value;
    };

    $scope.goToStep4 = function () {
        $scope.currentStep = 4;
    };

    $scope.backToStep3 = function () {
        $scope.currentStep = 3;
    };

    $scope.share = function (isValid) {
        if (isValid) {
            $scope.shareButton = "CUSTOMARCHIVES.YOURARCHIVES.MODALS.SHAREARCHIVEFOLDERWITHEMAIL.BTN_SHARE.ALTERNATE_TEXT";
            $scope.isProcessing = true;

            var requestModel = {};
            requestModel.subject = $scope.sharingModel.subject;
            requestModel.message = $scope.sharingModel.message;
            requestModel.archiveFiles = $scope.sharingModel.archiveFiles;
            requestModel.selectedFields = [];
            angular.forEach($scope.sharingModel.selectedFields, function (value, key) {
                if (value) {
                    requestModel.selectedFields.push(key);
                }
            });
            requestModel.emailGroup = $scope.sharingModel.selectedEmailGroup;
            requestModel.customEmails = $scope.sharingModel.customEmails;

            shareFactory.shareArchiveFolderWithEmail(requestModel)
                .then(function (response) {
                    $scope.shareButton = "CUSTOMARCHIVES.YOURARCHIVES.MODALS.SHAREARCHIVEFOLDERWITHEMAIL.BTN_SHARE.TEXT";
                    $scope.isProcessing = false;

                    var message = $translate.instant("CUSTOMARCHIVES.YOURARCHIVES.MODALS.SHAREARCHIVEFOLDERWITHEMAIL.SUCCESS_MESSAGES.SUCCESSFUL_SHARE");
                    toastr.success(message, '', { positionClass: 'toast-bottom-full-width', timeOut: 3000 });

                    $timeout(function () {
                        $modalInstance.dismiss('cancel');
                    }, 500);


                });
        }
    }

    //Functions End

    //Inner Functions
    var initialize = function () {
        initializeSharingModel(function () {
            getCustomArchiveById(customArchiveId)
            getAllEmailTags();
        });
    };

    var initializeSharingModel = function (nextProcess) {
        $scope.sharingModel = {};
        $scope.sharingModel.selectedFields = {};
        $scope.sharingModel.archiveFiles = archiveFiles;
        $scope.sharingModel.subject = $translate.instant("CUSTOMARCHIVES.YOURARCHIVES.MODALS.SHAREARCHIVEFOLDERWITHEMAIL.SHAREEMAIL.SUBJECT");
        $scope.sharingModel.message = $translate.instant('CUSTOMARCHIVES.YOURARCHIVES.MODALS.SHAREARCHIVEFOLDERWITHEMAIL.SHAREEMAIL.MESSAGE');

        var userInformationData = localStorageService.get('userInformationData');
        if (userInformationData) {
            $scope.sharingModel.userFullName = userInformationData.FirstName + ' ' + userInformationData.LastName;
            $scope.sharingModel.message = $scope.sharingModel.message.replace("$UserName$", $scope.sharingModel.userFullName);
        }

        nextProcess();
    };

    var getAllEmailTags = function () {
        $scope.sharingModel.emailGroups = [];
        emailListService.getAllEmailTags()
            .then(function (response) {
                //Empty Choice
                $scope.sharingModel.emailGroups.push($scope.emptyEmailGroupOption);

                angular.forEach(response, function (value, key) {
                    this.push('' + value + '');
                }, $scope.sharingModel.emailGroups);

                $scope.sharingModel.selectedEmailGroup = $scope.emptyEmailGroupOption;
            });
    };

    var getCustomArchiveById = function (customArchiveId) {
        archiveFactory.getCustomArchiveById(customArchiveId)
            .then(function (response) {
                $scope.sharingModel.fields = response.fields;

                if ($scope.sharingModel.fields.length == 1) {
                    var firstField = $scope.sharingModel.fields[0];
                    $scope.sharingModel.selectedFields[firstField.name] = true;
                }
            });
    }
    //Inner Functions End

    //Initials 
    initialize();
    //Initials End
};

module.exports = function (app) {
    app.controller('shareArchiveFolderWithEmailCtrl', ['$scope', '$timeout', '$translate', '$modalInstance', 'localStorageService', 'emailListService', 'archiveFactory', 'shareFactory', '_', 'customArchiveId', 'archiveFiles', shareArchiveFolderWithEmailCtrl]);
};