var selectTemplateCtrl = function ($rootScope, $scope, $timeout, $translate, $modalInstance, folderFactory, templateFactory, checkPermissionFactory) {
    //Fields
    $scope.selectButton = "Select";
    $scope.isProcessing = false;

    $scope.translatedArchive = $translate.instant("TEMPLATE.NEWTEMPLATE.MODALS.SELECTTEMPLATE.LBL_ARCHIVE.TEXT");

    $scope.selectedFolder = {};

    $scope.selectedTemplate = {};
    $scope.loaded = {
        template: {
            folders: false,
            templates: false
        }
    };
    //Fields End

    //Watches
    $scope.$watch('selectedFolder.id', function () {
        if (angular.isDefined($scope.selectedFolder.id) && $scope.selectedFolder.id != "") {
            var selectedFolderId = $scope.selectedFolder.id;
            getTemplatesByFolderId(selectedFolderId, function () {
                $timeout(function () {
                    $rootScope.$broadcast('reRenderNiceScroll', {
                        "id": "templates"
                    });
                }, 100);
            });
        }
    });
    //Watches End

    //Inner Functions
    var getTemplateFolders = function (nextProcess) {
        $scope.loaded.template.folders = false;
        folderFactory.getTemplateFolders()
            .then(function (response) {

                $scope.templateFolders = response;
                for (var i = 0; i < $scope.templateFolders.length; i++) {
                    if ($scope.templateFolders[i].Parent == "#") {
                        $scope.templateFolders[i].Name = $scope.translatedArchive;
                        $scope.rootFolder = $scope.templateFolders[i];
                        break;
                    }
                }

                for (var i = 0; i < $scope.templateFolders.length; i++) {
                    var templateFolder = $scope.templateFolders[i];
                    templateFolder.id = templateFolder._id;
                    templateFolder.text = templateFolder.Name;
                    templateFolder.parent = templateFolder.Parent;
                }


                $scope.loaded.template.folders = true;
                nextProcess();
            });
    };

    var getTemplatesByFolderId = function (selectedFolderId, nextProcess) {
        $scope.loaded.template.templates = false;
        templateFactory.getTemplatesByFolderId(selectedFolderId)
            .then(function (response) {
                var templates = checkPermissionFactory.canSelectTemplateForCopy(response);
                $scope.templates = templates;

                $scope.loaded.template.templates = true;
                nextProcess();
            });
    };
    //Inner Functions End

    //Functions
    $scope.cancel = function () {
        $modalInstance.dismiss();
    };

    $scope.jsTreeOpenedNode = function () {
        //Rerender Nice scroll
        $rootScope.$broadcast('reRenderNiceScroll', {
            "id": "folders"
        });
    };

    $scope.jsTreeClosedNode = function () {
        //Rerender Nice scroll
        $rootScope.$broadcast('reRenderNiceScroll', {
            "id": "folders"
        });
    };

    $scope.selectTemplate = function (template) {
        $scope.selectedTemplate = template;
    };

    $scope.selectTemplateAndClose = function (template) {
        $scope.selectTemplate(template);
        $scope.select();
    };

    $scope.select = function () {
        $modalInstance.close({
            status: true,
            selectedTemplate: $scope.selectedTemplate
        });
    };
    //Functions End

    //Initials
    getTemplateFolders(function () {
        $scope.folders = $scope.templateFolders;
    });
    //Initials End
};

module.exports = function (app) {
    app.controller('selectTemplateCtrl', ['$rootScope', '$scope', '$timeout', '$translate', '$modalInstance', 'folderFactory', 'templateFactory', 'checkPermissionFactory', selectTemplateCtrl]);
};
