var slimscroll = function($window){

    return{
        restrict: 'A',
        link: function (scope, el, attrs) {
            var width = attrs.ssWidth;
            var height = attrs.ssHeight;
            var alwaysVisible = false;
            var railVisible = false;
            var color = "#000000";
            var opacity = .4;
            var size = "7px";

            if(angular.isDefined(attrs.ssAlwaysVisible))
                alwaysVisible = attrs.ssAlwaysVisible;

            if(angular.isDefined(attrs.ssRailVisible))
                railVisible = attrs.ssRailVisible;


            if(angular.isDefined(attrs.ssColor))
                color = attrs.ssColor;

            if(angular.isDefined(attrs.ssOpacity))
                opacity = attrs.ssOpacity;

            if(angular.isDefined(attrs.ssSize))
                size = attrs.ssSize;



            var setSlimScroll = function(){

                if(angular.isDefined(attrs.ssHeightDifferance)){
                    var heightDifferance = attrs.ssHeightDifferance;
                    height = window.innerHeight - heightDifferance;
                }
                $(el).slimscroll({
                    width : width,
                    height : height,
                    color: color,
                    opacity : opacity,
                    alwaysVisible : alwaysVisible,
                    railVisible: railVisible,
                    size : size,
                    wrapperClass: 'slimScrollDivPriority'
                });

                //Slimcscroll hazır oluyor ama tam sayfa yüklenmediğinden scroll bar mouse üstüne gelmeden görünmüyor
                //o yüzden 2sn sonra tekrar slimscroll yapıyor. Sadece 2sn sonra tekrar yapsam üstekini yapmazsamda
                //bu seferde browser'ın normal scrollu önce görünüyor çirkin oluyor
                setTimeout(function(){
                  $(el).slimscroll({
                      width : width,
                      height : height,
                      color: color,
                      opacity : opacity,
                      alwaysVisible : alwaysVisible,
                      railVisible: railVisible,
                      size : size,
                      wrapperClass: 'slimScrollDivPriority'
                  });
                }, 2000);
            };


            function onResize(){
                setSlimScroll();

                if(!scope.$$phase)
                    scope.$digest();
            };

            function cleanUp() {
                angular.element($window).off('resize', onResize);
            }

            angular.element($window).on('resize', onResize);
            scope.$on('$destroy', cleanUp);

            setSlimScroll();
        }
    }
};

module.exports = function(app){
    app.directive('slimscroll', ['$window', slimscroll]);
};
