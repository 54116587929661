require("./../../../../bower_components/angular/angular.js");
require('angular-ui-router');
require('angular-translate');
require('angular-local-storage');


require('../core/');


//Module
var authenticationModule = angular.module('tdsApp.authentication', [
    'tdsApp.core',
    'ui.router',
    'pascalprecht.translate'
]);

//Run
require('./run.js')(authenticationModule);

//Configs

//Constants

//Router
require('./routing.js')(authenticationModule);

//Factories
require('./shared/factories/authFactory.js')(authenticationModule);

//Controllers
require("./signIn/controllers/signInController.js")(authenticationModule);
require("./resetPassword/controllers/resetPasswordController.js")(authenticationModule);
require("./signUp/controllers/signUpController.js")(authenticationModule);
require("./validate/controllers/validateController.js")(authenticationModule);
require("./validateChangedEmail/controllers/validateChangedEmailController.js")(authenticationModule);
require("./lastStep/controllers/lastStepController.js")(authenticationModule);
require("./confirmInvitation/controllers/confirmInvitationController.js")(authenticationModule);


module.exports = authenticationModule;
