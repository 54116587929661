var uiRooter = function($stateProvider, $urlRouterProvider, $locationProvider) {
  $stateProvider.state('app.searchResults', {
    url: '/search',
    templateUrl: 'app/components/search/searchResults/views/index.html',
    controller: "searchCtrl",
    onExit: ['$rootScope', function($rootScope) {
        $rootScope.searchModel.headerSearch = true;
    }]
  });

  /* removeIfProduction */
  // $urlRouterProvider.otherwise("/en/app/search");
  /* endRemoveIfProduction */
};

module.exports = function(app){
  app.config(['$stateProvider', '$urlRouterProvider', '$locationProvider', uiRooter]);
};
