var moment = require("./../../../../../../../bower_components/moment/moment.js");

var pdfViewsCtrl = function($rootScope, $scope, $state, $translate, $filter, $timeout, analyticFactory, _) {
    //Fields
    var months = [];
    var labelList = [];

    var monthNames = ["JANUARY", "FEBURARY", "MARCH", "APRIL", "MAY", "JUNE",
        "JULY", "AUGUST", "SEPTEMBER", "OCTOBER", "NOVEMBER", "DECEMBER"
    ];
    $scope.options = {
        onResize: function() {
            $rootScope.$broadcast('pdfViewsChartResized', {});
        },
        legend: {
            display: true
        },
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true,
                    stepSize: 10
                }
            }],
            xAxes : [{
              stacked: false
            }]
        }
    };
    //Fields End

    //Events
    //Events End

    //Watches
    //Watches End

    //Inner Functions
    var initialize2 = function(){
      $scope.chartData = {};

      var lblApplication = $translate.instant("DASHBOARD.DASHBOARD.PARTIALS.PDFVIEWSCHART.LBL_APPLICATION.TEXT");
      var lblApplicationColor = "#3db9dc";

      var lblDirect = $translate.instant("DASHBOARD.DASHBOARD.PARTIALS.PDFVIEWSCHART.LBL_DIRECT.TEXT");
      var lblDirectColor = "#1bb99a";

      var lblMobile = $translate.instant("DASHBOARD.DASHBOARD.PARTIALS.PDFVIEWSCHART.LBL_MOBILE.TEXT");
      var lblMobileColor = "#595959";

      var lblEmail = $translate.instant("DASHBOARD.DASHBOARD.PARTIALS.PDFVIEWSCHART.LBL_EMAIL.TEXT");
      var lblEmailColor = "#B91B6F";

      var lblWebPage = $translate.instant("DASHBOARD.DASHBOARD.PARTIALS.PDFVIEWSCHART.LBL_WEB_PAGE.TEXT");
      var lblWebPageColor = "#B9A71B";

      $scope.chartData.labels = '["' + lblApplication + '","' + lblDirect + '","' + lblMobile + '","' + lblEmail + '","' + lblWebPage + '"]';
      $scope.chartData.colors = '["' + lblApplicationColor + '","' + lblDirectColor + '","' + lblMobileColor + '","' + lblEmailColor + '","' + lblWebPageColor + '"]';
      $scope.chartData.fields = '["application", "direct", "mobile", "email", "webpage"]';
      $scope.chartData.xFields = [];
      $scope.chartData.data = [];


      for (var i = 11; i >= 0; i--) {
          var currentDate = moment().add(-i, 'months');

          var monthName = $translate.instant("DASHBOARD.DASHBOARD.PARTIALS.PDFVIEWSCHART.LBL_" + monthNames[currentDate.month()] + ".TEXT");
          var year = parseInt(currentDate.year()) - 2000;

          $scope.chartData.xFields.push({
              year: year,
              name: monthName,
              label : monthName + "," + year,
              month: currentDate.month() + 1
          });
      }

      // { y: "2009", a: 75,  b: 65, c: 30 },
      analyticFactory.getPDFViewsData()
          .then(function(response) {

            var webAppData = 0;
            var mobileData = 0;
            var emailData = 0;
            var directData = 0;
            var webPageData = 0;

            for(var i in $scope.chartData.xFields){
              var currentMonth = $scope.chartData.xFields[i];

              var currentAppDatas = _.filter(response, {
                  DateYear: parseInt(currentMonth.year) + 2000,
                  DateMonth: parseInt(currentMonth.month)
              });

              if (currentAppDatas.length === 0) {
                  webAppData = 0;
                  mobileData = 0;
                  emailData = 0;
                  directData = 0;
                  webPageData = 0;
              } else {
                var webAppCounter = 0;
                var mobileCounter = 0;
                var emailCounter = 0;
                var directCounter = 0;
                var webPageCounter = 0;

                for (var iData = 0; iData < currentAppDatas.length; iData++) {
                    var currentData = currentAppDatas[iData];
                    if (currentData.AnalyticItem.Source == "webApp")
                        webAppCounter = webAppCounter + 1;
                    else if (currentData.AnalyticItem.Source == "direct")
                        directCounter = directCounter + 1;
                    else if (currentData.AnalyticItem.Source == "mobile")
                        mobileCounter = mobileCounter + 1;
                    else if (currentData.AnalyticItem.Source == "email")
                        emailCounter = emailCounter + 1;
                    else if (currentData.AnalyticItem.Source == "webpage")
                        webPageCounter = webPageCounter + 1;
                }

                webAppData = webAppCounter;
                mobileData = mobileCounter;
                emailData = emailCounter;
                directData = directCounter;
                webPageData = webPageCounter;
              }

              var dataItem = {
                month : currentMonth.label,
                application : webAppData,
                direct : directData,
                mobile : mobileData,
                email : emailData,
                webpage : webPageData
              };

              $scope.chartData.data.push(dataItem);
            }

            
      });

    }
    var initialize = function() {
        $scope.data = [];
        $scope.datasets = [];
        $scope.labels = [];

        for (var i = 11; i >= 0; i--) {
            var currentDate = moment().add(-i, 'months');

            var monthName = $translate.instant("DASHBOARD.DASHBOARD.PARTIALS.PDFVIEWSCHART.LBL_" + monthNames[currentDate.month()] + ".TEXT");
            var year = parseInt(currentDate.year()) - 2000;

            labelList.push(
                monthName + "," + year
            );

            months.push({
                year: year,
                name: monthName,
                month: currentDate.month() + 1
            });
        }


        analyticFactory.getPDFViewsData()
            .then(function(response) {

                var webAppData = [];
                var mobileData = [];
                var emailData = [];
                var directData = [];
                var webPageData = [];

                var maxDataSize = 1;

                for (var i = 0; i < months.length; i++) {
                    var currentMonth = months[i];
                    var currentAppDatas = $filter('filter')(response, {
                        DateYear: parseInt(currentMonth.year) + 2000,
                        DateMonth: parseInt(currentMonth.month)
                    }, true);

                    if (currentAppDatas.length === 0) {
                        webAppData.push(0);
                        mobileData.push(0);
                        emailData.push(0);
                        directData.push(0);
                        webPageData.push(0);
                    } else {
                        var webAppCounter = 0;
                        var mobileCounter = 0;
                        var emailCounter = 0;
                        var directCounter = 0;
                        var webPageCounter = 0;

                        for (var iData = 0; iData < currentAppDatas.length; iData++) {
                            var currentData = currentAppDatas[iData];
                            if (currentData.AnalyticItem.Source == "webApp")
                                webAppCounter = webAppCounter + 1;
                            else if (currentData.AnalyticItem.Source == "direct")
                                directCounter = directCounter + 1;
                            else if (currentData.AnalyticItem.Source == "mobile")
                                mobileCounter = mobileCounter + 1;
                            else if (currentData.AnalyticItem.Source == "email")
                                emailCounter = emailCounter + 1;
                            else if (currentData.AnalyticItem.Source == "webpage")
                                webPageCounter = webPageCounter + 1;
                        }

                        webAppData.push(webAppCounter);
                        mobileData.push(mobileCounter);
                        emailData.push(emailCounter);
                        directData.push(directCounter);
                        webPageData.push(webPageCounter);

                    }
                }

                $scope.viewerHeight = 250;
                var lblApplication = $translate.instant("DASHBOARD.DASHBOARD.PARTIALS.PDFVIEWSCHART.LBL_APPLICATION.TEXT");
                var lblDirect = $translate.instant("DASHBOARD.DASHBOARD.PARTIALS.PDFVIEWSCHART.LBL_DIRECT.TEXT");
                var lblMobile = $translate.instant("DASHBOARD.DASHBOARD.PARTIALS.PDFVIEWSCHART.LBL_MOBILE.TEXT");
                var lblEmail = $translate.instant("DASHBOARD.DASHBOARD.PARTIALS.PDFVIEWSCHART.LBL_EMAIL.TEXT");
                var lblWebPage = $translate.instant("DASHBOARD.DASHBOARD.PARTIALS.PDFVIEWSCHART.LBL_WEB_PAGE.TEXT");
                $scope.datasets = [{
                    label: lblApplication,
                    backgroundColor: 'rgba(61,185,220,0)',
                    borderColor: 'rgba(61,185,220,1)',
                    pointBackgroundColor: 'rgba(61,185,220,1)',
                    pointBorderColor: '#fff',
                    pointHighlightFill: '#fff',
                    pointHighlightStroke: 'rgba(61,185,220,1)',
                    radius: 5,
                    hoverRadius: 6,
                    data: webAppData,
                    lineTension: 0
                }, {
                    label: lblDirect,
                    backgroundColor: 'rgba(27,185,154,0)',
                    borderColor: 'rgba(27,185,154,1)',
                    pointBackgroundColor: 'rgba(27,185,154,1)',
                    pointBorderColor: '#fff',
                    pointHighlightFill: '#fff',
                    pointHighlightStroke: 'rgba(27,185,154,1)',
                    radius: 5,
                    hoverRadius: 6,
                    lineTension: 0,
                    data: directData
                }, {
                    label: lblMobile,
                    backgroundColor: 'rgba(241,181,61,0)',
                    borderColor: 'rgba(241,181,61,1)',
                    pointBackgroundColor: 'rgba(241,181,61,1)',
                    pointBorderColor: '#fff',
                    pointHighlightFill: '#fff',
                    pointHighlightStroke: 'rgba(241,181,61,1)',
                    radius: 5,
                    hoverRadius: 6,
                    lineTension: 0,
                    data: mobileData
                }, {
                    label: lblEmail,
                    backgroundColor: 'rgba(255,48,21,0)',
                    borderColor: 'rgba(255,48,21,1)',
                    pointBackgroundColor: 'rgba(255,48,21,1)',
                    pointBorderColor: '#fff',
                    pointHighlightFill: '#fff',
                    pointHighlightStroke: 'rgba(255,48,21,1)',
                    radius: 5,
                    hoverRadius: 6,
                    lineTension: 0,
                    data: emailData
                }, {
                    label: lblWebPage,
                    backgroundColor: 'rgba(146,97,198,0)',
                    borderColor: 'rgba(146,97,198,1)',
                    pointBackgroundColor: 'rgba(146,97,198,1)',
                    pointBorderColor: '#fff',
                    pointHighlightFill: '#fff',
                    pointHighlightStroke: 'rgba(146,97,198,1)',
                    radius: 5,
                    hoverRadius: 6,
                    lineTension: 0,
                    data: webPageData
                }];

                $scope.labels = labelList;

                $scope.data.push(webAppData);
                $scope.data.push(directData);
                $scope.data.push(mobileData);
                $scope.data.push(emailData);
                $scope.data.push(webPageData);

                function getMaxOfArray(numArray) {
                    return Math.max.apply(null, numArray);
                }

                maxDataSize = getMaxOfArray([
                    getMaxOfArray(webAppData),
                    getMaxOfArray(mobileData), 
                    getMaxOfArray(directData), 
                    getMaxOfArray(emailData), 
                    getMaxOfArray(webPageData)
                ]);

                if (maxDataSize > 10) {
                    $scope.options.scales.yAxes[0].ticks.stepSize = parseInt(maxDataSize / 10);
                } else {
                    $scope.options.scales.yAxes[0].ticks.stepSize = 1;
                }
            });
    };
    //Inner Functions End

    //Functions
    //Functions End

    //Initials
    initialize();
    // initialize2();

    $(window).resize(function() {
      // initialize2();
    });
    //Initials End
};

module.exports = function(app) {
    app.controller('pdfViewsCtrl', ['$rootScope', '$scope', '$state', '$translate', '$filter', '$timeout', 'analyticFactory', '_', pdfViewsCtrl]);
};
