var newPermissionLevelCtrl = function($scope, $state, $filter, $timeout, _, menuToggleFactory, permissionFactory) {
    //Fields
    $scope.saveButton = "PERMISSION.NEWPERMISSIONLEVEL.BTN_SAVE.TEXT";
    $scope.isProcessing = false;
    $scope.permissions = [];
    $scope.permissionLevel = {
        Permissions: []
    };
    $scope.requiredPermissions = [];
    //Fields End

    //Events
    $scope.$on('$viewContentLoaded', function() {
        $timeout(function() {
            menuToggleFactory.collapse();
        }, 100);
    });
    //Events End

    //Inner Functions
    var getAllPermissionGroups = function() {
        permissionFactory.getAllPermissionGroups()
            .then(function(response) {
                $scope.permissionGroups = response;

                _($scope.permissionGroups).forEach(function(permissionGroup) {
                    $scope.permissions = _.unionWith($scope.permissions, permissionGroup.permissions, _.isEqual);
                });
            });
    };
    //Inner Functions End

    //Functions
    $scope.selectPermission = function(permissionGroup, permission) {
        if (permission.isSelected) {
            if (angular.isDefined(permission.RequiredPermissions) && permission.RequiredPermissions.length > 0) {
                for (var i = 0; i < permission.RequiredPermissions.length; i++) {
                    var requiredPermission = permission.RequiredPermissions[i];
                    var foundPermission = $filter('filter')($scope.permissions, {
                        _id: requiredPermission
                    })[0];

                    if (angular.isUndefined(foundPermission.isSelected) || !foundPermission.isSelected) {
                        foundPermission.isSelected = true;

                        $scope.selectPermission(permissionGroup, foundPermission);
                    }
                }
            }

            $scope.permissionLevel.Permissions.push(permission._id);

        } else {

            for (var i = 0; i < $scope.permissionLevel.Permissions.length; i++) {
                var permissionLevelPermission = $scope.permissionLevel.Permissions[i];
                if (permissionLevelPermission == permission._id) {
                    $scope.permissionLevel.Permissions.splice(i, 1);
                    break;
                }
            }
        }

        permission.isNeedToBeSelected = false;

    };

    $scope.submitted = false;
    $scope.savePermissionLevel = function(isValid, permissionLevel) {
        $scope.saveButton = "PERMISSION.NEWPERMISSIONLEVEL.BTN_SAVE.ALTERNATE_TEXT";
        $scope.isProcessing = true;

        $scope.errors = [];
        $scope.submitted = true;

        //Permission seçilmemişse,
        if ($scope.permissionLevel.Permissions.length == 0) {
            $scope.errors.push('PERMISSION.NEWPERMISSIONLEVEL.ERROR_MESSAGES.SELECT_ONE_PERMISSION');
        }

        //Gerekli permission seçilmemişse,
        var requiredPermissions = [];
        for (var i = 0; i < permissionLevel.Permissions.length; i++) {
            var permission = permissionLevel.Permissions[i];

            for (var j = 0; j < $scope.permissionGroups.length; j++) {
                var permissionGroup = $scope.permissionGroups[j];
                var foundPermission = $filter('filter')($scope.permissions, {
                    _id: permission
                })[0];

                if (angular.isDefined(foundPermission) && foundPermission != null) {
                    angular.forEach(foundPermission.RequiredPermissions, function(value, key) {
                        requiredPermissions.push(value);
                    });
                    break;
                }
            }
        }

        var isNeedToBeSelected = false;
        for (var i = 0; i < requiredPermissions.length; i++) {
            var requiredPermission = requiredPermissions[i];

            for (var j = 0; j < $scope.permissionGroups.length; j++) {
                var permissionGroup = $scope.permissionGroups[j];
                var foundPermission = $filter('filter')($scope.permissions, {
                    _id: requiredPermission
                })[0];

                if (angular.isDefined(foundPermission) && foundPermission != null && !foundPermission.isSelected) {
                    isNeedToBeSelected = true;
                    foundPermission.isNeedToBeSelected = true;
                    break;
                }
            }
        }

        if (isNeedToBeSelected) {
            $scope.errors.push('PERMISSION.NEWPERMISSIONLEVEL.ERROR_MESSAGES.SELECT_REQUIRED_PERMISSIONS');
        }


        if ($scope.errors.length > 0) {
            $scope.saveButton = "PERMISSION.NEWPERMISSIONLEVEL.BTN_SAVE.TEXT";
            $scope.isProcessing = false;

            $timeout(function() {
                $scope.errors = [];
            }, 5000);

            return;
        }

        if (isValid) {
            permissionFactory.saveAccountPermissionLevel(permissionLevel)
                .then(function(response) {
                    $scope.saveButton = "PERMISSION.NEWPERMISSIONLEVEL.BTN_SAVE.ALTERNATE_TEXT";
                    $scope.isProcessing = false;

                    if (response.status) {
                        $state.go('app.allPermissionLevels');
                    };
                });
        }
    };

    $scope.closeAlert = function() {
        $scope.errors = [];
    };
    //Functions End

    //Initials
    getAllPermissionGroups();
    //Initials End
};

module.exports = function(app) {
    app.controller('newPermissionLevelCtrl', ['$scope', '$state', '$filter', '$timeout', '_', 'menuToggleFactory', 'permissionFactory', newPermissionLevelCtrl]);
};
