var addEmailListCtrl = function($scope, $timeout, $modalInstance, mailGroupFactory) {
    //Fields
    $scope.OKButton = "MAILGROUP.ALLEMAILS.MODALS.ADDEMAILLIST.BTN_OK.TEXT";
    $scope.isProccessing = false;

    $scope.newListRequest = {};
    $scope.newListRequest.emails = "";
    $scope.newListRequest.tags = [];

    $scope.errors = [];
    //Fields-End

    //Events
    //Events-End

    //Action Handlers
    $scope.cancel = function() {
        $modalInstance.dismiss('cancel');
    };

    $scope.ok = function(model) {
        $scope.errors = [];

        $scope.OKButton = "MAILGROUP.ALLEMAILS.MODALS.ADDEMAILLIST.BTN_OK.ALTERNATE_TEXT";
        $scope.isProccessing = true;
        $scope.showError = false;

        if (model.tags.length === 0) {
            $scope.errors.push("MAILGROUP.ALLEMAILS.MODALS.ADDEMAILLIST.ERROR_MESSAGES.ADD_ONE_TAG");
        }

        if (model.emails.length === 0) {
            $scope.errors.push("MAILGROUP.ALLEMAILS.MODALS.ADDEMAILLIST.ERROR_MESSAGES.ADD_ONE_EMAIL");
        }

        if ($scope.errors.length > 0) {
            $scope.OKButton = "MAILGROUP.ALLEMAILS.MODALS.ADDEMAILLIST.BTN_OK.TEXT";
            $scope.isProccessing = false;

            $timeout(function() {
                $scope.errors = []
            }, 5000);

            return;
        }

        var newTags = [];
        for (var i = 0; i < model.tags.length; i++) {
            var tag = model.tags[i];

            newTags.push(tag.text);
        }

        model.tags = newTags;

        mailGroupFactory.saveEmailList(model)
            .then(function(response) {
                $scope.OKButton = "MAILGROUP.ALLEMAILS.MODALS.ADDEMAILLIST.BTN_OK.TEXT";
                $scope.isProccessing = false;

                $timeout(function() {
                    $modalInstance.close({
                        status: true
                    });
                }, 500);
            });

    };
    //Action Handlers - End
};

module.exports = function(app) {
    app.controller('addEmailListCtrl', ['$scope', '$timeout', '$modalInstance', 'mailGroupFactory', addEmailListCtrl]);
};
