var mailGroupFactory = function(httpProvider) {
    //Fields
    var factoryModel = {};
    //Fields - End

    //Private Functions
    factoryModel.getAllEmailList = function() {
      return httpProvider.get('emailList','getAllEmailList');
    };

    factoryModel.saveEmailList = function(model) {
      return httpProvider.post('emailList', 'saveEmailList', model);
    };

    factoryModel.updateEmail = function(model) {
      return httpProvider.put('emailList', 'updateEmail', model);
    };

    factoryModel.deleteEmail = function(emailId) {
      return httpProvider.delete('emailList', 'deleteEmail', emailId);
    };
    //Private Functions - End

    return factoryModel;
};

module.exports = function(app){
  app.factory('mailGroupFactory', ['httpProvider', mailGroupFactory]);
};
