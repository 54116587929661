var uiDroppable = function(){
  return {
    restrict: 'A',
    scope: {
            droppableDrop: "&"
    },
    link : function(scope, elm, attrs){

      var options = scope.$eval(attrs.droppableOptions);
      $(elm).droppable(options);

      elm.on('drop', function (event, ui) {
          if (attrs.droppableDrop) {
              scope.droppableDrop({ event: event, ui: ui });
          }
      });
    }
  }
};

module.exports = function(app){
  app.directive('uiDroppable', uiDroppable);
};
