require("./../../../../bower_components/angular/angular.js");

//Module
var folderModule = angular.module('tdsApp.folder', []);

//Routers
require('./routing.js')(folderModule);

//Directives
require('./shared/directives/jsTreeDirective.js')(folderModule);

//Factories
require('./shared/factories/folderFactory.js')(folderModule);

//Controllers
require('./folderPermissions/controllers/folderPermissionsController.js')(folderModule);

//Partials Controllers
require('./shared/controllers/_partials/folderOptionsController.js')(folderModule);
require('./shared/controllers/_partials/folderOptionsForSaveController.js')(folderModule);
// require('./shared/controllers/_partials/createNewFolderForSaveController.js')(folderModule);
require('./shared/controllers/_partials/newFolderController.js')(folderModule);
require('./shared/controllers/_partials/renameFolderController.js')(folderModule);

//Modal Controllers
require('./shared/controllers/modals/deleteFolderController.js')(folderModule);
require('./shared/controllers/modals/moveFolderController.js')(folderModule);

module.exports = folderModule;
