var pdfTableRow = function ($compile, pdfViewService, pdfElementService, _) {
    return {
        restrict: 'A',
        link: function (scope, elem, attrs) {
            //Fields 
            var containerElement = elem;
            //Fields 

            //Elements
            // var element = scope.row;
            // var datasheetElement = datasheetElementService.getDatasheetElement(scope.datasheetModel, element);
            //Elements End

            //Inner Functions
            var setElementStyle = function () {
                var newStyle = scope.row.style;
                if (newStyle.borderLeftWidth == "0px") {
                    newStyle.borderLeft = "none";
                }

                if (newStyle.borderTopWidth == "0px") {
                    newStyle.borderTop = "none";
                }

                if (newStyle.borderRightWidth == "0px") {
                    newStyle.borderRight = "none";
                }

                if (newStyle.borderBottomWidth == "0px") {
                    newStyle.borderBottom = "none";
                }

                containerElement.css(newStyle);
            };

            var setCells = function () {
                var rowId = scope.row._id;
                var cells = _.filter(scope.pdfModel.elements, {
                    parentId: rowId,
                    type: "cell"
                });
                cells = _.sortBy(cells, 'order');


                for (var i = 0, len = cells.length; i < len; i++) {
                    var cell = cells[i];
                    var cellHtml = pdfViewService.createPdfElement(cell.type);

                    var childScope = scope.$new();
                    childScope.cell = cell;
                    $(elem).append($compile(cellHtml)(childScope));
                }
            };
            //Inner Functions

            //Handlers
            //Handlers End

            //Initials 
            setElementStyle();
            setCells();
            //Initials End
        }
    };
};

module.exports = function (app) {
    app.directive('pdfTableRow', ['$compile', 'pdfViewService', 'pdfElementService', '_', pdfTableRow]);
};