var cancelSubscriptionCtrl = function ($rootScope, $scope, $state, $filter, $timeout, $translate, accountService, subscriptionFactory, menuToggleFactory, localStorageService) {
    //Fields
    var userInformationData = localStorageService.get('userInformationData');

    $scope.isProcessing = false;
    $scope.loaded = false;

    $scope.cancelSubscriptionModel = {};
    $scope.messages = {
        warning_1: ""
    };

    //Fields End

    //Events
    $scope.$on('$viewContentLoaded', function () {
        $timeout(function () {
            menuToggleFactory.collapse();
        }, 100);
    });
    //Events End

    //Inner Functions
    var init = function () {
        var warning1 = $translate.instant("SUBSCRIPTION.CANCEL_SUBSCRIPTION.LBL_WARNING_1.TEXT");

        subscriptionFactory.getAccountSubscription()
            .then(function (accountSubscription) {
                $scope.accountSubscription = accountSubscription;

                var accountId = $scope.accountSubscription.accountId;
                accountService.getAccount(accountId)
                    .then(function (account) {
                        $scope.account = account;

                        //Freemium pakette ise zaten aboneliğini iptal ettiremez. O yuzden direk accountSubscription sayfasına yönlendiriyoruz. 
                        if ($scope.accountSubscription.package.level == 0) {
                            $state.go("app.accountSubscription", {});
                        }
                        else {
                            var mCurrentDate = moment();
                            var mStartDate = moment(new Date($scope.accountSubscription.startDate));
                            var mEndDate = moment(new Date($scope.accountSubscription.endDate));
                            var mCancelDate;

                            var usedDays = mCurrentDate.diff(mStartDate, 'days', false);
                            var payBackMonth;
                            var isCancelledNow = false;
                            if (usedDays <= 14) {
                                mCancelDate = mCurrentDate;
                                payBackMonth = mEndDate.diff(mStartDate, 'months', true);
                                isCancelledNow = true;
                            }
                            else {
                                mCancelDate = mStartDate.clone().add(1, 'months');
                                payBackMonth = mEndDate.diff(mCancelDate, 'months', true);
                            }


                            var totalPaid = $scope.accountSubscription.totalPaid + $scope.accountSubscription.orderDetails.payBack;
                            var totalMonths = mEndDate.diff(mStartDate, 'months', true);
                            var paidPerMonth = totalPaid / totalMonths;

                            $scope.cancelSubscriptionModel.cancelDate = new Date(mCancelDate);
                            $scope.cancelSubscriptionModel.isCancelledNow = isCancelledNow;



                            $scope.cancelSubscriptionModel.totalMonths = totalMonths;
                            $scope.cancelSubscriptionModel.paidPerMonth = paidPerMonth;
                            $scope.cancelSubscriptionModel.payBackMonth = payBackMonth;
                            $scope.cancelSubscriptionModel.payBackAmount = paidPerMonth * payBackMonth;

                            var fCancelDate = $filter('date')($scope.cancelSubscriptionModel.cancelDate, 'dd.MM.yyyy');
                            $scope.messages.warning_1 = warning1.replace("$cancelDate$", fCancelDate);
                        }
                    });
            });
    };
    //Inner Functions End

    //Functions
    $scope.cancelSubscription = function () {
        $scope.isProcessing = true;

        subscriptionFactory.cancelSubscription($scope.cancelSubscriptionModel)
            .then(function (response) {
                var isCancelled = response.isCancelled;

                var trackMessage = userInformationData.FirstName + " " + userInformationData.LastName +
                    " a subscription downgrade to Freemium" +
                    " on account " + $scope.account.Name;
                var packageName = $scope.accountSubscription.package.name

                // console.log(trackMessage);
                // console.log(packageName);
                // analytics.track(trackMessage, {
                //     packageName: packageName
                // }, {},
                //     function () {
                //         setTimeout(function () {
                //             window.Intercom('update');
                //         }, 1500);
                //     });

                $timeout(function () {
                    $scope.isProcessing = false;
                    $state.go('app.accountSubscription', {
                        lang: $rootScope.activeLang
                    }, {
                            reload: true
                        });
                }, 500);
            });


    };
    //Functions End

    //Initialize
    init();
    //Initialize End

    //For Testing
    $scope._init = init;
    //For Testing End
};

module.exports = function (app) {
    app.controller('cancelSubscriptionCtrl', ['$rootScope', '$scope', '$state', '$filter', '$timeout', '$translate', 'accountService', 'subscriptionFactory', 'menuToggleFactory', 'localStorageService', cancelSubscriptionCtrl]);
};