var uiRouter = function($stateProvider, $urlRouterProvider, $locationProvider) {
    $stateProvider
        .state('app.folderPermissions', {
            url: '/folder-permissions/:folderId',
            templateUrl: 'app/components/folder/folderPermissions/views/folderPermissions.html',
            controller: 'folderPermissionsCtrl'
        });
};

module.exports = function(app) {
    app.config(['$stateProvider', '$urlRouterProvider', '$locationProvider', uiRouter]);
};
