require("./../../../../bower_components/angular/angular.js");

//Module
var integrationModule = angular.module('tdsApp.integration',[]);

//Routers
require('./routing.js')(integrationModule);

//Factories

//Controllers
require('./integrationInfos/controllers/integrationInfosController')(integrationModule);

//Modals

module.exports = integrationModule;
