var viewCustomFileCtrl = function ($rootScope, $scope, $stateParams, localStorageService, archiveFileFactory, config, environment) {
    //Fields
    var mode = environment.MODE || config.mode;
    var customFileId = $stateParams.customFileId;

    var options = {
        pdfOpenParams: {
            navpanes: 1,
            toolbar: 1,
            statusbar: 1,
            view: "FitV",
            pagemode: "thumbs",
            page: 1,
            locale: $rootScope.activeLang
        },
        forcePDFJS: true,
        PDFJS_URL: "/assets/js/pdfjs/web/viewer.html"
    };
    //Fields End

    //Functions
    //Functions End

    //Inner Functions 
    var getArchiveFile = function (callback) {
        archiveFileFactory.getCustomArchiveFileById(customFileId)
            .then(function (archiveFile) {
                console.log(archiveFile);

                callback(archiveFile);
            });

    };
    //Inner Functions End

    //Initials 
    var archiveFile = getArchiveFile(function (archiveFile) {
        archiveFilePdf = config.api[mode].apiUrl + archiveFile.path;
        var myPDF = PDFObject.embed(archiveFilePdf, "#pdf", options);
    });

    //Initials End
};

module.exports = function (app) {
    app.controller('viewCustomFileCtrl', ['$rootScope', '$scope', '$stateParams', 'localStorageService', 'archiveFileFactory', 'config', 'environment', viewCustomFileCtrl]);
};