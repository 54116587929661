var uiRooter = function ($stateProvider, $urlRouterProvider, $locationProvider) {
  $stateProvider
    .state('app.datasheetReport', {
      url: '/datasheet-report',
      templateUrl: "/app/components/reports/datasheetReports/views/datasheetReports.html",
      controller : 'datasheetReportCtrl',
      onEnter: ['menuToggleFactory', function(menuToggleFactory) {
            menuToggleFactory.collapse();
            $('.open-left').hide();
      }],
      onExit: ['menuToggleFactory', function(menuToggleFactory) {
            menuToggleFactory.doPreviousStatus();
            $('.open-left').show();
            $('.footer').show();
      }]
    });

  /* removeIfProduction */
  // $urlRouterProvider.otherwise("/en/pdf/datasheet-preview/57398392d78b17f40d2d3bf5/en");
  /* endRemoveIfProduction */
};

module.exports = function (app) {
  app.config(['$stateProvider', '$urlRouterProvider', '$locationProvider', uiRooter]);
};
