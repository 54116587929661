var datasheetSelectListAddItemCtrl = function ($scope, $modalInstance, generalsService, templateFactory, googleTranslate, _, element) {
    //Fields 
    var languages = void 0;

    $scope.isProcessing = false;

    //Fields End

    //Functions
    $scope.cancel = function () {
        $modalInstance.dismiss();
    };

    $scope.save = function () {
        var request = {
            templateId: element.templateId,
            elementId: element._id,
            items: $scope.items
        };

        templateFactory.addSelectListItemFromDatasheet(request)
            .then(function (response) {
                $modalInstance.close({
                    status: true,
                    newItems: $scope.items
                });

            });
    };

    $scope.translate = function (language, text) {
        $scope.isProcessing = true;
        googleTranslate.translateText(text, language, languages)
            .then(function (response) {
                $scope.isProcessing = false;

                for (var iTranslate = 0; iTranslate < response.TranslateList.length; iTranslate++) {
                    var currentTranslate = response.TranslateList[iTranslate];
                    if (currentTranslate.Language != language) {
                        var relatedItem = _.find($scope.items, {
                            language: currentTranslate.Language
                        });
                        relatedItem.value = currentTranslate.Text;
                    }
                }
            });
    };
    //Functions End

    //Inner Functions
    var init = function () {
        $scope.items = [];

        getTemplateById(element.templateId, function () {
            getPublishedTemplateById(element.templateId, function () {
                var guid = createGUID();
                for (var i = 0, len = languages.length; i < len; i++) {
                    var language = languages[i];

                    $scope.items.push({
                        language: language,
                        id : guid,
                        value: ""
                    });
                }
            });
        });
    };

    var getTemplateById = function (templateId, nextProcess) {
        templateFactory.getTemplate(templateId)
            .then(function (response) {
                languages = response.template.languages;

                nextProcess();
            });
    };

    var getPublishedTemplateById = function (templateId, nextProcess) {
        templateFactory.getPublishedTemplateById(templateId)
            .then(function (response) {
                _.assignIn(languages, languages, response.template.languages);

                nextProcess();
            });
    }

    var createGUID = function () {
        var newId = generalsService.createGUID();
        newId = newId.toUpperCase();
        return newId;
    };
    //Inner Functions End

    //Initials
    init();

    //Initials End
};

module.exports = function (app) {
    app.controller('datasheetSelectListAddItemCtrl', ['$scope', '$modalInstance', 'generalsService', 'templateFactory', 'googleTranslate', '_', 'element', datasheetSelectListAddItemCtrl]);
};