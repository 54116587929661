var uiRouter = function($stateProvider, $urlRouterProvider, $locationProvider) {
    $stateProvider
        .state('admin.dashboard', {
            url: '/dashboard',
            templateUrl: 'app/components/admin/dashboard/views/dashboard.html',
            controller: 'dashboardCtrl'
        })
        .state('admin.accounts', {
            url: '/accounts',
            templateUrl: 'app/components/admin/accounts/views/accounts.html',
            controller: 'accountsCtrl'
        })
        .state('admin.templateTransfer', {
            url: '/template-transfer',
            templateUrl: 'app/components/admin/templateTransfer/views/templateTransfer.html',
            controller: 'templateTransferCtrl'
        })
};

module.exports = function(app) {
  app.config(['$stateProvider','$urlRouterProvider','$locationProvider', uiRouter]);
};
