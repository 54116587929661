var uiRouter = function($stateProvider, $urlRouterProvider, $locationProvider) {
    $stateProvider
        .state('app.integrationInfos', {
            url: '/integration-infos',
            templateUrl: 'app/components/integration/integrationInfos/views/integrationInfos.html',
            controller: "integrationInfosCtrl"
        });
};

module.exports = function(app) {
    app.config(['$stateProvider', '$urlRouterProvider', '$locationProvider', uiRouter]);
};
