require("./../../../../bower_components/angular/angular.js");

require('./../../../../bower_components/ngSweetAlert/SweetAlert.js');

//Module
var groupsModule = angular.module('tdsApp.groups',[
  'oitozero.ngSweetAlert'
]);

//Routers
require('./routing.js')(groupsModule);

//Factories
require('./shared/factories/groupFactory.js')(groupsModule);

//Constants
require("./shared/constants/jobTitleGroups.js")(groupsModule);

//Controllers
require('./allGroups/controllers/allGroupsController.js')(groupsModule);
require('./groupUsers/controllers/groupUsersController.js')(groupsModule);
require('./newGroup/controllers/newGroupController.js')(groupsModule);
require('./editGroup/controllers/editGroupController.js')(groupsModule);

//Modal Controllers
require('./groupUsers/controllers/modals/addUserToGroupController.js')(groupsModule);
require('./groupUsers/controllers/modals/deleteUserFromGroupController.js')(groupsModule);
require('./allGroups/controllers/modals/deleteGroupController.js')(groupsModule);

module.exports = groupsModule;
