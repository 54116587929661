var run = function ($rootScope, $state, localStorageService, loadingFactory, generalsService) {
    var responseRedirect = function (toState, toParams) {
        $rootScope.toStateName = toState.name;
        $rootScope.toStateParams = toParams;

        setLanguage();
        $state.go('blank.loading', {
            lang: $rootScope.activeLang
        });

    };

    var setLanguage = function () {
        var userData = localStorageService.get('userInformationData');
        if (userData === null) {
            lang = window.navigator.language || window.navigator.browserLanguage || window.navigator.userLanguage;
            if (lang.indexOf('-') !== -1)
                lang = lang.split('-')[0];

            if (lang.indexOf('_') !== -1)
                lang = lang.split('_')[0];


            if (lang === "tr" || lang === "en") {
                $rootScope.activeLang = lang;
            } else {
                $rootScope.activeLang = "en";
            }
        } else {
            $rootScope.activeLang = userData.Language;
        }
    };

    var authData = localStorageService.get('authorizationData');

    var stateChanged = false;

    if ($state.current.name === "") {

        setTimeout(function () {
            if (!stateChanged) {

                setLanguage();
                $state.go('blank.loading', {
                    lang: $rootScope.activeLang
                });

                /*generalsService.getIpCountry().then(function (response) {
                    if (!stateChanged) { //Hala state değişmediyse

                        if (response.country_code.toLowerCase() == "tr")
                            $rootScope.activeLang = "tr";
                        else
                            $rootScope.activeLang = "en";
                        $state.go('blank.loading', {
                            lang: $rootScope.activeLang
                        });
                    }
                }, function (err) {
                    $rootScope.activeLang = "en";
                    $state.go('blank.loading', {
                        lang: $rootScope.activeLang
                    });
                });*/
            }

        }, 500);
    }

    $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {

        $rootScope.activeLang = toParams.lang;
        stateChanged = true;
        if (fromState.name == "" &&
            toState.name != "blank.loading" &&
            toState.name != "pdf.previewDatasheet" &&
            toState.name != "pdf.previewTemplate" &&
            toState.name != "pdf.viewDatasheet" &&
            toState.name != "pdf.view" &&
            toState.name != "pdf.viewWithRoute" &&
            toState.name != "pdf.viewPreview" &&
            toState.name != "pdf.viewCustomFile" &&
            toState.name != "auth.verifyEmail" &&
            toState.name != "auth.signInAuto" &&
            toState.name != "pdf.preparing" &&
            toState.name != "auth.confirmInvitation" &&
            toState.name != "auth.signIn" &&
            toState.name != "auth.signUp" &&
            toState.name != "auth.verifyChangeEmail"
        ) {
            event.preventDefault();

            responseRedirect(toState, toParams);
        }


    });



};

module.exports = function (app) {
    app.run(['$rootScope', '$state', 'localStorageService', 'loadingFactory', 'generalsService', run]);
};
