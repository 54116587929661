var reportFactory = function(httpProvider){
    var factoryModel = {};

    factoryModel.loadDatasheetReport = function(params){
        return httpProvider.post('reports', 'getDatasheetReport', params);
    };

    return factoryModel;
};

module.exports = function(app){
    app.factory('reportFactory', ['httpProvider', reportFactory]);
};