var datasheetLanguagesPicker = function ($translate, localStorageService) {
    return {
        restrict: 'E',
        replace: true,
        template: '<div id="spanSlLanguage" class="pull-right"><select class="form-control " style="width:120px"></select></div>',
        link: function (scope, elem, attrs) {
            //Fields 
            //Fields End

            //Elements
            var slstLanguages = elem.find('select');
            //Elements End

            //Inner Functions
            var init = function () {
                setLanguagesOptions();
                setLanguageValue();
                setSelect2();
            };

            var setLanguagesOptions = function () {
                var languages = scope.templateModel.template.languages;

                //Select List de yer alan tüm optionları sil,
                $('option', slstLanguages).remove();

                //Yeni optionları ekle
                for (var i = 0; i < languages.length; i++) {
                    var languageKey = languages[i];
                    var language = $translate.instant('TEMPLATE.SHARED.LANGUAGES.' + languageKey.toUpperCase());
                    var optionAsString = '<option val="' + languageKey + '">' + language + '</option>';
                    slstLanguages.append(optionAsString);
                };

                if (languages.length == 1) {
                    elem.hide();
                }
            };

            var setLanguageValue = function () {
                var optionSelected = slstLanguages.find("option[val='" + scope.selectionModel.selectedLanguage + "']");
                optionSelected.attr('selected', 'selected');

            };

            var setSelect2 = function () {
                slstLanguages.select2({
                    minimumResultsForSearch: Infinity
                });
            };

            var saveLocalStorage = function (selectedLanguage) {
                //LocalStorage kaydet,
                var datasheetSelectedLanguages = localStorageService.get('datasheetSelectedLanguages');
                if (datasheetSelectedLanguages == null || datasheetSelectedLanguages == undefined)
                    datasheetSelectedLanguages = [];

                var datasheetSelectedLanguage = _.find(datasheetSelectedLanguages, {
                    datasheetId: scope.datasheetModel.datasheet._id
                });

                if (datasheetSelectedLanguage == null || datasheetSelectedLanguage == undefined) {
                    datasheetSelectedLanguage = {};
                    datasheetSelectedLanguage.datasheetId = scope.datasheetModel.datasheet._id;

                    datasheetSelectedLanguages.push(datasheetSelectedLanguage);
                }

                datasheetSelectedLanguage.selectedLanguage = selectedLanguage;
                localStorageService.set('datasheetSelectedLanguages', datasheetSelectedLanguages);
            };
            //Inner Functions End

            //Events
            slstLanguages.bind('change', function (e) {
                var optionSelected = $(this).find("option:selected");
                var selectedLanguage = optionSelected.attr('val');
                scope.selectionModel.selectedLanguage = selectedLanguage;

                $('.changeable-content').trigger('datasheetLanguageChanged', {
                    language: selectedLanguage
                });

                saveLocalStorage(selectedLanguage);

            });

            scope.$on('$destroy', function () {
                slstLanguages.remove();
            });
            //Events End

            //Initials 
            init();
            //Initials End
        }
    }
};

module.exports = function (app) {
    app.directive('datasheetLanguagesPicker', ['$translate', 'localStorageService', datasheetLanguagesPicker]);
};