//Required Packages
require("./../../../../bower_components/angular/angular.js");

require('../core/');
require('../users');
require('../subscription')

//Module
var coreModule = angular.module('tdsApp.core.loading', [
    'tdsApp.core',
    'tdsApp.users',
    'tdsApp.subscription'
]);

//Router
require("./routing.js")(coreModule);

//Run
require("./run.js")(coreModule);

//Factories & Services
require("./shared/factories/loadingFactory.js")(coreModule);
require("./shared/factories/quoteFactory.js")(coreModule);

//Directives

//Controllers
require("./loading/controllers/loadingController.js")(coreModule);
module.exports = coreModule;
