var datasheetInputIconService = function (generalsService, _) {
    //Fields
    var serviceModel = {};
    //Fields End

    //Functions
    serviceModel.editValue = function (element, templateElementSource) {
        //Elementin Value Kısmını Oluştur.
        if (element.value == null || element.value == undefined || element.value == '') {
            element.value = '';
            if (templateElementSource.length > 0) {
                element.value = templateElementSource[0];
            }
        }
        else {
            var deValue = element.value;
            if (templateElementSource.indexOf(deValue) == -1) {
                element.value = '';

                if (templateElementSource.length > 0) {
                    element.value = templateElementSource[0];
                }
            }
        }
    };
    //Functions End

    //Inner Functions
    //Inner Functions End

    return serviceModel;
};

module.exports = function (app) {
    app.service('datasheetInputIconService', ['_', datasheetInputIconService]);
};