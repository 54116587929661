var backupOptionsCtrl = function($scope, $modal, backupFactory){

  //Private Functions
  var loadChannels = function(){
    backupFactory.getChannels().then(function(response){
      $scope.backupChannels = response;
    });
  };
  //Private Functions - End

  //Functions
  $scope.addBackup = function(){
    var modalInstance = $modal.open({
        animation: false,
        controller: 'ftpChannelModalCtrl',
        templateUrl: '/app/components/backup/backupOptions/views/modals/ftpChannelModal.html',
        backdropClass: "defaultModal",
        size: "lg",
        resolve: {
            model: function() {
                return $scope.model;
            },
            parentScope: function() {
                return $scope;
            }
        }
    });

    modalInstance.result.then(function() {
        loadChannels();
    });
  };
  //Functions - End

  //Initials
  loadChannels();
  //Initials - End
};

module.exports = function(app){
  app.controller('backupOptionsCtrl', ['$scope', '$modal', 'backupFactory', backupOptionsCtrl]);
};
