var browserUpgradeCtrl = function($rootScope, $scope, $state, $stateParams, $translate, localStorageService) {

    
    var authorizationData = localStorageService.get('authorizationData');
    
    var checkForBrowser = function() {
        
        // Opera 8.0+
        var isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
        
        // Firefox 1.0+
        var isFirefox = typeof InstallTrigger !== 'undefined';
        
        // Safari 3.0+ "[object HTMLElementConstructor]"
        var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || safari.pushNotification);
        
        // Internet Explorer 6-11
        var isIE = /*@cc_on!@*/false || !!document.documentMode;
        
        // Edge 20+
        var isEdge = !isIE && !!window.StyleMedia;
        
        // Chrome 1+
        var isChrome = !!window.chrome && !!window.chrome.webstore;
        
        // Blink engine detection
        var isBlink = (isChrome || isOpera) && !!window.CSS;
        
        if(isOpera) {
            return "Opera"
        } else if(isFirefox) {
            return "Mozilla Firefox"
        } else if(isSafari) {
            return "Apple Safari"
        } else if(isIE) {
            return "Microsoft Internet Explorer"
        } else if(isEdge) {
            return "Microsoft Edge"
        } else if(isBlink) {
            return "Chromium"
        } else if (isChrome){
            return "Google Chrome"
        }
    }
    
    $scope.chromeLink = $translate.instant('ERRORS.BROWSER_UPGRADE.URL_CHROME.LINK');
    $scope.browserName = { browserName: checkForBrowser() };

    $scope.proceedToDashboard = function() {

        authorizationData.skipBrowserUpgrade = true;
        localStorageService.set('authorizationData', authorizationData);

        $state.go('app.dashboard', {
            lang: $rootScope.activeLang
        });
    }

};

module.exports = function(app) {
    app.controller('browserUpgradeCtrl', ['$rootScope', '$scope', '$state', '$stateParams', '$translate', 'localStorageService', browserUpgradeCtrl]);
};
