var datasheetTableRow = function($compile, datasheetViewService, datasheetElementService, datasheetContextMenuService, datasheetContextMenuHandlerService, _) {
    return {
        restrict: 'A',
        link: function(scope, elem, attrs) {
            //Fields 
            var containerElement = elem;
            //Fields 

            //Elements
            var element = scope.row;
            //Elements End

            //Inner Functions
            var init = function() {

            };

            var setElementStyle = function() {
                containerElement.css(element.style);

                //Elementler gizli ise ilgili class ı ekleyelim.
                if (element != null && element != undefined) {
                    if (element.isHidden) {
                        containerElement.addClass('hidden-blur');
                    }
                }
            };

            var setCells = function() {
                var rowId = scope.row._id;
                var cells = _.filter(scope.datasheetModel.elements, {
                    parentId: rowId,
                    type: "cell"
                });
                cells = _.sortBy(cells, 'order');


                for (var i = 0, len = cells.length; i < len; i++) {
                    var cell = cells[i];
                    var cellHtml = datasheetViewService.createDatasheetElement(cell.type);

                    var childScope = scope.$new();
                    childScope.cell = cell;
                    $(elem).append($compile(cellHtml)(childScope));
                }
            };

            var setContextMenu = function() {
                $.contextMenu({
                    selector: ".row-" + scope.row._id,
                    reposition: false,
                    build: function($trigger, e) {
                        var page = scope.page;
                        var table = scope.table;
                        var row = scope.row;

                        return {
                            callback: contextMenuClickHandler,
                            items: datasheetContextMenuService.createContextMenuItemsForTableRow(page, table, row)
                        };
                    }
                });
            };
            //Inner Functions

            //Handlers
            var contextMenuClickHandler = function(key, options) {
                datasheetContextMenuHandlerService
                    .handleContextMenuClick(key,
                    scope.selectionModel.showHiddenElements,
                    scope.templateModel,
                    scope.datasheetModel,
                    scope.page,
                    scope.table,
                    scope.row,
                    scope.cell,
                    scope.element);
            };
            //Handlers End

            //Initials 
            setElementStyle();
            setCells();
            setContextMenu();
            //Initials End
        }
    };
};

module.exports = function(app) {
    app.directive('datasheetTableRow', ['$compile', 'datasheetViewService', 'datasheetElementService', 'datasheetContextMenuService', 'datasheetContextMenuHandlerService', '_', datasheetTableRow]);
};