var uiRooter = function ($stateProvider, $urlRouterProvider, $locationProvider) {
    $stateProvider.state('app.yourDatasheets', {
        url: '/your-datasheets',
        templateUrl: "/app/components/datasheet/yourDatasheets/views/yourDatasheets.html",
        controller: "yourDatasheetsCtrl",
        permission: {
            requiredPermissions: ['View Datasheet', 'View Datasheet Folder']
        },
        onExit: function() {
            $('body').css('overflow-y', 'auto');   
        }
    })
        .state('app.newDatasheet', {
            url: '/new-datasheet',
            templateUrl: "/app/components/datasheet/newDatasheet/views/newDatasheet.html",
            controller: "newDatasheetCtrl",
            permission: {
                requiredPermissions: ['New Datasheet'],
            },
            onExit: function() {
                $('body').css('overflow-y', 'auto');   
            }
        })
        .state('editor.editDatasheet', {
            url: '/edit-datasheet/:id',
            templateUrl: "/app/components/datasheet/editDatasheet/views/editDatasheet.html",
            controller: "editDatasheetCtrl",
            onEnter: ['menuToggleFactory', function (menuToggleFactory) {
                $('body').css('overflow-y', 'hidden');
                menuToggleFactory.collapse()
            }],
            onExit: ['menuToggleFactory', function (menuToggleFactory) {
                $.contextMenu('destroy');
                $('body').css('overflow-y', 'auto'); 
                menuToggleFactory.doPreviousStatus();
            }]
        })
        .state('app.copyDatasheet', {
            url: '/copy-datasheet/:id/:templateId',
            templateUrl: "/app/components/datasheet/newDatasheet/views/newDatasheet.html",
            controller: "newDatasheetCtrl",
            onExit: function() {
                $('body').css('overflow-y', 'auto');   
            }
        })
        .state('app.datasheetPermissions', {
            url: '/datasheet-permissions/:datasheetId',
            templateUrl: 'app/components/datasheet/datasheetPermissions/views/datasheetPermissions.html',
            controller: 'datasheetPermissionsCtrl',
            onExit: function() {
                $('body').css('overflow-y', 'auto');   
            }
        });

    /* removeIfProduction */
    // $urlRouterProvider.otherwise("/en/app/your-datasheets");
    /* endRemoveIfProduction */
};

module.exports = function (app) {
    app.config(['$stateProvider', '$urlRouterProvider', '$locationProvider', uiRooter]);
};
