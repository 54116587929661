var editGroupCtrl = function($scope, $state, $timeout, $stateParams, $translate, menuToggleFactory, groupFactory) {
    //Fields
    var groupId = $stateParams.groupId;

    //Fields End

    //Events
    $scope.$on('$viewContentLoaded', function() {
        $timeout(function() {
            menuToggleFactory.collapse();
        }, 100);
    });
    //Events End

    //Inner Functions
    var getGroupById = function(groupId) {
        groupFactory.getGroupById(groupId)
            .then(function(response) {
                $scope.groupName = response.Name;
                $scope.group = response;
            });
    };
    //Inner Functions End

    //Functions
    $scope.submitted = false;
    $scope.updateGroup = function(isValid, group) {
        $scope.submitted = true;
        if (isValid) {
            group.groupId = groupId;

            groupFactory.updateGroup(group)
                .then(function(response) {
                    $scope.groupName = group.Name;
                    var message = $translate.instant("GROUP.EDITGROUP.SUCCESS_MESSAGES.GROUP_EDITED");
                    toastr.success(message, '', { positionClass: 'toast-bottom-full-width', timeOut: 3000 });
                    $timeout(function() {
                        $state.go("app.allGroups");
                    }, 1000);
                });
        }
    };
    //Functions End

    //Initials
    getGroupById(groupId);
    //Initials End
};

module.exports = function(app) {
    app.controller('editGroupCtrl', ['$scope', '$state', '$timeout', '$stateParams', '$translate', 'menuToggleFactory', 'groupFactory', editGroupCtrl]);
}
