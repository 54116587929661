var pdfUploadFactory = function(
  $rootScope,
  $q,
  config,
  filepickerService,
  localStorageService
) {
  var factoryModel = {}

  factoryModel.uploadWithCallback = function(customOptions, callback) {
    var userData = localStorageService.get('userInformationData')

    var unixTime = Date.now()
    var path = '/' + userData.AccountId._id + '/' + Date.now() + '.pdf'

    var options = {
      mimetype: 'application/pdf',
      language: $rootScope.activeLang,
      services: [
        'COMPUTER',
        'GOOGLE_DRIVE',
        'SKYDRIVE',
        'DROPBOX',
        'BOX',
        'GMAIL',
      ],
    }

    if (angular.isDefined(customOptions))
      options = angular.merge(options, customOptions)

    // filepickerService.setKey('Am0vSpcsQti9yqRBdpQtuz');
    filepickerService.setKey(config.filepicker.live.apiKey)
    filepickerService.pickAndStore(
      options,
      { path: path },
      function(blobs) {
        if (angular.isUndefined(blobs) || blobs.length == 0) callback('', true)

        callback(blobs[0].key, true)
      },
      function() {
        callback('', false)
      }
    )
  }

  factoryModel.upload = function(customOptions) {
    var defer = $q.defer()
    var userData = localStorageService.get('userInformationData')

    var unixTime = Date.now()
    var path = '/' + userData.AccountId._id + '/' + Date.now() + '.pdf'

    var options = {
      mimetype: 'application/pdf',
      language: $rootScope.activeLang,
      services: [
        'COMPUTER',
        'GOOGLE_DRIVE',
        'SKYDRIVE',
        'DROPBOX',
        'BOX',
        'GMAIL',
      ],
    }

    if (angular.isDefined(customOptions))
      options = angular.merge(options, customOptions)

    // filepickerService.setKey('Am0vSpcsQti9yqRBdpQtuz')
    filepickerService.setKey(config.filepicker.live.apiKey)
    filepickerService.pickAndStore(
      options,
      { path: path },
      function(blobs) {
        if (angular.isUndefined(blobs) || blobs.length == 0) defer.resolve('')

        defer.resolve(blobs[0].key)
      },
      function() {
        defer.reject()
      }
    )

    return defer.promise
  }

  return factoryModel
}

module.exports = function(app) {
  app.factory('pdfUploadFactory', [
    '$rootScope',
    '$q',
    'config',
    'filepickerService',
    'localStorageService',
    pdfUploadFactory,
  ])
}
