//Required Packages
require("./../../../../bower_components/angular/angular.js");

require("angular-animate");
require("./../../../../node_modules/angular-busy/dist/angular-busy.js");
require('angular-local-storage');

require('angular-sanitize');
require("lodash");
require("underscore");
require("ng-notify");
require('./../../../../plugins/filePicker/');
require("./../../../../plugins/angular-bootstrap/ui-bootstrap-tpls.js");
require('./../../../../node_modules/ngstorage/ngStorage.min.js');
require('./../../../../bower_components/angular-cryptography/mdo-angular-cryptography.js');


//Module
var coreModule = angular.module('tdsApp.core', [
    'ngSanitize',
    'ngAnimate',
    'angular-filepicker',
    'LocalStorageModule',
    'ngStorage',
    'cgBusy',
    'ui.bootstrap',
    'ngNotify',
    'mdo-angular-cryptography'
    //'ngRaven' //Sentry RavenJS Angular Injection
]);

//Config
require("./config.js")(coreModule);

//Run
require("./run.js")(coreModule);

//Constants
require("./shared/constants/config.js")(coreModule);

//Filters
require("./shared/filters/htmlSafeFilter.js")(coreModule);
require("./shared/filters/htmlTagCleanerFilter.js")(coreModule);
require("./shared/filters/imageFilter.js")(coreModule);
require("./shared/filters/userFolderPathFilter.js")(coreModule);

//Factories & Services
require("./shared/services/environmentService.js")(coreModule);
require("./shared/factories/firebaseFactory.js")(coreModule);
require("./shared/factories/underscore.js")(coreModule);
require("./shared/factories/httpInterceptor.js")(coreModule);
require("./shared/factories/uploadFactory.js")(coreModule);
require("./shared/factories/googleTranslateFactory.js")(coreModule);
require("./shared/services/httpProvider.js")(coreModule);
require("./shared/services/authApiProvider.js")(coreModule);
require("./shared/services/publicApiProvider.js")(coreModule);
require("./shared/services/generalsService.js")(coreModule);
require("./shared/services/accountService.js")(coreModule);
require("./shared/factories/menuToggleFactory.js")(coreModule);
require("./shared/factories/imageFactory.js")(coreModule);
require("./shared/factories/pdfUploadFactory.js")(coreModule);
require("./shared/factories/htmlTagCleanerFactory.js")(coreModule);
require("./shared/services/intercomApiProvider")(coreModule);
require("./shared/factories/intercomFactory.js")(coreModule);

//Directives
require("./shared/directives/includeReplace.js")(coreModule);
require("./shared/directives/sideMenu.js")(coreModule);
require("./shared/directives/menuToggle.js")(coreModule);
require("./shared/directives/counterUp.js")(coreModule);
require("./shared/directives/select2.js")(coreModule);
require("./shared/directives/select2raw.js")(coreModule);
require("./shared/directives/tooltip.js")(coreModule);
require("./shared/directives/slimscroll.js")(coreModule);
require("./shared/directives/switchery.js")(coreModule);
require("./shared/directives/promiseButton.js")(coreModule);
require("./shared/directives/inputMask.js")(coreModule);
require("./shared/directives/dateTimePicker.js")(coreModule);
require("./shared/directives/niceScroll.js")(coreModule);
require("./shared/directives/nonChromeAlert.js")(coreModule);
require("./shared/directives/rangeSlider.js")(coreModule);
require("./shared/directives/uiDraggable.js")(coreModule);
require("./shared/directives/uiSortable.js")(coreModule);
require("./shared/directives/uiDroppable.js")(coreModule);
require("./shared/directives/panelScroll.js")(coreModule);
require("./shared/directives/initialisation.js")(coreModule);


//Controllers
require("./shared/controllers/globalController.js")(coreModule);


module.exports = coreModule;
