var niceScroll = function ($rootScope, $timeout) {
    return {
        restrict: 'A',
        link: function (scope, el, attrs) {
            var defaultOptions = {
                autohidemode: attrs.niceScrollAutohide != undefined ? JSON.parse(attrs.niceScrollAutohide) : false,
                touchbehavior: false,
                cursorcolor: "#00A1E1",
                cursoropacitymin: 0,
                background: "#F5F5F5",
                cursorborder: "0",
                cursorminheight: 30
            };

            //Events
            scope.$on('reRenderNiceScroll', function (event, data) {
                if (angular.isDefined(data.id)) {
                    if (data.id == attrs.id) {
                        el.getNiceScroll().resize();
                    }
                } else
                    el.getNiceScroll().resize();
            });

            $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
                el.getNiceScroll().remove();
            });
            
            $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
                el.getNiceScroll().remove();
            });

            scope.$on('destroyNiceScroll', function (event, data) {
                if (attrs.id == data.id) {
                    el.getNiceScroll().remove();
                }
            });
            //Events End

            //Inner Functions
            var setNiceScroll = function () {
                if (angular.isUndefined(attrs.options)) {
                    el.niceScroll(defaultOptions);
                } else {
                    var options = attrs.options.replace(/\'/g, '\"');
                    options = JSON.parse(options);
                    el.niceScroll(defaultOptions);
                }
            };
            //Inner Functions End

            //Initials
            setNiceScroll();
            //Initials End
        }
    }
};

module.exports = function (app) {
    app.directive('niceScroll', ['$rootScope', '$timeout', niceScroll]);
};
