var promiseButton = function ($parse, $compile, $translate) {
    var link = function (scope, element, attrs) {
        var loadingText = $translate.instant(attrs.loadingText) + ' <i class="fa fa-spinner fa-spin" ></i>';

        var successMessage = attrs.successMessage;
        var errorMessage = attrs.errorMessage;

        if (angular.isDefined(successMessage) && successMessage.length > 0)
            successMessage = $translate.instant(successMessage);

        if (angular.isDefined(errorMessage) && errorMessage.length > 0)
            errorMessage = $translate.instant(errorMessage);

        var fn = $parse(attrs.action);

        element.on('click', function (event) {
            var currentText = $(element).html();

            $(element).html(loadingText);

            fn(scope, { $event: event }).then(function (res) {
                $(element).html(currentText);

                if (angular.isDefined(successMessage) && successMessage.length > 0) {
                    toastr.success(successMessage, '', { positionClass: 'toast-bottom-full-width ', timeOut: 3000 });
                }


                return res;
            }, function (res) {
                $(element).html(currentText);
                if (angular.isDefined(errorMessage) && errorMessage.length > 0)
                    toastr.error(errorMessage, '', { positionClass: 'toast-bottom-full-width ', timeOut: 3000 });
            });
        });

    };

    return {
        restrict: 'E',
        template: "<button></button>",
        compile: function (elm, attrs) {

            var cssClass = attrs.class;
            var text = $translate.instant(attrs.text);
            var loadingText = attrs.loadingText;

            var action = attrs.action;
            var icon = attrs.icon;

            var $elem = angular.element(elm);
            var button = $elem.children()[0];

            if (angular.isDefined(icon))
                text += ' <i class="' + icon + '" ></i>'

            $(button).html(text);
            $(button).attr('loading-text', loadingText);
            $(button).attr('text', text);
            $(button).addClass(cssClass);
            $(button).attr('action', action);

            $elem.replaceWith($elem.children());

            return {
                pre: function () { },
                post: link
            };
        }
    };
};

module.exports = function (app) {
    app.directive('promiseButton', ['$parse', '$compile', '$translate', promiseButton]);
};
