var datasheetText = function (datasheetElementService, datasheetContextMenuService, datasheetContextMenuHandlerService) {
    return {
        restrict: "E",
        replace: true,
        templateUrl: 'app/components/datasheet/shared/directives/elements/datasheetText/datasheetText.html',
        link: function (scope, elem, attrs) {
            //Fields
            var containerElement = elem;
            var innerElement = containerElement.find('.inner-element');
            //Fields End

            //Elements       
            var element = scope.element;
            //Elements End

            //Inner Functions
            var init = function () {
                containerElement = $('#' + scope.element.type + '-' + scope.element._id);
                innerElement = containerElement.find(".inner-element");
            };

            var addUsefulClass = function () {
                containerElement.addClass("container-element changeable-content text-" + element._id + "");
            };

            var setElementStyle = function () {
                containerElement.css(element.containerStyle);
                innerElement.css(element.style);


                //Elementler gizli ise ilgili class ı ekleyelim.
                if (element != null && element != undefined) {
                    if (element.isHidden) {
                        containerElement.addClass("hidden-blur");
                    }
                }
            };

            var setElementValue = function () {
                //Element değerlerinde seçilen dile göre değer varsa o değeri yükle
                if (angular.isDefined(scope.element.values[scope.selectionModel.selectedLanguage])) {
                    var elementValue = scope.element.values[scope.selectionModel.selectedLanguage];
                    elementValue = datasheetElementService.setConstantFields(scope.datasheetModel, scope.page, elementValue, scope.selectionModel.selectedLanguage);
                    innerElement.html(elementValue);
                }
                else {
                    innerElement.html('');
                }
            };

            var setContextMenu = function () {
                $.contextMenu({
                    selector: "." + scope.element.type + "-" + scope.element._id,
                    reposition: false,
                    build: function ($trigger, e) {
                        var page = scope.page;
                        var table = scope.table;
                        var row = scope.row;
                        var cell = scope.cell;
                        var element = scope.element;

                        return {
                            callback: contextMenuClickHandler,
                            items: datasheetContextMenuService.createContextMenuItemsForText(page, table, row, cell, element)
                        };
                    }
                });
            };
            //Inner Functions End

            //Handlers
            var contextMenuClickHandler = function (key, options) {
                datasheetContextMenuHandlerService
                    .handleContextMenuClick(key,
                    scope.selectionModel.showHiddenElements,
                    scope.templateModel,
                    scope.datasheetModel,
                    scope.page,
                    scope.table,
                    scope.row,
                    scope.cell,
                    scope.element);
            };
            //Handlers End

            //Events

            containerElement.on('datasheetLanguageChanged', function (e, data) {
                _.defer(function () {
                    setElementValue();
                });
            });
            //Events End

            //Initials
            addUsefulClass();
            setElementStyle();
            setElementValue();
            setContextMenu();
            //Initials End
        }
    }
};

module.exports = function (app) {
    app.directive('datasheetText', ['datasheetElementService', 'datasheetContextMenuService', 'datasheetContextMenuHandlerService', datasheetText]);
};