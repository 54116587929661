var datasheetShowHiddenElements = function () {
    return {
        restrict: 'E',
        replace: true,
        templateUrl: 'app/components/datasheet/shared/directives/datasheetShowHiddenElements/datasheetShowHiddenElements.html',
        link: function (scope, elem, attrs) {
            //Fields 
            //Fields End

            //Elements
            var chbxShowHiddenElements = elem.find('#chbxShowHiddenElements');
            //Elements End

            //Inner Functions
            var init = function () {
                setSwitchery();
            };

            var setSwitchery = function () {
                //HTML DOM Objesi olması lazım olduğu için [0] indexi geciyoruz. 
                if (scope.selectionModel.showHiddenElements)
                    chbxShowHiddenElements.attr('checked', 'checked');

                var switcheryInit = new Switchery(chbxShowHiddenElements[0], { color: '#1bb99a', size: 'small' });
            }
            //Inner Functions End

            //Events
            chbxShowHiddenElements.bind('change', function () {
                var isChecked = chbxShowHiddenElements.is(':checked');
                scope.selectionModel.showHiddenElements = isChecked;

                if (isChecked) {
                    $(".hidden-blur").show();
                }
                else {
                    $(".hidden-blur").hide();
                }


            });
            //Evetns End

            //Initials 
            init();
            //Initials End
        }
    }
};

module.exports = function (app) {
    app.directive('datasheetShowHiddenElements', [datasheetShowHiddenElements]);
};