var accountSubscriptionCtrl = function ($rootScope, $scope, $state, $stateParams, accountService, subscriptionFactory, generalsService, localStorageService) {
    //Fields 
    var userInformationData = localStorageService.get('userInformationData');

    $scope.paymentStatusModel = {
        token: $stateParams.token
    };

    $scope.loaded = {
        accountSubscription: false
    };
    //Fields End

    //Initialize
    //Initialize - End

    //Inner Functions
    var init = function () {
        if ($stateParams.token) {
            var token = $stateParams.token;
            retrieveIyzicoCheckoutFormResult(token, function () {
                getAccountSubscription(function () {
                    var accountId = $scope.accountSubscription.accountId;
                    accountService.getAccount(accountId)
                        .then(function (response) {
                            var accountName = response.Name;

                            if ($scope.paymentStatusModel.paymentStatus == "SUCCESS") {

                                //Intercom'a bu değeri yazdıralım.
                                var annualVerb = $scope.accountSubscription.month == 12 ? "annually" : "monthly"

                                var trackMessage = userInformationData.FirstName + " " + userInformationData.LastName +
                                    " a " + annualVerb + " subscription change to " +
                                    $scope.accountSubscription.package.name + " for " + $scope.accountSubscription.totalPaid + $scope.accountSubscription.currency.toUpperCase() +
                                    " on account " + accountName;

                                // console.log("Track Message : ", trackMessage);
                                // console.log("Package Name : ", $scope.accountSubscription.package.name);
                                // console.log("Total Paid : ", $scope.accountSubscription.totalPaid);


                                // analytics.track(trackMessage, {
                                //     packageName: $scope.accountSubscription.package.name,
                                //     totalPaid: $scope.accountSubscription.totalPaid + $scope.accountSubscription.currency.toUpperCase()
                                // }, {}, function () {
                                //     setTimeout(function () {
                                //         window.Intercom('update');
                                //     }, 1500);
                                // });
                            }
                        });
                });
            });
        }
        else {
            getAccountSubscription();
        }
    };

    var getAccountSubscription = function (nextProcess) {
        $scope.loaded.accountSubscription = false;
        subscriptionFactory.getAccountSubscription()
            .then(function (response) {
                $scope.accountSubscription = response;

                $scope.loaded.accountSubscription = true;

                if (nextProcess) {
                    nextProcess();
                }
            });
    };

    var retrieveIyzicoCheckoutFormResult = function (token, nextProcess) {
        var request = {
            locale: $rootScope.activeLang,
            conversationId: generalsService.createGUID(),
            token: token
        };

        subscriptionFactory.retrieveIyzicoCheckoutFormResult(request)
            .then(function (response) {

                $scope.paymentStatusModel.paymentStatus = response.paymentStatus;
                $scope.paymentStatusModel.iyzicoCheckoutFormResult = response;

                nextProcess();
            });
    };
    //Inner Functions End

    //Functions

    //Functions End

    //Initials 
    init();
    //Initials End

    //For Testing
    $scope._init = init;
    $scope._getAccountSubscription = getAccountSubscription;
    $scope._retrieveIyzicoCheckoutFormResult = retrieveIyzicoCheckoutFormResult;
    //ForTesting End
};

module.exports = function (app) {
    app.controller('accountSubscriptionCtrl', ['$rootScope', '$scope', '$state', '$stateParams', 'accountService', 'subscriptionFactory', 'generalsService', 'localStorageService', accountSubscriptionCtrl]);
};
