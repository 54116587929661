var pdfBox = function (pdfElementService, pdfViewService) {
    return {
        restrict: 'E',
        replace: true,
        templateUrl: 'app/components/pdf/shared/directives/elements/pdfBox/pdfBox.html',
        link: function (scope, elem, attrs) {
            //Fields 
            var containerElement = elem;
            var innerElement = containerElement.find('.inner-element');
            //Fields End

            //Elements       
            // var element = scope.element;
            // var datasheetElement = datasheetElementService.getDatasheetElement(scope.datasheetModel, element);
            // var dynamicElement = element == datasheetElement;
            //Elements End

            //Inner Functions
            var setElementStyle = function () {
                containerElement.css(scope.element.containerStyle);

                var newStyle = pdfViewService.arrangeElementBorder(scope.element.style);
                innerElement.css(newStyle);
            };

            var setElementValue = function () {

            };
            //Inner Functions End

            //Handlers
            //Handlers End

            //Initials 
            setElementStyle();
            setElementValue();
            //Initials End
        }
    };
};

module.exports = function (app) {
    app.directive('pdfBox', ['pdfElementService', 'pdfViewService', pdfBox]);
}