var select2 = function($rootScope, $parse, $translate){

    return {
        restrict: 'AE',
        require: 'ngModel',
        link: function (scope, el, attrs, ngModel) {

          scope.$watch(function() {
              return ngModel.$modelValue;

          }, initialize);

          function initialize(value) {
            setSelect2();
          };

          $(el).find('option').each(function() {
              var innerHtml = $(this).html();
              innerHtml = $translate.instant(innerHtml);
              $(this).html(innerHtml);


          });

          var disableSearch = false;

          if(angular.isDefined(attrs.disableSearch))
              disableSearch = attrs.disableSearch;


          var setSelect2 = function(){
                var placeholder = attrs.selectPlaceholder || "";
                if(disableSearch) {
                    $(el).select2({minimumResultsForSearch: -1, placeholder: placeholder});
                } else {
                    $(el).select2({placeholder: placeholder});
                }
          };

          attrs.$observe('source', function(value){
            setSelect2();
          });
        }
    };
};

module.exports = function(app){
    app.directive('select2', ['$rootScope', '$parse', '$translate', select2]);
};
