var selectIconPackCtrl = function($scope, $modalInstance, $log, $filter, element, elementService) {

    elementService.iconList().then(function(response) {
        $scope.iconList = response;
        $scope.selectedCategory = $scope.categoryList[0];
        $scope.getIcons();

        if (element.source.length > 0){
          $scope.userSelectedIcons = [];
            for(var i in element.source){
              var icon = _.filter($scope.iconList, { icon : element.source[i]})[0];
              $scope.userSelectedIcons.push(icon);
            }
        }
        else
            $scope.userSelectedIcons = [];
    });

    //Fields


    $scope.categoryList = [{
        "Name": "Paint Icons",
        "LanguageCode": "TEMPLATE.SHARED.ICONCATEGORIES.PAINT_ICONS",
        "id": 1
    }, {
        "Name": "Do It Your Self",
        "LanguageCode": "TEMPLATE.SHARED.ICONCATEGORIES.DO_IT_YOURSELF",
        "id": 2
    }, {
        "Name": "Eco Friendly",
        "LanguageCode": "TEMPLATE.SHARED.ICONCATEGORIES.ECO_FRIENDLY",
        "id": 3
    }, {
        "Name": "Fire Safety",
        "LanguageCode": "TEMPLATE.SHARED.ICONCATEGORIES.FIRE_SAFETY",
        "id": 4
    }, {
        "Name": "Tools And Machinery",
        "LanguageCode": "TEMPLATE.SHARED.ICONCATEGORIES.TOOLS_AND_MACHINERY",
        "id": 5
    }, {
        "Name": "Tools And Machinery",
        "LanguageCode": "TEMPLATE.EDIT_TEMPLATE.MODALS.SELECT_ICON.LBL_OTHER.TEXT",
        "id": 6
    }];


    $scope.iconList = [];
    //Fields - End

    //Functions

    $scope.getIcons = function() {
        var id = $scope.selectedCategory.id;
        $scope.filteredIconList = $filter('filter')($scope.iconList, {
            categoryId: parseInt(id)
        }, true);
    };

    $scope.selectIcon = function(icon) {

        var referanceIcon = $filter('filter')($scope.userSelectedIcons, {
            categoryId: icon.categoryId,
            name: icon.name
        }, true)[0];
        if (angular.isUndefined(referanceIcon))
            $scope.userSelectedIcons.push(angular.copy(icon));

    };

    $scope.removeIcon = function(icon) {
        var indexOf = $scope.userSelectedIcons.indexOf(icon);
        $scope.userSelectedIcons.splice(indexOf, 1);
    };

    $scope.ok = function() {
      element.source = [];

      for(var i in $scope.userSelectedIcons){
        element.source.push($scope.userSelectedIcons[i].icon);
      }
      $.event.trigger({
        type : "updateInputIcon",
        element : element
      })
        $modalInstance.dismiss('cancel');
    };

    $scope.cancel = function() {
        $modalInstance.dismiss('cancel');
    };
    //Functions - End
};

module.exports = function(app) {
    app.controller('selectIconPackCtrl', ['$scope', '$modalInstance', '$log', '$filter', 'element', 'elementService', selectIconPackCtrl]);
};
