var accountDetailsCtrl = function($scope, $modal, adminFactory) {
    //Fields
    //Fields End

    //Functions
    $scope.editAccountSubscription = function (accountSub) {
        $scope.selectedAccountSubscription = accountSub;
        var modalInstance = $modal.open({
            animation: false,
            controller: 'editAccountSubscriptionCtrl',
            templateUrl: '/app/components/admin/accounts/views/modals/editAccountSubscription.html',
            backdrop: "static",
            resolve: {
                selectedAccountSubscription: function () {
                    return $scope.selectedAccountSubscription;
                }
            }
        });

        modalInstance.result
            .then(function (result) {
                if (result.status) {
                    adminFactory.getAccountSubscriptions($scope.selectedAccountSubscription.accountId).then(function(response) {
                        $scope.refreshAccountSubscriptions($scope.selectedAccountSubscription.accountId, response);
                    });
                }
            });
    };

    $scope.newAccountSubscription = function (account) {
        $scope.selectedAccount = account;
        var modalInstance = $modal.open({
            animation: false,
            controller: 'newAccountSubscriptionCtrl',
            templateUrl: '/app/components/admin/accounts/views/modals/newAccountSubscription.html',
            backdrop: "static",
            resolve: {
                selectedAccount: function () {
                    return $scope.selectedAccount;
                }
            }
        });

        modalInstance.result
            .then(function (result) {
                if (result.status) {
                    adminFactory.getAccountSubscriptions($scope.selectedAccount._id).then(function(response) {
                        $scope.refreshAccountSubscriptions($scope.selectedAccount._id, response);
                    });
                }
            });
    };
    //Functions End

    //Initials
    //Initials End
};

module.exports = function(app) {
    app.controller('accountDetailsCtrl', ['$scope', '$modal', 'adminFactory', accountDetailsCtrl]);
};
