require('./components/');

var tdsApp = angular.module('tdsApp', [
    'tdsApp.core',
    'tdsApp.core.layout',
    'tdsApp.core.translate',
    'tdsApp.core.loading',
    'tdsApp.errors',
    'tdsApp.authentication',
    'tdsApp.mailGroups',
    'tdsApp.template',
    'tdsApp.datasheet',
    'tdsApp.pdf',
    'tdsApp.search',
    'tdsApp.dashboard',
    'tdsApp.users',
    'tdsApp.settings',
    'tdsApp.groups',
    'tdsApp.permissions',
    'tdsApp.folder',
    'tdsApp.subscription',
    'tdsApp.backup',
    'tdsApp.integration',
    'tdsApp.customArchives',
    'tdsApp.reports',
    'tdsApp.admin',
    'tdsApp.accountSettings',
]);


//Initials 
var initInjector = angular.injector(['ng']);
var $http = initInjector.get('$http');
$http.get('/environment')
    .then(function (response) {
        angular.element(document).ready(function () {
            angular.module('tdsApp.core').constant('environment', response.data);
            angular.bootstrap(document, ['tdsApp']);
        });
    });

//Initials End




