var uiRooter = function($stateProvider, $urlRouterProvider, $locationProvider) {
    $stateProvider
        .state('app.mailSettings', {
            url: '/mail-settings',
            templateUrl: 'app/components/accountSettings/mailSettings/views/mailSettings.html',
            controller: "mailSettingsCtrl"
        });
};

module.exports = function(app) {
    app.config(['$stateProvider', '$urlRouterProvider', '$locationProvider', uiRooter]);
};
