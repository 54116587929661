var pdfTableHeader = function (pdfViewService, _) {
    return {
        restrict: 'A',
        link: function (scope, elem, attrs) {
            //Fields 
            //Fields End

            //Elements
            //Elements End

            //Inner Functions
            var setHeaderCells = function () {
                var tableId = scope.element._id;
                var headerCells = _.filter(scope.pdfModel.elements, {
                    parentId: tableId,
                    type: "headercell"
                });
                headerCells = _.sortBy(headerCells, 'order');

                var headerHtml = $("<tr></tr>");
                for (var i = 0, len = headerCells.length; i < len; i++) {
                    var headerCell = headerCells[i];
                    var headerCellHtml = $(pdfViewService.createPdfElement(headerCell.type));
                    $(headerCellHtml).css(headerCell.style);
                    $(headerHtml).append(headerCellHtml);
                }

                $(elem).append(headerHtml);
            };
            //Inner Functions End

            //Initials 
            setHeaderCells();
            //Initials End
        }
    };
};

module.exports = function (app) {
    app.directive('pdfTableHeader', ['pdfViewService', '_', pdfTableHeader]);
};