var templatesMenuInSideMenuCtrl = function($rootScope,$scope, $state, menuToggleFactory, localStorageService) {
    //Fields
    $scope.userPermissions = localStorageService.get('userPermissions');
    //Fields End

    //Functions
    $scope.newTemplate = function() {
        $state.go('app.newTemplate', {
            lang: $rootScope.activeLang
        }, {
            reload: true
        });
    };
    //Functions End

    //Initials
    //Initials End
};

module.exports = function(app) {
    app.controller('templatesMenuInSideMenuCtrl', ['$rootScope','$scope', '$state', 'menuToggleFactory', 'localStorageService', templatesMenuInSideMenuCtrl]);
};
