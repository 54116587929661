var subscriptionExtraItemsCtrl = function($scope){
    //Fields

    //Fields-End

    //Initials
    $scope.extraItemModel = {
        templateSlider : 1,
        datasheetSlider : 1,
        storageSlider : 1,
        storageTotal : 3,
        datasheetTotal : 95,
        templateTotal : 3
    }

    //Initials - End

    //Private Functions
    function monthDiff(d1, d2) {
      var months;
      months = (d2.getFullYear() - d1.getFullYear()) * 12;
      months -= d1.getMonth() + 1;
      months += d2.getMonth();
      return months <= 0 ? 0 : months;
    }
    //Private Functions - End

    //Functions

    $scope.getSubTotal = function(){
      return $scope.extraItemModel.storageTotal + $scope.extraItemModel.datasheetTotal + $scope.extraItemModel.templateTotal;
    };

    $scope.getGrandTotal = function(){
      if(angular.isDefined($scope.accountSubscription)){

        $scope.monthTotal = monthDiff(new Date(), new Date($scope.accountSubscription.endDate));
        
        $scope.monthTotal = $scope.monthTotal > 0 ? $scope.monthTotal : 1;
        return {
          grandTotal : $scope.getSubTotal() * $scope.monthTotal,
          totalMonth : $scope.monthTotal
        }


      }

    };
    //Functions - End



    //Watches
    $scope.$watch('extraItemModel.storageSlider', function(newVal, oldVal){

        switch (newVal) {
          case "0 GB":
            $scope.extraItemModel.storageTotal = 0;
            $scope.extraItemModel.storage = "0 GB";
            break;
          case 1:
          case "1 GB":
            $scope.extraItemModel.storageTotal = 3;
            $scope.extraItemModel.storage = "1 GB";
            break;
          case "5 GB":
            $scope.extraItemModel.storageTotal = 5;
            $scope.extraItemModel.storage = "5 GB";
            break;
          case "10 GB":
            $scope.extraItemModel.storageTotal = 7;
            $scope.extraItemModel.storage = "10 GB";
            break;
          default:
            $scope.extraItemModel.storageTotal = 0;
            $scope.extraItemModel.storage = "0 GB";
            break;

        }

    });

    $scope.$watch('extraItemModel.datasheetSlider', function(newVal, oldVal){
      newVal = parseInt(newVal);
      switch (newVal) {
        case 0:
          $scope.extraItemModel.datasheetTotal = 0;
          $scope.extraItemModel.datasheet = "0";
          break;
        case 1:
        case 1000:
          $scope.extraItemModel.datasheetTotal = 10;
          $scope.extraItemModel.datasheet = "1000";
          break;
        case 2:
        case 10000:
          $scope.extraItemModel.datasheetTotal = 95;
          $scope.extraItemModel.datasheet = "10.000";
          break;
        case 100000:
          $scope.extraItemModel.datasheetTotal = 900;
          $scope.extraItemModel.datasheet = "100.000";
          break;
        default:
          $scope.extraItemModel.datasheetTotal = 0;
          $scope.extraItemModel.datasheet = "0";
          break;

      }
    });

    $scope.$watch('extraItemModel.templateSlider', function(newVal, oldVal){
      newVal = parseInt(newVal);
        switch (newVal) {
          case 0:
            $scope.extraItemModel.templateTotal = 0;
            $scope.extraItemModel.template = "0";
            break;
          case 1:
            $scope.extraItemModel.templateTotal = 3;
            $scope.extraItemModel.template = "1";
            break;
          case 5:
            $scope.extraItemModel.templateTotal = 14;
            $scope.extraItemModel.template = "5";
            break;
          case 10:
            $scope.extraItemModel.templateTotal = 26;
            $scope.extraItemModel.template = "10";
            break;
          case 50:
            $scope.extraItemModel.templateTotal = 125;
            $scope.extraItemModel.template = "50";
            break;
          default:
            $scope.extraItemModel.templateTotal = 0;
            $scope.extraItemModel.template = "0";
            break;

        }
    });
    //Watches - End

};

module.exports = function(app){
    app.controller('subscriptionExtraItemsCtrl', ['$scope', subscriptionExtraItemsCtrl]);
};
