var folderOptionsForSaveCtrl = function($rootScope,$scope,$filter,$modal) {
    //Fields
    //Fields End

    //Functions
    $scope.openCreateNewFolder = function() {
        $rootScope.$broadcast('newFolderOpenned', {});
        $('#renameFolder').slideUp();
        $('#newFolder').slideDown();
    };
    //Functions End

    //Initials
    //Initials End
};

module.exports = function(app) {
    app.controller('folderOptionsForSaveCtrl', ['$rootScope','$scope','$filter','$modal', folderOptionsForSaveCtrl]);
};
