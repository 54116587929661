var pdfElementService = function ($filter, _) {
    //Fields 
    var serviceModel = {};
    //Fields End

    //Functions
    serviceModel.getTextValue = function (pdfModel, page, element, selectedLangauge) {
        var textValue = "";
        if (pdfModel.mode == "template-preview") {
            var value = element.values[selectedLangauge];
            if (angular.isDefined(value)) {
                textValue = _setTemplateConstantFields(pdfModel.template, pdfModel.elements, page, value);
            }

        }
        else if (pdfModel.mode == "datasheet-preview" || pdfModel.mode == "datasheet-view") {
            var value = element.values[selectedLangauge];
            if (angular.isDefined(value)) {
                textValue = _setDatasheetConstantFields(pdfModel.datasheet, pdfModel.elements, page, value, selectedLangauge);
            }
        }

        return textValue;
    };

    serviceModel.getInputTextValue = function (mode, element, selectedLangauge) {
        var inputTextValue = "";
        if (mode == "template-preview") {
            if (element.previews[selectedLangauge] != null && element.previews[selectedLangauge] != undefined) {
                inputTextValue = element.previews[selectedLangauge];
            }
        }
        else if (mode == "datasheet-preview" || mode == "datasheet-view") {
            if (element.values[selectedLangauge] != null && element.values[selectedLangauge] != undefined) {
                inputTextValue = element.values[selectedLangauge];
            }
        }

        return inputTextValue;
    };

    serviceModel.getSelectListValue = function (mode, element, selectedLanguage) {
        var selectListValue = "";
        if (mode == "template-preview") {
            var source = element.source[selectedLanguage];
            if (source != null && source != undefined && source.length != 0) {
                selectListValue = source[0].value;
            }
        }
        else if (mode == "datasheet-preview" || mode == "datasheet-view") {
            var source = element.source[selectedLanguage];
            var selectedItem = element.values[selectedLanguage];

            if (angular.isDefined(selectedItem) && angular.isDefined(selectedItem)) {
                var selected;
                var selectedItemId;
                if (angular.isDefined(selectedItem.id)) {
                    selectedItemId = selectedItem.id;
                }
                else {
                    selectedItemId = selectedItem;
                }

                var selected = _.find(source, {
                    id: selectedItemId
                });

                if (angular.isDefined(selected))
                    selectListValue = selected.value;
            }
        }

        return selectListValue;
    };

    serviceModel.getSelectListDefaultText = function (language) {
        var selectListText = {};
        switch (language) {
            case "en":
                selectListText = {
                    addItem: "Add Item"
                }
                break;
            case "tr":
                selectListText = {
                    addItem: "Öğe Ekle"
                }
                break;
            case "es":
                selectListText = {
                    addItem: "Add Item"
                }
                break;
            case "de":
                selectListText = {
                    addItem: "Add Item"
                }
                break;
            case "fr":
                selectListText = {
                    addItem: "Add Item"
                }
                break;
            case "ru":
                selectListText = {
                    addItem: "Add Item"
                }
                break;
            case "it":
                selectListText = {
                    addItem: "Add Item"
                }
                break;
            case "hu":
                selectListText = {
                    addItem: "Add Item"
                }
                break;
            case "zh-CN":
                selectListText = {
                    addItem: "Add Item"
                }
                break;
            case "el":
                selectListText = {
                    addItem: "Add Item"
                }
                break;
            case "vi":
                selectListText = {
                    addItem: "Add Item"
                }
                break;
            default:
                selectListText = {
                    addItem: "Add Item"
                }
                break;
        }

        return selectListText;
    };

    serviceModel.getImageDefaultText = function (language) {
        var imageText = {};
        switch (language) {
            case "en":
                imageText = {
                    image: "Image",
                    imageWillBeHere: "Image will be here"
                }
                break;
            case "tr":
                imageText = {
                    image: "Resim",
                    imageWillBeHere: "Buraya resim gelecek"
                }
                break;
            case "es":
                imageText = {
                    image: "Imagen",
                    imageWillBeHere: "Haz clic para subir la imagen"
                }
                break;
            case "de":
                imageText = {
                    image: "Image",
                    imageWillBeHere: "Klicken Sie, um das Bild hochzuladen"
                }
                break;
            case "fr":
                imageText = {
                    image: "Image",
                    imageWillBeHere: "Cliquez pour télécharger l'image"
                }
                break;
            case "ru":
                imageText = {
                    image: "Образ",
                    imageWillBeHere: "Нажмите, чтобы загрузить изображение"
                }
                break;
            case "it":
                imageText = {
                    image: "Immagine",
                    imageWillBeHere: "Clicca per caricare immagine"
                }
                break;
            case "hu":
                imageText = {
                    image: "Kép",
                    imageWillBeHere: "Kattintson feltölteni a képet"
                }
                break;
            case "zh-CN":
                imageText = {
                    image: "圖片",
                    imageWillBeHere: "點擊上傳圖片"
                }
                break;
            case "el":
                imageText = {
                    image: "Εικόνα",
                    imageWillBeHere: "Κάντε κλικ για να ανεβάσετε την εικόνα"
                }
                break;
            case "vi":
                imageText = {
                    image: "Hình ảnh",
                    imageWillBeHere: "Nhấn vào đây để tải lên hình ảnh"
                }
                break;
            case "id":
                imageText = {
                    image: "Gambar",
                    imageWillBeHere: "Gambar akan ada disini"
                }
                break;
            default:
                imageText = {
                    image: "Resim",
                    imageWillBeHere: "Buraya resim gelecek"
                }
                break;
        }

        return imageText;
    };

    serviceModel.getIconDefaultText = function (language) {
        var iconText = {};
        switch (language) {
            case "en":
                iconText = {
                    icon: "Icon",
                    iconWillBeHere: "Icon will be here"
                }
                break;
            case "tr":
                iconText = {
                    icon: "İkon",
                    iconWillBeHere: "Buraya ikon gelecek"
                }
                break;
            case "es":
                iconText = {
                    icon: "Imagen",
                    iconWillBeHere: "Haz clic para subir la imagen"
                }
                break;
            case "de":
                iconText = {
                    icon: "Image",
                    iconWillBeHere: "Klicken Sie, um das Bild hochzuladen"
                }
                break;
            case "fr":
                iconText = {
                    icon: "Image",
                    iconWillBeHere: "Cliquez pour télécharger l'image"
                }
                break;
            case "ru":
                iconText = {
                    icon: "Образ",
                    iconWillBeHere: "Нажмите, чтобы загрузить изображение"
                }
                break;
            case "it":
                iconText = {
                    icon: "Immagine",
                    iconWillBeHere: "Clicca per caricare immagine"
                }
                break;
            case "hu":
                iconText = {
                    image: "Kép",
                    iconWillBeHere: "Kattintson feltölteni a képet"
                }
                break;
            case "zh-CN":
                iconText = {
                    icon: "圖片",
                    iconWillBeHere: "點擊上傳圖片"
                }
                break;
            case "el":
                iconText = {
                    icon: "Εικόνα",
                    iconWillBeHere: "Κάντε κλικ για να ανεβάσετε την εικόνα"
                }
                break;
            case "vi":
                iconText = {
                    icon: "Hình ảnh",
                    iconWillBeHere: "Nhấn vào đây để tải lên hình ảnh"
                }
                break;
            case "id":
                iconText = {
                    icon: "Ikon",
                    iconWillBeHere: "Ikon akan ada di sini"
                }
                break;
            default:
                iconText = {
                    icon: "İkon",
                    iconWillBeHere: "Buraya ikon gelecek"
                }
                break;
        }

        return iconText;
    };

    serviceModel.getInputIconValue = function (mode, element, selectedLangauge) {
        var inputIconValue = "";
        if (mode == "template-preview") {
            if (element.source != null && element.source != undefined && element.source.length != 0) {
                inputIconValue = element.source[0];
            }
        }
        else if (mode == "datasheet-preview" || mode == "datasheet-view") {
            if (element.value != null && element.value != undefined && element.value != "") {
                inputIconValue = element.value;
            }
        }

        return inputIconValue;
    };
    //Functions End

    //Inner Functions

    function pad(n, width, z) {
        z = z || '0';
        n = n + '';
        return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
    }


    var _setTemplateConstantFields = function (template, elements, page, value) {
        //Product Number
        if (value.indexOf("#Product-Number#") >= 0) {
            value = value.replace(new RegExp("#Product-Number#", 'g'), 'Product Number');
        }

        //Product Name
        if (value.indexOf("#Product-Name#") >= 0) {
            value = value.replace(new RegExp("#Product-Name#", 'g'), 'Product Name');
        }

        //Publish Date
        if (value.indexOf("#Publish-Date#") >= 0) {
            var publishDate = $filter('date')(template.dateModified, "dd.MM.yyyy");
            value = value.replace(new RegExp("#Publish-Date#", 'g'), publishDate);
        }

        //Revision Number
        if (value.indexOf("#Revision-Number#") >= 0) {
            value = value.replace(new RegExp("#Revision-Number#", 'g'), '01');
        }

        //Page-No
        if (value.indexOf("#Page-No#") >= 0) {
            var templatePages = _.filter(elements, {
                "type": "page"
            });
            var totalPage = _.countBy(elements, { // pages'ın başına sıfır eklemek için buraya eklendi: sayı basamak sayısı için
                "type": "page"
            })[true];

            var orderedPages = _.sortBy(templatePages, 'order');
            var pageOrder = templatePages.indexOf(page) + 1;
            //var pageNo = totalPage < 10 ? pad(pageOrder, 2) : pad(pageOrder, totalPage.toString().length); // başına sıfır eklemek için
            var pageNo = pageOrder;
            value = value.replace(new RegExp("#Page-No#", 'g'), pageNo);
        }

        //Total-Page
        if (value.indexOf("#Total-Page#") >= 0) {
            var totalPage = _.countBy(elements, {
                "type": "page"
            })[true];
            //totalPage = totalPage < 10 ? pad(totalPage, 2) : totalPage; // başına sıfır eklemek için
            value = value.replace(new RegExp("#Total-Page#", 'g'), totalPage);
        }

        return value;
    };

    var _setDatasheetConstantFields = function (datasheet, elements, page, value, language) {
        //Product Number
        if (value.indexOf("#Product-Number#") >= 0) {
            if (datasheet.productNumber) {
                value = value.replace(new RegExp("#Product-Number#", 'g'), datasheet.productNumber);
            }
            else {
                value = value.replace(new RegExp("#Product-Number#", 'g'), "");
            }
        }


        //Product Name
        if (value.indexOf("#Product-Name#") >= 0) {
            var datasheetName = _.find(datasheet.names, {
                "language": language
            });
            value = value.replace(new RegExp("#Product-Name#", 'g'), datasheetName.name);
        }

        //Publish Date
        if (value.indexOf("#Publish-Date#") >= 0) {
            var publishDate = $filter('date')(datasheet.dateModified, "dd.MM.yyyy");
            value = value.replace(new RegExp("#Publish-Date#", 'g'), publishDate);
        }

        //Revision Number
        if (value.indexOf("#Revision-Number#") >= 0) {
            var revisionNumber = datasheet.majorVersion >= 10 ? datasheet.majorVersion.toString() : "0" + datasheet.majorVersion;
            value = value.replace(new RegExp("#Revision-Number#", 'g'), revisionNumber);
        }

        //Page-No
        if (value.indexOf("#Page-No#") >= 0) {
            var pages = _.filter(elements, {
                "type": "page"
            });
            var totalPage = _.countBy(elements, { // pages'ın başına sıfır eklemek için buraya eklendi: sayı basamak sayısı için
                "type": "page"
            })[true];

            var orderedPages = _.sortBy(pages, 'order');
            var pageOrder = pages.indexOf(page) + 1;
            //var pageNo = totalPage < 10 ? pad(pageOrder, 2) : pad(pageOrder, totalPage.toString().length); // başına sıfır eklemek için
            var pageNo = pageOrder;
            value = value.replace(new RegExp("#Page-No#", 'g'), pageNo);
        }

        //Total-Page
        if (value.indexOf("#Total-Page#") >= 0) {
            var totalPage = _.countBy(elements, {
                "type": "page"
            })[true];
            //totalPage = totalPage < 10 ? pad(totalPage, 2) : totalPage; // başına sıfır eklemek için
            value = value.replace(new RegExp("#Total-Page#", 'g'), totalPage);
        }

        return value;
    };
    //Inner Functions End

    return serviceModel;
};

module.exports = function (app) {
    app.service('pdfElementService', ['$filter', '_', pdfElementService]);
};
