require("./../../../../bower_components/angular/angular.js");
require('angular-ui-router');
require('../core/');
require('../authentication/');
require('../template/');
require('../datasheet/');

//Module
var pdfModule = angular.module('tdsApp.pdf', [
  'ui.router',
  'tdsApp.core',
  'tdsApp.authentication',
  'tdsApp.template',
  'tdsApp.datasheet'
]);

//Router
require('./routing.js')(pdfModule);

//Run
require('./run.js')(pdfModule);

//Factories
require('./shared/factories/pdfFactory.js')(pdfModule);

//Services
require('./shared/services/pdfViewService.js')(pdfModule);
require('./shared/services/pdfElementService.js')(pdfModule);

//Directives
require('./shared/directives/pdfPages/pdfPages.js')(pdfModule);
require('./shared/directives/pdfPages/pdfPage.js')(pdfModule);
require('./shared/directives/pdfHeader/pdfHeader.js')(pdfModule);
require('./shared/directives/pdfFooter/pdfFooter.js')(pdfModule);
require('./shared/directives/pdfBody/pdfBody.js')(pdfModule);
require('./shared/directives/pdfLayouts/pdfSingleLayout.js')(pdfModule);
require('./shared/directives/pdfLayouts/pdfBottomSingleLayout.js')(pdfModule);
require('./shared/directives/pdfLayouts/pdfDoubleLayout.js')(pdfModule);
require('./shared/directives/pdfLayouts/pdfTripleLayout.js')(pdfModule);
require('./shared/directives/pdfLayouts/pdfTopSingleLayout.js')(pdfModule);
require('./shared/directives/pdfLayouts/pdfFourPaneLayout.js')(pdfModule);
require('./shared/directives/pdfPane/pdfPane.js')(pdfModule);
require('./shared/directives/elements/pdfText/pdfText.js')(pdfModule);
require('./shared/directives/elements/pdfInputText/pdfInputText.js')(pdfModule);
require('./shared/directives/elements/pdfBox/pdfBox.js')(pdfModule);
require('./shared/directives/elements/pdfIcon/pdfIcon.js')(pdfModule);
require('./shared/directives/elements/pdfImage/pdfImage.js')(pdfModule);
require('./shared/directives/elements/pdfInputIcon/pdfInputIcon.js')(pdfModule);
require('./shared/directives/elements/pdfInputImage/pdfInputImage.js')(pdfModule);
require('./shared/directives/elements/pdfTable/pdfTable/pdfTable.js')(pdfModule);
require('./shared/directives/elements/pdfTable/pdfTableBody/pdfTableBody.js')(pdfModule);
require('./shared/directives/elements/pdfTable/pdfTableCell/pdfTableCell.js')(pdfModule);
require('./shared/directives/elements/pdfTable/pdfTableHeader/pdfTableHeader.js')(pdfModule);
require('./shared/directives/elements/pdfTable/pdfTableRow/pdfTableRow.js')(pdfModule);
require('./shared/directives/elements/pdfSelectList/pdfSelectList.js')(pdfModule);

//Controllers
require('./previewTemplate/controllers/previewTemplateController.js')(pdfModule);
require('./previewDatasheet/controllers/previewDatasheetController.js')(pdfModule);
require('./viewDatasheet/controllers/viewDatasheetController.js')(pdfModule);
require('./viewPdf/controllers/viewPdfController.js')(pdfModule);
require('./viewPreview/controllers/viewPreviewController.js')(pdfModule);
require('./viewCustomFile/controllers/viewCustomFileController.js')(pdfModule);

//Directive Controllers
require('./shared/directives/pdfPane/pdfPaneController.js')(pdfModule);

//Export
module.exports = pdfModule;
