require("./../../../../bower_components/angular/angular.js");
require("angular-bootstrap-switch");

//Module
var permissionsModule = angular.module('tdsApp.permissions', [
    'frapontillo.bootstrap-switch'
]);

permissionsModule.run(['$rootScope', '$state', '$injector', '$filter', '$sessionStorage', 'localStorageService', 'checkPermissionFactory', function ($rootScope, $state, $injector, $filter, $sessionStorage, localStorageService, checkPermissionFactory) {
    //Fields
    //Fields End

    //Events
    $rootScope.$on('$stateChangeStart',
        function (event, toState, toParams, fromState, fromParams, options) {
            if (toState.name == "editor.editTemplate") {
                var templateId = toParams.id;

                var checkedTemplates = $sessionStorage.checkedTemplates;
                if (checkedTemplates == null || checkedTemplates == undefined) {
                    checkedTemplates = [];
                    $sessionStorage.checkedTemplates = checkedTemplates;
                }
                var template = _.find(checkedTemplates, {
                    id: templateId
                });

                if (!template) {
                    event.preventDefault();
                    checkPermissionFactory.canEditTemplate(templateId)
                        .then(function (canEdit) {
                            if (canEdit) {
                                $sessionStorage.checkedTemplates.push({
                                    id: templateId
                                });
                                $state.go(toState, toParams);
                            }
                            else {
                                $state.go('app.dashboard', { lang: $rootScope.activeLang });
                            }
                        });
                }
                else {
                    $sessionStorage.$reset();
                }
            }

            else if (toState.name == 'editor.editDatasheet') {
                var datasheetId = toParams.id;

                var checkedDatasheets = $sessionStorage.checkedDatasheets;
                if (checkedDatasheets == null || checkedDatasheets == undefined) {
                    checkedDatasheets = [];
                    $sessionStorage.checkedDatasheets = checkedDatasheets;
                }
                var datasheet = _.find(checkedDatasheets, {
                    id: datasheetId
                });

                if (!datasheet) {
                    event.preventDefault();
                    checkPermissionFactory.canEditDatasheet(datasheetId)
                        .then(function (canEdit) {

                            if (canEdit) {
                                $sessionStorage.checkedDatasheets.push({
                                    id: datasheetId
                                });
                                $state.go(toState, toParams);
                            }
                            else {
                                $state.go('app.dashboard',  { lang: $rootScope.activeLang });
                            }
                        });
                }
                else {
                    $sessionStorage.$reset();
                }
            }
            else if (toState.permission != null && angular.isDefined(toState.permission) && toState.permission.requiredPermissions != null &&
                angular.isDefined(toState.permission.requiredPermissions) && toState.permission.requiredPermissions.length > 0) {

                //Kullanıcının genel izinlerini kontrol et,
                var userPermissions = localStorageService.get('userPermissions');
                var requiredPermissions = toState.permission.requiredPermissions;

                var hasPermission = false;
                for (var i = 0; i < requiredPermissions.length; i++) {
                    var requiredPermission = requiredPermissions[i];

                    var foundPermission = $filter('filter')(userPermissions, {
                        Name: requiredPermission
                    })[0];

                    if (foundPermission != null && angular.isDefined(foundPermission)) {
                        hasPermission = true;
                        break;
                    }
                }

                //Kullanıcının özel izinlerini kontrol et,
                if (!hasPermission) {
                    event.preventDefault();
                    $state.go('app.dashboard');
                }
            }
        });
    //Events End
}]);


//Routers
require('./routing.js')(permissionsModule);

//Directives
require('./shared/directives/checkPermission.js')(permissionsModule);

//Factories
require('./shared/factories/permissionFactory.js')(permissionsModule);
require('./shared/factories/checkPermissionFactory.js')(permissionsModule);

//Controllers
require('./allPermissions/controllers/allPermissionsController.js')(permissionsModule);
require('./allPermissionLevels/controllers/allPermissionLevelsController.js')(permissionsModule);
require('./newPermissionLevel/controllers/newPermissionLevelController.js')(permissionsModule);
require('./editPermissionLevel/controllers/editPermissionLevelController.js')(permissionsModule);

//Modal Controllers
require('./shared/controllers/modals/grantPermissionController.js')(permissionsModule);
require('./shared/controllers/modals/editPermissionController.js')(permissionsModule);
require('./shared/controllers/modals/deletePermissionController.js')(permissionsModule);
require('./allPermissionLevels/controllers/modals/deletePermissionLevelController.js')(permissionsModule);


module.exports = permissionsModule;
