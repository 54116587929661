var dashboardCtrl = function ($rootScope, $scope, $timeout, intercomFactory) {
    //Fields
    //Fields End

    //Events
    //Events End

    //Watches
    //Watches End

    //Inner Functions
    //Inner Functions End

    //Functions
    //Functions End

    //Initials
    intercomFactory.addEvent({
        event_name: "Dashboard Viewed"
    });
    //Initials End
};

module.exports = function (app) {
    app.controller('dashboardCtrl', ['$rootScope', '$scope', '$timeout', 'intercomFactory', dashboardCtrl]);
};
