var datasheetPane = function ($compile, datasheetViewService, datasheetViewCacheService) {
    return {
        restrict: 'E',
        replace: true,
        templateUrl: 'app/components/datasheet/shared/directives/datasheetPane/datasheetPane.html',
        link: function (scope, elem, attrs) {
            //Fields 
            var closestTd = $(elem).closest("td");
            //Fields End

            //Elements
            //Elements End

            //Inner Functions
            var setPaneStyle = function () {
                elem.find(".pane-inner-panel").css(scope.pane.style);
            };

            var setWidthOfClosestTd = function () {
                var width = scope.pane.style.width;
                if (width != null && width != undefined && width.indexOf("px") > 0) {
                    closestTd.css("width", width);
                }

                var height = scope.pane.style.height;
                if (height != null && height != undefined && height.indexOf("px") > 0) {
                    closestTd.css("height", height);
                }
            };

            var setPaneElements = function () {
                var paneId = scope.pane._id;
                var elements = _.filter(scope.datasheetModel.elements, {
                    "parentId": paneId,
                    "isFree": false
                });

                elements = _.sortBy(elements, 'order');

                for (var i = 0, len = elements.length; i < len; i++) {
                    var element = elements[i];
                    var elementHtml = datasheetViewService.createDatasheetElement(element.type);

                    var childScope = scope.$new();
                    childScope.element = element;
                    $(elem).find(".pane-inner-panel").append($compile(elementHtml)(childScope));
                }
            };

            var setSlimScroll = function () {
                $(elem).find(".pane-inner-panel").slimscroll({
                    height: '100%',
                    railVisible: false,
                    alwaysVisible: false,
                    distance: "10px"
                });
            };
            //Inner Functions End

            //Initials
            setPaneStyle();
            setWidthOfClosestTd();
            setPaneElements();
            setSlimScroll();
            //Initials End
        }
    }
};

module.exports = function (app) {
    app.directive('datasheetPane', ['$compile', 'datasheetViewService', 'datasheetViewCacheService', datasheetPane]);
};