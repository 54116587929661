var elementService = function($http, $q, $filter, generalsService, localStorageService, filepickerService, $rootScope) {
    //Fields
    var serviceModel = {};
    //Fields - End

    //Private Functions
    var isFunction = function(obj) {
        return {}.toString.apply(obj) === '[object Function]';
    };

    var isArray = function(obj) {
        return {}.toString.apply(obj) === '[object Array]';
    };

    var isObject = function(obj) {
        return {}.toString.apply(obj) === '[object Object]';
    };

    var isValue = function(obj) {
        return !isObject(obj) && !isArray(obj);
    };

    var getBaseStyle = function(element, hasScale) {
        var elementStyle = element.ElementStyle;
        var style = {};
        style.backgroundColor = elementStyle.BackgroundColor;

        if (!angular.isUndefined(elementStyle.FontStyle)) {
            if (angular.isUndefined(elementStyle.FontStyle.FontSizeType) === true)
                elementStyle.FontStyle.FontSizeType = "pt";

            style.color = elementStyle.FontStyle.ForeColor;
            style.fontFamily = elementStyle.FontStyle.FontFamily;
            style.fontSize = elementStyle.FontStyle.FontSize + elementStyle.FontStyle.FontSizeType;
            style.fontWeight = elementStyle.FontStyle.FontWeight;
            style.fontStyle = elementStyle.FontStyle.FontVisibleStyle;
            style.textDecoration = elementStyle.FontStyle.TextDecoration;
            style.textAlign = elementStyle.FontStyle.TextAlign;

            if (angular.isUndefined(elementStyle.FontStyle.LineHeightType))
                elementStyle.FontStyle.LineHeightType = "pt";
            style.lineHeight = elementStyle.FontStyle.LineHeight + elementStyle.FontStyle.LineHeightType;


            if (!angular.isUndefined(elementStyle.DimensionFactor) && angular.isUndefined(hasScale) === false && hasScale === true) {


                style.fontSize = (elementStyle.FontStyle.FontSize * elementStyle.DimensionFactor) + elementStyle.FontStyle.FontSizeType;


                if (angular.isUndefined(elementStyle.FontStyle.LineHeightType))
                    elementStyle.FontStyle.LineHeightType = "pt";


                style.lineHeight = (elementStyle.FontStyle.LineHeight * elementStyle.DimensionFactor) + elementStyle.FontStyle.LineHeightType;
            }
        }

        if (!angular.isUndefined(elementStyle.Borders)) {
            angular.forEach(elementStyle.Borders, function(border, key) {
                if (border.Width > 0) {
                    var bWidth = border.Width;
                    switch (border.Position) {
                        case "top":
                            style.borderTop = bWidth + "px " + border.Style + " " + border.Color;
                            break;
                        case "bottom":
                            style.borderBottom = bWidth + "px " + border.Style + " " + border.Color;
                            break;
                        case "left":
                            style.borderLeft = bWidth + "px " + border.Style + " " + border.Color;
                            break;
                        case "right":
                            style.borderRight = bWidth + "px " + border.Style + " " + border.Color;
                            break;
                        default:
                            break;

                    }
                }
            });
        }

        if (!angular.isUndefined(elementStyle.Paddings)) {
            angular.forEach(elementStyle.Paddings, function(padding, key) {
                switch (padding.Position) {
                    case "top":
                        style.paddingTop = padding.PaddingPx + "px";
                        break;
                    case "bottom":
                        style.paddingBottom = padding.PaddingPx + "px";
                        break;
                    case "left":
                        style.paddingLeft = padding.PaddingPx + "px";
                        break;
                    case "right":
                        style.paddingRight = padding.PaddingPx + "px";
                        break;
                    default:
                        break;

                }
            });
        }

        if (!angular.isUndefined(elementStyle.Margins)) {
            angular.forEach(elementStyle.Margins, function(margin, key) {

                var pxWidth = margin.MarginPx;

                if (!angular.isUndefined(elementStyle.DimensionFactor) && angular.isUndefined(hasScale) === false && hasScale === true)
                    pxWidth = pxWidth * elementStyle.DimensionFactor;

                switch (margin.Position) {
                    case "top":
                        style.marginTop = pxWidth + "px";
                        break;
                    case "bottom":
                        style.marginBottom = pxWidth + "px";
                        break;
                    case "left":
                        style.marginLeft = pxWidth + "px";
                        break;
                    case "right":
                        style.marginRight = pxWidth + "px";
                        break;
                    default:
                        break;

                }
            });
        }

        if (!angular.isUndefined(elementStyle.VerticalAlign)) {
            style.verticalAlign = elementStyle.VerticalAlign;
        }

        if (!angular.isUndefined(elementStyle.BackgroundImage)) {
            style.backgroundImage = "url('" + elementStyle.BackgroundImage + "')";
            style.backgroundRepeat = elementStyle.BackgroundRepeat;
            style.backgroundSize = elementStyle.BackgroundSize;

            if (angular.isUndefined(elementStyle.BackgroundPosition) || elementStyle.BackgroundPosition === "")
                elementStyle.BackgroundPosition = "left top";

            style.backgroundPosition = elementStyle.BackgroundPosition;
        }

        return style;
    };

    var computeElementPositionsRelated = function(elem, pos, w, h) {
        if (elem !== null) {

            var element = $(elem);
            var position = element.position();
            var width = element.outerWidth();
            var height = element.outerHeight();

            return {
                leftBorder: position.left,
                topBorder: position.top,
                rightBorder: position.left + width,
                bottomBorder: position.top + height,
                htmlElement: elem
            };
        }
    };

    serviceModel.getStaticTemplate = function(itemType) {

        var deferred = $q.defer();
        var res = $http.get("/app/components/template/shared/json/elementTemplates.json");

        res.success(function(data, status, headers, config) {
            var resultTemplate = $filter('filter')(data, {
                itemType: itemType
            }, true)[0];

            deferred.resolve(resultTemplate);
        });

        res.error(function(msg, code) {
            deferred.reject(msg);
        });

        return deferred.promise;
    };
    serviceModel.getElementTemplate = function(element) {
        switch (element.ElementType) {
            case 1:
                return "/app/views/elements/textElement.html";
            case 2:
                return "/app/views/elements/imageElement.html";
            case 3:
                return "/app/views/elements/tableElement.html";
            case 4:
                return "/app/views/elements/iconElement.html";
            case 5:
                return "/app/views/elements/inputtextElement.html";
            case 29:
                return "/app/views/elements/boxElement.html";
            case 8:
                return "/app/views/elements/productNameElement.html";
            case 9:
                return "/app/views/elements/versionNumberElement.html";
            case 10:
                return "/app/views/elements/publishDateElement.html";
            case 11:
                return "/app/views/elements/productNumberElement.html";
            case 13:
                return "/app/views/elements/selectlistElement.html";
            case 14:
                return "/app/views/elements/inputimageElement.html";
            case 16:
                return "/app/views/elements/pageNumberElement.html";
            case 25:
                return "/app/views/elements/panelElement.html";
            case 26:
                return "/app/views/elements/splitPanelElement.html";
            case 12:
                return "/app/views/elements/inputIconElement.html";
            default:
                return "";
        }
    };
    serviceModel.switcElementTypeTextToInt = function(elementType) {
        switch (elementType) {
            case "text":
                return 1;
            case "image":
                return 2;
            case "table":
                return 3;
            case "icon":
                return 4;
            case "inputtext":
                return 5;
            case "box":
                return 29;
            case "productName":
                return 8;
            case "versionNumber":
                return 9;
            case "publishDate":
                return 10;
            case "productNumber":
                return 11;
            case "selectlist":
                return 13;
            case "inputimage":
                return 14;
            case "pageNumber":
                return 16;
            case "panel":
                return 25;
            case "splitpanel":
                return 26;
            case "inputicon":
                return 12;
            default:
                return 0;
        }
    };
    serviceModel.switcElementTypeIntToText = function(elementType) {
        switch (elementType) {
            case 1:
                return "text";
            case 2:
                return "image";
            case 3:
                return "table";
            case 4:
                return "icon";
            case 5:
                return "inputtext";
            case 6:
                return "cell";
            case 29:
                return "box";
            case 8:
                return "productName";
            case 9:
                return "versionNumber";
            case 10:
                return "publishDate";
            case 11:
                return "productNumber";
            case 13:
                return "selectlist";
            case 14:
                return "inputimage";
            case 16:
                return "pageNumber";
            case 25:
                return "panel";
            case 26:
                return "splitpanel";
            case 12:
                return "inputicon";
            default:
                return "text";
        }
    };

    serviceModel.setNewElementStyle = function(newElement, position) {
        var style = {};
        var containerStyle = {};
        if (newElement.isDraggable) {
            containerStyle.width = "150px";
            containerStyle.height = "auto";
            containerStyle.left = position.left + "px";
            containerStyle.top = position.top + "px";
            containerStyle.position = "absolute";
        }

        style.width = "100%";
        style.height = "auto";

        style.borderTopWidth = "0px";
        style.borderTopStyle = "solid";
        style.borderTopColor = "#000000";

        style.borderBottomWidth = "0px";
        style.borderBottomStyle = "solid";
        style.borderBottomColor = "#000000";

        style.borderLeftWidth = "0px";
        style.borderLeftStyle = "solid";
        style.borderLeftColor = "#000000";

        style.borderRightWidth = "0px";
        style.borderRightStyle = "solid";
        style.borderRightColor = "#000000";

        style.paddingTop = "0px";
        style.paddingBottom = "0px";
        style.paddingLeft = "0px";
        style.paddingRight = "0px";

        style.marginTop = "0px";
        style.marginBottom = "0px";
        style.marginLeft = "0px";
        style.marginRight = "0px";

        style.fontFamily = "Arial, Helvetica, sans-serif";
        style.fontSize = "11pt";
        style.color = "#000000";
        style.lineHeight = "12pt";
        style.fontWeight = "normal";
        style.textDecoration = "none";
        style.textAlign = "left";

        newElement.style = style;
        newElement.containerStyle = containerStyle;

        //Style Options
        var styleOptions = {};
        styleOptions.BordersLocked = false;
        styleOptions.PaddingsLocked = false;
        styleOptions.MarginsLocked = false;
        newElement.styleOptions = styleOptions;
    };
    serviceModel.setNewElementLanguages = function(newElement, languages) {
        newElement.elementValues = {};
        if (newElement.type == "text") {
            for (var i in languages) {
                newElement.elementValues[languages[i]] = {
                    value: "Sample Text"
                }
            }
        }

    };
    serviceModel.setRow = function(newElement){
      var newRow = {
        _id : generalsService.createObjectId(),
        parentId : newElement._id,
        templateId : newElement.templateId,
        style: {},
        order : 1,
        type : "row"
      };

      return newRow;
    };

    serviceModel.setHeaderCell = function(tableId, templateId, order){
      var newCell = {
        _id : generalsService.createObjectId(),
        parentId : tableId,
        templateId : templateId,
        style: {
          width : "50%",
        },
        order : order,
        type : "headercell"
      };

      return newCell;
    };

    serviceModel.setCell = function(row, order){
      var newCell = {
        _id : generalsService.createObjectId(),
        parentId : row._id,
        templateId : row.templateId,
        style: {
          height : "20px",
          width : "auto",
          borderTopStyle : 'solid',
          borderTopColor : '#000000',
          borderTopWidth : '0px',
          borderBottomStyle : 'solid',
          borderBottomColor : '#000000',
          borderBottomWidth : '0px',
          borderLeftStyle : 'solid',
          borderLeftColor : '#000000',
          borderLeftWidth : '0px',
          borderRightStyle : 'solid',
          borderRightColor : '#000000',
          borderRightWidth : '0px'
        },
        styleOptions : {
          BordersLocked : false
        },
        colSpan : 1,
        rowSpan : 1,
        order : order,
        type : "cell"
      };

      return newCell;
    };
    serviceModel.setNewElement = function(newElement, position, languages) {

        newElement._id = generalsService.createObjectId();
        newElement.name = newElement.type.toUpperCase() + "-" + newElement._id.substring(0, 5).toUpperCase();

        serviceModel.setNewElementStyle(newElement, position);
        serviceModel.setNewElementLanguages(newElement, languages);

        if(newElement.type === 'selectlist'){
          newElement.source = {};

          for(var i in languages){
              newElement.source[languages[i]] = [];
          }
        }
        return newElement;
    };

    serviceModel.createNewRow = function(referanceModel, method, elements){
      var model = {};
      model.row = angular.copy(referanceModel.row);
      model.row._id = generalsService.createObjectId();

      if(method == "after")
        model.row.order = parseFloat(referanceModel.row.order + "." + 1);
      else{
        model.row.order = model.row.order - 0.1;
      }

      for(var c in referanceModel.cells){
        var cell = referanceModel.cells[c];
        var newCell = angular.copy(cell);
        newCell._id = generalsService.createObjectId();
        newCell.parentId = model.row._id;
        elements.push(newCell);
      };

      elements.push(model.row);

    };

    serviceModel.createNewColumn = function(referanceModel, method, elements){
      var targeCellHeader = _.filter(elements, { parentId : referanceModel.selectedTable._id, type : 'headercell', order : referanceModel.selectedCellOrder})[0];

      var targetCellHeaderWidth = targeCellHeader.style.width.replace('%', '');
      targeCellHeader.style.width = (targetCellHeaderWidth / 2) + "%";

      var newCellHeader = angular.copy(targeCellHeader);
      newCellHeader._id = generalsService.createObjectId();

      if(method == "after")
        newCellHeader.order =  targeCellHeader.order + 0.1;
      else
        newCellHeader.order =  targeCellHeader.order - 0.1;

      elements.push(newCellHeader);

      var rows = _.filter(elements, { parentId : referanceModel.selectedRow.parentId, type : 'row'});

      for(var r in rows){
        var row = rows[r];
        var targetCell = _.filter(elements, { parentId : row._id, type : 'cell', order : referanceModel.selectedCellOrder})[0];

        var newCell = angular.copy(targetCell);
        newCell._id = generalsService.createObjectId();
        newCell.colSpan = 1;
        if(method == "after")
          newCell.order = targetCell.order + 0.1;
        else
          newCell.order = targetCell.order - 0.1;

        elements.push(newCell);
      }

    };

    serviceModel.getStyle = function(element, hasScale) {

        var elementStyle = element.ElementStyle;
        var style = getBaseStyle(element, hasScale);

        if (elementStyle.Size.IsWidthAuto)
            style.width = "auto";
        else {
            style.width = elementStyle.Size.Width + elementStyle.Size.WidthType;

            if (!angular.isUndefined(elementStyle.DimensionFactor) && angular.isUndefined(hasScale) === false && hasScale === true) {
                style.width = (elementStyle.Size.Width * elementStyle.DimensionFactor) + elementStyle.Size.WidthType;
            }
        }


        if (elementStyle.Size.IsHeightAuto)
            style.height = "auto";
        else {
            style.height = elementStyle.Size.Height + elementStyle.Size.HeightType;
            if (!angular.isUndefined(elementStyle.DimensionFactor) && angular.isUndefined(hasScale) === false && hasScale === true) {
                style.height = (elementStyle.Size.Height * elementStyle.DimensionFactor) + elementStyle.Size.HeightType;
            }
        }

        style.minHeight = elementStyle.Size.MinHeight + elementStyle.Size.HeightType;
        if (element.ParentType == 7) {
            style.position = "absolute";
            style.top = elementStyle.Position.YPos + "px";
            style.left = elementStyle.Position.XPos + "px";

            if (!angular.isUndefined(elementStyle.DimensionFactor) && angular.isUndefined(hasScale) === false && hasScale === true) {
                style.top = (elementStyle.Position.YPos * elementStyle.DimensionFactor) + "px";
                style.left = (elementStyle.Position.XPos * elementStyle.DimensionFactor) + "px";
            }

        } else if (element.ElementType == 6) {
            style.position = "relative";
        } else {
            style.position = "relative";
            style.width = "100%";
        }

        if (element.ElementType == 1 || element.ElementType == 3) //Text yada Table ise
            style.height = "auto";



        return style;
    };

    serviceModel.getTextValue = function(element, language) {

        var findRelated = $filter('filter')(element.ElementValue, {
            Language: language
        }, true)[0];

        if (element.ElementType === 13 && findRelated.Value.length === 0) {
            var sourceList = serviceModel.getSelectListSource(element, language);
            findRelated.Value = sourceList[0];
        } else if (element.ElementType == 13 && findRelated.Value.length) {
            var sourcedList = serviceModel.getSelectListSource(element, language);
            var founded = false;
            for (i = 0; i < sourcedList.length; i++) {
                if (findRelated.Value == sourcedList[i])
                    founded = true;
            }

            if (!founded)
                findRelated.Value = sourcedList[0];
        }
        return findRelated;
    };

    serviceModel.getInputValue = function(element, language) {
        var findRelated = $filter('filter')(element.ElementValue, {
            Language: language
        }, true)[0];


        if (element.ElementType === 13 && findRelated.Value.length === 0) {
            var sourceList = serviceModel.getSelectListSource(element, language);
            findRelated.Value = sourceList[0];
        } else if (element.ElementType == 13 && findRelated.Value.length) {
            var sourcedList = serviceModel.getSelectListSource(element, language);
            var founded = false;
            for (i = 0; i < sourcedList.length; i++) {
                if (findRelated.Value == sourcedList[i])
                    founded = true;
            }

            if (!founded)
                findRelated.Value = sourcedList[0];
        }

        return findRelated;
    };

    serviceModel.getTextInputValue = function(element, language) {
        var findRelated = $filter('filter')(element.ElementValue, {
            Language: language
        }, true)[0];
        if (element.ElementType === 13 && findRelated.Value.length === 0) {
            var sourceList = serviceModel.getSelectListSource(element, language);
            findRelated.Value = sourceList[0];
        } else if (element.ElementType == 13 && findRelated.Value.length) {
            var sourcedList = serviceModel.getSelectListSource(element, language);
            var founded = false;
            for (i = 0; i < sourcedList.length; i++) {
                if (findRelated.Value == sourcedList[i])
                    founded = true;
            }

            if (!founded)
                findRelated.Value = sourcedList[0];
        }

        return findRelated;
    };

    serviceModel.getInputIcon = function(element, language) {
        var findRelated = $filter('filter')(element.ElementValue, {
            Language: language
        }, true)[0];

        var response = "";
        if (findRelated.Value.length > 0)
            response = findRelated.Value;
        else if (findRelated.Value.length === 0 && angular.isUndefined(element.iconPack) === false && element.iconPack.length > 0)
            response = element.iconPack[0].icon;
        else
            response = "";

        return response;
    };

    serviceModel.getEditorInputIcon = function(element, language) {
        var findRelated = $filter('filter')(element.elementValue, {
            Language: language
        }, true)[0];

        var response = "";
        if (findRelated.Value.length > 0)
            response = findRelated.Value;
        else if (findRelated.Value.length === 0 && angular.isUndefined(element.iconPack) === false && element.iconPack.length > 0)
            response = element.iconPack[0].icon;
        else
            response = "";

        return response;
    };

    serviceModel.translate = function(element, language) {

        var requestModel = {};
        requestModel.ControlLanguage = language;
        requestModel.ControlText = $filter('filter')(element.ElementValue, {
            Language: language
        }, true)[0].Value;
        requestModel.TranslateList = [];

        angular.forEach(element.ElementValue, function(value, key) {
            var requestListModel = {};
            requestListModel.Language = value.Language;
            requestListModel.Text = value.Value;

            requestModel.TranslateList.push(requestListModel);

        });
        generalsService.translateTextFrom(requestModel).then(function(response) {
            angular.forEach(response.TranslateList, function(resElement, key) {
                var currentTextElement = $filter('filter')(element.ElementValue, {
                    Language: resElement.Language
                }, true)[0];
                currentTextElement.Value = resElement.Text;

                // ngNotify.set("Translation complete!", {
                //     type: "info"
                // });
            });
        });

    };

    serviceModel.setPrefPanePosition = function(htmlElement, scope) {
        var refElementId = "";
        var selectedElement = {};

        if (angular.isUndefined(scope.selectedElement) || angular.equals({}, scope.selectedElement))
            selectedElement = scope.focusedElement;
        else
            selectedElement = scope.selectedElement;

        if (selectedElement.ElementType != 6)
            refElementId = selectedElement.Id;
        else
            refElementId = selectedElement.TableId;

        var element = $('#' + refElementId);

        var elementPos = element.offset();

        var prefHeight = $(htmlElement).outerHeight();

        var prefLeft = elementPos.left;
        var prefTop = elementPos.top - prefHeight - 5;

        $(htmlElement).css('left', prefLeft + "px");

        if (selectedElement.ParentType == 7)
            $(htmlElement).css('top', prefTop + "px");
        else {
            // $(htmlElement).css('top', (prefTop - 32) + "px");
            $(htmlElement).css('top', prefTop + "px");
        }
        $(htmlElement).css("display", "block");
    };

    serviceModel.getSelectListSource = function(element, language) {
        var selectedSource = $filter('filter')(element.ElementValueSource, {
            Language: language
        }, true)[0];
        var list = [];

        if (selectedSource.Value.indexOf("<br />") > -1) {
            var splittedSource = selectedSource.Value.split('<br />');

            for (var i = 0; i < splittedSource.length; i++) {
                if (i > 0)
                    list.push(splittedSource[i].substring(0, splittedSource[i].length));
                else
                    list.push(splittedSource[i]);
            }

        } else
            list.push(selectedSource.Value);

        return list;
    };

    serviceModel.getSelectListSourceAsObjectList = function(element, language) {
        var selectedSource = $filter('filter')(element.ElementValueSource, {
            Language: language
        }, true)[0];
        var list = [];

        if (selectedSource.Value.indexOf("<br />") > -1) {
            var splittedSource = selectedSource.Value.split('<br />');

            for (var i = 0; i < splittedSource.length; i++) {
                if (i > 0)
                    list.push({
                        value: splittedSource[i].substring(0, splittedSource[i].length)
                    });
                else
                    list.push({
                        value: splittedSource[i]
                    });
            }

        } else
            list.push({
                value: selectedSource.Value
            });

        return list;
    };

    serviceModel.getStyleSelectList = function(element, hasScale) {
        var elementStyle = element.ElementStyle;
        var style = getBaseStyle(element, hasScale);
        style.border = "0px solid #000";
        style.width = "100%";
        style.height = "auto";
        return style;
    };

    serviceModel.findElement = function(elements, id) {
        var result = $filter('filter')(elements, {
            Id: id
        }, true)[0];

        if (!angular.isUndefined(result) && result !== null) {
            return result;

        } else {
            for (var i = 0; i < elements.length; i++) {
                var currentElement = elements[i];

                if (currentElement.ElementType == 3) {

                    for (var iRow = 0; iRow < currentElement.TableRows.length; iRow++) {

                        var currentRow = currentElement.TableRows[iRow];

                        for (var iCell = 0; iCell < currentRow.Cells.length; iCell++) {
                            var currentCell = currentRow.Cells[iCell];
                            if (id == currentCell.Id)
                                return currentCell;
                            result = serviceModel.findElement(currentCell.Elements, id);

                            if (!angular.isUndefined(result) && result !== null)
                                return result;
                        }

                    }

                }


                if (elements[i].Elements !== null && elements[i].Elements.length > 0) {
                    result = serviceModel.findElement(elements[i].Elements, id);
                    if (!angular.isUndefined(result) && result !== null)
                        return result;
                }
            }
        }
        return null;
    };

    serviceModel.getPageStyle = function(page) {

        if (angular.isUndefined(page)) return;
        var style = {};
        var elementStyle = page.PageStyle;
        if (!angular.isUndefined(elementStyle.BackgroundImage)) {
            style.backgroundImage = "url('" + elementStyle.BackgroundImage + "')";
            style.backgroundRepeat = elementStyle.BackgroundRepeat;
            style.backgroundSize = elementStyle.BackgroundSize;

            if (angular.isUndefined(elementStyle.BackgroundPosition) || elementStyle.BackgroundPosition === "")
                elementStyle.BackgroundPosition = "left top";

            style.backgroundPosition = elementStyle.BackgroundPosition;
        }

        if (!angular.isUndefined(elementStyle.BackgroundColor)) {
            style.backgroundColor = elementStyle.BackgroundColor;
        }


        return style;
    };

    serviceModel.getBoxContiner = function(element) {
        if (angular.isUndefined(element)) return;

        var style = getBaseStyle(element);
        var elementStyle = element.ElementStyle;
        style.backgroundImage = "none";
        style.backgroundColor = "transparent";
        style.width = "100%";
        style.height = "100%";
        style.borderWidth = "0px";
        style.margin = "0px";
        style.padding = "0px";


        return style;
    };

    serviceModel.calculateElementPositions = function(tdsElement, growing) {
        var position = tdsElement.position();
        var height = tdsElement.outerHeight();
        var width = tdsElement.outerWidth();

        var rightBorder = position.left + width;
        var leftBorder = position.left;
        var topBorder = position.top;
        var bottomBorder = position.top + height;


        var elementList = $.map($(".element").not(tdsElement), computeElementPositionsRelated);


        for (var i = 0; i < elementList.length; i++) {
            var currentElement = elementList[i];
            var xpos = parseInt($(currentElement.htmlElement).attr('xpos'));
            var ypos = parseInt($(currentElement.htmlElement).attr('ypos'));
            var currentElementTopBorder = $(currentElement.htmlElement).position().top;

            if (growing && currentElement.topBorder < bottomBorder && currentElement.bottomBorder > topBorder && currentElement.rightBorder > leftBorder && currentElement.leftBorder < rightBorder) {

                $(currentElement.htmlElement).css('top', bottomBorder + "px");

                serviceModel.calculateElementPositions($(currentElement.htmlElement), growing);
            } else if (growing === false && currentElementTopBorder != ypos) {

            }



        }
    };

    serviceModel.iconList = function() {

        // var deferred = $q.defer();
        // var res = $http.get("/app/json/icon.json");
        // res.success(function(data, status, headers, config) {
        //     var newList = [];

        //     for (var i = 0; i < data.icons.length; i++) {
        //         var currentIcon = data.icons[i];

        //         var newItem = {
        //             categoryId: 5,
        //             order: i,
        //             name: currentIcon.properties.name,
        //             icon: 'tools-' + currentIcon.properties.name
        //         };

        //         newList.push(newItem);
        //     }

        //     localStorageService.set('test', {
        //         data: newList
        //     })
        // });



        var deferred = $q.defer();
        var res = $http.get("/app/components/template/editTemplate/json/icons.json");

        res.success(function(data, status, headers, config) {
            deferred.resolve(data);
        });

        res.error(function(msg, code) {
            deferred.reject(msg);
        });

        return deferred.promise;
    };

    serviceModel.getPanelElements = function(languages) {
        var deferred = $q.defer();
        var res = $http.get("/app/json/readyStyleElements.json");

        res.success(function(data, status, headers, config) {

            for (var iElement = 0; iElement < data.length; iElement++) {

                var realElement = data[iElement].realElement;

                if (!angular.isUndefined(realElement)) {


                    if (realElement.ElementType == 3) {
                        realElement.Id = generalsService.createGUID();

                        for (var iRow = 0; iRow < realElement.TableRows.length; iRow++) {

                            var currentRow = realElement.TableRows[iRow];
                            currentRow.Id = generalsService.createGUID();

                            for (var iCell = 0; iCell < currentRow.Cells.length; iCell++) {

                                var currentCell = currentRow.Cells[iCell];
                                currentCell.Id = generalsService.createGUID();
                                currentCell.TableId = realElement.Id;

                                for (var iSubElement = 0; iSubElement < currentCell.Elements.length; iSubElement++) {
                                    var currentElement = currentCell.Elements[iSubElement];
                                    currentElement.Id = generalsService.createGUID();
                                    currentElement.ParentId = currentCell.Id;

                                    var enValue = $filter('filter')(currentElement.ElementValue, {
                                        Language: "en"
                                    }, true)[0];


                                    for (var iLanguage = 0; iLanguage < languages.length; iLanguage++) {
                                        var currentLanguage = languages[iLanguage];

                                        if (currentLanguage != "en") {
                                            currentElement.ElementValue.push({
                                                Language: currentLanguage,
                                                Value: enValue.Value
                                            });
                                        }

                                    }
                                }

                            }
                        }
                    } else {

                        var enValue2 = $filter('filter')(realElement.ElementValue, {
                            Language: "en"
                        }, true)[0];


                        for (var iLang = 0; iLang < languages.length; iLang++) {
                            var currentLang = languages[iLang];

                            if (currentLang != "en") {
                                realElement.ElementValue.push({
                                    Language: currentLang,
                                    Value: enValue2.Value
                                });
                            }

                        }

                    }
                }
            }

            deferred.resolve(data);
        });

        res.error(function(msg, code) {
            deferred.reject(msg);
        });

        return deferred.promise;
    };

    serviceModel.checkIsAvaibleForAdd = function(targetType, elementType) {

        if (elementType === 0)
            return false;

        if (targetType == 25 && elementType == 25)
            return false;


        if (targetType == 6) {
            switch (elementType) {
                case 25:
                    return false;
                case 3:
                    return false;

            }
        }

        return true;

    };

    serviceModel.getAllElements = function(elementSource) {
        var list = [];

        for (var iElement = 0; iElement < elementSource.Elements.length; iElement++) {
            var element = elementSource.Elements[iElement];

            if (element.ElementType == 3) { //Table
                for (var iRow = 0; iRow < element.TableRows.length; iRow++) {
                    var row = element.TableRows[iRow];

                    for (var iCell = 0; iCell < row.Cells.length; iCell++) {
                        var cell = row.Cells[iCell];

                        var elementList = serviceModel.getAllElements(cell);
                        list = list.concat(elementList);
                    }
                }
            } else {
                if (!angular.isUndefined(element.Elements) && element.Elements.length > 0) {
                    var elementList2 = serviceModel.getAllElements(element);
                    list = list.concat(elementList2);
                } else
                    list.push(element);
            }

        }

        return list;
    };
    serviceModel.getInlineStyle = function(element) {
        var style = {};
        var elementStyle = element.elementStyle;

        if (angular.isUndefined(elementStyle)) return;

        if (!angular.isUndefined(elementStyle.Paddings) && elementStyle.Paddings.length > 0) {
            style.paddingTop = elementStyle.Paddings[0].PaddingPx;
            style.paddingLeft = elementStyle.Paddings[1].PaddingPx;
            style.paddingBottom = elementStyle.Paddings[2].PaddingPx;
            style.paddingRight = elementStyle.Paddings[3].PaddingPx;
        }

        if (!angular.isUndefined(elementStyle.BackgroundColor) && elementStyle.BackgroundColor.length > 0)
            style.backgroundColor = elementStyle.BackgroundColor;


        if (!angular.isUndefined(elementStyle.BackgroundImage) && elementStyle.BackgroundImage.length > 0) {
            style.backgroundImage = "url('" + elementStyle.BackgroundImage + "')";
            style.backgroundPosition = elementStyle.BackgroundPosition;
            style.backgroundSize = elementStyle.BackgroundSize;
            style.backgroundRepeat = elementStyle.BackgroundRepeat;
        }

        if (!angular.isUndefined(elementStyle.Borders) && elementStyle.Borders.length > 0) {
            style.borderTop = elementStyle.Borders[0].Width + "px " + elementStyle.Borders[0].Style + " " + elementStyle.Borders[0].Color;
            style.borderLeft = elementStyle.Borders[1].Width + "px " + elementStyle.Borders[1].Style + " " + elementStyle.Borders[1].Color;
            style.borderBottom = elementStyle.Borders[2].Width + "px " + elementStyle.Borders[2].Style + " " + elementStyle.Borders[2].Color;
            style.borderRight = elementStyle.Borders[3].Width + "px " + elementStyle.Borders[3].Style + " " + elementStyle.Borders[3].Color;
        }

        if (element.type == "inputtext") {
            style.fontFamily = elementStyle.FontStyle.FontFamily;
            style.fontSize = elementStyle.FontStyle.FontSize + elementStyle.FontStyle.FontSizeType;
            style.lineHeight = elementStyle.FontStyle.LineHeight + elementStyle.FontStyle.LineHeightType;
            style.color = elementStyle.FontStyle.ForeColor;
            style.fontWeight = elementStyle.FontStyle.FontWeight ? 'bold' : 'normal';
            style.textDecoration = elementStyle.FontStyle.TextDecoration;
            style.fontStyle = elementStyle.FontStyle.FontVisibleStyle ? 'italic' : 'normal';
        }

        return style;
    };

    serviceModel.getContainerStyle = function(element) {
        var style = {};
        var elementStyle = element.elementStyle;

        if (element.isDraggable) {
            style.position = "absolute";
            if (elementStyle.Size.IsWidthAuto)
                style.width = "auto";
            else
                style.width = elementStyle.Size.Width + elementStyle.Size.WidthType;

            if (elementStyle.Size.IsHeightAuto)
                style.height = "auto";
            else
                style.height = elementStyle.Size.Height + elementStyle.Size.HeightType;

            style.top = elementStyle.Position.YPos + "px";
            style.left = elementStyle.Position.XPos + "px";
        } else {
            if (element.type == "box")
                style.height = elementStyle.Size.Height;

            if (element.type == "icon" || element.type == "inputicon" || element.type == "image" || element.type == "inputimage") {
                style.height = elementStyle.Size.Height;
                style.width = elementStyle.Size.Width;

                if (elementStyle.Position.Aligment == "center") {
                    style.marginLeft = "auto";
                    style.marginRight = "auto";
                } else if (elementStyle.Position.Aligment == "right") {
                    style.marginLeft = "auto";
                    style.marginRight = "0px";
                }
            }

        }

        //Inline elementte margin yerine dışındaki container'a padding veriyoruz görünüşte aynı ama diğeri ekranı bozuyor
        if (!angular.isUndefined(elementStyle.Margins) && elementStyle.Margins.length > 0) {

            style.paddingTop = elementStyle.Margins[0].MarginPx;
            style.paddingLeft = elementStyle.Margins[1].MarginPx;
            style.paddingBottom = elementStyle.Margins[2].MarginPx;
            style.paddingRight = elementStyle.Margins[3].MarginPx;

        }

        return style;
    };


    serviceModel.getInnerElementStyle = function(element) {
        var elementStyle = element.ElementStyle;
        var style = getBaseStyle(element, false);
        return style;
    };

    serviceModel.newElement = function(request) {
        var deferred = $q.defer();

        var res = $http.get("/app/components/template/editTemplate/json/elementTypes.json");

        res.success(function(data, status, headers, config) {

            var jsonData = $filter('filter')(data, {
                type: request.elementType
            }, true)[0];
            var model = {};
            model.id = generalsService.createGUID();
            model.type = request.elementType;
            model.order = request.order;
            model.elementValue = [];
            if (!angular.isUndefined(jsonData.values)) {
                for (var i = 0; i < request.languages.length; i++) {
                    var currentLanguage = request.languages[i];
                    var currentLanguageValue = $filter('filter')(jsonData.values, {
                        Language: currentLanguage
                    }, true)[0];
                    model.elementValue.push(currentLanguageValue);
                }
            }

            if (model.type == "selectlist") {
                model.selectListItems = jsonData.selectListItems;
                model.selectListItemSource = jsonData.selectListItemSource;
            }


            if (model.type == "table")
                model.body = jsonData.body;

            if (!angular.isUndefined(jsonData.elements))
                model.elements = jsonData.elements;

            model.hideElement = false;
            model.elementStyle = jsonData.style;
            model.isInput = jsonData.isInput;

            if (angular.isUndefined(model.elementStyle.Size))
                model.elementStyle.Size = {};

            model.builderElement = request.builderElement;
            deferred.resolve(model);
        });

        res.error(function(msg, code) {
            deferred.reject(msg);
        });

        return deferred.promise;
    };

    serviceModel.setStyle = function(element) {
        var elementStyle = element.elementStyle;
        var style = {};
        style.top = elementStyle.Position.YPos;
        style.left = elementStyle.Position.XPos;
        style.width = elementStyle.Size.Width;
        style.height = elementStyle.Size.Height;
        return style;
    };

    serviceModel.getEditorTextInputValue = function(element, language) {
        var findRelated = $filter('filter')(element.elementValue, {
            Language: language
        }, true)[0];
        if (element.type === 'selectlist' && findRelated.Value.length === 0) {
            var sourceList = serviceModel.getSelectListSource(element, language);
            findRelated.Value = sourceList[0];
        } else if (element.ElementType == 'selectlist' && findRelated.Value.length) {
            var sourcedList = serviceModel.getSelectListSource(element, language);
            var founded = false;
            for (i = 0; i < sourcedList.length; i++) {
                if (findRelated.Value == sourcedList[i])
                    founded = true;
            }

            if (!founded)
                findRelated.Value = sourcedList[0];
        }

        return findRelated;
    };

    serviceModel.getEditorInputValue = function(element, language) {
        var findRelated = $filter('filter')(element.elementValue, {
            Language: language
        }, true)[0];

        if (element.type === 'selectlist' && findRelated.Value.length === 0) {
            var sourceList = serviceModel.getSelectListSource(element, language);
            findRelated.Value = sourceList[0];
        } else if (element.type == 'selectlist' && findRelated.Value.length) {
            var sourcedList = serviceModel.getSelectListSource(element, language);
            var founded = false;
            for (i = 0; i < sourcedList.length; i++) {
                if (findRelated.Value == sourcedList[i])
                    founded = true;
            }

            if (!founded)
                findRelated.Value = sourcedList[0];
        }


        return findRelated;
    };

    serviceModel.editorUploadImage = function(element) {
        var imgBlob;

        var jsImageOnLoad = function() {
            var height = 0;
            var width = 0;
            if (this.height > 100) {
                var ratio = this.width / this.height;
                height = 100;
                width = 100 * ratio;
            } else {
                height = this.height;
                width = this.width;
            }
            element.elementStyle.Size.Width = width;
            element.elementStyle.Size.Height = height;


            for (var i = 0; i < element.elementValue.length; i++) {

                var currentValueElement = element.elementValue[i];
                currentValueElement.Value = imgBlob.url;

            }
            if (!$rootScope.$$phase)
                $rootScope.$apply();
        };

        var imageUploadSuccess = function(blob) {
            var img = new Image();
            imgBlob = blob;
            img.onload = jsImageOnLoad;
            img.src = blob.url;
        };

        filepickerService.pick({
                mimetype: 'image/*',
                services: ['COMPUTER', 'GOOGLE_DRIVE', 'SKYDRIVE', 'DROPBOX', 'EVERNOTE', 'BOX', 'GMAIL']
            },
            imageUploadSuccess
        );

    };

    serviceModel.getRealFontFamilyName = function(fontFamilyName) {
        switch (fontFamilyName) {
            case "AgencyFB-Reg":
                return "AgencyFB-Reg";
            case "Arial, Helvetica, sans-serif":
                return "Arial";
            case "'Arial Black', Gadget, sans-serif":
                return "Arial Black";
            case "ArialNarrow":
                return "Arial Narrow";
            case 'Calibri':
                return 'Calibri';
            case "'Courier New', Courier, monospace":
                return 'Courier New';
            case 'Tahoma, Geneva, sans-serif':
                return 'Tahoma';
            case "'Times New Roman', Times, serif":
                return 'Times New Roman';
            case 'Verdana, Geneva, sans-serif':
                return 'Verdana';


            case 'FuturaNewLight-Reg':
                return 'Futura New Light';
            case 'FuturaNewBook-Reg':
                return 'Futura New Book';
            case 'FuturaNewMedium-Reg':
                return 'Futura New Medium';
            case 'FuturaNewDemi-Reg':
                return 'Futura New Demi';
            case 'FuturaNewHeavy-Reg':
                return 'Futura New Heavy'
            case 'FuturaNewBold-Reg':
                return 'Futura New Bold';
            case 'HelveticaNeueLTPro-Lt':
                return 'Helvetica Neue';
            case 'Avenir-Black':
                return 'Avenir Black';
            case "'Lato', sans-serif":
              return 'Lato';
          case "'Open Sans', sans-serif":
            default:
                return "Arial";
        }
    };
    //Private Functions - End

    return serviceModel;
};

module.exports = function(app) {
    app.service('elementService', ['$http', '$q', '$filter', 'generalsService', 'localStorageService', 'filepickerService', '$rootScope', elementService]);
};
