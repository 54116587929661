var datasheetHeader = function ($compile, datasheetViewService) {
    return {
        restrict: 'E',
        replace: true,
        templateUrl: 'app/components/datasheet/shared/directives/datasheetHeader/datasheetHeader.html',
        link: function (scope, elem, attrs) {
            //Fields 
            //Fields End

            //Inner Functions
            var init = function () {
                setHeaderElements();
                setHeaderStyle();
            };

            var setHeaderElements = function () {
                var headerId = scope.header._id;
                var elements = _.filter(scope.templateModel.elements, {
                    "parentId": headerId
                });

                elements = _.sortBy(elements, 'order');

                for (var i = 0, len = elements.length; i < len; i++) {
                    var element = elements[i];
                    var elementHtml = datasheetViewService.createDatasheetElement(element.type);

                    var childScope = scope.$new();
                    childScope.element = element;
                    $(elem).append($compile(elementHtml)(childScope));
                }
            };

            var setHeaderStyle = function () {
                elem.css(scope.header.style);
            };
            //Inner Functions End

            //Initials 
            init();
            //Initials End





        }
    };
};

module.exports = function (app) {
    app.directive('datasheetHeader', ['$compile', 'datasheetViewService', datasheetHeader]);
};