var pdfPage = function ($compile, pdfViewService) {
    return {
        restrict: 'E',
        replace: true,
        templateUrl: 'app/components/pdf/shared/directives/pdfPages/tpl/pdfPage.html',
        link: function (scope, elem, attrs) {
            //Fields
            var containerElement = elem;
            //Fields End

            //Elements
            // var element = scope.page;
            // var datasheetElement = datasheetElementService.getDatasheetElement(scope.datasheetModel, element);
            //Elements End

            //Inner Functions
            var setHeaderBodyAndFooter = function () {
                
                //Header
                if (scope.page.isHeaderEnabled) {
                    var headerId = scope.page.headerId;
                    var header = _.find(scope.pdfModel.elements, {
                        _id: headerId
                    });

                    var headerHtml = pdfViewService.createPdfHeader();
                    var headerChildScope = scope.$new();
                    headerChildScope.header = header;
                    $(elem).prepend($compile(headerHtml)(headerChildScope));
                }

                //Body
                var bodyHtml = pdfViewService.createPdfBody();
                var bodyChildScope = scope.$new();
                if (scope.page.isHeaderEnabled) {
                    $(elem).find("pdf-header").after($compile(bodyHtml)(bodyChildScope));
                }
                else {
                    $(elem).prepend($compile(bodyHtml)(bodyChildScope));
                }

                //Footer
                if (scope.page.isFooterEnabled) {
                    var footerId = scope.page.footerId;
                    var footer = _.find(scope.pdfModel.elements, {
                        _id: footerId
                    });


                    var footerHtml = pdfViewService.createPdfFooter();
                    var footerChildScope = scope.$new();
                    footerChildScope.footer = footer;
                    $(elem).find("pdf-body").after($compile(footerHtml)(footerChildScope));
                }
            };

            var setPageStyle = function () {
                if (scope.page.style != null && scope.page.style != undefined)
                    containerElement.css(scope.page.style);
            };

            var setFreeElements = function () {
                var pageId = scope.page._id;
                var freeElements = _.filter(scope.pdfModel.elements, {
                    "pageId": pageId,
                    "isFree": true
                });

                for (var i = 0, len = freeElements.length; i < len; i++) {
                    var element = freeElements[i];
                    var elementHtml = pdfViewService.createPdfElement(element.type);

                    var childScope = scope.$new();
                    childScope.element = element;
                    $(elem).append($compile(elementHtml)(childScope));
                }
            };
            //Inner Functions End

            //Handlers
            //Handlers End

            //Initials
            setHeaderBodyAndFooter();
            setFreeElements();
            setPageStyle();
            //Initials End
        }
    }
};

module.exports = function (app) {
    app.directive('pdfPage', ['$compile', 'pdfViewService', pdfPage]);
};