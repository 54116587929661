var allPermissionLevelsCtrl = function ($scope, $timeout, $modal, menuToggleFactory, permissionFactory, intercomFactory) {
    //Fields
    //Fields End

    //Events
    $scope.$on('$viewContentLoaded', function () { });
    //Events End

    //Functions
    $scope.openDeletePermissionLevelModal = function (accountPermissionLevelId) {
        var modalInstance = $modal.open({
            animation: false,
            templateUrl: 'app/components/permissions/allPermissionLevels/views/modals/deletePermissionLevel.html',
            controller: 'deletePermissionLevelCtrl',
            resolve: {
                accountPermissionLevelId: function () {
                    return accountPermissionLevelId;
                }
            }
        });

        modalInstance.result
            .then(function (response) {
                if (response.status) {
                    getAllAccountPermissionLevels();
                }
            });
    };
    //Functions End

    //Inner Functions
    var getAllAccountPermissionLevels = function () {
        $scope.tdsMakerPromise.allPermissionLevels = {};
        $scope.tdsMakerPromise.allPermissionLevels.promise = permissionFactory.getAllAccountPermissionLevels()
            .then(function (response) {
                $scope.permissionLevels = response;

            });
    };
    //Inner Functions End

    //Initials
    getAllAccountPermissionLevels();
    intercomFactory.addEvent({
        event_name: "Permission Leves Visited"
    });
    //Initials End
};

module.exports = function (app) {
    app.controller('allPermissionLevelsCtrl', ['$scope', '$timeout', '$modal', 'menuToggleFactory', 'permissionFactory', 'intercomFactory', allPermissionLevelsCtrl]);
};
