var accountsCtrl = function($scope) {
    //Fields
    //Fields-End

    //Functions
    //Functions End

    //Inner Functions
    //Inner Functions End

    //Initials
    //Initials-End
};

module.exports = function(app) {
    app.controller('accountsCtrl', ['$scope', accountsCtrl]);
};
