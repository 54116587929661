var folderFactory = function($filter, $translate, httpProvider, publicApiProvider, $q, _) {
    //Fields
    var factoryModel = {};
    //Fields - End

    //Functions
    factoryModel.getFolders = function(query){
        return publicApiProvider.get('/folders', query);
    };

    factoryModel.getTemplateFolders = function() {
        return httpProvider.get('folder', 'getAccountTemplateFolders');
    };

    factoryModel.getDatasheetFolders = function() {
        return httpProvider.get('folder', 'getAccountDatasheetFolders');
    };

    factoryModel.getFolderById = function(folderId) {
        var params = "folderId=" + folderId;
        return httpProvider.get('folder', 'getFolderById', params);
    };

    factoryModel.createNewFolder = function(model) {
        return httpProvider.post('folder', 'createNewFolder', model);
    };

    factoryModel.getFolderPath = function(folderList, folderId) {
        
        //Bunu kapatırsın yeni apide
        var folders = [];

        for(var i in folderList){
            var folder = folderList[i];

            var newFolder = {};
            for(var field in folder){
                var newField = field.substr(0, 1).toLowerCase() + field.substr(1, field.length);
                newFolder[newField] = folder[field];
            }

            folders.push(newFolder);
        }

        var folder = $filter('filter')(folders, {
            "_id": folderId
        })[0];

        if (folder.parent == "#") {
            return "/" + $translate.instant("DATASHEET.NEWDATASHEET.LBL_ARCHIVE.TEXT") + "";
        } else {
            return factoryModel.getFolderPath(folders, folder.parent) + "/" + folder.name;
        }
    };

    factoryModel.deleteFolder = function(folderId) {
        return httpProvider.delete('folder', 'deleteFolder', folderId);
    };

    factoryModel.changeFolderName = function(folderId, folderName) {
        var model = {};
        model.folderId = folderId;
        model.folderName = folderName;

        return httpProvider.put('folder', 'changeFolderName', model);
    };

    factoryModel.moveFolder = function(destinationFolderId, targetFolderId) {
        var model = {};
        model.destinationFolderId = destinationFolderId;
        model.targetFolderId = targetFolderId;

        return httpProvider.put('folder', 'moveFolder', model);
    };

    factoryModel.getArchiveFolders = function(customArchiveId) {
        return httpProvider.get('folder', 'getArchiveFolders', 'customArchiveId=' + customArchiveId);
    };
    //Functions End



    return factoryModel;
};

module.exports = function(app) {
    app.factory('folderFactory', ['$filter', '$translate', 'httpProvider', 'publicApiProvider', '$q', '_', folderFactory]);
};
