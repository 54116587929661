var userOptionsInHeaderCtrl = function($rootScope, $scope, $state, $modal, locales, userFactory, intercomFactory, localStorageService) {
    //Fields
    var languageCode = $rootScope.activeLang;
    $scope.mode = "list";
    //Fields End

    //Events
    $rootScope.$on('loadedUserInformation', function(event, data) {
        var user = data.user;

        $scope.userId = user._id;
        $scope.firstName = user.FirstName;
        $scope.profilePicture = user.ProfilePicture;
        if (user.ProfilePicture == null || angular.isUndefined(user.ProfilePicture)) {
            $scope.profilePicture = "assets/images/default-user.png";
        }
    });

    $rootScope.$on('changedProfilePicture', function(event, data) {
        var profilePicture = data.profilePicture;
        var user = localStorageService.get('userInformationData');
        user.ProfilePicture = profilePicture;
        localStorageService.set('userInformationData', user);

        $scope.profilePicture = profilePicture;

    });
    //Events End

    //Functions
    $scope.getIconCode = function(locale) {
        if (locale.LanguageCode.toLowerCase() == 'en') {
            return 'us'
        } else {
            return locale.LanguageCode.toLowerCase();
        }
    };

    $scope.openChangePasswordModal = function(userId) {
        $scope.closeMenu();
        var modalInstance = $modal.open({
            animation: false,
            controller: 'changePasswordCtrl',
            templateUrl: 'app/components/users/shared/views/modals/changePassword.html',
            backdrop: 'static',
            resolve: {
                userId: function() {
                    return userId;
                }
            }
        });

        modalInstance.result
            .then(function(result) {
                if (result.status) {

                }
            });
    };

    $scope.closeMenu = function() {
        $('#userOptionsInHeader').removeClass('open');
    };

    $scope.changeLanguage = function (language) {
        $rootScope.activeLang = language.LanguageCode;
        $rootScope.$emit('changedLanguage', {
            languageCode: language.LanguageCode
        });
        
        userFactory.changeLanguage(language.LanguageCode)
        .then(function (response) {
                $scope.closeMenu();
                var userData = localStorageService.get('userInformationData');
                userData.Language = language.LanguageCode;
                localStorageService.set('userInformationData', userData);

                var hash = CryptoJS.HmacSHA256(userData._id, "O7B-0Ex_OM-j-GINwCn7Ok9qIoTykVSntBm4PzRM");
                var base64 = hash.toString();

                intercomFactory.updateUser({
                    user_id: userData._id,
                    custom_attributes: {
                        language: userData.Language
                    }
                });

                $state.go($state.current, {
                    lang: language.LanguageCode
                }, {
                        reload: true
                    });
            });
    };

    $scope.logOut = function() {
        $('#userOptionsInHeader').removeClass('open');
        $rootScope.$emit('logout', {});
    };

    //Functions End

    //Initials
    var user = localStorageService.get('userInformationData');
    if (user) {
        $scope.userId = user._id;
        $scope.firstName = user.FirstName;
        $scope.profilePicture = user.ProfilePicture;
        if (user.ProfilePicture == null || angular.isUndefined(user.ProfilePicture)) {
            $scope.profilePicture = "assets/images/default-user.png";
        }
    }

    $scope.mainLocales = [];
    $scope.additionalLocales = [];
    for (var i = 0; i < locales.length; i++) {
        var locale = locales[i];

        if (locale.Main || locale.LanguageCode == languageCode) {
            $scope.mainLocales.push(locale);
        } else {
            $scope.additionalLocales.push(locale);
        }
    }

    $(document).on('click', '#userOptionsInHeader .dropdown-menu', function (e) {
        e.stopPropagation();
    });
    //Initials End
};

module.exports = function(app) {
    app.controller('userOptionsInHeaderCtrl', ['$rootScope', '$scope', '$state', '$modal', 'locales', 'userFactory', 'intercomFactory', 'localStorageService', userOptionsInHeaderCtrl]);
};
