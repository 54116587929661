var publicApiProvider = function ($http, $q, config, environment) {

    var publicApiUrl = environment.PUBLIC_API_URL || config.public_api[config.mode].url;

    this.get = function (route, query) {
        var deferred = $q.defer();
        var url = publicApiUrl + route;

        if (query !== undefined)
            url += query;

        var res = $http.get(url);

        res.success(function (data, status, headers, config) {
            deferred.resolve(data);
        });

        res.error(function (msg, code) {
            deferred.reject(msg);
        });

        return deferred.promise;
    };
};

module.exports = function (app) {
    app.service('publicApiProvider', ['$http', '$q', 'config', 'environment', publicApiProvider]);
};
