var newPlanCtrl = function ($rootScope, $scope, $state, $stateParams, $interval, $translate, localStorageService, subscriptionFactory, accountService, generalsService, _) {
    //Fields 
    var userInformationData = localStorageService.get('userInformationData');

    $scope.model = {
        currentPlan: {},
        account: {},
        newPlan: {
            package: {},
            month: 12,
            isAnnual: true,
            packagePrice: 0,
            discountedPackagePrice: 0,
            itemPrice: 0,
            discount: 0,
            payBack: 0,
            totalPrice: 0
        },
        shippingAddress: {
            title: "",
            country: "",
            city: "",
            address: ""
        },
        billingAddress: {
            title: "",
            vat: "",
            country: "",
            city: "",
            address: ""
        },
        payment: {
            iyzicoResponse: {}
        },
        useSingleAddress: true
    };

    $scope.loaded = {
        iyzicoCheckoutForm: false
    };
    //Fields End

    //Inner Functions
    var init = function () {
        $scope.currentStep = 1;

        //New Plan sayfasına eğer 'selectedPlan' seçilmeden gelindiyse kullanıcıyı plan seçmesi için changePlan sayfasına yönlendir.
        if ($stateParams.selectedPlan == undefined || $stateParams.selectedPlan == null) {
            $state.go('app.changePlan', {
                lang: $rootScope.activeLang
            }, {
                    reload: true
                });
        } else {
            $scope.model.newPlan.package = $stateParams.selectedPlan.package;
            
            if ($scope.model.newPlan.isAnnual) {
                $scope.model.newPlan.packagePrice = $scope.model.newPlan.package.price * $scope.model.newPlan.month;
                $scope.model.newPlan.discountedPackagePrice = $scope.model.newPlan.package.priceDiscounted * $scope.model.newPlan.month;
                $scope.model.newPlan.discount = $scope.model.newPlan.packagePrice - $scope.model.newPlan.discountedPackagePrice;
            }
            else {
                $scope.model.newPlan.packagePrice = $scope.model.newPlan.package.price * $scope.model.newPlan.month;
                $scope.model.newPlan.discountedPackagePrice = $scope.model.newPlan.package.priceDiscounted * $scope.model.newPlan.month;
                $scope.model.newPlan.discount = 0;
            }


            accountService.getAccount(userInformationData.AccountId._id)
                .then(function (response) {
                    $scope.model.account = response;
                    loadShippingAddres();
                    loadBillingAddres();

                    if ($scope.model.shippingAddress.address != $scope.model.billingAddress.address)
                        $scope.model.useSingleAddress = false;
                });

            subscriptionFactory.getAccountSubscription()
                .then(function (response) {
                    $scope.model.currentPlan = response;

                    var monthlyPrice = response.totalPaid == 0 ? 0 : response.totalPaid / response.month;
                    var month = monthDiff($scope.model.currentPlan.endDate);
                    var payBack = parseFloat(parseFloat(monthlyPrice * month).toFixed(2));

                    if (payBack >= $scope.model.newPlan.package.price) {
                        $scope.onlyAnnual = true;
                    }

                    $scope.model.newPlan.payBack = payBack;
                    $scope.model.newPlan.totalPrice = parseFloat(parseFloat($scope.model.newPlan.packagePrice - $scope.model.newPlan.discount - $scope.model.newPlan.payBack).toFixed(2));
                });

            subscriptionFactory.getCurrencyInfo()
                .then(function (currencyInfo) {
                    $scope.currencyInfo = currencyInfo;
                });
        }
    };

    var step3Init = function () {
        var conversationId = generalsService.createGUID();
        var basketId = $scope.model.newPlan.package.name.toUpperCase() + "-" + conversationId.substring(0, 8).toUpperCase();

        var request = {
            currencyInfo: $scope.currencyInfo,
            subscriptionModel: $scope.model,
            iyzicoRequest: {
                conversationId: conversationId,
                basketId: basketId,
                totalPrice: $scope.model.newPlan.totalPrice,
                currency: $scope.model.newPlan.package.currency.toUpperCase(),
                planName: $scope.model.newPlan.package.name,
                planId: $scope.model.newPlan.package._id,
                planType: $scope.model.newPlan.isAnnual ? "Annually" : "Monthly",
                language: $rootScope.activeLang.toUpperCase(),
                billingAddress: $scope.model.billingAddress,
                shippingAddress: $scope.model.shippingAddress
            }
        }
        console.log(request);
        subscriptionFactory.createIyzicoCheckoutForm(request)
            .then(function (response) {
                console.log(response);
                if (response.status == "success") {
                    //İyzico CheckoutForm daha önce hiç yüklenmediyse,
                    if (!$scope.loaded.iyzicoCheckoutForm) {
                        //İyziInit fonksiyonu daha önceden eklendiyse siliyoruz ki yeni bir content ile bastan oluşturulabilsin.
                        iyziInit = undefined;
                        $("#iyzipay-checkout-form").html(response.checkoutFormContent);

                        var changePayButtonTextInterval;
                        changePayButtonTextInterval = $interval(function () {
                            var $originalPayButton = $("#originalPayButton");
                            if ($originalPayButton.length > 0) {
                                var originalPayButtonText = $translate.instant("SUBSCRIPTION.NEW_PLAN.WIZARD.STEP_3.BTN_PAY.TEXT", {
                                    totalPrice: $scope.model.newPlan.totalPrice
                                });

                                $originalPayButton.html(originalPayButtonText);
                                $scope.loaded.iyzicoCheckoutForm = true;

                                $interval.cancel(changePayButtonTextInterval);
                            }
                        }, 100);
                    }
                }
            });
    };

    var monthDiff = function (endDate) {
        var mCurrentDate = moment();
        var mEndDate = moment(endDate);
        var monthDiff = mEndDate.diff(mCurrentDate, 'months', true);

        return monthDiff;
    };

    var loadShippingAddres = function () {
        var shippingAddress = _.find($scope.model.account.addresses, { type: "shipping" });
        if (shippingAddress !== undefined)
            $scope.model.shippingAddress = shippingAddress;

    };
    var loadBillingAddres = function () {
        var billingAddress = _.find($scope.model.account.addresses, { type: "billing" });
        if (billingAddress !== undefined)
            $scope.model.billingAddress = billingAddress;
    };
    //Inner Functions End

    //Functions
    $scope.changeStep = function (index) {
        $scope.currentStep = index;
    };
    $scope.goStep_1 = function () {
        if ($scope.frmStep1.$valid) {
            $scope.showCustomError = false;
            $scope.changeStep(2);
        }
    };
    $scope.goStep_2 = function () {
        if ($scope.frmStep2.$valid) {
            $scope.showCustomError = false;
            $scope.changeStep(3);

            step3Init();
        }
    };
    $scope.goStep_3 = function () {
        $scope.finishButtonDisabled = true;
    };
    $scope.cancel = function () {
        $state.go('app.changePlan', {
            lang: $rootScope.activeLang
        }, {
                reload: true
            })
    };

    $scope.changePeriod = function (period) {
        if (period == "monthly") {
            $scope.model.newPlan.month = 1;
            $scope.model.newPlan.isAnnual = false;
        }
        else if (period == "annually") {
            $scope.model.newPlan.month = 12;
            $scope.model.newPlan.isAnnual = true;
        }

        init();
    };

    $scope.updateShipping = function () {
        if ($scope.model.useSingleAddress) {
            $scope.model.shippingAddress.title = $scope.model.billingAddress.title;
            $scope.model.shippingAddress.country = $scope.model.billingAddress.country;
            $scope.model.shippingAddress.city = $scope.model.billingAddress.city;
            $scope.model.shippingAddress.address = $scope.model.billingAddress.address;
        }
    };
    //Functions End

    //Initials 
    init();
    //Initials End
};

module.exports = function (app) {
    app.controller('newPlanCtrl', ['$rootScope', '$scope', '$state', '$stateParams', '$interval', '$translate', 'localStorageService', 'subscriptionFactory', 'accountService', 'generalsService', '_', newPlanCtrl]);
}