var shareFactory = function(httpProvider) {
    var factoryModel = {};

    factoryModel.shareDatasheetWithEmail = function(model) {
      return httpProvider.post('share', 'shareDatasheetWithEmail', model);
    };


    factoryModel.shareDatasheetFolderWithEmail = function(model) {
      return httpProvider.post('share', 'shareDatasheetFolderWithEmail', model);
    };

    factoryModel.getMailTemplate = function(templateType) {
      return httpProvider.get('share', 'getMailTemplate', "templateType=" + templateType);
    };

    factoryModel.shareArchiveFileWithEmail = function(model) {
      return httpProvider.post('share', 'shareArchiveFileWithEmail', model);
    };

    factoryModel.shareMultipleDatasheetWithEmail = function(model){
      return httpProvider.post('share', 'shareMultipleDatasheetWithEmail', model);
    };

    return factoryModel
};

module.exports = function(app) {
    app.factory('shareFactory', ['httpProvider', shareFactory]);
};
