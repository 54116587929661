var yourTemplatesCtrl = function ($rootScope, $scope, $state, $filter, $timeout, $translate, $modal, localStorageService, _, templateFactory, folderFactory, userFactory, subscriptionFactory, statisticFactory) {
    //Field
    var thisCtrl = this;
    thisCtrl.$doc = angular.element(document);

    $scope.translatedArchive = $translate.instant("TEMPLATE.YOURTEMPLATES.LBL_ARCHIVE.TEXT");
    $scope.rootFolder = {};
    $scope.selectedFolder = {};
    $scope.selectedTemplate = {};

    $scope.newFolder = {};
    $scope.folderType = "Template";
    $scope.loaded = {
        template: {
            folders: false,
            templates: false
        }
    };
    $scope.sortModel = {
        field: "name",
        reverse: false
    };
    //Fields End

    //Events
    $scope.$on('createdNewFolder', function (event, data) {
        getTemplateFolders(function () {
            var newFolder = $filter("filter")($scope.templateFolders, {
                parent: data.parentFolderId,
                text: data.newFolderName
            }, true)[0];

            openNewFolder(newFolder);
            $scope.folders = $scope.templateFolders;
        });
    });

    $scope.$on('deletedFolder', function (event, data) {
        getTemplateFolders(function () {
            var parentFolder = $filter("filter")($scope.templateFolders, {
                id: data.parentFolderId
            }, true)[0];

            openNewFolder(parentFolder);
            $scope.folders = $scope.templateFolders;
        });
    });

    $scope.$on('renamedFolder', function (event, data) {
        getTemplateFolders(function () {
            $scope.folders = $scope.templateFolders;
            var selectedFolderNew = $filter('filter')($scope.folders, {
                "_id": $scope.selectedFolder.id
            });
            $scope.selectedFolder = selectedFolderNew;

        });
    });

    $scope.$on('movedFolder', function (event, data) {
        getTemplateFolders(function () {
            $scope.folders = $scope.templateFolders;
        });
    });
    //Events End

    //Event Handlers,
    thisCtrl.keydownHandler = function (e) {
        //Kütüphane için export paneli açar

        if ((e.altKey && e.shiftKey && e.keyCode === 83) || (e.altKey && e.shiftKey && e.keyCode === 69) || (e.ctrlKey && e.altKey && e.shiftKey && e.keyCode === 83) || (e.ctrlKey && e.altKey && e.shiftKey && e.keyCode === 69)) {
            thisCtrl.exportTemplate();
        }

        if (e.keyCode === 46) {
            if (angular.isUndefined($scope.selectedTemplate) === false && angular.equals($scope.selectedTemplate, {}) === false) {
                $scope.deleteTemplate();
            }
        }
    };

    thisCtrl.$doc.on('keydown', thisCtrl.keydownHandler);

    $scope.$on('$destroy', function () {
        thisCtrl.$doc.off('keydown', thisCtrl.keydownHandler);
    });
    //Event Handlers End,

    //Watches
    $scope.$watch('selectedFolder.id', function () {


        if (angular.isDefined($scope.selectedFolder.id) && $scope.selectedFolder.id != "") {
            $scope.selectedFolderPath = folderFactory.getFolderPath($scope.templateFolders, $scope.selectedFolder.id);

            var selectedFolder = $filter('filter')($scope.templateFolders, {
                "_id": $scope.selectedFolder.id
            })[0];

            $scope.selectedFolder.text = selectedFolder.text;
            $scope.selectedFolder.name = selectedFolder.name;
            $scope.selectedFolder.parent = selectedFolder.parent;

            $scope.selectedFolder.folderPermissions = selectedFolder.itemPermissions;

            getTemplates(function () {
                $scope.selectedTemplate = {};
                $timeout(function () {
                    $rootScope.$broadcast('reRenderNiceScroll', {
                        "id": "templateFolderTree"
                    });
                }, 100);
            });
        }
    });

    $(window).resize(function () {
        setDivHeight();
    });
    //Watches End

    //Inner Functions
    var setDivHeight = function () {
        var height = $('#divYourTemplateListContainer').height() - 40;
        $('#divYourFolderListContainer').height($('#divYourTemplateListContainer').height() - 60);
        $timeout(function () {
            $('#divYourTemplateList').slimscroll({
                height: height,
                railVisible: true,
                alwaysVisible: false
            });
        }, 250);
        // $('body').css('overflow', 'hidden');
    };

    var getTemplateFolders = function (nextProcess) {
        $scope.loaded.template.folders = false;
        folderFactory.getTemplateFolders()
            .then(function (response) {

                $scope.loaded.template.folders = true;

                $scope.templateFolders = response;
                for (var i = 0; i < $scope.templateFolders.length; i++) {
                    if ($scope.templateFolders[i].Parent == "#") {
                        $scope.templateFolders[i].Name = $scope.translatedArchive;
                        $scope.rootFolder = $scope.templateFolders[i];
                        break;
                    }
                }

                for (var i = 0; i < $scope.templateFolders.length; i++) {
                    var templateFolder = $scope.templateFolders[i];
                    templateFolder.id = templateFolder._id;
                    templateFolder.text = templateFolder.Name;
                    templateFolder.parent = templateFolder.Parent;
                }
                nextProcess();
            });
        // $scope.loaded.template.folders = false;
        // folderFactory.getFolders("?filter[folderType]=Template&limit=1000&fields=name,parent,_id,itemPermissions,state,text").then(function(response){
        //     if(response.status){
        //         $scope.loaded.template.folders = true;
        //         $scope.templateFolders = response.data;

        //         for (var i = 0; i < $scope.templateFolders.length; i++) {
        //             if ($scope.templateFolders[i].parent == "#") {
        //                 $scope.templateFolders[i].name = $scope.translatedArchive;
        //                 $scope.rootFolder = $scope.templateFolders[i];
        //                 break;
        //             }
        //         }

        //         for (var i = 0; i < $scope.templateFolders.length; i++) {
        //             var templateFolder = $scope.templateFolders[i];
        //             templateFolder.id = templateFolder._id;
        //             templateFolder.text = templateFolder.name;
        //             templateFolder.parent = templateFolder.parent;
        //         }
        //         nextProcess();
        //     }

        // }, function(error){
        //     console.log("error", error);
        // });

    };

    var openNewFolder = function (folder) {
        $scope.newFolder._id = folder._id;
    };

    var getTemplates = function (nextProcess) {

        $scope.loaded.template.templates = false;

        $scope.tdsMakerPromise.yourTemplate = {};


        // $scope.tdsMakerPromise.yourTemplate.promise = templateFactory.getTemplatesByFolderId($scope.selectedFolder.id)
        var query = "?filter[folderId]=" + $scope.selectedFolder.id + "&fields=_id,dateModified,isDisabled,majorVersion,minorVersion,modifiedBy,name,numberOfDatasheet,permissions&limit=1000";
        $scope.tdsMakerPromise.yourTemplate.promise = templateFactory.getAll(query)
            .then(function (response) {

                $scope.loaded.template.templates = true;

                $scope.templateFiles = response.data;
                $scope.files = $scope.templateFiles;
                var disabledTemplates = _.filter(response.data, {
                    isDisabled: true
                });

                if (disabledTemplates.length > 0)
                    $scope.hasDisabledTemplates = true;
                else
                    $scope.hasDisabledTemplates = false;

                setDivHeight();
                nextProcess();
            });

    };
    //Inner Functions End

    //Functions
    $scope.jsTreeOpenedNode = function () {
        //Rerender Nice scroll
        $rootScope.$broadcast('reRenderNiceScroll', {
            "id": "templateFolderTree"
        });
    };

    $scope.jsTreeClosedNode = function () {
        //Rerender Nice scroll
        $rootScope.$broadcast('reRenderNiceScroll', {
            "id": "templateFolderTree"
        });
    };

    thisCtrl.exportTemplate = function () {

        userFactory.hasLibraryExportAccess().then(function (response) {
            if (response) {
                var libraryTemplatesModel = $modal.open({
                    animation: false,
                    controller: 'libraryTemplatesCtrl',
                    size: 'full-width',
                    templateUrl: '/app/components/template/yourTemplates/views/modals/libraryTemplates.html',
                    resolve: {
                        libraryTemplatesModel: function () {
                            return libraryTemplatesModel;
                        }
                    }
                });
            }
        });

    }

    $scope.translateBadge = function (num) {
        return $translate.instant('TEMPLATE.YOURTEMPLATES.LBL_NUMBER_OF_DATASHEET.TOOLTIP', {
            numberOfDatasheet: num
        });
    }
    $scope.reOrder = function (field) {
        $scope.sortModel.reverse = ($scope.sortModel.field === field) ? !$scope.sortModel.reverse : false;
        $scope.sortModel.field = field;
    };

    $scope.selectTemplate = function (template) {
        if (template.isDisabled) return;
        $scope.selectedTemplate = template;
    };

    $scope.openMoveTemplateModal = function () {
        var modalInstance = $modal.open({
            animation: false,
            controller: 'moveTemplateCtrl',
            templateUrl: '/app/components/template/yourTemplates/views/modals/moveTemplate.html',
            resolve: {
                folders: function () {
                    return $scope.templateFolders;
                },
                selectedTemplate: function () {
                    return $scope.selectedTemplate;
                }
            }
        });

        modalInstance.result.then(function (result) {
            if (result.status) {
                getTemplates(function () {
                    $scope.selectedTemplate = {};
                });
            }
        });
    };

    $scope.copyTemplate = function () {
        if (!angular.isUndefined($scope.selectedTemplate) && angular.equals($scope.selectedTemplate, {}) === false) {
            $state.go('app.copyTemplate', {
                lang: $rootScope.activeLang,
                templateId: $scope.selectedTemplate._id
            });
        }
    };

    $scope.openRenameTemplateModal = function () {
        if (!angular.isUndefined($scope.selectedTemplate) && angular.equals($scope.selectedTemplate, {}) === false) {
            var modalInstance = $modal.open({
                animation: false,
                controller: 'renameTemplateCtrl',
                templateUrl: '/app/components/template/yourTemplates/views/modals/renameTemplate.html',
                backdrop: "static",
                resolve: {
                    selectedTemplate: function () {
                        return $scope.selectedTemplate;
                    }
                }
            });

            modalInstance.result
                .then(function (result) {
                    if (result.status) {
                        getTemplates(function () {
                            $scope.selectedTemplate = {};
                        });
                    }
                });
        }
    };

    $scope.openDeleteTemplateModal = function () {
        var modalInstance = $modal.open({
            animation: false,
            controller: 'deleteTemplateCtrl',
            templateUrl: '/app/components/template/yourTemplates/views/modals/deleteTemplate.html',
            resolve: {
                selectedTemplate: function () {
                    return $scope.selectedTemplate;
                }
            }
        });

        modalInstance.result.then(function (result) {
            if (result.status) {
                getTemplates(function () {
                    $scope.selectedTemplate = {};
                });
            }
        });
    };

    $scope.editTemplate = function () {
        if (angular.isDefined($scope.selectedTemplate) && $scope.selectedTemplate.isDisabled) return;
        var editTemplatePermission = $filter('filter')($scope.selectedTemplate.permissions, {
            "Name": "Edit Template"
        })[0];
        if (editTemplatePermission == null || editTemplatePermission == undefined) {
            return;
        }

        if (angular.isUndefined($scope.selectedTemplate) === false && angular.equals($scope.selectedTemplate, {}) === false) {
            $state.go('editor.editTemplate', {
                lang: $rootScope.activeLang,
                id: $scope.selectedTemplate._id
            });
        }
    };
    //Funtions End

    //Initials
    getTemplateFolders(function () {
        $scope.folders = $scope.templateFolders;
    });
    //Initials End
};

module.exports = function (app) {
    app.controller('yourTemplatesCtrl', ['$rootScope', '$scope', '$state', '$filter', '$timeout', '$translate', '$modal', 'localStorageService', '_', 'templateFactory', 'folderFactory', 'userFactory', 'subscriptionFactory', 'statisticFactory', yourTemplatesCtrl]);
};