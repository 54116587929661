var selectDatasheetCtrl = function($rootScope, $scope, $filter, $timeout, $translate, $modalInstance, folderFactory, datasheetFactory, checkPermissionFactory) {
    //Fields
    $scope.selectButton = "Select";
    $scope.isProcessing = false;

    $scope.translatedArchive = $translate.instant("DATASHEET.NEWDATASHEET.MODALS.SELECTDATASHEET.LBL_ARCHIVE.TEXT");

    $scope.selectedFolder = {};

    $scope.selectedDatasheet = {};
    $scope.sortModel = {
        field: "productName",
        reverse: false
    };
    $scope.loaded = {
        datasheet: {
            folders: false,
            datasheets: false
        }
    };
    //Fields End

    //Watches
    $scope.$watch('selectedFolder.id', function() {
        if (angular.isDefined($scope.selectedFolder.id) && $scope.selectedFolder.id != "") {
            var selectedFolderId = $scope.selectedFolder.id;
            getDatasheetsByFolderId(selectedFolderId, function() {
                $timeout(function() {
                    $rootScope.$broadcast('reRenderNiceScroll', {
                        "id": "datasheets"
                    });
                }, 100);
            });
        }
    });
    //Watches End



    //Functions
    $scope.updateSearchResults = function () {

        $scope.filteredDatasheets = $scope.datasheets.filter(function (datasheet) {
            if (!$scope.listSearchKey) return true;
            var matched = false;
            $scope.listSearchKey.split(' ').forEach(function (word) {
                word = $scope.listSearchKey.toString().toLowerCase();
                var datasheetSearchFieldValues = datasheet.productNumber.toString().toLowerCase() + datasheet.productName.toString().toLowerCase();

                if (datasheetSearchFieldValues.indexOf(word) != -1) {
                    if (matched === false) {
                        matched = true;
                    }
                }
            });

            return matched;
        });
    };
    $scope.cancel = function() {
        $modalInstance.dismiss('cancel');
    };

    $scope.jsTreeOpenedNode = function() {
        //Rerender Nice scroll
        $rootScope.$broadcast('reRenderNiceScroll', {
            "id": "folders"
        });
    };

    $scope.jsTreeClosedNode = function() {
        //Rerender Nice scroll
        $rootScope.$broadcast('reRenderNiceScroll', {
            "id": "folders"
        });
    };

    $scope.selectDatasheet = function(datasheet) {
        $scope.selectedDatasheet = datasheet;
    };

    $scope.selectDatasheetAndClose = function(datasheet) {
        $scope.selectDatasheet(datasheet);
        $scope.select();
    };

    $scope.select = function() {
        $modalInstance.close({
            status: true,
            selectedDatasheet: $scope.selectedDatasheet
        });
    };
    //Functions End

    //Inner Functions
    var getDatasheetFolders = function(nextProcess) {
        $scope.loaded.datasheet.folders = false;
        folderFactory.getDatasheetFolders()
            .then(function(response) {
                var datasheetFolders = response;
                datasheetFolders = checkPermissionFactory.canSelectFoldersForCopy(datasheetFolders);

                $scope.datasheetFolders = datasheetFolders;

                $scope.datasheetFolders = response;
                for (var i = 0; i < $scope.datasheetFolders.length; i++) {
                    if ($scope.datasheetFolders[i].Parent == "#") {
                        $scope.datasheetFolders[i].Name = $scope.translatedArchive;
                        $scope.rootFolder = $scope.datasheetFolders[i];
                        break;
                    }
                }

                for (var i = 0; i < $scope.datasheetFolders.length; i++) {
                    var datasheetFolder = $scope.datasheetFolders[i];
                    datasheetFolder.id = datasheetFolder._id;
                    datasheetFolder.text = datasheetFolder.Name;
                    datasheetFolder.parent = datasheetFolder.Parent;
                }


                $scope.loaded.datasheet.folders = true;
                nextProcess();
            });
    };

    var getDatasheetsByFolderId = function(folderId, nextProcess) {
        $scope.listSearchKey = "";
        $scope.loaded.datasheet.datasheets = false;
        datasheetFactory.getDatasheetsByFolderId(folderId)
            .then(function(response) {
                $scope.datasheets = checkPermissionFactory.canSelectDatasheetForCopy(response);
                $scope.updateSearchResults();

                $scope.loaded.datasheet.datasheets = true;
                nextProcess();
            });
    };
    //Inner Functions End

    //Initials
    getDatasheetFolders(function() {
        $scope.folders = $scope.datasheetFolders;
    });
    //Initials End
};

module.exports = function(app) {
    app.controller('selectDatasheetCtrl', ['$rootScope', '$scope', '$filter', '$timeout', '$translate', '$modalInstance', 'folderFactory', 'datasheetFactory', 'checkPermissionFactory', selectDatasheetCtrl]);
};
