var templateLibraryCtrl = function($rootScope, $scope, $q, $filter, _, templateFactory, imageFactory){
  //Fields
  $scope.templatesLoaded = false;
  $scope.templateLibraryModel = {};
  //Fields - End

  //Initials
  var getIndustries = function() {
      templateFactory.getIndustries().then(function(response) {
          $scope.industries = response;
          $scope.selectedIndustry = $scope.industries[0]._id;
          $scope.templateLibraryModel.industryId = $scope.selectedIndustry;
          $scope.industryChange();
      });
  }();
  //Initials - End

  //Private Functions
  var loadTemplates = function(){
      $scope.templatesLoaded = false;
      templateFactory.getTemplatesFromLibraryByIndustryId($scope.selectedIndustry).then(function(response){
        response = _.sortBy(response, 'usage');

        response = _.filter(response, function(item){
          return item.isLayout === false || item.isLayout === undefined;
        })

        var rowCount = parseInt(response.length / 3) + 1;
        $scope.templateRows = [];

        for(var i=1; i <= rowCount; i++)
          $scope.templateRows.push({row : i});

        for(var i in response){
          response[i].row = parseInt(i / 3) + 1;
        }

        $scope.templates = response;
        $scope.templatesLoaded = true;
        
        if($scope.templates.length > 0)
          $scope.selectedTemplate = $scope.templates[0];
        else
          $scope.selectedTemplate = void 0;
    });
  };
  //Comment End
  //Private Functions - End

  //Actions
  
  $scope.isTemplateDeatilsActive = function(row){
    if(angular.isDefined($scope.selectedTemplate) && $scope.selectedTemplate.row == row)
      return true;
    else
      return false;
  };

  $scope.closeSelectedLibrary = function(){
    $('.library-template-details').slideUp();
    if(!$scope.$$phase)
      $scope.$apply();
  };
  $scope.selectTemplate = function(template){
    $('.library-template-details').slideDown();
    $scope.selectedTemplate = template;
    if(!$scope.$$phase)
      $scope.$apply();
  };
  $scope.getSeperatorPosition = function(){
    //Seçili şablonun satırındaki tüm şablonları buluyoruz zaten toplam 3 tane olacak en fazla.
    var releatedTemplates = _.filter($scope.templates, { row : $scope.selectedTemplate.row});

    //Daha sonra bunların sağ menüde yer alan resimlerinin soldan ne kadar uzak(offset) olduğunu buluyoruz.
    var list = [];
    for(var i in releatedTemplates){
      var template = releatedTemplates[i];
      var offsetX = $('#img-' + template._id).offset().left;

      list.push({
        "_id" : template._id,
        "x" : offsetX
      });
    }

    //bulduklarımızı listeliyoruz. Böylece 1.sıradaki en solda 2. sıradaki ortada ve 3.sıradaki en sağda olacak
    list = _.sortBy(list, "x");
    var selectedTemplateItem = _.filter(list, { "_id" : $scope.selectedTemplate._id})[0];
    var indexOf = list.indexOf(selectedTemplateItem) + 1;
    return "library-seperator-pos" + indexOf;
  };

  $scope.useTemplate = function(){
    var deferred = $q.defer();
    
    templateFactory.getLibraryTemplate($scope.selectedTemplate._id).then(function(response){
      $('#templateLoading').show();
      
      var elements = templateFactory.changeTemplateElementIds(response.elements, model.template._id);

      for(var i in elements){
        var element = elements[i];

        if (element.type === "text") {
          for (var l in model.template.languages) {
            var language = model.template.languages[l];
            if (element.values[language] === undefined) {
              element.values[language] = "Sample Text";
            }
          }
        } else if (element.type === "inputtext") {

          for (var l in model.template.languages) {
            var language = model.template.languages[l];
            if (element.placeHolders[language] === undefined) {
              element.placeHolders[language] = "";
            }
            if (element.previews[language] === undefined) {
              element.previews[language] = "";
            }
          }
        } else if (element.type === "selectlist") {
          for (var l in model.template.languages) {
            var language = model.template.languages[l];

            if (element.source[language] === undefined) {
              var primaryValues = element.source[model.template.primaryLanguage];
              element.source[language] = primaryValues;
            }
          }
        }
      }
      model.elements = elements;
      historyService.addHistory('template library selected');
      $scope.isLibraryTemplate.isVisible = true;

      setTimeout(function(){
       mainModule.init();
      },1000);
      deferred.resolve();
    }, function(){
      
      deferred.reject();
    });

    return deferred.promise;
  };

  $scope.showPreview = function(template){
    var url = $filter('userFolderPath')(template.image);
    $('#libraryPreviewImage').attr('src', url);
    $('#libraryPreview').show();
  };
  $scope.hidePreview = function(){
    $('#libraryPreview').hide();
  };
  //Actions - End

  //Watches
  $scope.industryChange = function(){
      loadTemplates();
  };
  //Watches
};

module.exports = function(app){
  app.controller('templateLibraryCtrl', ['$rootScope', '$scope', '$q', '$filter', '_', 'templateFactory', 'imageFactory', templateLibraryCtrl]);
};
