var pdfInputIcon = function (pdfElementService, pdfViewService) {
    return {
        restrict: 'E',
        replace: true,
        templateUrl: 'app/components/pdf/shared/directives/elements/pdfInputIcon/pdfInputIcon.html',
        link: function (scope, elem, attrs) {
            //Fields 
            var containerElement = elem;
            var innerElement = containerElement.find('.inner-element');
            //Fields End

            //Elements
            //Elements End

            //Inner Functions
            var setElementStyle = function () {
                containerElement.css(scope.element.containerStyle);
                
                var newStyle = pdfViewService.arrangeElementBorder(scope.element.style);
                innerElement.css(newStyle);
            };

            var setElementValue = function () {
                innerElement.html('');
                var inputIconValue = pdfElementService.getInputIconValue(scope.pdfModel.mode, scope.element, scope.selectionModel.selectedLanguage);

                if (inputIconValue != "") {
                    innerElement.append($('<i></i>').addClass(inputIconValue));

                    
                }
                // else {
                //     var lblIcon = pdfElementService.getIconDefaultText(scope.selectionModel.selectedLanguage).icon;
                //     var lblIconWillBeHere = pdfElementService.getIconDefaultText(scope.selectionModel.selectedLanguage).iconWillBeHere;
                //     var iconInfo = {
                //         icon: lblIcon,
                //         iconWillBeHere: lblIconWillBeHere
                //     };
                //     var empty = pdfViewService.createEmptyPdfIcon();
                //     empty = empty.replace("{{icon}}", iconInfo.icon);
                //     empty = empty.replace("{{iconWillBeHere}}", iconInfo.iconWillBeHere);

                //     innerElement.append($(empty));
                // }


            };
            //Inner Functions End

            //Handlers
            //Handlers End

            //Events
            //Events End

            //Initials 
            setElementStyle();
            setElementValue();
            //Initials End
        }
    };
};

module.exports = function (app) {
    app.directive('pdfInputIcon', ['pdfElementService', 'pdfViewService', pdfInputIcon]);
}