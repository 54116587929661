var inputMask = function() {
    return {
        restrict: 'AE',
        link: function(scope, el, attrs) {
            attrs.$observe('inputMask', function() {
                var mask = attrs.inputMask;
                el.inputmask({
                    mask: mask
                });
            });

        }
    };
};


module.exports = function(app) {
    app.directive('inputMask', [inputMask]);
};
