var datasheetTripleLayout = function () {
    return {
        restrict: 'E',
        replace: true,
        templateUrl: 'app/components/datasheet/shared/directives/datasheetLayouts/tpl/datasheetTripleLayout.html',
        link: function (scope, elem, attrs) {

        }
    }
};

module.exports = function (app) {
    app.directive('datasheetTripleLayout', [datasheetTripleLayout]);
};