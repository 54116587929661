require('../core/');
require('../core.layout/');
require('../core.translate/');
require('../core.loading/');
//Module
var adminModule = angular.module('tdsApp.admin', [
    'ui.router',
    'tdsApp.core',
    'tdsApp.core.layout',
    'tdsApp.core.loading',
    'tdsApp.core.translate'
]);

//Router
require('./routing.js')(adminModule);

//Factories
require("./shared/factories/adminFactory.js")(adminModule);
//Services

//Controllers
require("./dashboard/controllers/dashboardController.js")(adminModule);
require("./accounts/controllers/accountsController.js")(adminModule);
require("./accounts/controllers/_partials/accountDetailsController.js")(adminModule);
require("./accounts/controllers/modals/editAccountSubscriptionController.js")(adminModule);
require("./accounts/controllers/modals/newAccountSubscriptionController.js")(adminModule);
require("./templateTransfer/controllers/templateTransferController.js")(adminModule);
require("./shared/controllers/_partials/adminMenuInSideMenuController.js")(adminModule);
require("./shared/controllers/_partials/adminNavTabsController.js")(adminModule);

module.exports = adminModule;
