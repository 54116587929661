var pdfInputImage = function (pdfViewService, pdfElementService) {
    return {
        restrict: 'E',
        replace: true,
        templateUrl: 'app/components/pdf/shared/directives/elements/pdfInputImage/pdfInputImage.html',
        link: function (scope, elem, attrs) {
            //Fields 
            var containerElement = elem;
            var innerElement = containerElement.find('.inner-element');
            //Fields End

            //Elements       
            //Elements End

            //Inner Functions
            var setElementStyle = function () {
                containerElement.css(scope.element.containerStyle);
                
                var newStyle = pdfViewService.arrangeElementBorder(scope.element.style);
                innerElement.css(newStyle);
            };

            var setElementValue = function () {
                innerElement.html('');
                if (scope.element.value != null && scope.element.value != undefined && scope.element.value != "") {
                
                    var inputImageValues = {
                        width: scope.element.style.width,
                        height: scope.element.style.height,
                        src: scope.element.value
                    };

                    var inputImage = pdfViewService.createPdfInputImage();
                    inputImage = inputImage.replace("{{width}}", inputImageValues.width);
                    inputImage = inputImage.replace("{{height}}", inputImageValues.height);
                    inputImage = inputImage.replace("{{src}}", inputImageValues.src);

                    innerElement.append(inputImage);
                }
            };
            //Inner Functions End

            //Handlers
            //Handlers End

            //Events

            //Events End

            //Initials 
            setElementStyle();
            setElementValue();
            //Initials End
        }
    };
};

module.exports = function (app) {
    app.directive('pdfInputImage', ['pdfViewService', 'pdfElementService', pdfInputImage]);
}