var uiSortable = function(){
  return {
    restrict: 'A',
    scope: {
            sortableOut: "&",
            sortableRecive: "&",
            sortableStop : "&"
    },
    link : function(scope, elm, attrs){

      var options = scope.$eval(attrs.sortableOptions);
      $(elm).sortable(options);

      elm.on('sortout', function (event, ui) {
          if (attrs.sortableOut) {
              scope.sortableOut({ event: event, ui: ui });
          }
      });
      elm.on('sortreceive', function (event, ui) {
          if (attrs.sortableRecive) {
              scope.sortableRecive({ event: event, ui: ui });
          }
      });

      elm.on('sortstop', function (event, ui) {
          if (attrs.sortableStop) {
              scope.sortableStop({ event: event, ui: ui });
          }
      });

    }
  }
};

module.exports = function(app){
  app.directive('uiSortable', uiSortable);
};
