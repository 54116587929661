var userProfileCtrl = function($scope, $timeout, menuToggleFactory) {
    //Fields
    //Fields End

    //Events
    $scope.$on('$viewContentLoaded', function() {
        $timeout(function() {
            menuToggleFactory.collapse();
        }, 100);
    });
    //Events End

    //Watches
    //Watches End

    //Function
    //Functions End

    //Inner Functions


    //Inner Functions End

    //Initials
    //Initials End
};

module.exports = function(app) {
    app.controller('userProfileCtrl', ['$scope', '$timeout', 'menuToggleFactory', userProfileCtrl]);
};
