var userPreferencesCtrl = function($scope, $stateParams, $filter, $timeout, $translate, localStorageService, userFactory) {
    //Fields
    var uID = localStorageService.get('userInformationData');

    var socialMediaDatasheetPrompt =  uID.Preferences == undefined || uID.Preferences.SocialMediaDatasheetPrompt == undefined ? socialMediaDatasheetPrompt = true : socialMediaDatasheetPrompt = uID.Preferences["SocialMediaDatasheetPrompt"];


    $scope.preferences = {
        SocialMediaDatasheetPrompt: socialMediaDatasheetPrompt
    }
    //Fields End

    //Inner Functions
    //Inner Functions End

    //Functions
    $scope.changePreference = function(preference) {
        userFactory.savePreference(preference, $scope.preferences[preference])
            .then(function (response) {
                var preferenceObject = {};
                preferenceObject[preference] = $scope.preferences[preference];

                uID.Preferences = _.merge(uID.Preferences, preferenceObject);
                localStorageService.set('userInformationData', uID);
            });
    }
    //Functions End

    //Initials
    //Initials End
};

module.exports = function(app) {
    app.controller('userPreferencesCtrl', ['$scope', '$stateParams', '$filter', '$timeout', '$translate','localStorageService', 'userFactory', userPreferencesCtrl]);
};
