var currentSubscriptionCtrl = function ($scope, $filter, $translate, subscriptionFactory) {
    //Fields 
    $scope.subscriptionModel = {};
    $scope.loaded = false;
    //Fields End

    //Inner Functions
    var init = function () {
        getAccountCurrentStatus(function () {
            $scope.subscriptionModel = {
                name: $scope.accountSubscription.package.name,
                startDate: $scope.accountSubscription.startDate,
                endDate: $scope.accountSubscription.endDate,
                templateLimit: $scope.accountSubscription.package.templateLimit,
                templates: $scope.accountCurrentStatus.numberOfTemplates,
                datasheetLimit: $scope.accountSubscription.datasheetLimit,
                datasheets: $scope.accountCurrentStatus.numberOfDatasheets,
                storageLimit: $scope.accountSubscription.storageLimit,
                storage: $scope.accountCurrentStatus.storage,
                storageLimitAs: $scope.accountSubscription.storageLimitAs,
                isCancelled: $scope.accountSubscription.isCancelled,
                cancelDate: $scope.accountSubscription.cancelDate
            };

            if ($scope.subscriptionModel.storageLimitAs == "mb")
                $scope.subscriptionModel.storage = ($scope.subscriptionModel.storage / 1000 / 1000).toFixed(2);
            else
                $scope.subscriptionModel.storage = parseFloat(($scope.subscriptionModel.storage / 1000 / 1000 / 1000).toFixed(2));

            $scope.subscriptionModel.templateUsage = (100 * $scope.subscriptionModel.templates) / $scope.subscriptionModel.templateLimit;
            if ($scope.subscriptionModel.templateUsage > 100)
                $scope.subscriptionModel.templateUsage = 100;

            $scope.subscriptionModel.datasheetUsage = (100 * $scope.subscriptionModel.datasheets) / $scope.subscriptionModel.datasheetLimit;
            if ($scope.subscriptionModel.datasheetUsage > 100)
                $scope.subscriptionModel.datasheetUsage = 100;

            $scope.subscriptionModel.storageUsage = (100 * $scope.subscriptionModel.storage) / $scope.subscriptionModel.storageLimit;
            if ($scope.subscriptionModel.storageUsage > 100)
                $scope.subscriptionModel.storageUsage = 100;

            var cancelProcess = $scope.accountSubscription.isCancelled != null && $scope.accountSubscription.isCancelled != undefined;
            $scope.subscriptionModel.cancelProcess = cancelProcess;

            if (cancelProcess) {
                var cancelProcessMessage = $translate.instant("SUBSCRIPTION.ACCOUNT_SUBSCRIPTION.PARTIALS.CURRENT_SUBSCRIPTION.MESSAGES.CANCEL_MESSAGE.TEXT");
                var fCancelDate = $filter('date')($scope.accountSubscription.cancelDate, 'dd.MM.yyyy');
                $scope.subscriptionModel.cancelProcessMessage = cancelProcessMessage.replace("$cancelDate$", fCancelDate);

            }

            $scope.loaded = true;
        });
    };

    var getAccountCurrentStatus = function (nextProcess) {
        $scope.loaded = false;
        subscriptionFactory.getAccountCurrentStatus()
            .then(function (response) {
                $scope.accountCurrentStatus = response;

                nextProcess();
            });
    };

    //Inner Functions End

    //Functions 
    $scope.getProgressClass = function (value) {
        if (value <= 25)
            return "progress-bar-info";
        else if (value <= 65)
            return "progress-bar-success";
        else if (value <= 90)
            return "progress-bar-warning";
        else
            return "progress-bar-danger";
    };

    $scope.getChangePackageVisibility = function (packageName) {
        var visibility = true;
        if (packageName == "Ultimate")
            visibility = false;

        var cancelProcess = $scope.accountSubscription.isCancelled != null && $scope.accountSubscription.isCancelled != undefined;
        if (cancelProcess)
            visibility = false;

        return visibility;
    };

    $scope.getCancelSubscriptionVisibility = function (packageName) {
        var visibility = true;
        if (packageName == "Freemium")
            visibility = false;

        var cancelProcess = $scope.accountSubscription.isCancelled != null && $scope.accountSubscription.isCancelled != undefined;
        if (cancelProcess)
            visibility = false;

        return visibility;
    };
    //Functions End

    //Initialize
    init();
    //Initialize - End

    //ForTesting
    $scope._init = init;
    $scope._getAccountCurrentStatus = getAccountCurrentStatus;
    //ForTesting End
};

module.exports = function (app) {
    app.controller('currentSubscriptionCtrl', ['$scope', '$filter', '$translate', 'subscriptionFactory', currentSubscriptionCtrl]);
};
