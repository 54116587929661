var moment = require("./../../../../../../../bower_components/moment/moment.js");
var monthlyStatisticsCtrl = function($scope, analyticFactory) {
    //Fields
    //Fields End

    //Events
    //Events End

    //Watches
    //Watches End

    //Inner Functions
    var initialize = function() {
          var thisMonth = moment().month() + 1;
          var thisYear = moment().year();

          getMajorPDFViews(thisMonth,thisYear,function(response) {
            $scope.thisMonthData = response;
          });

          var lastMonth = moment().add(-1,'months').month() + 1;
          var lastYear = moment().add(-1,'months').year();

          getMajorPDFViews(lastMonth,lastYear,function(response) {
            $scope.lastMonthData = response;
          });

    };

    var getMajorPDFViews = function(month, year, callback) {
        analyticFactory.getMajorPDFViews(month, year)
            .then(function(response) {
                callback(response);
            });
    };
    //Inner Functions End

    //Functions
    //Functions End

    //Initials
    initialize();
    //Initials End
};

module.exports = function(app) {
    app.controller('monthlyStatisticsCtrl', ['$scope', 'analyticFactory', monthlyStatisticsCtrl]);
};
