 var topBarCtrl = function($rootScope, $scope, $state, accountService, $modal) {
     //Fields
     //Fields End

     //Events
     //Events End

     //Watches
     //Watches End

     //Inner Functions
     //Inner Functions End

     //Functions
     $scope.newDatasheet = function() {
         $state.go('app.newDatasheet', {
             lang: $rootScope.activeLang
         });

         return;

        accountService.checkPricingModel({
                "pricingItem": "Datasheet"
            })
            .then(function(response) {
                
                if (response.status) {
                    $state.go('app.newDatasheet', {
                        lang: $rootScope.activeLang
                    });

                } else {
                    var uibModalInstance = $modal.open({
                        animation: false,
                        controller : "pricingWarningCtrl",
                        templateUrl: '/app/components/users/shared/views/modals/pricingWarning.html',
                        backdropClass: "defaultuibModal",
                        size: 'full-width',
                        resolve: {
                            pricingItem: function() {
                                return "Form";
                            },
                            number: function() {
                                return response.numberOfDatasheets;
                            },
                            currentPlan: function() {
                                return response.currentPlan;
                            }
                        }
                    });
                }
            });
     };
     //Functions End

     //Initials
     //Initials End

 };

 module.exports = function(app) {
     app.controller("topBarCtrl", ['$rootScope', '$scope', '$state', 'accountService', '$modal', topBarCtrl]);
 };
