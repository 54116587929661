var validateCtrl = function($rootScope, $scope, $state, $stateParams, $timeout, authFactory) {
    //Fields
    $scope.validateButton = "AUTHENTICATION.VALIDATE.BTN_VALIDATE.TEXT";
    $scope.disabled = false;
    $scope.status = true;
    $scope.codeInputDisabled = false;
    //Fields - End

    //Inner Functions
    var initialize = function() {
        if (angular.isDefined($stateParams.code) && $stateParams.code != "") {
            $scope.codeInputDisabled = true;
            $scope.validationCode = $stateParams.code;
        }
    };
    //Inner Functions End

    //Functions
    $scope.validate = function() {
        if ($scope.validationForm.$valid) {
            $scope.validateButton = "AUTHENTICATION.VALIDATE.BTN_VALIDATE.ALTERNATE_TEXT";
            $scope.disabled = true;
            $state.current.name == "auth.verifyChangeEmail" ? $scope.confirmEmail = true : $scope.confirmEmail = false;
            authFactory.validateEmail($scope.validationCode, $scope.subDomain, $scope.confirmEmail)
                .then(function(response) {
                    if (response.status && !$scope.confirmEmail) {
                        $state.go('auth.lastStep', {
                            id: response.user._id
                        });
                    } else if (response.status && $scope.confirmEmail) {
                        $state.go('app.dashboard', { lang: $rootScope.activeLang
                        });
                    } else {
                        $scope.status = response.status;
                        $scope.customErrorMessage = response.message;
                        // $timeout(function() {
                        //     $scope.status = true;
                        // }, 6000);
                    }

                    $scope.validateButton = "AUTHENTICATION.VALIDATE.BTN_VALIDATE.TEXT";
                    $scope.disabled = false;
                });
        }
    };
    //Functions End

    //Initials
    initialize();
    //Initials End

    /* removeIfProduction */
    $scope._initialize = initialize;
    /* endRemoveIfProduction */
};

module.exports = function(app) {
    app.controller('validateCtrl', ['$rootScope', '$scope', '$state', '$stateParams', '$timeout', 'authFactory', validateCtrl]);
};
