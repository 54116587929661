var uiRooter = function($stateProvider, $urlRouterProvider, $locationProvider) {
    $stateProvider
        .state('app.allUsers', {
            url: '/all-users',
            templateUrl: 'app/components/users/allUsers/views/allUsers.html',
            controller: "allUsersCtrl",
            permission: {
                requiredPermissions: ['View Users']
            }
        })
        .state('app.editUser', {
            url: '/edit-user/:userId',
            templateUrl: 'app/components/users/editUser/views/editUser.html',
            controller: 'editUserCtrl'
        })
        .state('app.userProfile', {
            url: '/user-profile/:userId',
            templateUrl: 'app/components/users/userProfile/views/userProfile.html',
            controller: 'userProfileCtrl',
            onEnter: ['menuToggleFactory', function(menuToggleFactory) {
                menuToggleFactory.collapse();
            }],
            onExit: ['menuToggleFactory', function(menuToggleFactory) {
                menuToggleFactory.doPreviousStatus();
            }]
        });

    /* removeIfProduction */
    // $urlRouterProvider.otherwise("/en/app/all-users");
    /* endRemoveIfProduction */
};

module.exports = function(app) {
    app.config(['$stateProvider', '$urlRouterProvider', '$locationProvider', uiRooter]);
};
