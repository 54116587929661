require("./../../../../bower_components/angular/angular.js");
require('angular-ui-router');
require('../core/');

//Module
var accountSettingsModule = angular.module('tdsApp.accountSettings', [
    'ui.router',
    'tdsApp.core'
]);

//Config

//Run
require('./run.js')(accountSettingsModule);

//Constants

//Router
require('./routing.js')(accountSettingsModule);

//Factories
require('./shared/factories/accountSettingsFactory.js')(accountSettingsModule);

//Controllers
require('./mailSettings/controllers/mailSettingsController.js')(accountSettingsModule);

//Modal Controllers

//Partials Controllers

//Export
module.exports = accountSettingsModule;
