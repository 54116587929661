//Required Modules

//Module
var subscriptionModule = angular.module('tdsApp.subscription', ['tdsApp.core']);

//Config
require("./config.js")(subscriptionModule);

//Run
require('./run.js')(subscriptionModule);

//Router
require('./routing.js')(subscriptionModule);

//Factories
require('./shared/factories/subscriptionFactory.js')(subscriptionModule);
require("./shared/factories/subscriptionHttpInterceptor.js")(subscriptionModule);

//Filters
require('./shared/filters/orderIDFilter.js')(subscriptionModule);

//Controllers
require('./accountSubscription/controllers/accountSubscriptionController.js')(subscriptionModule);
require('./changePlan/controllers/changePlanController.js')(subscriptionModule);
require('./newPlan/controllers/newPlanController.js')(subscriptionModule);
require('./orders/controllers/orderDetailsController.js')(subscriptionModule);
require('./cancelSubscription/controllers/cancelSubscriptionController.js')(subscriptionModule);

require('./accountSubscription/controllers/_partials/currentSubscriptionController.js')(subscriptionModule);
require('./accountSubscription/controllers/_partials/orderHistoryController.js')(subscriptionModule);
require('./accountSubscription/controllers/_partials/subscriptionExtraItemsController.js')(subscriptionModule);

require('./shared/controllers/modals/subscriptionWarningModalController.js')(subscriptionModule);

module.exports = subscriptionModule;
