var pdfPages = function ($compile, pdfViewService, _) {
    return {
        restrict: 'E',
        replace: true,
        templateUrl: 'app/components/pdf/shared/directives/pdfPages/tpl/pdfPages.html',
        link: function (scope, elem, attrs) {
            //Fields 
            //Fields End

            //Inner Functions
            var setInitialElements = function () {
                var pages = _.filter(scope.pdfModel.elements, {
                    type: 'page'
                });
                pages = _.sortBy(pages, 'order');

                for (var i = 0, len = pages.length; i < len; i++) {
                    var page = pages[i];

                    var childScope = scope.$new();
                    childScope.page = page;
                    childScope.pageIndex = i;

                    var html = pdfViewService.createPdfPage();
                    $(elem).append($compile(html)(childScope));
                }
            };
            //Inner Functions End

            //Functions
            //Functions End

            //Initials 
            setInitialElements();
            //Initials End
        }
    };
};

module.exports = function (app) {
    app.directive('pdfPages', ['$compile', 'pdfViewService', '_', pdfPages]);
};