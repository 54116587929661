var firebaseFactory = function (localStorageService) {
    //Fields
    var factoryModel = {};
    //Fields End

    //Functions
    factoryModel.checkOnce = function (ref, callback) {
        var datasheetRef = firebase.database().ref(ref);
        datasheetRef.once('value', function (snapshot) {
            var value = snapshot.val();
            callback(value);
        });
    };

    factoryModel.checkAlways = function (ref, callback) {
        var datasheetRef = firebase.database().ref(ref);
        datasheetRef.on('value', function (snapshot) {
            var value = snapshot.val();
            callback(value);
        });
    };

    factoryModel.set = function (ref, data) {
        firebase.database().ref(ref).set(data);
    };

    factoryModel.clearRefEvents = function (ref) {
        var datasheetRef = firebase.database().ref(ref);
        datasheetRef.off('value');
    };


    // factoryModel.lock = function (id, type) {
    //     var userId = localStorageService.get('authorizationData')._id;

    // };


    // factoryModel.lockDatasheet = function (datasheetId) {
    //     var userId = localStorageService.get('authorizationData')._id;
    //     firebase.database().ref('datasheet/' + datasheetId).set({
    //         isLocked: true,
    //         userId: userId
    //     });
    // };

    // factoryModel.checkLockDatasheet = function (datasheetId, callback) {

    // };
    //Functions End

    //Inner Functions    
    //Inner Functions End

    return factoryModel;
};

module.exports = function (app) {
    app.factory("firebaseFactory", ['localStorageService', firebaseFactory]);
};