var editPermissionCtrl = function($scope, $filter, $timeout, $modalInstance, permissionFactory, viewScope, permissionId) {
    //Fields
    $scope.selectedGroupsOrUsers = [];
    $scope.okButton = "PERMISSION.MODALS.EDITPERMISSION.BTN_OK.TEXT";
    $scope.isProcessing = false;
    //Fields End

    //Functions
    $scope.cancel = function() {
        $modalInstance.dismiss('cancel');
    };
    //Functions End

    //Inner Functions
    var getAllAccountPermissionLevels = function(nextProcess) {
        permissionFactory.getAllAccountPermissionLevels()
            .then(function(response) {
                $scope.permissionLevels = response;

                nextProcess();
            });
    };

    var getAccountPermissionById = function(nextProcess) {

    };

    $scope.ok = function() {
        $scope.errors = [];

        //Hiçbir user veya group seçilmemişse,
        if ($scope.selectedGroupsOrUsers.length == 0) {
            $scope.errors.push("PERMISSION.MODALS.EDITPERMISSION.ERROR_MESSAGES.SELECT_ONE_USER_OR_GROUP");
        }

        //PermissionLevel seçilmemişse,
        var selectedPermissionLevels = $filter('filter')($scope.permissionLevels, {
            IsSelected: true
        });
        if (selectedPermissionLevels.length == 0) {
            $scope.errors.push("PERMISSION.MODALS.EDITPERMISSION.ERROR_MESSAGES.SELECT_ONE_PERMISSION_LEVEL");
        }

        if ($scope.errors.length > 0) {
            $timeout(function() {
                $scope.errors = [];
            }, 5000);
            return;
        }

        $scope.okButton = "PERMISSION.MODALS.EDITPERMISSION.BTN_OK.ALTERNATE_TEXT";
        $scope.isProcessing = true;
        viewScope.editPermissionModalOkClicked($scope.selectedGroupsOrUsers, selectedPermissionLevels, function(response) {
            $scope.okButton = "PERMISSION.MODALS.EDITPERMISSION.BTN_OK.TEXT";
            $scope.isProcessing = false;
            if (response.status) {
                $modalInstance.close({
                    status: true
                });
            }
        });
    };

    $scope.closeAlert = function() {
        $scope.errors = [];
    };

    //Inner Functions Emd

    //Initials
    getAllAccountPermissionLevels(function() {
        viewScope.editPermissionModalOpened(permissionId, function(selectedGroupsOrUsers, selectedPermissionLevels) {
            $scope.selectedGroupsOrUsers = selectedGroupsOrUsers;

            //PermissionLevels kontrolleri set edilir.
            for (var i = 0; i < selectedPermissionLevels.length; i++) {
                var permissionLevel = selectedPermissionLevels[i];

                var selectedPermissionLevel = $filter('filter')($scope.permissionLevels, {
                    "_id": permissionLevel
                })[0];
                if (angular.isDefined(selectedPermissionLevel)) {
                    selectedPermissionLevel.IsSelected = true;
                }
            }
        });
    });
    //Initials End
};

module.exports = function(app) {
    app.controller('editPermissionCtrl', ['$scope', '$filter', '$timeout', '$modalInstance', 'permissionFactory', 'viewScope', 'permissionId', editPermissionCtrl]);
};
