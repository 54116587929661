var run = function ($window, $rootScope, localStorageService, authFactory) {
    //Fields
    /* removeIfProduction */
    // $rootScope.activeLang = "en";
    /* endRemoveIfProduction */
    //Fields End

    //Events
    $rootScope.$on('logout', function (event, data) {
        authFactory.logout();

    });
    //Events End

    //Initials 

    //Initials End
};


module.exports = function (app) {
    app.run(['$window', '$rootScope', 'localStorageService', 'authFactory', run]);
};
