/*Bu dosya kullanılmıyormuş, modules/services altındaki kullanılıyormuş*/
var datasheetContextMenuHandlerService = function ($filter, $translate, datasheetElementService, datasheetViewService, generalsService, _) {
    //Fields 
    var serviceModel = {};
    //Fields End

    //Functions
    serviceModel.handleContextMenuClick = function (key, showHiddenElements, templateModel, datasheetModel, page, table, row, cell, element) {        
        switch (key) {
            case "hideElement": {
                _hideElement(showHiddenElements, element);
                break;
            }
            case "showElement": {
                _showElement(element);
                break;
            }
            case "hideRow": {
                _hideElement(showHiddenElements, row);
                break;
            }
            case "showRow": {
                _showElement(row);
                break;
            }
            case "hideTable": {
                _hideElement(showHiddenElements, table);
                break;
            }
            case "showTable": {
                _showElement(table);
                break;
            }
            case "hidePage": {
                _hideElement(showHiddenElements, page);
                break;
            }
            case "showPage": {
                _showElement(page);
                break;
            }
            case "addDynamicRowsAbove": {
                _addDynamicRowsAbove(templateModel, datasheetModel, row);
                break;
            }
            case "addDynamicRowsBelow": {
                _addDynamicRowsBelow(templateModel, datasheetModel, row);
                break;
            }
            case "removeDynamicRow": {
                _removeDynamicRow(datasheetModel, row);
                break;
            }
            case "copyRowAbove": {
                _copyRowAbove(templateModel, datasheetModel, row);
                break;
            }
            case "copyRowBelow": {
                _copyRowBelow(templateModel, datasheetModel, row);
                break;
            }
        }
    };


    //Functions End

    //Inner Functions 
    var _showElement = function (element) {
        var elementId = element._id;
        $("." + element.type + "-" + elementId).removeClass("hidden-blur");

        element.isHidden = false;
    };

    var _hideElement = function (showHiddenElements, element) {
        var elementId = element._id;

        $("." + element.type + "-" + elementId).addClass("hidden-blur");
        if (showHiddenElements == false) {
            $("." + element.type + "-" + elementId).hide();
        }

        element.isHidden = true;
    };

    var _addDynamicRowsAbove = function (templateModel, datasheetModel, row) {
        var tableId = row.parentId;
        datasheetElementService.addDynamicRowsAbove(templateModel, datasheetModel, row);

        //İlgili tabloyu kendini tekrardan render etmesi için uyar.
        $(".table-" + tableId).find("table").find("tbody").trigger('reRenderTable');
    };

    var _addDynamicRowsBelow = function (templateModel, datasheetModel, row) {
        var tableId = row.parentId;
        datasheetElementService.addDynamicRowsBelow(templateModel, datasheetModel, row);

        //İlgili tabloyu kendini tekrardan render etmesi için uyar.
        $(".table-" + tableId).find("table").find("tbody").trigger('reRenderTable');
    };

    var _removeDynamicRow = function (datasheetModel, row) {
        var tableId = row.parentId;
        datasheetElementService.removeDynamicRow(datasheetModel, row);

        //İlgili tabloyu kendini tekrardan render etmesi için uyar.
        $(".table-" + tableId).find("table").find("tbody").trigger('reRenderTable');
    };

    var _copyRowAbove = function (templateModel, datasheetModel, row) {
        var tableId = row.parentId;
        datasheetElementService.copyRowAbove(templateModel, datasheetModel, row);

        //İlgili tabloyu kendini tekrardan render etmesi için uyar.
        $(".table-" + tableId).find("table").find("tbody").trigger('reRenderTable');
    };

    var _copyRowBelow = function (templateModel, datasheetModel, row) {
        var tableId = row.parentId;
        datasheetElementService.copyRowBelow(templateModel, datasheetModel, row);

        //İlgili tabloyu kendini tekrardan render etmesi için uyar.
        $(".table-" + tableId).find("table").find("tbody").trigger('reRenderTable');

    };
    //Inner Functions End

    return serviceModel;
};

module.exports = function (app) {
    app.service('datasheetContextMenuHandlerService', ['$filter', '$translate', 'datasheetElementService', 'datasheetViewService', 'generalsService', '_', datasheetContextMenuHandlerService]);
};