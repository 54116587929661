var datasheetComments = function ($filter, $translate, datasheetViewService, datasheetFactory, _) {
    return {
        restrict: 'E',
        replace: true,
        templateUrl: 'app/components/datasheet/shared/directives/datasheetRightMenu/datasheetComments/datasheetComments.html',
        link: function (scope, elem, attrs) {
            //Fields
            var pnlComments = elem.find("#pnlComments");
            var btnRefresh = elem.find("#btnRefresh");
            var txtComment = elem.find("#txtComment");
            var btnSaveComment = elem.find("#btnSaveComment");
            //Fields End

            //Elements
            
            //Elements End

            //Inner Functions
            var init = function () {
                renderComments();
            };

            var renderComments = function () {
                pnlComments.empty();

                var comments = scope.datasheetModel.comments;
                var orderedComments = _.orderBy(comments, ["dateCommented"], ["desc"]);
                for (var i = 0, len = orderedComments.length; i < len; i++) {
                    var comment = jQuery.extend({}, orderedComments[i]);
                    comment.dateCommented = getRelativeTime(comment.dateCommented);

                    //Profile Picture
                    if (!comment.user.ProfilePicture) {
                        comment.user.ProfilePicture = "/assets/images/users/user-male.png"
                    } else {
                        comment.user.ProfilePicture = $filter('image')(comment.user.ProfilePicture);
                    }

                    var commentItemHtml = datasheetViewService.createDatasheetCommentItem();
                    commentItemHtml = commentItemHtml.replace("$profilePicture$", comment.user.ProfilePicture);
                    commentItemHtml = commentItemHtml.replace("$firstName$", comment.user.FirstName);
                    commentItemHtml = commentItemHtml.replace("$lastName$", comment.user.LastName);
                    commentItemHtml = commentItemHtml.replace("$comment$", comment.comment);
                    commentItemHtml = commentItemHtml.replace("$dateCommented$", comment.dateCommented);

                    pnlComments.append(commentItemHtml);

                }
            };

            var getRelativeTime = function (date) {
                var today = new Date();
                var commentDate = new Date(date);
                var diffMs = (today - commentDate);
                var diffMins = Math.round((diffMs / 1000) / 60);
                var diffHrs = Math.round(((diffMs / 1000) / 60) / 60);


                if (diffMins > 1440) {
                    return $filter('date')(date, 'dd MMM / HH:MM');
                } else if (diffMins > 60) {
                    var hourAgo = "";
                    if (diffHrs > 1) {
                        hourAgo = $translate.instant('DATASHEET.DIRECTIVES.DATASHEETRIGHTMENU.DATASHEETCOMMENTS.LBL_HOUR_AGO.PLURAL_TEXT');
                    } else {
                        hourAgo = $translate.instant('DATASHEET.DIRECTIVES.DATASHEETRIGHTMENU.DATASHEETCOMMENTS.LBL_HOUR_AGO.TEXT');
                    }
                    return diffHrs + " " + hourAgo;
                } else {
                    var minuteAgo = "";
                    if (diffHrs > 1) {
                        minuteAgo = $translate.instant('DATASHEET.DIRECTIVES.DATASHEETRIGHTMENU.DATASHEETCOMMENTS.LBL_MINUTE_AGO.PLURAL_TEXT');
                    } else {
                        minuteAgo = $translate.instant('DATASHEET.DIRECTIVES.DATASHEETRIGHTMENU.DATASHEETCOMMENTS.LBL_MINUTE_AGO.TEXT');
                    }
                    return diffMins + " " + minuteAgo;
                }
            };

            var saveComment = function (comment) {
                comment = comment.trim();
                if (comment != "") {
                    var commentModel = {
                        datasheetId: scope.datasheetModel.datasheet._id,
                        comment: comment
                    };

                    datasheetFactory.saveDatasheetComment(commentModel)
                        .then(function (response) {
                            scope.datasheetModel.comments = response;
                            renderComments();
                        });

                    return true;
                }
                else return false;
            };
            //Inner Functions End

            //Events
            scope.$on('$destroy', function () {
            });

            btnRefresh.click(function () {
                $(this).find("i").addClass("fa-spin");
                datasheetFactory.getDatasheetCommentsByDatasheetId(scope.datasheetModel.datasheet._id)
                    .then(function (response) {
                        scope.datasheetModel.comments = response;
                        renderComments();

                        btnRefresh.find("i").removeClass("fa-spin");
                    });

            });

            txtComment.bind('keydown', function (event) {
                if (event.keyCode == 13) {
                    event.preventDefault();

                    var comment = $(this).val();
                    var status = saveComment(comment);
                    if (status) {
                        $(this).val('');
                    }
                }
            });

            btnSaveComment.click(function () {
                var comment = txtComment.val();
                var status = saveComment(comment);
                if (status) {
                    txtComment.val('');
                }
            });
            //Events End

            //Initials 
            init();
            //Initials End

        }
    };
};

module.exports = function (app) {
    app.directive('datasheetComments', ['$filter', '$translate', 'datasheetViewService', 'datasheetFactory', '_', datasheetComments]);
};