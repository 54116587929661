var archiveFileFactory = function($http, $q, httpProvider) {
    var factoryModel = {};

    factoryModel.saveFiles = function(files) {
        return httpProvider.post('customFile', 'saveFiles', files);
    };

    factoryModel.updateFile = function(files) {
        return httpProvider.post('customFile', 'updateFile', files);
    };

    factoryModel.getArchiveFilesByFolderId = function(folderId) {
        return httpProvider.get('customFile', 'getArchiveFilesByFolderId', 'folderId=' + folderId);
    };

    factoryModel.getCustomArchiveFileById = function(archiveFileId) {
        return httpProvider.get('customFile', 'getCustomArchiveFileById', 'archiveFileId=' + archiveFileId);
    };

    factoryModel.deleteArchiveFile = function(archiveId) {
        return httpProvider.delete('customFile', 'deleteArchiveFile', archiveId);
    };

    factoryModel.moveArchiveFile = function(archiveFileId, folderId) {
        return httpProvider.put('customFile', 'moveArchiveFile', {
            archiveFileId: archiveFileId,
            folderId: folderId
        });
    };

    factoryModel.updateArchiveFileFields = function(archiveFile) {
        return httpProvider.put('customFile', 'updateArchiveFileFields', archiveFile);
    };

    return factoryModel;
};

module.exports = function(app) {
    app.factory('archiveFileFactory', ['$http', '$q', 'httpProvider', archiveFileFactory]);
};
