var regenerateDatasheetVersionCtrl = function ($rootScope, $scope, $filter, $translate, $modalInstance, datasheetFactory, languageFactory, checkPermissionFactory, parentScope, datasheet) {
    //Fields
    $scope.currentStep = 1;
    $scope.errors = [];

    $scope.shareButton = "DATASHEET.YOURDATASHEETS.MODALS.REGENERATEDATASHEETVERSION.BTN_REGENERATE.TEXT";
    $scope.isProcessing = false;
    $scope.formVersions = [];
    $scope.selectedDatasheet = {
        _id: null
    }
    //$scope.selectedDatasheet = "";
    //Fields - End

    //Events Handler
    //Events Handler End

    //Watches
    //Watches End

    //Functions
    $scope.regenerate = function () {   
        datasheetFactory.regeneratePublishedDatasheet($scope.selectedDatasheet._id)
            .then(function (response) {
                $modalInstance.dismiss('cancel');
                var regenerateCompleteMessage = $translate.instant('DATASHEET.YOURDATASHEETS.MODALS.REGENERATEDATASHEETVERSION.LBL_REGENERATION_SUCCESS');
                toastr.success(regenerateCompleteMessage, '', { positionClass : 'toast-bottom-full-width', timeOut : 1000});
            });
    };

    $scope.cancel = function () {
        $modalInstance.dismiss('cancel');
    };

    $scope.getLanguageCode = function (language) {
        if (angular.isDefined($scope.languages)) {
            var selectedLanguage = $filter('filter')($scope.languages, {
                "isoCode": language
            })[0];

            return selectedLanguage.languageCode;
        }

    };
    //Functions End

    //Inner Functions
    var intialize = function () {
        $scope.prepared = false;
        checkPermissionFactory.canSelectDatasheetVersion(datasheet._id)
            .then(function (canSelectDatasheetVersion) {
                console.timeEnd("check permission");
                $scope.canSelectDatasheetVersion = canSelectDatasheetVersion;

                //Datasheet de kullanılacak tüm diller sistemden çekilir.
                languageFactory.getLanguages()
                    .then(function (response) {
                        $scope.languages = response;
                    });

                datasheetFactory.getPublishedDatasheetsWithoutElements(datasheet._id)
                    .then(function (response) {
                        if (response.length > 0) {
                            $scope.formVersions = $filter('orderBy')(response, 'majorVersion', true);
                            $scope.prepared = true;
                        }
                    });

                    
            });
    };
    //Inner Functions End

    //Initials
    intialize();
    //Initials - End
};

module.exports = function (app) {
    app.controller('regenerateDatasheetVersionCtrl', ['$rootScope', '$scope', '$filter', '$translate', '$modalInstance', 'datasheetFactory', 'languageFactory', 'checkPermissionFactory', 'parentScope', 'datasheet', regenerateDatasheetVersionCtrl]);
};
