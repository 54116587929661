var htmlTagCleaner = function () {
  var factoryModel = {};

  factoryModel.clear = function (text) {
    return text ? String(text).replace(/<[^>]+>/gm, '').replace(/[&]nbsp[;]/gi, " ") : '';
  };

  return factoryModel;
};

module.exports = function (app) {
  app.factory('htmlTagCleaner', htmlTagCleaner);
};
