module.exports = {
  mode: 'live',
  api: {
    live: {
      apiUrl: 'https://privateapi.alphawireless.com',
      suffix: '.' + 'alphawireless.com',
      apiPrefix: '/api/v2/',
      fullApiUrl: 'https://privateapi.alphawireless.com/api/v2/',
    },
  },
  app: {
    templateLanguages: [
      'tr',
      'en',
      'zh-CN',
      'de',
      'fr',
      'it',
      'es',
      'el',
      'hu',
      'vi',
    ],
    imageStorageUrl: '',
    live: {
      appUrl: 'https://tdsapp.alphawireless.com',
      suffix: '.alphawireless.com',
      port: 8080,
    },
  },
  web: {
    live: {
      appUrl: 'https://alphawireless.com',
      suffix: '.alphawireless',
      port: 8100,
    },
  },
  firebase: {
    live: {
      apiKey: 'AIzaSyBarI7gitnHGejWmP3_uq7dfZpBXthnt38',
      authDomain: 'tdsmaker-465cb.firebaseapp.com',
      databaseURL: 'http://tdsmaker-465cb.firebaseio.com',
      storageBucket: 'tdsmaker-465cb.appspot.com',
      messagingSenderId: '773748661930',
    },
  },
  public_api: {
    live: {
      url: 'https://publicapi.alphawireless.com/v1',
    },
  },
  auth_api: {
    live: {
      url: 'https://authapi.alphawireless.com',
    },
  },
  filepicker: {
    live: {
      apiKey: 'AxQwftCtBSPeZ9xYaSV4Qz',
    },
  },
}

