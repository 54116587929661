var loadingFactory = function ($window, $rootScope, $q, $timeout, localStorageService, subscriptionFactory, userFactory, authFactory, quoteFactory, intercomFactory, accountService) {
    var factoryModel = {};

    //Functions
    factoryModel.loadApplication = function () {
        var deferred = $q.defer();

        _getRandomOneQuote($rootScope.activeLang).then(function (response) {
            $rootScope.quote = {
                sentence: response.Sentence,
                author: response.Author
            };

            _canUserBeLoaded().then(function (status) {
                if (status) {
                    //Cancel edildiyse veya paket süresi dolduysa otomatik olarak Freemium pakete düşürelim.
                    _checkSubscription().then(function (subscriptionResponse) {
                        //Subscription süresi dolmuşsa template ve datasheetleri disabled yap.
                        _disableData().then(function (disableEnableResponse) {
                            _loadUser().then(function (userResponse) {
                                var accountId = userResponse.AccountId._id;
                                _getAccount(accountId).then(function (accountResponse) {
                                    $rootScope.userId = userResponse._id;
                                    $rootScope.activeLang = userResponse.Language;
                                    var userInformation = localStorageService.get('userInformationData');
                                    if (userInformation.Status == 3) {
                                        authFactory.logout()
                                            .then(function (response) {
                                                localStorageService.clearAll();
                                                $window.location.href = "/";
                                            });
                                    }
                                    else {
                                        _loadUserPermissions().then(function (response) {
                                            $rootScope.$broadcast('loadUserPermissionsLoaded');
                                            $rootScope.isLoaded = true;


                                            // console.log("userResponse", userResponse);
                                            var permissions = localStorageService.get('userPermissions');
                                            var intercomPermission = {
                                                viewGroups: false,
                                                viewPermissionLevels: false,
                                                viewSubscription: false,
                                                viewUsers: false,
                                                viewEmailGroups: false,
                                                viewDatasheetReport: false,
                                                viewBackup: false
                                            };

                                            for (var i in permissions) {
                                                if (permissions[i].Name == "View Users")
                                                    intercomPermission.viewUsers = true;
                                                else if (permissions[i].Name == "View Groups")
                                                    intercomPermission.viewGroups = true;
                                                else if (permissions[i].Name == "View Permission Levels")
                                                    intercomPermission.viewPermissionLevels = true;
                                                else if (permissions[i].Name == "View Email Groups")
                                                    intercomPermission.viewEmailGroups = true;
                                                else if (permissions[i].Name == "View Subscription")
                                                    intercomPermission.viewSubscription = true;
                                                else if (permissions[i].Name == "View BackUp")
                                                    intercomPermission.viewBackup = true;
                                                else if (permissions[i].Name == "View Datasheet Report")
                                                    intercomPermission.viewDatasheetReport = true;
                                            }

                                            var updateUserPromise = intercomFactory.updateUser({
                                                user_id: userResponse._id,
                                                email: userResponse.Email,
                                                name: userResponse.FirstName + " " + userResponse.LastName,
                                                created_at: userResponse.DateEntered,
                                                custom_attributes: {
                                                    firstName: userResponse.FirstName,
                                                    lastName: userResponse.LastName,
                                                    language: userResponse.Language,
                                                    permissions_viewUsers: intercomPermission.viewUsers,
                                                    permissions_viewGroups: intercomPermission.viewGroups,
                                                    permissions_viewPermissionLevels: intercomPermission.viewPermissionLevels,
                                                    permissions_viewEmailGroups: intercomPermission.viewEmailGroups,
                                                    permissions_viewSubscription: intercomPermission.viewSubscription,
                                                    permissions_viewBackup: intercomPermission.viewBackup,
                                                    permissions_viewDatasheetReport: intercomPermission.viewDatasheetReport
                                                },
                                                companies: [{
                                                    company_id: accountResponse._id,
                                                    name: accountResponse.Name
                                                }]
                                            });

                                            var updateCompanyPromise = intercomFactory.updateCompany({
                                                company_id: accountResponse._id,
                                                name: accountResponse.Name,
                                                plan: disableEnableResponse.subscription.package.name,
                                                created_at: accountResponse.DateEntered,
                                                custom_attributes: {
                                                    subscriptionStartDate: disableEnableResponse.subscription.startDate,
                                                    subscriptionEndDate: disableEnableResponse.subscription.endDate,
                                                    currency: disableEnableResponse.subscription.currency,
                                                    templateLimit: disableEnableResponse.subscription.templateLimit,
                                                    datasheetLimit: disableEnableResponse.subscription.datasheetLimit,
                                                    userLimit: disableEnableResponse.subscription.userLimit,
                                                    storageLimit: disableEnableResponse.subscription.storageLimit,
                                                    storageLimitAs: disableEnableResponse.subscription.storageLimitAs.toUpperCase(),
                                                    numberOfDatasheets: disableEnableResponse.accountStatus.numberOfDatasheets,
                                                    numberOfTemplates: disableEnableResponse.accountStatus.numberOfTemplates,
                                                    numberOfUser: disableEnableResponse.accountStatus.numberOfUser,
                                                    storage: disableEnableResponse.accountStatus.storage
                                                }
                                            });

                                            $q.all([updateUserPromise, updateCompanyPromise])
                                                .then(function (response) {
                                                    //Başarılı Bir Şekilde Kullanıcı Update Edildiyse, Intercom'u Göster
                                                    if (INTERCOM_SETTINGS.IS_SHOWN) {
                                                        window.Intercom('boot', {
                                                            app_id: INTERCOM_SETTINGS.APP_ID,
                                                            email: userResponse.Email,
                                                            user_id: userResponse._id,
                                                            user_hash: userResponse.hash
                                                        });
                                                    }
                                                });
                                            deferred.resolve();
                                        });
                                    }
                                });
                            });
                        });
                    });
                }
                else {
                    localStorageService.clearAll();
                    $window.location.href = "/";
                }
            });
        });

        return deferred.promise;
    };

    factoryModel.preLoadApplication = function () {
        return true;
    };
    //Functions End

    //Inner Functions
    var _getAccount = function (accountId) {
        return accountService.getAccount(accountId);
    };

    var _getAccountSubscription = function () {
        return subscriptionFactory.getAccountSubscription();
    };

    var _canUserBeLoaded = function () {
        return userFactory.canUserBeLoaded();
    };

    var _loadUser = function () {
        return userFactory.loadUserInformation();
    };

    var _loadUserPermissions = function () {
        return userFactory.loadUserPermissions();
    };

    var _checkSubscription = function () {
        return subscriptionFactory.checkSubscription();
    };

    var _disableData = function () {
        return subscriptionFactory.disableEnableData();
    };

    var _getRandomOneQuote = function (language) {
        return quoteFactory.getRandomOneQuote(language);
    };
    //Inner Functions End
    return factoryModel;
};

module.exports = function (app) {
    app.factory('loadingFactory', ['$window', '$rootScope', '$q', '$timeout', 'localStorageService', 'subscriptionFactory', 'userFactory', 'authFactory', 'quoteFactory', 'intercomFactory', 'accountService', loadingFactory]);
};