var uiRouter = function($stateProvider, $urlRouterProvider, $locationProvider) {
    $stateProvider
        .state('app.allArchives', {
            url: '/all-archives',
            templateUrl: 'app/components/customArchives/allArchives/views/allArchives.html',
            controller: 'allArchivesCtrl'
        })
        .state('app.newArchive', {
            url: '/new-archive',
            templateUrl: 'app/components/customArchives/newArchive/views/newArchive.html',
            controller: 'newArchiveCtrl',
            onEnter: ['menuToggleFactory', function(menuToggleFactory) {
                menuToggleFactory.collapse();
            }],
            onExit: ['menuToggleFactory', function(menuToggleFactory) {
                menuToggleFactory.doPreviousStatus();
            }]
        })
        .state('app.editArchive', {
            url: '/edit-archive/:archiveId',
            templateUrl: 'app/components/customArchives/editArchive/views/editArchive.html',
            controller: 'editArchiveCtrl',
            onEnter: ['menuToggleFactory', function(menuToggleFactory) {
                menuToggleFactory.collapse();
            }],
            onExit: ['menuToggleFactory', function(menuToggleFactory) {
                menuToggleFactory.doPreviousStatus();
            }]
        })
        .state('app.yourArchives', {
            url: '/your-archives/:customArchiveId',
            templateUrl: 'app/components/customArchives/yourArchives/views/yourArchives.html',
            controller: 'yourArchivesCtrl'
        })
        .state('app.uploadFiles', {
            url: '/upload-files/:customArchiveId/:folderId',
            templateUrl: 'app/components/customArchives/uploadFiles/views/uploadFiles.html',
            controller: 'uploadFilesCtrl',
            params: {
                customArchiveFileId: null
            }
        });
};

module.exports = function(app) {
    app.config(['$stateProvider', '$urlRouterProvider', '$locationProvider', uiRouter]);
};
