var uploadFactory = function ($rootScope, imageFactory) {

  var factoryModel = {};

  factoryModel.uploadImage = function (callback) {
    var imgBlobUrl;
    imageFactory.upload()
      .then(function (imageFileName) {
        var imageUrl = imageFactory.image(imageFileName);
        callback(imageUrl);
      });
  };

  factoryModel.editorUploadImage = function (element) {
    var imgBlobUrl;

    var jsImageOnLoad = function () {
      var height = 0;
      var width = 0;
      if (this.height > 100) {
        var ratio = this.width / this.height;
        height = 100;
        width = 100 * ratio;
      } else {
        height = this.height;
        width = this.width;
      }
      element.elementStyle.Size.Width = width;
      element.elementStyle.Size.Height = height;


      for (var i = 0; i < element.elementValue.length; i++) {

        var currentValueElement = element.elementValue[i];
        currentValueElement.Value = imgBlobUrl;

      }
      if (!$rootScope.$$phase)
        $rootScope.$apply();
    };

    var imageUploadSuccess = function (url) {

      var img = new Image();
      imgBlobUrl = url;
      img.onload = jsImageOnLoad;
      img.src = imageFactory.image(url);
    };

    imageFactory.upload().then(imageUploadSuccess);
  };

  factoryModel.datasheetUploadImage = function (element, datasheet) {
    var datasheetElement = elementValueFactory.getDatasheetElement(element, datasheet);
    var imgBlobUrl;

    var jsImageOnLoad = function () {
      var width = this.width;
      var height = this.height;

      var holderWidth = element.elementStyle.Size.Width;
      var holderHeight = element.elementStyle.Size.Height;

      var whRatio = width / height;

      if (width > holderWidth) {
        height = (height * holderWidth) / width;
        width = holderWidth;
      }

      if (height > holderHeight) {
        width = (width * holderHeight) / height;
        height = holderHeight;
      }

      datasheetElement.height = height;
      datasheetElement.width = width;


      for (var i = 0; i < datasheetElement.elementValue.length; i++) {

        var currentValueElement = datasheetElement.elementValue[i];
        currentValueElement.Value = imgBlobUrl;

      }
      if (!$rootScope.$$phase)
        $rootScope.$apply();
    };

    var imageUploadSuccess = function (url) {
      var img = new Image();
      imgBlobUrl = url;
      img.onload = jsImageOnLoad;
      img.src = imageFactory.image(url);
    };

    imageFactory.upload().then(imageUploadSuccess);

  };

  factoryModel.uploadImageToTextbox = function (element, datasheetElement) {
    var imgBlobUrl;

    var jsImageOnLoad = function () {
      var width = this.width;
      var height = this.height;

      var holderWidth = element.elementStyle.Size.Width;
      var holderHeight = element.elementStyle.Size.Height;

      var whRatio = width / height;

      if (width > holderWidth) {
        height = (height * holderWidth) / width;
        width = holderWidth;
      }

      if (height > holderHeight) {
        width = (width * holderHeight) / height;
        height = holderHeight;
      }

      for (var i = 0; i < datasheetElement.elementValue.length; i++) {

        var currentValueElement = datasheetElement.elementValue[i];
        var newImageHtml = '<img src="' + imageFactory.image(imgBlobUrl) + '" class="fr-fil fr-dib fr-draggable" >';
        currentValueElement.Value += newImageHtml;

      }
      if (!$rootScope.$$phase)
        $rootScope.$apply();
    };
    var imageUploadSuccess = function (url) {
      var img = new Image();
      imgBlobUrl = url;
      img.onload = jsImageOnLoad;
      img.src = imageFactory.image(url);
    };
    imageFactory.upload().then(imageUploadSuccess);
  }
  return factoryModel;

};

module.exports = function (app) {
  app.factory('uploadFactory', ['$rootScope', 'imageFactory', uploadFactory]);
};
