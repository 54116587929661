var orderHistoryCtrl = function ($scope, $filter, subscriptionFactory) {
  //Fields 
  $scope.orders = [];

  $scope.loaded = false;
  //Fields End

  //Inner Functions
  var getAccountOrders = function () {
    $scope.loaded = false;
    subscriptionFactory.getAccountOrders()
      .then(function (response) {
        $scope.orders = response;

        $scope.loaded = true;
      });
  };
  //Inner Functions End

  //Functions
  //Functions End

  //Initials 
  getAccountOrders();
  //Initials End

  //For Testing
  $scope._getAccountOrders = getAccountOrders;
  //For Testing End
};

module.exports = function (app) {
  app.controller('orderHistoryCtrl', ['$scope', '$filter', 'subscriptionFactory', orderHistoryCtrl]);
};
