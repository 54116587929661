//Required Modules
require('angular-translate');
require('angular-translate-loader-static-files');
require('angular-translate-loader-partial');

//Module
var coreTranslateModule = angular.module('tdsApp.core.translate', [
    'pascalprecht.translate',
    'angular-filepicker',
    'ngSanitize'
]);

//Run
require('./run.js')(coreTranslateModule);

//Config
require('./config.js')(coreTranslateModule);

//Constants
require('./shared/constants/locales.js')(coreTranslateModule);

//Controllers

//Partials Controllers
require('./shared/controllers/_partials/languagePickerInHeaderController.js')(coreTranslateModule);

//Modals Controllers
require('./shared/controllers/modals/languagePickerController.js')(coreTranslateModule);


module.exports = coreTranslateModule;
