var authFactory = function (httpProvider, localStorageService, $window) {
    //Fields
    var factoryModel = {};

    //Fields End

    //Inner Functions
    factoryModel.authentication = {
        isAuth: false
    };
    factoryModel.fillAuthData = function () {
        var authData = localStorageService.get('authorizationData');
        if (authData) {
            factoryModel.authentication.isAuth = true;
        }
    };

    factoryModel.login = function (model) {
        return httpProvider.token(model);
    };

    factoryModel.getAutoLoginInfo = function (id, pass) {
        var params = "id=" + id + "&pass=" + pass;
        return httpProvider.get('authentication', 'getAutoLoginInfo', params);
    };

    factoryModel.signUp = function (model) {
        return httpProvider.post('authentication', 'signUp', model);
    };

    factoryModel.validateEmail = function (code) {
        var model = {
            validationCode: code
        };
        return httpProvider.post('authentication', 'validateEmail', model);
    };

    factoryModel.validateChangedEmail = function (code) {
        var model = {
            validationCode: code
        };

        return httpProvider.post('authentication', 'validateChangedEmail', model);
    }

    factoryModel.completeRegistration = function (model) {
        return httpProvider.post('authentication', 'completeRegistration', model);
    };

    factoryModel.logout = function () {

        localStorageService.clearAll();
        return httpProvider.get('authentication', 'logout').then(function (response) {            

            if (INTERCOM_SETTINGS.IS_SHOWN) {
                window.Intercom('shutdown');
            }

            $window.location.href = "/en/auth/sign-in";
        });
    };

    factoryModel.changePassword = function (oldPass, newPass) {
        var model = {
            currentPassword: oldPass,
            newPassword: newPass
        };

        return httpProvider.post('authentication', 'changePassword', model);
    };

    factoryModel.resetPassword = function (email) {
        var model = {
            email: email
        };

        return httpProvider.post('authentication', 'resetPassword', model);

    };
    //Inner Functions End

    return factoryModel;
};

module.exports = function (app) {
    app.factory('authFactory', ['httpProvider', 'localStorageService', '$window', authFactory]);
};
