var uiRooter = function($stateProvider, $urlRouterProvider, $locationProvider) {
  $stateProvider.state('app.allEmails', {
    url: '/all-emails',
    templateUrl: "/app/components/mailGroups/allEmails/views/allEmails.html",
    controller: "allEmailsCtrl",
    permission: {
        requiredPermissions: ['View Email Groups']
    }
  });

  /* removeIfProduction */
  // $urlRouterProvider.otherwise("/en/app/all-emails");
  /* endRemoveIfProduction */
};

module.exports = function(app){
  app.config(['$stateProvider', '$urlRouterProvider', '$locationProvider', uiRooter]);
};
