var uiRouter = function($stateProvider, $urlRouterProvider, $locationProvider) {
    $stateProvider
        .state('app.dashboard', {
            url: '',
            templateUrl: "/app/components/dashboard/dashboard/views/dashboard.html",
            controller: "dashboardCtrl"
        });

    $locationProvider.html5Mode(true);
    
    /* removeIfProduction */
    //Default route
    /* endRemoveIfProduction */
};

module.exports = function(app) {
    app.config(['$stateProvider', '$urlRouterProvider', '$locationProvider', uiRouter]);
};
