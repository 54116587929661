var languageFactory = function(httpProvider, $timeout, $q, _, config) {
    var factoryModel = {};

    factoryModel.getLanguages = function() {
        return httpProvider.get('template', 'getLanguages');
    };

    factoryModel.setLanguageModel = function(formLanguages) {
        var deferred = $q.defer();

        httpProvider.get('template', 'getLanguages').then(function(languages) {
            for (var i in languages) {
                var language = languages[i];
                if (formLanguages.indexOf(language.isoCode) < 0)
                    language.selected = false;
                else
                    language.selected = true;
            }

            deferred.resolve(languages);
        });
        return deferred.promise;
    };

    return factoryModel;
};
module.exports = function(app) {
    app.factory('languageFactory', ['httpProvider', '$timeout', '$q', '_', 'config', languageFactory]);
};
