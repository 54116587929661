var moveTemplateCtrl = function($scope, $modalInstance, $translate, templateFactory, folders, selectedTemplate) {
    //Fields
    $scope.selectedFolder = {};

    $scope.okButton = "TEMPLATE.YOURTEMPLATES.MODALS.MOVETEMPLATE.BTN_OK.TEXT";
    $scope.isProcessing = false;
    //Fields End

    //Functions
    $scope.cancel = function() {
        $modalInstance.dismiss();
    };

    $scope.moveTemplate = function() {
        $scope.okButton = "TEMPLATE.YOURTEMPLATES.MODALS.MOVETEMPLATE.BTN_OK.ALTERNATE_TEXT";
        $scope.isProcessing = true;

        var templateId = selectedTemplate._id;
        var folderId = $scope.selectedFolder.id;


        templateFactory.moveTemplate(templateId, folderId)
            .then(function(response) {
                $scope.okButton = "TEMPLATE.YOURTEMPLATES.MODALS.MOVETEMPLATE.BTN_OK.TEXT";
                $scope.isProcessing = false;

                if (response) {
                    var message = $translate.instant("TEMPLATE.YOURTEMPLATES.MODALS.MOVETEMPLATE.SUCCESS_MESSAGES.MOVED_SUCCESSFULLY");
                    toastr.success(message, '', { positionClass: 'toast-bottom-full-width', timeOut: 3000 });
                    $modalInstance.close({
                        status: true
                    });
                }
            });
    };
    //Functions End

    //Initials
    $scope.folders = angular.copy(folders);
    //Initials End
};

module.exports = function(app) {
    app.controller('moveTemplateCtrl', ['$scope', '$modalInstance', '$translate', 'templateFactory', 'folders', 'selectedTemplate', moveTemplateCtrl]);
};
