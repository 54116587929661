var lastStepCtrl = function ($window, $rootScope, $scope, $state, $q, $translate, $stateParams, $timeout, authFactory, intercomFactory, config, environment) {
    //Fields

    $scope.completeButton = "AUTHENTICATION.LASTSTEP.BTN_COMPLETE_REGISTRATION.TEXT";
    $scope.disabled = true;
    $scope.status = true;
    $scope.heardFrom = {
        question: $translate.instant('AUTHENTICATION.LASTSTEP.TXT_HEARD_FROM.PLACEHOLDER'),
        answer: 'question'
    };
    $scope.biggestProblem = {
        question: $translate.instant('AUTHENTICATION.LASTSTEP.TXT_BIGGEST_PROBLEM.PLACEHOLDER'),
        answer: 'question'
    };
    $scope.showComment = false;

    $scope.freemiumDetails = $translate.instant('AUTHENTICATION.LASTSTEP.LBL_FREEMIUM_DETAILS.TEXT');
    $scope.webUrl = environment.WEB_URL || config.web[environment.MODE || config.mode].url

    //Fields - End

    //Inner Functions
    //Inner Functions End

    //Functions
    $scope.changedValue = function () {
        var translateString = $translate.instant('AUTHENTICATION.LASTSTEP.TXT_HEARD_FROM.ANSWER_5');
        if ($scope.heardFrom.answer == translateString) {
            $scope.showComment = true;
        } else {
            $scope.showComment = false;
        }


        if ($scope.heardFrom.answer != 'question' && $scope.biggestProblem.answer != 'question') {
            $scope.disabled = false;
        } else {
            $scope.disabled = true;
        }
    };

    $scope.completeRegister = function () {
        if($scope.heardFrom.answer === "question" || $scope.biggestProblem.answer === "question")
            return;

        if ($scope.completeRegisterForm.$valid) {
            $scope.completeButton = "AUTHENTICATION.LASTSTEP.BTN_COMPLETE_REGISTRATION.ALTERNATE_TEXT";
            $scope.disabled = true;

            authFactory.completeRegistration({
                id: $stateParams.id,
                firstName: $scope.firstName,
                lastName: $scope.lastName,
                password: $scope.password,
                phoneNumber: $scope.phoneNumber || "",
                heardFrom: $scope.heardFrom,
                biggestProblem: $scope.biggestProblem

            }).then(function (response) {
                if (response.status) {
                    var user = response.user;
                    var account = response.account;

                    intercomFactory.updateUser({
                        user_id: user._id,
                        email: user.Email,
                        name: user.FirstName + " " + user.LastName,
                        created_at: user.DateEntered,
                        custom_attributes: {
                            firstName: user.FirstName,
                            lastName: user.LastName,
                            language: user.Language,
                        },
                        companies: [{
                            company_id: account._id,
                            name: account.Name
                        }]
                    }).then(function (resUpdateUser) {
                        intercomFactory.flushEvents()
                            .then(function (resFlushEvents) {
                                intercomFactory.addEvent({
                                    event_name: "Sign Up Last Step",
                                    meta_data: {
                                        email: user.Email,
                                        firstName: user.FirstName,
                                        lastName: user.LastName
                                    }
                                }, user._id)
                                    .then(function (resAddEvent) {
                                        $state.go('auth.signInAuto', {
                                            id: $stateParams.id,
                                            pass: $scope.password
                                        });
                                    });
                            });
                    });

                } else {
                    $scope.status = response.status;
                    $scope.customErrorMessage = response.message;
                }

            });

            $scope.completeButton = "AUTHENTICATION.LASTSTEP.BTN_COMPLETE_REGISTRATION.TEXT";
            $scope.disabled = false;
        }
    };
    //Functions End

    //Initials
    //Initials End
};

module.exports = function (app) {
    app.controller('lastStepCtrl', ['$window', '$rootScope', '$scope', '$state', '$q', '$translate', '$stateParams', '$timeout', 'authFactory', 'intercomFactory', 'config', 'environment', lastStepCtrl]);
};
