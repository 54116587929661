var datasheetInputIconPickerCtrl = function ($scope, $modalInstance, element) {
    //Fields 
    //Fields End

    //Functions
    $scope.selectIcon = function (icon) {
        $modalInstance.close({
            status: true,
            selectedIcon: icon
        });
    };

    $scope.cancel = function () {
        $modalInstance.dismiss('cancel');
    };
    //Functions End

    //Initials
    if (!angular.isUndefined(element.source))
        $scope.userSelectedIcons = element.source;
    else
        $scope.userSelectedIcons = [];
    //Initials End
};

module.exports = function (app) {
    app.controller('datasheetInputIconPickerCtrl', ['$scope', '$modalInstance', 'element', datasheetInputIconPickerCtrl]);
};