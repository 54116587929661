var switchery = function($window, $timeout, $log, $parse){
    function linkSwitchery(scope, elem, attrs, ngModel) {
            if(!ngModel) return false;
            var options = {};
            try {
                options = $parse(attrs.switchery)(scope);
            }
            catch (e) {}

            var switcher;

            attrs.$observe('disabled', function(value) {
              if (!switcher) {
                return;
              }

              if (value) {
                switcher.disable();
              }
              else {
                switcher.enable();
              }
            });

            // Watch changes
            scope.$watch(function () {
              return ngModel.$modelValue;
            }, function(newValue,oldValue) {
               initializeSwitch()
            });
            
            function initializeSwitch() {
              $timeout(function() {
                // Remove any old switcher
                if (switcher) {
                  angular.element(switcher.switcher).remove();
                }
                // (re)create switcher to reflect latest state of the checkbox element
                
                switcher = new $window.Switchery(elem[0], options);
                var element = switcher.element;
                element.checked = scope.initValue;
                if (attrs.disabled) {
                  switcher.disable();
                }

                switcher.setPosition(false);
                element.addEventListener('change',function(evt) {
                    scope.$apply(function() {
                        ngModel.$setViewValue(element.checked);
                    })
                });
                scope.$watch('initValue', function(newValue, oldValue) {
                    switcher.setPosition(false);
                });
              }, 0);
            }
            initializeSwitch();
          }

        return {
            require: 'ngModel',
            restrict: 'AE',
            scope : {
              initValue : '=ngModel'
            },
            link: linkSwitchery
        }
};

module.exports = function(app){
    app.directive('switchery', ['$window', '$timeout','$log', '$parse', switchery]);
};