var permissionFactory = function(httpProvider) {
    //Fields
    var factoryModel = {};
    //Fields - End

    //Functions
    factoryModel.ping = function() {
        return httpProvider.get('permission', 'ping');
    };

    factoryModel.getAllPermissions = function() {
        return httpProvider.get('permission','getAllPermissions');
    };

    factoryModel.getAllAccountPermissions = function() {
        return httpProvider.get('permission', 'getAllAccountPermissions');
    };

    factoryModel.getAllItemPermissions = function(itemId) {
        var params = "itemId=" + itemId;
        return httpProvider.get('permission', 'getAllItemPermissions', params);
    };

    factoryModel.getAllAccountPermissionLevels = function() {
        return httpProvider.get('permission', 'getAllAccountPermissionLevels');
    };

    factoryModel.getAllPermissionGroups = function() {
        return httpProvider.get('permission', 'getAllPermissionGroups');
    };

    factoryModel.saveAccountPermissionLevel = function(model) {
        return httpProvider.post('permission', 'saveAccountPermissionLevel', model);
    };

    factoryModel.getAllGroupsAndUsers = function() {
        return httpProvider.get('permission', 'getAllGroupsAndUsers');
    };

    factoryModel.saveAccountPermissions = function(model) {
        return httpProvider.post('permission', 'saveAccountPermissions', model);
    };

    factoryModel.deleteAccountPermissionLevel = function(accountPermissionLevelId) {
        return httpProvider.delete('permission', 'deleteAccountPermissionLevel', accountPermissionLevelId);
    };

    factoryModel.getAccountPermissionLevelById = function(permissionLevelId) {
        var params = "permissionLevelId=" + permissionLevelId;
        return httpProvider.get('permission', 'getAccountPermissionLevelById', params);
    };

    factoryModel.deleteAccountPermission = function(accountPermissionId) {
        return httpProvider.delete('permission', 'deleteAccountPermission', accountPermissionId);
    };

    factoryModel.deleteAccountItemPermission = function(itemPermissionId) {
        return httpProvider.delete('permission', 'deleteAccountItemPermission', itemPermissionId);
    };

    factoryModel.getAccountPermissionById = function(accountPermissionId) {
        var params = "accountPermissionId=" + accountPermissionId;
        return httpProvider.get('permission', 'getAccountPermissionById', params);
    };

    factoryModel.getItemPermissionById = function(itemPermissionId) {
        var params = "itemPermissionId=" + itemPermissionId;
        return httpProvider.get('permission', 'getItemPermissionById', params);
    };

    factoryModel.saveAccountItemPermissions = function(model) {
        return httpProvider.post('permission', 'saveAccountItemPermissions', model);
    };

    //Functions End

    return factoryModel;
};

module.exports = function(app) {
    app.factory('permissionFactory', ['httpProvider', permissionFactory]);
};
