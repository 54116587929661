var sideMenu = function(){
    
    return {
      restrict: 'AE', /* optional */
      link: function (scope, el, attrs) {
        var menuItem = $(el);

        menuItem.bind('click', function(e){
            if(!$("#wrapper").hasClass("enlarged")){
       
                if(!$(this).hasClass("subdrop")) {
                // hide any open menus and remove all other classes
                $("ul",$(this).parents("ul:first")).slideUp(350);
                $("a",$(this).parents("ul:first")).removeClass("subdrop");
                $("#sidebar-menu .pull-right i").removeClass("md-remove").addClass("md-add");

                // open our new menu and add the open class
                $(this).next("ul").slideDown(350);
                $(this).addClass("subdrop");
                $(".pull-right i",$(this).parents(".has_sub:last")).removeClass("md-add").addClass("md-remove");
                $(".pull-right i",$(this).siblings("ul")).removeClass("md-remove").addClass("md-add");
                }else if($(this).hasClass("subdrop")) {
                $(this).removeClass("subdrop");
                $(this).next("ul").slideUp(350);
                $(".pull-right i",$(this).parent()).removeClass("md-remove").addClass("md-add");
                }
            }
        });

      }
  };
};


module.exports = function(app){
    app.directive('sideMenu', sideMenu);
};