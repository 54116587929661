require("angular-bootstrap-colorpicker");
require("angular-sanitize");
require("./../../../../plugins/angular-bootstrap-contextmenu/contextMenu.js");
require("angular-bootstrap-switch");
require('./../../../../bower_components/angular-froala/src/angular-froala.js');
require('./../../../../bower_components/ngSweetAlert/SweetAlert.js');
require('./../../../../plugins/dnd/angular-drag-and-drop-lists.min.js');
require('../core/');
require('../authentication/');
require('../core.layout/');
require('../core.translate/');
require('../core.loading/');
require('../folder/');

//Module
var templateModule = angular.module('tdsApp.template', [
    'ui.router',
    'tdsApp.core',
    'oitozero.ngSweetAlert',
    'colorpicker.module',
    'froala',
    'ngSanitize',
    'ui.bootstrap.contextMenu',
    'frapontillo.bootstrap-switch',
    'dndLists',
    'tdsApp.core',
    'tdsApp.core.layout',
    'tdsApp.core.loading',
    'tdsApp.core.translate',
    'tdsApp.authentication',
    'tdsApp.folder'
]);

//Run
require('./run.js')(templateModule);

//Config

//Factories
require("./shared/factories/templateFactory.js")(templateModule);
require("./shared/factories/languageFactory.js")(templateModule);
require("./shared/factories/templateContextMenuFactory.js")(templateModule);

//Services
require("./shared/services/elementService.js")(templateModule);
require("./shared/services/froalaEditorService.js")(templateModule);

//Filters
require("./shared/filters/fancyTypeNameFilter.js")(templateModule);

//Directives
// require("./shared/directives/sidebar/verticalMenuItem.js")(templateModule);
// require("./shared/directives/sidebar/verticalMenuContainer.js")(templateModule);
// require("./shared/directives/sidebar/sideColorPicker/sideColorPicker.js")(templateModule);
// require("./shared/directives/sidebar/buttonColorPicker/buttonColorPicker.js")(templateModule);
//
// require("./shared/directives/splitters/headerSplitter.js")(templateModule);
// require("./shared/directives/splitters/footerSplitter.js")(templateModule);
// require("./shared/directives/splitters/paneSplitter.js")(templateModule);
// require("./shared/directives/splitters/bodySplitter.js")(templateModule);
//
// require("./shared/directives/layouts/singleLayout.js")(templateModule);
// require("./shared/directives/layouts/doubleLayout.js")(templateModule);
// require("./shared/directives/layouts/tripleLayout.js")(templateModule);
// require("./shared/directives/layouts/topSingleLayout.js")(templateModule);
// require("./shared/directives/layouts/bottomSingleLayout.js")(templateModule);
// require("./shared/directives/layouts/fourPaneLayout.js")(templateModule);
//
// require("./shared/directives/droppablePanel/droppablePanel.js")(templateModule);
//
// require("./shared/directives/templateHeader/templateHeader.js")(templateModule);
// require("./shared/directives/templateFooter/templateFooter.js")(templateModule);
// require("./shared/directives/templateBody/templateBody.js")(templateModule);
// require("./shared/directives/templatePane/templatePane.js")(templateModule);
//
// require("./shared/directives/pageSettings/pageSettings.js")(templateModule);
// require("./shared/directives/pageSettings/marginType.js")(templateModule);
// require("./shared/directives/pageSettings/layoutType.js")(templateModule);
// require("./shared/directives/pageSettings/pageBackground.js")(templateModule);
// require("./shared/directives/pageSettings/headerOptions.js")(templateModule);
// require("./shared/directives/pageSettings/footerOptions.js")(templateModule);
//
// require("./shared/directives/templatePages/templatePages.js")(templateModule);
//
// require("./shared/directives/templateSettings/templateSettings.js")(templateModule);
//
// require("./shared/directives/dragDrop/tdsDraggable.js")(templateModule);
// require("./shared/directives/dragDrop/draggableElement.js")(templateModule);
// require("./shared/directives/tableResizer/tableResizer.js")(templateModule);

//Elements - Directives
// require("./shared/directives/elements/text/templateText.js")(templateModule);
// require("./shared/directives/elements/table/templateTable.js")(templateModule);
// require("./shared/directives/elements/table/templateTableBody.js")(templateModule);
// require("./shared/directives/elements/table/templateTableHeader.js")(templateModule);
// require("./shared/directives/elements/table/templateTableRow.js")(templateModule);
// require("./shared/directives/elements/table/templateTableCell.js")(templateModule);
// require("./shared/directives/elements/selectlist/templateSelectList.js")(templateModule);
// require("./shared/directives/elements/shared/selectableElement.js")(templateModule);


//Elements Properties - Directives
// require("./shared/directives/elementProperties/shared/paragraphProperties/paragraphProperties.js")(templateModule);
// require("./shared/directives/elementProperties/shared/borderProperties/borderProperties.js")(templateModule);
// require("./shared/directives/elementProperties/shared/paddingProperties/paddingProperties.js")(templateModule);
// require("./shared/directives/elementProperties/shared/marginProperties/marginProperties.js")(templateModule);
// require("./shared/directives/elementProperties/shared/fontProperties/fontProperties.js")(templateModule);
// require("./shared/directives/elementProperties/shared/nameProperties/nameProperties.js")(templateModule);
// require("./shared/directives/elementProperties/shared/backgroundColorProperties/backgroundColorProperties.js")(templateModule);
// require("./shared/directives/elementProperties/shared/selectListAddItemProperties/selectListAddItemProperties.js")(templateModule);
// require("./shared/directives/elementProperties/shared/cellProperties/sizeAndAligmentProperties.js")(templateModule);
// require("./shared/directives/elementProperties/shared/cellProperties/cellBackgroundProperties.js")(templateModule);
// require("./shared/directives/elementProperties/shared/tableProperties/rowProperties.js")(templateModule);
// require("./shared/directives/elementProperties/shared/tableProperties/columnProperties.js")(templateModule);
//
// require("./shared/directives/elementProperties/templateTextProperties/templateTextProperties.js")(templateModule);
// require("./shared/directives/elementProperties/templateSelectListProperties/templateSelectListProperties.js")(templateModule);
// require("./shared/directives/elementProperties/templateTableProperties/templateTableProperties.js")(templateModule);
// require("./shared/directives/elementProperties/templateTableProperties/templateTableCellProperties.js")(templateModule);
// require("./shared/directives/elementProperties/templateTableProperties/templateTableTableProperties.js")(templateModule);



//Controllers
require("./newTemplate/controllers/newTemplateController.js")(templateModule);
require("./newTemplate/controllers/modals/selectTemplateController")(templateModule);

require("./yourTemplates/controllers/yourTemplatesController.js")(templateModule);
require("./yourTemplates/controllers/modals/deleteLibraryTemplateController.js")(templateModule);
require("./yourTemplates/controllers/modals/deleteTemplateController.js")(templateModule);
require("./yourTemplates/controllers/modals/moveTemplateController.js")(templateModule);
require("./yourTemplates/controllers/modals/renameTemplateController.js")(templateModule);
require("./yourTemplates/controllers/modals/libraryTemplatesController.js")(templateModule);

require("./editTemplate/controllers/editTemplateController.js")(templateModule);
require("./editTemplate/controllers/modals/selectListItemController.js")(templateModule);
require("./editTemplate/controllers/modals/selectIconCtrlNew.js")(templateModule);
require("./editTemplate/controllers/modals/selectIconPackCtrl.js")(templateModule);
require("./editTemplate/controllers/modals/confirmFormPublishCtrl.js")(templateModule);
require("./editTemplate/controllers/sidebar/templateLibraryController.js")(templateModule);


//Router
require('./routing.js')(templateModule);

//Partials Controllers
require("./shared/controllers/partials/templatesMenuInSideMenuController.js")(templateModule);

//Export
module.exports = templateModule;
