var environmentService = function ($q, $http) {
    //Fields 
    var serviceModel = {};
    var environment;
    //Fields End

    //Inner Functions
    serviceModel.getEnvironments = function () {
        var deferred = $q.defer();

        if (!environment) {
            var res = $http.get("/environment");
            res.success(function (data, status, headers, config) {
                deferred.resolve(data);
            });

            res.error(function (msg, code) {
                deferred.reject(msg);
            });
        }
        else {
            deferred.resolve(environment);
        }

        return deferred.promise;
    };
    //Inner Functions End

    return serviceModel;
};

module.exports = function (app) {
    app.service("environmentService", ['$q', '$http', environmentService]);
};