var adminMenuInSideMenuCtrl = function($scope, $state, localStorageService) {
    //Fields
    //Fields End

    //Initials
    //Initials End
};

module.exports = function(app) {
    app.controller('adminMenuInSideMenuCtrl', ['$scope', '$state', 'localStorageService', adminMenuInSideMenuCtrl]);
};
