var viewPreviewCtrl = function ($rootScope, $scope, $stateParams, localStorageService) {
    //Fields
    var previewPdf = localStorageService.get('previewPdf');
    var options = {
        pdfOpenParams: {
            navpanes: 1,
            toolbar: 1,
            statusbar: 1,
            view: "FitV",
            pagemode: "thumbs",
            page: 1,
            locale: $rootScope.activeLang
        },
        forcePDFJS: true,
        PDFJS_URL: "/assets/js/pdfjs/web/viewer.html"
    };
    //Fields End

    //Initials 
    var myPDF = PDFObject.embed(previewPdf, "#pdf", options);
    //Initials End
};

module.exports = function (app) {
    app.controller('viewPreviewCtrl', ['$rootScope', '$scope', '$stateParams', 'localStorageService', viewPreviewCtrl]);
};