var datasheetFilterCtrl = function($rootScope, $scope){
    //Fields
    $scope.currentTab = "filter";

    
    


    //Private methods
    var setDateOptions = function(){
        var language = $rootScope.activeLang;
        var today = new Date();
        var yesterday = new Date();
        var sevenDaysAgo = new Date();
        var thirtyDaysAgo = new Date();
        yesterday.setDate(today.getDate() -1);
        sevenDaysAgo.setDate(today.getDate() -6);
        thirtyDaysAgo.setDate(today.getDate() -29);

        var thisMonthFirstDay = moment().startOf('month');
        var thisMonthLastDay = moment().endOf('month');

        var lastMonthFirstDay = moment().startOf('month').add(-1, 'month');
        var lastMonthLastDay = moment().endOf('month').add(-1, 'month');
        
        var last3MonthStart = moment().startOf('month').add(-3, 'month');

        var last6MonthStart = moment().startOf('month').add(-6, 'month');

        $scope.datePicker = {
            date : {
                startDate: thisMonthFirstDay, 
                endDate: thisMonthLastDay
            }
        };

        if(language === "tr"){
            $('#demo').daterangepicker({
                "showDropdowns": true,
                "autoApply": true,
                "ranges": {
                    "Bugün": [
                        today,
                        today
                    ],
                    "Dün": [
                        yesterday,
                        yesterday
                    ],
                    "Bu Ay": [
                        thisMonthFirstDay,
                        thisMonthLastDay
                    ],
                    "Geçen Ay": [
                        lastMonthFirstDay,
                        lastMonthLastDay
                    ],
                    "Son 7 Gün": [
                        sevenDaysAgo,
                        today
                    ],
                    "Son 30 Gün": [
                        thirtyDaysAgo,
                        today
                    ],
                    "Son 3 Ay": [
                        last3MonthStart,
                        lastMonthLastDay
                    ],
                    "Son 6 Ay": [
                        last6MonthStart,
                        lastMonthLastDay
                    ]
                    
                },
                "locale": {
                    "format": "DD.MM.YYYY",
                    "separator": " - ",
                    "applyLabel": "Tamam",
                    "cancelLabel": "İptal",
                    "fromLabel": "From",
                    "toLabel": "To",
                    "customRangeLabel": "Özel",
                    "weekLabel": "W",
                    "daysOfWeek": [
                        "Pa",
                        "Pt",
                        "Sa",
                        "Ça",
                        "Pe",
                        "Cu",
                        "Ct"
                    ],
                    "monthNames": [
                        "Ocak",
                        "Şubat",
                        "Mart",
                        "Nisan",
                        "Mayıs",
                        "Haziran",
                        "Temmuz",
                        "Ağustos",
                        "Eylül",
                        "Ekim",
                        "Kasım",
                        "Aralık"
                    ],
                    "firstDay": 1
                },
                "startDate": thisMonthFirstDay,
                "endDate": thisMonthLastDay,
                "opens": "left"
            }, function(start, end, label) {
                $scope.loadReport(start.format(), end.format())
            });
        }
    };




    //Init
    setDateOptions();

};

module.exports = function(app){
    app.controller('datasheetFilterCtrl', ['$rootScope', '$scope', datasheetFilterCtrl]);
};