var shareDatasheetFolderWithEmailCtrl = function($scope, $filter, $timeout, $translate, $modalInstance, localStorageService, generalsService, emailListService, shareFactory, datasheetFactory, languageFactory, parentScope, folder) {
    //Fields
    $scope.folder = {};
    $scope.folder.name = folder.text;
    $scope.folder.path = folder.id;

    $scope.emptyEmailGroupOption = $translate.instant("DATASHEET.YOURDATASHEETS.MODALS.SHAREDATASHEETFOLDERWITHEMAIL.SLST_EMAIL_GROUPS.EMPTY_OPTION.TEXT");
    $scope.errors = [];

    $scope.shareButton = "DATASHEET.YOURDATASHEETS.MODALS.SHAREDATASHEETFOLDERWITHEMAIL.BTN_SHARE.TEXT";
    $scope.isProcessing = false;
    //Fields End

    //Events
    //Events End

    //Watches
    //Watches End

    //Functions
    $scope.previous = function() {
        $scope.formErrors = false;
        $scope.currentStep -= 1;
    };

    $scope.next = function() {
        $scope.errors = [];

        if (($scope.sharingModel.SelectedEmailGroup === null || angular.isUndefined($scope.sharingModel.SelectedEmailGroup) || $scope.sharingModel.SelectedEmailGroup === $scope.emptyEmailGroupOption) && ($scope.sharingModel.CustomEmails === null || angular.isUndefined($scope.sharingModel.CustomEmails) || $scope.sharingModel.CustomEmails === "")) {
           $scope.errors.push("DATASHEET.YOURDATASHEETS.MODALS.SHAREDATASHEETFOLDERWITHEMAIL.ERROR_MESSAGES.ADD_ONE_EMAIL");
        }


        if ($scope.sharingModel.selectedLanguages !== null) {
            var hasSelectedLanguage = false;
            angular.forEach($scope.sharingModel.selectedLanguages, function(value, key) {
                if (value)
                    hasSelectedLanguage = true;
            });

            if (hasSelectedLanguage === false) {
                $scope.errors.push("DATASHEET.YOURDATASHEETS.MODALS.SHAREDATASHEETFOLDERWITHEMAIL.ERROR_MESSAGES.SELECT_TDS_LANGUAGE");
            }
        }

        if ($scope.errors.length > 0) {
            return;
        }

        $scope.sharingModel.forms = [];
        angular.forEach($scope.folder.publisheds, function(publishedValueItem, publishedKeyItem) {
            var keepGoingLanguage = true;
            angular.forEach($scope.sharingModel.selectedLanguages, function(languageValueItem, languageKeyItem) {
                if (keepGoingLanguage) {
                    if (languageValueItem) {
                        var found = findFormsWithLanguage(publishedValueItem.pdfFiles, languageKeyItem);
                        if (found) {
                            $scope.sharingModel.forms.push(publishedValueItem);
                            keepGoingLanguage = false;
                        }
                    }
                }
            });
        });

        $scope.currentStep += 1;
    };

    $scope.share = function(isValid) {
        if (isValid) {
            $scope.shareButton = "DATASHEET.YOURDATASHEETS.MODALS.SHAREDATASHEETFOLDERWITHEMAIL.BTN_SHARE.ALTERNATE_TEXT";
            $scope.isProcessing = true;


            var requestModel = {};
            requestModel.forms = $scope.sharingModel.forms;
            requestModel.languages = [];
            angular.forEach($scope.sharingModel.selectedLanguages, function(value, key) {
                if (value) {
                    var selectedLanguage = $filter('filter')($scope.languages, {
                        "isoCode": key
                    })[0];

                    var object = {};
                    object.Language = key;

                    object.DisplayName = $translate.instant(selectedLanguage.languageCode);
                    requestModel.languages.push(object);
                }
            });
            requestModel.emailGroup = $scope.sharingModel.SelectedEmailGroup;
            requestModel.customEmails = $scope.sharingModel.CustomEmails;

            requestModel.subject = $scope.sharingModel.Subject;
            requestModel.message = $scope.sharingModel.Message;

            


            shareFactory.shareDatasheetFolderWithEmail(requestModel)
                .then(function(response) {
                    $scope.shareButton = "DATASHEET.YOURDATASHEETS.MODALS.SHAREDATASHEETFOLDERWITHEMAIL.BTN_SHARE.TEXT";
                    $scope.isProcessing = false;

                    var message = $translate.instant("DATASHEET.YOURDATASHEETS.MODALS.SHAREDATASHEETFOLDERWITHEMAIL.SUCCESS_MESSAGES.SUCCESSFUL_SHARE");
                    toastr.success(message, '', { positionClass: 'toast-bottom-full-width', timeOut: 3000 });

                    $timeout(function() {
                        $modalInstance.dismiss('cancel');
                    }, 500);


                });
        }
    };

    $scope.cancel = function() {
        $modalInstance.dismiss('cancel');
    };

    $scope.getProductName = function(published) {
        var datasheet = $filter('filter')($scope.folder.datasheets, {
            _id: published.datasheetId
        }, true)[0];
        return datasheet.productName;
    };

    $scope.getLanguageCode = function(language) {
        if (angular.isDefined($scope.languages)) {
            var selectedLanguage = $filter('filter')($scope.languages, {
                "isoCode": language
            })[0];

            return selectedLanguage.languageCode;
        }

    };
    //Functions End

    //Inner Functions
    var initialize = function() {
        //Datasheet de kullanılacak tüm diller sistemden çekilir.
        languageFactory.getLanguages()
            .then(function(response) {
                $scope.languages = response;
            });
        initializeSharingModel(function() {
            getAllFormsByFolder(function() {
                getAllEmailTags();
            });
        });
    };

    var initializeSharingModel = function(nextProcess) {
        $scope.sharingModel = {};
        $scope.sharingModel.selectedLanguages = {};
        $scope.sharingModel.Subject = $translate.instant("DATASHEET.YOURDATASHEETS.MODALS.SHAREDATASHEETFOLDERWITHEMAIL.SHAREEMAIL.SUBJECT");
        $scope.sharingModel.Message = $translate.instant('DATASHEET.YOURDATASHEETS.MODALS.SHAREDATASHEETFOLDERWITHEMAIL.SHAREEMAIL.MESSAGE');

        var userInformationData = localStorageService.get('userInformationData');
        if (userInformationData) {
            $scope.sharingModel.userFullName = userInformationData.FirstName + ' ' + userInformationData.LastName;
            $scope.sharingModel.Message = $scope.sharingModel.Message.replace("$UserName$", $scope.sharingModel.userFullName);
        }

        nextProcess();
    };

    var getAllFormsByFolder = function(nextProcess) {
        var folderId = folder.id;
        $scope.currentStep = 0;
        datasheetFactory.getAllPublishedByFolderId(folderId)
            .then(function(response) {
                if (response.datasheets.length === 0) {
                    $scope.currentStep = 0;
                } else {
                    $scope.currentStep = 1;
                    $scope.folder.datasheets = response.datasheets;
                    $scope.folder.publisheds = response.publisheds;
                    $scope.folder.languages = response.languages;
                }

                nextProcess();
            });
    };

    var getAllEmailTags = function() {
        $scope.sharingModel.EmailGroups = [];
        $scope.sharingModel.EmailGroups.push($scope.emptyEmailGroupOption);
        emailListService.getAllEmailTags()
            .then(function(response) {
                var emailTags = response;
                for (var i = 0; i < emailTags.length; i++) {
                    var emailTag = emailTags[i];

                    $scope.sharingModel.EmailGroups.push('' + emailTag + '');
                }

                if ($scope.sharingModel.EmailGroups.length > 0)
                    $scope.sharingModel.SelectedEmailGroup = $scope.sharingModel.EmailGroups[0];
            });
    };

    var findFormsWithLanguage = function(pdfFiles, lang) {
        var found = false;
        angular.forEach(pdfFiles, function(value, key) {
            if (value.language == lang)
                found = true;
        });

        return found;
    };
    //Inner Functions End

    //Initials
    initialize();
    //Initials End
};

module.exports = function(app) {
    app.controller('shareDatasheetFolderWithEmailCtrl', ['$scope', '$filter', '$timeout', '$translate', '$modalInstance', 'localStorageService', 'generalsService', 'emailListService', 'shareFactory', 'datasheetFactory', 'languageFactory', 'parentScope', 'folder', shareDatasheetFolderWithEmailCtrl]);
};
