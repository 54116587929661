require("./../../../../bower_components/angular/angular.js");
require('angular-ui-router');
require('angular-translate');
require("ng-tags-input");
require('../core/');

//Module
var mailGroupsModule = angular.module('tdsApp.mailGroups', [
    'ui.router',
    'tdsApp.core',
    'ngTagsInput',
    'pascalprecht.translate'
]);

//Router
require('./routing.js')(mailGroupsModule);

//Factories
require("./allEmails/factories/mailGroupFactory.js")(mailGroupsModule);

//Services
require("./shared/services/emailListService.js")(mailGroupsModule);

//Controllers
require("./allEmails/controllers/allEmailsController.js")(mailGroupsModule);
require("./allEmails/controllers/modals/addEmailListController.js")(mailGroupsModule);
require("./allEmails/controllers/modals/editEmailController.js")(mailGroupsModule);
require("./allEmails/controllers/modals/deleteEmailController.js")(mailGroupsModule);

//Export
module.exports = mailGroupsModule;
