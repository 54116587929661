var templateContextMenuFactory = function($rootScope, $filter, $translate, _, generalsService, elementService){
  var factoryModel = {};

  //Fields
  var templateElements = [];
  var copiedElement = {};
  var tCopy = $translate.instant('TEMPLATE.EDIT_TEMPLATE.CONTEXT_MENU.COPY');
  var tDelete = $translate.instant('TEMPLATE.EDIT_TEMPLATE.CONTEXT_MENU.DELETE');
  var tCopyAllColumnCells = $translate.instant('TEMPLATE.EDIT_TEMPLATE.CONTEXT_MENU.COPY_TO_ALL_COLUMN_CELLS');
  var tCopyAllRowCells = $translate.instant('TEMPLATE.EDIT_TEMPLATE.CONTEXT_MENU.COPY_TO_ALL_ROW_CELLS');
  var tRowOptions = $translate.instant('TEMPLATE.EDIT_TEMPLATE.CONTEXT_MENU.ROW_OPTIONS');
  var tAddRowAfter = $translate.instant('TEMPLATE.EDIT_TEMPLATE.CONTEXT_MENU.ADD_ROW_AFTER');
  var tAddRowBefore = $translate.instant('TEMPLATE.EDIT_TEMPLATE.CONTEXT_MENU.ADD_ROW_BEFORE');
  var tRemoveRow = $translate.instant('TEMPLATE.EDIT_TEMPLATE.CONTEXT_MENU.REMOVE_ROW');
  var tColumnOptions = $translate.instant('TEMPLATE.EDIT_TEMPLATE.CONTEXT_MENU.COLUMN_OPTIONS');
  var tAddColumnAfter = $translate.instant('TEMPLATE.EDIT_TEMPLATE.CONTEXT_MENU.ADD_COLUMN_AFTER');
  var tAddColumnBefore = $translate.instant('TEMPLATE.EDIT_TEMPLATE.CONTEXT_MENU.ADD_COLUMN_BEFORE');
  var tRemoveColumn = $translate.instant('TEMPLATE.EDIT_TEMPLATE.CONTEXT_MENU.REMOVE_COLUMN');
  var tMergeCells = $translate.instant('TEMPLATE.EDIT_TEMPLATE.CONTEXT_MENU.MERGE_CELLS');
  var tMergeHorizontal = $translate.instant('TEMPLATE.EDIT_TEMPLATE.CONTEXT_MENU.MERGE_HORIZONTAL');
  var tUnMergeHorizontal = $translate.instant('TEMPLATE.EDIT_TEMPLATE.CONTEXT_MENU.UNMERGE_HORIZONTAL');
  var tMergeVertical = $translate.instant('TEMPLATE.EDIT_TEMPLATE.CONTEXT_MENU.MERGE_VERTICAL');
  var tUnMergeVertical = $translate.instant('TEMPLATE.EDIT_TEMPLATE.CONTEXT_MENU.UNMERGE_VERTICAL');
  var tUnMergeAll = $translate.instant('TEMPLATE.EDIT_TEMPLATE.CONTEXT_MENU.UNMERGE_ALL');
  var tCopyTable = $translate.instant('TEMPLATE.EDIT_TEMPLATE.CONTEXT_MENU.COPY_TABLE');
  var tDeleteTable = $translate.instant('TEMPLATE.EDIT_TEMPLATE.CONTEXT_MENU.DELETE_TABLE');
  var tClearCells = $translate.instant('TEMPLATE.EDIT_TEMPLATE.CONTEXT_MENU.CLEAR_CELLS');
  var tClearColumn = $translate.instant('TEMPLATE.EDIT_TEMPLATE.CONTEXT_MENU.CLEAR_COLUMN');
  var tClearRow = $translate.instant('TEMPLATE.EDIT_TEMPLATE.CONTEXT_MENU.CLEAR_ROW');
  var tClearAllCells = $translate.instant('TEMPLATE.EDIT_TEMPLATE.CONTEXT_MENU.CLEAR_ALL_CELLS');
  var tCopyTable = $translate.instant('TEMPLATE.EDIT_TEMPLATE.CONTEXT_MENU.COPY_TABLE');
  var tDeleteTable = $translate.instant('TEMPLATE.EDIT_TEMPLATE.CONTEXT_MENU.DELETE_TABLE');
  var tAddItem = $translate.instant('TEMPLATE.EDIT_TEMPLATE.CONTEXT_MENU.ADD_ITEM');
  //Private Functions
  var removeElement = function($itemScope, $event, model){
    var indexOf = templateElements.indexOf(model);
    var elementParentId = model.parentId;
    templateElements.splice(indexOf, 1);
    $('.element-' + model._id).detach();


    var parent = _.filter(templateElements, { _id : elementParentId})[0];

    if(angular.isDefined(parent) && parent.type == 'cell'){
        var cellElements = _.filter(templateElements, { parentId : parent._id});

        if(cellElements.length == 0){
          $('#' + parent._id).find('.cellInfoText').show();
        }
    }



  };

  var copyElement = function($itemScope, $event, model){
    copiedElement = angular.copy(copiedElement);
  };

  var copyAllColunnCells = function(element, cell){
    var currentRow = _.filter(templateElements, { _id : cell.parentId})[0];
    var table = _.filter(templateElements, { _id : currentRow.parentId})[0];

    var rows = _.filter(templateElements, { parentId : table._id});

    for(var r in rows){
      var row = rows[r];
      if(row._id != currentRow._id){

        var currentCell = _.filter(templateElements, { parentId : row._id, order : cell.order})[0];

        if(angular.isDefined(currentCell)){
          var copiedElement = angular.copy(element);
          copiedElement.parentId = currentCell._id;
          copiedElement._id = generalsService.createObjectId();

          templateElements.push(copiedElement);
        }

      }
    }


    $rootScope.$broadcast('reCompileTable', { id : table._id});
  };
  var copyAllRowCells = function(element, cell){
      var row = _.filter(templateElements, { _id : cell.parentId})[0];
      var cells = _.filter(templateElements, { parentId : row._id});
      var tableId = row.parentId;

      for(var i in cells){
        var currentCell = cells[i];
        if(currentCell._id != cell._id){
          var copiedElement = angular.copy(element);
          copiedElement.parentId = currentCell._id;
          copiedElement._id = generalsService.createObjectId();
          templateElements.push(copiedElement);
        }
      }

      $rootScope.$broadcast('reCompileTable', { id : tableId});
  };
  var addRow = function(cell, target){
    var request = {};

    request.row = _.filter(templateElements, { _id : cell.parentId})[0];
    var tableId = request.row.parentId;

    request.cells = _.filter(templateElements, { type : 'cell', parentId : request.row._id});

    elementService.createNewRow(request, target, templateElements);

    $rootScope.$broadcast('reCompileTable', { id : tableId});
  };
  var removeRow = function(cell){
    var row = _.filter(templateElements, { _id : cell.parentId})[0];
    var tableId = row.parentId;
    var indexOf = templateElements.indexOf(row);
    templateElements.splice(indexOf, 1);
    $rootScope.$broadcast('reCompileTable', { id : tableId});
  };
  var addColumn = function(cell, target){
    var row = _.filter(templateElements, { _id : cell.parentId})[0];
    var table = _.filter(templateElements, { _id : row.parentId})[0];

    var model = {
       selectedCell : cell,
       selectedRow : row,
       selectedTable : table,
       selectedCellOrder : cell.order
    }

    elementService.createNewColumn(model, target, templateElements);
    $rootScope.$broadcast('reCompileTable', { id : table._id});
  };
  var removeColumn = function(cell){
    var cellOrder = cell.order;
    var row = _.filter(templateElements, { _id : cell.parentId})[0];
    var table = _.filter(templateElements, { _id : row.parentId})[0];

    //table headerlarından gerekli hücreyi sil
    var headerCell = _.filter(templateElements, { parentId : table._id, type : 'headercell', order : cellOrder})[0];
    var indexOf = templateElements.indexOf(headerCell);
    templateElements.splice(indexOf, 1);

    var rows = _.filter(templateElements, { parentId : table._id , type : 'row'});

    for(var i in rows){
      var row = rows[i];
      var targetCelL = _.filter(templateElements, { parentId : row._id, type : "cell", order : cellOrder})[0];
      indexOf = templateElements.indexOf(targetCelL);
      templateElements.splice(indexOf, 1);
    }

    $rootScope.$broadcast('reCompileTable', { id : table._id});
  };
  var mergeHorizontal = function(cell){
    var cellOrder = cell.order;
    var row = _.filter(templateElements, { _id : cell.parentId})[0];
    var table = _.filter(templateElements, { _id : row.parentId})[0];

    var nextCell = _.filter(templateElements, { parentId : row._id, order : cellOrder + 1, type : "cell"})[0];
    var prevCell = _.filter(templateElements, { parentId : row._id, order : cellOrder - 1, type : "cell"})[0];

    if(angular.isDefined(nextCell)){
      var indexOf = templateElements.indexOf(nextCell);
      cell.colSpan =cell.colSpan + nextCell.colSpan;
      templateElements.splice(indexOf, 1);
    }else if(angular.isUndefined(nextCell) && angular.isDefined(prevCell)){
      var indexOf = templateElements.indexOf(prevCell);
      cell.colSpan =cell.colSpan + prevCell.colSpan;

      templateElements.splice(indexOf, 1);
    }else{
      return;
    }

    $rootScope.$broadcast('reCompileTable', { id : table._id});
  };
  var unMergeHorizontal = function(cell){
    var cellOrder = cell.order;
    var row = _.filter(templateElements, { _id : cell.parentId})[0];
    var table = _.filter(templateElements, { _id : row.parentId})[0];

    var headercells = _.filter(templateElements, { parentId: table._id});
    var cells = _.filter(templateElements, { parentId : row._id});

    var addCellCount = cell.colSpan - 1;


    if((addCellCount + cells.length) > headercells.length){ // Fazla column eklemeyelim.
      addCellCount = headercells.length - cells.length;
    }

    for(var i = 1; i <= addCellCount; i++){
      var newCell = angular.copy(cell);
      newCell.colSpan = 1;
      newCell._id = generalsService.createObjectId();
      newCell.order = parseFloat(cell.order + "." + i);
      templateElements.push(newCell);
    }

    cell.colSpan = 1;
    $rootScope.$broadcast('reCompileTable', { id : table._id});
  }
  var mergeVertical = function(cell){
    var cellOrder = cell.order;
    var row = _.filter(templateElements, { _id : cell.parentId})[0];
    var rowOrder  = row.order;

    var table = _.filter(templateElements, { _id : row.parentId})[0];
    var rows = _.filter(templateElements, { parentId : table._id, type : "row"});

    var nextRowOrder = row.order + cell.rowSpan;

    if(nextRowOrder <= rows.length){ //Demekki hala bir sonraki satırda hedef hücre var bunu sil ve birleştir.
      var nextRow = _.filter(rows, { order : nextRowOrder})[0];
      var targetCell = _.filter(templateElements, { parentId : nextRow._id, order : cellOrder, type : 'cell'})[0];

      cell.rowSpan = cell.rowSpan + targetCell.rowSpan;

      var indexOf = templateElements.indexOf(targetCell);
      templateElements.splice(indexOf, 1);
    }else if(row.order > 1){ // next row yok ama prev row var

      var prevRow = _.filter(rows, { order : row.order - 1})[0];
      var targetCell = _.filter(templateElements, { parentId : prevRow._id, order : cellOrder, type : 'cell'})[0];

      cell.rowSpan = cell.rowSpan + targetCell.rowSpan;
      cell.parentId = prevRow._id;

      var indexOf = templateElements.indexOf(targetCell);
      templateElements.splice(indexOf, 1);
    }else{
      return;
    }


    $rootScope.$broadcast('reCompileTable', { id : table._id});
  };
  var unMergeVertical = function(cell){
    var cellOrder = cell.order;
    var row = _.filter(templateElements, { _id : cell.parentId})[0];
    var rowOrder  = row.order;
    var table = _.filter(templateElements, { _id : row.parentId})[0];
    var rows = _.filter(templateElements, { parentId : table._id, type : "row"});

    var addRowCount = cell.rowSpan - 1;

    for(var i = 1; i <= addRowCount; i++){
      var nextRow = _.filter(rows, { order : row.order + i})[0];

      if(angular.isDefined(nextRow)){
        var newCell = angular.copy(cell);
        newCell._id = generalsService.createObjectId();
        newCell.parentId = nextRow._id;
        newCell.rowSpan = 1;
        templateElements.push(newCell);
      }

    }

    cell.rowSpan =1;
    $rootScope.$broadcast('reCompileTable', { id : table._id});

  };
  var clearColumns = function(cell){
    var cellOrder = cell.order;
    var row = _.filter(templateElements, { _id : cell.parentId})[0];
    var rows = _.filter(templateElements, { parentId : row.parentId, type :'row'});

    for(var i in rows){
      var row = rows[i];
      var targetCell = _.filter(templateElements, { parentId : row._id, order : cellOrder, type : 'cell'})[0];

      var elements = _.filter(templateElements, { parentId : targetCell._id});
      for(var e in elements){
        var element = elements[e];
        var indexOf = templateElements.indexOf(element);
        templateElements.splice(indexOf, 1);
      }

    }

    $rootScope.$broadcast('reCompileTable', { id : row.parentId});

  };
  var clearRows = function(cell){
    var row = _.filter(templateElements, { _id : cell.parentId})[0];
    var cells = _.filter(templateElements, { parentId : row._id, type : 'cell'});

    for(var c in cells){
      var targetCell = cells[c];
      var elements = _.filter(templateElements, { parentId : targetCell._id});
      for(var e in elements){
        var element = elements[e];
        var indexOf = templateElements.indexOf(element);
        templateElements.splice(indexOf, 1);
      }
    }

    $rootScope.$broadcast('reCompileTable', { id : row.parentId});
  };
  var clearAllCells = function(cell){
    var row = _.filter(templateElements, { _id : cell.parentId})[0];
    var rows = _.filter(templateElements, { parentId : row.parentId, type : 'row'});

    for(var r in rows){
      var currentRow = rows[r];
      var cells = _.filter(templateElements, { parentId : currentRow._id, type : 'cell'});
      for(var c in cells){
        var targetCell = cells[c];

        var elements = _.filter(templateElements, { parentId : targetCell._id});
        for(var e in elements){
          var element = elements[e];
          var indexOf = templateElements.indexOf(element);
          templateElements.splice(indexOf, 1);
        }
      }
    }

    $rootScope.$broadcast('reCompileTable', { id : row.parentId});
  };
  var copyTable = function(cell){
    var row = _.filter(templateElements, { _id : cell.parentId})[0];
    var table = _.filter(templateElements, { _id : row.parentId, type : 'table'})[0];
    copiedElement = angular.copy(table);
  };
  var deleteTable = function(cell){
    var row = _.filter(templateElements, { _id : cell.parentId})[0];
    var table = _.filter(templateElements, { _id : row.parentId, type : 'table'})[0];
    var indexOf = templateElements.indexOf(table);

    $('#' + table._id).detach();

    templateElements.splice(indexOf, 1);
  };


  factoryModel.cellContextMenu = function(cell, elements){
    var contextMenu = [];
    templateElements = elements;

    //Add Item
    var addItem = [tAddItem, function($itemScope) {},
        [
            [$filter('fancyTypeName')('text'), function() {
              $rootScope.addingItemType = 'text';
              $rootScope.$broadcast('addItemToDropPanel', { id : cell._id});
            }],
            [$filter('fancyTypeName')('table'), function() {
              $rootScope.addingItemType = 'table';
              $rootScope.$broadcast('addItemToDropPanel', { id : cell._id});
            }],
            [$filter('fancyTypeName')('inputtext'), function() {
              $rootScope.addingItemType = 'inputtext';
              $rootScope.$broadcast('addItemToDropPanel', { id : cell._id});
            }],
            [$filter('fancyTypeName')('selectlist'), function() {
              $rootScope.addingItemType = 'selectlist';
              $rootScope.$broadcast('addItemToDropPanel', { id : cell._id});
            }]
        ]
    ];
    contextMenu.push(addItem);

    //Row Options
    var rowOptions = [
        tRowOptions,
        function($itemScope) {},
        [
            [tAddRowAfter, function(){
              addRow(cell, 'after');
            }],
            [tAddRowBefore, function(){
              addRow(cell, 'before');
            }],
            null, [tRemoveRow, function(){
              removeRow(cell);
            }]
        ]
    ];
    contextMenu.push(rowOptions);

    //Column Options
    var columnOptions = [
        tColumnOptions,
        function($itemScope) {},
        [
            [tAddColumnAfter, function(){
              addColumn(cell, 'after');
            }],
            [tAddColumnBefore, function(){
              addColumn(cell, 'before');
            }],
            null, [tRemoveColumn, function(){
              removeColumn(cell);
            }]
        ]
    ];
    contextMenu.push(columnOptions);

    //Merging
    var mergeSubOptions = [];
    mergeSubOptions.push([tMergeHorizontal, function(){
      mergeHorizontal(cell);
    }]);

    if(cell.colSpan > 1)
      mergeSubOptions.push([tUnMergeHorizontal, function(){
        unMergeHorizontal(cell);
      }]);

    mergeSubOptions.push(null);
    mergeSubOptions.push([tMergeVertical, function(){
      mergeVertical(cell);
    }]);

    if(cell.rowSpan > 1)
      mergeSubOptions.push([tUnMergeVertical, function(){
        unMergeVertical(cell);
      }]);

    var mergeOptions = [tMergeCells, function($itemScope){}, mergeSubOptions];
    contextMenu.push(mergeOptions);

    //Clear
    contextMenu.push([tClearCells, function($itemScope) {},[
        [tClearColumn, function(){
          clearColumns(cell);

        }],
        [tClearRow, function(){
          clearRows(cell);
        }],
        null,
        [tClearAllCells, function(){
          clearAllCells(cell);
        }]
    ]]);

    contextMenu.push(null);

    contextMenu.push([tCopyTable, function($itemScope){
      copyTable(cell);
    }]);

    contextMenu.push([tDeleteTable, function($itemScope){
      deleteTable(cell);
    }]);

    return contextMenu;
  };

  factoryModel.elementContextMenu = function(element, elements){
    var contextMenu = [];
    templateElements = elements;

    contextMenu.push([tCopy, copyElement]);
    contextMenu.push([tDelete, removeElement]);

    var parentElement = _.filter(templateElements, { _id : element.parentId})[0];

    if(parentElement.type == 'cell'){

      contextMenu.push([tCopyAllColumnCells, function(){
        copyAllColunnCells(element, parentElement);
      }]);


      contextMenu.push([tCopyAllRowCells, function(){
        copyAllRowCells(element, parentElement);
      }]);

      contextMenu.push(null);
      var cellOptions = factoryModel.cellContextMenu(parentElement, elements);
      contextMenu = contextMenu.concat(cellOptions);
    }


    return contextMenu;
  };

  return factoryModel;
};


module.exports = function(app){
  app.factory('templateContextMenuFactory', ['$rootScope', '$filter', '$translate', '_', 'generalsService', 'elementService', templateContextMenuFactory]);
};
