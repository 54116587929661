var datasheetOptionsCtrl = function($rootScope, $scope, $state, $filter,$translate, $modal, SweetAlert, datasheetFactory) {
    //Fields
    //Fields End

    //Functions
    $scope.openMoveDatasheetModal = function () {
        if (!angular.isUndefined($scope.selectedDatasheets[0]) && angular.equals($scope.selectedDatasheets[0], {}) === false) {
            var modalInstance = $modal.open({
                animation: true,
                controller: 'moveDatasheetCtrl',
                templateUrl: '/app/components/datasheet/yourDatasheets/views/modals/moveDatasheet.html',
                resolve: {
                    selectedDatasheet: function () {
                        return $scope.selectedDatasheets[0];
                    }
                }
            });

            modalInstance.result.then(function (response) {
                if (response.status) {
                    $scope.$emit('movedDatasheet', {});
                };
            });
        }
    };

    $scope.copyDatasheet = function () {
        if (!angular.isUndefined($scope.selectedDatasheets[0]) && angular.equals($scope.selectedDatasheets[0], {}) === false) {
            $state.go('app.copyDatasheet', {
                lang: $rootScope.activeLang,
                id: $scope.selectedDatasheets[0]._id,
                templateId: $scope.selectedDatasheets[0].templateId
            });
        }
    };

    $scope.openRenameDatasheetModal = function () {
        if (!angular.isUndefined($scope.selectedDatasheets[0]) && angular.equals($scope.selectedDatasheets[0], {}) === false) {
            var modalInstance = $modal.open({
                animation: false,
                controller: 'renameDatasheetCtrl',
                templateUrl: '/app/components/datasheet/yourDatasheets/views/modals/renameDatasheet.html',
                backdrop: "static",
                resolve: {
                    selectedDatasheet: function () {
                        return $scope.selectedDatasheets[0];
                    }
                }
            });

            modalInstance.result
                .then(function (result) {
                    if (result.status) {
                        $scope.$emit('renamedDatasheet', {});
                    }
                });
        }
    };

    $scope.openViewPdfModal = function () {
        if (!angular.isUndefined($scope.selectedDatasheets[0]) && angular.equals($scope.selectedDatasheets[0], {}) === false) {
            //Datasheet publish edildi mi kontrolu?
            if ($scope.selectedDatasheets[0].majorVersion > 0) {
                var modalInstance = $modal.open({
                    animation: false,
                    controller: 'viewDatasheetPdfCtrl',
                    templateUrl: '/app/components/datasheet/yourDatasheets/views/modals/viewDatasheetPdf.html',
                    resolve: {
                        datasheet: function () {
                            return $scope.selectedDatasheets[0];
                        },
                        version: function () {
                            return null;
                        }
                    }
                });

                modalInstance.result.then(function (response) {
                    if (response.status) {
                    }
                });
            }
            else {
                var title = $translate.instant("DATASHEET.YOURDATASHEETS.PARTIALS.DATASHEETOPTIONS.ERROR_MESSAGES.NOT_PUBLISHED_FOR_VIEW.LBL_TITLE.TEXT");
                var notPublished = $translate.instant("DATASHEET.YOURDATASHEETS.PARTIALS.DATASHEETOPTIONS.ERROR_MESSAGES.NOT_PUBLISHED_FOR_VIEW.LBL_EXPLANATION.TEXT");

                SweetAlert.swal({
                    title: title,
                    text: notPublished,
                    type: 'error'
                }, function () { });
            }
        }
    };

    $scope.editDatasheet = function () {
        if (angular.isUndefined($rootScope.activeLang)) {
            $rootScope.activeLang = "en";
        }

        var editDatasheetPermission = $filter('filter')($scope.selectedDatasheets[0].datasheetPermissions, {
            "Name": "Edit Datasheet"
        })[0];
        if (editDatasheetPermission == null || editDatasheetPermission == undefined) {
            return;
        }

        $state.go('editor.editDatasheet', {
            lang: $rootScope.activeLang,
            id: $scope.selectedDatasheets[0]._id
        });
    };

    $scope.goToDatasheetPermissions = function () {
        if (angular.isUndefined($rootScope.activeLang)) {
            $rootScope.activeLang = "en";
        }

        $state.go('app.datasheetPermissions', {
            lang: $rootScope.activeLang,
            datasheetId: $scope.selectedDatasheets[0]._id
        });
    };

    $scope.openDeleteDatasheetModal = function () {
        if (!angular.isUndefined($scope.selectedDatasheets[0]) && angular.equals($scope.selectedDatasheets[0], {}) === false) {
            var modalInstance = $modal.open({
                animation: false,
                controller: 'deleteDatasheetCtrl',
                templateUrl: '/app/components/datasheet/yourDatasheets/views/modals/deleteDatasheet.html',
                resolve: {
                    selectedDatasheet: function () {
                        return $scope.selectedDatasheets[0];
                    }
                }
            });

            modalInstance.result
                .then(function (result) {
                    if (result.status) {
                        $scope.$emit('deletedDatasheet', {});
                    }
                });
        }
    };

    $scope.openShareWithEmailModal = function () {
        if (!angular.isUndefined($scope.selectedDatasheets[0]) && angular.equals($scope.selectedDatasheets[0], {}) === false) {
            //Datasheet publish edildi mi kontrolu?
            if ($scope.selectedDatasheets[0].majorVersion > 0) {
                var modalInstance = $modal.open({
                    animation: true,
                    controller: 'shareDatasheetWithEmailCtrl',
                    templateUrl: '/app/components/datasheet/yourDatasheets/views/modals/shareDatasheetWithEmail.html',
                    resolve: {
                        parentScope: function () {
                            return $scope;
                        },
                        datasheet: function () {
                            return $scope.selectedDatasheets[0];
                        }
                    }
                });

            } else {
                var title = $translate.instant("DATASHEET.YOURDATASHEETS.PARTIALS.DATASHEETOPTIONS.ERROR_MESSAGES.NOT_PUBLISHED_FOR_SHARE.LBL_TITLE.TEXT");
                var notPublished = $translate.instant("DATASHEET.YOURDATASHEETS.PARTIALS.DATASHEETOPTIONS.ERROR_MESSAGES.NOT_PUBLISHED_FOR_SHARE.LBL_EXPLANATION.TEXT");

                SweetAlert.swal({
                    title: title,
                    text: notPublished,
                    type: 'error'
                }, function () { });
            }
        }
    };

    $scope.openRegenerateDatasheetModal = function () {
        if (!angular.isUndefined($scope.selectedDatasheets[0]) && angular.equals($scope.selectedDatasheets[0], {}) === false) {
            //Datasheet publish edildi mi kontrolu?
            if ($scope.selectedDatasheets[0].majorVersion > 0) {
                var modalInstance = $modal.open({
                    animation: true,
                    controller: 'regenerateDatasheetVersionCtrl',
                    templateUrl: '/app/components/datasheet/yourDatasheets/views/modals/regenerateDatasheetVersion.html',
                    resolve: {
                        parentScope: function () {
                            return $scope;
                        },
                        datasheet: function () {
                            return $scope.selectedDatasheets[0];
                        }
                    }
                });

            } else {
                var title = $translate.instant("DATASHEET.YOURDATASHEETS.PARTIALS.DATASHEETOPTIONS.ERROR_MESSAGES.NOT_PUBLISHED_FOR_SHARE.LBL_TITLE.TEXT");
                var notPublished = $translate.instant("DATASHEET.YOURDATASHEETS.PARTIALS.DATASHEETOPTIONS.ERROR_MESSAGES.NOT_PUBLISHED_FOR_SHARE.LBL_EXPLANATION.TEXT");

                SweetAlert.swal({
                    title: title,
                    text: notPublished,
                    type: 'error'
                }, function () { });
            }
        }
    };

    $scope.openMultiShareWithEmailModal = function () {

        if (!angular.isUndefined($scope.selectedDatasheets) && $scope.selectedDatasheets.length > 1) {
            var modalInstance = $modal.open({
                animation: true,
                controller: 'shareMultipleDatasheetWithEmailCtrl',
                templateUrl: '/app/components/datasheet/yourDatasheets/views/modals/shareMultipleDatasheetWithEmail.html',
                resolve: {
                    parentScope: function () {
                        return $scope;
                    },
                    datasheets: function () {
                        return _.filter($scope.selectedDatasheets, { isPublished: true });
                    }
                }
            });
        }
    };
    //Functions End

    //Initials
    //Initials End
};

module.exports = function(app) {
    app.controller('datasheetOptionsCtrl', ['$rootScope', '$scope', '$state', '$filter','$translate', '$modal', 'SweetAlert', 'datasheetFactory', datasheetOptionsCtrl]);
};
