var datasheetAutomaticTranslater = function ($modal, $q, $translate, googleTranslate, _) {
    return {
        restrict: 'E',
        replace: true,
        templateUrl: 'app/components/datasheet/shared/directives/datasheetAutomaticTranslater/datasheetAutomaticTranslater.html',
        controller: function ($scope) {
            var translateToOtherLangauges = function (text, language, targetLanguages, element, callback) {
                return googleTranslate.translate(text, language, targetLanguages)
                    .then(function (response) {
                        //Yazılan metni diğer dile otomatik olarak çevir.
                        for (var tl = 0, len = response.TranslateList.length; tl < len; tl++) {
                            var translateItem = response.TranslateList[tl];
                            if ($scope.selectionModel.selectedLanguage != translateItem.Language) {
                                element.values[translateItem.Language] = translateItem.Text;
                            }
                        }
                    });
            };

            $scope.onTranslateClick = function (targetLanguage) {
                var warningText = targetLanguage === "all"
                    ? $translate.instant("DATASHEET.DIRECTIVES.DATASHEETAUTOMATICTRANSLATER.MODALS.CONFIRMAUTOMATICTRANSLATER.LBL_WARNING_TRANSLATE.TEXT")
                    : $translate.instant("DATASHEET.DIRECTIVES.DATASHEETAUTOMATICTRANSLATER.MODALS.CONFIRMAUTOMATICTRANSLATER.LBL_WARNING_TRANSLATE_TO.TEXT", { language: $translate.instant('TEMPLATE.SHARED.LANGUAGES.' + targetLanguage.toUpperCase()) });

                swal({
                    title: $translate.instant("DATASHEET.DIRECTIVES.DATASHEETAUTOMATICTRANSLATER.MODALS.CONFIRMAUTOMATICTRANSLATER.LBL_TITLE.TEXT"),
                    text: warningText,
                    type: "warning",
                    showCancelButton: true,
                    cancelButtonText: $translate.instant("DATASHEET.DIRECTIVES.DATASHEETAUTOMATICTRANSLATER.MODALS.CONFIRMAUTOMATICTRANSLATER.BTN_CANCEL.TEXT"),
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: $translate.instant("DATASHEET.DIRECTIVES.DATASHEETAUTOMATICTRANSLATER.MODALS.CONFIRMAUTOMATICTRANSLATER.BTN_CONFIRM.TEXT"),
                    closeOnConfirm: true
                }, function () {
                    var targetLanguages = targetLanguage === "all" ? $scope.templateModel.template.languages.filter(function (language) { return language !== $scope.selectionModel.selectedLanguage }) : [targetLanguage];
                    var selectedLanguage = $scope.selectionModel.selectedLanguage;
                    var inputTexts = _.filter($scope.datasheetModel.elements, {
                        type: "inputtext"
                    });

                    var tPromises = [];
                    for (var a in inputTexts) {
                        var inputText = inputTexts[a];
                        var selectedValue = inputText.values[selectedLanguage];
                        if (selectedValue != "") {
                            var tPromise = translateToOtherLangauges(selectedValue, selectedLanguage, targetLanguages, inputText);
                            tPromises.push(tPromise);
                        }
                    }

                    $q.all(tPromises)
                        .then(function () {
                            var message = $translate.instant("DATASHEET.DIRECTIVES.DATASHEETAUTOMATICTRANSLATER.SUCCESS_MESSAGES.ALL_TRANSLATED_SUCCESSFULLY");
                            toastr.success(message, '', {
                                positionClass: 'toast-bottom-full-width ',
                                timeOut: 3000
                            });
                        });
                });

            };

            $scope.onCopyClick = function (targetLanguage) {
                var warningText = targetLanguage === "all"
                    ? $translate.instant("DATASHEET.DIRECTIVES.DATASHEETAUTOMATICTRANSLATER.MODALS.CONFIRMAUTOMATICTRANSLATER.LBL_WARNING_COPY.TEXT")
                    : $translate.instant("DATASHEET.DIRECTIVES.DATASHEETAUTOMATICTRANSLATER.MODALS.CONFIRMAUTOMATICTRANSLATER.LBL_WARNING_COPY_TO.TEXT", { language: $translate.instant('TEMPLATE.SHARED.LANGUAGES.' + targetLanguage.toUpperCase()) });

                swal({
                    title: $translate.instant("DATASHEET.DIRECTIVES.DATASHEETAUTOMATICTRANSLATER.MODALS.CONFIRMAUTOMATICTRANSLATER.LBL_TITLE.TEXT"),
                    text: warningText,
                    type: "warning",
                    showCancelButton: true,
                    cancelButtonText: $translate.instant("DATASHEET.DIRECTIVES.DATASHEETAUTOMATICTRANSLATER.MODALS.CONFIRMAUTOMATICTRANSLATER.BTN_CANCEL.TEXT"),
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: $translate.instant("DATASHEET.DIRECTIVES.DATASHEETAUTOMATICTRANSLATER.MODALS.CONFIRMAUTOMATICTRANSLATER.BTN_CONFIRM.TEXT"),
                    closeOnConfirm: true
                }, function () {
                    var targetLanguages = targetLanguage === "all" ? $scope.templateModel.template.languages.filter(function (language) { return language !== $scope.selectionModel.selectedLanguage }) : [targetLanguage];
                    var selectedLanguage = $scope.selectionModel.selectedLanguage;
                    var inputTexts = _.filter($scope.datasheetModel.elements, {
                        type: "inputtext"
                    });

                    for (var a in inputTexts) {
                        var inputText = inputTexts[a];
                        var selectedValue = inputText.values[selectedLanguage];
                        if (selectedValue != "") {
                            for (var tl in targetLanguages) {
                                var templateLanguage = targetLanguages[tl];
                                if (templateLanguage != selectedLanguage) {
                                    inputText.values[templateLanguage] = selectedValue;
                                }
                            }
                        }
                    }

                    var message = $translate.instant("DATASHEET.DIRECTIVES.DATASHEETAUTOMATICTRANSLATER.SUCCESS_MESSAGES.ALL_COPIED_SUCCESSFULLY");
                    toastr.success(message, '', {
                        positionClass: 'toast-bottom-full-width ',
                        timeOut: 3000
                    });
                });
            };
        },
        link: function (scope, elem, attrs) {
            //Fields
            var pnlAutomaticTranslate = elem;
            
            //Fields End
            scope.$on('$destroy', function () {
                pnlAutomaticTranslate.remove();
            });
            //Events End

            //Initials
            if (scope.templateModel.template.languages.length == 1) {
                elem.hide();
            }
            //Initials End
        }
    };
};

module.exports = function (app) {
    app.directive('datasheetAutomaticTranslater', ['$modal', '$q', '$translate', 'googleTranslate', '_', datasheetAutomaticTranslater]);
};
