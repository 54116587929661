var integrationInfosCtrl = function($scope) {
    //Fields
    
    //Fields End

    //Initials
    //Initials End
};

module.exports = function(app) {
    app.controller('integrationInfosCtrl', ['$scope', integrationInfosCtrl]);
};
