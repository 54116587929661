require('jquery-slimscroll');
require('./../../../../node_modules/angular-chart.js/dist/angular-chart.js');
require('v-accordion');

//Module
var dashboardModule = angular.module('tdsApp.dashboard', [
    'chart.js',
    'vAccordion'
]);

//Router
require('./routing.js')(dashboardModule);

//Factories
require("./shared/factories/analyticFactory.js")(dashboardModule);
require("./shared/factories/statisticFactory.js")(dashboardModule);

//Controllers
require("./dashboard/controllers/dashboardController.js")(dashboardModule);

//Partial Controllers
require("./dashboard/controllers/_partials/summaryInformationsController.js")(dashboardModule);
require("./dashboard/controllers/_partials/welcomeController.js")(dashboardModule);
require("./dashboard/controllers/_partials/pdfViewsChartController.js")(dashboardModule);
require("./dashboard/controllers/_partials/activitiesController.js")(dashboardModule);
require("./dashboard/controllers/_partials/monthlyStatisticsController.js")(dashboardModule);

module.exports = dashboardModule;
