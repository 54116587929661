var accountService = function($http, $q, config, httpProvider){
    var serviceModel = {};

    serviceModel.checkPricingModel = function(model) {
        var deferred = $q.defer();
        var res = $http.post(config.api[config.mode].fullApiUrl + "account/checkPricingModel", model);

        res.success(function(data, status, headers, cnfg) {
            deferred.resolve(data);
        });

        res.error(function(msg, code) {
            deferred.reject(msg);
        });

        return deferred.promise;
    };

    serviceModel.getAccount = function(accountId) {
        var params = "accountId=" + accountId;
        return httpProvider.get('account','getAccount', params);
    };

    return serviceModel;
;}

module.exports = function(app){
    app.service('accountService', ['$http', '$q', 'config', 'httpProvider', accountService]);
};
