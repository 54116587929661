var userInformationsCtrl = function ($rootScope, $scope, $stateParams, $translate, localStorageService, userFactory, jobTitleGroups, imageFactory) {
    //Fields
    $scope.updateButton = "USER.USERPROFILE.PARTIALS.USERINFORMATIONS.BTN_UPDATE.TEXT";
    $scope.isProcessing = false;


    $scope.mode = "View";

    $scope.authUserId = localStorageService.get('authorizationData')._id;
    $scope.userId = $stateParams.userId;
    //Fields End

    //Watches
    $scope.$watch('updateUser.Email', function (newValue, oldValue) {

        if (newValue != null && newValue != undefined) {
            $scope.updateUser.Email = newValue.toLowerCase();
        }
    });
    //Watches End

    //Functions
    $scope.isHidden = function (userProperty) {
        if (userProperty == null || angular.isUndefined(userProperty)) {
            return true;
        } else {
            return false;
        }
    };

    $scope.getElementStyleIfHidden = function (userPropery) {
        if (userPropery == null || angular.isUndefined(userPropery) || userPropery == "") {
            return {
                "display": "none"
            }
        }
    };

    $scope.getElementStyleIfSocialInfoHidden = function (userSocialInfo) {
        if (userSocialInfo == null || angular.isUndefined(userSocialInfo) || userSocialInfo == "" ||
            userSocialInfo.FacebookUrl == null || angular.isUndefined(userSocialInfo.FacebookUrl) || userSocialInfo.FacebookUrl == "" ||
            userSocialInfo.LinkedInUrl == null || angular.isUndefined(userSocialInfo.LinkedInUrl) || userSocialInfo.LinkedInUrl == "" ||
            userSocialInfo.TwitterUrl == null || angular.isUndefined(userSocialInfo.TwitterUrl) || userSocialInfo.TwitterUrl == "") {
            return {
                "visibility": "hidden"
            }
        }
    }

    $scope.editProfile = function () {
        //Profile Picture
        if ($scope.user.ProfilePicture == "assets/images/default-user.png") {
            $scope.btnRemoveProfilePhotoDisabled = true;
        }

        //Gender
        if ($scope.user.Gender == null || angular.isUndefined($scope.user.Gender)) {
            $scope.user.Gender = "Male";
        }

        $scope.updateUser = angular.copy($scope.user);
        $scope.mode = "Edit";


    };

    $scope.cancel = function () {
        $scope.mode = "View";
    };

    $scope.uploadProfilePhoto = function () {

        var activeLang = $rootScope.activeLang;
        var imageUploadSuccess = function (url) {
            $scope.updateUser.ProfilePicture = url;
            $scope.btnRemoveProfilePhotoDisabled = false;

            var message = $translate.instant("USER.USERPROFILE.PARTIALS.USERINFORMATIONS.SUCCESS_MESSAGES.UPLOADED_PROFILE_PHOTO");
            toastr.success(message, '', { positionClass: 'toast-bottom-full-width', timeOut: 3000 });

            if (!$scope.$$phase)
                $scope.$apply();

        };
        imageFactory.upload({
            conversions: ['crop', 'rotate', 'filter'],
            cropRatio: 1 / 1,
            cropForce: true,
        }).then(imageUploadSuccess);
    };

    $scope.removeProfilePhoto = function () {
        $scope.updateUser.ProfilePicture = "assets/images/default-user.png";
        $scope.btnRemoveProfilePhotoDisabled = true;
    };

    $scope.update = function (isValid, user) {
        if (isValid) {
            $scope.updateButton = "USER.USERPROFILE.PARTIALS.USERINFORMATIONS.BTN_UPDATE.ALTERNATE_TEXT";
            $scope.isProcessing = true;

            userFactory.updateUserProfile(user)
                .then(function (response) {
                    if (response.status) {
                        var message = $translate.instant("USER.USERPROFILE.PARTIALS.USERINFORMATIONS.SUCCESS_MESSAGES.UPDATED_USER_INFORMATION");
                        toastr.success(message, '', { positionClass: 'toast-bottom-full-width', timeOut: 3000 });

                        if (!response.sameEmail && response.emailInUse) {
                            var message3 = $translate.instant("USER.USERPROFILE.PARTIALS.USERINFORMATIONS.ERROR_MESSAGES.EMAIL_IN_USE");
                            toastr.warning(message3, '', { positionClass: 'toast-bottom-full-width', timeOut: 0 });

                            $scope.updateUser.Email = $scope.user.Email;
                        } else if (!response.sameEmail && !response.emailInUse) {
                            var message2 = $translate.instant("USER.USERPROFILE.PARTIALS.USERINFORMATIONS.SUCCESS_MESSAGES.UPDATED_EMAIL");
                            toastr.warning(message2, '', { positionClass: 'toast-bottom-full-width', timeOut: 0 });
                        }

                        //Profile picture değiştirildi mi?
                        if ($scope.updateUser.ProfilePicture != $scope.user.ProfilePicture) {
                            $rootScope.$broadcast('changedProfilePicture', {
                                profilePicture: $scope.updateUser.ProfilePicture
                            });
                        }

                        $scope.updateButton = "USER.USERPROFILE.PARTIALS.USERINFORMATIONS.BTN_UPDATE.TEXT";
                        $scope.isProcessing = false;

                        $scope.user = $scope.updateUser;
                        $scope.btnRemoveProfilePhotoDisabled = false;
                        $scope.mode = "View";


                        var userLocalData = localStorageService.get('userInformationData');
                        userFactory.getUserByUserId(userLocalData._id).then(function (userData) {
                            localStorageService.set('userInformationData', userData);
                        });
                    }


                });
        }
    };

    $scope.getUserJobTitle = function () {
        if (angular.isUndefined($scope.user) || angular.isUndefined($scope.user.JobTitle))
            return;

        for (var i = 0; i < jobTitleGroups.length; i++) {
            var jobTitleGroup = jobTitleGroups[i];
            for (var j = 0; j < jobTitleGroup.JobTitles.length; j++) {
                var jobTitle = jobTitleGroup.JobTitles[j];
                if (jobTitle.Name == $scope.user.JobTitle) {
                    return $translate.instant("USER.USERPROFILE.PARTIALS.USERINFORMATIONS.CONSTANTS.JOBTITLEGROUPS." + jobTitleGroup.LanguageCode + ".JOBTITLES." + jobTitle.LanguageCode + ".TEXT");
                }

            }
        }

        return "";
    };
    //Functions End

    //Inner Functions
    var getUserByUserId = function (userId) {
        userFactory.getUserByUserId(userId)
            .then(function (response) {
                $scope.user = response;

                if ($scope.user.ProfilePicture == null || angular.isUndefined($scope.user.ProfilePicture)) {
                    $scope.user.ProfilePicture = "assets/images/default-user.png"
                    $scope.btnRemoveProfilePhotoDisabled = true;
                }
            });
    };
    //Inner Functions End

    //Initials
    $scope.jobTitleGroups = jobTitleGroups;
    getUserByUserId($scope.userId);

    //Initials End
};

module.exports = function (app) {
    app.controller('userInformationsCtrl', ['$rootScope', '$scope', '$stateParams', '$translate', 'localStorageService', 'userFactory', 'jobTitleGroups', 'imageFactory', userInformationsCtrl]);
};
