var newAccountSubscriptionCtrl = function($rootScope, $scope, $translate, $modalInstance, localStorageService, selectedAccount, adminFactory) {
    //Fields
    var user = localStorageService.get('userInformationData');

    $scope.packages = {
        "freemium": {
            name: "Freemium",
            userLimit: 1,
            templateLimit: 2,
            datasheetLimit: 2,
            storageLimit: 100,
            storageLimitAs: 'mb',
            price: 0,
            currency: 'usd'
        },
        "standart": {
            name: "Standart",
            userLimit: 2,
            templateLimit: 5,
            datasheetLimit: 100,
            storageLimit: 500,
            storageLimitAs: 'mb',
            price: 25,
            currency: 'usd'
        },
        "professional": {
            name: "Professional",
            userLimit: -1,
            templateLimit: 10,
            datasheetLimit: 1000,
            storageLimit: 1,
            storageLimitAs: 'gb',
            price: 125,
            currency: 'usd'
        },
        "premium": {
            name: "Premium",
            userLimit: -1,
            templateLimit: 25,
            datasheetLimit: 5000,
            storageLimit: 1.5,
            storageLimitAs: 'gb',
            price: 250,
            currency: 'usd'
        },
        "ultimate": {
            name: "Ultimate",
            userLimit: -1,
            templateLimit: -1,
            datasheetLimit: 10000,
            storageLimit: 4,
            storageLimitAs: 'gb',
            price: 400,
            currency: 'usd'
        }
    };

    $scope.subscription = {
        accountId: selectedAccount._id,
        createdBy: user._id,
        defaultPackage: {},
        purchasedPackage: {}
    };
    $scope.isProcessing = false;
    $scope.saveButton = "ADMIN.PARTIALS.ACCOUNTS.ACCOUNT_SUBSCRIPTIONS.SHARED.BTN_SAVE.TEXT";
    //Fields End
    
    //Functions
    $scope.cancel = function() {
        $modalInstance.dismiss();
    };

    $scope.newAccountSubscription = function(isValid) {
        if (isValid) {
            $scope.saveButton = "ADMIN.PARTIALS.ACCOUNTS.ACCOUNT_SUBSCRIPTIONS.SHARED.BTN_SAVE.SAVING";
            $scope.isProcessing = true;
            $scope.subscription.purchasedPackage.startDate = new Date($scope.subscription.purchasedPackage.filteredStartDate).toISOString();
            $scope.subscription.purchasedPackage.endDate = new Date($scope.subscription.purchasedPackage.filteredEndDate).toISOString();
            adminFactory.newAccountSubscription($scope.subscription)
                .then(function(response) {
                    $scope.isProcessing = false;
                    $scope.updateButton = "ADMIN.PARTIALS.ACCOUNTS.ACCOUNT_SUBSCRIPTIONS.SHARED.BTN_SAVE.TEXT";
                    if (response) {
                        var message = $translate.instant("ADMIN.PARTIALS.ACCOUNTS.ACCOUNT_SUBSCRIPTIONS.SHARED.BTN_SAVE.NOTIFICATION");
                        toastr.success(message, '', { positionClass: 'toast-bottom-full-width', timeOut: 3000 });
                        $modalInstance.close({
                            status: true
                        });
                    }
                });
        }
    };

    $scope.changedPackagePreset = function() {
        switch($scope.slcPackagePreset) {
            case 'none':
                $scope.subscription.defaultPackage.name = "";
                $scope.subscription.defaultPackage.price = "";
                $scope.subscription.defaultPackage.currency = "usd";
                $scope.subscription.defaultPackage.userLimit = "";
                $scope.subscription.defaultPackage.templateLimit = "";
                $scope.subscription.defaultPackage.datasheetLimit = "";
                $scope.subscription.defaultPackage.storageLimit = "";
                $scope.subscription.defaultPackage.storageLimitAs = 'mb';

                $scope.subscription.purchasedPackage.currency = "usd";
                $scope.subscription.purchasedPackage.userLimit = "";
                $scope.subscription.purchasedPackage.templateLimit = "";
                $scope.subscription.purchasedPackage.datasheetLimit = "";
                $scope.subscription.purchasedPackage.storageLimit = "";
                $scope.subscription.purchasedPackage.storageLimitAs = "";
                break;
            case 'freemium':
                $scope.subscription.defaultPackage.name = $scope.packages.freemium.name;
                $scope.subscription.defaultPackage.price = $scope.packages.freemium.price;
                $scope.subscription.defaultPackage.currency = $scope.packages.freemium.currency;
                $scope.subscription.defaultPackage.userLimit = $scope.packages.freemium.userLimit;
                $scope.subscription.defaultPackage.templateLimit = $scope.packages.freemium.templateLimit;
                $scope.subscription.defaultPackage.datasheetLimit = $scope.packages.freemium.datasheetLimit;
                $scope.subscription.defaultPackage.storageLimit = $scope.packages.freemium.storageLimit;
                $scope.subscription.defaultPackage.storageLimitAs = $scope.packages.freemium.storageLimitAs;

                $scope.subscription.purchasedPackage.currency = selectedAccountSubscription.currency;
                $scope.subscription.purchasedPackage.userLimit = selectedAccountSubscription.userLimit;
                $scope.subscription.purchasedPackage.templateLimit = selectedAccountSubscription.templateLimit;
                $scope.subscription.purchasedPackage.datasheetLimit = selectedAccountSubscription.datasheetLimit;
                $scope.subscription.purchasedPackage.storageLimit = selectedAccountSubscription.storageLimit;
                $scope.subscription.purchasedPackage.storageLimitAs = selectedAccountSubscription.storageLimitAs;
                break;
            case 'standart':
                $scope.subscription.defaultPackage.name = $scope.packages.standart.name;
                $scope.subscription.defaultPackage.price = $scope.packages.standart.price;
                $scope.subscription.defaultPackage.currency = $scope.packages.standart.currency;
                $scope.subscription.defaultPackage.userLimit = $scope.packages.standart.userLimit;
                $scope.subscription.defaultPackage.templateLimit = $scope.packages.standart.templateLimit;
                $scope.subscription.defaultPackage.datasheetLimit = $scope.packages.standart.datasheetLimit;
                $scope.subscription.defaultPackage.storageLimit = $scope.packages.standart.storageLimit;
                $scope.subscription.defaultPackage.storageLimitAs = $scope.packages.standart.storageLimitAs;

                $scope.subscription.purchasedPackage.currency = $scope.packages.standart.currency;
                $scope.subscription.purchasedPackage.userLimit = $scope.packages.standart.userLimit;
                $scope.subscription.purchasedPackage.templateLimit = $scope.packages.standart.templateLimit;
                $scope.subscription.purchasedPackage.datasheetLimit = $scope.packages.standart.datasheetLimit;
                $scope.subscription.purchasedPackage.storageLimit = $scope.packages.standart.storageLimit;
                $scope.subscription.purchasedPackage.storageLimitAs = $scope.packages.standart.storageLimitAs;
                break;
            case 'professional':
                $scope.subscription.defaultPackage.name = $scope.packages.professional.name;
                $scope.subscription.defaultPackage.price = $scope.packages.professional.price;
                $scope.subscription.defaultPackage.currency = $scope.packages.professional.currency;
                $scope.subscription.defaultPackage.userLimit = $scope.packages.professional.userLimit;
                $scope.subscription.defaultPackage.templateLimit = $scope.packages.professional.templateLimit;
                $scope.subscription.defaultPackage.datasheetLimit = $scope.packages.professional.datasheetLimit;
                $scope.subscription.defaultPackage.storageLimit = $scope.packages.professional.storageLimit;
                $scope.subscription.defaultPackage.storageLimitAs = $scope.packages.professional.storageLimitAs;

                $scope.subscription.purchasedPackage.currency = $scope.packages.professional.currency;
                $scope.subscription.purchasedPackage.userLimit = $scope.packages.professional.userLimit;
                $scope.subscription.purchasedPackage.templateLimit = $scope.packages.professional.templateLimit;
                $scope.subscription.purchasedPackage.datasheetLimit = $scope.packages.professional.datasheetLimit;
                $scope.subscription.purchasedPackage.storageLimit = $scope.packages.professional.storageLimit;
                $scope.subscription.purchasedPackage.storageLimitAs = $scope.packages.professional.storageLimitAs;
                break;
            case 'premium':
                $scope.subscription.defaultPackage.name = $scope.packages.premium.name;
                $scope.subscription.defaultPackage.price = $scope.packages.premium.price;
                $scope.subscription.defaultPackage.currency = $scope.packages.premium.currency;
                $scope.subscription.defaultPackage.userLimit = $scope.packages.premium.userLimit;
                $scope.subscription.defaultPackage.templateLimit = $scope.packages.premium.templateLimit;
                $scope.subscription.defaultPackage.datasheetLimit = $scope.packages.premium.datasheetLimit;
                $scope.subscription.defaultPackage.storageLimit = $scope.packages.premium.storageLimit;
                $scope.subscription.defaultPackage.storageLimitAs = $scope.packages.premium.storageLimitAs;

                $scope.subscription.purchasedPackage.currency = $scope.packages.premium.currency;
                $scope.subscription.purchasedPackage.userLimit = $scope.packages.premium.userLimit;
                $scope.subscription.purchasedPackage.templateLimit = $scope.packages.premium.templateLimit;
                $scope.subscription.purchasedPackage.datasheetLimit = $scope.packages.premium.datasheetLimit;
                $scope.subscription.purchasedPackage.storageLimit = $scope.packages.premium.storageLimit;
                $scope.subscription.purchasedPackage.storageLimitAs = $scope.packages.premium.storageLimitAs;
                break;
            case 'ultimate':
                $scope.subscription.defaultPackage.name = $scope.packages.ultimate.name;
                $scope.subscription.defaultPackage.price = $scope.packages.ultimate.price;
                $scope.subscription.defaultPackage.currency = $scope.packages.ultimate.currency;
                $scope.subscription.defaultPackage.userLimit = $scope.packages.ultimate.userLimit;
                $scope.subscription.defaultPackage.templateLimit = $scope.packages.ultimate.templateLimit;
                $scope.subscription.defaultPackage.datasheetLimit = $scope.packages.ultimate.datasheetLimit;
                $scope.subscription.defaultPackage.storageLimit = $scope.packages.ultimate.storageLimit;
                $scope.subscription.defaultPackage.storageLimitAs = $scope.packages.ultimate.storageLimitAs;

                $scope.subscription.purchasedPackage.currency = $scope.packages.ultimate.currency;
                $scope.subscription.purchasedPackage.userLimit = $scope.packages.ultimate.userLimit;
                $scope.subscription.purchasedPackage.templateLimit = $scope.packages.ultimate.templateLimit;
                $scope.subscription.purchasedPackage.datasheetLimit = $scope.packages.ultimate.datasheetLimit;
                $scope.subscription.purchasedPackage.storageLimit = $scope.packages.ultimate.storageLimit;
                $scope.subscription.purchasedPackage.storageLimitAs = $scope.packages.ultimate.storageLimitAs;
                break;
        }
    }
    //Functions End

    //Initials
    $('#numPurchasedPackageStartDate').inputmask({
    mask: '999-99-999-9999-9'
    })
    //Initials End
};

module.exports = function(app) {
    app.controller('newAccountSubscriptionCtrl', ['$rootScope', '$scope', '$translate', '$modalInstance', 'localStorageService', 'selectedAccount', 'adminFactory', newAccountSubscriptionCtrl]);
};
