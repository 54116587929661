var initialisation = function ($rootScope) {
    return {
        restrict: 'A',
        link: function ($scope) {
            var to;
            var listener = $scope.$watch(function () {
                clearTimeout(to);
                to = setTimeout(function () {
                    listener();
                    $rootScope.$broadcast('initialised');
                }, 50);
            });
        }
    };
}

module.exports = function (app) {
    app.directive('initialisation', ['$rootScope', initialisation]);
};