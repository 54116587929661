var summaryInformationsCtrl = function($scope, statisticFactory) {
    //Fields
    $scope.summaryInformation = {};
    //Fields End

    //Inner Functions
    var getSummaryInformations = function() {
        statisticFactory.getSummaryInformations()
            .then(function(response) {
                $scope.summaryInformation = response;
            });
    };
    //Inner Functions End

    //Initials
    getSummaryInformations();
    //Initials End
};

module.exports = function(app) {
    app.controller('summaryInformationsCtrl', ['$scope', 'statisticFactory', summaryInformationsCtrl]);
}
