var menuToggle = function(menuToggleFactory, localStorageService) {
    return {
        restrict: 'AE',
        link: function(scope, el, attrs) {
            var openLeftBtn = $(".open-left");
            var sideMenuStatus = localStorageService.get('sideMenuStatus');
            if (sideMenuStatus != null && angular.isDefined(sideMenuStatus)) {
                if (sideMenuStatus == "Expand") {
                    menuToggleFactory.expand();
                } else {
                    menuToggleFactory.collapse();
                }
            }

            openLeftBtn.bind('click', function(e) {
                menuToggleFactory.toggle();
            });
            
            menuToggleFactory.init(1000);
        }
    };
};


module.exports = function(app) {
    app.directive('menuToggle', ['menuToggleFactory', 'localStorageService', menuToggle]);
};
