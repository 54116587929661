var customArchiveMenuInSideMenuCtrl = function($rootScope, $scope, $stateParams, $filter, archiveFactory) {
    //Fields
    $scope.selectedArchive = $stateParams.customArchiveId;
    //Fields End

    //Events
    $rootScope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams, options) {
        if (toState.name == "app.yourArchives") {
            $scope.selectedArchive = toParams.customArchiveId;
        } else {
            $scope.selectedArchive = undefined;
        }
    });

    $rootScope.$on('addedNewArchiveScenario', function(event, data) {
        hasActiveCustomArchive(function() {
            getAllActiveArchives();
        });
    });

    $rootScope.$on('editedNewArchiveScenario', function(event, data) {
        hasActiveCustomArchive(function() {
            getAllActiveArchives();
        });
    });

    $rootScope.$on('deletedNewArchiveScenario', function(event, data) {
        hasActiveCustomArchive(function() {
            getAllActiveArchives();
        });
    });
    //Events End

    //Inner Functions
    var hasActiveCustomArchive = function(nextProcess) {
        archiveFactory.getActiveCustomArchiveCount()
            .then(function(count) {
                $scope.hasActiveCustomArchive = count > 0;

                if ($scope.hasActiveCustomArchive) {
                    nextProcess();

                }
            });
    };

    var getAllActiveArchives = function() {
        archiveFactory.getAllArchives()
            .then(function(response) {
                var archives = response;

                var activeArchives = $filter('filter')(archives, {
                    "isActive": true
                });

                $scope.activeArchives = activeArchives;
            });
    };
    //Inner Functions End

    //Initials
    hasActiveCustomArchive(function() {
        getAllActiveArchives();
    });
    //Initials End
};

module.exports = function(app) {
    app.controller('customArchiveMenuInSideMenuCtrl', ['$rootScope', '$scope', '$stateParams', '$filter', 'archiveFactory', customArchiveMenuInSideMenuCtrl]);
};
