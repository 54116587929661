var datasheetPages = function ($compile, datasheetViewService, datasheetViewCacheService, _) {
    return {
        restrict: 'E',
        replace: true,
        templateUrl: 'app/components/datasheet/shared/directives/datasheetPages/tpl/datasheetPages.html',
        link: function (scope, elem, attrs) {
            //Fields 
            //Fields End

            //Inner Functions
            var setInitialElements = function () {
                var pages = _.filter(scope.datasheetModel.elements, {
                    type: 'page'
                });
                pages = _.sortBy(pages, 'order');

                for (var i = 0, len = pages.length; i < len; i++) {
                    var page = pages[i];

                    var childScope = scope.$new();
                    childScope.page = page;

                    var html = datasheetViewService.createDatasheetPage();
                    $(elem).append($compile(html)(childScope));

                }
            };
            //Inner Functions End

            //Functions
            //Functions End

            //Events
            scope.$on('$destroy', function () {
                elem.remove();
            });
            //Events End

            //Initials 
            setInitialElements();
            //Initials End
        }
    };
};

module.exports = function (app) {
    app.directive('datasheetPages', ['$compile', 'datasheetViewService', 'datasheetViewCacheService', '_', datasheetPages]);
};