//Module
var customArchivesModule = angular.module('tdsApp.customArchives', ['tdsApp.core']);

//Routers
require('./routing.js')(customArchivesModule);

//Run

//Factories
require('./shared/factories/archiveFactory.js')(customArchivesModule);
require('./shared/factories/archiveFileFactory.js')(customArchivesModule);

//Controllers
require('./allArchives/controllers/allArchivesController.js')(customArchivesModule);
require('./editArchive/controllers/editArchiveController.js')(customArchivesModule);
require('./newArchive/controllers/newArchiveController.js')(customArchivesModule);
require('./yourArchives/controllers/yourArchivesController.js')(customArchivesModule);
require('./uploadFiles/controllers/uploadFilesController.js')(customArchivesModule);

//Partial Controllers
require('./shared/controllers/_partials/customArchiveMenuInSideMenuController.js')(customArchivesModule);

//Modal Controllers
require('./shared/controllers/modals/iconPickerController.js')(customArchivesModule);
require('./uploadFiles/controllers/modals/confirmDeleteFileController.js')(customArchivesModule);
require('./uploadFiles/controllers/modals/warningInUploadFilesController.js')(customArchivesModule);
require('./allArchives/controllers/modals/deleteArchiveController.js')(customArchivesModule);
require('./editArchive/controllers/modals/confirmChangedArchiveFieldsController.js')(customArchivesModule);
require('./yourArchives/controllers/modals/deleteArchiveFileController.js')(customArchivesModule);
require('./yourArchives/controllers/modals/manageArchiveFileFieldsController.js')(customArchivesModule);
require('./yourArchives/controllers/modals/moveArchiveFileController.js')(customArchivesModule);
require('./yourArchives/controllers/modals/shareArchiveFileWithEmailController.js')(customArchivesModule);
require('./yourArchives/controllers/modals/shareArchiveFolderWithEmailController.js')(customArchivesModule);

module.exports = customArchivesModule;
