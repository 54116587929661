var shareOnSocialMediaCtrl = function ($rootScope, $scope, $modalInstance, $filter, datasheet, config, languageFactory, userFactory, localStorageService) {
    //Fields
    $scope.datasheetLanguages = datasheet.names;
    var selectedDatasheet = _.find($scope.datasheetLanguages, function(tds){ return tds.language == $scope.datasheetLanguages[0].language; });
    $scope.facebookUrl = "https://www.facebook.com/sharer/sharer.php?u=" + config.app[config.mode].appUrl + "/" + $rootScope.activeLang + "/pdf/view/" + datasheet._id + "/" + $scope.datasheetLanguages[0].language + "/facebook";
    $scope.twitterUrl = "https://twitter.com/intent/tweet?text=" + selectedDatasheet.name + "&amp;url=" + config.app[config.mode].appUrl + "/" + $rootScope.activeLang + "/pdf/view/" + datasheet._id + "/" + $scope.datasheetLanguages[0].language + "/twitter" + "&amp;via=TDSmaker";
    $scope.linkedinUrl = "https://www.linkedin.com/shareArticle?mini=true&url=" + config.app[config.mode].appUrl + "/" + $rootScope.activeLang + "/pdf/view/" + datasheet._id + "/" + $scope.datasheetLanguages[0].language + "/linkedin" + "&amp;title=" + selectedDatasheet.name;
    $scope.showButtons = datasheet.showButtons;
    //Fields End

    //Functions
    $scope.cancel = function () {
        $modalInstance.dismiss('cancel');
    };

    $scope.getLanguageCode = function(language) {
        if (angular.isDefined($scope.languages)) {
            var selectedLanguage = $filter('filter')($scope.languages, {
                "isoCode": language
            })[0];
            return selectedLanguage.languageCode;
        }
    };

    $scope.updateShareLinks = function() {
        selectedDatasheet = _.find($scope.datasheetLanguages, function(tds){ return tds.language == $scope.selectedDatasheetLanguage; });
        $scope.facebookUrl = "https://www.facebook.com/sharer/sharer.php?u=" + config.app[config.mode].appUrl + "/" + $rootScope.activeLang + "/pdf/view/" + datasheet._id + "/" + $scope.selectedDatasheetLanguage + "/facebook";
        $scope.twitterUrl = "https://twitter.com/intent/tweet?text=" + selectedDatasheet.name + "&amp;url=" + config.app[config.mode].appUrl + "/" + $rootScope.activeLang + "/pdf/view/" + datasheet._id + "/" + $scope.selectedDatasheetLanguage + "/twitter" + "&amp;via=TDSmaker";
        $scope.linkedinUrl = "https://www.linkedin.com/shareArticle?mini=true&url=" + config.app[config.mode].appUrl + "/" + $rootScope.activeLang + "/pdf/view/" + datasheet._id + "/" + $scope.selectedDatasheetLanguage + "/linkedin" + "&amp;title=" + selectedDatasheet.name;
    }

    $scope.cancelAndSave = function() {
        userFactory.savePreference("SocialMediaDatasheetPrompt", "false")
                    .then(function (response) {
                        var uID = localStorageService.get('userInformationData');
                        uID.Preferences.SocialMediaDatasheetPrompt = false;
                        localStorageService.set('userInformationData', uID);
                        $modalInstance.dismiss('cancel');
                    });
                    
    }
    //Functions End

    //Initials
    languageFactory.getLanguages()
            .then(function(response) {
                $scope.languages = response;
            });
    //Initials End
};

module.exports = function (app) {
    app.controller('shareOnSocialMediaCtrl', ['$rootScope', '$scope', '$modalInstance', '$filter', 'datasheet', 'config', 'languageFactory', 'userFactory', 'localStorageService', shareOnSocialMediaCtrl]);
};