//USER STATUS
var userStatusType = {
    0: {
        Value: "Expected to be accepted",
        Code: "ENUM_TO_BE_ACCEPTED"
    },
    1: {
        Value: "Expected to be completed",
        Code: "ENUM_TO_BE_COMPLETED"
    },
    2: {
        Value: "Enabled",
        Code: "ENUM_ENABLED"
    },
    3: {
        Value: "Disabled",
        Code: "ENUM_DISABLED"
    }
};

module.exports = function(app) {
    app.constant('userStatusType', userStatusType);
};
