var renameFolderCtrl = function($rootScope, $scope, $translate, folderFactory) {
    //Fields
    $scope.okButton = "FOLDER.PARTIALS.RENAMEFOLDER.BTN_OK.TEXT";
    $scope.isProcessing = false;
    //Fields End

    //Events
    $rootScope.$on('renameFolderOpenned', function(event, data) {
        $scope.newFolderName = data.selectedFolderName;
    });


    $rootScope.$on('closeNewFolderOrRenameFolder', function(event, data) {
        $('#renameFolder').css("display","none");
    });
    //Events End

    //Functions
    $scope.cancel = function() {
        $('#renameFolder').slideUp();
    };

    $scope.renameFolder = function() {
        if ($scope.renameFolderForm.$invalid) return;
        $scope.okButton = "FOLDER.PARTIALS.RENAMEFOLDER.BTN_OK.ALTERNATE_TEXT";
        $scope.isProcessing = true;

        folderFactory.changeFolderName($scope.selectedFolder.id, $scope.newFolderName)
            .then(function(response) {
                $scope.okButton = "FOLDER.PARTIALS.RENAMEFOLDER.BTN_OK.TEXT";
                $scope.isProcessing = false;

                $('#renameFolder').slideToggle();

                var message = $translate.instant("FOLDER.PARTIALS.RENAMEFOLDER.SUCCESS_MESSAGES.RENAMED_FOLDER");
                toastr.success(message, '', { positionClass: 'toast-bottom-full-width', timeOut: 3000 });

                $scope.$emit('renamedFolder', {});
            });
    };
    //Functions End

    //Initials
    //Initials End
};

module.exports = function(app) {
    app.controller('renameFolderCtrl', ['$rootScope', '$scope','$translate', 'folderFactory', renameFolderCtrl]);
};
