var deleteDatasheetCtrl = function($scope, $translate, $modalInstance, datasheetFactory, selectedDatasheet) {
    //Fields
    $scope.deleteButton = "DATASHEET.YOURDATASHEETS.MODALS.DELETEDATASHEET.BTN_DELETE.TEXT";
    $scope.isProcessing = false;
    //Fields End

    //Functions
    $scope.cancel = function() {
        $modalInstance.dismiss();
    };

    $scope.deleteDatasheet = function() {
        $scope.deleteButton = "DATASHEET.YOURDATASHEETS.MODALS.DELETEDATASHEET.BTN_DELETE.ALTERNATE_TEXT";
        $scope.isProcessing = true;
        datasheetFactory.removeDatasheet(selectedDatasheet._id)
            .then(function(response) {
                $scope.deleteButton = "DATASHEET.YOURDATASHEETS.MODALS.DELETEDATASHEET.BTN_DELETE.TEXT";
                $scope.isProcessing = false;

                var message = $translate.instant("DATASHEET.YOURDATASHEETS.MODALS.DELETEDATASHEET.SUCCESS_MESSAGES.DELETED_SUCCESSFULLY");
                toastr.success(message, '', { positionClass: 'toast-bottom-full-width', timeOut: 3000 });

                $modalInstance.close({
                    status: true
                });
            });
    };
    //Functions End

    //Initials
    //Initials End
};

module.exports = function(app) {
    app.controller('deleteDatasheetCtrl', ['$scope', '$translate', '$modalInstance', 'datasheetFactory', 'selectedDatasheet', deleteDatasheetCtrl])
};
