var config = function ($translateProvider, $translatePartialLoaderProvider) {
    //Translate settings
    $translateProvider.useSanitizeValueStrategy('escaped');

    //Modelleri Part olarak yükleyelim - Daha geniş zamanda bu configleri kendi modulleri altına tasıyalım.
    $translatePartialLoaderProvider.addPart('admin');
    $translatePartialLoaderProvider.addPart('authentication');
    $translatePartialLoaderProvider.addPart('backup');
    $translatePartialLoaderProvider.addPart('core');
    $translatePartialLoaderProvider.addPart('core.layout');
    $translatePartialLoaderProvider.addPart('core.loading');
    $translatePartialLoaderProvider.addPart('errors');
    $translatePartialLoaderProvider.addPart('customArchives');
    $translatePartialLoaderProvider.addPart('dashboard');
    $translatePartialLoaderProvider.addPart('datasheet');
    $translatePartialLoaderProvider.addPart('folder');
    $translatePartialLoaderProvider.addPart('groups');
    $translatePartialLoaderProvider.addPart('mailGroups');
    $translatePartialLoaderProvider.addPart('pdf');
    $translatePartialLoaderProvider.addPart('permissions');
    $translatePartialLoaderProvider.addPart('reports');
    $translatePartialLoaderProvider.addPart('search');
    $translatePartialLoaderProvider.addPart('settings');
    $translatePartialLoaderProvider.addPart('subscription');
    $translatePartialLoaderProvider.addPart('template');
    $translatePartialLoaderProvider.addPart('users');

    $translateProvider.useLoader('$translatePartialLoader', {
        urlTemplate: '/app/components/{part}/locales/{lang}.json'
    });
    // $translateProvider.useStaticFilesLoader({
    //     prefix: '/app/components/core.translate/languages/',
    //     suffix: '.json'
    // });

    $translateProvider.preferredLanguage('en');
};


module.exports = function (app) {
    app.config(['$translateProvider', '$translatePartialLoaderProvider', config]);
};
