var yourArchivesCtrl = function ($window, $rootScope, $scope, $state, $stateParams, $filter, $timeout, $translate, $modal, $crypto, SweetAlert, archiveFactory, folderFactory, archiveFileFactory, localStorageService, config, environment) {
    //Fields
    $scope.selectedCustomArchiveId = $stateParams.customArchiveId;

    $scope.translatedArchive = $translate.instant("TEMPLATE.YOURTEMPLATES.LBL_ARCHIVE.TEXT");
    $scope.rootFolder = {};
    $scope.selectedFolder = {};
    $scope.selectedArchiveFile = {};

    $scope.yourArchivesTranslations = {};

    $scope.newFolder = {};
    $scope.folderType = $scope.selectedCustomArchiveId;

    $scope.loaded = {
        archive: {
            folders: false,
            files: false
        }
    };
    $scope.sortModel = {
        field: "productName",
        reverse: false
    }
    //Fields End

    //Events
    $scope.$on('createdNewFolder', function (event, data) {
        getArchiveFolders($scope.selectedCustomArchiveId, function () {
            var newFolder = $filter("filter")($scope.archiveFolders, {
                parent: data.parentFolderId,
                text: data.newFolderName
            }, true)[0];

            openNewFolder(newFolder);
            $scope.folders = $scope.archiveFolders;
        });
    });

    $scope.$on('deletedFolder', function (event, data) {
        getArchiveFolders($scope.selectedCustomArchiveId, function () {
            var parentFolder = $filter("filter")($scope.archiveFolders, {
                id: data.parentFolderId
            }, true)[0];

            openNewFolder(parentFolder);
            $scope.folders = $scope.archiveFolders;
        });
    });

    $scope.$on('renamedFolder', function (event, data) {
        getArchiveFolders($scope.selectedCustomArchiveId, function () {
            $scope.folders = $scope.archiveFolders;
            var selectedFolderNew = $filter('filter')($scope.folders, {
                "_id": $scope.selectedFolder.id
            });
            $scope.selectedFolder = selectedFolderNew;
        });
    });

    $scope.$on('movedFolder', function (event, data) {
        getArchiveFolders($scope.selectedCustomArchiveId, function () {
            $scope.folders = $scope.archiveFolders;
        });
    });
    //Events End

    //Watches
    $scope.$watch('selectedFolder.id', function () {
        if (angular.isDefined($scope.selectedFolder.id) && $scope.selectedFolder.id != "") {
            $scope.selectedFolderPath = folderFactory.getFolderPath($scope.archiveFolders, $scope.selectedFolder.id);
            var selectedFolder = $filter('filter')($scope.archiveFolders, {
                "_id": $scope.selectedFolder.id
            })[0];

            $scope.selectedFolder.text = selectedFolder.text;
            $scope.selectedFolder.Name = selectedFolder.Name;
            $scope.selectedFolder.parent = selectedFolder.parent;
            $scope.selectedFolder.Parent = selectedFolder.Parent;

            // $scope.selectedFolder.folderPermissions = selectedFolder.ItemPermissions;


            getArchiveFiles(function () {
                $scope.selectedArchiveFile = {};
            });
        }
    });
    //Watches End


    //Functions
    $scope.uploadFilesToThisFolder = function () {
        $state.go('app.uploadFiles', {
            customArchiveId: $scope.selectedCustomArchiveId,
            folderId: $scope.selectedFolder.id
        });
    };

    $scope.getFieldValue = function (field, fileFields) {
        for (var i = 0; i < fileFields.length; i++) {
            var fileField = fileFields[i];

            if (fileField.text == field.name) {
                return fileField.value;
            }
        }
    };

    $scope.selectArchiveFile = function (archiveFile) {
        $scope.selectedArchiveFile = archiveFile;
    };

    $scope.openMoveArchiveFileModal = function () {
        var modalInstance = $modal.open({
            templateUrl: '/app/components/customArchives/yourArchives/views/modals/moveArchiveFile.html',
            controller: 'moveArchiveFileCtrl',
            resolve: {
                customArchiveId: function () {
                    return $stateParams.customArchiveId;
                },
                archiveFile: function () {
                    return $scope.selectedArchiveFile;
                }
            }
        });

        modalInstance.result
            .then(function (result) {
                if (result) {
                    refreshArchiveFiles();
                }
            });


    };

    $scope.showArchiveFile = function () {
        var mode = environment.MODE || config.mode;

        var pdfWindow = window.open(config.app[mode].appUrl + '/' + $scope.activeLang + '/pdf/preparing', '_blank');
        var fileUrl = config.app[mode].appUrl + "/" + $rootScope.activeLang + "/pdf/view-custom-file/" + $scope.selectedArchiveFile._id;
        $timeout(function () {
            pdfWindow.location = fileUrl;
        }, 1000);
    };

    $scope.openShareWithEmailModal = function () {
        var modalInstance = $modal.open({
            templateUrl: '/app/components/customArchives/yourArchives/views/modals/shareArchiveFileWithEmail.html',
            controller: 'shareArchiveFileWithEmailCtrl',
            resolve: {
                customArchiveId: function () {
                    return $stateParams.customArchiveId;
                },
                archiveFile: function () {
                    return $scope.selectedArchiveFile;
                }
            }
        });

        modalInstance.result
            .then(function (result) {
                if (result) {
                    refreshArchiveFiles();
                }
            });
    };

    $scope.openShareFolderWithEmailModal = function () {
        if ($scope.archiveFiles.length > 0) {
            var modalInstance = $modal.open({
                animation: true,
                controller: 'shareArchiveFolderWithEmailCtrl',
                templateUrl: '/app/components/customArchives/yourArchives/views/modals/shareArchiveFolderWithEmail.html',
                resolve: {
                    customArchiveId: function () {
                        return $stateParams.customArchiveId;
                    },
                    archiveFiles: function () {
                        return $scope.archiveFiles;
                    }
                }
            });
        } else {
            var title = $translate.instant("CUSTOMARCHIVES.YOURARCHIVES.ERROR_MESSAGES.NOT_ARCHIVE_FILES.LBL_TITLE.TEXT");
            var notArchiveFiles = $translate.instant("CUSTOMARCHIVES.YOURARCHIVES.ERROR_MESSAGES.NOT_ARCHIVE_FILES.LBL_EXPLANATION.TEXT");

            SweetAlert.swal({
                title: title,
                text: notArchiveFiles,
                type: 'error'
            }, function () {
            });
        }
    };

    $scope.openDeleteArchiveFileModal = function () {
        var modalInstance = $modal.open({
            templateUrl: '/app/components/customArchives/yourArchives/views/modals/deleteArchiveFile.html',
            controller: 'deleteArchiveFileCtrl',
            resolve: {
                archiveFile: function () {
                    return $scope.selectedArchiveFile;
                }
            }
        });

        modalInstance.result
            .then(function (result) {
                if (result) {
                    refreshArchiveFiles();
                }
            });
    };

    $scope.editArchiveFile = function () {
        $state.go('app.uploadFiles', {
            customArchiveId: $scope.selectedCustomArchiveId,
            folderId: $scope.selectedFolder.id,
            customArchiveFileId: $scope.selectedArchiveFile._id
        });

        /*         var modalInstance = $modal.open({
                    templateUrl: '/app/components/customArchives/yourArchives/views/modals/manageArchiveFileFields.html',
                    controller: 'manageArchiveFileFieldsCtrl',
                    resolve: {
                        archiveFile: function () {
                            return $scope.selectedArchiveFile;
                        }
                    }
                });
        
                modalInstance.result
                    .then(function (result) {
                        if (result) {
                            refreshArchiveFiles();
        
                        }
                    }); */
    };

    $scope.reOrder = function (field) {
        $scope.sortModel.reverse = ($scope.sortModel.field === field) ? !$scope.sortModel.reverse : false;
        $scope.sortModel.field = field;
    };
    //Functions End

    //Inner Functions
    var setDivHeight = function () {
        var height = $('#divArchiveListContainer').height() - 75;
        $('#divYourFolderListContainer').height($('#divArchiveListContainer').height() - 60);
        $timeout(function () {
            $('#divArchiveList').slimscroll({
                height: height,
                railVisible: true,
                alwaysVisible: false
            });
        }, 250);
        // $('body').css('overflow', 'hidden');
    };

    var getCustomArchiveById = function (customArchiveId) {
        archiveFactory.getCustomArchiveById(customArchiveId)
            .then(function (response) {
                if (response != null) {
                    $scope.customArchive = response;
                    $scope.sortModel.field = clearFieldName($scope.customArchive.fields[0].name);

                    for (var i in $scope.customArchive.fields) {
                        $scope.customArchive.fields[i].uniqueName = clearFieldName($scope.customArchive.fields[i].name);
                    }
                    $scope.yourArchivesTranslations.archiveName = $scope.customArchive.name;
                } else {
                    $state.go('app.dashboard', {
                        lang: $rootScope.activeLang
                    });
                }
            });
    };

    var getArchiveFolders = function (customArchiveId, nextProcess) {
        $scope.loaded.archive.folders = false;
        folderFactory.getArchiveFolders(customArchiveId)
            .then(function (response) {

                $scope.loaded.archive.folders = true;

                $scope.archiveFolders = response;
                for (var i = 0; i < $scope.archiveFolders.length; i++) {
                    if ($scope.archiveFolders[i].Parent == "#") {
                        $scope.archiveFolders[i].Name = $scope.translatedArchive;
                        $scope.rootFolder = $scope.archiveFolders[i];
                        break;
                    }
                }

                for (var i = 0; i < $scope.archiveFolders.length; i++) {
                    var archiveFolder = $scope.archiveFolders[i];
                    archiveFolder.id = archiveFolder._id;
                    archiveFolder.text = archiveFolder.Name;
                    archiveFolder.parent = archiveFolder.Parent;
                    archiveFolder.state = {
                        disabled: false
                    };
                }
                nextProcess();
            });
    };

    var getArchiveFiles = function (nextProcess) {
        $scope.listSearchKey = "";
        $scope.archiveFiles = [];
        $scope.loaded.archive.files = false;
        archiveFileFactory.getArchiveFilesByFolderId($scope.selectedFolder.id)
            .then(function (response) {
                setDivHeight();


                for (var i in response) {
                    var data = response[i];

                    if (data.uploadedBy !== null) {
                        data.uploadedBy = data.uploadedBy.FirstName + ' ' + data.uploadedBy.LastName.substring(0, 1).toUpperCase();
                    } else {
                        data.uploadedBy = "";
                    }

                    for (var f in data.fields) {
                        var field = data.fields[f];
                        data[clearFieldName(field.text)] = field.value;
                    }


                }

                $scope.loaded.archive.files = true;

                $scope.archiveFiles = _.sortBy(response, $scope.sortModel.field);
                nextProcess();
            });

    };

    var openNewFolder = function (folder) {
        $scope.newFolder._id = folder._id;
    };

    var refreshArchiveFiles = function () {
        getArchiveFiles(function () {
            $scope.selectedArchiveFile = {};
        });
    };


    var clearFieldName = function (str) {
        var str = str.replace(/\s+/g, '');

        var output = "";
        for (var i = 0; i < str.length; i++) {
            if (str.charCodeAt(i) <= 127) {
                output += str.charAt(i);
            }
        }
        return output;
    };
    //Inner Functions End

    //Initials
    if ($scope.selectedCustomArchiveId == null || $scope.selectedCustomArchiveId == undefined || $scope.selectedCustomArchiveId == '') {
        $state.go('app.dashboard', {
            lang: $rootScope.activeLang
        });
    }

    getCustomArchiveById($scope.selectedCustomArchiveId);
    getArchiveFolders($scope.selectedCustomArchiveId, function () {
        $scope.folders = $scope.archiveFolders;
    });
    //Initials End

    $(window).resize(function () {
        setDivHeight();
    });
};

module.exports = function (app) {
    app.controller('yourArchivesCtrl', ['$window', '$rootScope', '$scope', '$state', '$stateParams', '$filter', '$timeout', '$translate', '$modal', '$crypto', 'SweetAlert', 'archiveFactory', 'folderFactory', 'archiveFileFactory', 'localStorageService', 'config', 'environment', yourArchivesCtrl]);
};
