var tooltip = function($translate, $rootScope){
    return {
        restrict: 'A', /* optional */
        link: function (scope, el, attrs) {
            var title = $translate.instant(attrs.ttTitle);
            $(el).attr('title', title);

            $(el).tooltip({ position: "bottom left", opacity: 0.7});

            el.bind('mouseenter', function(){
                $(el).tooltip({trigger: 'manual'}).tooltip('show');

                setTimeout(function() {
                   $(el).tooltip({trigger: 'manual'}).tooltip('hide');
                }, 2000);
            });

            el.bind('mouseleave', function(){
                $(el).tooltip({trigger: 'manual'}).tooltip('hide');
            });

            scope.$on('$destroy', function() {
                $(el).tooltip({trigger: 'manual'}).tooltip('hide');
            });

            $rootScope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams){ 
                $('.tooltip').tooltip({trigger: 'manual'}).tooltip('hide');
            })
        }
    }
};

module.exports = function(app){
    app.directive('tooltip', ['$translate', '$rootScope', tooltip]);
};
