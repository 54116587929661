var uiRouter = function($stateProvider, $urlRouterProvider, $locationProvider) {
    $stateProvider
        .state('app.allPermissions', {
            url: '/all-permissions',
            templateUrl: 'app/components/permissions/allPermissions/views/allPermissions.html',
            controller: 'allPermissionsCtrl'
        })
        .state('app.allPermissionLevels', {
            url: '/all-permission-levels',
            templateUrl: 'app/components/permissions/allPermissionLevels/views/allPermissionLevels.html',
            controller: "allPermissionLevelsCtrl",
            permission: {
                requiredPermissions: ['View Permission Levels']
            }
        })
        .state('app.newPermissionLevel', {
            url: '/new-permission-level',
            templateUrl: 'app/components/permissions/newPermissionLevel/views/newPermissionLevel.html',
            controller: 'newPermissionLevelCtrl',
            onEnter: ['menuToggleFactory', function(menuToggleFactory) {
                menuToggleFactory.collapse();
            }],
            onExit: ['menuToggleFactory', function(menuToggleFactory) {
                menuToggleFactory.doPreviousStatus();
            }]
        })
        .state('app.editPermissionLevel', {
            url: '/edit-permission-level/:permissionLevelId',
            templateUrl: 'app/components/permissions/editPermissionLevel/views/editPermissionLevel.html',
            controller: 'editPermissionLevelCtrl',
            onEnter: ['menuToggleFactory', function(menuToggleFactory) {
                menuToggleFactory.collapse();
            }],
            onExit: ['menuToggleFactory', function(menuToggleFactory) {
                menuToggleFactory.doPreviousStatus();
            }]
        });
};

module.exports = function(app) {
    app.config(['$stateProvider', '$urlRouterProvider', '$locationProvider', uiRouter]);
};
