var languagePickerCtrl = function($scope, $modalInstance) {
    //Fields
    //Fields End

    //Functions
    $scope.cancel = function() {
        $modalInstance.dismiss();
    };
    //Functions End

    //Initials
    //Initials End
};

module.exports = function(app) {
    app.controller('languagePickerCtrl', ['$scope', '$modalInstance', languagePickerCtrl]);
};
