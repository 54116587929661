var templateFactory = function ($q, $translate, $modal, httpProvider, $filter, generalsService, publicApiProvider, _) {
    var factoryModel = {};

    //Yeni Api
    factoryModel.getAll = function(query){
        return publicApiProvider.get('/templates', query);
    };

    factoryModel.get = function(id, query){
         return publicApiProvider.get('/templates/' + id, query);
    };

    factoryModel.getElements = function(id, query){
        return publicApiProvider.get('/templates/' + id + '/elements', query);
    };

    //Eski Api


    factoryModel.createEmptyTemplate = function () {
        var model = {
            folderId: "",
            name: "",
            languages: ["en"],
            primaryLanguage: "en",
            description: "",
            hasProductNumber: true,
            productNumberRequired: true,
            uniqueProductNumber: false,
            hasStartupVersionNo: false,
            majorVersion: 0,
            minorVersion: 0,
            isDeleted: false
        };

        return model;
    };
    factoryModel.saveTemplateToLibrary = function (model) {
        return httpProvider.post('template', 'saveTemplateToLibrary', model);
    };
    factoryModel.getAllTemplatesFromLibrary = function (bool) {
        return httpProvider.get('template', 'getAllTemplatesFromLibrary', "isLayout=" + bool);
    };

    factoryModel.getTemplatesFromLibraryByIndustryId = function (industryId) {
        return httpProvider.get('template', 'getTemplatesFromLibraryByIndustryId', "industryId=" + industryId);
    };
    factoryModel.getLibraryTemplate = function (id) {
        return httpProvider.get('template', 'getLibraryTemplate', "id=" + id);
    };
    factoryModel.getLibraryTemplateByTemplateId = function (id) {
        return httpProvider.get('template', 'getLibraryTemplateByTemplateId', "id=" + id);
    };
    factoryModel.deleteLibraryTemplate = function (id) {
        return httpProvider.post('template', 'deleteLibraryTemplate', {id: id});
    };
    factoryModel.updateLibraryTemplate = function (template) {
        return httpProvider.put('template', 'updateLibraryTemplate', {template: template});
    };
    factoryModel.createLibraryTemplate = function (template) {
        return httpProvider.post('template', 'createLibraryTemplate', template);
    };

    factoryModel.makeLibraryTemplateRequestForIndustry = function (industryId) {
        return httpProvider.post('template', 'makeLibraryTemplateRequestForIndustry', {industryId: industryId});
    };

    factoryModel.getUserLibraryTemplateRequestsForIndustries = function () {
        return httpProvider.post('template', 'getUserLibraryTemplateRequestsForIndustries');
    };

    factoryModel.getTemplatesGroupedByFolderByUserId = function (userId) {
        var params = "userId=" + userId;
        return httpProvider.get('template', 'getTemplatesGroupedByFolderByUserId', params)
    };

    factoryModel.getIndustries = function () {
        return httpProvider.get('template', 'getIndustries');
    };

    factoryModel.createNew = function (model) {
        return httpProvider.put('template', 'createTemplate', model);
    };

    factoryModel.copyAndTransferTemplate = function (model) {
        return httpProvider.put('admin', 'copyAndTransferTemplate', model);
    };

    factoryModel.getTemplatesByFolderId = function (folderId) {
        var params = "folderId=" + folderId;
        return httpProvider.get('template', 'getTemplatesByFolderId', params);
    };

    factoryModel.moveTemplate = function (templateId, folderId) {
        return httpProvider.put('template', 'moveTemplate', {
            templateId: templateId,
            folderId: folderId
        });
    };

    factoryModel.renameTemplate = function (templateId, templateName) {
        return httpProvider.put('template', 'renameTemplate', {
            templateId: templateId,
            templateName: templateName
        });
    };

    factoryModel.removeTemplate = function (id) {
        return httpProvider.post('template', 'removeTemplate', {
            id: id
        });
    };

    factoryModel.preview = function (id, language) {
        return httpProvider.getNonQuery('pdf', 'previewTemplate', id + "/" + language);
    };

    factoryModel.doesTemplateBelongToThisAccount = function (templateId) {
        return httpProvider.get('template', 'doesTemplateBelongToThisAccount', 'id=' + templateId);
    };

    factoryModel.getTemplate = function (id) {
        return httpProvider.get('template', 'getTemplate', "id=" + id);
    };

    factoryModel.getTemplateWithoutElements = function (id) {
        return httpProvider.get('template', 'getTemplateWithoutElements', "id=" + id);
    };

    factoryModel.save = function (model) {
        return httpProvider.post('template', 'saveTemplate', model);
    };

    factoryModel.publish = function (model) {
        var deferred = $q.defer();

        factoryModel.getTemplateUsage(model.template._id)
            .then(function (numberOfDatasheet) {
                console.log("numberOfDatasheet", numberOfDatasheet);
                if (numberOfDatasheet > 0) {
                    var modalInstance = $modal.open({
                        animation: false,
                        controller: 'confirmFormPublishCtrl',
                        templateUrl: '/app/components/template/editTemplate/views/modals/confirmFormPublish.html',
                        backdropClass: "defaultModal",
                        resolve: {
                            numberOfDatasheet: function () {
                                return numberOfDatasheet;
                            }
                        }
                    });

                    modalInstance.result.then(function (modalResponse) {
                        model.publishOptions = modalResponse;
                        httpProvider.post('template', 'publishTemplate', model)
                            .then(function (publishResponse) {
                                deferred.resolve(publishResponse);
                            }, function () {
                                deferred.reject();
                            });

                    }, function () {
                        deferred.reject();
                    });
                }
                else {

                    httpProvider.post('template', 'publishTemplate', model)
                        .then(function (publishResponse) {
                            deferred.resolve(publishResponse);
                        }, function () {
                            deferred.reject();
                        });
                }
            });

        return deferred.promise;
    };

    factoryModel.getTemplateUsage = function (templateId) {
        return httpProvider.get('template', 'getTemplateUsage', "templateId=" + templateId);
    };

    factoryModel.getPublishedTemplatesByFolderId = function (folderId) {
        return httpProvider.get('template', 'getPublishedTemplatesByFolderId', "folderId=" + folderId);
    };

    factoryModel.getPublishedTemplateById = function (id) {
        return httpProvider.get('template', 'getPublishedTemplateById', "id=" + id);
    };

    factoryModel.addSelectListItemFromDatasheet = function (model) {
        return httpProvider.post('template', 'addSelectListItemFromDatasheet', model);
    };

    factoryModel.getTemplateHistories = function (id) {
        return httpProvider.get('template', 'getTemplateHistories', "id=" + id);
    };

    factoryModel.getTemplateHistory = function (id) {
        return httpProvider.get('template', 'getTemplateHistory', "id=" + id);
    };

    factoryModel.changeTemplateElementIds = function(elements, templateId){
        console.log("templateId", templateId);
        for(var i in elements){
            var element = elements[i];
            element.templateId = templateId;
            
            var oldId = element._id;

            element._id = generalsService.createObjectId();

            if(element.type === "header"){
                var pages = _.filter(elements, { headerId : oldId});
                for(var p in pages){
                    pages[p].headerId = element._id;
                }

            }else if(element.type === "footer"){
                var pages = _.filter(elements, { footerId : oldId});
                for(var p in pages){
                    pages[p].footerId = element._id;
                }
            }else if(element.type === "page"){
                var freeElements = _.filter(elements, { pageId : oldId});
                for(var f in freeElements){
                    freeElements[f].pageId = element._id;
                }
            }

            var childElements = _.filter(elements, { parentId : oldId});

            for(var c in childElements){
                childElements[c].parentId = element._id;
            }
        }

        return elements;

    };
    return factoryModel;
};

module.exports = function (app) {
    app.factory('templateFactory', ['$q', '$translate', '$modal', 'httpProvider', '$filter', 'generalsService', 'publicApiProvider', '_', templateFactory]);
};
