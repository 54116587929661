require("./../../../../bower_components/angular/angular.js");
require('angular-ui-router');
require('../core/');
require("./../../../../plugins/angular-complexify/build/angular-complexify.js");

//Module
var usersModule = angular.module('tdsApp.users', [
    'ui.router',
    'tdsApp.core',
    'angular-complexify'
]);

//Config

//Run
require('./run.js')(usersModule);

//Constants
require("./shared/constants/jobTitleGroups.js")(usersModule);
require("./shared/constants/enums/userStatusType.js")(usersModule);

//Router
require('./routing.js')(usersModule);

//Factories
require('./shared/factories/userFactory.js')(usersModule);

//Controllers
require('./allUsers/controllers/allUsersController.js')(usersModule);
require('./userProfile/controllers/userProfileController.js')(usersModule);
require('./editUser/controllers/editUserController.js')(usersModule);

//Modal Controllers
require('./shared/controllers/modals/changePasswordController.js')(usersModule);
require('./allUsers/controllers/modals/inviteUserController.js')(usersModule);
require('./allUsers/controllers/modals/userGroupsController.js')(usersModule);
require('./allUsers/controllers/modals/makeDisableController.js')(usersModule);
require('./allUsers/controllers/modals/makeEnableController.js')(usersModule);
require('./allUsers/controllers/modals/deleteUserController.js')(usersModule);
require('./allUsers/controllers/modals/remindUserController.js')(usersModule);


//Partials Controllers
require('./shared/controllers/_partials/userOptionsInHeaderController.js')(usersModule);
require('./userProfile/controllers/_partials/userSummaryInformationsController.js')(usersModule);
require('./userProfile/controllers/_partials/userActivitiesController.js')(usersModule);
require('./userProfile/controllers/_partials/userPreferencesController.js')(usersModule);
require('./userProfile/controllers/_partials/userInformationsController.js')(usersModule);

//Export
module.exports = usersModule;
