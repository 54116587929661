var includeReplace = function(){
  return {
      require: 'ngInclude',
      restrict: 'A', /* optional */
      link: function (scope, el, attrs) {
          el.replaceWith(el.children());
      }
  };
};

module.exports = function(app){
  app.directive('includeReplace', includeReplace);
};
