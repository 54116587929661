var validateChangedEmailCtrl = function ($rootScope, $scope, $state, $stateParams, $timeout, $translate, authFactory) {
    //Fields
    $scope.validateButton = "AUTHENTICATION.VALIDATECHANGEDEMAIL.BTN_VALIDATE.TEXT";
    $scope.disabled = false;
    $scope.status = true;
    $scope.codeInputDisabled = false;
    //Fields - End

    //Inner Functions
    var initialize = function () {
        if (angular.isDefined($stateParams.code) && $stateParams.code != "") {
            $scope.codeInputDisabled = true;
            $scope.validationCode = $stateParams.code;
        }
    };
    //Inner Functions End

    //Functions
    $scope.validate = function () {
        if ($scope.validationForm.$valid) {
            $scope.validateButton = "AUTHENTICATION.VALIDATECHANGEDEMAIL.BTN_VALIDATE.ALTERNATE_TEXT";
            $scope.disabled = true;
            authFactory.validateChangedEmail($scope.validationCode)
                .then(function (response) {
                    if (response.status) {
                        var message = $translate.instant('AUTHENTICATION.VALIDATECHANGEDEMAIL.INFO_MESSAGES.CHANGED_SUCCESSFULLY');;
                        toastr.success(message, '', { positionClass: 'toast-bottom-full-width', timeOut: 3000 });

                        $timeout(function () {
                            $state.go('auth.signIn', {
                                lang: $rootScope.activeLang
                            });
                        }, 3000);

                    } else {
                        $scope.status = response.status;
                        $scope.customErrorMessage = response.message;
                    }

                    $scope.validateButton = "AUTHENTICATION.VALIDATECHANGEDEMAIL.BTN_VALIDATE.TEXT";
                    $scope.disabled = false;
                });
        }
    };
    //Functions End

    //Initials
    initialize();
    //Initials End
};

module.exports = function (app) {
    app.controller('validateChangedEmailCtrl', ['$rootScope', '$scope', '$state', '$stateParams', '$timeout', '$translate', 'authFactory', validateChangedEmailCtrl]);
};
