var editDatasheetCtrl = function ($window, $rootScope, $scope, $state, $stateParams, $translate, $timeout, $modal, datasheetFactory, templateFactory, uploadFactory, datasheetDataEditingService, googleTranslate, menuToggleFactory, localStorageService, folderFactory, htmlTagCleaner, firebaseFactory, userFactory, intercomFactory, config, _) {
    //Fields 
    var datasheetId = $stateParams.id;
    var firstVersionElements = [];

    $scope.datasheetModel = {};
    $scope.templateModel = {};

    $scope.selectionModel = {
        applicationLanguage: $rootScope.activeLang,
        showHiddenElements: true,
        selectedLanguage: "en"
    };

    var datasheetTranslationModel = {
        datasheetPage: {
            contextMenu: {
                lblShow: {
                    text: $translate.instant("DATASHEET.DIRECTIVES.DATASHEETPAGES.DATASHEETPAGE.CONTEXTMENU.LBL_SHOW.TEXT")
                },
                lblHide: {
                    text: $translate.instant("DATASHEET.DIRECTIVES.DATASHEETPAGES.DATASHEETPAGE.CONTEXTMENU.LBL_HIDE.TEXT")
                }
            }
        },
        elements: {
            datasheetText: {
                contextMenu: {
                    lblShow: {
                        text: $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETTEXT.CONTEXTMENU.LBL_SHOW.TEXT")
                    },
                    lblHide: {
                        text: $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETTEXT.CONTEXTMENU.LBL_HIDE.TEXT")
                    }
                }
            },
            datasheetInputText: {
                modals: {
                    copyWarning: {
                        lblTitle: {
                            text: $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETINPUTTEXT.MODALS.COPYWARNING.LBL_TITLE.TEXT")
                        },
                        lblMessage: {
                            text: $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETINPUTTEXT.MODALS.COPYWARNING.LBL_MESSAGE.TEXT")
                        },
                        btnCancel: {
                            text: $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETINPUTTEXT.MODALS.COPYWARNING.BTN_CANCEL.TEXT")
                        },
                        btnConfirm: {
                            text: $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETINPUTTEXT.MODALS.COPYWARNING.BTN_CONFIRM.TEXT")
                        }
                    },
                    translateWarning: {
                        lblTitle: {
                            text: $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETINPUTTEXT.MODALS.TRANSLATEWARNING.LBL_TITLE.TEXT")
                        },
                        lblMessage: {
                            text: $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETINPUTTEXT.MODALS.TRANSLATEWARNING.LBL_MESSAGE.TEXT")
                        },
                        btnCancel: {
                            text: $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETINPUTTEXT.MODALS.TRANSLATEWARNING.BTN_CANCEL.TEXT")
                        },
                        btnConfirm: {
                            text: $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETINPUTTEXT.MODALS.TRANSLATEWARNING.BTN_CONFIRM.TEXT")
                        }
                    }
                },
                contextMenu: {
                    lblTranslate: {
                        text: $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETINPUTTEXT.CONTEXTMENU.LBL_TRANSLATE.TEXT")
                    },
                    lblTranslateAll: {
                        text: $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETINPUTTEXT.CONTEXTMENU.LBL_TRANSLATE_TO_ALL.TEXT")
                    },
                    lblCopy: {
                        text: $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETINPUTTEXT.CONTEXTMENU.LBL_COPY.TEXT")
                    },
                    lblCopyAll: {
                        text: $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETINPUTTEXT.CONTEXTMENU.LBL_COPY_TO_ALL.TEXT")
                    },
                    lblShow: {
                        text: $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETINPUTTEXT.CONTEXTMENU.LBL_SHOW.TEXT")
                    },
                    lblHide: {
                        text: $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETINPUTTEXT.CONTEXTMENU.LBL_HIDE.TEXT")
                    }
                },
                lblTranslate: {
                    text: $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETINPUTTEXT.LBL_TRANSLATE.TEXT")
                },
                lblCopy: {
                    text: $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETINPUTTEXT.LBL_COPY.TEXT")
                },
                lblAddImage: {
                    text: $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETINPUTTEXT.LBL_ADD_IMAGE.TEXT")
                },
                lblAddMathSymbol: {
                    text: $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETINPUTTEXT.LBL_ADD_MATH_SYMBOL.TEXT")
                },
                lblPlusMinusSign: {
                    text: $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETINPUTTEXT.LBL_PLUS_MINUS_SIGN.TEXT")
                },
                successMessages: {
                    translatedSuccessfully: $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETINPUTTEXT.SUCCESS_MESSAGES.TRANSLATED_SUCCESSFULLY"),
                    copiedSuccessfully: $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETINPUTTEXT.SUCCESS_MESSAGES.COPIED_SUCCESSFULLY")
                }
            },
            datasheetSelectList: {
                contextMenu: {
                    lblAddItem: {
                        text: $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETSELECTLIST.CONTEXTMENU.LBL_ADD_ITEM.TEXT")
                    },
                    lblShow: {
                        text: $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETSELECTLIST.CONTEXTMENU.LBL_SHOW.TEXT")
                    },
                    lblHide: {
                        text: $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETSELECTLIST.CONTEXTMENU.LBL_HIDE.TEXT")
                    }
                }
            },
            datasheetImage: {
                contextMenu: {
                    lblShow: {
                        text: $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETIMAGE.CONTEXTMENU.LBL_SHOW.TEXT")
                    },
                    lblHide: {
                        text: $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETIMAGE.CONTEXTMENU.LBL_HIDE.TEXT")
                    }
                }
            },
            datasheetIcon: {
                contextMenu: {
                    lblShow: {
                        text: $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETICON.CONTEXTMENU.LBL_SHOW.TEXT")
                    },
                    lblHide: {
                        text: $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETICON.CONTEXTMENU.LBL_HIDE.TEXT")
                    }
                }
            },
            datasheetInputIcon: {
                contextMenu: {
                    lblShow: {
                        text: $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETINPUTICON.CONTEXTMENU.LBL_SHOW.TEXT")
                    },
                    lblHide: {
                        text: $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETINPUTICON.CONTEXTMENU.LBL_HIDE.TEXT")
                    }
                }
            },
            datasheetInputImage: {
                contextMenu: {
                    lblShow: {
                        text: $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETINPUTIMAGE.CONTEXTMENU.LBL_SHOW.TEXT")
                    },
                    lblHide: {
                        text: $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETINPUTIMAGE.CONTEXTMENU.LBL_HIDE.TEXT")
                    }
                }
            },
            datasheetBox: {
                contextMenu: {
                    lblShow: {
                        text: $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETBOX.CONTEXTMENU.LBL_SHOW.TEXT")
                    },
                    lblHide: {
                        text: $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETBOX.CONTEXTMENU.LBL_HIDE.TEXT")
                    }
                }
            },
            datasheetTable: {
                datasheetTable: {
                    contextMenu: {
                        lblShow: {
                            text: $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETTABLE.DATASHEETTABLE.CONTEXTMENU.LBL_SHOW.TEXT")
                        },
                        lblHide: {
                            text: $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETTABLE.DATASHEETTABLE.CONTEXTMENU.LBL_HIDE.TEXT")
                        },
                        lblCopyData: {
                            text: $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETTABLE.DATASHEETTABLE.CONTEXTMENU.LBL_COPY_DATA.TEXT"),
                            success: $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETTABLE.DATASHEETTABLE.CONTEXTMENU.LBL_COPY_DATA.SUCCESS")
                        }
                    }
                },
                datasheetTableRow: {
                    contextMenu: {
                        lblRemoveDynamicRow: {
                            text: $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETTABLE.DATASHEETTABLEROW.CONTEXTMENU.LBL_REMOVE_DYNAMIC_ROW.TEXT")
                        },
                        lblDynamicRows: {
                            text: $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETTABLE.DATASHEETTABLEROW.CONTEXTMENU.LBL_DYNAMIC_ROWS.TEXT")
                        },
                        lblAddDynamicRowAbove: {
                            text: $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETTABLE.DATASHEETTABLEROW.CONTEXTMENU.LBL_ADD_DYNAMIC_ROWS_ABOVE.TEXT")
                        },
                        lblAddDynamicRowBelow: {
                            text: $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETTABLE.DATASHEETTABLEROW.CONTEXTMENU.LBL_ADD_DYNAMIC_ROWS_BELOW.TEXT")
                        },
                        lblCopyOptions: {
                            text: $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETTABLE.DATASHEETTABLEROW.CONTEXTMENU.LBL_COPY_OPTIONS.TEXT")
                        },
                        lblCopyRowAbove: {
                            text: $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETTABLE.DATASHEETTABLEROW.CONTEXTMENU.LBL_COPY_ROW_ABOVE.TEXT")
                        },
                        lblCopyRowBelow: {
                            text: $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETTABLE.DATASHEETTABLEROW.CONTEXTMENU.LBL_COPY_ROW_BELOW.TEXT")
                        },
                        lblShow: {
                            text: $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETTABLE.DATASHEETTABLEROW.CONTEXTMENU.LBL_SHOW.TEXT")
                        },
                        lblHide: {
                            text: $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETTABLE.DATASHEETTABLEROW.CONTEXTMENU.LBL_HIDE.TEXT")
                        }

                    },
                    errorMessages: {
                        mergeRowError: $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETTABLE.DATASHEETTABLEROW.ERROR_MESSAGES.MERGE_ROW_ERROR"),
                        hasTableInRowError: $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETTABLE.DATASHEETTABLEROW.ERROR_MESSAGES.HAS_TABLE_ROW_ERROR"),
                    }
                }
            }
        }
    };

    //Loading
    $scope.loading = {
        datasheet: false,
        editDatasheet: false,
    };
    $scope.isLocked = false;
    //Fields End

    //Events
    $(document).off('datasheetGenerated').on('datasheetGenerated', function (e, data) {
        console.timeEnd("render");
        $("#divPreparingDatasheet").fadeOut(1000, function () {
            $("#datasheetRightMenu").fadeIn(1000);
            $("#contentPage").fadeIn(1000);
        });

    });

    $(document).off('addImageToTextBox').on('addImageToTextBox', function (e, data) {
        var elementId = data.elementId;

        uploadFactory.uploadImage(function (imageSrc) {
            var jsImageOnLoad = function () {
                var width = this.width;
                var height = this.height;

                var newImageHtml = '<img src="' + imageSrc + '" class="fr-fil fr-dib fr-draggable" >';
                $('.inputtext-' + elementId).find('.inner-element').find(".fr-element").append(newImageHtml);
            };

            var img = new Image();
            img.onload = jsImageOnLoad;
            img.src = imageSrc;
        });
    });

    $(document).off('translateToOtherLanguages').on('translateToOtherLanguages', function (e, data) {
        var element = data.element;
        var text = data.value;
        var selectedLanguage = $scope.selectionModel.selectedLanguage;
        var targetLanguages = data.language ? [ data.language] : $scope.templateModel.template.languages;

        var isOtherLanguageElementFill = false;
        for (var l = 0; l < targetLanguages.length; l++) {
            var targetLanguage = targetLanguages[l];
            if (targetLanguage != selectedLanguage) {
                var otherLanguageValue = element.values[targetLanguage];
                var cleanedOtherLanguageValue = htmlTagCleaner.clear(otherLanguageValue);
                if (cleanedOtherLanguageValue != "") {
                    isOtherLanguageElementFill = true;
                    break;
                }
            }
        }

        if (isOtherLanguageElementFill) {
            swal({
                title: datasheetTranslationModel.elements.datasheetInputText.modals.translateWarning.lblTitle.text,
                text: datasheetTranslationModel.elements.datasheetInputText.modals.translateWarning.lblMessage.text,
                type: "warning",
                showCancelButton: true,
                cancelButtonText: datasheetTranslationModel.elements.datasheetInputText.modals.translateWarning.btnCancel.text,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: datasheetTranslationModel.elements.datasheetInputText.modals.translateWarning.btnConfirm.text,
                closeOnConfirm: true
            }, function () {
                translateToOtherLangauges(text, selectedLanguage, targetLanguages, function (response) {
                    for (var tl = 0, len = response.TranslateList.length; tl < len; tl++) {
                        var translateItem = response.TranslateList[tl];
                        if ($scope.selectionModel.selectedLanguage != translateItem.Language) {
                            element.values[translateItem.Language] = translateItem.Text;
                        }
                    }

                    var message = datasheetTranslationModel.elements.datasheetInputText.successMessages.translatedSuccessfully;
                    toastr.success(message, '', { positionClass: 'toast-bottom-full-width', timeOut: 3000 });
                });
            });
        }
        else {
            translateToOtherLangauges(text, selectedLanguage, targetLanguages, function (response) {
                for (var tl = 0, len = response.TranslateList.length; tl < len; tl++) {
                    var translateItem = response.TranslateList[tl];
                    if ($scope.selectionModel.selectedLanguage != translateItem.Language) {
                        element.values[translateItem.Language] = translateItem.Text;
                    }
                }

                var message = datasheetTranslationModel.elements.datasheetInputText.successMessages.translatedSuccessfully;
                toastr.success(message, '', { positionClass: 'toast-bottom-full-width', timeOut: 3000 });
            });
        }
    });

    $(document).off('selectInputIcon').on('selectInputIcon', function (e, data) {
        var element = data.element;

        var modalInstance = $modal.open({
            animation: false,
            controller: 'datasheetInputIconPickerCtrl',
            templateUrl: '/app/components/datasheet/shared/directives/elements/datasheetInputIcon/views/modals/datasheetInputIconPicker.html',
            backdropClass: "defaultModal",
            size: "lg",
            resolve: {
                element: function () {
                    return element;
                }
            }
        });

        modalInstance.result.then(function (response) {
            if (response.status) {
                element.value = response.selectedIcon;

                //SET EDELİM.
                $(".inputicon-" + element._id).find(".inner-element").find("i").removeClass().addClass(element.value);
            }
        }).finally(function () {
            modalInstance.$destroy();
        });
    });

    $(document).off('selectInputImage').on('selectInputImage', function (e, data) {
        var element = data.element;

        uploadFactory.uploadImage(function (imageSrc) {
            var jsImageOnLoad = function () {
                var width = this.width;
                var height = this.height;

                //Header veya Footer daki input image ler farklı davranıyor. Eğer Header veya footer da ise size özellikleri containerStyle parametresinden geliyor. Değilse style parametresinden geliyor.
                var holderWidth = 0;
                var holderHeight = 0;
                if (!element.containerStyle.height || element.containerStyle.height == "auto") {
                    holderWidth = parseFloat(element.style.width.replace("px", ""));
                    holderHeight = parseFloat(element.style.height.replace("px", ""));
                }
                else {
                    holderWidth = parseFloat(element.containerStyle.width.replace("px", ""));
                    holderHeight = parseFloat(element.containerStyle.height.replace("px", ""));
                }

                var whRatio = width / height;

                //Width Holder Width den küçükse,
                if (width < holderWidth) {
                    height = (holderWidth / width) * height;
                    width = holderWidth;
                }

                //Height HolderHeight den küçükse,
                if (height < holderHeight) {
                    width = (holderHeight / height) * width;
                    height = holderHeight;
                }

                //Width holder Width den büyükse,
                if (width > holderWidth) {
                    height = (holderWidth / width) * height;
                    width = holderWidth;
                }

                //Height HolderHeight den büyükse,
                if (height > holderHeight) {
                    width = (holderHeight / height) * width;
                    height = holderHeight;
                }

                element.size = {
                    width: width + "px",
                    height: height + "px"
                };
                element.value = imageSrc;

                var $image = $('<img class="inline-element" >');
                $image.css("width", element.size.width);
                $image.css("height", element.size.height);
                $image.attr("src", element.value);

                $(".inputimage-" + element._id).find(".inner-element").empty();
                $(".inputimage-" + element._id).find(".inner-element").append($image);
            };

            var img = new Image();
            img.onload = jsImageOnLoad;
            img.src = imageSrc;
        });
    });

    $(document).off('addItemToSelectList').on('addItemToSelectList', function (e, data) {
        var selectList = data.element;

        var modalInstance = $modal.open({
            animation: false,
            controller: 'datasheetSelectListAddItemCtrl',
            templateUrl: '/app/components/datasheet/shared/directives/elements/datasheetSelectList/views/modals/datasheetSelectListAddItem.html',
            backdropClass: "defaultModal",
            size: "lg",
            resolve: {
                element: function () {
                    return selectList;
                }
            }
        });

        modalInstance.result.then(function (response) {
            if (response.status) {
                var newItems = response.newItems;

                var templateLanguages = $scope.templateModel.template.languages;
                for (var i = 0, len = templateLanguages.length; i < len; i++) {
                    var templateLanguage = templateLanguages[i];

                    var newItem = _.find(newItems, {
                        language: templateLanguage
                    });

                    selectList.source[templateLanguage].push({
                        id: newItem.id,
                        value: newItem.value
                    });
                    selectList.values[templateLanguage] = newItem;
                }

                $('.' + selectList.type + '-' + selectList._id).trigger('updateYourSelf');
            }
        });
    });

    $scope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {

        if (fromState.name == 'editor.editDatasheet') {
            $(document).find('.fr-basic').each(function () {
                var editor = $(this).data('froala.editor');
                editor.destroy();
            });

            if (!angular.equals(firstVersionElements, $scope.datasheetModel.elements)) {
                event.preventDefault();
                bootbox.dialog({
                    message: $translate.instant("DATASHEET.EDIT_DATASHEET.LBL_WITHOUT_SAVING_MESSAGE.TEXT"),
                    buttons: {
                        stayInPage: {
                            label: $translate.instant("DATASHEET.EDIT_DATASHEET.BTN_STAY_IN_PAGE.TEXT"),
                            className: 'btn-default',
                            callback: function () { }
                        },
                        justContinue: {
                            label: $translate.instant("DATASHEET.EDIT_DATASHEET.BTN_JUST_CONTINUE.TEXT"),
                            className: 'btn-danger',
                            callback: function () {
                                angular.copy($scope.datasheetModel.elements, firstVersionElements);
                                $timeout(function () {
                                    $state.go(toState, toParams);
                                }, 500);
                            }
                        },
                        saveAndContinue: {
                            label: $translate.instant("DATASHEET.EDIT_DATASHEET.BTN_SAVE_AND_CONTINUE.TEXT"),
                            className: 'btn-success',
                            callback: function () {
                                $scope.save()
                                    .then(function (response) {
                                        angular.copy($scope.datasheetModel.elements, firstVersionElements);
                                        var message = $translate.instant("DATASHEET.EDIT_DATASHEET.MESSAGES.SUCCESS.SAVED_WITHOUT_SAVING");
                                        toastr.success(message, '', { positionClass: 'toast-bottom-full-width', timeOut: 3000 });
                                        $timeout(function () {
                                            $state.go(toState, toParams);
                                        }, 500);

                                    });
                            }
                        }
                    }
                });
            }
            else {
                unLockDatasheet();
                notifyBar.clear();
            }
        }
    });

    $scope.$on('$destroy', function () {
        $window.onbeforeunload = undefined;
        $(window).off('unload');
        $(document).off('datasheetGenerated');
        $(document).off('addImageToTextBox');
        $(document).off('translateToOtherLanguages');
        $(document).off('selectInputIcon');
        $(document).off('selectInputImage');
        $(document).off('addItemToSelectList');
        $.contextMenu('destroy');
        var accountId = localStorageService.get('userInformationData').AccountId._id;
        firebaseFactory.clearRefEvents(accountId + '/locked/datasheet/' + datasheetId);
    });

    $window.onbeforeunload = function (e) {
        if (!angular.equals(firstVersionElements, $scope.datasheetModel.elements)) {
            return false;
        }
    };

    $(window).unload(function () {
        unLockDatasheet();
    });
    //Events End

    //Events Handler
    var datasheetUnlockHandler = function (value) {
        if (value == null || value == undefined) {
            notifyBar.clear();

            var workOnDatasheetMessage = $translate.instant("DATASHEET.EDIT_DATASHEET.LBL_UNLOCK.TEXT");
            notifyBar.info(workOnDatasheetMessage);

            $timeout(function () {
                location.reload();
            }, 3000);
        }
    }
    //Events Handler End

    //Inner Functions
    var editDatasheetInitialize = function () {
        //Datasheet ilk yüklendiğinde başka bir kullanıcı tarafından kilitlenip kilitlenmediğini öğrenelim.  
        var userId = localStorageService.get('userInformationData')._id;
        var accountId = localStorageService.get('userInformationData').AccountId._id;
        firebaseFactory.checkOnce(accountId + '/locked/datasheet/' + datasheetId, function (value) {
            if (value && value.lockedBy != userId) {
                $scope.isLocked = true;

                userFactory.getUserByUserId(value.lockedBy)
                    .then(function (user) {
                        var fullName = user.FirstName + ' ' + user.LastName;

                        console.log(fullName);

                        var workingUserMessage = $translate.instant("DATASHEET.EDIT_DATASHEET.LBL_WORKING_USER.TEXT");
                        workingUserMessage = workingUserMessage.replace("$fullName$", fullName);
                        notifyBar.warning(workingUserMessage);
                    });


                firebaseFactory.checkAlways(accountId + '/locked/datasheet/' + datasheetId, datasheetUnlockHandler);
            }
            else {
                lockDatasheet();
            }
        });

        console.time("beforeRender");
        getDatasheetById(datasheetId, function (folders) {
            //EditDatasheet sayfasına özel işlemler,
            $scope.loading.editDatasheet = true;

            var rootFolder = _.find(folders, {
                "Parent": "#"
            });

            if (rootFolder != null) {
                rootFolder.Name = $translate.instant("DATASHEET.EDIT_DATASHEET.LBL_ARCHIVE.TEXT")
            }

            $scope.folderPath = folderFactory.getFolderPath(folders, $scope.datasheetModel.datasheet.folderId);

            for (var i = 0; i < $scope.templateModel.template.languages.length; i++) {
                var languageKey = $scope.templateModel.template.languages[i];
                var language = $translate.instant('TEMPLATE.SHARED.LANGUAGES.' + languageKey.toUpperCase());
                if (i == 0) {
                    $('#datasheetLanguages').append(language);
                } else {
                    $('#datasheetLanguages').append(', ' + language);
                }

            };

            var selectedLanguage = $scope.templateModel.template.primaryLanguage;
            var datasheetSelectedLanguages = localStorageService.get('datasheetSelectedLanguages');
            if (datasheetSelectedLanguages != null && datasheetSelectedLanguages != undefined) {
                var datasheetSelectedLanguage = _.find(datasheetSelectedLanguages, {
                    datasheetId: $scope.datasheetModel.datasheet._id
                });
                if (datasheetSelectedLanguage != null && datasheetSelectedLanguage != undefined) {
                    selectedLanguage = datasheetSelectedLanguage.selectedLanguage;
                }
            }

            
            
            datasheetTranslationModel.datasheetPage.contextMenu.lblTranslateLanguages = $scope.templateModel.template.languagesWithName.reduce(function(prev, language){
                prev[language.isoCode] = $translate.instant("DATASHEET.DIRECTIVES.DATASHEETAUTOMATICTRANSLATER.LBL_TRANSLATE_TO.TEXT", { language : language.text});
                return prev;
            }, {}); 

            datasheetTranslationModel.datasheetPage.contextMenu.lblCopyLanguages = $scope.templateModel.template.languagesWithName.reduce(function(prev, language){
                prev[language.isoCode] = $translate.instant("DATASHEET.DIRECTIVES.DATASHEETAUTOMATICTRANSLATER.LBL_COPY_TO.TEXT", { language : language.text});
                return prev;
            }, {}); 

            //Daha önce seçilen dil publishedTemplate dilleri arasında yoksa,
            if ($scope.templateModel.template.languages.indexOf(selectedLanguage) == -1) {
                selectedLanguage = $scope.templateModel.template.languages[0];
            }

            $scope.selectionModel.selectedLanguage = selectedLanguage;

            $scope.loading.editDatasheet = true;

            console.timeEnd("beforeRender");
            console.time("render");
            $(document).trigger('datasheetModelLoaded', {
                datasheetModel: $scope.datasheetModel,
                templateModel: $scope.templateModel,
                selectionModel: $scope.selectionModel,
                translationModel: datasheetTranslationModel
            });
        });
    };

    var getDatasheetById = function (datasheetId, nextProcess) {
        $scope.loading.datasheet = false;
        datasheetFactory.getDatasheetById(datasheetId)
            .then(function (response) {
                $scope.templateModel = response.templateModel;
                $scope.templateModel.template.languagesWithName = $scope.templateModel.template.languages.map(function(key) {
                    return {
                        isoCode : key,
                        text : $translate.instant('TEMPLATE.SHARED.LANGUAGES.' + key.toUpperCase())
                    };
                })
                $scope.datasheetModel.datasheet = response.datasheetModel.datasheet;
                $scope.datasheetModel.comments = response.datasheetModel.comments;

                $scope.datasheetModel.elements = datasheetDataEditingService.mergeDatasheetAndTemplateElements(response.datasheetModel.elements, response.templateModel.elements);
                $scope.loading.datasheet = true;

                angular.copy($scope.datasheetModel.elements, firstVersionElements);
                nextProcess(response.datasheetModel.folders);
            });
    };

    var translateToOtherLangauges = function (text, language, targetLanguages, callback) {
        googleTranslate.translate(text, language, targetLanguages)
            .then(function (response) {
                callback(response);
            });
    };

    var lockDatasheet = function () {
        var userId = localStorageService.get('userInformationData')._id;
        var accountId = localStorageService.get('userInformationData').AccountId._id;
        firebaseFactory.set(accountId + '/locked/datasheet/' + datasheetId, {
            isLocked: true,
            lockedBy: userId,
            lockedDate: new Date().toString()
        });
    };

    var unLockDatasheet = function () {
        var accountId = localStorageService.get('userInformationData').AccountId._id;

        //Kullanıcı tarafından locked işlemi yapıldıysa serbest bırak
        if (!$scope.isLocked) {
            firebaseFactory.set(accountId + '/locked/datasheet/' + datasheetId, null);
        }
    };
    //Inner Functions End

    //Functions
    $scope.save = function () {
        return datasheetFactory.saveDatasheet($scope.datasheetModel)
            .then(function (response) {
                $scope.datasheetModel.datasheet.minorVersion = response.minorVersion;
            });
    };

    $scope.preview = function () {
        var previewWindow = window.open(config.app[config.mode].appUrl + '/' + $scope.activeLang + '/pdf/preparing', '_blank');

        return datasheetFactory.saveDatasheet($scope.datasheetModel)
            .then(function (response) {
                $scope.datasheetModel.datasheet.minorVersion = response.minorVersion;
                angular.copy($scope.datasheetModel.elements, firstVersionElements);
                return datasheetFactory.preview(
                    $scope.datasheetModel.datasheet._id,
                    $scope.selectionModel.selectedLanguage).then(function (previewResponse) {
                        $timeout(function () {
                            var previewPdf = previewResponse.fileWebPath.replace("~", config.api[config.mode].apiUrl);
                            localStorageService.set('previewPdf', previewPdf);
                            var url = $state.href('pdf.viewPreview', {
                                lang: $rootScope.activeLang
                            }, { absolute: true });

                            previewWindow.location = url;
                        }, 500);
                    });
            });
    };

    $scope.publish = function () {
        return datasheetFactory.publishDataSheet($scope.datasheetModel, $scope.templateModel)
            .then(function (response) {
                if (INTERCOM_SETTINGS.IS_SHOWN) {
                    window.Intercom('shutdown');
                    userFactory.loadUserInformation()
                        .then(function (user) {
                            window.Intercom('boot', {
                                app_id: INTERCOM_SETTINGS.APP_ID,
                                email: user.Email,
                                user_id: user._id,
                                user_hash: user.hash
                            });
                        });
                }

                intercomFactory.addEvent({
                    event_name: "Datasheet Created",
                    metadata: {
                        productName: $scope.datasheetModel.datasheet.productName,
                        productNumber: $scope.datasheetModel.datasheet.productNumber,
                        primaryLanguage: $scope.datasheetModel.datasheet.primaryLanguage
                    }
                });

                angular.copy($scope.datasheetModel.elements, firstVersionElements);

                $scope.datasheetModel.datasheet.isPublished = true;
                $scope.datasheetModel.datasheet.majorVersion = response.majorVersion;
                $scope.datasheetModel.datasheet.minorVersion = response.minorVersion;

                var preferences = localStorageService.get('userInformationData').Preferences;

                if (preferences) {
                    var socialMediaDatasheetPrompt = localStorageService.get('userInformationData').Preferences.SocialMediaDatasheetPrompt;
                    socialMediaDatasheetPrompt != undefined ? socialMediaDatasheetPrompt = JSON.parse(socialMediaDatasheetPrompt) : socialMediaDatasheetPrompt = true;

                    if (socialMediaDatasheetPrompt) {
                        var datasheet = $scope.datasheetModel.datasheet;
                        datasheet.showButtons = true;
                        var modalInstance = $modal.open({
                            animation: false,
                            controller: 'shareOnSocialMediaCtrl',
                            templateUrl: '/app/components/datasheet/shared/views/modals/shareOnSocialMedia.html',
                            backdropClass: "defaultModal",
                            size: "sm",
                            resolve: {
                                datasheet: function () {
                                    return datasheet;
                                }
                            }
                        });

                        modalInstance.result.then(function (response) {
                        }).finally(function () {
                            modalInstance.$destroy();
                        });
                    }
                }

            });
    };
    //Functions End

    //Initials     
    menuToggleFactory.collapse();
    editDatasheetInitialize();
    //Initials End
};

module.exports = function (app) {
    app.controller('editDatasheetCtrl', ['$window', '$rootScope', '$scope', '$state', '$stateParams', '$translate', '$timeout', '$modal', 'datasheetFactory', 'templateFactory', 'uploadFactory', 'datasheetDataEditingService', 'googleTranslate', 'menuToggleFactory', 'localStorageService', 'folderFactory', 'htmlTagCleaner', 'firebaseFactory', 'userFactory', 'intercomFactory', 'config', '_', editDatasheetCtrl]);
};