var deletePermissionLevelCtrl = function($scope, $timeout, $modalInstance, permissionFactory, accountPermissionLevelId) {
    //Fields
    $scope.deleteButton = "PERMISSION.ALLPERMISSIONLEVELS.MODALS.DELETEPERMISSIONLEVEL.BTN_DELETE.TEXT";
    $scope.isProcessing = false;
    //Fields End

    //Functions
    $scope.cancel = function() {
        $modalInstance.dismiss('cancel');
    };

    $scope.delete = function() {
        $scope.deleteButton = "PERMISSION.ALLPERMISSIONLEVELS.MODALS.DELETEPERMISSIONLEVEL.BTN_DELETE.ALTERNATE_TEXT";
        $scope.isProcessing = true;

        permissionFactory.deleteAccountPermissionLevel(accountPermissionLevelId)
            .then(function(response) {
                $scope.deleteButton = "PERMISSION.ALLPERMISSIONLEVELS.MODALS.DELETEPERMISSIONLEVEL.BTN_DELETE.SUCCESS";

                $timeout(function() {
                    $modalInstance.close({
                        status: true
                    });
                }, 300);
            });
    };
    //Functions End

    //Initials
    //Initials End
};

module.exports = function(app) {
    app.controller('deletePermissionLevelCtrl', ['$scope', '$timeout', '$modalInstance', 'permissionFactory', 'accountPermissionLevelId', deletePermissionLevelCtrl]);
};
