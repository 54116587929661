var renameTemplateCtrl = function($scope, $log, $translate, $modalInstance, templateFactory, selectedTemplate) {
    //Fields
    $scope.updateButton = "TEMPLATE.YOURTEMPLATES.MODALS.RENAMETEMPLATE.BTN_UPDATE.TEXT";
    $scope.isProcessing = false;

    $scope.template = angular.copy(selectedTemplate);
    $log.info($scope.template);
    //Fields End

    //Functions
    $scope.cancel = function() {
        $modalInstance.dismiss();
    };

    $scope.renameTemplate = function(isValid) {
        if (isValid && $scope.template.name != selectedTemplate.name) {
            $scope.updateButton = "TEMPLATE.YOURTEMPLATES.MODALS.RENAMETEMPLATE.BTN_UPDATE.ALTERNATE_TEXT";
            $scope.isProcessing = true;

            templateFactory.renameTemplate($scope.template._id, $scope.template.name)
                .then(function(response) {
                    $scope.updateButton = "TEMPLATE.YOURTEMPLATES.MODALS.RENAMETEMPLATE.BTN_UPDATE.TEXT";
                    $scope.isProcessing = false;
                    if (response) {
                        var message = $translate.instant("TEMPLATE.YOURTEMPLATES.MODALS.RENAMETEMPLATE.SUCCESS_MESSAGES.RENAMED_SUCCESSFULLY");
                        toastr.success(message, '', { positionClass: 'toast-bottom-full-width', timeOut: 3000 });
                        $modalInstance.close({
                            status: true
                        });
                    }
                });
        }
    };
    //Functions End

    //Initials
    //Initials End
};

module.exports = function(app) {
    app.controller('renameTemplateCtrl', ['$scope', '$log', '$translate', '$modalInstance', 'templateFactory', 'selectedTemplate', renameTemplateCtrl]);
};
