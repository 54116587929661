var subscriptionWarningModalCtrl = function($rootScope, $scope, $state, $modalInstance, model){

  $scope.errorMessage = model.text;
  //Functions
  $scope.cancel = function() {
      $modalInstance.dismiss('cancel');
  };

  $scope.gotoSubscription = function(){
    $state.go('app.accountSubscription', {lang : $rootScope.activeLang});
    $modalInstance.dismiss('cancel');
  };
  //Functions - End
};


module.exports = function(app){
  app.controller('subscriptionWarningModalCtrl', ['$rootScope', '$scope', '$state', '$modalInstance', 'model', subscriptionWarningModalCtrl]);
};
