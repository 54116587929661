var panelScroll = function($window){
  return{
      restrict: 'A',
      link: function (scope, el, attrs) {
        var width = attrs.ssWidth;
        var height = attrs.ssHeight;

        var setSlimScroll = function(){
            $(el).slimscroll({
                width : width,
                height : height,
                alwaysVisible : false,
                railVisible: false
            });
        };


        function onResize(){
            setSlimScroll();

            if(!scope.$$phase)
                scope.$digest();
        };

        function cleanUp() {
            angular.element($window).off('resize', onResize);
        }

        angular.element($window).on('resize', onResize);
        scope.$on('$destroy', cleanUp);

        setSlimScroll();
      }
  }
};

module.exports = function(app){
  app.directive('panelScroll', ['$window', panelScroll]);
}
