var searchFactory = function(httpProvider){
  var factoryModel = {};
  factoryModel.search = function(keys) {
    var model = { searchKeys: keys };
    return httpProvider.post('form', 'searchTDS', model);
  };

  return factoryModel;
};

module.exports = function(app){
  app.factory('searchFactory', ['httpProvider', searchFactory]);
};
