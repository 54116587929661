var deleteLibraryTemplateCtrl = function($scope, $timeout, $translate, $modalInstance, templateFactory, selectedTemplate, mode) {
    //Fields
    $scope.deleteButton = "TEMPLATE.YOURTEMPLATES.MODALS.DELETETEMPLATE.BTN_DELETE.TEXT";
    $scope.isProcessing = false;
    $scope.mode = mode;

    $scope.lblDeleteTemplateTranslationData = {
        templateName: selectedTemplate.name
    };
    //Fields End

    //Functions
    $scope.cancel = function() {
        $modalInstance.dismiss();
    };

    $scope.ok = function() {
        $scope.deleteButton = "TEMPLATE.YOURTEMPLATES.MODALS.DELETETEMPLATE.BTN_DELETE.ALTERNATE_TEXT";
        $scope.isProcessing = true;
        templateFactory.deleteLibraryTemplate(selectedTemplate.templateId)
            .then(function(response) {
                $scope.deleteButton = "TEMPLATE.YOURTEMPLATES.MODALS.DELETETEMPLATE.BTN_DELETE.TEXT";
                $scope.isProcessing = false;

                $timeout(function() {
                    $modalInstance.close({
                        status: true
                    });
                }, 500);

            });
    };
    //Functions End

    //Initials
    //Initials End
};

module.exports = function(app) {
    app.controller('deleteLibraryTemplateCtrl', ['$scope', '$timeout', '$translate', '$modalInstance', 'templateFactory', 'selectedTemplate', 'mode', deleteLibraryTemplateCtrl]);
};
