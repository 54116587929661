var moveDatasheetCtrl = function($scope, $translate, $modalInstance, folderFactory, datasheetFactory, checkPermissionFactory, selectedDatasheet) {
    //Fields
    $scope.translatedArchive = $translate.instant("DATASHEET.YOURDATASHEETS.MODALS.MOVEDATASHEET.LBL_ARCHIVE.TEXT");
    $scope.rootFolder = {};
    $scope.selectedFolder = {};
    $scope.datasheetFolders = [];

    $scope.okButton = "DATASHEET.YOURDATASHEETS.MODALS.MOVEDATASHEET.BTN_OK.TEXT";
    $scope.isProcessing = false;

    $scope.loaded = {
        datasheet: {
            folders: false
        }
    };
    //Fields End

    //Inner Functions
    var getDatasheetFolders = function(nextProcess) {
        $scope.loaded.datasheet.folders = false;
        folderFactory.getDatasheetFolders()
            .then(function(response) {
                var datasheetFolders = response;
                datasheetFolders = checkPermissionFactory.canMoveDatasheetToFolders(datasheetFolders);

                $scope.datasheetFolders = datasheetFolders;
                for (var i = 0; i < $scope.datasheetFolders.length; i++) {
                    if ($scope.datasheetFolders[i].Parent == "#") {
                        $scope.datasheetFolders[i].Name = $scope.translatedArchive;
                        $scope.rootFolder = $scope.datasheetFolders[i];
                        break;
                    }
                }

                for (var i = 0; i < $scope.datasheetFolders.length; i++) {
                    var datasheetFolder = $scope.datasheetFolders[i];
                    datasheetFolder.id = datasheetFolder._id;
                    datasheetFolder.text = datasheetFolder.Name;
                    datasheetFolder.parent = datasheetFolder.Parent;
                }

                $scope.loaded.datasheet.folders = true;
                nextProcess();
            });
    };
    //Inner Functions End

    //Functions
    $scope.cancel = function() {
        $modalInstance.dismiss();
    };

    $scope.moveDatasheet = function() {
        $scope.okButton = "DATASHEET.YOURDATASHEETS.MODALS.MOVEDATASHEET.BTN_OK.ALTERNATE_TEXT";
        $scope.isProcessing = true;

        var datasheetId = selectedDatasheet._id;
        var folderId = $scope.selectedFolder.id;
        datasheetFactory.moveDatasheet(datasheetId, folderId)
            .then(function(response) {
                $scope.okButton = "DATASHEET.YOURDATASHEETS.MODALS.MOVEDATASHEET.BTN_OK.ALTERNATE_TEXT";
                $scope.isProcessing = false;

                if (response.status) {
                    var message = $translate.instant("DATASHEET.YOURDATASHEETS.MODALS.MOVEDATASHEET.SUCCESS_MESSAGES.MOVED_SUCCESSFULLY");
                    toastr.success(message, '', { positionClass: 'toast-bottom-full-width', timeOut: 3000 });
                    $modalInstance.close({
                        status: true
                    });
                }
            });
    };
    //Functions End

    //Initials
    getDatasheetFolders(function() {
        $scope.folders = $scope.datasheetFolders;
    });
    //Initials End
};

module.exports = function(app) {
    app.controller('moveDatasheetCtrl', ['$scope', '$translate', '$modalInstance', 'folderFactory', 'datasheetFactory', 'checkPermissionFactory', 'selectedDatasheet', moveDatasheetCtrl]);
};
