var checkPermissionFactory = function (httpProvider, $filter, $q, $timeout, localStorageService, _) {
    //Fields
    var factoryModel = {};
    //Fields - End

    //Functions
    factoryModel.canEditTemplate = function (templateId) {
        return httpProvider.get('permission', 'canEditTemplate', 'templateId=' + templateId);
    };

    factoryModel.canEditDatasheet = function (datasheetId) {
        return httpProvider.get('permission', 'canEditDatasheet', 'datasheetId=' + datasheetId);
    };

    factoryModel.canSelectDatasheetVersion = function (datasheetId) {
        return httpProvider.get('permission', 'canSelectDatasheetVersion', 'datasheetId=' + datasheetId);
    };

    factoryModel.canSaveDatasheetToFolders = function (folders) {
        for (var f = 0; f < folders.length; f++) {
            var folder = folders[f];
            var itemPermissions = folder.ItemPermissions;

            if (!folder.state.disabled) {
                var canSaveDatasheet = _.find(itemPermissions, {
                    Name: "New Datasheet"
                });

                if (canSaveDatasheet) {
                    folder.state.disabled = false;
                } else {
                    folder.state.disabled = true;
                }
            }
        }

        return folders;
    };

    factoryModel.canMoveDatasheetToFolders = function (folders) {
        for (var f = 0; f < folders.length; f++) {
            var folder = folders[f];
            var itemPermissions = folder.ItemPermissions;

            if (!folder.state.disabled) {
                var canSaveDatasheet = _.find(itemPermissions, {
                    Name: "Move Datasheet"
                });

                if (canSaveDatasheet) {
                    folder.state.disabled = false;
                } else {
                    folder.state.disabled = true;
                }
            }
        }

        return folders;
    };

    factoryModel.canMoveDatasheetFolderToFolders = function (folders) {
        for (var f = 0; f < folders.length; f++) {
            var folder = folders[f];
            var itemPermissions = folder.ItemPermissions;

            if (!folder.state.disabled) {
                var canSaveDatasheet = _.find(itemPermissions, {
                    Name: "Move Datasheet Folder"
                });

                if (canSaveDatasheet) {
                    folder.state.disabled = false;
                } else {
                    folder.state.disabled = true;
                }
            }
        }

        return folders;
    };

    factoryModel.canSelectFoldersForCopy = function (folders) {
        for (var f = 0; f < folders.length; f++) {
            var folder = folders[f];
            var itemPermissions = folder.ItemPermissions;

            if (!folder.state.disabled) {
                var canCopyDatasheet = _.find(itemPermissions, {
                    Name: "Copy Datasheet"
                });

                if (canCopyDatasheet) {
                    folder.state.disabled = false;
                } else {
                    folder.state.disabled = true;
                }
            }
        }

        return folders;
    };

    factoryModel.canSelectDatasheetForCopy = function (datasheets) {
        var selectableDatasheets = [];
        for (var d = 0; d < datasheets.length; d++) {
            var datasheet = datasheets[d];
            var permissions = datasheet.datasheetPermissions;

            var canSelectDatasheetForCopy = _.find(permissions, {
                Name: "Copy Datasheet"
            });

            if (canSelectDatasheetForCopy) {
                selectableDatasheets.push(datasheet);
            }

        }

        return selectableDatasheets;
    };

    factoryModel.canSaveTemplateToFolders = function (folders) {
        for (var f = 0; f < folders.length; f++) {
            var folder = folders[f];
            var itemPermissions = folder.ItemPermissions;

            if (!folder.state.disabled) {
                var canSaveTemplate = _.find(itemPermissions, {
                    Name: "New Template"
                });

                if (canSaveTemplate) {
                    folder.state.disabled = false;
                } else {
                    folder.state.disabled = true;
                }
            }
        }

        return folders;
    };

    factoryModel.canSelectTemplateForCopy = function (templates) {
        var selectableTemplates = [];
        for (var t = 0; t < templates.length; t++) {
            var template = templates[t];
            var permissions = template.permissions;

            var canSelectTemplateForCopy = _.find(permissions, {
                Name: "Copy Template"
            });

            if (canSelectTemplateForCopy) {
                selectableTemplates.push(template);
            }

        }

        return selectableTemplates;
    };


    //Functions End

    //Inner Functions
    //Inner Functions End

    return factoryModel;
};

module.exports = function (app) {
    app.factory('checkPermissionFactory', ['httpProvider', '$filter', '$q', '$timeout', 'localStorageService', '_', checkPermissionFactory]);
};