var confirmInvitationCtrl = function($rootScope, $scope, $state, $stateParams, $timeout, $interval, userFactory) {
    //Fields
    var emailId = $stateParams.id;
    $scope.progressValue = 0;
    //Fields - End

    //Functions
    //Functions - End

    //Inner Functions
    var confirmInvitedUser = function(emailId, nextProcess) {
        userFactory.confirmInvitedUser($stateParams.id)
            .then(function(response) {
                nextProcess(response.user);

            });
    };
    //Inner Functions End

    //Initials
    var progressBar = $interval(function() {
        $scope.progressValue += 10;
        if ($scope.progressValue >= 90) {
            $interval.cancel(progressBar);

            confirmInvitedUser(emailId, function(user) {
                $scope.progressValue = 100;

                $timeout(function() {
                    if (user.Status == 1) {

                        $state.go('auth.lastStep', {
                            lang: $rootScope.activeLang,
                            id: user._id
                        });

                    } else {
                        $state.go('auth.signIn', {
                            lang: $rootScope.activeLang
                        });
                    }
                }, 500);
            });
        }
    }, 500);
    //Initials End
};

module.exports = function(app) {
    app.controller('confirmInvitationCtrl', ['$rootScope', '$scope', '$state', '$stateParams', '$timeout', '$interval', 'userFactory', confirmInvitationCtrl]);
};
