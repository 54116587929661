var checkPermission = function($timeout, $filter, localStorageService) {
    return {
        scope: {
            userPermissions: '=',
            requiredPermissions: '='
        },
        restrict: 'A',
        link: function(scope, el, attrs) {
            scope.$watch(function() {
                return scope.userPermissions;
            }, function(newVal, oldVal) {
                if (angular.isUndefined(scope.userPermissions)) {
                    return;
                };

                var userPermissions = scope.userPermissions;
                var requiredPermissions = scope.requiredPermissions;

                var hasPermission = false;
                for (var i = 0; i < requiredPermissions.length; i++) {
                    var requiredPermission = requiredPermissions[i];

                    var foundPermission = $filter('filter')(userPermissions, {
                        Name: requiredPermission
                    })[0];

                    if (foundPermission != null && angular.isDefined(foundPermission)) {
                        hasPermission = true;
                        break;
                    }
                }

                if (!hasPermission) {
                    el.hide();
                } else {
                    el.show();
                }
            }, true);


        }
    }
};

module.exports = function(app) {
    app.directive('checkPermission', ['$timeout', '$filter', 'localStorageService', checkPermission]);
};
