var manageArchiveFileFieldsCtrl = function($scope, $timeout, $translate, $modalInstance, archiveFileFactory, archiveFile) {
    //Fields
    var oldFileFields = angular.copy(archiveFile.fields);

    $scope.updateButton = "CUSTOMARCHIVES.YOURARCHIVES.MODALS.MANAGEARCHIVEFILEFIELDS.BTN_UPDATE.TEXT";
    $scope.isProcessing = false;
    //Fields End

    //Functions
    $scope.cancel = function() {
        archiveFile.fields = oldFileFields;
        $modalInstance.dismiss();
    };

    $scope.update = function(isValid) {
        if (isValid) {
            $scope.updateButton = "CUSTOMARCHIVES.YOURARCHIVES.MODALS.MANAGEARCHIVEFILEFIELDS.BTN_UPDATE.ALTERNATE_TEXT";
            $scope.isProcessing = true;

            archiveFileFactory.updateArchiveFileFields($scope.archiveFile)
                .then(function(response) {
                    $scope.updateButton = "CUSTOMARCHIVES.YOURARCHIVES.MODALS.MANAGEARCHIVEFILEFIELDS.BTN_UPDATE.TEXT";
                    $scope.isProcessing = false;
                    var message = $translate.instant("CUSTOMARCHIVES.YOURARCHIVES.MODALS.MANAGEARCHIVEFILEFIELDS.SUCCESS_MESSAGES.UPDATED_SUCCESSFULLY");
                    toastr.success(message, '', { positionClass: 'toast-bottom-full-width', timeOut: 3000 });
                    $timeout(function() {
                        $modalInstance.close(true)
                    }, 250);
                });
        }
    };
    //Functions End

    //Initials
    $scope.archiveFile = archiveFile;
    //Initials End
};

module.exports = function(app) {
    app.controller('manageArchiveFileFieldsCtrl', ['$scope', '$timeout', '$translate', '$modalInstance', 'archiveFileFactory', 'archiveFile', manageArchiveFileFieldsCtrl]);
};
