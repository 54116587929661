(function() {
    'use strict';
    require("./core/");
    require("./core.layout/");
    require("./core.translate/");
    require("./core.loading/");
    require("./errors/");
    require("./authentication/");
    require("./mailGroups/");
    require("./template/");
    require("./datasheet/");
    require("./search/");
    require("./pdf/");
    require("./users/");
    require("./dashboard/");
    require("./settings/");
    require("./groups/");
    require("./permissions/");
    require("./folder/");
    require("./subscription/");
    require("./backup/");
    require("./integration/");
    require("./customArchives/");
    require("./reports/");
    require("./admin/");
    require("./accountSettings/");
}());
