var activitiesCtrl = function($rootScope, $scope, $filter, $timeout, $translate, config, analyticFactory) {
    //Fields
    $scope.loaded = {
        activities: false
    };
    //Fields End

    //Events
    $rootScope.$on('pdfViewsChartResized', function(event, data) {
        setSlimScroll();
    });
    //Events End

    //Watches
    //Watches End

    //Inner Functions
    var getActivities = function(nextProcess) {
        $scope.loaded.activities = false;
        analyticFactory.getActivities()
            .then(function(response) {

                for(var i in response){
                    var item = response[i];
                    var analyticType = item.AnalyticItem.ActivityType;

                    if(analyticType !== "PublishTemplate" && analyticType !== "PublishDatasheet" && analyticType !== "CreateNewDatasheet" && analyticType !== "CreateTemplate")
                        item.hasUrl = false;

                    if(item.AnalyticItem.ActivityItems.TemplateId !== undefined && item.AnalyticItem.ActivityItems.TemplateId !== null){
                        item.hasUrl = true;
                        var templateId = item.AnalyticItem.ActivityItems.TemplateId;
                        item.AnalyticItem.ActivityItems.TemplateId = config.app[config.mode].appUrl + "/tr/editor/edit-template/" + templateId;
                    }

                     if(item.AnalyticItem.ActivityItems.DatasheetId !== undefined && item.AnalyticItem.ActivityItems.DatasheetId !== null){
                        item.hasUrl = true;
                        var DatasheetId = item.AnalyticItem.ActivityItems.DatasheetId;
                        item.AnalyticItem.ActivityItems.DatasheetId = config.app[config.mode].appUrl + "/tr/editor/edit-datasheet/" + DatasheetId;
                    }
                }
                
                $scope.activities = response;
                $scope.loaded.activities = true;

                nextProcess();
            });
    };

    var setSlimScroll = function() {
            var height = $('#pdfViewsChart-card-box').height();
            $('#activities-card-box').height(height);
            $timeout(function() {
                $('#activities-scroll-content').slimScroll({
                    height: (height - 45) + 'px'
                });
            }, 250);
        }
        //Inner Functions End

    //Functions
    $scope.getActivityDate = function(activityCreatedDate) {
        var today = new Date();
        var activityDate = new Date(activityCreatedDate);
        var diffMs = (today - activityDate);
        var diffMins = Math.round((diffMs / 1000) / 60);
        var diffHrs = Math.round(((diffMs / 1000) / 60) / 60);


        if (diffMins > 1440) {
            return $filter('date')(activityCreatedDate, 'dd/MM/yyyy HH:MM:ss');
        } else if (diffMins > 60) {
            var hourAgo = "";
            if (diffHrs > 1) {
                hourAgo = $translate.instant('DASHBOARD.DASHBOARD.PARTIALS.ACTIVITIES.LBL_HOUR_AGO.PLURAL_TEXT');
            } else {
                hourAgo = $translate.instant('DASHBOARD.DASHBOARD.PARTIALS.ACTIVITIES.LBL_HOUR_AGO.TEXT');
            }
            return diffHrs + " " + hourAgo;
        } else {
            var minuteAgo = "";
            if (diffHrs > 1) {
                minuteAgo = $translate.instant('DASHBOARD.DASHBOARD.PARTIALS.ACTIVITIES.LBL_MINUTE_AGO.PLURAL_TEXT');
            } else {
                minuteAgo = $translate.instant('DASHBOARD.DASHBOARD.PARTIALS.ACTIVITIES.LBL_MINUTE_AGO.TEXT');
            }
            return diffMins + " " + minuteAgo;
        }

    };
    //Functions End

    //Initials

    getActivities(function() {
        setSlimScroll();
    });
    //Initials End
};

module.exports = function(app) {
    app.controller('activitiesCtrl', ['$rootScope', '$scope', '$filter', '$timeout', '$translate', 'config', 'analyticFactory', activitiesCtrl]);
};
