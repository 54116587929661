var addUserToGroupCtrl = function($scope, $timeout, $modalInstance, userFactory, groupFactory, groupId) {
    //Fields
    $scope.selectedUsers = [];
    //Fields End

    //Functions
    $scope.cancel = function() {
        $modalInstance.dismiss('cancel');
    };

    $scope.getUserPickerSuggestion = function(searchString) {
        return userFactory.getAllUserForPicker()
            .then(function(response) {
                var allUsers = response;

                var filteredUsers = allUsers.filter(function(user) {
                    return user.Name.toLowerCase().indexOf(searchString.toLowerCase()) != -1;
                });
                return filteredUsers;
            });
    };

    $scope.ok = function() {
        $scope.errors = [];

        if ($scope.selectedUsers.length === 0) {
            $scope.errors.push('GROUP.GROUPUSERS.MODALS.ADDUSERTOGROUP.ERROR_MESSAGES.SELECT_ONE_USER_OR_MORE');
        }

        if ($scope.errors.length > 0) {
            $timeout(function() {
                $scope.errors = [];
            }, 3000);
            return;
        }

        var model = {};
        model.groupId = groupId;
        model.users = [];

        for (var i = 0; i < $scope.selectedUsers.length; i++) {
            var user = $scope.selectedUsers[i];
            model.users.push(user._id);
        }

        groupFactory.addUsersToGroup(model)
            .then(function(response) {
                if (response.status) {
                    $modalInstance.close({
                        status: true
                    });
                }
            });
    };

    $scope.closeAlert = function() {
        $scope.errors = [];
    };
    //Functions End

    //Inner Functions
    //Inner Functions End

    //Initials
    //Initials End
};

module.exports = function(app) {
    app.controller('addUserToGroupCtrl', ['$scope', '$timeout', '$modalInstance', 'userFactory', 'groupFactory', 'groupId', addUserToGroupCtrl]);
};
