var loadingCtrl = function($rootScope, $scope, $state, $stateParams, localStorageService, loadingFactory, generalsService, datasheetFactory, templateFactory, authApiProvider) {

    // var isChrome = !!window.chrome && !!window.chrome.webstore;
    var isChrome = true;
    
    var convertToken = function(token){
        authApiProvider.post("/tokenConvert", {
            token : token
        }).then(function(response){
            if(response.status){
                localStorageService.set('authData', response.data.token);
            }
        });
    }

    var loadApplication = function() {

        var authorizationData = localStorageService.get('authorizationData');
        var authData = localStorageService.get('authData');

        if (authorizationData) {
            if(!authData){ //Yeni key'i oluştur.
                convertToken(authorizationData.token);
            }else{
                authApiProvider.post("/verify", {
                    token : authData
                }).then(function(response){
                    if(!response.status){
                        convertToken(authorizationData.token);
                    }
                });
            }


            if (!$rootScope.isLoaded) {
                loadingFactory.loadApplication()
                    .then(function() {

                        var toStateName = 'app.dashboard';
                        var toStateParams = {
                            lang: $rootScope.activeLang
                        };

                        //tarayıcı chrome değilse ve önceden yine de devam et seçeneğini seçerek localStorage'a değer atanmamışsa hata göster
                        if ((!isChrome) && (authorizationData.skipBrowserUpgrade == null || !authorizationData.skipBrowserUpgrade)) {
                            $state.go('errors.browserUpgrade', {
                                lang: $rootScope.activeLang
                            });
                            return;
                        }

                        if (angular.isDefined($rootScope.toStateName) && $rootScope.toStateName != "blank.loading" && $rootScope.toStateName != "auth.signIn") {
                            toStateName = $rootScope.toStateName;
                            toStateParams = $rootScope.toStateParams;
                            toStateParams.lang = $rootScope.activeLang;
                        }

                        if (toStateName == "editor.editDatasheet") {
                            datasheetFactory.doesDatasheetBelongToThisAccount(toStateParams.id)
                                .then(function(response) {
                                    $state.go(toStateName, toStateParams);
                                }, function(error, code) {
                                    $state.go("app.dashboard", {
                                        lang: $rootScope.activeLang
                                    });
                                });
                        } else if (toStateName == "editor.editTemplate") {
                            templateFactory.doesTemplateBelongToThisAccount(toStateParams.id)
                                .then(function(response) {
                                    $state.go(toStateName, toStateParams);
                                }, function(error, code) {
                                    $state.go("app.dashboard", {
                                        lang: $rootScope.activeLang
                                    });
                                });
                        } else {
                            $state.go(toStateName, toStateParams);
                        }
                    });
            } else {
                window.history.go(-2);
            }
        } else {
            loadingFactory.preLoadApplication();
            
            if($rootScope.toStateName === "auth.lastStep" || $rootScope.toStateName === "auth.resetPassword"){
                $state.go($rootScope.toStateName, $rootScope.toStateParams);
            }else{
                $state.go('auth.signIn', {
                    lang: $rootScope.activeLang
                });
            }
            

        }
    }

    loadApplication();

};

module.exports = function(app) {
    app.controller('loadingCtrl', ['$rootScope', '$scope', '$state', '$stateParams', 'localStorageService', 'loadingFactory', 'generalsService', 'datasheetFactory', 'templateFactory', 'authApiProvider', loadingCtrl]);
};
