var datasheetFactory = function ($q, $translate, $modal, httpProvider, generalsService) {
    var factoryModel = {};

    //Functions
    factoryModel.createNewDatasheet = function (model) {
        return httpProvider.post('form', 'createNewDatasheet', model);
    };

    factoryModel.getDatasheetById = function (id) {
        return httpProvider.get('form', 'getDatasheetById', "id=" + id);
    }

    //TODO : Şu kısmı kullanmayabiliriz.
    factoryModel.getDatasheet = function (id) {
        return httpProvider.get('form', 'getDatasheet', "id=" + id);
    };

    factoryModel.getDatasheetByIdAnounmously = function (id) {
        return httpProvider.get('form', 'getDatasheetByIdAnounmously', "id=" + id);
    };

    factoryModel.getPublishedDatasheetAnounmously = function (id) {
        return httpProvider.get('form', 'getPublishedDatasheetAnounmously', "id=" + id);
    };

    factoryModel.getDatasheets = function (path) {
        return httpProvider.get('form', 'getDatasheets', "path=" + path);
    };

    factoryModel.getDatasheetsByFolderId = function (folderId) {
        var params = "folderId=" + folderId;
        return httpProvider.get('form', 'getDatasheetsByFolderId', params);
    };
    factoryModel.regeneratePublishedDatasheet = function (datasheetId) {
        return httpProvider.get('form', 'regeneratePublishedDatasheet', "datasheetId=" + datasheetId);
    };

    factoryModel.saveDatasheet = function (model) {
        var requestModel = {};

        angular.copy(model, requestModel);

        var elements = _arrangeDatasheetElements(model.elements, model.datasheet._id);
        requestModel.elements = elements;

        return httpProvider.post('form', 'saveDatasheet', requestModel);
    };

    factoryModel.publishDataSheet = function (datasheetModel, templateModel) {
        var deferred = $q.defer();

        if (datasheetModel.datasheet.isPublished) {
            var modalInstance = $modal.open({
                animation: false,
                controller: 'confirmVersionUpgrade',
                templateUrl: '/app/components/datasheet/editDatasheet/views/modals/confirmVersionUpgrade.html',
                backdropClass: "defaultModal",
                resolve: {
                    version: function () {
                        return datasheetModel.datasheet.majorVersion;
                    }
                }
            });

            modalInstance.result.then(function (modalResult) {
                datasheetModel.datasheet.hasMajorVersionUpgrade = modalResult;

                var request = {
                    datasheetModel: datasheetModel,
                    templateModel: templateModel
                };

                var elements = _arrangeDatasheetElements(request.datasheetModel.elements, request.datasheetModel.datasheet._id);
                request.datasheetModel.elements = elements;
                httpProvider.post('form', 'publishDataSheet', request)
                    .then(function (response) {
                        deferred.resolve(response);
                    });
            });
        } else {
            datasheetModel.datasheet.hasMajorVersionUpgrade = true;
            datasheetModel.datasheet.isPublished = true;

            var request = {
                datasheetModel: datasheetModel,
                templateModel: templateModel
            };

            var elements = _arrangeDatasheetElements(request.datasheetModel.elements, request.datasheetModel.datasheet._id);
            request.datasheetModel.elements = elements;
            httpProvider.post('form', 'publishDataSheet', request)
                .then(function (response) {
                    deferred.resolve(response);
                });
        }

        return deferred.promise;
    };

    factoryModel.getDatasheetCommentsByDatasheetId = function (datasheetId) {
        return httpProvider.get('form', 'getDatasheetCommentsByDatasheetId', 'datasheetId=' + datasheetId)
    };

    factoryModel.saveDatasheetComment = function (model) {
        return httpProvider.post('form', 'saveDatasheetComment', model);
    };

    factoryModel.preview = function (id, language) {
        return httpProvider.getNonQuery('pdf', 'previewDatasheet', id + "/" + language);
    };

    factoryModel.removeDatasheet = function (id) {
        return httpProvider.post('form', 'removeDatasheet', {
            id: id
        });
    };

    factoryModel.getPublishedDatasheets = function (id) {
        return httpProvider.get('form', 'getPublishedDatasheets', "id=" + id);
    };
    
    factoryModel.getPublishedDatasheetsWithoutElements = function (id) {
        return httpProvider.get('form', 'getPublishedDatasheetsWithoutElements', "id=" + id);
    };

    factoryModel.getPublishedDatasheet = function (id) {
        return httpProvider.get('form', 'getPublishedDatasheet', "id=" + id);
    };

    factoryModel.hasPublishedDatasheetsByDatasheetId = function (id) {
        return httpProvider.get('form', 'hasPublishedDatasheetsByDatasheetId', "id=" + id);
    };

    factoryModel.getPublishedDatasheetForViewPdf = function (id) {
        return httpProvider.get('form', 'getPublishedDatasheetForViewPdf', "id=" + id);
    };

    factoryModel.moveDatasheet = function (datasheetId, folderId) {
        return httpProvider.put('form', 'moveDatasheet', {
            datasheetId: datasheetId,
            folderId: folderId
        });
    };

    factoryModel.getAllPublishedByFolderId = function (folderId) {
        return httpProvider.get('form', 'getAllPublishedByFolderId', "folderId=" + folderId);
    };

    factoryModel.hasPublishedDatasheetsByFolderId = function (folderId) {
        return httpProvider.get('form', 'hasPublishedDatasheetsByFolderId', "folderId=" + folderId);
    };

    factoryModel.getAllByFolder = function (model) {
        return httpProvider.post('form', 'getAllByFolder', model);
    };

    factoryModel.hasDatasheetWithSameProductNumber = function (templateId, productNumber) {
        return httpProvider.get('form', 'hasDatasheetWithSameProductNumber', 'templateId=' + templateId + '&productNumber=' + productNumber);
    }

    factoryModel.doesDatasheetBelongToThisAccount = function (datasheetId) {
        return httpProvider.get('form', 'doesDatasheetBelongToThisAccount', 'id=' + datasheetId);
    };

    factoryModel.getPublishedDatasheetsByIdsVersion = function(model){
        return httpProvider.post('form', "getPublishedDatasheetsByIdsVersion", model);
    }

    factoryModel.hasAnyDatasheet = function(accountId){
        return httpProvider.get('form', 'hasAnyDatasheet', 'accountId=' + accountId);
    }

    factoryModel.getAllDatasheetsByAccountId = function(accountId){
        return httpProvider.get('form', 'getAllDatasheetsByAccountId', 'accountId=' + accountId);
    }
    //Functions End

    //Inner Functions
    var _arrangeDatasheetElements = function (datasheetElements, datasheetId) {
        var elements = [];
        var templateElements = _.filter(datasheetElements, function (element) {
            return !element.isDynamic;
        });

        for (var te in templateElements) {
            var templateElement = templateElements[te];
            var element = {
                datasheetId: datasheetId,
                type: templateElement.type
            };

            if (templateElement.type == "page" || templateElement.type == "row" || templateElement.type == "inputtext" || templateElement.type == "selectlist" || templateElement.type == "inputicon" || templateElement.type == "inputimage" || templateElement.type == "text" || templateElement.type == "icon" || templateElement.type == "image" || templateElement.type == "table" || templateElement.type == "box") {
                element.isHidden = templateElement.isHidden;

                if (templateElement.type == "page" || templateElement.type == "row") {
                    element.templateElementId = templateElement.templateElementId;
                }
                else if (templateElement.type == "inputtext" || templateElement.type == "selectlist" || templateElement.type == "inputicon" || templateElement.type == "inputimage") {
                    element.name = templateElement.name;
                    if (templateElement.type == "inputtext" || templateElement.type == "selectlist") {
                        element.values = templateElement.values;
                    }
                    else if (templateElement.type == "inputicon") {
                        element.value = templateElement.value;
                    }
                    else if (templateElement.type == "inputimage") {
                        element.value = templateElement.value;
                        element.size = templateElement.size;
                    }
                } else if (templateElement.type == "text" || templateElement.type == "icon" || templateElement.type == "image" || templateElement.type == "table" || templateElement.type == "box") {
                    element.name = templateElement.name;
                }

                elements.push(element);
            }
        }

        var dynamicElements = _.filter(datasheetElements, {
            isDynamic: true
        });
        for (var de in dynamicElements) {
            var dynamicElement = dynamicElements[de];

            elements.push(dynamicElement);
        }

        return elements;
    }
    //Inner Functions End

    return factoryModel;
};

module.exports = function (app) {
    app.factory('datasheetFactory', ['$q', '$translate', '$modal', 'httpProvider', 'generalsService', datasheetFactory]);
};
