var run = function($rootScope, $urlRouter, $state, $modal, subscriptionFactory) {
  var bypass = false;
$rootScope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams){

        if(toState.name === "app.newDatasheet" ){

          if(!bypass){
            event.preventDefault();
            subscriptionFactory.canCreateDatasheet().then(function(response){
              if(!response.status){

                if(fromState.name == "")
                  $state.go('app.dashboard', { lang : $rootScope.activeLang});


                  var modalInstance = $modal.open({
                      animation: true,
                      controller: 'subscriptionWarningModalCtrl',
                      templateUrl: '/app/components/subscription/shared/views/modals/subscriptionWarningModal.html',
                      resolve: {
                          model: function() {
                              return {
                                text : "SUBSCRIPTION.SHARED.MODALS.SUBSCRIPTION_WARNING.MESSAGE_DATASHEET"
                              };
                          }
                      }
                  });
              }else{
                bypass = true;
                $state.go('app.newDatasheet', {lang : $rootScope.activeLang});
              }

            });
          }else
            bypass = false;
        }
});



};

module.exports = function(app) {
    app.run(['$rootScope', '$urlRouter', '$state', '$modal', 'subscriptionFactory', run]);
};
