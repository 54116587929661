var deleteUserCtrl = function($scope, $timeout, $translate, $modalInstance, userFactory, userId) {
    //Fields
    $scope.deleteButton = $translate.instant("USER.ALLUSERS.MODALS.DELETEUSER.BTN_DELETE.TEXT");
    $scope.isProcessing = false;
    //Fields End

    //Functions
    $scope.cancel = function() {
        $modalInstance.dismiss('cancel');
    };

    $scope.delete = function() {
        $scope.deleteButton = $translate.instant("USER.ALLUSERS.MODALS.DELETEUSER.BTN_DELETE.ALTERNATE_TEXT");
        $scope.isProcessing = true;

        userFactory.deleteUser(userId)
            .then(function(response) {
                $scope.deleteButton = $translate.instant("USER.ALLUSERS.MODALS.DELETEUSER.BTN_DELETE.SUCCESS");

                $timeout(function() {
                    var message = $translate.instant("USER.ALLUSERS.MODALS.DELETEUSER.LBL_DELETED.TEXT");
                    toastr.success(message, '', { positionClass: 'toast-bottom-full-width', timeOut: 3000 });
                    $modalInstance.close({
                        status: true
                    });
                }, 500);
            });
    };
    //Functions End

    //Initials
    //Initials End
};

module.exports = function(app) {
    app.controller('deleteUserCtrl', ['$scope', '$timeout', '$translate', '$modalInstance', 'userFactory', 'userId', deleteUserCtrl]);
};
