var grantPermissionCtrl = function($scope, $filter, $timeout, $modalInstance, permissionFactory, viewScope) {
    //Fields
    $scope.selectedGroupsOrUsers = [];

    $scope.okButton = "PERMISSION.MODALS.GRANTPERMISSION.BTN_OK.TEXT";
    $scope.isProcessing = false;
    //Fields End

    //Functions
    $scope.cancel = function() {
        $modalInstance.dismiss('cancel');
    };

    $scope.getGroupOrUserPickerSuggestion = function(searchString) {
        return permissionFactory.getAllGroupsAndUsers()
            .then(function(response) {
                var allGroupsAndUsers = response;

                for (var i = 0; i < allGroupsAndUsers.length; i++) {
                    var item = allGroupsAndUsers[i];
                    if (item.Name == "Administrators") {
                        allGroupsAndUsers.splice(i, 1);
                        break;
                    }
                }

                return allGroupsAndUsers.filter(function(groupOrUser) {
                    return groupOrUser.Name.toLowerCase().indexOf(searchString.toLowerCase()) != -1;
                });
            });
    };

    $scope.ok = function() {
        $scope.errors = [];

        //Hiçbir user veya group seç
        if ($scope.selectedGroupsOrUsers.length == 0) {
            $scope.errors.push("PERMISSION.MODALS.GRANTPERMISSION.ERROR_MESSAGES.SELECT_ONE_USER_OR_GROUP");
        }

        //PermissionLevel seçilmemişse,
        var selectedPermissionLevels = $filter('filter')($scope.permissionLevels, {
            IsSelected: true
        });
        if (selectedPermissionLevels.length == 0) {
            $scope.errors.push("PERMISSION.MODALS.GRANTPERMISSION.ERROR_MESSAGES.SELECT_ONE_PERMISSION_LEVEL");
        }

        if ($scope.errors.length > 0) {
            $timeout(function() {
                $scope.errors = [];
            }, 5000);
            return;
        }

        $scope.okButton = "PERMISSION.MODALS.GRANTPERMISSION.BTN_OK.ALTERNATE_TEXT";
        $scope.isProcessing = true;
        viewScope.grantPermissionModalOkClicked($scope.selectedGroupsOrUsers, selectedPermissionLevels, function(response) {
            $scope.okButton = "PERMISSION.MODALS.GRANTPERMISSION.BTN_OK.TEXT";
            $scope.isProcessing = false;
            if (response.status) {
                $modalInstance.close({
                    status: true
                });
            }
        });
    };

    $scope.closeAlert = function() {
        $scope.errors = [];
    };
    //Functions End

    //Inner Functions
    var getAllAccountPermissionLevels = function() {
        permissionFactory.getAllAccountPermissionLevels()
            .then(function(response) {
                $scope.permissionLevels = response;
            });
    };
    //Inner Functions Emd


    //Initials
    getAllAccountPermissionLevels();
    //Initials End
};

module.exports = function(app) {
    app.controller('grantPermissionCtrl', ['$scope', '$filter', '$timeout', '$modalInstance', 'permissionFactory', 'viewScope', grantPermissionCtrl]);
};
