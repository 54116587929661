var datasheetsMenuInSideMenuCtrl = function($scope, $timeout, $q, localStorageService, folderFactory, $state, $rootScope) {
    //Fields
    $scope.userPermissions = localStorageService.get('userPermissions');
    //Fields End

    //Functions
    $scope.isThereACustomPermissionForFolder = function() {
        $scope.tdsMakerPromise.global = {};

        var promise1 = folderFactory.getDatasheetFolders()
            .then(function(response) {
                var hasPermission = true;
                var datasheetFolders = response;

                return hasPermission;
            });


        $scope.tdsMakerPromise.global.promise = $q.when(promise1)
            .then(function() {
                var deferred = $q.defer();
                $timeout(function() {
                    deferred.resolve();
                }, 1000);
                return deferred.promise;
            });

        return promise1;
    };

    $scope.newDatasheet = function() {
        $state.go('app.newDatasheet', {
            lang: $rootScope.activeLang
        });

        return;
    };
    //Functions End

    //Initials
    //Initials End
};

module.exports = function(app) {
    app.controller('datasheetsMenuInSideMenuCtrl', ['$scope', '$timeout', '$q', 'localStorageService', 'folderFactory', '$state', '$rootScope', datasheetsMenuInSideMenuCtrl]);
};
