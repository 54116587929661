var previewTemplateCtrl = function ($scope, $stateParams, templateFactory) {
    //Fields 
    var templateId = $stateParams.id;
    var language = $stateParams.templateLang;

    $scope.pdfModel = {
        mode: 'template-preview'
    };
    $scope.selectionModel = {
        selectedLanguage: language
    };

    $scope.loading = {
        pdf: false,
        templatePreview: false,
    };
    //Fields End

    //Inner Functions
    var getTemplateById = function (templateId) {
        $scope.loading.pdf = false;
        templateFactory.getTemplate(templateId)
            .then(function (response) {
                var templateModel = response;
                $scope.pdfModel.templateModel = templateModel;
                $scope.pdfModel.elements = templateModel.elements;

                $(document).trigger("pdfModelLoaded", { pdfModel: $scope.pdfModel, selectionModel: $scope.selectionModel });
                $scope.loading.pdf = true;
            });
    }
    //Inner Functions End

    //Functions
    //Functions End

    //Events
    $(document).off("pdfGenerated").on('pdfGenerated', function (event, data) {
        setTimeout(function () {
            // $(window).load(function () {
            window.status = "printPage";

            //PhantomJS
            if (typeof window.callPhantom === 'function') {
                window.callPhantom({ printStatus: 'ready' });
            }
            // });
        }, 1000);
    });
    //Events End

    //Initials 
    getTemplateById(templateId);
    //Initials End
};

module.exports = function (app) {
    app.controller('previewTemplateCtrl', ['$scope', '$stateParams', 'templateFactory', previewTemplateCtrl]);
};