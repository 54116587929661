var newArchiveCtrl = function ($rootScope, $scope, $state, $timeout, $translate, $modal, menuToggleFactory, archiveFactory) {
    //Fields
    $scope.datasheetFields = [{
        text: $translate.instant("DATASHEET.SHARED.DATASHEETFIELDS.NO_MAPPING"),
        value: "noMapping"
    }, {
        text: $translate.instant("DATASHEET.SHARED.DATASHEETFIELDS.PRODUCT_NUMBER"),
        value: "productNumber"
    }, {
        text: $translate.instant("DATASHEET.SHARED.DATASHEETFIELDS.PRODUCT_NAME"),
        value: "productName"
    }];

    $scope.saveButton = "CUSTOMARCHIVES.NEWARCHIVE.BTN_SAVE.TEXT";
    $scope.isProcessing = false;

    $scope.archiveModel = {};
    $scope.step = 0;

    $scope.newFieldName = "";
    //Fields End

    //Events
    $scope.$on('$viewContentLoaded', function () {
        $timeout(function () {
            menuToggleFactory.collapse();
        }, 100);
    });
    //Events End

    //Functions
    $scope.openIconPickerModal = function () {
        var modalInstance = $modal.open({
            templateUrl: 'app/components/customArchives/shared/views/modals/iconPicker.html',
            controller: 'iconPickerCtrl',
            size: 'lg',
            resolve: {
                parentScope: function () {
                    return $scope;
                }
            }
        });

        modalInstance.result
            .then(function (result) {
                $scope.archiveModel.selectedIcon = result.selectedIcon;
            });
    };

    $scope.removeIcon = function () {
        $scope.archiveModel.selectedIcon = undefined;
    };

    $scope.goToFieldsStep = function (isValid) {
        $scope.errors = [];
        if ($scope.archiveModel.selectedIcon == null || $scope.archiveModel.selectedIcon == undefined) {
            $scope.errors.push('CUSTOMARCHIVES.NEWARCHIVE.ERROR_MESSAGES.SELECT_ICON');
        }

        if ($scope.errors.length > 0) {
            return;
        }

        if (isValid) {
            $scope.newField = {};
            $scope.step = 1;
        }
    };

    $scope.backToNameAndDescriptionStep = function () {
        $scope.errors = [];
        $scope.step = 0;
    };

    $scope.saveArchive = function () {
        $scope.saveButton = "CUSTOMARCHIVES.NEWARCHIVE.BTN_SAVE.ALTERNATE_TEXT";
        $scope.isProcessing = true;
        archiveFactory.saveArchive($scope.archiveModel)
            .then(function (response) {
                $scope.saveButton = "CUSTOMARCHIVES.NEWARCHIVE.BTN_SAVE.SUCCESS";
                var message = $translate.instant("CUSTOMARCHIVES.NEWARCHIVE.SUCCESS_MESSAGES.ADDED_SUCCESSFULLY")
                toastr.success(message, '', { positionClass: 'toast-bottom-full-width', timeOut: 3000 });
                $rootScope.$broadcast('addedNewArchiveScenario', {});
                $timeout(function () {
                    $state.go("app.allArchives");
                }, 1000);
            });
    };

    $scope.addNewField = function (fieldName) {
        $scope.errors = [];
        if ($scope.archiveModel.fields == null || $scope.archiveModel.fields == undefined) {
            $scope.archiveModel.fields = [];
        }

        if ($scope.selectedFields == null || $scope.selectedFields == undefined || $scope.selectedFields.length == 0) {
            $scope.errors.push("CUSTOMARCHIVES.NEWARCHIVE.ERROR_MESSAGES.SELECT_FIELD");
        }

        if (fieldName != null && fieldName != undefined && fieldName != '') {
            $scope.archiveModel.fields.push({
                name: fieldName,
                type: 'Textbox',
                isVisible: false
            });
            $scope.newField = {};
        }
    };

    $scope.checkForSelectedFieldsLimit = function() {
        $scope.selectedFields = _.filter($scope.archiveModel.fields, function(field){ return field.isVisible == true; });
    };

    $scope.removeField = function(index) {
        $scope.archiveModel.fields.splice(index, 1);
    };

    $scope.backToFieldsStep = function () {
        $scope.errors = [];
        $scope.step = 1;
    };

    $scope.goToMappingStep = function () {
        $scope.errors = [];
        if ($scope.archiveModel.fields == null || $scope.archiveModel.fields == undefined || $scope.archiveModel.fields.length == 0) {
            $scope.errors.push("CUSTOMARCHIVES.NEWARCHIVE.ERROR_MESSAGES.ADD_FIELD");
        }

        if ($scope.selectedFields == null || $scope.selectedFields == undefined || $scope.selectedFields.length == 0) {
            $scope.errors.push("CUSTOMARCHIVES.NEWARCHIVE.ERROR_MESSAGES.SELECT_FIELD");
        }

        if ($scope.errors.length > 0) {
            return;
        }

        for (var i = 0; i < $scope.archiveModel.fields.length; i++) {
            var field = $scope.archiveModel.fields[i];
            if (field.mapField == null || field.mapField == undefined) {
                field.mapField = "noMapping";
            }
        }
        $scope.step = 2;
    };
    //Functions End

    //Initials
    //Initials End
};

module.exports = function (app) {
    app.controller('newArchiveCtrl', ['$rootScope', '$scope', '$state', '$timeout', '$translate', '$modal', 'menuToggleFactory', 'archiveFactory', newArchiveCtrl]);
};
