var datasheetPaneCtrl = function ($scope, $attrs, $compile, _) {
    //Fields
    var order = parseInt($attrs.order);
    //Fields End

    //Inner Functions
    var setPane = function () {
        var pageId = $scope.page._id;

        $scope.pane = _.find($scope.templateModel.elements, {
            "parentId": pageId,
            "order": order
        });
    };
    //Inner Functions End

    //Initials
    setPane();
    //Initials End
};

module.exports = function (app) {
    app.controller('datasheetPaneCtrl', ['$scope', '$attrs', '$compile', '_', datasheetPaneCtrl]);
};