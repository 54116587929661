//Required Packages
require("./../../../../bower_components/angular/angular.js");

require('../core/');

//Module
var errorsModule = angular.module('tdsApp.errors', [
    'tdsApp.core'
]);

//Router
require("./routing.js")(errorsModule);

//Run
require("./run.js")(errorsModule);

//Factories & Services

//Directives

//Controllers
require("./browserUpgrade/controllers/browserUpgradeController.js")(errorsModule);
module.exports = errorsModule;
