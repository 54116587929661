var changePasswordCtrl = function($scope, $translate, $modalInstance, Complexify, userFactory) {
    //Fields
    $scope.changePasswordButton = "USER.MODALS.CHANGEPASSWORD.BTN_CHANGE_PASSWORD.TEXT";
    $scope.passwordModal = {};
    //Fields End

    //Functions
    $scope.cancel = function() {
        $modalInstance.dismiss();
    };

    $scope.changePassword = function(isValid) {

        $scope.errors = [];

        //Control
        var complexity = Complexify($scope.passwordModal.newPassword).complexity;

        if (complexity === 0) {
          $scope.errors.push('USER.MODALS.CHANGEPASSWORD.ERROR_MESSAGES.PASSWORD_STRENGTH_MORE_SECURE');
        } else if (complexity === 1) {
          $scope.errors.push('USER.MODALS.CHANGEPASSWORD.ERROR_MESSAGES.PASSWORDS_MATCH');
        }

        if ($scope.passwordModal.newPassword != $scope.passwordModal.newPasswordAgain) {
            $scope.errors.push('USER.MODALS.CHANGEPASSWORD.ERROR_MESSAGES.PASSWORDS_MATCH');
        }

        if ($scope.passwordModal.newPassword == undefined || $scope.passwordModal.newPassword.length < 8) {
            $scope.errors.push('USER.MODALS.CHANGEPASSWORD.ERROR_MESSAGES.PASSWORD_LENGTH');
        }

        if ($scope.errors.length > 0) {
            return;
        }


        if (isValid) {
            userFactory.changePassword($scope.passwordModal.oldPassword, $scope.passwordModal.newPassword)
                .then(function(response) {
                    if (response.status) {
                        var message = $translate.instant("USER.MODALS.CHANGEPASSWORD.SUCCESS_MESSAGES.CHANGED_SUCCESSFULLY");
                        toastr.success(message, '', { positionClass: 'toast-bottom-full-width', timeOut: 3000 });
                        $modalInstance.close(true);
                    } else {
                        $scope.errors.push(response.message);
                    }
                });
        }
    };

    $scope.getProgressBarType = function() {
        var type = "";
        var complexity = Complexify($scope.passwordModal.newPassword).complexity;

        if (complexity === 0) {
            $('.progress-bar').css('color','black');
        } else if (complexity >= 1 && complexity <= 25) {
            $('.progress-bar').css('color','white');
            type = "danger";
        } else if (complexity > 25 && complexity <= 75) {
            type = "warning";
        } else if (complexity > 75) {
            type = "success";
        }

        return type;
    };
    //Functions End

    //Initials
    //Initials End
};

module.exports = function(app) {
    app.controller('changePasswordCtrl', ['$scope', '$translate', '$modalInstance', 'Complexify', 'userFactory', changePasswordCtrl]);
};
