var preparingDatasheet = function ($translate) {
    return {
        restrict: 'E',
        replace: true,
        templateUrl: 'app/components/datasheet/shared/directives/preparingDatasheet/preparingDatasheet.html',
        link: function (scope, elem, attrs) {
            //Elements
            var txtMessage = elem.find('#txtMessage');
            //Elements End

            //Fields 
            //Fields End

            //Inner Functions
            var init = function () {
                var message = $translate.instant('DATASHEET.EDIT_DATASHEET.LBL_PREPARING_DATASHEET.TEXT');

                txtMessage.html(message);
            };
            //Inner Functions End

            //Initials 
            init();
            //Initials End

        }
    }
};

module.exports = function (app) {
    app.directive('preparingDatasheet', ['$translate', preparingDatasheet]);
};