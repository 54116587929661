var uiDraggable = function(){
  return {
    restrict: 'A',
    link : function(scope, elm, attrs){
      
      var options = scope.$eval(attrs.draggableOptions);
      $(elm).draggable(options);
    }
  }
};

module.exports = function(app){
  app.directive('uiDraggable', uiDraggable);
};
