var viewDatasheetPdfCtrl = function($rootScope, $scope, $state, $filter, $modalInstance, SweetAlert, config, datasheetFactory, languageFactory, checkPermissionFactory, datasheet, version) {
    //Fields
    $scope.model = {};
    $scope.majorList = [];

    //Fields End

    //Inner Functions
    var selectedDatasheetChanged = function() {
        $scope.languageList = [];
        var datasheet = $scope.selectionModel.datasheet;
        for (var i in datasheet.pdfFiles) {
            var currentFile = datasheet.pdfFiles[i];
            var language = currentFile.language;
            $scope.languageList.push(language);
        }

        $scope.selectedLanguage = $scope.languageList[0];
    };
    //Inner Functions End

    //Functions
    $scope.cancel = function() {
        $modalInstance.dismiss('cancel');
    };


    $scope.ok = function() {
        var datasheet = $scope.selectionModel ? $scope.selectionModel.datasheet : {};
        var url = config.app[config.mode].appUrl + "/" + $rootScope.activeLang + "/pdf/view/" + datasheet._id + "/" + $scope.selectedLanguage;
        window.open(url, '_blank');
        $modalInstance.dismiss('cancel');
    };

    //Functions End

    //Initials
    checkPermissionFactory.canSelectDatasheetVersion(datasheet._id)
        .then(function(canSelectDatasheetVersion) {
            $scope.canSelectDatasheetVersion = canSelectDatasheetVersion;

            datasheetFactory.getPublishedDatasheetForViewPdf(datasheet._id).then(function(response) {
                $scope.datasheets = _.sortBy(response, 'majorVersion');
                $scope.datasheets = $scope.datasheets.reverse();
                $scope.selectionModel = { datasheet : $scope.datasheets[0]};
                selectedDatasheetChanged();
            });
        });
    //Initials End
};
module.exports = function(app) {
    app.controller('viewDatasheetPdfCtrl', ['$rootScope', '$scope', '$state', '$filter', '$modalInstance', 'SweetAlert', 'config', 'datasheetFactory', 'languageFactory', 'checkPermissionFactory', 'datasheet', 'version', viewDatasheetPdfCtrl]);
};
