var ftpChannelModalCtrl = function($scope, $translate, $modalInstance, backupFactory){

  //Fields
  var emptyField = $translate.instant('BACKUP.BACKUP_OPTIONS.MODALS.FTP_CHANNEL.LBL_FILL_ALL.TEXT');
  var connecting = $translate.instant('BACKUP.BACKUP_OPTIONS.MODALS.FTP_CHANNEL.BTN_OK.CONNECTING');
  var saveButton = $translate.instant('BACKUP.BACKUP_OPTIONS.MODALS.FTP_CHANNEL.BTN_OK.TEXT');
  var errWrongUser = $translate.instant('BACKUP.BACKUP_OPTIONS.MODALS.FTP_CHANNEL.LBL_WRONG_USER.TEXT');
  var errServer = $translate.instant('BACKUP.BACKUP_OPTIONS.MODALS.FTP_CHANNEL.LBL_WRONG_SERVER.TEXT');


  $scope.saveButtonText = saveButton;
  $scope.ftpModel = {
    port : 21,
    syncNow : true,
    name : "",
    server : "",
    userName : "",
    path : "/",
    password : ""
  }
  //Fields - End

  //Private Functions
  var checkFields = function(){
    $scope.errorText = "";

    if(angular.isUndefined($scope.ftpModel.name) || $scope.ftpModel.name.length == 0)
      $scope.errorText = emptyField;

    if(angular.isUndefined($scope.ftpModel.server) || $scope.ftpModel.server.length == 0)
      $scope.errorText = emptyField;

    if(angular.isUndefined($scope.ftpModel.port) || $scope.ftpModel.port.length == 0)
      $scope.errorText = emptyField;

    if(angular.isUndefined($scope.ftpModel.userName) || $scope.ftpModel.userName.length == 0)
      $scope.errorText = emptyField;

    if(angular.isUndefined($scope.ftpModel.password) || $scope.ftpModel.password.length == 0)
      $scope.errorText = emptyField;

     return $scope.errorText.length == 0 ? true:false;
  };

  var saveModel = function(){
    if(checkFields()){
      $scope.errorText = "";
      $scope.saveButtonText = connecting;
      backupFactory.checkFtpSettings($scope.ftpModel).then(function(response){
        $scope.saveButtonText = saveButton;

        if(response.connected){
          var model = {
            name : $scope.ftpModel.name,
            type : "ftp",
            isActive : true,
            options : {
              host : $scope.ftpModel.server,
              port : $scope.ftpModel.port,
              user : $scope.ftpModel.userName,
              pass : $scope.ftpModel.password,
              remote : $scope.ftpModel.path
            }
          };

          backupFactory.createNewChannel({syncNow : $scope.ftpModel.syncNow, model : model}).then(function(res){
            $modalInstance.close();
          });
        }else{
          if(angular.isDefined(response.message) && response.message.length > 0)
            $scope.errorText = errServer;
          else
            $scope.errorText = errWrongUser;
        }

      });
    }
  };
  //Private Functions - End

  //Functions
  $scope.cancel = function() {
      $modalInstance.dismiss('cancel');
  };

  $scope.save = function(){
    saveModel();

  };
  //Functions - End
};

module.exports = function(app){
  app.controller('ftpChannelModalCtrl', ['$scope', '$translate', '$modalInstance', 'backupFactory', ftpChannelModalCtrl]);
};
