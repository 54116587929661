var mailSettingsCtrl = function ($rootScope, $scope, $state, $translate, accountSettingsFactory) {
    //Fields
    $scope.mailSettings = {};
    $scope.showTestError = false;
    $scope.testMailSuccess = false;
    $scope.isTesting = false;
    $scope.alreadySaved = false;
    //Fields End

    //Events
    //Events End

    //Functions
    $scope.sendTestMail = function () {
        $scope.isTesting = true;
        $('#btnSendTestMail').removeClass('fa-paper-plane').addClass('fa-spinner fa-pulse fa-fw');
        accountSettingsFactory.sendTestMail($scope.mailSettings).then(function (response) {
            if (response.responseCode != null && response.responseCode == "250") {
                $scope.testMailSuccess = true;
                $scope.showTestError = false;
                $scope.testSuccessMessage = response;
            } else if (_.isEmpty(response)) {
                $scope.testMailSuccess = false;
                $scope.showTestError = true;
                $scope.testErrorMessage = $translate.instant('SETTINGS.PARTIALS.ACCOUNT_SETTINGS.MAIL_SETTINGS.LBL_VERIFY_SETTINGS.TEST_UKNOWN_ERROR_MESSAGE');
            } else {
                $scope.testMailSuccess = false;
                $scope.showTestError = true;
                $scope.testErrorMessage = response;
            }
            $scope.isTesting = false;
            $('#btnSendTestMail').removeClass('fa-spinner fa-pulse fa-fw').addClass('fa-paper-plane');
        });
    }

    $scope.saveMailSettings = function () {
        accountSettingsFactory.saveMailSettings($scope.mailSettings).then(function (response) {
            if (response.status) {
                $scope.alreadySaved = true;
                var message = $translate.instant('SETTINGS.PARTIALS.ACCOUNT_SETTINGS.MAIL_SETTINGS.LBL_VERIFY_SETTINGS.LBL_SAVED');;
                toastr.success(message, '', { positionClass: 'toast-bottom-full-width', timeOut: 3000 });
                $state.go("app.mailSettings", {
                    lang: $rootScope.activeLang
                });

            }
        });
    }
    //Functions End

    //Private Functions
    var getMailSettings = function () {
        accountSettingsFactory.getMailSettings().then(function (response) {
            $scope.mailSettings = response.smtpSettings;
        });
    }
    //Private Functions End

    //Initials
    getMailSettings();
    //Initials End
};

module.exports = function (app) {
    app.controller('mailSettingsCtrl', ['$rootScope', '$scope', '$state', '$translate', 'accountSettingsFactory', mailSettingsCtrl]);
};
