var datasheetTableCell = function ($compile, datasheetViewService, _) {
    return {
        restrict: 'A',
        link: function (scope, elem, attrs) {
            //Fields 
            //Fields End

            //Elements
            //Elements End

            //Inner Functions
            var init = function () {
                setElementStyle();
                setCellElements();
            };

            var setElementStyle = function () {
                elem.css(scope.cell.style);
                elem.attr("colspan", scope.cell.colSpan);
                elem.attr("rowspan", scope.cell.rowSpan);
            };

            var setCellElements = function () {
                var cellId = scope.cell._id;
                var elements = _.filter(scope.datasheetModel.elements, {
                    parentId: cellId
                });
                
                elements = _.sortBy(elements, 'order');

                for (var i = 0, len = elements.length; i < len; i++) {
                    var element = elements[i];
                    var elementHtml = datasheetViewService.createDatasheetElement(element.type);

                    var childScope = scope.$new();
                    childScope.element = element;
                    $(elem).append($compile(elementHtml)(childScope));
                }
            };
            //Inner Functions End

            //Initials 
            init();
            //Initials End
        }
    }
};

module.exports = function (app) {
    app.directive('datasheetTableCell', ['$compile', 'datasheetViewService', '_', datasheetTableCell]);
}