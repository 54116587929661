var backupFactory = function(httpProvider){
  var factoryModel = {};

  factoryModel.checkFtpSettings = function(model){
    return httpProvider.post('backup', 'checkFtpSettings', model);
  };

  factoryModel.createNewChannel = function(model){
    return httpProvider.post('backup', 'createNewChannel', model);
  };

  factoryModel.getChannels = function(){
    return httpProvider.get('backup', 'getChannels');
  };

  return factoryModel;
};

module.exports = function(app){
  app.factory('backupFactory', ['httpProvider', backupFactory]);
};
