var deleteArchiveCtrl = function($scope, $timeout, $translate, $modalInstance, archiveFactory, archiveId) {
    //Fields
    $scope.deleteButton = "CUSTOMARCHIVES.ALLARCHIVES.MODALS.DELETEARCHIVE.BTN_DELETE.TEXT";
    $scope.isProcessing = false;
    //Fields End

    //Functions
    $scope.cancel = function() {
        $modalInstance.dismiss();
    };

    $scope.delete = function() {
        $scope.deleteButton = "CUSTOMARCHIVES.ALLARCHIVES.MODALS.DELETEARCHIVE.BTN_DELETE.ALTERNATE_TEXT";
        $scope.isProcessing = true;

        archiveFactory.deleteArchive(archiveId)
            .then(function(response) {
                $scope.deleteButton = "CUSTOMARCHIVES.ALLARCHIVES.MODALS.DELETEARCHIVE.BTN_DELETE.SUCCESS";

                $timeout(function() {
                    $modalInstance.close(true)
                }, 250);
            });
    };
    //Functions End

    //Initials
    //Initials End
};

module.exports = function(app) {
    app.controller('deleteArchiveCtrl', ['$scope', '$timeout', '$translate', '$modalInstance', 'archiveFactory', 'archiveId', deleteArchiveCtrl]);
};
