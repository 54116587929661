var httpInterceptor = function(
  $q,
  localStorageService,
  $injector,
  $rootScope,
  config,
  environment
) {
  var privateApiUrl = config.api[config.mode].apiUrl
  var publicApiUrl =
    environment.PUBLIC_API_URL || config.public_api[config.mode].url

  return {
    request: function(config) {
      var authorizationData = localStorageService.get('authorizationData')
      var authData = localStorageService.get('authData')

      var newApiCheck = config.url.indexOf(publicApiUrl)
      var privateApiCheck = config.url.indexOf(privateApiUrl)

      if (newApiCheck >= 0) {
        config.headers.Authorization = 'Bearer ' + authData
        config.headers.oldtoken = authorizationData.token
      } else if (
        authorizationData &&
        config.url != 'http://ipv4.myexternalip.com/json' &&
        config.url.indexOf('http://ip-api.com/') < 0 &&
        config.url.indexOf('http://freegeoip.net/') < 0 &&
        privateApiCheck >= 0
      ) {
        config.headers.Authorization = 'Bearer ' + authorizationData.token
      }
      return config
    },
    responseError: function(rejection) {
      if (rejection.status == 401) {
        var authFactory = $injector.get('authFactory')
        //TODO: Daha sonra açılacak.
        // authService.logOut();

        var url = rejection.config.url

        if (
          url != 'http://privateapi.tdsmaker.net/api/v2/token' &&
          url != 'http://privateapi.alphawireless.com/api/v2/token' &&
          url != 'https://api.tdsmaker.net/api/v2/token' &&
          url != 'https://api.alphawireless.com/api/v2/token' &&
          url != 'http://localhost:8000/api/v2/token'
        ) {
          window.location = '/' + $rootScope.activeLang + '/auth/sign-in'
        }
      } else if (rejection.status == 408) {
        console.log('aha')
        localStorageService.remove('authData')
        location.reload()
      }
      return $q.reject(rejection)
    },
    requestError: function(rejection) {
      return $q.reject(rejection)
    },
  }
}

module.exports = function(app) {
  app.factory('httpInterceptor', [
    '$q',
    'localStorageService',
    '$injector',
    '$rootScope',
    'config',
    'environment',
    httpInterceptor,
  ])
}
