var changePlanCtrl = function ($rootScope, $scope, $state, $timeout, $translate, subscriptionFactory, menuToggleFactory, _) {
    //Fields 
    $scope.subscriptionModels = {};
    $scope.currentPlan = {};

    var txtUpgradePlan = '<i class="fa fa-arrow-circle-up" aria-hidden="true"></i>&nbsp;' + $translate.instant('SUBSCRIPTION.CHANGE_PLAN.BTN_UPGRADE.TEXT');
    var txtCurrentPlan = '<i class="fa fa-check-circle" aria-hidden="true"></i>&nbsp;' + $translate.instant('SUBSCRIPTION.CHANGE_PLAN.BTN_CURRENT_PLAN.TEXT');
    // var downgradePlan = '<i class="fa fa-arrow-circle-down" aria-hidden="true"></i>&nbsp;' + $translate.instant('SUBSCRIPTION.CHANGE_PLAN.BTN_DOWNGRADE.TEXT');
    //Fields End

    //Events
    $scope.$on('$viewContentLoaded', function () {
        $timeout(function () {
            menuToggleFactory.collapse();
        }, 100);
    });
    //Events End

    //Inner Functions
    var getSubscriptionModels = function () {
        subscriptionFactory.getSubscriptonModels()
            .then(function (response) {
                console.log("response", response);
                $scope.subscriptionModels = response;
            });
    };

    var getAccountSubscription = function (nextProcess) {
        subscriptionFactory.getAccountSubscription()
            .then(function (response) {
                $scope.currentPlan = response;

                nextProcess();
            });
    };

    var getSubscriptionModel = function (packageName) {
        if (angular.isUndefined($scope.subscriptionModels)) return;


        var relatedModel = _.filter($scope.subscriptionModels, {
            name: packageName
        })[0];

        return relatedModel;
    };
    //Inner Functions End

    //Functions 
    $scope.buyButtonText = function (packageName, level) {
        if (angular.isUndefined($scope.currentPlan)) return "";

        if (packageName === $scope.currentPlan.package.name)
            return txtCurrentPlan;
        else
            return txtUpgradePlan;
    };

    $scope.buyNow = function (packageName, level) {
        if (packageName === $scope.currentPlan.package.name)
            return;

        var subsModel = getSubscriptionModel(packageName);

        var fakeModel = {
            package: subsModel,
            month: 12
        };

        if (subsModel.level > $scope.currentPlan.package.level) {
            fakeModel.action = "upgrade";
        } else {
            fakeModel.action = "downgrade";
        }

        $state.go('app.newPlan', {
            lang: $rootScope.activeLang,
            selectedPlan: fakeModel
        });
    }
    //Functions End


    //Initialize
    getSubscriptionModels();
    getAccountSubscription(function () {
        var cancelProcess = $scope.currentPlan.isCancelled != undefined && $scope.currentPlan.isCancelled != null;
        if (cancelProcess) {
            $state.go('app.accountSubscription', {
                lang: $rootScope.activeLang
            }, {
                    reload: true
                });
        }


    });
    //Initialize - End

    //ForTesting
    $scope._txtUpgradePlan = txtUpgradePlan;
    $scope._txtCurrentPlan = txtCurrentPlan;

    $scope._getSubscriptionModels = getSubscriptionModels;
    $scope._getAccountSubscription = getAccountSubscription;
    //ForTesting End
};

module.exports = function (app) {
    app.controller('changePlanCtrl', ['$rootScope', '$scope', '$state', '$timeout', '$translate', 'subscriptionFactory', 'menuToggleFactory', '_', changePlanCtrl]);
};