var pdfText = function (pdfElementService, pdfViewService) {
    return {
        restrict: "E",
        replace: true,
        templateUrl: 'app/components/pdf/shared/directives/elements/pdfText/pdfText.html',
        link: function (scope, elem, attrs) {
            //Fields
            var containerElement = elem;
            var innerElement = containerElement.find('.inner-element');
            //Fields End

            //Elements
            //Elements End

            //Inner Functions
            var setElementStyle = function () {
                containerElement.css(scope.element.containerStyle);

                var newStyle = pdfViewService.arrangeElementBorder(scope.element.style);
                innerElement.css(newStyle);
            };

            var setElementValue = function () {
                var innerElementHtml = pdfElementService.getTextValue(scope.pdfModel, scope.page, scope.element, scope.selectionModel.selectedLanguage);
                innerElement.html(innerElementHtml);
            };
            //Inner Functions End

            //Handlers
            //Handlers End

            //Events            
            //Events End

            //Initials            
            setElementStyle();
            setElementValue();
            //Initials End
        }
    }
};

module.exports = function (app) {
    app.directive('pdfText', ['pdfElementService', 'pdfViewService', pdfText]);
};