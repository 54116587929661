var datasheetHeader = function ($compile, pdfViewService) {
    return {
        restrict: 'E',
        replace: true,
        templateUrl: 'app/components/pdf/shared/directives/pdfHeader/pdfHeader.html',
        link: function (scope, elem, attrs) {
            //Fields 
            //Fields End

            //Inner Functions
            var setHeaderElements = function () {
                var headerId = scope.header._id;
                var elements = _.filter(scope.pdfModel.elements, {
                    "parentId": headerId
                });

                elements = _.sortBy(elements, 'order');
                for (var i = 0, len = elements.length; i < len; i++) {
                    var element = elements[i];
                    var elementHtml = pdfViewService.createPdfElement(element.type);

                    var childScope = scope.$new();
                    childScope.element = element;
                    $(elem).append($compile(elementHtml)(childScope));
                }
            };

            var setHeaderStyle = function () {
                elem.css(scope.header.style);
            };
            //Inner Functions End

            //Initials 
            setHeaderElements();
            setHeaderStyle();
            //Initials End





        }
    };
};

module.exports = function (app) {
    app.directive('pdfHeader', ['$compile', 'pdfViewService', datasheetHeader]);
};