var accountsCtrl = function($scope, $translate, adminFactory, localStorageService) {
    //Fields
    $scope.accounts = {};
    $scope.accounts.loaded = false;
    $scope.checkObjectLength = function(object) {
        if(object !== undefined)
            return Object.keys(object).length;
    };
    //Fields-End

    //Functions
    $scope.getAccountDetails = function(account) {
        adminFactory.getAccountDetails(account._id)
            .then(function(response) {
                var index = $scope.accounts.indexOf(account)
                $scope.accounts[index].details = response;
            });
    };

    $scope.refreshAccountSubscriptions = function(accountId, subscriptions) {
        var account = _.find($scope.accounts, function(account){ return account._id == accountId; });
        account.details.subscriptions = subscriptions;
    };

    $scope.switchToAccount = function(account) {
        var user = localStorageService.get('userInformationData');

        adminFactory.switchToAccount(user._id, account._id)
            .then(function(response) {
               if(response) {
                    user.AccountId._id = account._id;
                    user.AccountId.Name = account.Name;
                    localStorageService.set('userInformationData', user);
                    var authData = {
                        "token": response.token,
                        "_id": response._id
                    }
                    localStorageService.set('authorizationData', authData);

                    var message = $translate.instant("ADMIN.PARTIALS.ACCOUNTS.BTN_ACCOUNT_SWITCH.SUCCESS_MESSAGE", { name: account.Name});
                    toastr.success(message, '', { positionClass: 'toast-bottom-full-width', timeOut: 3000 });


                    /*var activeAccountFooterTemplate = "<span id='adminActiveAccount'>" + $translate.instant('ADMIN.PARTIALS.ACCOUNT_NAME_IN_FOOTER.CURRENT_ACCOUNT.TEXT') + " " + account.Name + "</span>";
                    $('#adminActiveAccount').remove();
                    $('footer.footer').append(activeAccountFooterTemplate);*/

                    localStorageService.remove('authData');
                    setTimeout(location.reload.bind(location), 2000);
                }
            });
    };
    //Functions End

    //Inner Functions
    var getAllAccounts = function() {
        $scope.tdsMakerPromise.allAccounts = {};
        $scope.tdsMakerPromise.allAccounts.message = "Loading All Accounts";
        $scope.tdsMakerPromise.allAccounts.promise = adminFactory.getAllAccounts()
            .then(function(response) {
                $scope.accounts = response;
                $scope.accounts.loaded = true;
            });
    };
    //Inner Functions End

    //Initials
    getAllAccounts();
    //Initials-End
};

module.exports = function(app) {
    app.controller('accountsCtrl', ['$scope', '$translate', 'adminFactory', 'localStorageService', accountsCtrl]);
};
