var userFactory = function ($rootScope, $q, $modal, httpProvider, localStorageService, subscriptionFactory) {
    //Fields
    var factoryModel = {};
    //Fields - End

    //Function

    factoryModel.canUserBeLoaded = function () {
        return httpProvider.get('user', 'canUserBeGotten');
    };

    var isLoadUserInformationRunFirst = true;
    factoryModel.loadUserInformation = function () {
        var authData = localStorageService.get('authorizationData');
        var deferred = $q.defer();

        if (isLoadUserInformationRunFirst) {
            if (authData) {
                isLoadUserInformationRunFirst = false;
                factoryModel.getUserByUserId(authData._id)
                    .then(function (response) {
                        if (response == null || response == undefined) {
                            deferred.reject();
                        } else {

                            var user = response;

                            localStorageService.set('userInformationData', user);
                            $rootScope.$broadcast("loadedUserInformation", {
                                user: user
                            });

                            //HASH

                            user.hash = userHash(response._id);
                            deferred.resolve(user);
                        }
                    });
            }
            else {
                deferred.reject();
            }
        }
        else {
            var userInformation = localStorageService.get('userInformationData');

            userInformation.hash = userHash(userInformation._id);
            deferred.resolve(userInformation);
        }

        return deferred.promise;
    };

    var isLoadUserPermissionRunFirst = true;
    factoryModel.loadUserPermissions = function () {
        var authData = localStorageService.get('authorizationData');
        var deferred = $q.defer();

        if (isLoadUserPermissionRunFirst) {
            if (authData) {
                isLoadUserPermissionRunFirst = false;

                factoryModel.getUserPermissions()
                    .then(function (responsePermission) {
                        localStorageService.set('userPermissions', responsePermission.UserPermissions);

                        deferred.resolve({
                            status: true
                        });
                    });
            }
        }

        return deferred.promise;
    };

    factoryModel.getAllUsers = function () {
        return httpProvider.get('user', 'getAllUsers');
    };

    factoryModel.getAllUserForPicker = function () {
        return httpProvider.get('user', 'getAllUserForPicker');
    };

    factoryModel.getUserGroupsByUserId = function (userId) {
        var params = "userId=" + userId;
        return httpProvider.get('user', 'getUserGroupsByUserId', params);
    };

    factoryModel.makeDisable = function (userId) {
        var params = "userId=" + userId;
        return httpProvider.get('user', 'makeDisable', params);
    };

    factoryModel.openMakeEnableModal = function (userId) {
        var deferred = $q.defer();

        subscriptionFactory.canEnableUser()
            .then(function (response) {
                if (response.status) {
                    var modalInstance = $modal.open({
                        animation: false,
                        controller: 'makeEnableCtrl',
                        templateUrl: 'app/components/users/allUsers/views/modals/makeEnable.html',
                        backdrop: 'static',
                        resolve: {
                            userId: function () {
                                return userId;
                            }
                        }
                    });

                    modalInstance.result
                        .then(function (result) {
                            deferred.resolve(result);
                            // if (result.status) {
                            //
                            //     getAllUsers();
                            // }
                        });
                } else {
                    deferred.reject();

                    var modalInstance = $modal.open({
                        animation: true,
                        controller: 'subscriptionWarningModalCtrl',
                        templateUrl: '/app/components/subscription/shared/views/modals/subscriptionWarningModal.html',
                        resolve: {
                            model: function () {
                                return {
                                    text: "SUBSCRIPTION.SHARED.MODALS.SUBSCRIPTION_WARNING.MESSAGE_ENABLE_USER"
                                };
                            }
                        }
                    });

                }
            });

        return deferred.promise;
    };

    factoryModel.makeEnable = function (userId) {
        var params = "userId=" + userId;
        return httpProvider.get('user', 'makeEnable', params);
    };

    factoryModel.deleteUser = function (userId) {
        var params = "userId=" + userId;
        return httpProvider.get('user', 'deleteUser', params);
    };

    factoryModel.remindUser = function (userId) {
        var params = "userId=" + userId;
        return httpProvider.get('user', 'remindUser', params);
    };

    factoryModel.getUserPermissions = function () {
        return httpProvider.get('user', 'getUserPermissions');
    };

    factoryModel.getUserItemPermissions = function (type) {
        var params = "type=" + type;
        return httpProvider.get('user', 'getUserItemPermissions', params);
    };

    factoryModel.changeLanguage = function (lang) {
        var requestModel = {};
        requestModel.language = lang;
        return httpProvider.put('user', 'changeLanguage', requestModel);
    };

    factoryModel.getUserByUserId = function (userId) {
        var params = "userId=" + userId;
        return httpProvider.get('user', 'getUserByUserId', params);
    };

    factoryModel.updateUserProfile = function (user) {
        return httpProvider.put('user', 'updateUserProfile', user);
    };

    factoryModel.openInviteUserModal = function () {
        var deferred = $q.defer();

        subscriptionFactory.canInviteUser()
            .then(function (response) {
                if (response.status) {
                    var modalInstance = $modal.open({
                        animation: false,
                        controller: 'inviteUserCtrl',
                        templateUrl: 'app/components/users/allUsers/views/modals/inviteUser.html',
                        backdrop: 'static'
                    });

                    modalInstance.result
                        .then(function (response) {
                            deferred.resolve(response);
                        });
                } else {
                    deferred.reject();

                    var modalInstance = $modal.open({
                        animation: true,
                        controller: 'subscriptionWarningModalCtrl',
                        templateUrl: '/app/components/subscription/shared/views/modals/subscriptionWarningModal.html',
                        resolve: {
                            model: function () {
                                return {
                                    text: "SUBSCRIPTION.SHARED.MODALS.SUBSCRIPTION_WARNING.MESSAGE_INVITE_USER"
                                };
                            }
                        }
                    });

                }
            });

        return deferred.promise;
    };

    factoryModel.inviteUser = function (email, language) {
        var request = {};
        request.email = email;
        request.language = language;

        return httpProvider.post('user', 'invite', request);
    };

    factoryModel.confirmInvitedUser = function (emailId) {
        return httpProvider.getNonQuery('user', 'confirmInvitation', emailId);
    };

    factoryModel.hasLibraryExportAccess = function () {
        return httpProvider.get('user', 'hasLibraryExportAccess');
    };

    factoryModel.changePassword = function (oldPassword, newPassword) {
        var passwordModel = {
            oldPassword: oldPassword,
            newPassword: newPassword
        };
        return httpProvider.post('user', 'changePassword', passwordModel);
    };
    factoryModel.savePreference = function (preference, choice) {
        var params = "preference=" + preference + "&choice=" + choice;
        return httpProvider.get('user', 'savePreference', params);
    };
    //Functions End

    //Inner Functions 
    var userHash = function (userId) {
        var hash = CryptoJS.HmacSHA256(userId, "O7B-0Ex_OM-j-GINwCn7Ok9qIoTykVSntBm4PzRM");
        var base64 = hash.toString();

        return base64;
    };
    //Inner Functions End


    return factoryModel;
};

module.exports = function (app) {
    app.factory('userFactory', ['$rootScope', '$q', '$modal', 'httpProvider', 'localStorageService', 'subscriptionFactory', userFactory]);
};
