/*Bu dosya kullanılmıyormuş, modules/services altındaki kullanılıyormuş*/
var datasheetContextMenuService = function ($translate, datasheetElementService, _) {
    //Fields 
    var serviceModel = {};
    //Fields End

    //Functions
    serviceModel.createContextMenuItemsForPage = function (page) {
        return _createContextMenuItemsForPage(page);
    };

    serviceModel.createContextMenuItemsForTable = function (page, element) {
        var contextMenuItems = {};

        //Tablo ile ilgili özellikler
        angular.extend(contextMenuItems, _createContextMenuItemsForTable(element));
        contextMenuItems["pageSep"] = "---------";
        angular.extend(contextMenuItems, _createContextMenuItemsForPage(page));

        return contextMenuItems;
    };

    serviceModel.createContextMenuItemsForTableRow = function (page, table, element) {
        var contextMenuItems = {};

        angular.extend(contextMenuItems, _createContextMenuItemsForTableRow(element));
        contextMenuItems["tableSep"] = "---------";
        angular.extend(contextMenuItems, _createContextMenuItemsForTable(table));
        contextMenuItems["pageSep"] = "---------";
        angular.extend(contextMenuItems, _createContextMenuItemsForPage(page));

        return contextMenuItems;
    };

    serviceModel.createContextMenuItemsForText = function (page, table, row, cell, element) {
        var contextMenuItems = {};

        //Element ile ilgili context menu
        if (element) {
            if (element.isHidden) {
                contextMenuItems['showElement'] = { name: $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETTEXT.CONTEXTMENU.LBL_SHOW.TEXT"), icon: "fa-eye" }
            }
        }
        if (element) {
            if (!element.isHidden) {
                contextMenuItems['hideElement'] = { name: $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETTEXT.CONTEXTMENU.LBL_HIDE.TEXT"), icon: "fa-eye-slash" }
            }
        }

        if (row) {
            contextMenuItems["rowSep"] = "---------";
            angular.extend(contextMenuItems, _createContextMenuItemsForTableRow(row));
        }

        if (table) {
            contextMenuItems["tableSep"] = "---------";
            angular.extend(contextMenuItems, _createContextMenuItemsForTable(table));
        }

        if (page) {
            contextMenuItems["pageSep"] = "---------";
            angular.extend(contextMenuItems, serviceModel.createContextMenuItemsForPage(page));
        }

        return contextMenuItems;
    };

    serviceModel.createContextMenuItemsForInputText = function (page, table, row, cell, element, languageCount) {
        var contextMenuItems = {};

        //Element ile ilgili context menu
        if (element) {
            if (languageCount > 1) {
                contextMenuItems['translateElement'] = { name: $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETINPUTTEXT.CONTEXTMENU.LBL_TRANSLATE.TEXT"), icon: "fa-globe" }

                contextMenuItems['copyElement'] = { name: $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETINPUTTEXT.CONTEXTMENU.LBL_COPY.TEXT"), icon: "fa-clone" }
            }

            if (element.isHidden) {

                contextMenuItems['showElement'] = { name: $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETINPUTTEXT.CONTEXTMENU.LBL_SHOW.TEXT"), icon: "fa-eye" }
            }

            if (!element.isHidden) {
                contextMenuItems['hideElement'] = { name: $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETINPUTTEXT.CONTEXTMENU.LBL_HIDE.TEXT"), icon: "fa-eye-slash" }
            }
        }


        if (row) {
            contextMenuItems["rowSep"] = "---------";
            angular.extend(contextMenuItems, _createContextMenuItemsForTableRow(row));
        }

        if (table) {
            contextMenuItems["tableSep"] = "---------";
            angular.extend(contextMenuItems, _createContextMenuItemsForTable(table));
        }

        if (page) {
            contextMenuItems["pageSep"] = "---------";
            angular.extend(contextMenuItems, serviceModel.createContextMenuItemsForPage(page));
        }

        return contextMenuItems;
    };

    serviceModel.createContextMenuItemsForSelectList = function (page, table, row, cell, element) {
        var contextMenuItems = {};

        //Element ile ilgili context menu
        if (element) {
            if (!element.isDynamic) {
                contextMenuItems['addItemToElement'] = { name: $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETSELECTLIST.CONTEXTMENU.LBL_ADD_ITEM.TEXT"), icon: "fa-plus" }
            }

            if (element.isHidden) {
                contextMenuItems['showElement'] = { name: $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETSELECTLIST.CONTEXTMENU.LBL_SHOW.TEXT"), icon: "fa-eye" }
            }

            if (!element.isHidden) {
                contextMenuItems['hideElement'] = { name: $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETSELECTLIST.CONTEXTMENU.LBL_HIDE.TEXT"), icon: "fa-eye-slash" }
            }
        }


        if (row) {
            contextMenuItems["rowSep"] = "---------";
            angular.extend(contextMenuItems, _createContextMenuItemsForTableRow(row));
        }

        if (table) {
            contextMenuItems["tableSep"] = "---------";
            angular.extend(contextMenuItems, _createContextMenuItemsForTable(table));
        }

        if (page) {
            contextMenuItems["pageSep"] = "---------";
            angular.extend(contextMenuItems, serviceModel.createContextMenuItemsForPage(page));
        }

        return contextMenuItems;
    };

    serviceModel.createContextMenuItemsForImage = function (page, table, row, cell, element) {
        var contextMenuItems = {};

        //Element ile ilgili context menu
        if (element) {
            if (element.isHidden) {
                contextMenuItems['showElement'] = { name: $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETIMAGE.CONTEXTMENU.LBL_SHOW.TEXT"), icon: "fa-eye" }
            }

            if (!element.isHidden) {
                contextMenuItems['hideElement'] = { name: $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETIMAGE.CONTEXTMENU.LBL_HIDE.TEXT"), icon: "fa-eye-slash" }
            }
        }


        if (row) {
            contextMenuItems["rowSep"] = "---------";
            angular.extend(contextMenuItems, _createContextMenuItemsForTableRow(row));
        }

        if (table) {
            contextMenuItems["tableSep"] = "---------";
            angular.extend(contextMenuItems, _createContextMenuItemsForTable(table));
        }

        if (page) {
            contextMenuItems["pageSep"] = "---------";
            angular.extend(contextMenuItems, serviceModel.createContextMenuItemsForPage(page));
        }

        return contextMenuItems;
    };

    serviceModel.createContextMenuItemsForIcon = function (page, table, row, cell, element) {
        var contextMenuItems = {};

        //Element ile ilgili context menu
        if (element) {
            if (element.isHidden) {
                contextMenuItems['showElement'] = { name: $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETICON.CONTEXTMENU.LBL_SHOW.TEXT"), icon: "fa-eye" }
            }

            if (!element.isHidden) {
                contextMenuItems['hideElement'] = { name: $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETICON.CONTEXTMENU.LBL_HIDE.TEXT"), icon: "fa-eye-slash" }
            }
        }


        if (row) {
            contextMenuItems["rowSep"] = "---------";
            angular.extend(contextMenuItems, _createContextMenuItemsForTableRow(row));
        }

        if (table) {
            contextMenuItems["tableSep"] = "---------";
            angular.extend(contextMenuItems, _createContextMenuItemsForTable(table));
        }

        if (page) {
            contextMenuItems["pageSep"] = "---------";
            angular.extend(contextMenuItems, serviceModel.createContextMenuItemsForPage(page));
        }

        return contextMenuItems;
    };

    serviceModel.createContextMenuItemsForInputIcon = function (page, table, row, cell, element) {
        var contextMenuItems = {};

        //Element ile ilgili context menu
        if (element) {
            if (element.isHidden) {
                contextMenuItems['showElement'] = { name: $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETINPUTICON.CONTEXTMENU.LBL_SHOW.TEXT"), icon: "fa-eye" }
            }

            if (!element.isHidden) {
                contextMenuItems['hideElement'] = { name: $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETINPUTICON.CONTEXTMENU.LBL_HIDE.TEXT"), icon: "fa-eye-slash" }
            }
        }


        if (row) {
            contextMenuItems["rowSep"] = "---------";
            angular.extend(contextMenuItems, _createContextMenuItemsForTableRow(row));
        }

        if (table) {
            contextMenuItems["tableSep"] = "---------";
            angular.extend(contextMenuItems, _createContextMenuItemsForTable(table));
        }

        if (page) {
            contextMenuItems["pageSep"] = "---------";
            angular.extend(contextMenuItems, serviceModel.createContextMenuItemsForPage(page));
        }

        return contextMenuItems;
    };

    serviceModel.createContextMenuItemsForBox = function (page, table, row, cell, element) {
        var contextMenuItems = {};

        //Element ile ilgili context menu
        if (element) {
            if (element.isHidden) {
                contextMenuItems['showElement'] = { name: $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETBOX.CONTEXTMENU.LBL_SHOW.TEXT"), icon: "fa-eye" }
            }

            if (!element.isHidden) {
                contextMenuItems['hideElement'] = { name: $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETBOX.CONTEXTMENU.LBL_HIDE.TEXT"), icon: "fa-eye-slash" }
            }
        }


        if (row) {
            contextMenuItems["rowSep"] = "---------";
            angular.extend(contextMenuItems, _createContextMenuItemsForTableRow(row));
        }

        if (table) {
            contextMenuItems["tableSep"] = "---------";
            angular.extend(contextMenuItems, _createContextMenuItemsForTable(table));
        }

        if (page) {
            contextMenuItems["pageSep"] = "---------";
            angular.extend(contextMenuItems, serviceModel.createContextMenuItemsForPage(page));
        }

        return contextMenuItems;
    };

    serviceModel.createContextMenuItemsForInputImage = function (page, table, row, cell, element) {
        var contextMenuItems = {};

        //Element ile ilgili context menu
        if (element) {
            if (element.isHidden) {
                contextMenuItems['showElement'] = { name: $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETINPUTIMAGE.CONTEXTMENU.LBL_SHOW.TEXT"), icon: "fa-eye" }
            }

            if (!element.isHidden) {
                contextMenuItems['hideElement'] = { name: $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETINPUTIMAGE.CONTEXTMENU.LBL_HIDE.TEXT"), icon: "fa-eye-slash" }
            }
        }


        if (row) {
            contextMenuItems["rowSep"] = "---------";
            angular.extend(contextMenuItems, _createContextMenuItemsForTableRow(row));
        }

        if (table) {
            contextMenuItems["tableSep"] = "---------";
            angular.extend(contextMenuItems, _createContextMenuItemsForTable(table));
        }

        if (page) {
            contextMenuItems["pageSep"] = "---------";
            angular.extend(contextMenuItems, serviceModel.createContextMenuItemsForPage(page));
        }

        return contextMenuItems;
    };
    //Functions End

    //Inner Functions 
    var _createContextMenuItemsForPage = function (page) {
        var contextMenuItems = {};
        if (page.isHidden) {
            contextMenuItems['showPage'] = { name: $translate.instant("DATASHEET.DIRECTIVES.DATASHEETPAGES.DATASHEETPAGE.CONTEXTMENU.LBL_SHOW.TEXT"), icon: "fa-eye" }
        }
        else {
            contextMenuItems['hidePage'] = { name: $translate.instant("DATASHEET.DIRECTIVES.DATASHEETPAGES.DATASHEETPAGE.CONTEXTMENU.LBL_HIDE.TEXT"), icon: "fa-eye-slash" }
        }

        return contextMenuItems;
    };

    var _createContextMenuItemsForTable = function (table) {
        var contextMenuItems = {};

        if (table.isHidden) {
            contextMenuItems['showTable'] = { name: $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETTABLE.DATASHEETTABLE.CONTEXTMENU.LBL_SHOW.TEXT"), icon: "fa-eye" }
        } else if (!table.isHidden) {
            contextMenuItems['hideTable'] = { name: $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETTABLE.DATASHEETTABLE.CONTEXTMENU.LBL_HIDE.TEXT"), icon: "fa-eye-slash" }
        }

        return contextMenuItems;
    }

    var _createContextMenuItemsForTableRow = function (row) {
        var contextMenuItems = {};


        if (row.isDynamic) {
            contextMenuItems["removeDynamicRow"] = { name: $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETTABLE.DATASHEETTABLEROW.CONTEXTMENU.LBL_REMOVE_DYNAMIC_ROW.TEXT"), icon: "fa-trash" };

        } else {
            contextMenuItems['dynamicRows'] = {
                "name": $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETTABLE.DATASHEETTABLEROW.CONTEXTMENU.LBL_DYNAMIC_ROWS.TEXT"),
                "icon": "fa-plus-circle",
                "items": {
                    "addDynamicRowsAbove": { "name": $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETTABLE.DATASHEETTABLEROW.CONTEXTMENU.LBL_ADD_DYNAMIC_ROWS_ABOVE.TEXT"), icon: "fa-arrow-up" },
                    "addDynamicRowsBelow": { "name": $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETTABLE.DATASHEETTABLEROW.CONTEXTMENU.LBL_ADD_DYNAMIC_ROWS_BELOW.TEXT"), icon: "fa-arrow-down" },
                }
            };

            contextMenuItems['copyOptions'] = {
                "name": $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETTABLE.DATASHEETTABLEROW.CONTEXTMENU.LBL_COPY_OPTIONS.TEXT"),
                "icon": "fa-clipboard",
                "items": {
                    "copyRowAbove": { "name": $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETTABLE.DATASHEETTABLEROW.CONTEXTMENU.LBL_COPY_ROW_ABOVE.TEXT"), icon: "fa-arrow-up" },
                    "copyRowBelow": { "name": $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETTABLE.DATASHEETTABLEROW.CONTEXTMENU.LBL_COPY_ROW_BELOW.TEXT"), icon: "fa-arrow-down" },
                }
            };

        }

        if (row.isHidden) {
            contextMenuItems['showRow'] = { name: $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETTABLE.DATASHEETTABLEROW.CONTEXTMENU.LBL_SHOW.TEXT"), icon: "fa-eye" }
        } else if (!row.isHidden) {
            contextMenuItems['hideRow'] = { name: $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETTABLE.DATASHEETTABLEROW.CONTEXTMENU.LBL_HIDE.TEXT"), icon: "fa-eye-slash" }
        }

        return contextMenuItems;
    }


    //Inner Functions End

    return serviceModel;
};

module.exports = function (app) {
    app.service('datasheetContextMenuService', ['$translate', 'datasheetElementService', '_', datasheetContextMenuService]);
};