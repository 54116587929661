var inviteUserCtrl = function ($rootScope, $scope, $translate, $modalInstance, userFactory) {
    //Fields
    $scope.status = true;
    $scope.inviteSuccess = $translate.instant('USER.ALLUSERS.MODALS.INVITEUSER.SUCCESS_MESSAGES.INVITE_SUCCESS');
    //Fields - End

    //Watches
    $scope.$watch('email', function (newValue, oldValue) {
        if (newValue != null && newValue != undefined) {
            $scope.email = newValue.toLowerCase();
        }
    })
    //Watches End

    //Event Handlers
    $rootScope.$on('$translateChangeSuccess', function () {
        $scope.inviteSuccess = $translate.instant('USER.ALLUSERS.MODALS.INVITEUSER.SUCCESS_MESSAGES.INVITE_SUCCESS')
    });
    //Event Handlers - End


    //Functions
    $scope.inviteUser = function () {
        $scope.customErrorMessage = "";
        $scope.status = true;
        if ($scope.inviteUserForm.$valid) {
            userFactory.inviteUser($scope.email, $rootScope.activeLang)
                .then(function (response) {
                    $scope.status = response.status;
                    if (!response.status) {
                        $scope.customErrorMessage = response.errorMessage;
                    } else {
                        toastr.success($scope.inviteSuccess, '', { positionClass: 'toast-bottom-full-width', timeOut: 3000 });
                        $modalInstance.close({
                            status: true
                        });
                    }
                });

        }
    };

    $scope.cancel = function () {
        $modalInstance.dismiss('cancel');
    };
    //Functions - End
};

module.exports = function (app) {
    app.controller('inviteUserCtrl', ['$rootScope', '$scope', '$translate', '$modalInstance', 'userFactory', inviteUserCtrl]);
};
