var allArchivesCtrl = function ($rootScope, $scope, $translate, $modal, archiveFactory, intercomFactory) {
    //Fields
    //Fields End

    //Inner Functions
    var getAllArchives = function () {
        archiveFactory.getAllArchives()
            .then(function (response) {
                $scope.archives = response;
            });
    };
    //Inner Functions End

    //Functions
    $scope.openDeleteCustomArchiveModal = function (archiveId) {
        var modalInstance = $modal.open({
            templateUrl: 'app/components/customArchives/allArchives/views/modals/deleteArchive.html',
            controller: 'deleteArchiveCtrl',
            resolve: {
                archiveId: function () {
                    return archiveId;
                }
            }
        });

        modalInstance.result
            .then(function (result) {
                if (result) {
                    var message = $translate.instant("CUSTOMARCHIVES.ALLARCHIVES.SUCCESS_MESSAGES.DELETED_SUCCESSFULLY");
                    toastr.success(message, '', { positionClass: 'toast-bottom-full-width', timeOut: 3000 });
                    $rootScope.$broadcast('deletedNewArchiveScenario', {});

                    getAllArchives();
                }
            });
    };
    //Functions End

    //Initials
    getAllArchives();
    intercomFactory.addEvent({
        event_name: "Custom Archives Visited"
    });
    //Initials End
};

module.exports = function (app) {
    app.controller('allArchivesCtrl', ['$rootScope', '$scope', '$translate', '$modal', 'archiveFactory', 'intercomFactory', allArchivesCtrl]);
};
