var editTemplateCtrl = function ($window, $rootScope, $q, $state, $scope, $stateParams, $filter, $timeout, $translate, $modal, localStorageService, _, templateContextMenuFactory, templateFactory, generalsService, menuToggleFactory, froalaEditorService, googleTranslate, languageFactory, checkPermissionFactory, firebaseFactory, userFactory, intercomFactory, config) {
  var templateId = $stateParams.id;

  // Fields
  $scope.isLibraryTemplate = { isVisible: false };

  var saveMessage = $translate.instant('TEMPLATE.EDIT_TEMPLATE.PARTIALS.TOP_BAR.BTN_SAVE.SUCCESS');
  fieldTexts = {
    productName: $translate.instant('TEMPLATE.EDIT_TEMPLATE.ELEMENT_TEMPLATES.FREE_TEXT.LBL_PRODUCT_NAME.TEXT'),
    productNumber: $translate.instant('TEMPLATE.EDIT_TEMPLATE.ELEMENT_TEMPLATES.FREE_TEXT.LBL_PRODUCT_NUMBER.TEXT'),
    publishDate: $translate.instant('TEMPLATE.EDIT_TEMPLATE.ELEMENT_TEMPLATES.FREE_TEXT.LBL_PUBLISH_DATE.TEXT'),
    revisionNumber: $translate.instant('TEMPLATE.EDIT_TEMPLATE.ELEMENT_TEMPLATES.FREE_TEXT.LBL_REVISION_NO.TEXT'),
    pageNo: $translate.instant('TEMPLATE.EDIT_TEMPLATE.ELEMENT_TEMPLATES.FREE_TEXT.LBL_PAGE_NO.TEXT'),
    totalPage: $translate.instant('TEMPLATE.EDIT_TEMPLATE.ELEMENT_TEMPLATES.FREE_TEXT.LBL_TOTAL_PAGE.TEXT'),
  };
  var startupModel = {};
  var startupModelElements;
  $scope.isLocked = false;

  //Functions
  $scope.save = function (callback) {
    $scope.saving = templateFactory.save(model).then(function (response) {
      $scope.isLibraryTemplate.isVisible = false;
      var model = response;
      var title = response.template.name;
      var version = response.template.majorVersion + "." + response.template.minorVersion
      $('#templateNameTitle').html(title);
      $('#templateVersionTitle').html(version);
      $('#templateVersionTitle').parent().show();
      $('#templateNameTitle').parent().attr("title", title);
      startupModelElements = angular.copy(model.elements);
      setTimeout(function () {
        loadAuditData();
      }, 2500);
      if (callback)
        callback();
    });
    return $scope.saving;
  };
  $scope.saveProperties = function () {
    console.log('edittmp', fakeModel)
    model.template.industryId = fakeModel.industryId;
    model.template.name = fakeModel.name;
    model.template.languages = fakeModel.languages;


    languageFactory.getLanguages().then(function (response) {
      var allLanguages = response;
    });
    $('#slLanguage').empty();
    $('#templateLanguages').empty();
    for (var i in model.template.languages) {
      var language = _.filter(allLanguages, { isoCode: model.template.languages[i] })[0];

      $('#slLanguage').append($('<option>', {
        value: language.isoCode,
        text: language.text
      }));

      if (i == 0) {
        $('#templateLanguages').append(language.text);
      } else {
        $('#templateLanguages').append(', ' + language.text);
      }
    }

    if (model.template.languages.length == 1) {
      $('#divPageTranslate').hide();
      $('#spanSlLanguage').hide();
    } else {
      $('#divPageTranslate').show();
      $('#spanSlLanguage').show();
    }


    $('#slLanguage').val(selectionModel.selectedLanguage);
    $('#slLanguage').select2({ minimumResultsForSearch: -1 });

    model.template.hasProductNumber = fakeModel.hasProductNumber;
    model.template.productNumberRequired = fakeModel.productNumberRequired;
    model.template.uniqueProductNumber = fakeModel.uniqueProductNumber;
    model.template.hasStartupVersionNo = fakeModel.hasStartupVersionNo;
    model.template.description = fakeModel.description;


    for (var i in model.elements) {

      var element = model.elements[i];

      if (element.type === "text") {
        for (var l in model.template.languages) {
          var language = model.template.languages[l];
          if (element.values[language] === undefined) {
            element.values[language] = "Sample Text";
          }
        }
      } else if (element.type === "inputtext") {

        for (var l in model.template.languages) {
          var language = model.template.languages[l];
          if (element.placeHolders[language] === undefined) {
            element.placeHolders[language] = "";
          }
          if (element.previews[language] === undefined) {
            element.previews[language] = "";
          }
        }
      } else if (element.type === "selectlist") {
        for (var l in model.template.languages) {
          var language = model.template.languages[l];

          if (element.source[language] === undefined) {
            var primaryValues = element.source[model.template.primaryLanguage];
            element.source[language] = primaryValues;
          }
        }
      }
    }

    model.template.primaryLanguage = fakeModel.primaryLanguage;

    return $scope.save();
  };
  $scope.preview = function () {
    $scope.previewing = true;
    var previewWindow = window.open(config.app[config.mode].appUrl + '/' + $rootScope.activeLang + '/pdf/preparing', '_blank');

    return templateFactory.preview(
      model.template._id,
      selectionModel.selectedLanguage).then(function (response) {
        setTimeout(function () {

          var previewPdf = response.fileWebPath.replace("~", config.api[config.mode].apiUrl);
          localStorageService.set('previewPdf', previewPdf);
          var url = $state.href('pdf.viewPreview', {
            lang: $rootScope.activeLang
          }, { absolute: true });

          previewWindow.location = url;

          $scope.previewing = false;
        }, 500);
      });
  };
  $scope.publish = function () {
    return templateFactory.publish(model)
      .then(function (response) {
        intercomFactory.addEvent({
          event_name: "Template Published",
          metadata: {
            templateName: response.template.name
          }
        })
          .then(function (response) {
            if (INTERCOM_SETTINGS.IS_SHOWN) {
              window.Intercom('shutdown');
              userFactory.loadUserInformation()
                .then(function (user) {
                  window.Intercom('boot', {
                    app_id: INTERCOM_SETTINGS.APP_ID,
                    email: user.Email,
                    user_id: user._id,
                    user_hash: user.hash
                  });
                });
            }
          });

        $scope.isLibraryTemplate.isVisible = false;
        startupModelElements = angular.copy(model.elements);

        model.template.majorVersion = response.template.majorVersion;
        model.template.minorVersion = response.template.minorVersion;
        //var title = response.template.name + " <span style='font-size:14px'>(v" + response.template.majorVersion + "." + response.template.minorVersion + ")</span>";
        var title = response.template.name;
        var version = response.template.majorVersion + "." + response.template.minorVersion

        $('#templateNameTitle').html(title);
        $('#templateVersionTitle').html(version);
        $('#templateVersionTitle').parent().show();
        $('#templateNameTitle').parent().attr("title", title);
        loadAuditData();
      });
  };

  $scope.loadAuidit = function (audit) {
    $('#templateLoading').show();
    templateFactory.getTemplateHistory(audit._id).then(function (response) {
      setTimeout(function () {
        $.event.trigger({
          type: "templateLoaded",
          model: response,
          selectedLanguage: selectionModel.selectedLanguage
        });
      }, 1000);
    });

  };
  $scope.revertToSavedTemplate = function () {
    $('#templateLoading').show();
    $scope.isLibraryTemplate.isVisible = false;

    startupModel.elements = startupModelElements

    setTimeout(function () {
      $.event.trigger({
        type: "templateLoaded",
        model: startupModel,
        selectedLanguage: selectionModel.selectedLanguage
      });

    }, 1000);
  };
  //Private Functions
  //Events
  $(document).off('translateElement').on('translateElement', function (e) {
    var elementText = e.element[e.field][e.language];
    googleTranslate.translateText(elementText, e.language, e.languages || model.template.languages)
      .then(function (response) {
        for (var i in response.TranslateList) {
          var currentTranslate = response.TranslateList[i];
          if (currentTranslate.Language != e.language)
            e.element[e.field][currentTranslate.Language] = currentTranslate.Text;
        }

        e.element.isTranslateDone = true;
      });
  });
  $(document).off('translateSelectListItem').on('translateSelectListItem', function (e) {
    var item = e.item;
    var language = e.language;

    googleTranslate.translateText(item.value, language, e.languages || model.template.languages).then(function (response) {

      for (var i in response.TranslateList) {
        var currentTranslate = response.TranslateList[i];
        if (currentTranslate.Language != language) {
          var source = e.source[currentTranslate.Language];
          var releatedItem = _.filter(source, { id: item.id })[0];
          if(releatedItem)
            releatedItem.value = currentTranslate.Text;
        }
      }
      item.isTranslateDone = true;
    });

  });
  $(document).off('addSelectListItem').on('addSelectListItem', function (e) {
    var modalInstance = $modal.open({
      animation: false,
      controller: 'selectListItemCtrl',
      templateUrl: '/app/components/template/editTemplate/views/modals/selectListItems.html',
      backdropClass: "defaultmodal",
      size: 'lg',
      resolve: {
        element: function () {
          return selectionModel.selectedElement;
        },
        model: function () {
          return model;
        },
        selectedLanguage: function () {
          return selectionModel.selectedLanguage;
        }
      }
    });
  });
  $(document).off('changeIcon').on('changeIcon', function (e) {
    var modalInstance = $modal.open({
      animation: false,
      controller: 'selectIconCtrlNew',
      templateUrl: '/app/components/template/editTemplate/views/modals/selectIcon.html',
      backdropClass: "defaultmodal",
      size: 'lg',
      resolve: {
        element: function () {
          return selectionModel.selectedElement;
        }
      }
    });
  });
  $(document).off('changeInputIcon').on('changeInputIcon', function (e) {
    var modalInstance = $modal.open({
      animation: false,
      controller: 'selectIconPackCtrl',
      templateUrl: '/app/components/template/editTemplate/views/modals/selectIconPack.html',
      backdropClass: "defaultmodal",
      size: 'lg',
      resolve: {
        element: function () {
          return selectionModel.selectedElement;
        }
      }
    });
  });
  $(document).unbind("templateGenerated").bind("templateGenerated", function (e) {
    startupModelElements = angular.copy(e.model.elements);
  });

  $scope.$on('$destroy', function () {
    $window.onbeforeunload = undefined;
    $(window).off('unload');
    var accountId = localStorageService.get('userInformationData').AccountId._id;
    firebaseFactory.clearRefEvents(accountId + '/locked/template/' + templateId);
  });

  $scope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
    if (fromState.name == 'editor.editTemplate') {

      $(document).find('.fr-basic').each(function () {
        var editor = $(this).data('froala.editor');
        editor.destroy();
      });

      $('.colorpicker-element').colorpicker('destroy');

      if (!angular.equals(startupModelElements, model.elements)) {
        event.preventDefault();
        bootbox.dialog({
          message: $translate.instant("TEMPLATE.EDIT_TEMPLATE.LBL_WITHOUT_SAVING_MESSAGE.TEXT"),
          buttons: {
            stayInPage: {
              label: $translate.instant("TEMPLATE.EDIT_TEMPLATE.BTN_STAY_IN_PAGE.TEXT"),
              className: 'btn-default',
              callback: function () { }
            },
            justContinue: {
              label: $translate.instant("TEMPLATE.EDIT_TEMPLATE.BTN_JUST_CONTINUE.TEXT"),
              className: 'btn-danger',
              callback: function () {
                startupModelElements = angular.copy(model.elements);
                setTimeout(function () {
                  $state.go(toState, toParams);
                }, 500);
              }
            },
            saveAndContinue: {
              label: $translate.instant("TEMPLATE.EDIT_TEMPLATE.BTN_SAVE_AND_CONTINUE.TEXT"),
              className: 'btn-success',
              callback: function () {
                $scope.save(function () {
                  startupModelElements = angular.copy(model.elements);
                  setTimeout(function () {
                    $state.go(toState, toParams);
                  }, 500);
                });
              }
            }
          }
        });
      }
      else {
        unLockTemplate();
        notifyBar.clear();
      }

    }
  });

  $window.onbeforeunload = function (e) {
    if (!angular.equals(startupModelElements, model.elements)) {
      return false;
    }
  };

  $(window).unload(function () {
    unLockTemplate();
  });

  //Event Handlers
  var templateUnlockHandler = function (value) {
    if (value == null || value == undefined) {
      notifyBar.clear();

      var workOnMessage = $translate.instant("TEMPLATE.EDIT_TEMPLATE.LBL_UNLOCK.TEXT");
      notifyBar.info(workOnMessage);

      $timeout(function () {
        location.reload();
      }, 3000);
    }
  };
  //Event Handlers End

  //Initials
  var getSelectedLanguage = function (template) {
    var selectedLangLocal = localStorageService.get('selectedLanguageForTemplates');
    if (angular.isUndefined(selectedLangLocal) || selectedLangLocal === null) {
      selectedLangLocal = [];
      selectedLangLocal.push({
        templateId: template._id,
        language: template.primaryLanguage
      });
      localStorageService.set('selectedLanguageForTemplates', selectedLangLocal);
      return template.primaryLanguage;
    } else {
      var relatedTemplateLang = _.filter(selectedLangLocal, { templateId: template._id })[0];

      if (angular.isUndefined(relatedTemplateLang)) {
        selectedLangLocal.push({
          templateId: template._id,
          language: template.primaryLanguage
        });
        localStorageService.set('selectedLanguageForTemplates', selectedLangLocal);
        return template.primaryLanguage;
      } else {
        return relatedTemplateLang.language;
      }
    }
  };

  var loadAuditData = function () {
    templateFactory.getTemplateHistories($stateParams.id).then(function (response) {
      console.log("audit", response);
      $scope.audits = _.sortBy(response, "dateCreated");
      $scope.audits = $scope.audits.reverse();
    });
  };

  var setContextMenuText = function () {
    contextMenuTextList = [];
    contextMenuTextList.push({
      key: "copy",
      text: $translate.instant('TEMPLATE.EDIT_TEMPLATE.CONTEXT_MENU.COPY')
    });

    contextMenuTextList.push({
      key: "cut",
      text: $translate.instant('TEMPLATE.EDIT_TEMPLATE.CONTEXT_MENU.CUT')
    });

    contextMenuTextList.push({
      key: "delete",
      text: $translate.instant('TEMPLATE.EDIT_TEMPLATE.CONTEXT_MENU.DELETE')
    });

    contextMenuTextList.push({
      key: "paste",
      text: $translate.instant('TEMPLATE.EDIT_TEMPLATE.CONTEXT_MENU.PASTE')
    });

    contextMenuTextList.push({
      key: "addItem",
      text: $translate.instant('TEMPLATE.EDIT_TEMPLATE.CONTEXT_MENU.ADD_ITEM')
    });

    contextMenuTextList.push({
      key: "rowOptions",
      text: $translate.instant('TEMPLATE.EDIT_TEMPLATE.CONTEXT_MENU.ROW_OPTIONS')
    });

    contextMenuTextList.push({
      key: "rowAfter",
      text: $translate.instant('TEMPLATE.EDIT_TEMPLATE.CONTEXT_MENU.ADD_ROW_AFTER')
    });

    contextMenuTextList.push({
      key: "rowBefore",
      text: $translate.instant('TEMPLATE.EDIT_TEMPLATE.CONTEXT_MENU.ADD_ROW_BEFORE')
    });

    contextMenuTextList.push({
      key: "removeRow",
      text: $translate.instant('TEMPLATE.EDIT_TEMPLATE.CONTEXT_MENU.REMOVE_ROW')
    });

    contextMenuTextList.push({
      key: "columnOptions",
      text: $translate.instant('TEMPLATE.EDIT_TEMPLATE.CONTEXT_MENU.COLUMN_OPTIONS')
    });
    contextMenuTextList.push({
      key: "columnAfter",
      text: $translate.instant('TEMPLATE.EDIT_TEMPLATE.CONTEXT_MENU.ADD_COLUMN_AFTER')
    });
    contextMenuTextList.push({
      key: "columnBefore",
      text: $translate.instant('TEMPLATE.EDIT_TEMPLATE.CONTEXT_MENU.ADD_COLUMN_BEFORE')
    });
    contextMenuTextList.push({
      key: "removeColumn",
      text: $translate.instant('TEMPLATE.EDIT_TEMPLATE.CONTEXT_MENU.REMOVE_COLUMN')
    });
    contextMenuTextList.push({
      key: "mergeCells",
      text: $translate.instant('TEMPLATE.EDIT_TEMPLATE.CONTEXT_MENU.MERGE_CELLS')
    });
    contextMenuTextList.push({
      key: "mergeHorizontal",
      text: $translate.instant('TEMPLATE.EDIT_TEMPLATE.CONTEXT_MENU.MERGE_HORIZONTAL')
    });
    contextMenuTextList.push({
      key: "unmergeHorizontal",
      text: $translate.instant('TEMPLATE.EDIT_TEMPLATE.CONTEXT_MENU.UNMERGE_HORIZONTAL')
    });
    contextMenuTextList.push({
      key: "mergeVertical",
      text: $translate.instant('TEMPLATE.EDIT_TEMPLATE.CONTEXT_MENU.MERGE_VERTICAL')
    });
    contextMenuTextList.push({
      key: "unmergeVertical",
      text: $translate.instant('TEMPLATE.EDIT_TEMPLATE.CONTEXT_MENU.UNMERGE_VERTICAL')
    });
    contextMenuTextList.push({
      key: "unmergeAll",
      text: $translate.instant('TEMPLATE.EDIT_TEMPLATE.CONTEXT_MENU.UNMERGE_ALL')
    });
    contextMenuTextList.push({
      key: "clearCells",
      text: $translate.instant('TEMPLATE.EDIT_TEMPLATE.CONTEXT_MENU.CLEAR_CELLS')
    });
    contextMenuTextList.push({
      key: "clearColumn",
      text: $translate.instant('TEMPLATE.EDIT_TEMPLATE.CONTEXT_MENU.CLEAR_COLUMN')
    });
    contextMenuTextList.push({
      key: "clearColumnBelow",
      text: $translate.instant('TEMPLATE.EDIT_TEMPLATE.CONTEXT_MENU.CLEAR_COLUMN_BELOW')
    });
    contextMenuTextList.push({
      key: "clearRow",
      text: $translate.instant('TEMPLATE.EDIT_TEMPLATE.CONTEXT_MENU.CLEAR_ROW')
    });
    contextMenuTextList.push({
      key: "clearRowRight",
      text: $translate.instant('TEMPLATE.EDIT_TEMPLATE.CONTEXT_MENU.CLEAR_ROW_RIGHT')
    });
    contextMenuTextList.push({
      key: "clearAllCells",
      text: $translate.instant('TEMPLATE.EDIT_TEMPLATE.CONTEXT_MENU.CLEAR_ALL_CELLS')
    });

    contextMenuTextList.push({
      key: "copyTable",
      text: $translate.instant('TEMPLATE.EDIT_TEMPLATE.CONTEXT_MENU.COPY_TABLE')
    });

    contextMenuTextList.push({
      key: "deleteTable",
      text: $translate.instant('TEMPLATE.EDIT_TEMPLATE.CONTEXT_MENU.DELETE_TABLE')
    });

    contextMenuTextList.push({
      key: "copyToAllColumnCells",
      text: $translate.instant('TEMPLATE.EDIT_TEMPLATE.CONTEXT_MENU.COPY_TO_ALL_COLUMN_CELLS')
    });

    contextMenuTextList.push({
      key: "copyToAllRowCells",
      text: $translate.instant('TEMPLATE.EDIT_TEMPLATE.CONTEXT_MENU.COPY_TO_ALL_ROW_CELLS')
    });

    contextMenuTextList.push({
      key: "cellOptions",
      text: $translate.instant('TEMPLATE.EDIT_TEMPLATE.CONTEXT_MENU.CELL_OPTIONS')
    });

    contextMenuTextList.push({
      key: "copyCellStyleToRow",
      text: $translate.instant('TEMPLATE.EDIT_TEMPLATE.CONTEXT_MENU.CELL_STYLE_TO_ALL_ROW')
    });

    contextMenuTextList.push({
      key: "copyCellStyleToColumn",
      text: $translate.instant('TEMPLATE.EDIT_TEMPLATE.CONTEXT_MENU.CELL_STYLE_TO_ALL_COLUMN')
    });

    contextMenuTextList.push({
      key: "setCellsEqual",
      text: $translate.instant('TEMPLATE.EDIT_TEMPLATE.CONTEXT_MENU.EQUAL_CELLS')
    });

    contextMenuTextList.push({
      key: "paneProperties",
      text: $translate.instant('TEMPLATE.EDIT_TEMPLATE.CONTEXT_MENU.PANEL_PROPERTIES')
    });

    contextMenuTextList.push({
      key: "cutTable",
      text: $translate.instant('TEMPLATE.EDIT_TEMPLATE.CONTEXT_MENU.CUT_TABLE')
    });

    contextMenuTextList.push({
      key: "textItem",
      text: $filter('fancyTypeName')('text')
    });

    contextMenuTextList.push({
      key: "inputTextItem",
      text: $filter('fancyTypeName')('inputtext')
    });

    contextMenuTextList.push({
      key: "selectListItem",
      text: $filter('fancyTypeName')('selectlist')
    });

  };
  var init = function () {
    //Template ilk yüklendiğinde başka bir kullanıcı tarafından kilitlenip kilitlenmediğini öğrenelim.  
    var userId = localStorageService.get('userInformationData')._id;
    var accountId = localStorageService.get('userInformationData').AccountId._id;
    firebaseFactory.checkOnce(accountId + '/locked/template/' + templateId, function (value) {
      if (value && value.lockedBy != userId) {
        $scope.isLocked = true;

        userFactory.getUserByUserId(value.lockedBy)
          .then(function (userResponse) {
            var fullName = userResponse.FirstName + ' ' + userResponse.LastName;
            var workingUserMessage = $translate.instant("TEMPLATE.EDIT_TEMPLATE.LBL_WORKING_USER.TEXT");
            workingUserMessage = workingUserMessage.replace("$fullName$", fullName);
            notifyBar.warning(workingUserMessage);
          })
        firebaseFactory.checkAlways(accountId + '/locked/template/' + templateId, templateUnlockHandler);

      }
      else {
        lockTemplate();
      }
    });


    activeLang = $rootScope.activeLang;
    menuToggleFactory.collapse();
    setContextMenuText();
    templateFactory.getIndustries().then(function (response) {

      for (var i in response) {
        var industry = response[i];
        industry.text = $translate.instant(industry.languageCode);
      }

      industries = response;
    });

    languageFactory.getLanguages().then(function (response) {
      for (var i in response) {
        var language = response[i];
        language.text = $translate.instant(language.languageCode);
      }

      allLanguages = response;
    });

    templateFactory.get($stateParams.id).then(function (templateResponse) {

      startupModel.template = templateResponse.data;
      $rootScope.editTemplateTemplate = templateResponse.data;

      templateFactory.getElements($stateParams.id).then(function (elementResponse) {

        startupModel.elements = elementResponse.data;
        $scope.templateName = startupModel.template.name;

        var selectedLanguage = getSelectedLanguage(startupModel.template);

        setTimeout(function () {
          $.event.trigger({
            type: "templateLoaded",
            model: startupModel,
            selectedLanguage: selectedLanguage
          });
        }, 1000);

      });

    });



    translateCompleteMessae = $translate.instant('TEMPLATE.EDIT_TEMPLATE.LBL_TRASLATION_COMPLETED.TEXT');
    translateInProgressMessae = $translate.instant('TEMPLATE.EDIT_TEMPLATE.LBL_TRASLATION_IN_PROGRESS.TEXT');
    copyCompleteMessae = $translate.instant('TEMPLATE.EDIT_TEMPLATE.LBL_COPIED.TEXT');
    copyInProgressMessae = $translate.instant('TEMPLATE.EDIT_TEMPLATE.LBL_COPY_IN_PROGRESS.TEXT');
    tableInHeaderFooterMessage = $translate.instant('TEMPLATE.EDIT_TEMPLATE.LBL_TABLE_IN_HEADER_FOOTER_PROHIBITED.TEXT');
    layoutSwitchElementsHidden = $translate.instant('TEMPLATE.EDIT_TEMPLATE.LBL_LAYOUT_SWITCH_HIDDEN_ELEMENTS_HIDDEN.TEXT');
    layoutSwitchElementsRevealed = $translate.instant('TEMPLATE.EDIT_TEMPLATE.LBL_LAYOUT_SWITCH_HIDDEN_ELEMENTS_REVEALED.TEXT');
    feildsTitle = $translate.instant('TEMPLATE.EDIT_TEMPLATE.SIDE_BAR.FONT_PROPERTIES.BTN_FIELDS.TOOLTIP');
    mathSymbolsTitle = $translate.instant('TEMPLATE.EDIT_TEMPLATE.SIDE_BAR.FONT_PROPERTIES.BTN_MATH_SYMBOLS.TOOLTIP');
    translateToAllLanguages = $translate.instant('TEMPLATE.EDIT_TEMPLATE.LBL_TRANSLATE_TO_ALL.TEXT');
    translateTo = $translate.instant('TEMPLATE.EDIT_TEMPLATE.LBL_TRANSLATE_TO.TEXT');
    translatePage = $translate.instant('TEMPLATE.EDIT_TEMPLATE.LBL_TRANSLATE_PAGE.TEXT');
    translateElement = $translate.instant('TEMPLATE.EDIT_TEMPLATE.LBL_TRANSLATE_ELEMENT.TEXT');
    copyToAllLanguages = $translate.instant('TEMPLATE.EDIT_TEMPLATE.LBL_COPY_TO_ALL.TEXT');
    copyTo = $translate.instant('TEMPLATE.EDIT_TEMPLATE.LBL_COPY_TO.TEXT');
    copyPage = $translate.instant('TEMPLATE.EDIT_TEMPLATE.LBL_COPY_PAGE.TEXT');

    loadAuditData();

  };

  var lockTemplate = function () {
    var userId = localStorageService.get('userInformationData')._id;
    var accountId = localStorageService.get('userInformationData').AccountId._id;
    firebaseFactory.set(accountId + '/locked/template/' + templateId, {
      isLocked: true,
      lockedBy: userId,
      lockedDate: new Date().toString()
    });
  };

  var unLockTemplate = function () {
    var accountId = localStorageService.get('userInformationData').AccountId._id;

    //Kullanıcı tarafından locked işlemi yapıldıysa serbest bırak
    if (!$scope.isLocked) {
      firebaseFactory.set(accountId + '/locked/template/' + templateId, null);
    }
  };

  init();
};

module.exports = function (app) {
  app.controller('editTemplateCtrl', ['$window', '$rootScope', '$q', '$state', '$scope', '$stateParams', '$filter', '$timeout', '$translate', '$modal', 'localStorageService', '_', 'templateContextMenuFactory', 'templateFactory', 'generalsService', 'menuToggleFactory', 'froalaEditorService', 'googleTranslate', 'languageFactory', 'checkPermissionFactory', 'firebaseFactory', 'userFactory', 'intercomFactory', 'config', editTemplateCtrl]);
};
