var deleteUserFromGroupCtrl = function($scope, $timeout, $modalInstance, groupFactory, groupId, userId) {
    //Fields
    $scope.deleteButton = "GROUP.GROUPUSERS.MODALS.DELETEUSERFROMGROUP.BTN_DELETE.TEXT";
    $scope.isProcessing = false;
    //Fields End

    //Functions
    $scope.cancel = function() {
        $modalInstance.dismiss('cancel');
    };

    $scope.delete = function() {
        $scope.deleteButton = "GROUP.GROUPUSERS.MODALS.DELETEUSERFROMGROUP.BTN_DELETE.ALTERNATE_TEXT";
        $scope.isProcessing = true;

        var model = {};
        model.groupId = groupId;
        model.userId = userId;

        groupFactory.deleteUserFromGroup(model)
            .then(function(response) {
                $scope.deleteButton = "GROUP.GROUPUSERS.MODALS.DELETEUSERFROMGROUP.BTN_DELETE.SUCCESS";

                if (response.status) {
                    $timeout(function() {
                        $modalInstance.close({
                            status: true
                        });
                    }, 500);

                }
            });

    };
    //Functions End

    //Initials
    //Initials End
};

module.exports = function(app) {
    app.controller('deleteUserFromGroupCtrl', ['$scope', '$timeout', '$modalInstance', 'groupFactory', 'groupId', 'userId', deleteUserFromGroupCtrl]);
};
