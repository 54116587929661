var deleteFolderCtrl = function($scope, $translate, $modalInstance, folderFactory, selectedFolder) {
    //Fields
    $scope.lblDeleteFolderTranslationData = {
        folderName: selectedFolder.text
    };

    $scope.deleteButton = "FOLDER.MODALS.DELETEFOLDER.BTN_DELETE.TEXT";
    $scope.isProcessing = false;
    //Fields End

    //Functions
    $scope.cancel = function() {
        $modalInstance.dismiss('cancel');
    };

    $scope.deleteFolder = function() {
        $scope.deleteButton = "FOLDER.MODALS.DELETEFOLDER.BTN_DELETE.ALTERNATE_TEXT";
        $scope.isProcessing = true;

        folderFactory.deleteFolder(selectedFolder.id)
            .then(function(response) {
                $scope.deleteButton = "FOLDER.MODALS.DELETEFOLDER.BTN_DELETE.TEXT";
                $scope.isProcessing = false;

                if (response.data.status) {

                    var message = $translate.instant("FOLDER.MODALS.DELETEFOLDER.SUCCESS_MESSAGES.DELETED_FOLDER");
                    toastr.success(message, '', { positionClass: 'toast-bottom-full-width', timeOut: 3000 });

                    $modalInstance.close({
                        status: true
                    });
                }
            });

    };
    //Functions End

    //Initials
    //Initials End
};

module.exports = function(app) {
    app.controller('deleteFolderCtrl', ['$scope', '$translate', '$modalInstance', 'folderFactory', 'selectedFolder', deleteFolderCtrl]);
};
