var subscriptionFactory = function (httpProvider) {
    var factoryModel = {};

    factoryModel.getAccountSubscription = function () {
        return httpProvider.get("subscription", "getAccountSubscription");
    };

    factoryModel.getAccountCurrentStatus = function () {
        return httpProvider.get("subscription", "getAccountCurrentStatus");
    };

    factoryModel.getSubscriptonModels = function () {
        return httpProvider.get("subscription", "getSubscriptonModels");
    };

    factoryModel.upgrade = function (model) {
        return httpProvider.post("subscription", "upgrade", model);
    };

    factoryModel.downgrade = function (model) {
        return httpProvider.post("subscription", "downgrade", model);
    };

    factoryModel.cancelSubscription = function (model) {
        return httpProvider.post("subscription", "cancelSubscription", model);
    };

    factoryModel.getAccountOrders = function () {
        return httpProvider.get('subscription', 'getAccountOrders');
    };

    factoryModel.getOrderById = function (id) {
        return httpProvider.get('subscription', 'getOrderById', "id=" + id);
    };

    factoryModel.canCreateDatasheet = function () {
        return httpProvider.get('subscription', 'canCreateDatasheet');
    };

    factoryModel.canPublishTemplate = function () {
        return httpProvider.get('subscription', 'canPublishTemplate');
    };

    factoryModel.canInviteUser = function () {
        return httpProvider.get('subscription', 'canInviteUser');
    };

    factoryModel.canEnableUser = function () {
        return httpProvider.get('subscription', 'canEnableUser');
    };

    factoryModel.checkSubscription = function () {
        return httpProvider.get('subscription', 'checkSubscription');
    };

    factoryModel.disableEnableData = function () {
        return httpProvider.get('subscription', 'disableEnableData');
    };

    factoryModel.createPayment = function (model) {
        return httpProvider.post('subscription', 'createPayment', model);
    };

    factoryModel.checkPaymentBIN = function (model) {
        return httpProvider.post('subscription', 'checkPaymentBIN', model);
    };

    factoryModel.createIyzicoCheckoutForm = function (model) {
        return httpProvider.post('subscription', 'createIyzicoCheckoutForm', model);
    };

    factoryModel.retrieveIyzicoCheckoutFormResult = function (model) {
        return httpProvider.post('subscription', 'retrieveIyzicoCheckoutFormResult', model);
    };

    factoryModel.upgradeSubscription = function (model) {
        return httpProvider.post('subscription', 'upgradeSubscription', model);
    };

    factoryModel.getCurrencyInfo = function () {
        return httpProvider.get('subscription', 'currencyInfo');
    };

    return factoryModel;
};


module.exports = function (app) {
    app.factory('subscriptionFactory', ['httpProvider', subscriptionFactory]);
};
