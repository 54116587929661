var subscriptionHttpInterceptor = function ($q, localStorageService, $injector, $rootScope) {
    return {
        'responseError': function (rejection) {
            if (rejection.status == 402) {

                var $modal = $injector.get('$modal');
                var modalInstance = $modal.open({
                    animation: true,
                    controller: 'subscriptionWarningModalCtrl',
                    templateUrl: '/app/components/subscription/shared/views/modals/subscriptionWarningModal.html',
                    resolve: {
                        model: function () {
                            return {
                                text: "SUBSCRIPTION.SHARED.MODALS.SUBSCRIPTION_WARNING.MESSAGE_TEMPLATE"
                            };
                        }
                    }
                });
            }
            return $q.reject(rejection);
        }
    };
};

module.exports = function (app) {
    app.factory('subscriptionHttpInterceptor', ['$q', 'localStorageService', '$injector', '$rootScope', subscriptionHttpInterceptor]);
};
