var datasheetPage = function($compile, datasheetViewService, datasheetViewCacheService, datasheetElementService, datasheetContextMenuService, datasheetContextMenuHandlerService) {
    return {
        restrict: 'E',
        replace: true,
        templateUrl: 'app/components/datasheet/shared/directives/datasheetPages/tpl/datasheetPage.html',
        link: function(scope, elem, attrs) {
            //Fields
            var containerElement = elem;
            //Fields End

            //Elements
            var element = scope.page;
            //Elements End

            //Inner Functions
            var init = function() {
                containerElement = $('#' + element.type + '-' + element._id);
            };

            var setHeaderBodyAndFooter = function() {
                //Header
                var headerId = scope.page.headerId;
                var header = _.find(scope.datasheetModel.elements, {
                    _id: headerId
                });

                var headerHtml = datasheetViewService.createDatasheetHeader();
                var headerChildScope = scope.$new();
                headerChildScope.header = header;
                $(elem).prepend($compile(headerHtml)(headerChildScope));

                //Body
                var bodyHtml = datasheetViewService.createDatasheetBody();
                var bodyChildScope = scope.$new();
                $(elem).find("datasheet-header").after($compile(bodyHtml)(bodyChildScope));

                //Footer
                var footerId = scope.page.footerId;
                var footer = _.find(scope.datasheetModel.elements, {
                    _id: footerId
                });

                var footerHtml = datasheetViewService.createDatasheetFooter();
                var footerChildScope = scope.$new();
                footerChildScope.footer = footer;
                $(elem).find("datasheet-body").after($compile(footerHtml)(footerChildScope))
            };

            var setFreeElements = function() {
                var pageId = scope.page._id;
                var freeElements = _.filter(scope.datasheetModel.elements, {
                    "pageId": pageId,
                    "isFree": true
                });

                for (var i = 0, len = freeElements.length; i < len; i++) {
                    var element = freeElements[i];
                    var elementHtml = datasheetViewService.createDatasheetElement(element.type);

                    var childScope = scope.$new();
                    childScope.element = element;
                    $(elem).append($compile(elementHtml)(childScope));
                }
            };

            var setPageStyle = function() {
                if (element.style != null && element.style != undefined)
                    containerElement.css(element.style);

                //Elementler gizli ise ilgili class ı ekleyelim.
                if (element.isHidden) {
                    containerElement.addClass('hidden-blur');
                }
            };

            var setContextMenu = function() {
                $.contextMenu({
                    selector: "#" + element.type + "-" + element._id,
                    reposition: false,
                    build: function($trigger, e) {
                        var page = element;

                        return {
                            callback: contextMenuClickHandler,
                            items: datasheetContextMenuService.createContextMenuItemsForPage(page)
                        };
                    }
                });
            };
            //Inner Functions End

            //Events
            //Events End

            //Handlers
            var contextMenuClickHandler = function(key, options) {
                datasheetContextMenuHandlerService
                    .handleContextMenuClick(key,
                    scope.selectionModel.showHiddenElements,
                    scope.templateModel,
                    scope.datasheetModel,
                    scope.page,
                    null,
                    null,
                    null,
                    null);
            };
            //Handlers End

            //Initials
            setHeaderBodyAndFooter();
            setFreeElements();
            setPageStyle();
            setContextMenu();
            //Initials End
        }
    }
};

module.exports = function(app) {
    app.directive('datasheetPage', ['$compile', 'datasheetViewService', 'datasheetViewCacheService', 'datasheetElementService', 'datasheetContextMenuService', 'datasheetContextMenuHandlerService', datasheetPage]);
};