var run = function ($rootScope, $translate) {
    //Fields
    //Fields End

    //Events
    $rootScope.$on('changedLanguage', function (event, data) {
        var languageCode = data.languageCode;
        $translate.use(languageCode);
    });
    $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
        // $rootScope.activeLang = toParams.lang;
        $translate.use(toParams.lang);
    });
    $rootScope.$on('$translatePartialLoaderStructureChanged', function () {
        console.log('$translatePartialLoaderStructure değişti');
        $translate.refresh();
    });


    //Events End
};


module.exports = function (app) {
    app.run(['$rootScope', '$translate', run]);
};
