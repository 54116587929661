var nonChromeAlert = function ($rootScope, $timeout, $translate, ngNotify) {
    return {
        restrict: 'E',
        link: function (scope, el, attrs) {

            //Events
            $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
                ngNotify.dismiss();
            });
            //Events End

            //Inner Functions
            var setNonChromeAlert = function () {
                var nonChromeMessage = $translate.instant('GENERAL.NON_CHROME_BROWSER_WARNING.TEXT');
                ngNotify.set(nonChromeMessage);
                $('.ngn').css('padding', '21.5px 80px');
            };
            //Inner Functions End

            //Initials
            ngNotify.config({
                    theme: 'prime',
                    position: 'top',
                    type: 'warn',
                    sticky: true,
                    button: true,
                    html: true
            });
            // Opera 8.0+
            var isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

            // Firefox 1.0+
            var isFirefox = typeof InstallTrigger !== 'undefined';

            // Safari 3.0+ "[object HTMLElementConstructor]"
            var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || safari.pushNotification);

            // Internet Explorer 6-11
            var isIE = /*@cc_on!@*/false || !!document.documentMode;

            // Edge 20+
            var isEdge = !isIE && !!window.StyleMedia;

            // Chrome 1+
            var isChrome = !!window.chrome && !!window.chrome.webstore;

            // Blink engine detection
            var isBlink = (isChrome || isOpera) && !!window.CSS;
            
            if (!isChrome || !isBlink) {
                setNonChromeAlert();
            }
            //Initials End
        }
    }
};

module.exports = function (app) {
    app.directive('nonChromeAlert', ['$rootScope', '$timeout', '$translate', 'ngNotify', nonChromeAlert]);
};
