var shareArchiveFileWithEmailCtrl = function ($rootScope, $scope, $timeout, $translate, $modalInstance, emailListService, localStorageService, archiveFactory, shareFactory, _, customArchiveId, archiveFile) {
    //Fields 
    $scope.currentStep = 1;
    $scope.emptyEmailGroupOption = $translate.instant("CUSTOMARCHIVES.YOURARCHIVES.MODALS.SHAREARCHIVEFILEWITHEMAIL.SLST_EMAIL_GROUPS.EMPTY_OPTION.TEXT");

    $scope.shareButton = "CUSTOMARCHIVES.YOURARCHIVES.MODALS.SHAREARCHIVEFILEWITHEMAIL.BTN_SHARE.TEXT";
    $scope.isProcessing = false;
    //Fields End

    //Functions
    $scope.cancel = function () {
        $modalInstance.dismiss('cancel');
    };

    $scope.goToStep2 = function () {
        $scope.errors = [];

        if (($scope.sharingModel.selectedEmailGroup === null || angular.isUndefined($scope.sharingModel.selectedEmailGroup) || $scope.sharingModel.selectedEmailGroup === $scope.emptyEmailGroupOption) && ($scope.sharingModel.customEmails === null || angular.isUndefined($scope.sharingModel.customEmails) || $scope.sharingModel.customEmails === "")) {
            $scope.errors.push("CUSTOMARCHIVES.YOURARCHIVES.MODALS.SHAREARCHIVEFILEWITHEMAIL.ERROR_MESSAGES.ADD_ONE_EMAIL");
        }

        if ($scope.sharingModel.selectedFields !== null) {
            var hasSelectedField = false;
            angular.forEach($scope.sharingModel.selectedFields, function (value, key) {
                if (value)
                    hasSelectedField = true;

            });

            if (hasSelectedField === false) {
                $scope.errors.push("CUSTOMARCHIVES.YOURARCHIVES.MODALS.SHAREARCHIVEFILEWITHEMAIL.ERROR_MESSAGES.SELECT_FIELD");
            }
        }

        if ($scope.errors.length > 0) {
            return;
        }

        $scope.sharingModel.archiveFileNameField = $scope.sharingModel.fields[0].name;
        $scope.currentStep = 2;
    };

    $scope.backToStep1 = function () {
        $scope.currentStep = 1;
    };

    $scope.goToStep3 = function () {
        var archiveFileField = _.find(archiveFile.fields, {
            "text": $scope.sharingModel.archiveFileNameField
        });

        $scope.sharingModel.subject = $translate.instant("CUSTOMARCHIVES.YOURARCHIVES.MODALS.SHAREARCHIVEFILEWITHEMAIL.SHAREEMAIL.SUBJECT");
        $scope.sharingModel.subject = $scope.sharingModel.subject.replace("$ArchiveFileName$", archiveFileField.value);
        $scope.sharingModel.message = $translate.instant("CUSTOMARCHIVES.YOURARCHIVES.MODALS.SHAREARCHIVEFILEWITHEMAIL.SHAREEMAIL.MESSAGE");
        $scope.sharingModel.message = $scope.sharingModel.message.replace("$ArchiveFileName$", archiveFileField.value);
        $scope.sharingModel.message = $scope.sharingModel.message.replace("$UserName$", $scope.sharingModel.userFullName);


        if($scope.mailTemplateMode === "custom")
                $scope.currentStep = 4;
        else
            $scope.currentStep = 3;
    };

    $scope.backToStep2 = function () {
        $scope.currentStep = 2;
    };

    $scope.share = function (isValid) {
        if (isValid) {
            $scope.shareButton = "CUSTOMARCHIVES.YOURARCHIVES.MODALS.SHAREARCHIVEFILEWITHEMAIL.BTN_SHARE.ALTERNATE_TEXT";
            $scope.isProcessing = true;
            var requestModel = {};
            requestModel.subject = $scope.sharingModel.subject;
            requestModel.message = $scope.sharingModel.message;
            requestModel.emailGroup = $scope.sharingModel.selectedEmailGroup;
            requestModel.customEmails = $scope.sharingModel.customEmails;
            requestModel.selectedFields = [];
            requestModel.archiveFileId = archiveFile._id;
            requestModel.templateId = $scope.sharingModel.selectedTemplateId;
            angular.forEach($scope.sharingModel.selectedFields, function (value, key) {
                if (value) {
                    requestModel.selectedFields.push(key);
                }
            });

            shareFactory.shareArchiveFileWithEmail(requestModel)
                .then(function (response) {
                    $scope.shareButton = "CUSTOMARCHIVES.YOURARCHIVES.MODALS.SHAREARCHIVEFILEWITHEMAIL.BTN_SHARE.TEXT";
                    $scope.isProcessing = false;

                    var message = $translate.instant("CUSTOMARCHIVES.YOURARCHIVES.MODALS.SHAREARCHIVEFILEWITHEMAIL.SUCCESS_MESSAGES.SUCCESSFUL_SHARE");
                    toastr.success(message, '', { positionClass: 'toast-bottom-full-width', timeOut: 3000 });

                    $timeout(function () {
                        $modalInstance.dismiss('cancel');
                    }, 500);
                });
        }
    };

    $scope.templateLanguageChanged = function(){
        $scope.sharingModel.selectedTemplateId = _.find($scope.mailTemplates, { language : $scope.sharingModel.selectedTemplateLanguage})._id;
    };
    //Functions End

    //Inner Functions
    var intialize = function () {
        initializeSharingModel(function () {
            getCustomArchiveById(customArchiveId);
            getAllEmailTags();
        });

        shareFactory.getMailTemplate('customArchiveShare').then(function(response){
            $scope.mailTemplates = response;
            if(response[0].sysDefault){
                $scope.mailTemplateMode = "sysDefault";
            }
            else{
                $scope.mailTemplateMode = "custom";
            }
            
            $scope.sharingModel.selectedTemplateLanguage = response[0].language;
            $scope.templateLanguageChanged();
        });
    };

    var initializeSharingModel = function (nextProcess) {
        $scope.sharingModel = {};
        $scope.sharingModel.emailGroups = [];
        $scope.sharingModel.selectedFields = {};

        var userInformationData = localStorageService.get('userInformationData');
        if (userInformationData) {
            $scope.sharingModel.userFullName = userInformationData.FirstName + ' ' + userInformationData.LastName;
        }

        nextProcess();
    };

    var getAllEmailTags = function () {
        $scope.sharingModel.emailGroups = [];
        emailListService.getAllEmailTags()
            .then(function (response) {
                //Empty Choice
                $scope.sharingModel.emailGroups.push($scope.emptyEmailGroupOption);

                var emailTags = response;
                for (var i = 0; i < emailTags.length; i++) {
                    var emailTag = emailTags[i];

                    $scope.sharingModel.emailGroups.push('' + emailTag + '');
                }

                $scope.sharingModel.selectedEmailGroup = $scope.emptyEmailGroupOption;
            });
    };

    var getCustomArchiveById = function (customArchiveId) {
        archiveFactory.getCustomArchiveById(customArchiveId)
            .then(function (response) {
                $scope.sharingModel.fields = response.fields;

                if ($scope.sharingModel.fields.length == 1) {
                    var firstField = $scope.sharingModel.fields[0];
                    $scope.sharingModel.selectedFields[firstField.name] = true;
                }
            });
    }
    //Inner Functions End

    //Initials 
    intialize();
    //Initials End
};

module.exports = function (app) {
    app.controller('shareArchiveFileWithEmailCtrl', ['$rootScope', '$scope', '$timeout', '$translate', '$modalInstance', 'emailListService', 'localStorageService', 'archiveFactory', 'shareFactory', '_', 'customArchiveId', 'archiveFile', shareArchiveFileWithEmailCtrl]);
};