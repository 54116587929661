var allUsersCtrl = function ($rootScope, $scope, $translate, $modal, userFactory, intercomFactory, jobTitleGroups, userStatusType) {
    //Fields
    $scope.view = {
        name: "Card"
    };
    //Fields End

    //Events
    $rootScope.$on('$translateChangeSuccess', function (data) { });
    //Events End

    //Inner Functions
    var getAllUsers = function () {
        $scope.tdsMakerPromise.allUsers = {};
        $scope.tdsMakerPromise.allUsers.message = "Loading All Users";
        $scope.tdsMakerPromise.allUsers.promise = userFactory.getAllUsers()
            .then(function (response) {
                $scope.users = response;
                for (var i = $scope.users.length - 1; i >= 0; i--) {
                    var user = $scope.users[i];

                    //Profile Picture
                    if (angular.isUndefined(user.ProfilePicture) || user.ProfilePicture == null) {
                        user.ProfilePicture = "assets/images/default-user.png";
                    }
                }
            });
    };

    //Inner Functions End

    //Function
    $scope.openRemindUserModal = function (userId) {
        var modalInstance = $modal.open({
            animation: false,
            templateUrl: 'app/components/users/allUsers/views/modals/remindUser.html',
            controller: 'remindUserCtrl',
            backdrop: 'static',
            resolve: {
                userId: function () {
                    return userId;
                }
            }
        });

        modalInstance.result
            .then(function (result) {
                if (result.status) {
                    var message = $translate.instant("USER.ALLUSERS.MODALS.REMINDUSER.LBL_REMINDED.TEXT");
                    toastr.success(message, '', { positionClass: 'toast-bottom-full-width', timeOut: 3000 });
                }
            });
    };

    $scope.openInviteUserModal = function () {
        userFactory.openInviteUserModal()
            .then(function (response) {
                if (response.status) {
                    getAllUsers();
                };
            });
    };

    $scope.openUserGroupsModal = function (userId) {
        var modalInstance = $modal.open({
            animation: false,
            controller: 'userGroupsCtrl',
            templateUrl: 'app/components/users/allUsers/views/modals/userGroups.html',
            backdrop: 'static',
            resolve: {
                userId: function () {
                    return userId;
                }
            }
        });

        modalInstance.result
            .then(function (response) {
                if (response.status) { };
            });
    };

    $scope.openMakeDisableModal = function (userId) {
        var modalInstance = $modal.open({
            animation: false,
            controller: 'makeDisableCtrl',
            templateUrl: 'app/components/users/allUsers/views/modals/makeDisable.html',
            backdrop: 'static',
            resolve: {
                userId: function () {
                    return userId;
                }
            }
        });

        modalInstance.result
            .then(function (result) {
                if (result.status) {
                    getAllUsers();
                }
            });
    };

    $scope.openMakeEnableModal = function (userId) {
        userFactory.openMakeEnableModal(userId)
            .then(function (response) {
                if (response.status) {
                    getAllUsers();
                };
            });

    };

    $scope.openDeleteUserModal = function (userId) {
        var modalInstance = $modal.open({
            animation: false,
            controller: 'deleteUserCtrl',
            templateUrl: 'app/components/users/allUsers/views/modals/deleteUser.html',
            backdrop: 'static',
            resolve: {
                userId: function () {
                    return userId;
                }
            }
        });

        modalInstance.result
            .then(function (result) {
                if (result.status) {
                    getAllUsers();
                }
            });
    };

    $scope.changeView = function (viewName) {
        $scope.view.name = viewName;
    }

    $scope.getUserStatus = function (userStatus) {
        return 'USER.ALLUSERS.' + userStatusType[userStatus].Code + ".VALUE";
    };

    $scope.getUserJobTitle = function (userJobTitle) {

        for (var i = 0; i < jobTitleGroups.length; i++) {
            var jobTitleGroup = jobTitleGroups[i];
            for (var j = 0; j < jobTitleGroup.JobTitles.length; j++) {
                var jobTitle = jobTitleGroup.JobTitles[j];
                if (jobTitle.Name == userJobTitle) {
                    return $translate.instant("USER.USERPROFILE.PARTIALS.USERINFORMATIONS.CONSTANTS.JOBTITLEGROUPS." + jobTitleGroup.LanguageCode + ".JOBTITLES." + jobTitle.LanguageCode + ".TEXT");
                }

            }
        }

        return "";
    };
    //Function End

    //Initials
    getAllUsers();
    intercomFactory.addEvent({
        event_name: 'Users Page Visited'
    });
    //Initials End
};

module.exports = function (app) {
    app.controller('allUsersCtrl', ['$rootScope', '$scope', '$translate', '$modal', 'userFactory', 'intercomFactory', 'jobTitleGroups', 'userStatusType', allUsersCtrl]);
};
