var pdfTableCell = function ($compile, pdfViewService, _) {
    return {
        restrict: 'A',
        link: function (scope, elem, attrs) {
            //Fields 
            //Fields End

            //Elements
            //Elements End

            //Inner Functions

            var setElementStyle = function () {
                var newStyle = scope.cell.style;
                if (newStyle.borderLeftWidth == "0px") {
                    newStyle.borderLeft = "none";
                }

                if (newStyle.borderTopWidth == "0px") {
                    newStyle.borderTop = "none";
                }

                if (newStyle.borderRightWidth == "0px") {
                    newStyle.borderRight = "none";
                }

                if (newStyle.borderBottomWidth == "0px") {
                    newStyle.borderBottom = "none";
                }

                elem.css(newStyle);
                elem.attr("colspan", scope.cell.colSpan);
                elem.attr("rowspan", scope.cell.rowSpan);
            };

            var setCellElements = function () {
                var cellId = scope.cell._id;
                var elements = _.filter(scope.pdfModel.elements, {
                    parentId: cellId
                });
                elements = _.sortBy(elements, 'order');

                for (var i = 0, len = elements.length; i < len; i++) {
                    var element = elements[i];
                    var elementHtml = pdfViewService.createPdfElement(element.type);

                    var childScope = scope.$new();
                    childScope.element = element;
                    $(elem).find("div").first().append($compile(elementHtml)(childScope));
                }
            };
            //Inner Functions End

            //Initials 
            setElementStyle();
            setCellElements();
            //Initials End
        }
    }
};

module.exports = function (app) {
    app.directive('pdfTableCell', ['$compile', 'pdfViewService', '_', pdfTableCell]);
}