var htmlSafe = function($sce) {
    return function(htmlCode) {
        
        return $sce.trustAsHtml(htmlCode);
    };
}

module.exports = function(app) {
    app.filter("htmlSafe", ['$sce', htmlSafe]);
};
