var froalaEditorService = function() {
    //Fields
    var serviceModel = {};
    //Fields - End

    //Functions
    serviceModel.initTemplateTextFroalaEditor = function(elementId, target, contentChanged) {
        var templateTextElement = $('#' + elementId).find(target);
        if (templateTextElement.data('froala.editor')) {
            templateTextElement.froalaEditor('destroy');
        }

        $.FroalaEditor.DefineIconTemplate('material_design', '<i class="zmdi zmdi-[NAME]"></i>');
        $.FroalaEditor.DefineIcon('fields_dropdown', {
            NAME: 'label',
            template: 'material_design'
        });

        $.FroalaEditor.RegisterCommand('fields_dropdown', {
            title: 'Fields',
            type: 'dropdown',
            plainPaste: true,
            pasteDeniedTags: ['a', 'abbr', 'address', 'area', 'article', 'aside', 'audio', 'b', 'base', 'bdi', 'bdo', 'blockquote', 'br', 'button', 'canvas', 'caption', 'cite', 'code', 'col', 'colgroup', 'datalist', 'dd', 'del', 'details', 'dfn', 'dialog', 'div', 'dl', 'dt', 'em', 'embed', 'fieldset', 'figcaption', 'figure', 'footer', 'form', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'header', 'hgroup', 'hr', 'i', 'iframe', 'img', 'input', 'ins', 'kbd', 'keygen', 'label', 'legend', 'li', 'link', 'main', 'map', 'mark', 'menu', 'menuitem', 'meter', 'nav', 'noscript', 'object', 'ol', 'optgroup', 'option', 'output', 'p', 'param', 'pre', 'progress', 'queue', 'rp', 'rt', 'ruby', 's', 'samp', 'script', 'style', 'section', 'select', 'small', 'source', 'span', 'strike', 'strong', 'sub', 'summary', 'sup', 'table', 'tbody', 'td', 'textarea', 'tfoot', 'th', 'thead', 'time', 'title', 'tr', 'track', 'u', 'ul', 'var', 'video', 'wbr'],
            focus: false,
            undo: false,
            refreshAfterCallback: true,
            options: {
                '#Product-Name#': "productName",
                '#Product-Number#': "productNumber",
                '#Publish-Date#': "publishDate",
                '#Revision-Number#': "revisionNumber",
                '#Page-No#': "pageNo",
                '#Total-Page#': "totalPage"
            },
            callback: function(cmd, val) {
                this.html.insert(val);
            }
        });

        var htmlTag = '<div id="froalaPanel-' + elementId + '" class="froala-panel" style="width:38px; float:left; margin-right:5px;"></div>';

        if ($('#froalaToolbarContainer').has('#froalaPanel-' + elementId).length == 0)
            $('#froalaToolbarContainer').append(htmlTag);

        var placeHolder = "PlaceHolder";
        var templateTextFroalaOptions = {
            imagePaste: false,
            inlineMode: false,
            plainPaste: true,
            theme: 'custom',
            placeholderText: placeHolder,
            toolbarInline: false,
            toolbarContainer: '#froalaPanel-' + elementId,
            toolbarButtons: ['fields_dropdown'],
            events: {
                'froalaEditor.paste.initialized': function(e, editor) {},
                'froalaEditor.focus': function() {},
                'froalaEditor.blur': function() {}
            }
        };

        //İlgili kontrolu froalaEditoru yükle,
        templateTextElement.froalaEditor(templateTextFroalaOptions);

        //Tüm froalaPanel leri gizle,
        $('.froala-panel').hide();

        //İlgili froala panel i göster,
        $('#froalaPanel-' + elementId).show();

        //İlgili Cursor u son satıra gönder,
        var editor = templateTextElement.data('froala.editor');
        editor.$el.focus();
        editor.selection.setAtEnd(editor.$el.get(0));
        editor.selection.restore();

        templateTextElement.on('froalaEditor.contentChanged', contentChanged);
    };
    //Functions End

    //Inner Functions

    //Inner Function End

    return serviceModel;
};

module.exports = function(app) {
    app.service('froalaEditorService', [froalaEditorService]);
};
