var analyticFactory = function(httpProvider) {
    //Fields
    var factoryModel = {};
    //Fields End

    //Inner Functions
    factoryModel.getPDFViewsData = function() {
        return httpProvider.get('analytic', 'getPDFViewsData');
    };

    factoryModel.getActivities = function() {
        return httpProvider.get('analytic', 'getActivities');
    };

    factoryModel.getActivitiesByUserId = function(userId) {
        var params = "userId=" + userId;
        return httpProvider.get('analytic', 'getActivitiesByUserId', params);
    };

    factoryModel.getMajorPDFViews = function(month, year) {
        var params = "month=" + month + "&year=" + year;
        return httpProvider.get('analytic', 'getMajorPDFViews', params);
    };
    //Inner Functions End

    return factoryModel;
};

module.exports = function(app) {
    app.factory('analyticFactory', ['httpProvider', analyticFactory]);
};
