var datasheetInputText = function($modal, $translate, datasheetElementService, datasheetContextMenuService, datasheetContextMenuHandlerService, googleTranslate, htmlTagCleaner, uploadFactory, _) {
    return {
        restrict: 'E',
        replace: true,
        templateUrl: 'app/components/datasheet/shared/directives/elements/datasheetInputText/datasheetInputText.html',
        link: function(scope, elem, attrs) {
            //Fields
            var containerElement = elem;
            var innerElement = elem.find('.inner-element');

            var tSetDatasheetElementModel;
            //Fields End

            //Elements
            var element = scope.element;
            //Elements End

            //Inner Functions
            var init = function() {
                containerElement = $('#' + scope.element.type + '-' + scope.element._id);
                innerElement = containerElement.find(".inner-element");
            };

            var deferInit = function() {
                _.defer(function() {
                    addUsefulClass();
                    setElementStyle();
                    convertToFroalaEditor();
                    setElementValue();
                    setContextMenu();
                    setTooltip();
                });
            };

            var addUsefulClass = function() {
                containerElement.addClass("container-element changeable-content inputtext-" + element._id + "");
            };

            var setElementStyle = function() {
                containerElement.css(element.containerStyle);
                innerElement.css(element.style);

                //Elementler gizli ise ilgili class ı ekleyelim.
                if (element != null && element != undefined) {
                    if (element.isHidden) {
                        containerElement.addClass('hidden-blur');
                    }
                }
            };

            var convertToFroalaEditor = function() {
                $.FroalaEditor.DefineIconTemplate('material_design', '<i class="zmdi zmdi-[NAME]"></i>');

                var toolbarButtonsArray = ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', '|', 'formatOL', 'formatUL', '|', 'insertTable', ];

                //CUSTOM IMAGE BUTTON
                $.FroalaEditor.DefineIcon('material_image', {
                    NAME: 'image-o',
                    template: 'material_design'
                });
                $.FroalaEditor.RegisterCommand('image-' + element._id, {
                    title: $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETINPUTTEXT.LBL_ADD_IMAGE.TEXT"),
                    icon: 'material_image',
                    focus: true,
                    callback: imageHandler
                });
                toolbarButtonsArray.push('image-' + element._id);
                toolbarButtonsArray.push('selectAll');

                if (scope.templateModel.template.languages.length > 1) {
                    //TRANSLATE BUTTON
                    $.FroalaEditor.DefineIcon('material_translate', {
                        NAME: 'translate',
                        template: 'material_design'
                    });
                    $.FroalaEditor.RegisterCommand('translate-' + element._id, {
                        title: $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETINPUTTEXT.LBL_TRANSLATE.TEXT"),
                        icon: 'material_translate',
                        focus: true,
                        callback: translateHandler
                    });
                    toolbarButtonsArray.push('translate-' + element._id);

                    //COPY BUTTON
                    $.FroalaEditor.DefineIcon('material_copy', {
                        NAME: 'copy',
                        template: 'material_design'
                    });
                    $.FroalaEditor.RegisterCommand('copy-' + element._id, {
                        title: $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETINPUTTEXT.LBL_COPY.TEXT"),
                        icon: 'material_copy',
                        focus: true,
                        callback: copyHandler
                    });
                    toolbarButtonsArray.push('copy-' + element._id);
                }

                var froalaEditorHtml = '<div id="froalaEditor-' + element._id + '" style="float:left; margin-right:5px;display:none;"></div>';

                if ($('#froalaToolbarContainer').has('#froalaEditor-' + element._id).length == 0)
                    $('#froalaToolbarContainer').append(froalaEditorHtml);
                else
                    $('#froalaEditor-' + element._id).empty();

                var placeholder = datasheetElementService.getInputTextPlaceHolder(element, scope.selectionModel.selectedLanguage);
                innerElement.froalaEditor({
                    key: '3cA-16jwzwxlE2cno==',
                    language: scope.activeLang,
                    theme: 'custom',
                    imagePaste: false,
                    inlineMode: false,
                    plainPaste: true,
                    toolbarInline: false,
                    placeholderText: placeholder,
                    toolbarButtons: toolbarButtonsArray,
                    toolbarContainer: '#froalaEditor-' + element._id
                });
                innerElement.on('froalaEditor.contentChanged', contentChangedHandler);
                innerElement.on('froalaEditor.blur', function(e, editor) {
                    $("#froalaEditor-" + element._id).hide();
                });
                innerElement.on('froalaEditor.focus', function(e, editor) {
                    $("#froalaEditor-" + element._id).show();
                });
                innerElement.on('froalaEditor.keyup', function(e, editor, keyupEvent) {
                    if (keyupEvent.keyCode == 9 || keyupEvent.which == 9) {
                        var editorHtml = editor.$el;
                        if ($(editorHtml).find("table").length > 0)
                            event.stopPropagation();
                    }
                });
            };

            var destroyFroalaEditor = function() {
                innerElement.froalaEditor('destroy');
            };

            var setElementValue = function() {
                innerElement.froalaEditor('html.set', element.values[scope.selectionModel.selectedLanguage]);
            };

            var setPlaceHolder = function() {
                var placeholder = datasheetElementService.getInputTextPlaceHolder(element, scope.selectionModel.selectedLanguage);

                innerElement.find('.fr-placeholder').replaceWith('<span class="fr-placeholder" style="font-size: 12px; line-height: 17.3333px; margin-top: 0px; padding-top: 0px; padding-left: 0px; margin-left: 0px; padding-right: 0px; margin-right: 0px; text-align: justify;">' + placeholder + '</span>')
            };

            var setContextMenu = function() {
                $.contextMenu({
                    selector: "#" + element.type + "-" + element._id,
                    build: function($trigger, e) {
                        var page = scope.page;
                        var table = scope.table;
                        var row = scope.row;
                        var cell = scope.cell;
                        var element = scope.element;
                        var languageCount = scope.templateModel.template.languages.length;

                        return {
                            callback: contextMenuClickHandler,
                            items: datasheetContextMenuService.createContextMenuItemsForInputText(page, table, row, cell, element, languageCount)
                        };
                    }
                });
            };

            var setTooltip = function() {
                var tooltip = datasheetElementService.getInputTextTooltip(scope.selectionModel.selectedLanguage, element);
                containerElement.tooltip('dispose');
                containerElement.tooltip({
                    position: "bottom left",
                    opacity: 0.7,
                    title: tooltip
                });
                containerElement.unbind('mouseenter');
                containerElement.bind('mouseenter', function() {
                    containerElement.tooltip({
                        trigger: 'manual'
                    }).tooltip('show');
                });
                containerElement.unbind('mouseleave');
                containerElement.bind('mouseleave', function() {
                    containerElement.tooltip({
                        trigger: 'manual'
                    }).tooltip('hide');
                });
            };

            var translateToOtherLangauges = function(text, language, targetLanguages, callback) {
                var isOtherLanguageElementFill = false;
                for (var l = 0; l < targetLanguages.length; l++) {
                    var targetLanguage = targetLanguages[l];
                    if (targetLanguage != language) {
                        var otherLanguageValue = element.values[targetLanguages[l]];
                        if (otherLanguageValue != "") {
                            isOtherLanguageElementFill = true;
                            break;
                        }
                    }
                }

                if (isOtherLanguageElementFill) {
                    var modalInstance = $modal.open({
                        animation: false,
                        controller: 'translateWarningCtrl',
                        templateUrl: '/app/components/datasheet/shared/directives/elements/datasheetInputText/views/modals/translateWarning.html',
                        backdropClass: "static"
                    });

                    modalInstance.result.then(function(response) {
                        if (response.status) {
                            googleTranslate.translate(text, language, targetLanguages)
                                .then(function(response) {
                                    //Yazılan metni diğer dile otomatik olarak çevir.
                                    for (var tl = 0, len = response.TranslateList.length; tl < len; tl++) {
                                        var translateItem = response.TranslateList[tl];
                                        if (scope.selectionModel.selectedLanguage != translateItem.Language) {
                                            element.values[translateItem.Language] = translateItem.Text;
                                        }
                                    }

                                    callback();
                                });
                        }
                    });
                } else {
                    googleTranslate.translate(text, language, targetLanguages)
                        .then(function(response) {
                            //Yazılan metni diğer dile otomatik olarak çevir.
                            for (var tl = 0, len = response.TranslateList.length; tl < len; tl++) {
                                var translateItem = response.TranslateList[tl];
                                if (scope.selectionModel.selectedLanguage != translateItem.Language) {
                                    element.values[translateItem.Language] = translateItem.Text;
                                }
                            }

                            callback();
                        });
                }
            };

            var copyToOtherLanguages = function() {
                var html = innerElement.froalaEditor('html.get');
                var cleanHtml = htmlTagCleaner.clear(html);
                var templateLanguages = scope.templateModel.template.languages;
                var selectedLanguage = scope.selectionModel.selectedLanguage;

                if (cleanHtml != "") {
                    var isOtherLanguageElementFill = false;
                    for (var l = 0; l < templateLanguages.length; l++) {
                        var targetLanguage = templateLanguages[l];
                        if (targetLanguage != selectedLanguage) {
                            var otherLanguageValue = element.values[targetLanguage];
                            if (otherLanguageValue != "") {
                                isOtherLanguageElementFill = true;
                                break;
                            }
                        }
                    }

                    if (isOtherLanguageElementFill) {
                        var modalInstance = $modal.open({
                            animation: false,
                            controller: 'copyWarningCtrl',
                            templateUrl: '/app/components/datasheet/shared/directives/elements/datasheetInputText/views/modals/copyWarning.html',
                            backdropClass: "static"
                        });

                        modalInstance.result.then(function(response) {
                            if (response.status) {
                                //Yazılan metni diğer dillere kopyala
                                for (var tl = 0, len = scope.templateModel.template.languages.length; tl < len; tl++) {
                                    var templateLanguage = scope.templateModel.template.languages[tl];
                                    if (scope.selectionModel.selectedLanguage != templateLanguage) {
                                        element.values[templateLanguage] = html;
                                    }
                                }

                                var message = $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETINPUTTEXT.SUCCESS_MESSAGES.COPIED_SUCCESSFULLY");
                                toastr.success(message, '', {
                                    positionClass: 'toast-bottom-right',
                                    timeOut: 3000
                                });
                            }
                        });

                    } else {
                        //Yazılan metni diğer dillere kopyala
                        for (var tl = 0, len = scope.templateModel.template.languages.length; tl < len; tl++) {
                            var templateLanguage = scope.templateModel.template.languages[tl];
                            if (scope.selectionModel.selectedLanguage != templateLanguage) {
                                element.values[templateLanguage] = html;
                            }
                        }

                        var message = $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETINPUTTEXT.SUCCESS_MESSAGES.COPIED_SUCCESSFULLY");
                        toastr.success(message, '', {
                            positionClass: 'toast-bottom-right',
                            timeOut: 3000
                        });
                    }
                }
            };
            //Inner Functions End

            //Events
            containerElement.bind("keyup", function(event) {
                var keyCode = event.keyCode || event.which;
                if (keyCode == 9) {
                    var $inputTexts = $("[class*='inputtext']:not([class*='hidden-blur'])");
                    if (event.shiftKey) {
                        event.stopPropagation();
                    } else {
                        var $nextTextBox = $inputTexts.eq($inputTexts.index(this) + 1);
                        $nextTextBox.trigger('customFocus');
                    }
                }
            });

            containerElement.bind('customFocus', function(event, data) {
                containerElement.find(".inner-element").froalaEditor('events.focus');
            });

            containerElement.on('datasheetLanguageChanged', function(e, data) {
                _.defer(function(text) {
                    setElementValue();
                    setPlaceHolder();
                    setTooltip();
                }, 'deferred');

            });
            //Events End

            //Handlers
            var contextMenuClickHandler = function(key, options) {
                if (key == "translateElement") {
                    init();

                    var html = innerElement.froalaEditor('html.get');
                    translateToOtherLangauges(html, scope.selectionModel.selectedLanguage, scope.templateModel.template.languages, function() {
                        var message = $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETINPUTTEXT.SUCCESS_MESSAGES.TRANSLATED_SUCCESSFULLY");
                        toastr.success(message, '', {
                            positionClass: 'toast-bottom-right',
                            timeOut: 3000
                        });
                    });
                } else if (key == "copyElement") {
                    init();

                    copyToOtherLanguages();
                } else {
                    datasheetContextMenuHandlerService
                        .handleContextMenuClick(key,
                            scope.selectionModel.showHiddenElements,
                            scope.templateModel,
                            scope.datasheetModel,
                            scope.page,
                            scope.table,
                            scope.row,
                            scope.cell,
                            scope.element);
                }
            };

            var translateHandler = function() {
                var html = innerElement.froalaEditor('html.get');





                translateToOtherLangauges(html, scope.selectionModel.selectedLanguage, scope.templateModel.template.languages, function() {
                    var message = $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETINPUTTEXT.SUCCESS_MESSAGES.TRANSLATED_SUCCESSFULLY");
                    toastr.success(message, '', {
                        positionClass: 'toast-bottom-right',
                        timeOut: 3000
                    });
                });
            };

            var copyHandler = function() {
                copyToOtherLanguages();
            };

            var contentChangedHandler = function(e, editor) {
                clearTimeout(tSetDatasheetElementModel);

                //İşlem yapmayı bıraktıktan 100 milisaniye sonra editor de yazan değeri model e kopyala.
                tSetDatasheetElementModel = window.setTimeout(function() {
                    var html = editor.$el.html();
                    element.values[scope.selectionModel.selectedLanguage] = html;
                    clearTimeout(tSetDatasheetElementModel);
                }, 100);
            };

            var imageHandler = function() {
                uploadFactory.uploadImage(function(imageSrc) {
                    var jsImageOnLoad = function() {
                        var width = this.width;
                        var height = this.height;

                        var newImageHtml = '<img src="' + imageSrc + '" class="fr-fil fr-dib fr-draggable" >';
                        innerElement.find(".fr-element").append(newImageHtml);
                    };

                    var img = new Image();
                    img.onload = jsImageOnLoad;
                    img.src = imageSrc;
                });
            };
            //Handlers End

            //Initials
            deferInit();
            //Initials End
        }
    };
};

module.exports = function(app) {
    app.directive('datasheetInputText', ['$modal', '$translate', 'datasheetElementService', 'datasheetContextMenuService', 'datasheetContextMenuHandlerService', 'googleTranslate', 'htmlTagCleaner', 'uploadFactory', '_', datasheetInputText]);
};
