var datasheetViewCacheService = function ($compile, datasheetViewService, _) {
    var serviceModel = {};
    var compiledViews = [];

    serviceModel.getCompiledDatasheetView = function (elementType) {
        var compiledView = _.find(compiledViews, {
            type: elementType
        });

        if (!compiledView) {
            var elementHtml = "";

            if (elementType == "page") {
                elementHtml = datasheetViewService.createDatasheetPage();
            } else if (elementType == "header") {
                elementHtml = datasheetViewService.createDatasheetHeader();
            } else if (elementType == "footer") {
                elementHtml = datasheetViewService.createDatasheetFooter();
            } else if (elementType == "body") {
                elementHtml = datasheetViewService.createDatasheetBody();
            } else {
                elementHtml = datasheetViewService.createDatasheetElement(elementType);
            }

            compiledView = {
                type: elementType,
                compile: $compile(elementHtml)
            };

            compiledViews.push(compiledView);
        }

        return compiledView.compile;

    };

    serviceModel.getCompiledDatasheetLayout = function (layout) {
        var compiledView = _.find(compiledViews, {
            type: layout
        });

        if (!compiledView) {
            var elementHtml = datasheetViewService.createDatasheetLayout(layout);

            compiledView = {
                type: layout,
                compile: $compile(elementHtml)
            };

            compiledViews.push(compiledView);
        }

        return compiledView.compile;
    };

    return serviceModel;
};

module.exports = function (app) {
    app.service('datasheetViewCacheService', ['$compile', 'datasheetViewService', '_', datasheetViewCacheService]);
};