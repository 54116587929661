var editUserCtrl = function($scope,$state,$stateParams) {
  //Fields
  //Fields End

  //Inner Functions
  //Inner Functions End

  //Functions
  //Functions End

  //Initials
  //Initials End
};

module.exports = function(app) {
  app.controller('editUserCtrl', ['$scope','$state','$stateParams', editUserCtrl])
};
