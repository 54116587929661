var uiRooter = function ($stateProvider, $urlRouterProvider, $locationProvider) {
    $stateProvider
        .state('app.accountSubscription', {
            url: '/account-subscription/:token?',
            templateUrl: 'app/components/subscription/accountSubscription/views/accountSubscription.html',
            controller: "accountSubscriptionCtrl",
            permission: {
                requiredPermissions: ['View Subscription']
            }
        })
        .state('app.changePlan', {
            url: '/change-plan',
            templateUrl: 'app/components/subscription/changePlan/views/changePlan.html',
            controller: "changePlanCtrl",
            onEnter: ['menuToggleFactory', function (menuToggleFactory) {
                menuToggleFactory.collapse();
            }],
            onExit: ['menuToggleFactory', function (menuToggleFactory) {
                menuToggleFactory.doPreviousStatus();
            }]
        })
        .state('app.newPlan', {
            url: '/new-plan',
            params: {
                selectedPlan: null
            },
            templateUrl: 'app/components/subscription/newPlan/views/newPlan.html',
            controller: "newPlanCtrl",
            onEnter: ['menuToggleFactory', function (menuToggleFactory) {
                menuToggleFactory.collapse();
            }],
            onExit: ['menuToggleFactory', function (menuToggleFactory) {
                menuToggleFactory.doPreviousStatus();
            }]
        })
        .state('app.orderDetails', {
            url: '/order-details/:id',
            templateUrl: 'app/components/subscription/orders/views/orderDetails.html',
            controller: "orderDetailsCtrl",
            onEnter: ['menuToggleFactory', function (menuToggleFactory) {
                menuToggleFactory.collapse();
            }],
            onExit: ['menuToggleFactory', function (menuToggleFactory) {
                menuToggleFactory.doPreviousStatus();
            }]
        })
        .state('app.cancelSubscription', {
            url: '/cancel-subscription',
            templateUrl: 'app/components/subscription/cancelSubscription/views/cancelSubscription.html',
            controller: "cancelSubscriptionCtrl",
            onEnter: ['menuToggleFactory', function (menuToggleFactory) {
                menuToggleFactory.collapse();
            }],
            onExit: ['menuToggleFactory', function (menuToggleFactory) {
                menuToggleFactory.doPreviousStatus();
            }]
        });
};

module.exports = function (app) {
    app.config(['$stateProvider', '$urlRouterProvider', '$locationProvider', uiRooter]);
};
