var datasheetsExportCtrl = function($scope, $translate, localStorageService, datasheetFactory) {
    //Fields
    var datasheetNameTitle = $translate.instant('DATASHEET.YOURDATASHEETS.PARTIALS.DATASHEETSEXPORT.TBL_DATASHEETS.DATASHEET_NAME');
    var productNameTitle = $translate.instant('DATASHEET.YOURDATASHEETS.PARTIALS.DATASHEETSEXPORT.TBL_DATASHEETS.PRODUCT_NAME');
    var productNumberTitle = $translate.instant('DATASHEET.YOURDATASHEETS.PARTIALS.DATASHEETSEXPORT.TBL_DATASHEETS.PRODUCT_NUMBER');
    var productNumberNullTitle = $translate.instant('DATASHEET.YOURDATASHEETS.PARTIALS.DATASHEETSEXPORT.TBL_DATASHEETS.PRODUCT_NUMBER_NULL');
    var versionTitle = $translate.instant('DATASHEET.YOURDATASHEETS.PARTIALS.DATASHEETSEXPORT.TBL_DATASHEETS.VERSION');
    var isPublishedTitle = $translate.instant('DATASHEET.YOURDATASHEETS.PARTIALS.DATASHEETSEXPORT.TBL_DATASHEETS.IS_PUBLISHED.TITLE');
    var answerTrue = $translate.instant('DATASHEET.YOURDATASHEETS.PARTIALS.DATASHEETSEXPORT.TBL_DATASHEETS.IS_PUBLISHED.TRUE');
    var answerFalse = $translate.instant('DATASHEET.YOURDATASHEETS.PARTIALS.DATASHEETSEXPORT.TBL_DATASHEETS.IS_PUBLISHED.FALSE');
    var folderNameTitle = $translate.instant('DATASHEET.YOURDATASHEETS.PARTIALS.DATASHEETSEXPORT.TBL_DATASHEETS.FOLDER_NAME');
    var archiveFolderTitle = $translate.instant('DATASHEET.YOURDATASHEETS.LBL_ARCHIVE.TEXT');
    var createdByTitle = $translate.instant('DATASHEET.YOURDATASHEETS.PARTIALS.DATASHEETSEXPORT.TBL_DATASHEETS.CREATED_BY');
    var createdAtTitle = $translate.instant('DATASHEET.YOURDATASHEETS.PARTIALS.DATASHEETSEXPORT.TBL_DATASHEETS.CREATED_AT');
    var modifiedByTitle = $translate.instant('DATASHEET.YOURDATASHEETS.PARTIALS.DATASHEETSEXPORT.TBL_DATASHEETS.MODIFIED_BY');
    var modifiedAtTitle = $translate.instant('DATASHEET.YOURDATASHEETS.PARTIALS.DATASHEETSEXPORT.TBL_DATASHEETS.MODIFIED_AT');
    $scope.isLoading = false;
    //Fields End

    //Functions
    
    $scope.exportDatasheets = function () {
        $('#icndatasheetExportInProgress').show(); // bu alan animasyon olurken scope güncellenmesine rağmen butonun gizlenmesi durumuna karşın aşağıda da hide versiyonu var okuyunuz
        $scope.isLoading = true;
        var accountId = localStorageService.get('userInformationData').AccountId._id;
        datasheetFactory.getAllDatasheetsByAccountId(accountId)
            .then(function (response) {
                var table = '<html>'; //özel karakter sorunu için html formatında olmalı
                        table += '<head>';
                            table += '<meta http-equiv="content-type" content="text/html; charset=UTF-8" />';
                        table += '</head>';
                        table += '<body>';
                            table += '<table>';
                                table += '<tr>';
                                    table += '<td WIDTH="300">' + datasheetNameTitle + '</td>';
                                    table += '<td>' + productNameTitle + '</td>';
                                    table += '<td>' + productNumberTitle + '</td>';
                                    table += '<td>' + versionTitle + '</td>';
                                    table += '<td>' + isPublishedTitle + '</td>';
                                    table += '<td>' + folderNameTitle + '</td>';
                                    table += '<td>' + createdByTitle + '</td>';
                                    table += '<td>' + createdAtTitle + '</td>';
                                    table += '<td>' + modifiedByTitle + '</td>';
                                    table += '<td>' + modifiedAtTitle + '</td>';
                                table += '</tr>';
                            for (var i in response) {
                                table += '<tr>';
                                    var datasheetName = _.find(response[i].names, function(name){ return name.language == response[i].primaryLanguage; });
                                    table += '<td>' + datasheetName.name + '</td>';
                                    table += '<td>' + response[i].productName + '</td>';
                                    if (response[i].productNumber == undefined || response[i].productNumber == null) { 
                                        var productNumber = productNumberNullTitle;
                                    } else {
                                        var productNumber = response[i].productNumber;
                                    }
                                    table += '<td>' + productNumber + '</td>';
                                    table += '<td>' + response[i].majorVersion + '.' + response[i].minorVersion + '</td>';
                                    console.log(response[i].isPublished);
                                    if (response[i].isPublished != undefined || response[i].isPublished != null) { 
                                        var isPublished = response[i].isPublished.toString().replace("true", answerTrue).replace("false", answerFalse); 
                                    } else {
                                        var isPublished = answerFalse;
                                    }
                                    table += '<td>' + isPublished + '</td>';
                                    var folderName = response[i].folderName.replace("Archive", archiveFolderTitle);
                                    table += '<td>' + folderName + '</td>';
                                    table += '<td>' + response[i].createdByFullName + '</td>';
                                    table += '<td>' + moment(response[i].dateCreated).format('DD.MM.YYYY, HH:mm') + '</td>';
                                    table += '<td>' + response[i].modifiedByFullName + '</td>';
                                    table += '<td>' + moment(response[i].dateModified).format('DD.MM.YYYY, HH:mm') + '</td>';
                                table += '</tr>';
                            }
                            table += '</table>';
                        table += '</body>';
                    table += '</html>';
                
                
                var data_type = 'data:application/vnd.ms-excel;charset=UTF-8';
                var table_html = table.replace(/ /g, '%20');

                var a = document.createElement('a');
                a.href = data_type + ', ' + table_html;
                a.download = 'tdsmaker_datasheets-' + moment(new Date()).format('DD.MM.YYYY-HH:mm') + '.xls';
                a.click();
                $('#icndatasheetExportInProgress').hide(); // animasyon sürerken scope güncellense bile button ng-if ile gizlenmiyodu bu onu engellemek ve butonu saklamak için. yukarısı da tekrar göstermek için
                $scope.isLoading = false;
            });
    };
    //Functions End

    //Initials
    //Initials End
};

module.exports = function(app) {
    app.controller('datasheetsExportCtrl', ['$scope', '$translate', 'localStorageService', 'datasheetFactory', datasheetsExportCtrl]);
};
