var shareMultipleDatasheetWithEmailCtrl = function($rootScope, $scope, $translate, $modalInstance, localStorageService, shareFactory, datasheetFactory, emailListService, parentScope, datasheets){
    //Fields
    $scope.languages = [];
    $scope.emptyEmailGroupOption = $translate.instant("CUSTOMARCHIVES.YOURARCHIVES.MODALS.SHAREARCHIVEFILEWITHEMAIL.SLST_EMAIL_GROUPS.EMPTY_OPTION.TEXT");
    $scope.datasheets = datasheets;
    $scope.currentStep = 1;
    $scope.sharingModel = {
        selectedTemplateLanguage : $rootScope.activeLang,
        emailGroups : [],
        selectedLanguages : []
    };
    $scope.shareButton = "DATASHEET.YOURDATASHEETS.MODALS.SHARE_MULTIPLE_DATASHEET_WITH_EMAIL.BTN_SHARE.TEXT";
    $scope.isProcessing = false;

    //Private Methods
    var getAllEmailTags = function() {
        $scope.sharingModel.emailGroups = [];

        $scope.sharingModel.emailGroups.push($scope.emptyEmailGroupOption);
        emailListService.getAllEmailTags().then(function(response) {
            var emailTags = response;
            for (var i = 0; i < emailTags.length; i++) {
                var emailTag = emailTags[i];

                $scope.sharingModel.emailGroups.push('' + emailTag + '');
            }

            if ($scope.sharingModel.emailGroups.length > 0)
                $scope.sharingModel.selectedEmailGroup = $scope.sharingModel.emailGroups[0];
        });
    };

    var calculateAvaibleForms = function(){
        var idsVersion = _.map(datasheets, function(item){
            return { 
                _id : item._id,
                majorVersion : item.majorVersion
            }
        });
        $scope.sharingModel.selectedLanguageCodes = [];
        
        for(var lang in $scope.sharingModel.selectedLanguages) {
            $scope.sharingModel.selectedLanguageCodes.push(lang);
        };
        
        datasheetFactory.getPublishedDatasheetsByIdsVersion({ idsVersion : idsVersion, languages : $scope.sharingModel.selectedLanguageCodes}).then(function(response){
            $scope.sharingModel.selectedPublishedDatasheets = [];

            for(var i in datasheets){
                var relatedItem = _.find(response, {datasheetId : datasheets[i]._id});
                if(relatedItem !== undefined)
                    $scope.sharingModel.selectedPublishedDatasheets.push({
                        publishedId : relatedItem._id,
                        datasheetId : datasheets[i]._id,
                        productName : datasheets[i].productName
                    });
            }

            
        });
    };

    //Public Methods
    $scope.cancel = function() {
        $modalInstance.dismiss('cancel');
    };
    $scope.next = function() {
        $scope.errors = [];

        if (($scope.sharingModel.selectedEmailGroup === null || angular.isUndefined($scope.sharingModel.selectedEmailGroup)) && ($scope.sharingModel.customEmails === null || angular.isUndefined($scope.sharingModel.customEmails) || $scope.sharingModel.customEmails === "")) {
            $scope.errors.push("DATASHEET.YOURDATASHEETS.MODALS.SHAREDATASHEETWITHEMAIL.ERROR_MESSAGES.ADD_ONE_EMAIL");
        }
        
        if ($scope.sharingModel.selectedLanguages !== null) {
            var hasSelectedLanguage = false;

            for(var i in $scope.sharingModel.selectedLanguages){
                if($scope.sharingModel.selectedLanguages[i])
                    hasSelectedLanguage = true;
            }


            if (hasSelectedLanguage === false) {
                $scope.errors.push("DATASHEET.YOURDATASHEETS.MODALS.SHAREDATASHEETWITHEMAIL.ERROR_MESSAGES.SELECT_LANGUAGE");
            }
        }

        if($scope.sharingModel.selectedEmailGroup == $scope.emptyEmailGroupOption && ($scope.sharingModel.customEmails === null || angular.isUndefined($scope.sharingModel.customEmails) || $scope.sharingModel.customEmails === ""))
            $scope.errors.push("DATASHEET.YOURDATASHEETS.MODALS.SHAREDATASHEETWITHEMAIL.ERROR_MESSAGES.ADD_ONE_EMAIL");

        
        if ($scope.errors.length > 0) {
            return;
        }

        $scope.currentStep += 1;

        if($scope.currentStep == 2)
            calculateAvaibleForms();
        else if($scope.currentStep === 3){
            if($scope.mailTemplateMode === "custom")
                $scope.currentStep = 4;
        }
        
    };
    $scope.previous = function(){
        $scope.formErrors = false;
        if($scope.currentStep === 4)
            $scope.currentStep -= 2;
        else
            $scope.currentStep -= 1;
    };
    $scope.share = function(isValid){
        if (isValid) {
            $scope.shareButton = "DATASHEET.YOURDATASHEETS.MODALS.SHARE_MULTIPLE_DATASHEET_WITH_EMAIL.BTN_SHARE.ALTERNATE_TEXT";
            $scope.isProcessing = true;

            var requestModel = {};
            requestModel.datasheets = $scope.sharingModel.selectedPublishedDatasheets;
            requestModel.languages = $scope.sharingModel.selectedLanguageCodes;
            requestModel.customEmails = $scope.sharingModel.customEmails;
            requestModel.emailGroups = $scope.sharingModel.selectedEmailGroup;
            requestModel.subject = $scope.sharingModel.subject;
            requestModel.message = $scope.sharingModel.message;
            requestModel.templateId = $scope.sharingModel.selectedMailTemplateId;
            
            shareFactory.shareMultipleDatasheetWithEmail(requestModel).then(function(response) {
                $scope.shareButton = "DATASHEET.YOURDATASHEETS.MODALS.SHARE_MULTIPLE_DATASHEET_WITH_EMAIL.BTN_SHARE.TEXT";
                $scope.isProcessing = false;

                var message = $translate.instant("DATASHEET.YOURDATASHEETS.MODALS.SHARE_MULTIPLE_DATASHEET_WITH_EMAIL.SUCCESS_MESSAGES.SUCCESSFUL_SHARE");
                toastr.success(message, '', { positionClass: 'toast-bottom-full-width', timeOut: 3000 });

                setTimeout(function() {
                    $modalInstance.dismiss('cancel');
                }, 500);
            });
        }
    };

    $scope.templateLanguageChanged = function(){
        $scope.sharingModel.selectedMailTemplateId = _.find($scope.mailTemplates, { language : $scope.sharingModel.selectedTemplateLanguage})._id;
    };
    //Init
    var init = function(){

        var languageStrings = [];
        for(var i in datasheets){
            for(var n in datasheets[i].names){
                var lang = datasheets[i].names[n].language;


                var indexOf = languageStrings.indexOf(lang);
                if(indexOf < 0){
                    languageStrings.push(lang);
                    $scope.languages.push({
                        languageCode : lang,
                        language : $translate.instant('TEMPLATE.SHARED.LANGUAGES.' + lang.toUpperCase())
                    });

                    $scope.sharingModel.selectedLanguages[lang] = true;
                }
                
            }
        };

        shareFactory.getMailTemplate('multiDatasheetShare').then(function(response){
            
            $scope.mailTemplates = response;
            if(response[0].sysDefault){
                $scope.mailTemplateMode = "sysDefault";
                $scope.sharingModel.selectedTemplateLanguage = $rootScope.activeLang;
            }
            else{
                $scope.mailTemplateMode = "custom";
                $scope.sharingModel.selectedTemplateLanguage = response[0].language;
            }
            
            $scope.templateLanguageChanged();
        });

        getAllEmailTags();
        $scope.sharingModel.subject = $translate.instant("DATASHEET.YOURDATASHEETS.MODALS.SHARE_MULTIPLE_DATASHEET_WITH_EMAIL.SHAREEMAIL.SUBJECT");
        $scope.sharingModel.message = $translate.instant('DATASHEET.YOURDATASHEETS.MODALS.SHARE_MULTIPLE_DATASHEET_WITH_EMAIL.SHAREEMAIL.MESSAGE');
        var userInformationData = localStorageService.get('userInformationData');
            if (userInformationData) {
                $scope.sharingModel.userFullName = userInformationData.FirstName + ' ' + userInformationData.LastName;
                $scope.sharingModel.message = $scope.sharingModel.message.replace("$UserName$", $scope.sharingModel.userFullName);
            }
    };

    init();
    
};

module.exports = function(app){
    app.controller('shareMultipleDatasheetWithEmailCtrl', ['$rootScope', '$scope', '$translate', '$modalInstance', 'localStorageService', 'shareFactory', 'datasheetFactory', 'emailListService', 'parentScope', 'datasheets', shareMultipleDatasheetWithEmailCtrl]);
};