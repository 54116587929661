var jobTitleGroups = [{
    GroupName: "IT",
    LanguageCode: "IT",
    JobTitles: [{
        Name: "Designer",
        LanguageCode: "DESIGNER"
    }, {
        Name: "Software Engineer",
        LanguageCode: "SOFTWARE_ENGINEER"
    }]
}, {
    GroupName: "Other",
    LanguageCode: "OTHER",
    JobTitles: [{
        Name: "Other",
        LanguageCode: "OTHER"
    }]
}];

module.exports = function(app) {
    app.constant("jobTitleGroups", jobTitleGroups);
};
