var warningInUploadFilesCtrl = function($scope, $modalInstance, message) {
    //Fields
    //Fields End

    //Functions
    $scope.ok = function() {
        $modalInstance.close(false);
    };
    //Functions End

    //Initials
    $scope.message = message;
    //Initials End
};

module.exports = function(app) {
    app.controller('warningInUploadFilesCtrl', ['$scope', '$modalInstance', 'message', warningInUploadFilesCtrl]);
};
