var remindUserCtrl = function($scope, $modalInstance, userFactory, userId) {
    //Fields
    $scope.remindButton = "USER.ALLUSERS.MODALS.REMINDUSER.BTN_REMIND.TEXT";
    $scope.isProcessing = false;
    //Fields End

    //Functions
    $scope.cancel = function() {
        $modalInstance.dismiss();
    };

    $scope.remindAgain = function() {
        $scope.remindButton = "USER.ALLUSERS.MODALS.REMINDUSER.BTN_REMIND.ALTERNATE_TEXT";
        $scope.isProcessing = true;
        userFactory.remindUser(userId)
            .then(function(response) {
                $scope.remindButton = "USER.ALLUSERS.MODALS.REMINDUSER.BTN_REMIND.TEXT";
                $scope.isProcessing = false;


                if (response.status) {

                    $modalInstance.close({
                        status: true
                    });
                }
            });
    };
    //Functions End

    //Initials
    //Initials End
};

module.exports = function(app) {
    app.controller('remindUserCtrl', ['$scope', '$modalInstance', 'userFactory', 'userId', remindUserCtrl]);
};
