var dateTimePicker = function() {
    return {
        restrict: 'AE',
        link: function(scope, el, attrs) {
            attrs.$observe('dateTimePicker', function() {
                el.datetimepicker({
                    language: 'en',
                    pick12HourFormat: true
                });
            });
        }
    };
};


module.exports = function(app) {
    app.directive('dateTimePicker', [dateTimePicker]);
};
