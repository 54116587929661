var datasheetFooter = function($compile, datasheetViewService, _) {
    return {
        restrict: 'E',
        replace: true,
        templateUrl: 'app/components/datasheet/shared/directives/datasheetFooter/datasheetFooter.html',
        link: function(scope, elem, attrs) {
            //Fields 
            //Fields End

            //Inner Functions
            var init = function() {
                setFooterElements();
                setFooterStyle();
            };

            var setFooterElements = function() {
                var footerId = scope.footer._id;
                var elements = _.filter(scope.templateModel.elements, {
                    "parentId": footerId
                });

                elements = _.sortBy(elements, 'order');

                for (var i = 0, len = elements.length; i < len; i++) {
                    var element = elements[i];
                    var elementHtml = datasheetViewService.createDatasheetElement(element.type);

                    var childScope = scope.$new();
                    childScope.element = element;
                    $(elem).append($compile(elementHtml)(childScope));
                }
            };

            var setFooterStyle = function() {
                elem.css(scope.footer.style);
            };
            //Inner Functions End

            //Initials 
            init();
            //Initials End





        }
    };
};

module.exports = function(app) {
    app.directive('datasheetFooter', ['$compile', 'datasheetViewService', '_', datasheetFooter]);
};