var googleTranslateFactory = function ($filter, $http, config, httpProvider) {
  var factoryModel = {};

  //Functions
  factoryModel.translate = function (text, language, targetLanguages) {
    var requestModel = {};
    requestModel.ControlLanguage = language;
    requestModel.ControlText = text;
    requestModel.TranslateList = [];

    for (var i = 0; i < targetLanguages.length; i++) {
      var requestListModel = {};
      requestListModel.Language = targetLanguages[i];
      requestListModel.Text = "";

      requestModel.TranslateList.push(requestListModel);
    }

    return httpProvider.post("general", "translate", requestModel);
  };
  //Functions End

  //Inner Functions
  //Inner Functions End

  //Kullanılmayan Methodlar
  factoryModel.translateHtml = function (html, language, targetLanguages) {
    var requestModel = {};
    requestModel.ControlLanguage = language;
    requestModel.ControlText = html
  };

  factoryModel.translate1 = function (element, language) {
    var requestModel = {};
    requestModel.ControlLanguage = language;
    requestModel.ControlText = $filter('filter')(element.elementValue, {
      Language: language
    }, true)[0].Value;
    requestModel.TranslateList = [];

    angular.forEach(element.elementValue, function (value, key) {
      var requestListModel = {};
      requestListModel.Language = value.Language;
      requestListModel.Text = value.Value;

      requestModel.TranslateList.push(requestListModel);

    });

    $http.post(config.api[config.mode].fullApiUrl + "general/translate", requestModel).then(function (response) {
      angular.forEach(response.data.TranslateList, function (resElement, key) {
        var currentTextElement = $filter('filter')(element.elementValue, {
          Language: resElement.Language
        }, true)[0];
        currentTextElement.Value = resElement.Text;
      });
    });
  };

  factoryModel.translateText = function (text, language, targetLanguages) {
    var requestModel = {};
    requestModel.ControlLanguage = language;
    requestModel.ControlText = text;
    requestModel.TranslateList = [];

    for (var i = 0; i < targetLanguages.length; i++) {
      var requestListModel = {};
      requestListModel.Language = targetLanguages[i];
      requestListModel.Text = "";

      requestModel.TranslateList.push(requestListModel);
    }

    return httpProvider.post("general", "translate", requestModel);
  };

  return factoryModel;

};

module.exports = function (app) {
  app.factory('googleTranslate', ['$filter', '$http', 'config', 'httpProvider', googleTranslateFactory]);
};
