var globalCtrl = function($rootScope, $scope, $translate, localStorageService) {
    //Fields    
    $scope.tdsMakerPromise = {
        global: {

        }
    };

    //Fields End

    //Functions
    $scope.checkTdsMakerPromiseObject = function() {
        if ($scope.tdsMakerPromise.globalPromise == undefined) {
            return false;
        } else {
            return true;
        }
    };

    $scope.$on('loadUserPermissionsLoaded', function(){
        $scope.userPermissions = localStorageService.get('userPermissions');
    });


    //Functions End
};

module.exports = function(app) {
    app.controller('globalCtrl', ['$rootScope', '$scope', '$translate', 'localStorageService', globalCtrl]);
};
