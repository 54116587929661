var locales = [{
    Name: "Türkçe",
    LanguageCode: "tr",
    Main: true
}, {
    Name: "English",
    LanguageCode: "en",
    Main: true
}];

module.exports = function(app) {
    app.constant("locales", locales);
};
