var libraryTemplatesCtrl = function($rootScope, $scope, $modal, $modalInstance, $translate, templateFactory, imageFactory, pdfUploadFactory, config){
  $scope.loaded = {
    templates: false,
    industries: false,
    accountTemplates: false
  };

  $scope.decacher = Math.random();

  $scope.selectedLibraryTemplate = {
    nameTr : "",
    nameEn : "",
    descTr : "",
    descEn : "",
    image: "",
    pdf: "",
    mockup: "",
    tags: {
      tr: "",
      en: ""
    },
    templateId : "",
    isPopular: false,
    isLayout: false
  };
  $scope.hasError = false;

  $scope.mode = "list"
  $scope.activeLang = $rootScope.activeLang.charAt(0).toUpperCase() + $rootScope.activeLang.slice(1);

  $scope.dismiss = function(){
    $modalInstance.dismiss();
  };

  $scope.cancelEdit = function(){
    resetSelectedLibraryTemplate();
    $scope.mode = "list";
  };

  $scope.editLibraryTemplate = function(template) {
    $scope.mode = "edit";
    $scope.selectedLibraryTemplate = template;
    setTimeout(function() {
      setFilers();
    }, 0);
  };

  $scope.updateLibraryTemplate = function() {
    $scope.hasError = false;

    if (!$scope.selectedLibraryTemplate.isLayout) {
        if($scope.selectedLibraryTemplate.templateId.length == 0 ||
          $scope.selectedLibraryTemplate.nameTr.length == 0 ||
          $scope.selectedLibraryTemplate.nameEn.length == 0 ||
          $scope.selectedLibraryTemplate.descTr.length == 0 ||
          $scope.selectedLibraryTemplate.descEn.length == 0 ||
          $scope.selectedLibraryTemplate.image.length == 0 ||
          $scope.selectedLibraryTemplate.pdf.length == 0 ||
          $scope.selectedLibraryTemplate.mockup.length == 0 ||
          $scope.selectedLibraryTemplate.industryId.length == 0 ||
          ($scope.selectedLibraryTemplate.tags.tr.length == 0 ||
          $scope.selectedLibraryTemplate.tags.en.length == 0)){
          $scope.hasError = true;
          return;
        }
    } else {
        if($scope.selectedLibraryTemplate.templateId.length == 0 ||
            $scope.selectedLibraryTemplate.nameTr.length == 0 ||
            $scope.selectedLibraryTemplate.nameEn.length == 0 ||
            $scope.selectedLibraryTemplate.descTr.length == 0 ||
            $scope.selectedLibraryTemplate.descEn.length == 0 ||
            $scope.selectedLibraryTemplate.image.length == 0 ||
            $scope.selectedLibraryTemplate.industryId.length == 0 ||
            ($scope.selectedLibraryTemplate.tags.tr.length == 0 ||
            $scope.selectedLibraryTemplate.tags.en.length == 0)){
            $scope.hasError = true;
            return;
          }
    }

    
    templateFactory.updateLibraryTemplate($scope.selectedLibraryTemplate).then(function(response) {
      if (response.status) {
        var message = $translate.instant('TEMPLATE.YOURTEMPLATES.MODALS.EXPORT_TO_LIBRARY.LBL_SUCCESS.UPDATE');
        toastr.success(message, '', { positionClass: 'toast-bottom-full-width', timeOut: 3000 });

        var editedTemplate = _.find($scope.templates, function(t) { return t._id == response.model._id });
        editedTemplate.image = response.model.image;
        $scope.decacher = Math.random();

        resetSelectedLibraryTemplate();
        $scope.mode = "list";
        $('.modal-body').slimScroll({ scrollTo : '0px' });
      }
    });
  };

  $scope.newLibraryTemplate = function() {
    $scope.mode = "create";
    resetSelectedLibraryTemplate();
    setTimeout(function() {
      setFilers();
    }, 0);
  };

  $scope.createLibraryTemplate = function() {
    $scope.hasError = false;

    if (!$scope.selectedLibraryTemplate.isLayout) {
        if($scope.selectedLibraryTemplate.templateId.length == 0 ||
          $scope.selectedLibraryTemplate.nameTr.length == 0 ||
          $scope.selectedLibraryTemplate.nameEn.length == 0 ||
          $scope.selectedLibraryTemplate.descTr.length == 0 ||
          $scope.selectedLibraryTemplate.descEn.length == 0 ||
          $scope.selectedLibraryTemplate.image.length == 0 ||
          $scope.selectedLibraryTemplate.pdf.length == 0 ||
          $scope.selectedLibraryTemplate.mockup.length == 0 ||
          $scope.selectedLibraryTemplate.industryId.length == 0 ||
          $scope.selectedLibraryTemplate.tags.tr.length == 0 ||
          $scope.selectedLibraryTemplate.tags.en.length == 0){
          $scope.hasError = true;
          return;
        }
    } else {
        if($scope.selectedLibraryTemplate.templateId.length == 0 ||
            $scope.selectedLibraryTemplate.nameTr.length == 0 ||
            $scope.selectedLibraryTemplate.nameEn.length == 0 ||
            $scope.selectedLibraryTemplate.descTr.length == 0 ||
            $scope.selectedLibraryTemplate.descEn.length == 0 ||
            $scope.selectedLibraryTemplate.image.length == 0 ||
            $scope.selectedLibraryTemplate.industryId.length == 0 ||
            $scope.selectedLibraryTemplate.tags.tr.length == 0 ||
            $scope.selectedLibraryTemplate.tags.en.length == 0){
            $scope.hasError = true;
            return;
        }
    }
    

    templateFactory.getLibraryTemplateByTemplateId($scope.selectedLibraryTemplate.templateId).then(function(response) {
      if (response !== null) {
        $scope.openDeleteLibraryTemplateModal();
      } else {
        createLibraryTemplate();
      }
    });

  };

  $scope.deleteLibraryTemplate = function(templateId) {
    $scope.selectedLibraryTemplate = _.find($scope.templates, function(at) {
      return at._id == templateId;
    });
    $scope.openDeleteLibraryTemplateModal(function() {
      _.remove($scope.templates, {_id: templateId});
      resetSelectedLibraryTemplate();
      var message = $translate.instant('TEMPLATE.YOURTEMPLATES.MODALS.EXPORT_TO_LIBRARY.LBL_SUCCESS.DELETE');
      toastr.success(message, '', { positionClass: 'toast-bottom-full-width', timeOut: 3000 });
    });
  };

  $scope.getTemplateNameInActiveLanguage = function(template) {
    return _.find($scope.templates, {_id: template._id})['name' + $scope.activeLang];
  };

  $scope.getTemplateTagsInActiveLanguage = function(template) {
    return _.find($scope.templates, {_id: template._id}).tags[$rootScope.activeLang];
  };

  $scope.getTemplateIndustryInActiveLanguage = function(template) {
    return $translate.instant(_.find($scope.industries, {_id: template.industryId}).languageCode);
  };

  $scope.openDeleteLibraryTemplateModal = function (nextProcess) {
    if ($scope.mode == "create") {
      var tmp = _.find($scope.templates, function(at) {
        return at.templateId == $scope.selectedLibraryTemplate.templateId;
      });

      var st = {
        name: tmp['name' + $scope.activeLang],
        templateId: $scope.selectedLibraryTemplate.templateId
      }
    } else if ($scope.mode == "list") {
      var st = {
        name: $scope.selectedLibraryTemplate['name' + $scope.activeLang],
        templateId: $scope.selectedLibraryTemplate.templateId
      }
    }

    var modalInstance = $modal.open({
        animation: false,
        controller: 'deleteLibraryTemplateCtrl',
        templateUrl: '/app/components/template/yourTemplates/views/modals/deleteLibraryTemplate.html',
        resolve: {
            selectedTemplate: function () {
              return st;
            },
            mode: function() {
              return $scope.mode;
            }
        }
    });

    modalInstance.result.then(function (result) {
      if (result.status && $scope.mode === "create") {
        createLibraryTemplate();
      } else if (result.status && $scope.mode === "list"){
        nextProcess();
      }
    });
  };

  templateFactory.getAllTemplatesFromLibrary("all").then(function(response) {
    $scope.templates = response.templates;
    $scope.loaded.templates = true;
    templateFactory.getIndustries().then(function(response) {
      $scope.industries = response;
      $scope.loaded.industries = true;
      templateFactory.getTemplatesGroupedByFolderByUserId($rootScope.userId).then(function(response) {
          if (response) {
          $scope.accountTemplates = response;
          $scope.loaded.accountTemplates = true;
          resetSelectedLibraryTemplate();
        }
      });
      
      $('.modal-body').slimScroll({
        height: "82vh"
      });
    });
  });

  var createLibraryTemplate = function() {
    templateFactory.createLibraryTemplate($scope.selectedLibraryTemplate).then(function(response) {
      if (response.status) {
        var message = $translate.instant('TEMPLATE.YOURTEMPLATES.MODALS.EXPORT_TO_LIBRARY.LBL_SUCCESS.CREATE');
        toastr.success(message, '', { positionClass: 'toast-bottom-full-width', timeOut: 3000 });
        $scope.selectedLibraryTemplate._id = response.model._id;
        $scope.selectedLibraryTemplate.image = response.model.image;
        $scope.templates.push($scope.selectedLibraryTemplate);
        resetSelectedLibraryTemplate();
        $scope.mode = "list";
        $('.modal-body').slimScroll({ scrollTo : '0px' });
      }
    });
  }

  var resetSelectedLibraryTemplate = function() {
    $scope.selectedLibraryTemplate = {
      nameTr : "",
      nameEn : "",
      descTr : "",
      descEn : "",
      image : "",
      pdf : "",
      mockup : "",
      tags: {
        tr: "",
        en: ""
      },
      templateId : "",
      isPopular: false,
      isLayout: false
    };
    $scope.hasError = false;
  }

  var setFilers = function() {
    var lblDragDrop = $translate.instant("CUSTOMARCHIVES.UPLOADFILES.LBL_DRAGDROP.TEXT");
    var lblOr = $translate.instant("CUSTOMARCHIVES.UPLOADFILES.LBL_OR.TEXT");
    var btnBrowseFiles = $translate.instant("CUSTOMARCHIVES.UPLOADFILES.BTN_BROWSE_FILES.TEXT");
    var lblSuccess = $translate.instant("CUSTOMARCHIVES.UPLOADFILES.LBL_SUCCESS.TEXT");
    var lblError = $translate.instant("CUSTOMARCHIVES.UPLOADFILES.LBL_ERROR.TEXT");

    
    var lblDragDrop = $translate.instant("CUSTOMARCHIVES.UPLOADFILES.LBL_DRAGDROP.TEXT");
    var lblOr = $translate.instant("CUSTOMARCHIVES.UPLOADFILES.LBL_OR.TEXT");
    var btnBrowseFiles = $translate.instant("CUSTOMARCHIVES.UPLOADFILES.BTN_BROWSE_FILES.TEXT");
    var lblSuccess = $translate.instant("CUSTOMARCHIVES.UPLOADFILES.LBL_SUCCESS.TEXT");
    var lblError = $translate.instant("CUSTOMARCHIVES.UPLOADFILES.LBL_ERROR.TEXT");

    $('#fiImage').filer({
      changeInput: '<div class="jFiler-input-dragDrop"><div class="jFiler-input-inner"><div class="jFiler-input-icon"><i class="icon-jfi-cloud-up-o"></i></div><div class="jFiler-input-text"><h3>' + lblDragDrop + '</h3> <span style="display:inline-block; margin: 15px 0">' + lblOr + '</span></div><a class="jFiler-input-choose-btn btn-custom blue-light">' + btnBrowseFiles + '</a></div></div>',
      showThumbs: true,
      limit: 1,
      maxSize: 3,
      extensions: ['jpg', 'jpeg', 'png'],
      theme: "dragdropbox",
      templates: {
          box: '<ul class="jFiler-items-list jFiler-items-grid"></ul>',
          item: '<li class="jFiler-item">\
                      <div class="jFiler-item-container">\
                          <div class="jFiler-item-inner">\
                              <div class="jFiler-item-thumb">\
                                  <div class="jFiler-item-status"></div>\
                                  <div class="jFiler-item-thumb-overlay">\
                  <div class="jFiler-item-info">\
                    <div style="display:table-cell;vertical-align: middle;">\
                      <span class="jFiler-item-title"><b title="{{fi-name}}">{{fi-name}}</b></span>\
                      <span class="jFiler-item-others">{{fi-size2}}</span>\
                    </div>\
                  </div>\
                </div>\
                                  {{fi-image}}\
                              </div>\
                              <div class="jFiler-item-assets jFiler-row">\
                                  <ul class="list-inline pull-left">\
                                      <li>{{fi-progressBar}}</li>\
                                  </ul>\
                                  <ul class="list-inline pull-right">\
                                      <li><a class="icon-jfi-trash jFiler-item-trash-action"></a></li>\
                                  </ul>\
                              </div>\
                          </div>\
                      </div>\
                  </li>',
          itemAppend: '<li class="jFiler-item">\
                          <div class="jFiler-item-container">\
                              <div class="jFiler-item-inner">\
                                  <div class="jFiler-item-thumb">\
                                      <div class="jFiler-item-status"></div>\
                                      <div class="jFiler-item-thumb-overlay">\
                      <div class="jFiler-item-info">\
                        <div style="display:table-cell;vertical-align: middle;">\
                          <span class="jFiler-item-title"><b title="{{fi-name}}">{{fi-name}}</b></span>\
                          <span class="jFiler-item-others">{{fi-size2}}</span>\
                        </div>\
                      </div>\
                    </div>\
                                      {{fi-image}}\
                                  </div>\
                                  <div class="jFiler-item-assets jFiler-row">\
                                      <ul class="list-inline pull-left">\
                                          <li><span class="jFiler-item-others">{{fi-icon}}</span></li>\
                                      </ul>\
                                      <ul class="list-inline pull-right">\
                                          <li><a class="icon-jfi-trash jFiler-item-trash-action"></a></li>\
                                      </ul>\
                                  </div>\
                              </div>\
                          </div>\
                      </li>',
          progressBar: '<div class="bar"></div>',
          itemAppendToEnd: false,
          removeConfirmation: true,
          _selectors: {
              list: '.jFiler-items-list',
              item: '.jFiler-item',
              progressBar: '.bar',
              remove: '.jFiler-item-trash-action'
          }
      },
      captions: {
          errors: {
              filesType: function() { return $translate.instant("CUSTOMARCHIVES.UPLOADFILES.ERROR_MESSAGES.ALLOWED_EXTENSIONS"); },
              filesLimit: function(options) { return $translate.instant("CUSTOMARCHIVES.UPLOADFILES.ERROR_MESSAGES.FILE_LIMIT", { limit: options.limit}); },
              fileSize: function(options) { return $translate.instant("CUSTOMARCHIVES.UPLOADFILES.ERROR_MESSAGES.FILE_SIZE", { name: options.name, fileMaxSize: options.fileMaxSize}); },
              filesSizeAll: function(options) { return $translate.instant("CUSTOMARCHIVES.UPLOADFILES.ERROR_MESSAGES.FILE_SIZE_ALL", { maxSize: options.maxSize}); },
              fileName: function(options) { return $translate.instant("CUSTOMARCHIVES.UPLOADFILES.ERROR_MESSAGES.FILE_NAME", { name: options.name}); },
              folderUpload: function() { return $translate.instant("CUSTOMARCHIVES.UPLOADFILES.ERROR_MESSAGES.FOLDER_UPLOAD"); },
          }
      },
      onRemove: function(sender, file) {
          $scope.selectedLibraryTemplate.image = "";
      },
      dragDrop: {},
      uploadFile: {
          url: config.api[config.mode].apiUrl + "/api/v2/customFile/uploadLibraryTemplateImage",
          data: null,
          type: 'POST',
          enctype: 'multipart/form-data',
          beforeSend: function() {},
          success: function(data, el) {
              var parent = el.find(".jFiler-jProgressBar").parent();
              el.find(".jFiler-jProgressBar").fadeOut("slow", function() {
                  $("<div class=\"jFiler-item-others text-success\"><i class=\"icon-jfi-check-circle\"></i> " + lblSuccess + "</div>").hide().appendTo(parent).fadeIn("slow");
              });

              $scope.$apply(function() {
                  $scope.selectedLibraryTemplate.image = data.filename;
                  $scope.selectedLibraryTemplate.imageChanged = true;
                  $scope.errors = [];
              });
          },
          error: function(el) {
              var parent = el.find(".jFiler-jProgressBar").parent();
              el.find(".jFiler-jProgressBar").fadeOut("slow", function() {
                  $("<div class=\"jFiler-item-others text-error\"><i class=\"icon-jfi-minus-circle\"></i> " + lblError + "</div>").hide().appendTo(parent).fadeIn("slow");
              });
          },
          statusCode: null,
          onProgress: null,
          onComplete: null
      },
      dialogs: {
          alert: function(text) {
              var modalInstance = $modal.open({
                  templateUrl: 'app/components/customArchives/uploadFiles/views/modals/warningInUploadFiles.html',
                  controller: 'warningInUploadFilesCtrl',
                  resolve: {
                      message: function() {
                          return text;
                      }
                  }
              });
              return false;
          },
          confirm: function(text, callback) {
              var modalInstance = $modal.open({
                  templateUrl: 'app/components/customArchives/uploadFiles/views/modals/confirmDeleteFile.html',
                  controller: 'confirmDeleteFileCtrl',
                  resolve: {
                      text: function() {
                          return text;
                      }
                  }
              });

              modalInstance.result
                  .then(function(result) {
                      if (result) {
                          callback();
                      } else {
                          null;
                      }
                  });
            }
        }
    });

    $('#fiMockup').filer({
      changeInput: '<div class="jFiler-input-dragDrop"><div class="jFiler-input-inner"><div class="jFiler-input-icon"><i class="icon-jfi-cloud-up-o"></i></div><div class="jFiler-input-text"><h3>' + lblDragDrop + '</h3> <span style="display:inline-block; margin: 15px 0">' + lblOr + '</span></div><a class="jFiler-input-choose-btn btn-custom blue-light">' + btnBrowseFiles + '</a></div></div>',
      showThumbs: true,
      limit: 1,
      maxSize: 3,
      extensions: ['jpg', 'jpeg', 'png'],
      theme: "dragdropbox",
      templates: {
          box: '<ul class="jFiler-items-list jFiler-items-grid"></ul>',
          item: '<li class="jFiler-item">\
                      <div class="jFiler-item-container">\
                          <div class="jFiler-item-inner">\
                              <div class="jFiler-item-thumb">\
                                  <div class="jFiler-item-status"></div>\
                                  <div class="jFiler-item-thumb-overlay">\
                  <div class="jFiler-item-info">\
                    <div style="display:table-cell;vertical-align: middle;">\
                      <span class="jFiler-item-title"><b title="{{fi-name}}">{{fi-name}}</b></span>\
                      <span class="jFiler-item-others">{{fi-size2}}</span>\
                    </div>\
                  </div>\
                </div>\
                                  {{fi-image}}\
                              </div>\
                              <div class="jFiler-item-assets jFiler-row">\
                                  <ul class="list-inline pull-left">\
                                      <li>{{fi-progressBar}}</li>\
                                  </ul>\
                                  <ul class="list-inline pull-right">\
                                      <li><a class="icon-jfi-trash jFiler-item-trash-action"></a></li>\
                                  </ul>\
                              </div>\
                          </div>\
                      </div>\
                  </li>',
          itemAppend: '<li class="jFiler-item">\
                          <div class="jFiler-item-container">\
                              <div class="jFiler-item-inner">\
                                  <div class="jFiler-item-thumb">\
                                      <div class="jFiler-item-status"></div>\
                                      <div class="jFiler-item-thumb-overlay">\
                      <div class="jFiler-item-info">\
                        <div style="display:table-cell;vertical-align: middle;">\
                          <span class="jFiler-item-title"><b title="{{fi-name}}">{{fi-name}}</b></span>\
                          <span class="jFiler-item-others">{{fi-size2}}</span>\
                        </div>\
                      </div>\
                    </div>\
                                      {{fi-image}}\
                                  </div>\
                                  <div class="jFiler-item-assets jFiler-row">\
                                      <ul class="list-inline pull-left">\
                                          <li><span class="jFiler-item-others">{{fi-icon}}</span></li>\
                                      </ul>\
                                      <ul class="list-inline pull-right">\
                                          <li><a class="icon-jfi-trash jFiler-item-trash-action"></a></li>\
                                      </ul>\
                                  </div>\
                              </div>\
                          </div>\
                      </li>',
          progressBar: '<div class="bar"></div>',
          itemAppendToEnd: false,
          removeConfirmation: true,
          _selectors: {
              list: '.jFiler-items-list',
              item: '.jFiler-item',
              progressBar: '.bar',
              remove: '.jFiler-item-trash-action'
          }
      },
      captions: {
          errors: {
              filesType: function() { return $translate.instant("CUSTOMARCHIVES.UPLOADFILES.ERROR_MESSAGES.ALLOWED_EXTENSIONS"); },
              filesLimit: function(options) { return $translate.instant("CUSTOMARCHIVES.UPLOADFILES.ERROR_MESSAGES.FILE_LIMIT", { limit: options.limit}); },
              fileSize: function(options) { return $translate.instant("CUSTOMARCHIVES.UPLOADFILES.ERROR_MESSAGES.FILE_SIZE", { name: options.name, fileMaxSize: options.fileMaxSize}); },
              filesSizeAll: function(options) { return $translate.instant("CUSTOMARCHIVES.UPLOADFILES.ERROR_MESSAGES.FILE_SIZE_ALL", { maxSize: options.maxSize}); },
              fileName: function(options) { return $translate.instant("CUSTOMARCHIVES.UPLOADFILES.ERROR_MESSAGES.FILE_NAME", { name: options.name}); },
              folderUpload: function() { return $translate.instant("CUSTOMARCHIVES.UPLOADFILES.ERROR_MESSAGES.FOLDER_UPLOAD"); },
          }
      },
      onRemove: function(sender, file) {
          $scope.selectedLibraryTemplate.mockup = "";
      },
      dragDrop: {},
      uploadFile: {
          url: config.api[config.mode].apiUrl + "/api/v2/customFile/uploadLibraryTemplateMockup",
          data: null,
          type: 'POST',
          enctype: 'multipart/form-data',
          beforeSend: function() {},
          success: function(data, el) {
              var parent = el.find(".jFiler-jProgressBar").parent();
              el.find(".jFiler-jProgressBar").fadeOut("slow", function() {
                  $("<div class=\"jFiler-item-others text-success\"><i class=\"icon-jfi-check-circle\"></i> " + lblSuccess + "</div>").hide().appendTo(parent).fadeIn("slow");
              });

              $scope.$apply(function() {
                  $scope.selectedLibraryTemplate.mockup = data.filename;
                  $scope.selectedLibraryTemplate.mockupChanged = true;
                  $scope.errors = [];
              });
          },
          error: function(el) {
              var parent = el.find(".jFiler-jProgressBar").parent();
              el.find(".jFiler-jProgressBar").fadeOut("slow", function() {
                  $("<div class=\"jFiler-item-others text-error\"><i class=\"icon-jfi-minus-circle\"></i> " + lblError + "</div>").hide().appendTo(parent).fadeIn("slow");
              });
          },
          statusCode: null,
          onProgress: null,
          onComplete: null
      },
      dialogs: {
          alert: function(text) {
              var modalInstance = $modal.open({
                  templateUrl: 'app/components/customArchives/uploadFiles/views/modals/warningInUploadFiles.html',
                  controller: 'warningInUploadFilesCtrl',
                  resolve: {
                      message: function() {
                          return text;
                      }
                  }
              });
              return false;
          },
          confirm: function(text, callback) {
              var modalInstance = $modal.open({
                  templateUrl: 'app/components/customArchives/uploadFiles/views/modals/confirmDeleteFile.html',
                  controller: 'confirmDeleteFileCtrl',
                  resolve: {
                      text: function() {
                          return text;
                      }
                  }
              });

              modalInstance.result
                  .then(function(result) {
                      if (result) {
                          callback();
                      } else {
                          null;
                      }
                  });
            }
        }
    });

    $('#fiPDF').filer({
      changeInput: '<div class="jFiler-input-dragDrop"><div class="jFiler-input-inner"><div class="jFiler-input-icon"><i class="icon-jfi-cloud-up-o"></i></div><div class="jFiler-input-text"><h3>' + lblDragDrop + '</h3> <span style="display:inline-block; margin: 15px 0">' + lblOr + '</span></div><a class="jFiler-input-choose-btn btn-custom blue-light">' + btnBrowseFiles + '</a></div></div>',
      showThumbs: true,
      limit: 1,
      maxSize: 5,
      extensions: ['pdf'],
      theme: "dragdropbox",
      templates: {
          box: '<ul class="jFiler-items-list jFiler-items-grid"></ul>',
          item: '<li class="jFiler-item">\
                      <div class="jFiler-item-container">\
                          <div class="jFiler-item-inner">\
                              <div class="jFiler-item-thumb">\
                                  <div class="jFiler-item-status"></div>\
                                  <div class="jFiler-item-thumb-overlay">\
                  <div class="jFiler-item-info">\
                    <div style="display:table-cell;vertical-align: middle;">\
                      <span class="jFiler-item-title"><b title="{{fi-name}}">{{fi-name}}</b></span>\
                      <span class="jFiler-item-others">{{fi-size2}}</span>\
                    </div>\
                  </div>\
                </div>\
                                  {{fi-image}}\
                              </div>\
                              <div class="jFiler-item-assets jFiler-row">\
                                  <ul class="list-inline pull-left">\
                                      <li>{{fi-progressBar}}</li>\
                                  </ul>\
                                  <ul class="list-inline pull-right">\
                                      <li><a class="icon-jfi-trash jFiler-item-trash-action"></a></li>\
                                  </ul>\
                              </div>\
                          </div>\
                      </div>\
                  </li>',
          itemAppend: '<li class="jFiler-item">\
                          <div class="jFiler-item-container">\
                              <div class="jFiler-item-inner">\
                                  <div class="jFiler-item-thumb">\
                                      <div class="jFiler-item-status"></div>\
                                      <div class="jFiler-item-thumb-overlay">\
                      <div class="jFiler-item-info">\
                        <div style="display:table-cell;vertical-align: middle;">\
                          <span class="jFiler-item-title"><b title="{{fi-name}}">{{fi-name}}</b></span>\
                          <span class="jFiler-item-others">{{fi-size2}}</span>\
                        </div>\
                      </div>\
                    </div>\
                                      {{fi-image}}\
                                  </div>\
                                  <div class="jFiler-item-assets jFiler-row">\
                                      <ul class="list-inline pull-left">\
                                          <li><span class="jFiler-item-others">{{fi-icon}}</span></li>\
                                      </ul>\
                                      <ul class="list-inline pull-right">\
                                          <li><a class="icon-jfi-trash jFiler-item-trash-action"></a></li>\
                                      </ul>\
                                  </div>\
                              </div>\
                          </div>\
                      </li>',
          progressBar: '<div class="bar"></div>',
          itemAppendToEnd: false,
          removeConfirmation: true,
          _selectors: {
              list: '.jFiler-items-list',
              item: '.jFiler-item',
              progressBar: '.bar',
              remove: '.jFiler-item-trash-action'
          }
      },
      captions: {
          errors: {
              filesType: function() { return $translate.instant("CUSTOMARCHIVES.UPLOADFILES.ERROR_MESSAGES.ALLOWED_EXTENSIONS"); },
              filesLimit: function(options) { return $translate.instant("CUSTOMARCHIVES.UPLOADFILES.ERROR_MESSAGES.FILE_LIMIT", { limit: options.limit}); },
              fileSize: function(options) { return $translate.instant("CUSTOMARCHIVES.UPLOADFILES.ERROR_MESSAGES.FILE_SIZE", { name: options.name, fileMaxSize: options.fileMaxSize}); },
              filesSizeAll: function(options) { return $translate.instant("CUSTOMARCHIVES.UPLOADFILES.ERROR_MESSAGES.FILE_SIZE_ALL", { maxSize: options.maxSize}); },
              fileName: function(options) { return $translate.instant("CUSTOMARCHIVES.UPLOADFILES.ERROR_MESSAGES.FILE_NAME", { name: options.name}); },
              folderUpload: function() { return $translate.instant("CUSTOMARCHIVES.UPLOADFILES.ERROR_MESSAGES.FOLDER_UPLOAD"); },
          }
      },
      onRemove: function(sender, file) {
        $scope.selectedLibraryTemplate.pdf = "";
      },
      dragDrop: {},
      uploadFile: {
          url: config.api[config.mode].apiUrl + "/api/v2/customFile/uploadLibraryTemplatePDF",
          data: null,
          type: 'POST',
          enctype: 'multipart/form-data',
          beforeSend: function() {},
          success: function(data, el) {
              var parent = el.find(".jFiler-jProgressBar").parent();
              el.find(".jFiler-jProgressBar").fadeOut("slow", function() {
                  $("<div class=\"jFiler-item-others text-success\"><i class=\"icon-jfi-check-circle\"></i> " + lblSuccess + "</div>").hide().appendTo(parent).fadeIn("slow");
              });

              $scope.$apply(function() {
                
                  $scope.selectedLibraryTemplate.pdf = data.filename;
                  $scope.selectedLibraryTemplate.pdfChanged = true;
                  $scope.errors = [];
              });
          },
          error: function(el) {
              var parent = el.find(".jFiler-jProgressBar").parent();
              el.find(".jFiler-jProgressBar").fadeOut("slow", function() {
                  $("<div class=\"jFiler-item-others text-error\"><i class=\"icon-jfi-minus-circle\"></i> " + lblError + "</div>").hide().appendTo(parent).fadeIn("slow");
              });
          },
          statusCode: null,
          onProgress: null,
          onComplete: null
      },
      dialogs: {
          alert: function(text) {
              var modalInstance = $modal.open({
                  templateUrl: 'app/components/customArchives/uploadFiles/views/modals/warningInUploadFiles.html',
                  controller: 'warningInUploadFilesCtrl',
                  resolve: {
                      message: function() {
                          return text;
                      }
                  }
              });
              return false;
          },
          confirm: function(text, callback) {
              var modalInstance = $modal.open({
                  templateUrl: 'app/components/customArchives/uploadFiles/views/modals/confirmDeleteFile.html',
                  controller: 'confirmDeleteFileCtrl',
                  resolve: {
                      text: function() {
                          return text;
                      }
                  }
              });

              modalInstance.result
                  .then(function(result) {
                      if (result) {
                          callback();
                      } else {
                          null;
                      }
                  });
            }
        }
    });
  }

};

module.exports = function(app){
  app.controller('libraryTemplatesCtrl', ['$rootScope', '$scope', '$modal', '$modalInstance', '$translate', 'templateFactory', 'imageFactory', 'pdfUploadFactory', 'config', libraryTemplatesCtrl]);
};
