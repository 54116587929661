var confirmChangedArchiveFieldsCtrl = function($scope, $modalInstance) {
    //Fields
    //Fields End

    //Functions
    $scope.cancel = function() {
        $modalInstance.close(false);
    };

    $scope.no = function() {
        $modalInstance.close(false);
    };

    $scope.yes = function() {
        $modalInstance.close(true);
    };
    //Functions End

    //Initials
    //Initials End
};

module.exports = function(app) {
    app.controller('confirmChangedArchiveFieldsCtrl', ['$scope', '$modalInstance', confirmChangedArchiveFieldsCtrl]);
};
