var userGroupsCtrl = function($scope, $modal, $translate, $modalInstance, SweetAlert, userFactory, groupFactory, userId) {
    //Fields
    $scope.isLoading = false;
    //Fields End

    //Functions
    $scope.cancel = function() {
        $modalInstance.dismiss('cancel');
    };

    $scope.remove = function(groupName, groupId) {
        //Eğer admin gruptan çıkartılmak isteniyorsa, ve son kullanıcı ise hata ver,
        if (groupName == "Administrators") {
            groupFactory.getGroupUsersById(groupId)
                .then(function(response) {
                    var userCount = response.length;

                    if (userCount == 1) {
                        var title = $translate.instant("USER.ALLUSERS.MODALS.USERGROUPS.ERROR_MESSAGES.NOT_REMOVED_LAST_USER_FROM_ADMINISTRATORS.LBL_TITLE.TEXT");
                        var notRemoved = $translate.instant("USER.ALLUSERS.MODALS.USERGROUPS.ERROR_MESSAGES.NOT_REMOVED_LAST_USER_FROM_ADMINISTRATORS.LBL_EXPLANATION.TEXT");

                        $(".modal-dialog").hide();
                        SweetAlert.swal({
                            title: title,
                            text: notRemoved,
                            type: 'error'
                        }, function() {
                            $(".modal-dialog").show();
                        });
                        return;
                    } else {
                        createDeleteUserFromGroupModal(groupId);
                    }
                });
        } else {
            createDeleteUserFromGroupModal(groupId);
        }
    };
    //Functions End

    //Inner Functions
    var getUserGroupsByUserId = function(userId) {
        $scope.isLoading = true;

        $scope.modalPromise = userFactory.getUserGroupsByUserId(userId)
            .then(function(response) {
                $scope.isLoading = false;
                $scope.userGroups = response;
            });
    };

    var createDeleteUserFromGroupModal = function(groupId) {
        var modalInstance = $modal.open({
            animation: false,
            controller: 'deleteUserFromGroupCtrl',
            templateUrl: 'app/components/groups/groupUsers/views/modals/deleteUserFromGroup.html',
            resolve: {
                groupId: function() {
                    return groupId;
                },
                userId: function() {
                    return userId;
                }
            }
        });

        modalInstance.result
            .then(function(response) {
                getUserGroupsByUserId(userId);
            });
    };
    //Inner Functions End

    //Initials
    getUserGroupsByUserId(userId);
    //Initials End
};

module.exports = function(app) {
    app.controller('userGroupsCtrl', ['$scope', '$modal', '$translate', '$modalInstance', 'SweetAlert', 'userFactory', 'groupFactory', 'userId', userGroupsCtrl]);
};
