var pdfIcon = function (pdfElementService,pdfViewService) {
    return {
        restrict: 'E',
        replace: true,
       templateUrl: 'app/components/pdf/shared/directives/elements/pdfIcon/pdfIcon.html',
        link: function (scope, elem, attrs) {
            //Fields 
            var containerElement = elem;
            var innerElement = containerElement.find('.inner-element');
            //Fields End

            //Elements    
            //Elements End

            //Inner Functions
            var setElementStyle = function () {
                containerElement.css(scope.element.containerStyle);

                 var newStyle = pdfViewService.arrangeElementBorder(scope.element.style);
                innerElement.css(newStyle);                
            };

            var setElementValue = function () {
                innerElement.html('');
                if (scope.element.value != null && scope.element.value != undefined && scope.element.value != "") {
                    innerElement.append($('<i></i>').addClass(scope.element.value));
                }
                // else {
                //     var lblIcon = pdfElementService.getIconDefaultText(scope.selectionModel.selectedLanguage).icon;
                //     var lblIconWillBeHere = pdfElementService.getIconDefaultText(scope.selectionModel.selectedLanguage).iconWillBeHere;
                //     var iconInfo = {
                //         icon: lblIcon,
                //         iconWillBeHere: lblIconWillBeHere
                //     };
                //     var emptyPdfImage = pdfViewService.createEmptyPdfIcon();
                //     emptyPdfImage = emptyPdfImage.replace("{{icon}}",iconInfo.icon);
                //     emptyPdfImage = emptyPdfImage.replace("{{iconWillBeHere}}",iconInfo.iconWillBeHere);
                //     innerElement.append($(emptyPdfImage));
                // }
            };
            //Inner Functions End

            //Handlers
            //Handlers End

            //Initials 
            setElementStyle();
            setElementValue();
            //Initials End
        }
    };
};

module.exports = function (app) {
    app.directive('pdfIcon', ['pdfElementService','pdfViewService', pdfIcon]);
}