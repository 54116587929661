var confirmFormPublishCtrl = function($scope, $translate, $modalInstance, numberOfDatasheet) {
  //Fields
  $scope.selectedPrefer = "1";
  $scope.text = $translate.instant('TEMPLATE.EDIT_TEMPLATE.MODALS.CONFIRM_PUBLISH.P_1', { formCount : numberOfDatasheet });
  //Fields-End

  //Functions
  $scope.cancel = function() {
      $modalInstance.dismiss('cancel');
  };
  $scope.ok = function() {

      if ($scope.selectedPrefer == "1")
          $modalInstance.close({
              changeRevisionNumber : false,
              publishNow : true
          });
      else if($scope.selectedPrefer == "2")
          $modalInstance.close({
              changeRevisionNumber : true,
              publishNow : true
          });
    else 
        $modalInstance.close({
              publishNow : false
          });
  };
  //Functions-End
};

module.exports = function(app){
  app.controller('confirmFormPublishCtrl', ['$scope', '$translate', '$modalInstance', 'numberOfDatasheet', confirmFormPublishCtrl]);
};
