var generalsService = function ($http, $q, localStorageService, config) {
    //Fields
    var serviceModel = {};
    //Fields - End

    //Private Functions
    serviceModel.translateTextFrom = function (model) {
        var deferred = $q.defer();

        var res = $http.post(config.api[config.mode].fullApiUrl + "general/translate", model);

        res.success(function (data, status, headers, config) {
            deferred.resolve(data);
        });

        res.error(function (msg, code) {
            deferred.reject(msg);
        });
        return deferred.promise;
    };
    serviceModel.createGUID = function () {
        var d = new Date().getTime();
        var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
        return uuid;
    };
    serviceModel.createObjectId = function () {
        var m = Math;
        var d = Date;
        var h = 16;
        var s = function (s) {
            return m.floor(s).toString(h);
        };
        return s(d.now() / 1000) + ' '.repeat(h).replace(/./g, function () { return s(m.random() * h); });
    };
    serviceModel.imageUpload = function (formData, sourceType) {
        var deferred = $q.defer();


        var res = $http.post(config.api[config.mode].fullApiUrl + "general/imageUpload", formData, {
            headers: {
                'Content-Type': undefined
            },
            transformRequest: angular.identity
        });

        res.success(function (data, status, headers, config) {
            deferred.resolve(data);
        });

        res.error(function (msg, code) {
            deferred.reject(msg);
        });

        return deferred.promise;
    };
    serviceModel.toPixelForPage = function (cm) {
        var perCmValue = 900 / 21;
        return cm * perCmValue;
    };
    serviceModel.toCMForPage = function (pixel) {
        var perCmValue = 900 / 21;
        return pixel / perCmValue;
    };
    serviceModel.getIpCountry = function () {
        var deferred = $q.defer();
        var authData = localStorageService.get('authorizationData');
        var userData = localStorageService.get('userInformationData');

        if (authData && userData) {
            if (userData.Language.toLowerCase() == "tr")
                deferred.resolve({ country_code: "tr" });
            else
                deferred.resolve({ country_code: "en" });
        } else {
            var res = $http.get('https://ipv4.myexternalip.com/json');
            res.success(function (data, status, headers, config) {

                //var res2 = $http.get("http://ip-api.com/json/" + data.ip);
                var res2 = $http.get("https://freegeoip.net/json/" + data.ip);


                res2.success(function (data2, status2, headers2, config) {
                    deferred.resolve(data2);
                });


                res2.error(function (msg2, code2) {
                    deferred.reject(msg2);
                });
            });

            res.error(function (msg, code) {
                deferred.reject(msg);
            });
        }


        return deferred.promise;
    };
    //Private Functions - End

    return serviceModel;
};

module.exports = function (app) {
    app.service('generalsService', ['$http', '$q', 'localStorageService', 'config', generalsService]);
};
