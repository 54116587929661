var templateTransferCtrl = function ($rootScope, $scope, $state, $timeout, adminFactory) {
    //Fields
    $scope.selectedSourceAccount = "";
    $scope.selectedSourceTemplate = [];
    $scope.selectedTargetAccount = "";
    $scope.selectedTargetTemplate = [];
    $scope.templates = [];
    //Fields End

    //Events
    //Events End

    //Functions
    $scope.sourceAccountChange = function() {
        $scope.selectedSourceTemplate = "";
        getSourceAccountTemplateFolders();
    }
    $scope.targetAccountChange = function() {
        $scope.selectedTargetTemplate = "";
        getTargetAccountTemplateFolders();
        getTargetAccountUsers();
    }
    $scope.resetWidth = function(elementId) {
        $timeout(function() {
            var element = $('#' + elementId + ' + span.select2');
            element.each(function () {
                var width = element[0].style.width;
                width = width.replace('px', '');
                var newWidth = (parseInt(width) - 9) + 'px';
                this.style.setProperty( 'width', newWidth , 'important' );
            }); 
        });
    }
    $scope.transferTemplate = function() {
        var moveSettings = {
            sourceTemplateId: $scope.selectedSourceTemplate,
            targetAccountId: $scope.selectedTargetAccount,
            targetTemplateFolderId: $scope.selectedTargetTemplateFolder,
            targetUserId: $scope.selectedTargetUser
        }
        transferTemplate(moveSettings);
    }
    //Functions End

    //Watches
    //Watches End

    //Private Functions
    var transferTemplate = function(moveSettings) {
            $state.go('app.copyTemplate', {
                lang: $rootScope.activeLang,
                moveSettings: moveSettings
            });
    };
    var getAllAccounts = function() {
        adminFactory.getAllAccounts()
            .then(function(response) {
                $scope.accounts = response;
            });
    };
    var getSourceAccountTemplateFolders = function () {
        adminFactory.getTemplatesByAccountId($scope.selectedSourceAccount)
            .then(function (response) {
                $scope.sourceAccountTemplates = response;
            });
    };
    var getTargetAccountTemplateFolders = function () {
        adminFactory.getTemplatesByAccountId($scope.selectedTargetAccount)
            .then(function (response) {
                $scope.targetAccountTemplates = response;
            });
    };
    var getTargetAccountUsers = function () {
        adminFactory.getUsersByAccountId($scope.selectedTargetAccount)
            .then(function (response) {
                $scope.targetAccountUsers = response;
            });
    };
    //Private Functions End

    //Initials
    $scope.resetWidth("slcSelectedSourceAccount");
    $scope.resetWidth("slcSelectedSourceTemplate");
    $scope.resetWidth("slcSelectedTargetAccount");
    $scope.resetWidth("slcSelectedTargetTemplateFolder");
    $scope.resetWidth("slcSelectedTargetUser");
    getAllAccounts();        
    //Initials End
};

module.exports = function (app) {
    app.controller('templateTransferCtrl', ['$rootScope', '$scope', '$state', '$timeout', 'adminFactory', templateTransferCtrl]);
}
