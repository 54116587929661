var adminNavTabsCtrl = function($scope, $state, localStorageService) {
    //Fields
    $scope.currentName = $state.current.name;
    //Fields End

    //Initials
    //Initials End
};

module.exports = function(app) {
    app.controller('adminNavTabsCtrl', ['$scope', '$state', 'localStorageService', adminNavTabsCtrl]);
};
