var datasheetReportCtrl = function ($rootScope, $scope, $translate, menuToggleFactory, reportFactory, intercomFactory) {
    //Fields
    var dateFormat = "D MMMM YYYY";
    menuToggleFactory.collapse();
    moment.locale($rootScope.activeLang);

    $scope.reportGenerated = false;
    $scope.listSearchKey = "";
    $scope.groupOptions = {
        showVersion: false,
        showLanguage: true
    };

    $scope.sortModel = {
        field: "total",
        reverse: true
    }
    $scope.filterModel = {
        startDate: "",
        endDate: ""
    };

    //Private Functions
    var datePickerChanged = function (start, end, label) {
        $scope.selectedData = undefined;
        $scope.dateRange = start.format(dateFormat) + ' - ' + end.format(dateFormat);
        $scope.loadReport(start.format(), end.format())
    };

    var setDateOptions = function () {
        var language = $rootScope.activeLang;
        var today = new Date();
        var yesterday = new Date();
        var sevenDaysAgo = new Date();
        var thirtyDaysAgo = new Date();
        yesterday.setDate(today.getDate() - 1);
        sevenDaysAgo.setDate(today.getDate() - 6);
        thirtyDaysAgo.setDate(today.getDate() - 29);

        var thisMonthFirstDay = moment().startOf('month');
        var thisMonthLastDay = moment().endOf('month');

        var lastMonthFirstDay = moment().startOf('month').add(-1, 'month');
        var lastMonthLastDay = moment().endOf('month').add(-1, 'month');

        var last3MonthStart = moment().startOf('month').add(-3, 'month');

        var last6MonthStart = moment().startOf('month').add(-6, 'month');

        if (language === "tr")
            dateFormat = "D MMMM YYYY";
        else
            dateFormat = "MMMM D, YYYY";

        $scope.dateRange = thisMonthFirstDay.format(dateFormat) + ' - ' + thisMonthLastDay.format(dateFormat);

        $scope.loadReport(thisMonthFirstDay.format(), thisMonthLastDay.format())



        var datePickerOptions;

        if (language === "tr") {
            datePickerOptions = {
                "showDropdowns": true,
                "autoApply": true,
                "ranges": {
                    "Bugün": [
                        today,
                        today
                    ],
                    "Dün": [
                        yesterday,
                        yesterday
                    ],
                    "Bu Ay": [
                        thisMonthFirstDay,
                        thisMonthLastDay
                    ],
                    "Geçen Ay": [
                        lastMonthFirstDay,
                        lastMonthLastDay
                    ],
                    "Son 7 Gün": [
                        sevenDaysAgo,
                        today
                    ],
                    "Son 30 Gün": [
                        thirtyDaysAgo,
                        today
                    ],
                    "Son 3 Ay": [
                        last3MonthStart,
                        lastMonthLastDay
                    ],
                    "Son 6 Ay": [
                        last6MonthStart,
                        lastMonthLastDay
                    ]

                },
                "locale": {
                    "format": "DD.MM.YYYY",
                    "separator": " - ",
                    "applyLabel": "Tamam",
                    "cancelLabel": "İptal",
                    "fromLabel": "From",
                    "toLabel": "To",
                    "customRangeLabel": "Özel",
                    "weekLabel": "W",
                    "daysOfWeek": [
                        "Pa",
                        "Pt",
                        "Sa",
                        "Ça",
                        "Pe",
                        "Cu",
                        "Ct"
                    ],
                    "monthNames": [
                        "Ocak",
                        "Şubat",
                        "Mart",
                        "Nisan",
                        "Mayıs",
                        "Haziran",
                        "Temmuz",
                        "Ağustos",
                        "Eylül",
                        "Ekim",
                        "Kasım",
                        "Aralık"
                    ],
                    "firstDay": 1
                },
                "startDate": thisMonthFirstDay,
                "endDate": thisMonthLastDay,
                "opens": "left"
            };
        } else {
            datePickerOptions = {
                "showDropdowns": true,
                "autoApply": true,
                "ranges": {
                    "Today": [
                        today,
                        today
                    ],
                    "Yesterday": [
                        yesterday,
                        yesterday
                    ],
                    "This Month": [
                        thisMonthFirstDay,
                        thisMonthLastDay
                    ],
                    "Last Month": [
                        lastMonthFirstDay,
                        lastMonthLastDay
                    ],
                    "Last 7 Days": [
                        sevenDaysAgo,
                        today
                    ],
                    "Last 30 Days": [
                        thirtyDaysAgo,
                        today
                    ],
                    "Last 3 Months": [
                        last3MonthStart,
                        lastMonthLastDay
                    ],
                    "Last 6 Months": [
                        last6MonthStart,
                        lastMonthLastDay
                    ]

                },
                "locale": {
                    "format": "MM/DD/YYYY",
                    "separator": " - ",
                    "applyLabel": "Apply",
                    "cancelLabel": "Cancel",
                    "fromLabel": "From",
                    "toLabel": "To",
                    "customRangeLabel": "Custom",
                    "weekLabel": "W",
                    "daysOfWeek": [
                        "Su",
                        "Mo",
                        "Tu",
                        "We",
                        "Th",
                        "Fr",
                        "Sa"
                    ],
                    "monthNames": [
                        "January",
                        "February",
                        "March",
                        "April",
                        "May",
                        "June",
                        "July",
                        "August",
                        "September",
                        "October",
                        "November",
                        "December"
                    ],
                    "firstDay": 1
                },
                "startDate": thisMonthFirstDay,
                "endDate": thisMonthLastDay,
                "opens": "left"
            };
        }

        $('#rptDatePicker').daterangepicker(datePickerOptions, datePickerChanged);
    };

    var setGroupedData = function () {
        var data = $scope.reportData.data;

        var groups;

        if ($scope.groupOptions.showVersion && $scope.groupOptions.showLanguage) {

            groups = _.groupBy(data, function (value) {
                return value.datasheetId + '#' + value.language + '#' + value.version;
            });
            $scope.footerColspan = 5;
        } else if ($scope.groupOptions.showVersion && !$scope.groupOptions.showLanguage) {
            groups = _.groupBy(data, function (value) {
                return value.datasheetId + '#' + value.version;
            });
            $scope.footerColspan = 4;
        } else if (!$scope.groupOptions.showVersion && $scope.groupOptions.showLanguage) {
            groups = _.groupBy(data, function (value) {
                return value.datasheetId + '#' + value.language;
            });
            $scope.footerColspan = 4;
        } else {
            groups = _.groupBy(data, function (value) {
                return value.datasheetId + '#';
            });
            $scope.footerColspan = 3;
        }

        $scope.footerTotal = {
            mobile: 0,
            direct: 0,
            webApp: 0,
            webPage: 0,
            email: 0,
            total: 0
        };
        $scope.reportSource = _.map(groups, function (group) {
            var mobile = _.filter(group, { source: 'mobile' }).length;
            $scope.footerTotal.mobile += mobile;

            var webApp = _.filter(group, { source: 'webApp' }).length;
            $scope.footerTotal.webApp += webApp;

            var email = _.filter(group, { source: 'email' }).length;
            $scope.footerTotal.email += email;

            var webPage = _.filter(group, { source: 'webpage' }).length;
            $scope.footerTotal.webPage += webPage;

            var direct = _.filter(group, { source: 'direct' }).length;
            $scope.footerTotal.direct += direct;

            var mapObject = {
                datasheetId: group[0].datasheetId,
                productName: group[0].productName,
                version: group[0].version,
                language: group[0].language,
                mobile: mobile,
                direct: direct,
                webApp: webApp,
                email: email,
                webPage: webPage,
                details: group,
                total: group.length
            };

            $scope.footerTotal.total += mapObject.total;

            if (angular.isDefined(group[0].currentDatasheet) && group[0].currentDatasheet !== null)
                mapObject.productNumber = group[0].currentDatasheet.productNumber;

            return mapObject;
        });

        $scope.tileBoxData = {
            mobile: $scope.footerTotal.mobile,
            direct: $scope.footerTotal.direct,
            webApp: $scope.footerTotal.webApp,
            webPage: $scope.footerTotal.webPage,
            email: $scope.footerTotal.email,
            total: $scope.footerTotal.total
        }


        var groupsTop = _.groupBy(data, function (value) {
            return value.datasheetId + '#';
        });



        $scope.top5dataSource = _.map(groupsTop, function (group) {
            var mobile = _.filter(group, { source: 'mobile' }).length;
            var webApp = _.filter(group, { source: 'webApp' }).length;
            var email = _.filter(group, { source: 'email' }).length;
            var webPage = _.filter(group, { source: 'webpage' }).length;
            var direct = _.filter(group, { source: 'direct' }).length;
            var mapObject = {
                datasheetId: group[0].datasheetId,
                productName: group[0].productName,
                version: group[0].version,
                language: group[0].language,
                mobile: mobile,
                direct: direct,
                webApp: webApp,
                email: email,
                webPage: webPage,
                details: group,
                total: group.length
            };


            if (angular.isDefined(group[0].currentDatasheet) && group[0].currentDatasheet !== null)
                mapObject.productNumber = group[0].currentDatasheet.productNumber;

            return mapObject;
        });
        $scope.top5data = _.sortBy($scope.top5dataSource, 'total').reverse();
        $scope.top5data = $scope.top5data.slice(0, 5);

        if (!$scope.$$phase)
            $scope.$digest();

        $scope.reportGenerated = true;
        reportLoaded();
    };

    //Functions
    $scope.loadReport = function (startDate, endDate) {
        $scope.reportGenerated = false;
        $('#reportLoading').show();
        $('#reports-container').css('visibility', 'hidden').hide().fadeIn("slow");

        reportFactory.loadDatasheetReport({
            startDate: startDate,
            endDate: endDate
        }).then(function (response) {
            intercomFactory.addEvent({
                event_name: "Datasheet Report Created"
            });

            $scope.reportData = response;
            setGroupedData();
            $scope.filterModel.startDate = response.startDate;
            $scope.filterModel.endDate = response.endDate;
            $scope.filterModel.period = "thismonth";
        });
    };

    $scope.reOrder = function (field) {
        $scope.sortModel.reverse = ($scope.sortModel.field === field) ? !$scope.sortModel.reverse : false;
        $scope.sortModel.field = field;
    };

    $scope.groupOptionsChanged = function () {
        setGroupedData();
    };

    $scope.selectData = function (data) {
        $scope.selectedData = data;
    };
    $scope.cancelSelectedData = function () {
        $scope.selectedData = undefined;
    };

    $scope.translateSource = function (source) {
        if (source === "mobile")
            return $translate.instant('REPORTS.DATASHEET_REPORTS.REPORT_TABLE.TH_MOBILE');
        else if (source === "webApp")
            return $translate.instant('REPORTS.DATASHEET_REPORTS.REPORT_TABLE.TH_WEBAPP');
        else if (source === "email")
            return $translate.instant('REPORTS.DATASHEET_REPORTS.REPORT_TABLE.TH_EMAIL');
        else if (source === "webPage")
            return $translate.instant('REPORTS.DATASHEET_REPORTS.REPORT_TABLE.TH_WEBPAGE');
        else if (source === "direct")
            return $translate.instant('REPORTS.DATASHEET_REPORTS.REPORT_TABLE.TH_DIRECT');
        else
            return source;
    }
    //Init
    setDateOptions();

    var reportLoaded = function () {
        $('.open-left').hide();
        $('.footer').hide();

        setTimeout(function () {
            $('#reportLoading').hide();
            $('#reports-container').css('visibility', 'visible').hide().fadeIn("slow");
            var width = $('#first-five-div').outerWidth();
            $('#title-card-box').width(width);

            if (!$scope.$$phase)
                $scope.$apply();
        }, 1000);
    }

    $scope.$on('$viewContentLoaded', function () {
        menuToggleFactory.collapse();
    });
    //Watchers
    $(window).resize(function () {
        var width = $('#first-five-div').outerWidth();
        $('#title-card-box').width(width);
    });
};

module.exports = function (app) {
    app.controller('datasheetReportCtrl', ['$rootScope', '$scope', '$translate', 'menuToggleFactory', 'reportFactory', 'intercomFactory', datasheetReportCtrl]);
};