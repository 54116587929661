var signInCtrl = function($translate, $rootScope, $scope, $state, $stateParams, $timeout, $log, localStorageService, authFactory, userFactory, intercomFactory, locales) {
    //Fields
    $scope.signInButton = "AUTHENTICATION.SIGNIN.BTN_SIGNIN.TEXT";
    $scope.disabled = false;
    $scope.status = true;
    $scope.locales = locales;
    //Fields - End

    //Watches
    $scope.$watch('email', function(newValue, oldValue) {
        if (newValue != null && newValue != undefined)
            $scope.email = newValue.toLowerCase();
    });
    //Wathes End

    //Inner Functions
    var initialize = function() {
        var authData = localStorageService.get('authorizationData');

        if (authData) {
            var lang = "en";
            if ($rootScope.activeLang.length)
                lang = $rootScope.activeLang;


            $state.go('blank.loading', {
                lang: lang
            });
        }
        if (angular.isDefined($stateParams.id) && angular.isDefined($stateParams.pass)) {
            authFactory.getAutoLoginInfo($stateParams.id, $stateParams.pass)
                .then(function(response) {
                    if (response.status) {
                        intercomFactory.addEvent({
                            event_name: "Auto Login",
                            metadata: {
                                email: response.email,
                                firstName: $scope.firstName,
                                lastName: $scope.lastName
                            }
                        }, $stateParams.id);

                        $scope.email = response.email;
                        $scope.password = $stateParams.pass;
                        login();

                    }
                });
        }

    };

    var login = function() {
        $scope.signInButton = "AUTHENTICATION.SIGNIN.BTN_SIGNIN.ALTERNATE_TEXT";
        $scope.disabled = true;
        authFactory.login({
            email: $scope.email,
            password: $scope.password
        }).then(function(response) {
            $scope.signInButton = 'AUTHENTICATION.SIGNIN.BTN_SIGNIN.TEXT';
            $scope.disabled = false;
            if (response.status) {
                //Login bilgileri localStorage a yazılır.
                var authorizationData = {
                    token: response.authData.token,
                    _id: response.authData._id
                };
                authData = localStorageService.set('authorizationData', authorizationData);
                userFactory.getUserByUserId(response.authData._id)
                    .then(function(response) {
                        intercomFactory.addEvent({
                            event_name: "Login",
                            metadata: {
                                email: response.Email,
                                firstName: response.FirstName,
                                lastName: response.LastName
                            }
                        }, response._id);

                        $rootScope.activeLang = response.Language;
                        $state.go('blank.loading', {
                            lang: $rootScope.activeLang
                        });
                    });

            } else {
                $scope.status = false;
                $scope.customErrorMessage = response.message;
            }
        }, function(err, code) {
            $scope.signInButton = 'AUTHENTICATION.SIGNIN.BTN_SIGNIN.TEXT';
            $scope.disabled = false;

            $scope.status = false;
            $scope.customErrorMessage = "AUTHENTICATION.SIGNIN.ERROR_MESSAGES.WRONG_EMAIL_OR_PASSWORD";

            $timeout(function() {
                $scope.status = true;
            }, 3000);


        });
    };
    //Inner Functions End

    //Functions
    $scope.changeLanguage = function(language) {
        $rootScope.activeLang = language.LanguageCode;
        $rootScope.$emit('changedLanguage', {
            languageCode: language.LanguageCode
        });
        $state.go('auth.signIn', {
            lang: $rootScope.activeLang
        });
    };

    $scope.login = function() {
        if ($scope.loginForm.$valid) {
            login();
        }
    };
    //Functions End

    //Initialize
    initialize();
    //Initialize End

    /* removeIfProduction */
    $scope._initialize = initialize;
    $scope._login = login;
    /* endRemoveIfProduction */
};

module.exports = function(app) {
    app.controller('signInCtrl', ['$translate', '$rootScope', '$scope', '$state', '$stateParams', '$timeout', '$log', 'localStorageService', 'authFactory', 'userFactory', 'intercomFactory', 'locales', signInCtrl]);
};