var headerSearchCtrl = function($scope, $state, $rootScope){
  $scope.searchTds = function() {
    if($state.current.name == "app.searchResults"){
      $rootScope.$broadcast('search');
    }else{
      $state.go('app.searchResults', {
          lang: $rootScope.activeLang
      });
    }
  };
};

module.exports = function(app){
  app.controller('headerSearchCtrl', ['$scope', '$state', '$rootScope', headerSearchCtrl]);
};
