var newGroupCtrl = function($scope, $state, $timeout, menuToggleFactory, $translate, permissionFactory, groupFactory) {
    //Fields
    $scope.group = {};
    //Fields End

    //Events
    $scope.$on('$viewContentLoaded', function() {
        $timeout(function() {
            menuToggleFactory.collapse();
        },100);
    });
    //Events End

    //Functions
    $scope.submitted = false;
    $scope.createGroup = function(isValid, group) {
        $scope.submitted = true;
        if (isValid) {
            var selectedPermissionLevels = [];
            for (var i = 0; i < $scope.permissionLevels.length; i++) {
                var permissionLevel = $scope.permissionLevels[i];

                if (angular.isDefined(permissionLevel.IsSelected) && permissionLevel.IsSelected) {
                    selectedPermissionLevels.push(permissionLevel._id);
                }
            }

            group.PermissionLevels = selectedPermissionLevels;
            groupFactory.createGroup(group)
                .then(function(response) {
                    var message = $translate.instant("GROUP.NEWGROUP.SUCCESS_MESSAGES.GROUP_CREATED");
                    toastr.success(message, '', { positionClass: 'toast-bottom-full-width', timeOut: 3000 });
                    $timeout(function() {
                        $state.go("app.allGroups");
                    }, 1000);

                });
        }
    };
    //Functions End

    //Inner Functions
    var getAllAccountPermissionLevels = function() {
        permissionFactory.getAllAccountPermissionLevels()
            .then(function(response) {
                $scope.permissionLevels = response;
            });
    };
    //Inner Functions End

    //Initials
    menuToggleFactory.collapse();

    getAllAccountPermissionLevels();
    //Initials End
};

module.exports = function(app) {
    app.controller('newGroupCtrl', ['$scope', '$state', '$timeout', 'menuToggleFactory', '$translate', 'permissionFactory', 'groupFactory', newGroupCtrl]);
}
