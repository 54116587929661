var deleteGroupCtrl = function($scope, $timeout, $modalInstance, groupFactory, groupId) {
    //Fields
    $scope.deleteButton = "GROUP.ALLGROUPS.MODALS.DELETEGROUP.BTN_DELETE.TEXT";
    $scope.isProcessing = false;
    //Fields End

    //Functions
    $scope.cancel = function() {
        $modalInstance.dismiss('cancel');
    };

    $scope.delete = function() {
        $scope.deleteButton = "GROUP.ALLGROUPS.MODALS.DELETEGROUP.BTN_DELETE.ALTERNATE_TEXT";
        $scope.isProcessing = true;

        groupFactory.deleteGroup(groupId)
            .then(function(response) {
                if (response.status) {
                    $scope.deleteButton = "GROUP.ALLGROUPS.MODALS.DELETEGROUP.BTN_DELETE.SUCCESS";

                    $timeout(function() {
                        $modalInstance.close({
                            status: true
                        })
                    }, 1000);
                }
            });
    };
    //Functions End

    //Initials
    //Initials End
};

module.exports = function(app) {
    app.controller('deleteGroupCtrl', ['$scope', '$timeout', '$modalInstance', 'groupFactory', 'groupId', deleteGroupCtrl]);
}
