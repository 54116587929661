var orderDetailsCtrl = function ($scope, $stateParams, subscriptionFactory) {
  //Fields 
  $scope.orderDetailModel = {};
  //Fields End

  //Initials
  subscriptionFactory.getOrderById($stateParams.id)
    .then(function (response) {
      $scope.orderDetailModel = response;
    });
  //Initials-End

  //Functions
  $scope.calculateDiscount = function (order) {
    if (angular.isUndefined(order)) return;

    var percentDiscount = parseFloat(((order.orderDetails.discount / order.orderDetails.packagePrice) * 100).toFixed(2));
    if (percentDiscount <= 0) {
      return 0
    };

    return percentDiscount;
  }
  //Functions - End
};

module.exports = function (app) {
  app.controller('orderDetailsCtrl', ['$scope', '$stateParams', 'subscriptionFactory', orderDetailsCtrl]);
};
