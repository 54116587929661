var uiRouter = function($stateProvider, $urlRouterProvider, $locationProvider) {
    $stateProvider
        .state('app.backUpOptions', {
            url: '/backup-options',
            templateUrl: 'app/components/backup/backupOptions/views/backupOptions.html',
            controller: "backupOptionsCtrl",
            permission: {
                requiredPermissions: ['View BackUp']
            }
        });
};

module.exports = function(app) {
    app.config(['$stateProvider', '$urlRouterProvider', '$locationProvider', uiRouter]);
};
