var run = function ($rootScope) {
    $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
        if (toState.name == "pdf.previewDatasheet" ||
            toState.name == "pdf.previewTemplate" ||
            toState.name == "pdf.viewDatasheet" ||
            toState.name == "pdf.view" ||
            toState.name == "pdf.viewWithRoute" ||
            toState.name == "pdf.viewPreview" ||
            toState.name == "pdf.viewCustomFile" ||
            toState.name == "pdf.preparing")  {
                var intercomInterval;
                $(document).ready(function () {
                    intercomInterval = setInterval(function () {
                        var $intercomContainer = $('#intercom-container');
                        if ($intercomContainer.length) {
                            $('#intercom-container').hide();
                            clearInterval(intercomInterval);
                        }
                    }, 0);
        
                });

            }

    });   
    
    
};
    
module.exports = function (app) {
    app.run(['$rootScope', run]);
};
    