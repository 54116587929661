var languagePickerInHeaderCtrl = function ($rootScope, $scope, $state, $modal, locales, userFactory, intercomFactory, localStorageService) {
    //Fields
    var languageCode = $rootScope.activeLang;
    //Fields

    //Functions
    $scope.changeLanguage = function (language) {
        $rootScope.activeLang = language.LanguageCode;
        $rootScope.$emit('changedLanguage', {
            languageCode: language.LanguageCode
        });

        userFactory.changeLanguage(language.LanguageCode)
            .then(function (response) {
                var userData = localStorageService.get('userInformationData');
                userData.Language = language.LanguageCode;
                localStorageService.set('userInformationData', userData);

                var hash = CryptoJS.HmacSHA256(userData._id, "O7B-0Ex_OM-j-GINwCn7Ok9qIoTykVSntBm4PzRM");
                var base64 = hash.toString();

                intercomFactory.updateUser({
                    user_id: userData._id,
                    custom_attributes: {
                        language: userData.Language
                    }
                });

                $state.go($state.current, {
                    lang: language.LanguageCode
                }, {
                        reload: true
                    });
            });
    };

    $scope.openLanguagePickerModal = function () {
        var modalInstance = $modal.open({
            animation: false,
            backdrop: 'static',
            controller: 'languagePickerCtrl',
            templateUrl: 'app/components/core.translate/shared/views/modals/languagePicker.html'
        });

        modalInstance.result
            .then(function (result) {
                if (result.status) {

                }
            });
    };
    //Functions End

    //Initials
    $scope.mainLocales = [];
    $scope.additionalLocales = [];
    for (var i = 0; i < locales.length; i++) {
        var locale = locales[i];

        if (locale.Main || locale.LanguageCode == languageCode) {
            $scope.mainLocales.push(locale);
        } else {
            $scope.additionalLocales.push(locale);
        }
    }
    //Initials End
};

module.exports = function (app) {
    app.controller('languagePickerInHeaderCtrl', ['$rootScope', '$scope', '$state', '$modal', 'locales', 'userFactory', 'intercomFactory', 'localStorageService', languagePickerInHeaderCtrl]);
};
