var select2Raw = function($translate){

    return {
        restrict: 'AE',
        link: function (scope, el, attrs) {

          $(el).find('option').each(function() {
              var innerHtml = $(this).html();
              innerHtml = $translate.instant(innerHtml);
              $(this).html(innerHtml);
          });

          var disableSearch = false;

          if(angular.isDefined(attrs.disableSearch))
              disableSearch = attrs.disableSearch;

          var selectInstance;
          var setSelect2 = function(){
              if(disableSearch)
                selectInstance = $(el).select2({minimumResultsForSearch : -1});
              else
                selectInstance = $(el).select2();
          };

          setSelect2();

          scope.$on('triggerSelect2Raw', function(){
            selectInstance.trigger('change');
          });
        }
    };
};

module.exports = function(app){
    app.directive('select2Raw', ['$translate', select2Raw]);
};
