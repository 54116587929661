var uiRouter = function ($windowProvider, $stateProvider, $urlRouterProvider, $locationProvider, locales) {
    $stateProvider
        .state('auth.signIn', {
            url: '/sign-in',
            templateUrl: "/app/components/authentication/signIn/views/signIn.html",
            controller: 'signInCtrl'
        })
        .state('auth.signInAuto', {
            url: '/sign-in/:id/:pass',
            templateUrl: '/app/components/authentication/signIn/views/signIn.html',
            controller: 'signInCtrl'
        })
        .state('auth.signUp', {
            url: '/sign-up',
            templateUrl: '/app/components/authentication/signUp/views/signUp.html',
            controller: 'signUpCtrl'
        })
        .state('auth.verifyEmail', {
            url: '/email-validation/:code?',
            templateUrl: '/app/components/authentication/validate/views/validate.html',
            controller: 'validateCtrl'
        })
        .state('auth.verifyChangeEmail', {
            url: '/email-change-validation/:code?',
            templateUrl: '/app/components/authentication/validateChangedEmail/views/validateChangedEmail.html',
            controller: 'validateChangedEmailCtrl'
        })
        .state('auth.lastStep', {
            url: '/last-step/:id',
            templateUrl: '/app/components/authentication/lastStep/views/lastStep.html',
            controller: 'lastStepCtrl'
        })
        .state('auth.confirmInvitation', {
            url: '/confirm-invitation/:id',
            templateUrl: '/app/components/authentication/confirmInvitation/views/confirmInvitation.html',
            controller: 'confirmInvitationCtrl'
        })
        .state('auth.resetPassword', {
            url: '/reset-password',
            templateUrl: '/app/components/authentication/resetPassword/views/resetPassword.html',
            controller: 'resetPasswordCtrl'
        });

    //Default route
    // $locationProvider.html5Mode(true);

    var $window = $windowProvider.$get();
    var languageCodeWithCountry = $window.navigator.language;
    if (languageCodeWithCountry != null && angular.isDefined(languageCodeWithCountry) && languageCodeWithCountry.indexOf("-") >= 0) {
        var languageCode = languageCodeWithCountry.split('-')[0];
        var newUrl = "/en/auth/sign-in";
        for (var i = 0; i < locales.length; i++) {
            var locale = locales[i];
            if (locale.LanguageCode == languageCode) {
                newUrl = "/" + languageCode + "/auth/sign-in";
            }
        }
        // $urlRouterProvider.otherwise(newUrl);
    } else {
        // $urlRouterProvider.otherwise("/en/auth/sign-in");
    }




};

module.exports = function (app) {
    app.config(['$windowProvider', '$stateProvider', '$urlRouterProvider', '$locationProvider', 'locales', uiRouter]);
};
