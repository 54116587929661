var groupUsersCtrl = function($scope, $state, $stateParams, $timeout, $translate, $modal, SweetAlert, jobTitleGroups, menuToggleFactory, groupFactory) {
    //Fields
    $scope.view = {
        name: "Table"
    };
    //Fields End

    //Events
    $scope.$on('$viewContentLoaded', function() {
        $timeout(function() {
            menuToggleFactory.collapse();
        }, 100);
    });
    //Events End

    //Functions
    $scope.changeView = function(viewName) {
        $scope.view.name = viewName;
    };

    $scope.openAddUserToGroupModal = function() {
        var modalInstance = $modal.open({
            animation: false,
            backdrop: 'static',
            controller: 'addUserToGroupCtrl',
            templateUrl: 'app/components/groups/groupUsers/views/modals/addUserToGroup.html',
            resolve: {
                groupId: function() {
                    return $stateParams.groupId;
                }
            }
        });

        modalInstance.result
            .then(function(result) {
                if (result.status) {
                    getGroupUsersById($stateParams.groupId);
                }
            });
    };

    $scope.openDeleteUserFromGroupModal = function(userId) {
        //Eğer administrators grubu ise ve son bir kullanıcı kaldıysa silinemez uyarısı ver.
        if ($scope.group.Name == "Administrators" && $scope.users.length == 1) {
            var title = $translate.instant("GROUP.GROUPUSERS.ERROR_MESSAGES.NOT_REMOVED_LAST_USER_FROM_ADMINISTRATORS.LBL_TITLE.TEXT");
            var message = $translate.instant("GROUP.GROUPUSERS.ERROR_MESSAGES.NOT_REMOVED_LAST_USER_FROM_ADMINISTRATORS.LBL_EXPLANATION.TEXT");
            SweetAlert.swal(title, message, "error")
            return;
        }

        var modalInstance = $modal.open({
            animation: false,
            backdrop: 'static',
            controller: 'deleteUserFromGroupCtrl',
            templateUrl: 'app/components/groups/groupUsers/views/modals/deleteUserFromGroup.html',
            resolve: {
                groupId: function() {
                    return $stateParams.groupId;
                },
                userId: function() {
                    return userId;
                }
            }
        });

        modalInstance.result
            .then(function(result) {
                if (result.status) {
                    getGroupUsersById($stateParams.groupId);
                }
            });
    };

    $scope.getUserJobTitle = function(userJobTitle) {

        for (var i = 0; i < jobTitleGroups.length; i++) {
            var jobTitleGroup = jobTitleGroups[i];
            for (var j = 0; j < jobTitleGroup.JobTitles.length; j++) {
                var jobTitle = jobTitleGroup.JobTitles[j];
                if (jobTitle.Name == userJobTitle) {
                    return $translate.instant("USER.USERPROFILE.PARTIALS.USERINFORMATIONS.CONSTANTS.JOBTITLEGROUPS." + jobTitleGroup.LanguageCode + ".JOBTITLES." + jobTitle.LanguageCode + ".TEXT");
                }

            }
        }

        return "";
    };
    
    //Functions End

    //Inner Functions
    var getGroupById = function(groupId) {
        groupFactory.getGroupById(groupId)
            .then(function(response) {
                $scope.group = response;
            });
    };

    var getGroupUsersById = function(groupId) {
        $scope.tdsMakerPromise.groupUsers = {};
        $scope.tdsMakerPromise.groupUsers.promise = groupFactory.getGroupUsersById(groupId)
            .then(function(response) {
                
                $scope.users = response;

                for (var i = 0; i < $scope.users.length; i++) {
                    var user = $scope.users[i];

                    if (angular.isUndefined(user.ProfilePicture) || user.ProfilePicture == null || user.ProfilePicture == "") {
                        user.ProfilePicture = "assets/images/default-user.png";
                    }
                }
            });
    };
    //Inner Functions End

    //Initials
    getGroupById($stateParams.groupId);
    getGroupUsersById($stateParams.groupId);
    //Initials End
};

module.exports = function(app) {
    app.controller('groupUsersCtrl', ['$scope', '$state', '$stateParams', '$timeout', '$translate', '$modal', 'SweetAlert', 'jobTitleGroups', 'menuToggleFactory', 'groupFactory', groupUsersCtrl]);
};
