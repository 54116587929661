var folderPermissionsCtrl = function($rootScope, $scope, $state, $stateParams, $timeout, $translate, $modal, ngNotify, menuToggleFactory, folderFactory, permissionFactory, _) {
    //Fields
    $scope.lblFolderPermissionTranslationData = {};
    //Fields End

    //Events
    $scope.$on('$viewContentLoaded', function() {
        $timeout(function() {
            menuToggleFactory.collapse();
        }, 100);
    });

    $scope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams) {
        if (fromState.name == "app.folderPermissions") {
            $(".ngn").fadeOut(2000);
        }
    });
    //Events End

    //Functions
    $scope.back = function(folderType) {
        if (folderType == "Datasheet") {
            $state.go('app.yourDatasheets');
        } else if (folderType == "Template") {
            $state.go('app.yourTemplates');
        }
    };

    $scope.openGrantPermissionModal = function() {
        var modalInstance = $modal.open({
            animation: false,
            backdrop: 'static',
            controller: 'grantPermissionCtrl',
            templateUrl: 'app/components/permissions/shared/views/modals/grantPermission.html',
            resolve: {
                viewScope: function() {
                    return $scope;
                }
            }
        });

        modalInstance.result
            .then(function(response) {
                if (response.status) {
                    getAllItemPermissions($stateParams.folderId);
                }
            });
    };

    $scope.grantPermissionModalOkClicked = function(selectedGroupsOrUsers, selectedPermissionLevels, nextProcess) {
        var selectedPermissions = [];
        var itemPermissions = [];
        for (var i = 0; i < selectedGroupsOrUsers.length; i++) {
            var selectedGroupOrUser = selectedGroupsOrUsers[i];

            var itemPermission = {};
            itemPermission.ItemId = $scope.folder._id;
            itemPermission.Level = "Item";
            itemPermission.Type = getItemType();
            itemPermission.ReferanceId = selectedGroupOrUser._id;
            itemPermission.IsGroup = selectedGroupOrUser.IsGroup;
            itemPermission.PermissionLevels = _.map(selectedPermissionLevels, function(selectedPermissionLevel) {
                selectedPermissions = _.union(selectedPermissions, selectedPermissionLevel.Permissions);

                return selectedPermissionLevel._id
            });


            itemPermissions.push(itemPermission);
        }

        permissionFactory.saveAccountItemPermissions(itemPermissions)
            .then(function(response) {
                if ($scope.folder.FolderType == "Datasheet") {
                    permissionFactory.getAllPermissions()
                        .then(function(permissions) {
                            var newDatasheetPermission = _.find(permissions, function(permission) {
                                return permission.Name == "New Datasheet"
                            });
                            var isSelectedNewDatasheet = selectedPermissions.indexOf(newDatasheetPermission._id) >= 0;

                            if (isSelectedNewDatasheet) {
                                showOnlyTemplateViewNotify();
                            }

                            nextProcess(response);
                        });
                } else {
                    nextProcess(response);
                }
            });
    };

    //Edit Permission ı aç,
    $scope.openEditPermissionModal = function(permissionId) {
        var modalInstance = $modal.open({
            animation: false,
            controller: 'editPermissionCtrl',
            templateUrl: 'app/components/permissions/shared/views/modals/editPermission.html',
            backdrop: 'static',
            resolve: {
                viewScope: function() {
                    return $scope;
                },
                permissionId: function() {
                    return permissionId;
                }
            }
        });

        modalInstance.result
            .then(function(response) {
                getAllItemPermissions($stateParams.folderId);
            });
    };
    //Edit Permission Modal açıldıktan sonra,
    $scope.editPermissionModalOpened = function(permissionId, nextProcess) {
        permissionFactory.getItemPermissionById(permissionId)
            .then(function(response) {
                var selectedGroupsOrUsers = [];
                if (response.IsGroup) {
                    selectedGroupsOrUsers.push({
                        "_id": response.Referance._id,
                        "Name": response.Referance.Name,
                        "IsGroup": response.IsGroup
                    });
                } else {
                    var itemName = "";
                    if (angular.isDefined(response.Referance.FirstName) && angular.isDefined(response.Referance.LastName)) {
                        itemName = response.Referance.Email + '(' + response.Referance.FirstName + ' ' + response.Referance.LastName + ')';
                    } else {
                        itemName = response.Referance.Email;
                    }

                    selectedGroupsOrUsers.push({
                        "_id": response.Referance._id,
                        "Name": itemName,
                        "IsGroup": response.IsGroup
                    });
                }

                var permissionLevels = response.PermissionLevels;
                nextProcess(selectedGroupsOrUsers, permissionLevels);
            });
    };
    //Edit Permission Modal'da OK e basıldığında,
    $scope.editPermissionModalOkClicked = function(selectedGroupsOrUsers, selectedPermissionLevels, nextProcess) {
        var selectedPermissions = [];
        var itemPermissions = [];
        for (var i = 0; i < selectedGroupsOrUsers.length; i++) {
            var selectedGroupOrUser = selectedGroupsOrUsers[i];

            var itemPermission = {};
            itemPermission.ItemId = $scope.folder._id;
            itemPermission.Type = getItemType();
            itemPermission.ReferanceId = selectedGroupOrUser._id;
            itemPermission.IsGroup = selectedGroupOrUser.IsGroup;
            itemPermission.PermissionLevels = _.map(selectedPermissionLevels, function(selectedPermissionLevel) {
                selectedPermissions = _.union(selectedPermissions, selectedPermissionLevel.Permissions);

                return selectedPermissionLevel._id
            });

            itemPermissions.push(itemPermission);
        }

        permissionFactory.saveAccountItemPermissions(itemPermissions)
            .then(function(response) {
                if ($scope.folder.FolderType == "Datasheet") {
                    permissionFactory.getAllPermissions()
                        .then(function(permissions) {
                            var newDatasheetPermission = _.find(permissions, function(permission) {
                                return permission.Name == "New Datasheet"
                            });
                            var isSelectedNewDatasheet = selectedPermissions.indexOf(newDatasheetPermission._id) >= 0;

                            if (isSelectedNewDatasheet) {
                                showOnlyTemplateViewNotify();
                            }

                            nextProcess(response);
                        });
                } else {
                    nextProcess(response);
                }
            });
    };

    //Delete Permission'ı aç,
    $scope.openDeletePermissionModal = function(permissionId) {
        var modalInstance = $modal.open({
            animation: false,
            controller: 'deletePermissionCtrl',
            templateUrl: 'app/components/permissions/shared/views/modals/deletePermission.html',
            backdrop: 'static',
            resolve: {
                viewScope: function() {
                    return $scope;
                },
                permissionId: function() {
                    return permissionId;
                }
            }
        });

        modalInstance.result
            .then(function(response) {
                getAllItemPermissions($stateParams.folderId);
            });
    };
    $scope.deletePermissionModalDeleteClicked = function(permissionId, nextProcess) {
        permissionFactory.deleteAccountItemPermission(permissionId)
            .then(function(response) {
                nextProcess(response);
            });
    };
    //Functions End

    //Inner Functions
    var getFolderById = function(folderId) {
        folderFactory.getFolderById(folderId)
            .then(function(response) {
                $scope.folder = response;

                if ($scope.folder.Name == "Archive") {
                    $scope.lblFolderPermissionTranslationData.FolderName = $translate.instant("FOLDER.FOLDERPERMISSIONS.LBL_ARCHIVE.TEXT");
                } else {
                    $scope.lblFolderPermissionTranslationData.FolderName = $scope.folder.Name;
                }

            });
    };

    var getItemType = function() {
        if ($scope.folder.FolderType == "Datasheet") {
            return ["DatasheetFolder"];
        } else {
            return ["TemplateFolder"];
        }
    };

    var getAllItemPermissions = function(folderId) {
        $scope.tdsMakerPromise.folderPermissions = {};
        $scope.tdsMakerPromise.folderPermissions.message = $translate.instant("FOLDER.FOLDERPERMISSIONS.INFO_MESSAGES.LOADING_FOLDER_PERMISSIONS");
        $scope.tdsMakerPromise.folderPermissions.promise = permissionFactory.getAllItemPermissions(folderId)
            .then(function(response) {
                $scope.itemPermissions = response;


            });
    };

    var showOnlyTemplateViewNotify = function() {
        var url = $rootScope.activeLang + "/app/your-templates";
        var onlyTemplateViewMessage = $translate.instant("FOLDER.FOLDERPERMISSIONS.WARNING_MESSAGES.GRANT_FOR_TEMPLATES");
        onlyTemplateViewMessage = onlyTemplateViewMessage.replace("#url#", url);

        ngNotify.set(onlyTemplateViewMessage, {
            type: 'warn',
            position: 'top',
            sticky: true,
            html: true,
            duration: 0
        });
    };
    //Inner Functions End

    //Initials
    getFolderById($stateParams.folderId);
    getAllItemPermissions($stateParams.folderId);
    //Initials End
};

module.exports = function(app) {
    app.controller('folderPermissionsCtrl', ['$rootScope', '$scope', '$state', '$stateParams', '$timeout', '$translate', '$modal', 'ngNotify', 'menuToggleFactory', 'folderFactory', 'permissionFactory', '_', folderPermissionsCtrl]);
};
