var moveArchiveFileCtrl = function($scope, $translate, $modalInstance, folderFactory, archiveFileFactory, customArchiveId, archiveFile) {
    //Fields
    $scope.translatedArchive = $translate.instant("CUSTOMARCHIVES.YOURARCHIVES.MODALS.MOVEARCHIVEFILE.LBL_ARCHIVE.TEXT");
    $scope.rootFolder = {};
    $scope.selectedFolder = {};
    $scope.datasheetFolders = [];

    $scope.okButton = "CUSTOMARCHIVES.YOURARCHIVES.MODALS.MOVEARCHIVEFILE.BTN_OK.TEXT";
    $scope.isProcessing = false;

    $scope.loaded = {
        archive: {
            folders: false
        }
    };
    //Fields End

    //Inner Functions
    var getArchiveFolders = function(customArchiveId, nextProcess) {
        $scope.loaded.archive.folders = false;
        folderFactory.getArchiveFolders(customArchiveId)
            .then(function(response) {

                $scope.loaded.archive.folders = true;

                $scope.archiveFolders = response;
                for (var i = 0; i < $scope.archiveFolders.length; i++) {
                    if ($scope.archiveFolders[i].Parent == "#") {
                        $scope.archiveFolders[i].Name = $scope.translatedArchive;
                        $scope.rootFolder = $scope.archiveFolders[i];
                        break;
                    }
                }

                for (var i = 0; i < $scope.archiveFolders.length; i++) {
                    var archiveFolder = $scope.archiveFolders[i];
                    archiveFolder.id = archiveFolder._id;
                    archiveFolder.text = archiveFolder.Name;
                    archiveFolder.parent = archiveFolder.Parent;
                    archiveFolder.state = {
                        disabled: false
                    };
                }
                nextProcess();
            });
    };
    //Inner Functions End

    //Functions
    $scope.cancel = function() {
        $modalInstance.dismiss();
    };

    $scope.moveArchiveFile = function() {
        $scope.okButton = "CUSTOMARCHIVES.YOURARCHIVES.MODALS.MOVEARCHIVEFILE.BTN_OK.ALTERNATE_TEXT";
        $scope.isProcessing = true;

        var archiveFileId = archiveFile._id;
        var folderId = $scope.selectedFolder.id;
        archiveFileFactory.moveArchiveFile(archiveFileId, folderId)
            .then(function(response) {
                $scope.okButton = "CUSTOMARCHIVES.YOURARCHIVES.MODALS.MOVEARCHIVEFILE.BTN_OK.ALTERNATE_TEXT";
                $scope.isProcessing = false;

                if (response.status) {
                    var message = $translate.instant("CUSTOMARCHIVES.YOURARCHIVES.MODALS.MOVEARCHIVEFILE.SUCCESS_MESSAGES.MOVED_SUCCESSFULLY");
                    toastr.success(message, '', { positionClass: 'toast-bottom-full-width', timeOut: 3000 });
                    $modalInstance.close({
                        status: true
                    });
                }
            });
    };
    //Functions End

    //Initials
    getArchiveFolders(customArchiveId, function() {
        $scope.folders = $scope.archiveFolders;
    });
    //Initials End
};

module.exports = function(app) {
    app.controller('moveArchiveFileCtrl', ['$scope', '$translate', '$modalInstance', 'folderFactory', 'archiveFileFactory', 'customArchiveId', 'archiveFile', moveArchiveFileCtrl]);
};
