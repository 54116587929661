var httpProvider = function($http, $q, config, environment) {
  this.token = function(model) {
    var deferred = $q.defer()

    var url = config.api[config.mode].fullApiUrl + 'token'
    console.log('url', config.api[config.mode])

    $http.post(url, model).then(
      function(response) {
        deferred.resolve(response.data)
      },
      function(error, msg) {
        deferred.reject(msg)
      }
    )

    return deferred.promise
  }

  this.put = function(routeName, functionName, model) {
    var deferred = $q.defer()
    var url =
      config.api[config.mode].fullApiUrl + routeName + '/' + functionName
    $http.put(url, model).then(
      function(response) {
        deferred.resolve(response.data)
      },
      function(error, msg) {
        deferred.reject(msg)
      }
    )

    return deferred.promise
  }

  this.post = function(routeName, functionName, model) {
    var deferred = $q.defer()
    var url =
      config.api[config.mode].fullApiUrl + routeName + '/' + functionName

    $http.post(url, model).then(
      function(response) {
        deferred.resolve(response.data)
      },
      function(error, msg) {
        deferred.reject(msg)
      }
    )

    return deferred.promise
  }

  this.get = function(routeName, functionName, params) {
    var deferred = $q.defer()
    var url = ''
    if (params !== undefined)
      url =
        config.api[config.mode].fullApiUrl +
        routeName +
        '/' +
        functionName +
        '?' +
        params
    else
      url = config.api[config.mode].fullApiUrl + routeName + '/' + functionName
    var res = $http.get(url)

    res.success(function(data, status, headers, config) {
      deferred.resolve(data)
    })

    res.error(function(msg, code) {
      deferred.reject(msg)
    })

    return deferred.promise
  }

  this.getNonQuery = function(routeName, functionName, params) {
    var deferred = $q.defer()
    var url =
      config.api[config.mode].fullApiUrl +
      routeName +
      '/' +
      functionName +
      '/' +
      params
    var res = $http.get(url)

    res.success(function(data, status, headers, config) {
      deferred.resolve(data)
    })

    res.error(function(msg, code) {
      deferred.reject(msg)
    })

    return deferred.promise
  }

  this.getLocal = function(jsonName) {
    var deferred = $q.defer()
    var res = $http.get(jsonName)

    res.success(function(data, status, headers, config) {
      deferred.resolve(data)
    })

    res.error(function(msg, code) {
      deferred.reject(msg)
    })

    return deferred.promise
  }

  this.delete = function(routeName, functionName, id) {
    var deferred = $q.defer()
    var url =
      (environment.PRIVATE_API_FULL_URL || config.api[config.mode].fullApiUrl) +
      routeName +
      '/' +
      functionName +
      '/' +
      id

    $http.delete(url).then(
      function(response) {
        deferred.resolve(response)
      },
      function(err, msg) {
        deferred.reject(msg)
      }
    )

    return deferred.promise
  }

  this.deleteWithParam = function(routeName, functionName, parameter) {
    var deferred = $q.defer()
    var url =
      (environment.PRIVATE_API_FULL_URL || config.api[config.mode].fullApiUrl) +
      routeName +
      '/' +
      functionName

    $http.delete(url, parameter).then(
      function(response) {
        deferred.resolve(response)
      },
      function(err, msg) {
        deferred.reject(msg)
      }
    )

    return deferred.promise
  }
}

module.exports = function(app) {
  app.service('httpProvider', [
    '$http',
    '$q',
    'config',
    'environment',
    httpProvider,
  ])
}
