var pdfFourPaneLayout = function () {
    return {
        restrict: 'E',
        replace: true,
        templateUrl: 'app/components/pdf/shared/directives/pdfLayouts/tpl/pdfFourPaneLayout.html',
        link: function (scope, elem, attrs) {

        }
    }
};

module.exports = function (app) {
    app.directive('pdfFourPaneLayout', [pdfFourPaneLayout]);
};