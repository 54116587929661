var statisticFactory = function(httpProvider) {
    var factoryModel = {};

    //Functions
    factoryModel.getSummaryInformations = function() {
        return httpProvider.get('statistic', 'getSummaryInformations');
    };

    factoryModel.getUserSummaryInformationsByUserId = function(userId) {
        var params = "userId=" + userId;
        return httpProvider.get('statistic', 'getUserSummaryInformationsByUserId', params);
    };
    //Functions End

    return factoryModel;

};

module.exports = function(app) {
    app.factory('statisticFactory', ['httpProvider', statisticFactory]);
};
