var datasheetBody = function($compile, datasheetViewService, datasheetViewCacheService) {
    return {
        restrict: 'E',
        replace: true,
        templateUrl: 'app/components/datasheet/shared/directives/datasheetBody/datasheetBody.html',
        link: function(scope, elem, attrs) {
            //Fields 
            //Fields End

            //Inner Functions
            var setBodyStyle = function() {
                elem.css(scope.page.bodyStyle);
            };

            var setLayout = function() {
                var pageLayout = scope.page.layout;
                var pageLayoutHtml = datasheetViewService.createDatasheetLayout(pageLayout);
                var childScope = scope.$new();

                $(elem).append($compile(pageLayoutHtml)(childScope));

            };
            //Inner Functions End

            //Initials
            setBodyStyle();
            setLayout();
            //Initials End
        }
    };
};

module.exports = function(app) {
    app.directive('datasheetBody', ['$compile', 'datasheetViewService', 'datasheetViewCacheService', datasheetBody]);
};