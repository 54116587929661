var uiRooter = function ($stateProvider, $urlRouterProvider, $locationProvider) {
    $stateProvider
        .state('app.yourTemplates', {
            url: '/your-templates',
            templateUrl: "/app/components/template/yourTemplates/views/yourTemplates.html",
            controller: "yourTemplatesCtrl",
            permission: {
                requiredPermissions: ['View Template', 'View Template Folder'],
            },
            onExit: function () {
                $('body').css('overflow-y', 'auto');
            }
        })
        .state('app.newTemplate', {
            url: '/new-template',
            templateUrl: "/app/components/template/newTemplate/views/newTemplate.html",
            controller: "newTemplateCtrl",
            permission: {
                requiredPermissions: ['New Template'],
            },
            onExit: function () {
                /* $('body').css('overflow-y', 'auto'); */
            }
        })
        .state('app.copyTemplate', {
            url: '/copy-template/:templateId',
            templateUrl: "/app/components/template/newTemplate/views/newTemplate.html",
            controller: "newTemplateCtrl",
            permission: {
                requiredPermissions: ['Copy Template'],
            },
            params: {moveSettings: null},
            onExit: function () {
                $('body').css('overflow-y', 'auto');
            }
        })
        .state('editor.editTemplate', {
            url: '/edit-template/:id',
            templateUrl: "/app/components/template/editTemplate/views/editTemplate.html",
            controller: "editTemplateCtrl",
            permission: ['Edit Template'],
            onEnter: ['menuToggleFactory', function (menuToggleFactory) {
                $('body').css('overflow-y', 'hidden');
                menuToggleFactory.collapse();
            }],
            onExit: ['menuToggleFactory', function (menuToggleFactory) {
                $.contextMenu('destroy');
                $('body').css('overflow-y', 'auto');
                menuToggleFactory.doPreviousStatus();
            }]
        });

    /* removeIfProduction */
    // $urlRouterProvider.otherwise("/en/app/your-templates");
    /* endRemoveIfProduction */
};

module.exports = function (app) {
    app.config(['$stateProvider', '$urlRouterProvider', '$locationProvider', uiRooter]);
};
