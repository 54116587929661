var previewDatasheetCtrl = function ($scope, $stateParams, datasheetFactory, datasheetDataEditingService) {
    //Fields 
    var datasheetId = $stateParams.id;
    var language = $stateParams.datasheetLang;

    $scope.pdfModel = {
        mode: 'datasheet-preview'
    };
    $scope.selectionModel = {
        selectedLanguage: language
    };

    $scope.loading = {
        pdf: false
    };
    //Fields End

    //Events
    $(document).off("pdfGenerated").on('pdfGenerated', function (event, data) {
        setTimeout(function () {
            // $(window).load(function () {
            window.status = "printPage";

            //PhantomJS
            if (typeof window.callPhantom === 'function') {
                window.callPhantom({ printStatus: 'ready' });
            }
            // });
        }, 1000);

    });
    //Events End

    //Inner Functions
    var initializePdfModel = function () {
        $scope.loading.pdf = false;
        getDatasheetById(datasheetId, function (response) {
            $scope.pdfModel.datasheetModel = response.datasheetModel;
            $scope.pdfModel.templateModel = response.templateModel;

            $scope.pdfModel.elements = [];
            var elements = datasheetDataEditingService.mergeDatasheetAndTemplateElements(response.datasheetModel.elements, response.templateModel.elements);

            for (var e = 0; e < elements.length; e++) {
                var element = elements[e];

                if (!element.isHidden) {
                    $scope.pdfModel.elements.push(element);
                }
                else {
                    if (element.type == "row") {
                        $scope.pdfModel.elements.push(element);
                    }
                }
            };

            $(document).trigger("pdfModelLoaded", { pdfModel: $scope.pdfModel, selectionModel: $scope.selectionModel });
            $scope.loading.pdf = true;
        });
    };

    var getDatasheetById = function (datasheetId, nextProcess) {
        datasheetFactory.getDatasheetByIdAnounmously(datasheetId)
            .then(function (response) {
                nextProcess(response);
            });
    };
    //Inner Functions End

    //Functions
    //Functions End

    //Initials 
    initializePdfModel();
    //Initials End
};

module.exports = function (app) {
    app.controller('previewDatasheetCtrl', ['$scope', '$stateParams', 'datasheetFactory', 'datasheetDataEditingService', previewDatasheetCtrl]);
};