var datasheetElementService = function($filter, $translate, generalsService, _) {
    //Fields 
    var serviceModel = {};
    var copiedRow = {};
    //Fields End

    //Functions
    serviceModel.getDatasheetElement = function(datasheetModel, element) {
        var datasheetElement;
        if (element != null && element != undefined) {
            var elementId = element._id;
            var elementName = element.name;
            var elementType = element.type;

            if (elementType == "page" || elementType == "row") {
                datasheetElement = _.find(datasheetModel.elements, {
                    "templateElementId": elementId
                });
            }
            else if (elementType == "table" || elementType == "text" || elementType == "inputtext" ||
                elementType == "selectlist" || elementType == "image" || elementType == "inputimage" || elementType == "icon" || elementType == "inputicon" || elementType == "box") {
                datasheetElement = _.find(datasheetModel.elements, {
                    "name": elementName
                });
            }
        }

        return datasheetElement;
    };

    //Text
    serviceModel.setConstantFields = function(datasheetModel, page, elementValue, language) {
        //Product Number
        if (elementValue.indexOf("#Product-Number#") >= 0)
            elementValue = elementValue.replace(new RegExp("#Product-Number#", 'g'), datasheetModel.datasheet.productNumber);

        //Product Name
        if (elementValue.indexOf("#Product-Name#") >= 0) {
            var datasheetName = _.find(datasheetModel.datasheet.names, {
                "language": language
            });
            elementValue = elementValue.replace(new RegExp("#Product-Name#", 'g'), datasheetName.name);
        }

        //Publish Date
        if (elementValue.indexOf("#Publish-Date#") >= 0) {
            var publishDate = $filter('date')(datasheetModel.datasheet.dateModified, "dd.MM.yyyy");
            elementValue = elementValue.replace(new RegExp("#Publish-Date#", 'g'), publishDate);
        }

        //Revision Number
        if (elementValue.indexOf("#Revision-Number#") >= 0) {
            var revisionNumber = datasheetModel.datasheet.majorVersion >= 10 ? datasheetModel.datasheet.majorVersion.toString() : "0" + datasheetModel.datasheet.majorVersion;
            elementValue = elementValue.replace(new RegExp("#Revision-Number#", 'g'), revisionNumber);
        }

        //Page-No
        if (elementValue.indexOf("#Page-No#") >= 0) {
            var pages = _.filter(datasheetModel.elements, {
                "type": "page"
            });
            var orderedPages = _.sortBy(pages, 'order');
            var pageOrder = pages.indexOf(page) + 1;
            var pageNo = pageOrder > 10 ? pageOrder : "0" + pageOrder;
            elementValue = elementValue.replace(new RegExp("#Page-No#", 'g'), pageNo);
        }

        //Total-Page
        if (elementValue.indexOf("#Total-Page#") >= 0) {
            var totalPage = _.countBy(datasheetModel.elements, {
                "type": "page"
            })[true];
            totalPage = totalPage >= 10 ? totalPage.toString() : "0" + totalPage;
            elementValue = elementValue.replace(new RegExp("#Total-Page#", 'g'), totalPage);
        }

        return elementValue;
    };

    //InputText
    serviceModel.getInputTextPlaceHolder = function(element, language) {
        var placeHolder = "";
        if (angular.isDefined(element.placeHolders) && angular.isDefined(element.placeHolders[language]))
            placeHolder = element.placeHolders[language];

        //Input text'in placeHolder değeri yoksa dile göre default olanı getir...
        if (placeHolder == "") {
            placeHolder = serviceModel.getInputTextDefaultPlaceHolder(language);
        }
        return placeHolder;

    };

    serviceModel.getInputTextDefaultPlaceHolder = function(language) {
        var placeHolder = "";
        switch (language) {
            case "en":
                placeHolder = "Text here";
                break;
            case "tr":
                placeHolder = "Buraya yazınız";
                break;
            case "es":
                placeHolder = "Escribe aquí..."
                break;
            case "de":
                placeHolder = "Schreiben sie hier";
                break;
            case "fr":
                placeHolder = "Ecrivez ici...";
                break;
            case "ru":
                placeHolder = "Напишите здесь...";
                break;
            case "it":
                placeHolder = "Scrivi qui...";
                break;
            case "hu":
                placeHolder = "Írja ide...";
                break;
            case "zh-CN":
                placeHolder = "写到这里";
                break;
            case "el":
                placeHolder = "Γράψτε εδώ...";
                break;
            case "vi":
                placeHolder = "viết ở đây...";
                break;
            case "id":
                placeHolder = "Teks di sini...";
                break;
            default:
                placeHolder = "Buraya yazı yazın..."
                break;
        }

        return placeHolder;
    };

    serviceModel.getInputTextTooltip = function(language, element) {
        var tooltip = "";
        if (angular.isDefined(element.placeHolders) && angular.isDefined(element.placeHolders[language]))
            tooltip = element.placeHolders[language];
        if (tooltip == "")
            tooltip = serviceModel.getInputTextDefaultPlaceHolder(language);
        return $.parseHTML(tooltip)[0];
    };

    //Input Image
    serviceModel.getInputImageDefaultText = function(language) {
        var inputImageText = {};
        switch (language) {
            case "en":
                inputImageText = {
                    image: "Image",
                    clickToUpload: "Click to upload image!"
                }
                break;
            case "tr":
                inputImageText = {
                    image: "Resim",
                    clickToUpload: "Resim yüklemek için tıklayınız."
                }
                break;
            case "es":
                inputImageText = {
                    image: "Imagen",
                    clickToUpload: "Haz clic para subir la imagen"
                }
                break;
            case "de":
                inputImageText = {
                    image: "Image",
                    clickToUpload: "Klicken Sie, um das Bild hochzuladen"
                }
                break;
            case "fr":
                inputImageText = {
                    image: "Image",
                    clickToUpload: "Cliquez pour télécharger l'image"
                }
                break;
            case "ru":
                inputImageText = {
                    image: "Образ",
                    clickToUpload: "Нажмите, чтобы загрузить изображение"
                }
                break;
            case "it":
                inputImageText = {
                    image: "Immagine",
                    clickToUpload: "Clicca per caricare immagine"
                }
                break;
            case "hu":
                inputImageText = {
                    image: "Kép",
                    clickToUpload: "Kattintson feltölteni a képet"
                }
                break;
            case "zh-CN":
                inputImageText = {
                    image: "圖片",
                    clickToUpload: "點擊上傳圖片"
                }
                break;
            case "el":
                inputImageText = {
                    image: "Εικόνα",
                    clickToUpload: "Κάντε κλικ για να ανεβάσετε την εικόνα"
                }
                break;
            case "vi":
                inputImageText = {
                    image: "Hình ảnh",
                    clickToUpload: "Nhấn vào đây để tải lên hình ảnh"
                }
                break;
            case "id":
                inputImageText = {
                    image: "Gambar",
                    clickToUpload: "Klik untuk mengupload gambar!"
                }
                break;
            default:
                inputImageText = {
                    image: "Resim",
                    clickToUpload: "Resim yüklemek için tıklayınız."
                }
                break;
        }

        return inputImageText;
    };

    serviceModel.getInputImageDefaultTooltip = function(language) {
        var tooltip = "";
        switch (language) {
            case "en":
                tooltip = "Click here to upload image";
                break;
            case "tr":
                tooltip = "Resim yüklemek için tıklayınız";
                break;
            case "es":
                tooltip = "Haga clic aquí para subir la imagen";
                break;
            case "de":
                tooltip = "Klicken Sie hier, um Bild hochzuladen";
                break;
            case "fr":
                tooltip = "Cliquez ici pour télécharger l'image";
                break;
            case "ru":
                tooltip = "Нажмите здесь, чтобы загрузить изображение";
                break;
            case "it":
                tooltip = "Clicca qui per caricare un'immagine";
                break;
            case "hu":
                tooltip = "Kattintson ide, hogy feltölteni a képet";
                break;
            case "zh-CN":
                tooltip = "点击这里上传图片";
                break;
            case "el":
                tooltip = "Κάντε κλικ εδώ για να ανεβάσετε την εικόνα";
                break;
            case "vi":
                tooltip = "Nhấn vào đây để tải lên hình ảnh";
                break;
            case "id":
                tooltip = "Klik di sini untuk mengupload gambar";
                break;
            default:
                tooltip = "Resim yüklemek için tıklayınız";
                break;
        }

        return tooltip;
    };

    //Table
    serviceModel.addDynamicRowsBelow = function(templateModel, datasheetModel, element) {
        var row = $.extend(true, {}, element);

        var tableId = row.parentId;
        var rowId = row._id;

        var headerCells = _.filter(datasheetModel.elements, {
            parentId: tableId,
            type: "headercell"
        });

        var dynamicRow = {};
        dynamicRow._id = generalsService.createObjectId();
        dynamicRow.datasheetId = datasheetModel.datasheet._id;
        dynamicRow.parentId = tableId;
        dynamicRow.order = _findNewOrder(datasheetModel, row, true);
        dynamicRow.style = row.style;
        dynamicRow.type = row.type;
        dynamicRow.isDynamic = true;
        dynamicRow.referansElement = rowId;

        datasheetModel.elements.push(dynamicRow);

        var cells = _.filter(datasheetModel.elements, {
            parentId: row._id,
            type: "cell"
        });
        cells = _.sortBy(cells, 'order');

        for (var c = 0, len = cells.length; c < len; c++) {
            var cell = $.extend(true, {}, cells[c]);

            var dynamicCell = {};
            dynamicCell._id = generalsService.createObjectId();
            dynamicCell.datasheetId = datasheetModel.datasheet._id;
            dynamicCell.parentId = dynamicRow._id;
            dynamicCell.colSpan = cell.colSpan;
            dynamicCell.rowSpan = cell.rowSpan;
            dynamicCell.style = cell.style;
            dynamicCell.order = c + 1;
            dynamicCell.type = cell.type;
            dynamicCell.isDynamic = true;
            dynamicCell.referansElement = cell._id;

            datasheetModel.elements.push(dynamicCell);

            var dynamicInputText = _createDynamicInputTextForCell(templateModel, datasheetModel, dynamicCell);
            var referansElement = _findNearestElementHasStyle(datasheetModel, row, cell);
            dynamicInputText.style = referansElement.style;
            dynamicInputText.containerStyle = referansElement.containerStyle;
            datasheetModel.elements.push(dynamicInputText);

        }
    };

    serviceModel.addDynamicRowsAbove = function(templateModel, datasheetModel, element) {
        var row = $.extend(true, {}, element);

        var tableId = row.parentId;
        var row = row;
        var rowId = row._id;

        var dynamicRow = {};
        dynamicRow._id = generalsService.createObjectId();
        dynamicRow.datasheetId = datasheetModel.datasheet._id;
        dynamicRow.parentId = tableId;
        dynamicRow.order = _findNewOrder(datasheetModel, row, false);
        dynamicRow.style = row.style;
        dynamicRow.type = row.type;
        dynamicRow.referansElement = rowId;
        dynamicRow.isDynamic = true;

        datasheetModel.elements.push(dynamicRow);

        var cells = _.filter(templateModel.elements, {
            parentId: row._id,
            type: "cell"
        });
        cells = _.sortBy(cells, 'order');

        for (var c = 0, len = cells.length; c < len; c++) {
            var cell = $.extend(true, {}, cells[c]);

            var dynamicCell = {};
            dynamicCell._id = generalsService.createObjectId();
            dynamicCell.datasheetId = datasheetModel.datasheet._id;
            dynamicCell.parentId = dynamicRow._id;
            dynamicCell.colSpan = cell.colSpan;
            dynamicCell.rowSpan = cell.rowSpan;
            dynamicCell.style = cell.style;
            dynamicCell.order = c + 1;
            dynamicCell.type = cell.type;
            dynamicCell.isDynamic = true;
            dynamicCell.referansElement = cell._id;

            datasheetModel.elements.push(dynamicCell);

            var dynamicInputText = _createDynamicInputTextForCell(templateModel, datasheetModel, dynamicCell);
            var referansElement = _findNearestElementHasStyle(datasheetModel, row, cell);
            dynamicInputText.style = referansElement.style;
            dynamicInputText.containerStyle = referansElement.containerStyle;
            datasheetModel.elements.push(dynamicInputText);
        }
    };

    serviceModel.copyRowAbove = function(templateModel, datasheetModel, element) {
        var row = $.extend(true, {}, element);

        var tableId = row.parentId;
        var rowId = row._id;

        var hasRowSpanDifferentOne = _checkRowSpanDifferentOne(datasheetModel, row);
        if (hasRowSpanDifferentOne) {
            var errorMessage = $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETTABLE.DATASHEETTABLEROW.ERROR_MESSAGES.MERGE_ROW_ERROR");
            toastr.error(errorMessage, '', { positionClass: 'toast-bottom-right', timeOut: 1500 });
        } else {
            var dynamicRow = {};
            dynamicRow._id = generalsService.createObjectId();
            dynamicRow.datasheetId = datasheetModel.datasheet._id;
            dynamicRow.parentId = tableId;
            dynamicRow.order = _findNewOrder(datasheetModel, row, false);
            dynamicRow.style = row.style;
            dynamicRow.type = row.type;
            dynamicRow.referansElement = rowId;
            dynamicRow.isDynamic = true;

            datasheetModel.elements.push(dynamicRow);

            var cells = _.filter(templateModel.elements, {
                parentId: row._id,
                type: "cell"
            });
            cells = _.sortBy(cells, 'order');

            for (var c = 0; c < cells.length; c++) {
                var cell = $.extend(true, {}, cells[c]);

                var dynamicCell = {};
                dynamicCell._id = generalsService.createObjectId();
                dynamicCell.datasheetId = datasheetModel.datasheet._id;
                dynamicCell.parentId = dynamicRow._id;
                dynamicCell.colSpan = cell.colSpan;
                dynamicCell.rowSpan = cell.rowSpan;
                dynamicCell.style = cell.style;
                dynamicCell.order = c + 1;
                dynamicCell.type = cell.type;
                dynamicCell.referansElement = cell._id;
                dynamicCell.isDynamic = true;

                datasheetModel.elements.push(dynamicCell);

                //Element ler,
                var cellElements = _.filter(templateModel.elements, {
                    parentId: cell._id
                });
                cellElements = _.sortBy(cellElements, 'order');
                for (var ce = 0; ce < cellElements.length; ce++) {
                    var cellElement = $.extend(true, {}, cellElements[ce]);

                    var dynamicElement = {};
                    dynamicElement._id = generalsService.createObjectId();
                    dynamicElement.order = cellElement.order;
                    dynamicElement.datasheetId = datasheetModel.datasheet._id;
                    dynamicElement.parentId = dynamicCell._id;
                    dynamicElement.style = cellElement.style;
                    dynamicElement.containerStyle = cellElement.containerStyle;
                    dynamicElement.type = cellElement.type;
                    dynamicElement.name = dynamicElement.type.toUpperCase() + "-" + generalsService.createGUID().substring(0, 5).toUpperCase();
                    dynamicCell.referansElement = cellElement._id;
                    dynamicElement.isDynamic = true;

                    var cellDatasheetElement = $.extend(true, {}, _.find(datasheetModel.elements, {
                        name: cellElement.name
                    }));
                    if (cellDatasheetElement != null && cellDatasheetElement != undefined) {
                        dynamicElement.isHidden = cellDatasheetElement.isHidden;
                    }

                    if (cellElement.type == "text") {
                        dynamicElement.values = cellElement.values;
                    } else if (cellElement.type == "inputtext") {
                        dynamicElement.placeHolders = cellElement.placeHolders;
                        dynamicElement.values = cellDatasheetElement.values;

                    } else if (cellElement.type == "image") {

                    } else if (cellElement.type == "inputimage") {
                        dynamicElement.value = cellDatasheetElement.value;

                    } else if (cellElement.type == "icon") {
                        dynamicElement.value = cellElement.value;

                    } else if (cellElement.type == "inputicon") {
                        dynamicElement.source = cellElement.source;
                        dynamicElement.value = cellDatasheetElement.value;

                    } else if (cellElement.type == "box") {

                    } else if (cellElement.type == "selectlist") {
                        dynamicElement.source = cellElement.source;
                        dynamicElement.values = cellDatasheetElement.values;
                    }

                    datasheetModel.elements.push(dynamicElement);
                }

            }

        }
    };

    serviceModel.copyRowBelow = function(templateModel, datasheetModel, element) {
        var row = $.extend(true, {}, element);

        var tableId = row.parentId;
        var rowId = row._id;

        var hasRowSpanDifferentOne = _checkRowSpanDifferentOne(datasheetModel, row);
        if (hasRowSpanDifferentOne) {
            var errorMessage = $translate.instant("DATASHEET.DIRECTIVES.ELEMENTS.DATASHEETTABLE.DATASHEETTABLEROW.ERROR_MESSAGES.MERGE_ROW_ERROR");
            toastr.error(errorMessage, '', { positionClass: 'toast-bottom-right', timeOut: 1500 });

            return false;
        } else {
            var dynamicRow = {};
            dynamicRow._id = generalsService.createObjectId();
            dynamicRow.datasheetId = datasheetModel.datasheet._id;
            dynamicRow.parentId = tableId;
            dynamicRow.order = _findNewOrder(datasheetModel, row, true);
            dynamicRow.style = row.style;
            dynamicRow.type = row.type;
            dynamicRow.referansElement = rowId;
            dynamicRow.isDynamic = true;

            datasheetModel.elements.push(dynamicRow);

            var cells = _.filter(datasheetModel.elements, {
                parentId: row._id,
                type: "cell"
            });
            cells = _.sortBy(cells, 'order');

            for (var c = 0; c < cells.length; c++) {
                var cell = $.extend(true, {}, cells[c]);

                var dynamicCell = {};
                dynamicCell._id = generalsService.createObjectId();
                dynamicCell.datasheetId = datasheetModel.datasheet._id;
                dynamicCell.parentId = dynamicRow._id;
                dynamicCell.colSpan = cell.colSpan;
                dynamicCell.rowSpan = cell.rowSpan;
                dynamicCell.style = cell.style;
                dynamicCell.order = c + 1;
                dynamicCell.type = cell.type;
                dynamicCell.referansElement = cell._id;
                dynamicCell.isDynamic = true;

                datasheetModel.elements.push(dynamicCell);

                //Element ler,
                var cellElements = _.filter(datasheetModel.elements, {
                    parentId: cell._id
                });
                cellElements = _.sortBy(cellElements, 'order');
                for (var ce = 0; ce < cellElements.length; ce++) {
                    var cellElement = $.extend(true, {}, cellElements[ce]);

                    var dynamicElement = {};
                    dynamicElement._id = generalsService.createObjectId();
                    dynamicElement.order = cellElement.order;
                    dynamicElement.datasheetId = datasheetModel.datasheet._id;
                    dynamicElement.parentId = dynamicCell._id;
                    dynamicElement.style = cellElement.style;
                    dynamicElement.containerStyle = cellElement.containerStyle;
                    dynamicElement.type = cellElement.type;
                    dynamicElement.name = dynamicElement.type.toUpperCase() + "-" + generalsService.createGUID().substring(0, 5).toUpperCase();
                    dynamicCell.referansElement = cellElement._id;
                    dynamicElement.isDynamic = true;

                    var cellDatasheetElement = $.extend(true, {}, _.find(datasheetModel.elements, {
                        name: cellElement.name
                    }));
                    if (cellDatasheetElement != null && cellDatasheetElement != undefined) {
                        dynamicElement.isHidden = cellDatasheetElement.isHidden;
                    }

                    if (cellElement.type == "text") {
                        dynamicElement.values = cellElement.values;
                    } else if (cellElement.type == "inputtext") {
                        dynamicElement.placeHolders = cellElement.placeHolders;
                        dynamicElement.values = cellDatasheetElement.values;

                    } else if (cellElement.type == "image") {

                    } else if (cellElement.type == "inputimage") {
                        dynamicElement.value = cellDatasheetElement.value;

                    } else if (cellElement.type == "icon") {
                        dynamicElement.value = cellElement.value;

                    } else if (cellElement.type == "inputicon") {
                        dynamicElement.source = cellElement.source;
                        dynamicElement.value = cellDatasheetElement.value;

                    } else if (cellElement.type == "box") {

                    } else if (cellElement.type == "selectlist") {
                        dynamicElement.source = cellElement.source;
                        dynamicElement.values = cellDatasheetElement.values;
                    }

                    datasheetModel.elements.push(dynamicElement);
                }
            }

            return true;
        }
    };

    serviceModel.removeDynamicRow = function(datasheetModel, element) {
        var tableId = element.parentId;
        var rowId = element._id;
        var cells = _.filter(datasheetModel.elements, {
            parentId: rowId
        });
        var cellIds = _.map(cells, '_id');

        //Dinamik Satırı Sil,
        _.remove(datasheetModel.elements, function(datasheetElement) {
            return datasheetElement._id == rowId
        });
        //Dinamik Hücreleri Sil,
        _.remove(datasheetModel.elements, function(datasheetElement) {
            return datasheetElement.parentId == rowId
        });
        //Dinamik Elementleri Sil,
        _.remove(datasheetModel.elements, function(datasheetElement) {
            return cellIds.indexOf(datasheetElement.parentId) >= 0;
        });

    };
    //Functions End

    //Inner Functions 
    var _createDynamicInputTextForCell = function(templateModel, datasheetModel, cell) {
        var dynamicInputText = {};
        dynamicInputText._id = generalsService.createObjectId();
        dynamicInputText.type = "inputtext";
        dynamicInputText.name = dynamicInputText.type.toUpperCase() + "-" + generalsService.createGUID().substring(0, 5).toUpperCase();
        dynamicInputText.datasheetId = datasheetModel.datasheet._id;
        dynamicInputText.parentId = cell._id;
        dynamicInputText.containerStyle = {};
        dynamicInputText.style = {};
        dynamicInputText.isHidden = false;
        dynamicInputText.values = {};
        for (var l = 0, len = templateModel.template.languages.length; l < len; l++) {
            var language = templateModel.template.languages[l];
            dynamicInputText.values[language] = "";
        }

        return dynamicInputText;
    };

    var _checkRowSpanDifferentOne = function(datasheetModel, row) {
        var hasRowSpanDifferentOne = false;

        //Satırın Hücreleri        
        var cells;
        cells = _.filter(datasheetModel.elements, {
            parentId: row._id,
            type: "cell"
        });

        //Kopyalanacak satırın hücrelerinde rowSpan özelliklerinde 1 den farklı olan hücre var mı...
        for (var c = 0, len = cells.length; c < len; c++) {
            var cell = cells[c];

            if (cell.rowSpan > 1) {
                hasRowSpanDifferentOne = true;
                break;
            }
        }

        return hasRowSpanDifferentOne;
    };

    var _findNewOrder = function(datasheetModel, row, increase) {
        var tableId = row.parentId;
        var rowId = row._id;
        var rowOrder = row.order;

        if (!increase) {
            rowOrder = rowOrder - 1;
        }

        //Dynamic Satırları Bul
        var dynamicRows = _.filter(datasheetModel.elements, {
            parentId: tableId,
            type: 'row',
            isDynamic: true
        });

        dynamicRows = _.filter(datasheetModel.elements, function(dynamicRow) {
            return dynamicRow.order > rowOrder && dynamicRow.order < (rowOrder + 1);
        });

        dynamicRows = _.sortBy(dynamicRows, 'order');

        var newOrder = rowOrder + 0.1;
        for (var dr = 0; dr < dynamicRows.length; dr++) {
            var dynamicRow = dynamicRows[dr];

            dynamicRow.order = newOrder;
            newOrder += 0.1;
        }

        return newOrder;
    };

    var _findNearestElementHasStyle = function(datasheetModel, row, cell) {

        //Hücrede ara
        var foundElement = _findElementInCell(datasheetModel, cell._id);

        //Satırda ara
        if (foundElement == null) {
            foundElement = _findElementInRow(datasheetModel, row._id);
        }

        //Tablo da ara
        if (foundElement == null) {
            foundElement = _findElementInTable(datasheetModel, row.parentId);
        }

        return foundElement;


    };
    var _findElementInTable = function(datasheetModel, tableId) {
        var foundElement;

        var rows = _.filter(datasheetModel.elements, {
            parentId: tableId
        });

        for (var r in rows) {
            var row = rows[r];

            foundElement = _findElementInRow(datasheetModel, row._id);
            if (foundElement) {
                return foundElement;
            }
        }

        return null;
    };

    var _findElementInRow = function(datasheetModel, rowId) {
        var foundElement;

        var cells = _.filter(datasheetModel.elements, {
            parentId: rowId
        });

        for (var c in cells) {
            var cell = cells[c];

            foundElement = _findElementInCell(datasheetModel, cell._id);
            if (foundElement) {
                return foundElement;
            }
        }

        return null;
    };

    var _findElementInCell = function(datasheetModel, cellId) {
        var hasFoundElement = false;
        var foundElement;

        var cellElements = _.filter(datasheetModel.elements, {
            parentId: cellId
        });

        for (var ce in cellElements) {
            var cellElement = cellElements[ce];

            if (cellElement.type == "text" || cellElement.type == "inputtext" || cellElement.type == "selectlist") {
                hasFoundElement = true;
                foundElement = cellElement;
                break;
            }
        }

        if (hasFoundElement) {
            return foundElement;
        }
        else {
            return null;
        }
    }
    //Inner Functions End

    return serviceModel;
};

module.exports = function(app) {
    app.service('datasheetElementService', ['$filter', '$translate', 'generalsService', '_', datasheetElementService]);
};