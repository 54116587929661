require("./../../../../bower_components/angular/angular.js");
require('angular-ui-router');

require('../core/');
require('../template/');
require('../mailGroups/');

//Module
var datasheetModule = angular.module('tdsApp.datasheet', [
    'ui.router',
    'tdsApp.core',
    'tdsApp.template',
    'tdsApp.mailGroups'
]);

//Router
require('./routing.js')(datasheetModule);

//Factories
require('./shared/factories/datasheetFactory.js')(datasheetModule);
require('./shared/factories/shareFactory.js')(datasheetModule);

//Services
require('./shared/services/datasheetElementService.js')(datasheetModule);
require('./shared/services/datasheetContextMenuService.js')(datasheetModule);
require('./shared/services/datasheetContextMenuHandlerService.js')(datasheetModule);
require('./shared/services/datasheetViewService.js')(datasheetModule);
require('./shared/services/datasheetViewCacheService.js')(datasheetModule);

require('./shared/services/datasheetDataEditingService.js')(datasheetModule);
require('./shared/services/datasheetInputIconService.js')(datasheetModule);

//Providers

//Directives
require('./shared/directives/datasheetRightMenu/datasheetRightMenu.js')(datasheetModule);
require('./shared/directives/datasheetRightMenu/datasheetComments/datasheetComments.js')(datasheetModule);
require('./shared/directives/datasheetAutomaticTranslater/datasheetAutomaticTranslater.js')(datasheetModule);
require('./shared/directives/datasheetLanguagesPicker/datasheetLanguagesPicker.js')(datasheetModule);
require('./shared/directives/datasheetPages/datasheetPages.js')(datasheetModule);
require('./shared/directives/datasheetPages/datasheetPage.js')(datasheetModule);
require('./shared/directives/datasheetHeader/datasheetHeader.js')(datasheetModule);
require('./shared/directives/datasheetFooter/datasheetFooter.js')(datasheetModule);
require('./shared/directives/datasheetBody/datasheetBody.js')(datasheetModule);
require('./shared/directives/datasheetLayouts/datasheetSingleLayout.js')(datasheetModule);
require('./shared/directives/datasheetLayouts/datasheetBottomSingleLayout.js')(datasheetModule);
require('./shared/directives/datasheetLayouts/datasheetDoubleLayout.js')(datasheetModule);
require('./shared/directives/datasheetLayouts/datasheetTripleLayout.js')(datasheetModule);
require('./shared/directives/datasheetLayouts/datasheetTopSingleLayout.js')(datasheetModule);
require('./shared/directives/datasheetLayouts/datasheetFourPaneLayout.js')(datasheetModule);
require('./shared/directives/datasheetPane/datasheetPane.js')(datasheetModule);
require('./shared/directives/elements/datasheetText/datasheetText.js')(datasheetModule);
require('./shared/directives/elements/datasheetInputText/datasheetInputText.js')(datasheetModule);
require('./shared/directives/elements/datasheetSelectList/datasheetSelectList.js')(datasheetModule);
require('./shared/directives/elements/datasheetTable/datasheetTable/datasheetTable.js')(datasheetModule);
require('./shared/directives/elements/datasheetTable/datasheetTableHeader/datasheetTableHeader.js')(datasheetModule);
require('./shared/directives/elements/datasheetTable/datasheetTableBody/datasheetTableBody.js')(datasheetModule);
require('./shared/directives/elements/datasheetTable/datasheetTableRow/datasheetTableRow.js')(datasheetModule);
require('./shared/directives/elements/datasheetTable/datasheetTableCell/datasheetTableCell.js')(datasheetModule);
require('./shared/directives/elements/datasheetBox/datasheetBox.js')(datasheetModule);
require('./shared/directives/elements/datasheetIcon/datasheetIcon.js')(datasheetModule);
require('./shared/directives/elements/datasheetInputIcon/datasheetInputIcon.js')(datasheetModule);
require('./shared/directives/elements/datasheetImage/datasheetImage.js')(datasheetModule);
require('./shared/directives/elements/datasheetInputImage/datasheetInputImage.js')(datasheetModule);

require('./shared/directives/datasheetShowHiddenElements/datasheetShowHiddenElements.js')(datasheetModule);

require('./shared/directives/preparingDatasheet/preparingDatasheet.js')(datasheetModule);

//Controllers
require('./newDatasheet/controllers/newDatasheetController.js')(datasheetModule);
require('./yourDatasheets/controllers/yourDatasheetsController.js')(datasheetModule);
require('./editDatasheet/controllers/editDatasheetController.js')(datasheetModule);

//Directive Controllers
require('./shared/directives/datasheetAutomaticTranslater/controllers/modals/confirmAutomaticTranslaterController.js')(datasheetModule);
require('./shared/directives/datasheetPane/datasheetPaneController.js')(datasheetModule);
require('./shared/directives/elements/datasheetSelectList/controllers/modals/datasheetSelectListAddItemController.js')(datasheetModule);
require('./shared/directives/elements/datasheetInputIcon/controllers/modals/datasheetInputIconPickerController.js')(datasheetModule);
require('./shared/directives/elements/datasheetInputText/controllers/modals/translateWarningController.js')(datasheetModule);
require('./shared/directives/elements/datasheetInputText/controllers/modals/copyWarningController.js')(datasheetModule);

//Partial Controllers
require('./shared/controllers/_partials/datasheetsMenuInSideMenuController.js')(datasheetModule);
require('./yourDatasheets/controllers/_partials/datasheetOptionsController.js')(datasheetModule);
require('./yourDatasheets/controllers/_partials/datasheetsExportController.js')(datasheetModule);

//Modal Controllers
require('./newDatasheet/controllers/modals/selectDatasheetController.js')(datasheetModule);
require('./editDatasheet/controllers/modals/confirmVersionUpgrade.js')(datasheetModule);
require('./shared/controllers/modals/shareOnSocialMedia.js')(datasheetModule);
require('./yourDatasheets/controllers/modals/deleteDatasheetController.js')(datasheetModule);
require('./yourDatasheets/controllers/modals/moveDatasheetController.js')(datasheetModule);
require('./yourDatasheets/controllers/modals/renameDatasheetController.js')(datasheetModule);
require('./yourDatasheets/controllers/modals/shareDatasheetFolderWithEmailController.js')(datasheetModule);
require('./yourDatasheets/controllers/modals/shareDatasheetWithEmailController.js')(datasheetModule);
require('./yourDatasheets/controllers/modals/regenerateDatasheetVersionController.js')(datasheetModule);
require('./yourDatasheets/controllers/modals/viewDatasheetPdfController.js')(datasheetModule);
require('./yourDatasheets/controllers/modals/shareMultipleDatasheetWithEmailController.js')(datasheetModule);




//Export
module.exports = datasheetModule;
