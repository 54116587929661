var run = function($window, $rootScope, localStorageService, authFactory, subscriptionFactory, statisticFactory, $translate) {
    //Fields
    //Fields End

    //Events
    //Events End

    //Private Functions
    var checkTemplateLimit = function() {
        subscriptionFactory.getAccountSubscription().then(function(response) {
            var templateLimit = response.templateLimit;
                statisticFactory.getSummaryInformations().then(function(response) {
                    var templateCount = response.templateCount;
                    if((templateCount >= templateLimit) && (templateLimit != -1)) {
                        var message = $translate.instant("TEMPLATE.NEWTEMPLATE.LBL_TEMPLATE_LIMIT_REACHED");
                        toastr.warning(message, '', { 
                            closeButton: true, 
                            closeHtml: '<button type="button" class="toast-close-button" style="padding-right: 60px !important; top: 0 !important; right: 0 !important" role="button">×</button>', positionClass: 'toast-bottom-full-width', 
                            timeOut: 0,
                            extendedTimeOut: 0,
                            preventDuplicates: true
                         });
                    }
                });   
        });
    }
    //Private Functions End

    //Watches
    $rootScope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams) {
        if (toState.name == 'app.newTemplate'){
            checkTemplateLimit();
        }
        if (fromState.name == 'app.newTemplate') {
            toastr.clear();
        }
    });
    //Watches End

    //Initials
    //Initials End
};

module.exports = function(app) {
    app.run(['$window', '$rootScope', 'localStorageService', 'authFactory', 'subscriptionFactory', 'statisticFactory', '$translate', run]);
};
