var uploadFilesCtrl = function($scope, $state, $stateParams, $filter, $timeout, $translate, $modal, archiveFactory, archiveFileFactory, config) {
    //Fields
    var lblDragDrop = $translate.instant("CUSTOMARCHIVES.UPLOADFILES.LBL_DRAGDROP.TEXT");
    var lblOr = $translate.instant("CUSTOMARCHIVES.UPLOADFILES.LBL_OR.TEXT");
    var btnBrowseFiles = $translate.instant("CUSTOMARCHIVES.UPLOADFILES.BTN_BROWSE_FILES.TEXT");
    var lblSuccess = $translate.instant("CUSTOMARCHIVES.UPLOADFILES.LBL_SUCCESS.TEXT");
    var lblError = $translate.instant("CUSTOMARCHIVES.UPLOADFILES.LBL_ERROR.TEXT");
    var limit = 5;
    var uploadEndpoint = "uploadFiles";

    $scope.saveButton = "CUSTOMARCHIVES.UPLOADFILES.BTN_SAVE.TEXT";
    $scope.isProcessing = false;
    $scope.loaded = false;

    $scope.step = 0;
    $scope.uploadedFiles = [];

    $scope.customArchive = {};
    $scope.mode = "create";
    //Fields End

    //Events
    //Events End

    //Watches
    //Watches End

    //Functions
    $scope.cancel = function() {
        $state.go('app.yourArchives', {
            customArchiveId: $stateParams.customArchiveId
        });
    };

    $scope.goToPDFFilesInformation = function() {
        $scope.errors = [];
        if ($scope.uploadedFiles.length == 0 && $scope.mode == "create") {
            $scope.errors.push($translate.instant('CUSTOMARCHIVES.UPLOADFILES.ERROR_MESSAGES.ADD_PDF_FILES'));
        } else {
            for (var f = 0; f < $scope.uploadedFiles.length; f++) {
                var uploadedFile = $scope.uploadedFiles[f];

                if (uploadedFile.fields == null || uploadedFile.fields == undefined || uploadedFile.fields.length == 0) {
                    uploadedFile.fields = [];
                    for (var af = 0; af < $scope.customArchive.fields.length; af++) {
                        var archiveField = $scope.customArchive.fields[af];

                        uploadedFile.fields.push({
                            text: archiveField.name,
                            value: ""
                        });
                    }
                }
            }

            $scope.uploadedFiles[0].isPanelOpenned = true;
            $scope.saveButtonPressed = false;
            $scope.step = 1;
        }
    };

    $scope.backToUploadPDFFiles = function() {
        $scope.errors = [];
        $scope.step = 0;
    };

    $scope.openManageFileFieldsModal = function(uploadedFile) {
        var modalInstance = $modal.open({
            templateUrl: 'app/components/customArchives/uploadFiles/views/modals/manageFileFields.html',
            controller: 'manageFileFieldsCtrl',
            resolve: {
                archiveFields: function() {
                    return $scope.customArchive.fields;
                },
                file: function() {
                    return uploadedFile;
                }
            }
        });
    };

    $scope.save = function() {
        $scope.saveButtonPressed = true;

        //Kontrol
        var hasError = false;
        var iLimit;
        if($scope.mode === "create") {
            iLimit = $scope.uploadedFiles.length;
        } else if ($scope.mode === "edit") {
            iLimit = 1;
        }

        for (var i = 0; i < iLimit; i++) {
            var uploadedFile = $scope.uploadedFiles[i];

            var hasFieldError = false;
            for (var aff = 0; aff < uploadedFile.fields.length; aff++) {
                var field = uploadedFile.fields[aff];

                if (field.value == null || field.value == undefined || field.value == '') {
                    hasFieldError = true;
                    field.status = false;
                } else {
                    field.status = true;
                }
            }

            if (hasFieldError) {
                hasError = true;
                uploadedFile.status = false;
            } else {
                uploadedFile.status = true;
            }
        }

        if (hasError)
            return;

        $scope.saveButton = "CUSTOMARCHIVES.UPLOADFILES.BTN_SAVE.ALTERNATE_TEXT";
        $scope.isProcessing = true;

        var files = [];
        for (var i = 0; i < $scope.uploadedFiles.length; i++) {
            var uploadedFile = $scope.uploadedFiles[i];

            var file = {};
            file.customArchiveId = $stateParams.customArchiveId;
            file.folderId = $stateParams.folderId;
            file.fields = uploadedFile.fields;
            file.path = uploadedFile.path;
            file.size = uploadedFile.size;
            file.mimetype = uploadedFile.mimeType;
            file.filename = uploadedFile.filename;

            files.push(file);
        }

         if ($scope.mode === "create") {
            archiveFileFactory.saveFiles(files)
                .then(function(response) {
                    $scope.saveButton = "CUSTOMARCHIVES.UPLOADFILES.BTN_SAVE.TEXT";
                    $scope.isProcessing = false;
    
                    if (response) {
                        var message = $translate.instant("CUSTOMARCHIVES.UPLOADFILES.SUCCESS_MESSAGES.UPLOADED_SUCCESSFULLY");
                        toastr.success(message, '', { positionClass: 'toast-bottom-full-width', timeOut: 3000 });
                        $state.go('app.yourArchives', {
                            customArchiveId: $stateParams.customArchiveId
                        });
                    }
                });
        } else if ($scope.mode === "edit") {
            var editFileObject = {
                old: files[0],
                new: files[1]
            };
            editFileObject.old._id = $scope.customArchiveFile._id;

            archiveFileFactory.updateFile(editFileObject)
                .then(function(response) {
                    $scope.saveButton = "CUSTOMARCHIVES.UPLOADFILES.BTN_SAVE.TEXT";
                    $scope.isProcessing = false;
    
                    if (response) {
                        var message = $translate.instant("CUSTOMARCHIVES.UPLOADFILES.SUCCESS_MESSAGES.UPLOADED_SUCCESSFULLY");
                        toastr.success(message, '', { positionClass: 'toast-bottom-full-width', timeOut: 3000 });
                        $state.go('app.yourArchives', {
                            customArchiveId: $stateParams.customArchiveId
                        });
                    }
                });
        }
 


    };
    //Functions End

    //Inner Functions
    var initializeFileUploadControl = function() {

        if ($stateParams.customArchiveFileId !== null) {
            archiveFileFactory.getCustomArchiveFileById($stateParams.customArchiveFileId)
            .then(function(response) {
                $scope.customArchiveFile = response;
                $scope.uploadedFiles.push($scope.customArchiveFile);
                $scope.mode = "edit";
                limit = 1;
                uploadEndpoint = "uploadFile";
                initFiler('#fiCustomFile');
                $scope.loaded = true;
            });
        } else {
            initFiler('#fiCustomFiles');
            $scope.loaded = true;
        }
    };

    var getCustomArchiveById = function(customArchiveId) {
        archiveFactory.getCustomArchiveById(customArchiveId)
            .then(function(response) {
                $scope.customArchive = response;
            });
    };

    var initFiler = function(elementSelector) {
        $(elementSelector).filer({
            changeInput: '<div class="jFiler-input-dragDrop"><div class="jFiler-input-inner"><div class="jFiler-input-icon"><i class="icon-jfi-cloud-up-o"></i></div><div class="jFiler-input-text"><h3>' + lblDragDrop + '</h3> <span style="display:inline-block; margin: 15px 0">' + lblOr + '</span></div><a class="jFiler-input-choose-btn btn-custom blue-light">' + btnBrowseFiles + '</a></div></div>',
            showThumbs: true,
            limit: limit,
            maxSize: 50,
            extensions: ['pdf'],
            theme: "dragdropbox",
            templates: {
                box: '<ul class="jFiler-items-list jFiler-items-grid"></ul>',
                item: '<li class="jFiler-item">\
                            <div class="jFiler-item-container">\
                                <div class="jFiler-item-inner">\
                                    <div class="jFiler-item-thumb">\
                                        <div class="jFiler-item-status"></div>\
                                        <div class="jFiler-item-thumb-overlay">\
                        <div class="jFiler-item-info">\
                          <div style="display:table-cell;vertical-align: middle;">\
                            <span class="jFiler-item-title"><b title="{{fi-name}}">{{fi-name}}</b></span>\
                            <span class="jFiler-item-others">{{fi-size2}}</span>\
                          </div>\
                        </div>\
                      </div>\
                                        {{fi-image}}\
                                    </div>\
                                    <div class="jFiler-item-assets jFiler-row">\
                                        <ul class="list-inline pull-left">\
                                            <li>{{fi-progressBar}}</li>\
                                        </ul>\
                                        <ul class="list-inline pull-right">\
                                            <li><a class="icon-jfi-trash jFiler-item-trash-action"></a></li>\
                                        </ul>\
                                    </div>\
                                </div>\
                            </div>\
                        </li>',
                itemAppend: '<li class="jFiler-item">\
                                <div class="jFiler-item-container">\
                                    <div class="jFiler-item-inner">\
                                        <div class="jFiler-item-thumb">\
                                            <div class="jFiler-item-status"></div>\
                                            <div class="jFiler-item-thumb-overlay">\
                            <div class="jFiler-item-info">\
                              <div style="display:table-cell;vertical-align: middle;">\
                                <span class="jFiler-item-title"><b title="{{fi-name}}">{{fi-name}}</b></span>\
                                <span class="jFiler-item-others">{{fi-size2}}</span>\
                              </div>\
                            </div>\
                          </div>\
                                            {{fi-image}}\
                                        </div>\
                                        <div class="jFiler-item-assets jFiler-row">\
                                            <ul class="list-inline pull-left">\
                                                <li><span class="jFiler-item-others">{{fi-icon}}</span></li>\
                                            </ul>\
                                            <ul class="list-inline pull-right">\
                                                <li><a class="icon-jfi-trash jFiler-item-trash-action"></a></li>\
                                            </ul>\
                                        </div>\
                                    </div>\
                                </div>\
                            </li>',
                progressBar: '<div class="bar"></div>',
                itemAppendToEnd: false,
                removeConfirmation: true,
                _selectors: {
                    list: '.jFiler-items-list',
                    item: '.jFiler-item',
                    progressBar: '.bar',
                    remove: '.jFiler-item-trash-action'
                }
            },
            captions: {
                errors: {
                    filesType: function() { return $translate.instant("CUSTOMARCHIVES.UPLOADFILES.ERROR_MESSAGES.ALLOWED_EXTENSIONS"); },
                    filesLimit: function(options) { return $translate.instant("CUSTOMARCHIVES.UPLOADFILES.ERROR_MESSAGES.FILE_LIMIT", { limit: options.limit}); },
                    filesSize: function(options) { return $translate.instant("CUSTOMARCHIVES.UPLOADFILES.ERROR_MESSAGES.FILE_SIZE", { name: options.name, maxSize: options.maxSize}); },
                    filesSizeAll: function(options) { return $translate.instant("CUSTOMARCHIVES.UPLOADFILES.ERROR_MESSAGES.FILE_SIZE_ALL", { maxSize: options.maxSize}); },
                    fileName: function(options) { return $translate.instant("CUSTOMARCHIVES.UPLOADFILES.ERROR_MESSAGES.FILE_NAME", { name: options.name}); },
                    folderUpload: function() { return $translate.instant("CUSTOMARCHIVES.UPLOADFILES.ERROR_MESSAGES.FOLDER_UPLOAD"); },
                }
            },
            onRemove: function(sender, file) {
                var index = -1;
                var iIndex;
                if($scope.mode === "create") {
                    iIndex = 0;
                } else if ($scope.mode === "edit") {
                    iIndex = 1;
                }
                for (iIndex; iIndex < $scope.uploadedFiles.length; iIndex++) {
                    var uploadedFile = $scope.uploadedFiles[iIndex];

                    if (uploadedFile.name == file.originalName) {
                        index = iIndex;
                        break;
                    }
                }

                $scope.uploadedFiles.splice(index, 1);
            },
            dragDrop: {},
            uploadFile: {
                url: config.api[config.mode].apiUrl + "/api/v2/customFile/" + uploadEndpoint,
                data: null,
                type: 'POST',
                enctype: 'multipart/form-data',
                beforeSend: function(item, listEl, parentEl, newInputEl, inputEl) {
                },
                success: function(data, el) {
                    var parent = el.find(".jFiler-jProgressBar").parent();
                    el.find(".jFiler-jProgressBar").fadeOut("slow", function() {
                        $("<div class=\"jFiler-item-others text-success\"><i class=\"icon-jfi-check-circle\"></i> " + lblSuccess + "</div>").hide().appendTo(parent).fadeIn("slow");
                    });
                    $scope.$apply(function() {
                        $scope.uploadedFiles.push(data);
                        $scope.errors = [];
                    });
                },
                error: function(el) {
                    var parent = el.find(".jFiler-jProgressBar").parent();
                    el.find(".jFiler-jProgressBar").fadeOut("slow", function() {
                        $("<div class=\"jFiler-item-others text-error\"><i class=\"icon-jfi-minus-circle\"></i> " + lblError + "</div>").hide().appendTo(parent).fadeIn("slow");
                    });
                },
                statusCode: null,
                onProgress: null,
                onComplete: null
            },
            dialogs: {
                alert: function(text) {
                    var modalInstance = $modal.open({
                        templateUrl: 'app/components/customArchives/uploadFiles/views/modals/warningInUploadFiles.html',
                        controller: 'warningInUploadFilesCtrl',
                        resolve: {
                            message: function() {
                                return text;
                            }
                        }
                    });
                    return false;
                },
                confirm: function(text, callback) {
                    var modalInstance = $modal.open({
                        templateUrl: 'app/components/customArchives/uploadFiles/views/modals/confirmDeleteFile.html',
                        controller: 'confirmDeleteFileCtrl',
                        resolve: {
                            text: function() {
                                return text;
                            }
                        }
                    });

                    modalInstance.result
                        .then(function(result) {
                            if (result) {
                                callback();
                            } else {
                                null;
                            }
                        });
                }
            }
        });
    }
    //Inner Functions End

    //Initials
    initializeFileUploadControl();
    getCustomArchiveById($stateParams.customArchiveId);
    //Initials End
};

module.exports = function(app) {
    app.controller('uploadFilesCtrl', ['$scope', '$state', '$stateParams', '$filter', '$timeout', '$translate', '$modal', 'archiveFactory', 'archiveFileFactory', 'config', uploadFilesCtrl]);
};
