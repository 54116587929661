var fancyTypeName = function($translate){
  return function(type) {
    switch (type) {
      case 'text':
        return $translate.instant("TEMPLATE.SHARED.ELEMENT_NAMES.FREE_TEXT");
      case 'icon':
        return $translate.instant("TEMPLATE.SHARED.ELEMENT_NAMES.ICON");
      case 'image':
        return $translate.instant("TEMPLATE.SHARED.ELEMENT_NAMES.IMAGE");
      case 'table':
        return $translate.instant("TEMPLATE.SHARED.ELEMENT_NAMES.TABLE");
      case 'box':
        return $translate.instant("TEMPLATE.SHARED.ELEMENT_NAMES.BOX");
      case 'inputtext':
        return $translate.instant("TEMPLATE.SHARED.ELEMENT_NAMES.TEXT_BOX");
      case 'selectlist':
        return $translate.instant("TEMPLATE.SHARED.ELEMENT_NAMES.SELECT_LIST");
      case 'inputimage':
        return $translate.instant("TEMPLATE.SHARED.ELEMENT_NAMES.IMAGE_HOLDER");
      case 'inputicon':
        return $translate.instant("TEMPLATE.SHARED.ELEMENT_NAMES.ICON_HOLDER");
      default:
        return $translate.instant("TEMPLATE.SHARED.ELEMENT_NAMES.ELEMENT");
    }
  };
};

module.exports = function(app){
  app.filter('fancyTypeName', ['$translate', fancyTypeName]);
};
