var newDatasheetCtrl = function ($rootScope, $scope, $state, $stateParams, $timeout, $translate, $filter, localStorageService, $modal, generalsService, folderFactory, userFactory, templateFactory, datasheetFactory, checkPermissionFactory, languageFactory, intercomFactory) {
    //Fields
    $scope.currentStep = 0;
    $scope.translatedArchive = $translate.instant("DATASHEET.NEWDATASHEET.LBL_ARCHIVE.TEXT");
    $scope.toogleLink = "DATASHEET.NEWDATASHEET.BTN_HIDE_NAMES_BY_LANGUAGE.TEXT";
    $scope.namesByLanguage = true;
    $scope.selectedFolder = {};
    $scope.selectedTemplate = {};
    $scope.folderType = "Datasheet";
    $scope.newFolder = {};

    $scope.model = {};

    //Loading İşlemleri için kullanılan model.
    $scope.loaded = {
        template: {
            folders: false,
            templates: false
        },
        datasheet: {
            folders: false
        }
    };
    //Fields End

    //Events
    $scope.$on('createdNewFolder', function (event, data) {
        $scope.loadedFolders = false;
        getDatasheetFolders(function () {
            var newFolder = $filter("filter")($scope.datasheetFolders, {
                parent: data.parentFolderId,
                text: data.newFolderName
            }, true)[0];

            $scope.newFolder.newFolderName = "";

            setNewFolder(newFolder);
            $scope.folders = $scope.datasheetFolders;
            $scope.loadedFolders = true;

        });
    });
    //Events End

    //Watches
    $scope.$watch('selectedFolder.id', function () {
        //Select Template adımında klasor secildiyse,
        if ($scope.currentStep == 1) {
            var selectedFolderId = $scope.selectedFolder.id;
            $scope.loaded.template.templates = false;
            getPublishedTemplatesByFolderId(selectedFolderId, function () {
                $scope.loaded.template.templates = true;
            });
        }
        //Datasheet için Select Folder adımında ise,
        else if ($scope.currentStep == 3) {
            if (angular.isDefined($scope.selectedFolder.id) && $scope.selectedFolder.id !== "") {
                $scope.hasFolderSelected = true;
                $scope.selectedFolderPath = folderFactory.getFolderPath($scope.datasheetFolders, $scope.selectedFolder.id);
                var selectedFolder = $filter('filter')($scope.datasheetFolders, {
                    "_id": $scope.selectedFolder.id
                })[0];
                $scope.selectedFolder.folderPermissions = selectedFolder.ItemPermissions;
            } else {
                $scope.hasFolderSelected = false;
            }
        }
    });
    //Watches End

    //Inner Functions
    var newDatasheetInitialize = function () {
        //Eğer parametre olarak id geçilmişse bu baska bir datasheet in kopyası demektir.
        //O yuzden Select Template adımını atlayarak Create Datasheet sayfasına yönlendir.
        if (!angular.isUndefined($stateParams.id)) {
            $scope.currentStep = -1;

            $scope.mode = "Copy";
            $scope.model.copiedDatasheetId = $stateParams.id;

            var templateId = $stateParams.templateId;
            getPublishedTemplateById(templateId, function () {
                $scope.goToCreateDatasheetStep();
            });

        } else {
            $scope.mode = "New";
            $scope.model.copiedDatasheetId = null;
            $scope.currentStep = 0;
        }

        //Datasheet de kullanılacak tüm diller sistemden çekilir.
        languageFactory.getLanguages()
            .then(function (response) {
                $scope.languages = response;
            });
    };

    var getTemplateFolders = function (nextProcess) {

        $scope.loaded.template.folders = false;
        folderFactory.getTemplateFolders()
            .then(function (response) {
                $scope.templateFolders = response;
                for (var i = 0; i < $scope.templateFolders.length; i++) {
                    if ($scope.templateFolders[i].Parent == "#") {
                        $scope.templateFolders[i].Name = $scope.translatedArchive;
                        $scope.rootFolder = $scope.templateFolders[i];
                        break;
                    }
                }

                for (var i = 0; i < $scope.templateFolders.length; i++) {
                    var templateFolder = $scope.templateFolders[i];
                    templateFolder.id = templateFolder._id;
                    templateFolder.text = templateFolder.Name;
                    templateFolder.parent = templateFolder.Parent;
                }

                nextProcess();
            });
    };

    var getPublishedTemplatesByFolderId = function (selectedFolderId, nextProcess) {
        templateFactory.getPublishedTemplatesByFolderId(selectedFolderId)
            .then(function (response) {

                $scope.templates = response;
                var disabledTemplates = _.filter(response, {
                    isDisabled: true
                });
                if (disabledTemplates.length > 0)
                    $scope.hasDisabledTemplates = true;
                else
                    $scope.hasDisabledTemplates = false;
                nextProcess();
            });
    };

    var reRenderNiceScroll = function (id) {
        $timeout(function () {
            $scope.$broadcast('reRenderNiceScroll', {
                id: id
            });
        }, 0);
    };

    var getDatasheetFolders = function (nextProcess) {
        folderFactory.getDatasheetFolders()
            .then(function (response) {
                var datasheetFolders = response;
                datasheetFolders = checkPermissionFactory.canSaveDatasheetToFolders(datasheetFolders);

                $scope.datasheetFolders = datasheetFolders;

                for (var i = 0; i < $scope.datasheetFolders.length; i++) {
                    if ($scope.datasheetFolders[i].Parent == "#") {
                        $scope.datasheetFolders[i].Name = $scope.translatedArchive;
                        $scope.rootFolder = $scope.datasheetFolders[i];
                        break;
                    }
                }

                for (var i = 0; i < $scope.datasheetFolders.length; i++) {
                    var datasheetFolder = $scope.datasheetFolders[i];
                    datasheetFolder.id = datasheetFolder._id;
                    datasheetFolder.text = datasheetFolder.Name;
                    datasheetFolder.parent = datasheetFolder.Parent;
                }

                $scope.loadedFolders = false;
                nextProcess();
            });
    };

    var setNewFolder = function (folder) {
        $scope.newFolder._id = folder._id;
    };

    var getPublishedTemplateById = function (templateId, nextProcess) {
        templateFactory.getPublishedTemplateById(templateId)
            .then(function (response) {
                $scope.selectedTemplate = response.template;

                nextProcess();
            });
    };
    //Inner Functions End

    //Functions
    $scope.goToSelectTemplateStep = function () {
        $scope.currentStep = 1;
        //Template klasorlerini alalım.
        getTemplateFolders(function () {
            $scope.loaded.template.folders = true;
            $scope.folders = $scope.templateFolders;
        });
    };

    $scope.selectTemplate = function (template) {
        if (template.isDisabled) return;

        $scope.selectedTemplate = template;
        $scope.showSelectTemplateError = false;

        reRenderNiceScroll("templateFolders");
    };

    $scope.cancel = function () {
        $state.go('app.newDatasheet', {}, {
            reload: true
        });
    };

    $scope.goToCreateDatasheetStep = function () {
        $('a[id^="steps-uid-"]').height("48px");
        if (angular.isUndefined($scope.selectedTemplate._id)) {
            $scope.showSelectTemplateError = true;
            reRenderNiceScroll("templateFolders");
            return;
        }

        //Step - 1 de yer alan niceScroll u kaldır.
        $rootScope.$broadcast('destroyNiceScroll', {
            id: "templateFolders"
        });

        $scope.showSelectTemplateError = false;

        $scope.model.hasProductNumber = $scope.selectedTemplate.hasProductNumber;
        $scope.model.productNumberRequired = $scope.selectedTemplate.productNumberRequired;
        $scope.model.uniqueProductNumber = $scope.selectedTemplate.uniqueProductNumber;
        $scope.model.hasStartupVersionNo = $scope.selectedTemplate.hasStartupVersionNo;
        $scope.model.names = [];
        $scope.model.majorVersion = 0;
        $scope.model.minorVersion = 0;
        $scope.model.templateId = $scope.selectedTemplate._id;
        $scope.model.templateName = $scope.selectedTemplate.name;

        angular.forEach($scope.selectedTemplate.languages, function (language, index) {
            if ($scope.selectedTemplate.primaryLanguage == language) {
                $scope.model.primaryLanguage = language;
            } else {
                var nameItem = {};
                nameItem.language = language;
                nameItem.name = "";
                $scope.model.names.push(nameItem);
            }
        });

        $scope.currentStep = 2;
    };

    $scope.toggleNames = function () {
        if ($scope.namesByLanguage) {
            $scope.toogleLink = "DATASHEET.NEWDATASHEET.BTN_SHOW_NAMES_BY_LANGUAGE.TEXT";
            $scope.namesByLanguage = false;
        } else {
            $scope.namesByLanguage = true;
            $scope.toogleLink = "DATASHEET.NEWDATASHEET.BTN_HIDE_NAMES_BY_LANGUAGE.TEXT";
        }
    };

    $scope.preGoToSelectFolderStep = function (createDatasheetForm) {
        $scope.errors = [];

        //Ürün Numarası Kontrol
        if ($scope.model.hasProductNumber && createDatasheetForm.$invalid && createDatasheetForm.productNumber.$invalid) {
            $scope.errors.push('DATASHEET.NEWDATASHEET.ERROR_MESSAGES.FILL_PRODUCT_NUMBER');
        }

        //Ürün Bilgi Formu İsimleri Kontrol
        if (createDatasheetForm.$invalid && createDatasheetForm.productName.$invalid) {
            $scope.errors.push('DATASHEET.NEWDATASHEET.ERROR_MESSAGES.FILL_ALL_DATASHEET_NAME_FIELDS');
        }

        for (var i = 0; i < $scope.model.names.length; i++) {
            var datasheetName = $scope.model.names[i].name;

            if (datasheetName == null || datasheetName == undefined || datasheetName.trim() == '') {
                $scope.errors.push('DATASHEET.NEWDATASHEET.ERROR_MESSAGES.FILL_ALL_DATASHEET_NAME_FIELDS');
                break;
            }
        }

        //Benzersiz ürün numarası kontrol.
        if ($scope.model.productNumber != null && $scope.model.productNumber != undefined &&
            $scope.model.productNumber != '' && $scope.model.uniqueProductNumber) {
            datasheetFactory.hasDatasheetWithSameProductNumber($scope.selectedTemplate._id, $scope.model.productNumber)
                .then(function (status) {
                    if (status) {
                        $scope.errors.push('DATASHEET.NEWDATASHEET.ERROR_MESSAGES.SAME_DATASHEET_WITH_PRODUCT_NUMBER');
                    }
                    $scope.goToSelectFolderStep();
                });
        } else {
            $scope.goToSelectFolderStep();
        }
    };

    $scope.returnSelectTemplateStep = function () {
        $scope.currentStep = 1;
        $scope.selectedTemplate = {};

        getTemplateFolders(function () {
            $scope.loaded.template.folders = true;
            $scope.folders = $scope.templateFolders;


        });
    };

    $scope.goToSelectFolderStep = function () {
        if ($scope.errors.length > 0) {
            return;
        }

        $scope.currentStep = 3;

        getDatasheetFolders(function () {
            $scope.loadedFolders = true;
            $scope.folders = $scope.datasheetFolders;
        });
    };

    $scope.returnCreateDatasheetStep = function () {
        $scope.currentStep = 2;
    };

    $scope.saveDatasheet = function () {
        $scope.model.folderId = $scope.selectedFolder.id;
        $scope.model._id = generalsService.createObjectId();
        datasheetFactory.createNewDatasheet($scope.model)
            .then(function (response) {
                if (response.status) {
                    intercomFactory.addEvent({
                        event_name: "Datasheet Created",
                        metadata: {
                            productName: $scope.model.productName,
                            productNumber: $scope.model.productNumber,
                            primaryLanguage: $scope.model.primaryLanguage
                        }
                    });

                    $state.go('editor.editDatasheet', {
                        lang: $rootScope.activeLang,
                        id: response._id
                    });
                }
            });
    };

    //Helpers
    $scope.translateName = function (referanceLang, controlText) {
        if (angular.isUndefined(controlText) || controlText === "" || controlText.length <= 1) return;
        var requestModel = {};
        requestModel.ControlLanguage = referanceLang;
        requestModel.ControlText = controlText;
        requestModel.TranslateList = [];

        angular.forEach($scope.model.names, function (nameItem, key) {
            if (nameItem.language != referanceLang) {
                var requestListModel = {};
                requestListModel.Language = nameItem.language;
                requestListModel.Text = nameItem.name;

                requestModel.TranslateList.push(requestListModel);
            }
        });

        if (referanceLang != $scope.model.primaryLanguage) {
            var listModelForPrimaryLanguage = {};
            listModelForPrimaryLanguage.Language = $scope.model.primaryLanguage;
            listModelForPrimaryLanguage.Text = $scope.model.productName;
            requestModel.TranslateList.push(listModelForPrimaryLanguage);
        }

        $scope.translatePromise = generalsService.translateTextFrom(requestModel)
            .then(function (response) {
                for (var i = 0; i < response.TranslateList.length; i++) {
                    var currentItem = response.TranslateList[i];

                    if (currentItem.Language == $scope.model.primaryLanguage) {
                        $scope.model.productName = currentItem.Text;
                    } else {
                        var filteredItem = $filter('filter')($scope.model.names, {
                            language: currentItem.Language
                        }, true)[0];
                        filteredItem.name = currentItem.Text;
                    }

                }
                var message = $translate.instant("DATASHEET.NEWDATASHEET.SUCCESS_MESSAGES.TRANSLATE_SUCCESS");
                toastr.success(message, '', { positionClass: 'toast-bottom-full-width', timeOut: 3000 });
            });
    };

    $scope.getLanguageCode = function (language) {
        if (angular.isDefined($scope.languages)) {
            var selectedLanguage = $filter('filter')($scope.languages, {
                "isoCode": language
            })[0];

            return selectedLanguage.languageCode;
        }

    };

    $scope.jsTreeOpenedNode = function () {
        var id = "templateFolders";
        if ($scope.currentStep == 3) {
            id = "datasheetFolders"
        };

        //Rerender Nice scroll
        $rootScope.$broadcast('reRenderNiceScroll', {
            "id": id
        });
    };

    $scope.jsTreeClosedNode = function () {
        var id = "templateFolders";
        if ($scope.currentStep == 3) {
            id = "datasheetFolders"
        };

        //Rerender Nice scroll
        $rootScope.$broadcast('reRenderNiceScroll', {
            "id": id
        });
    };

    $scope.openSelectDatasheetModal = function () {
        var modalInstance = $modal.open({
            animation: false,
            templateUrl: 'app/components/datasheet/newDatasheet/views/modals/selectDatasheet.html',
            size: 'full-width',
            backdrop: 'static',
            controller: 'selectDatasheetCtrl'
        });

        modalInstance.result
            .then(function (result) {
                if (result.status) {
                    $state.go('app.copyDatasheet', {
                        "id": result.selectedDatasheet._id,
                        "templateId": result.selectedDatasheet.templateId
                    }, {
                            reload: true
                        });
                }
            });
    };
    //Functions End

    //Initials
    newDatasheetInitialize();
    //Initials End
};

module.exports = function (app) {
    app.controller('newDatasheetCtrl', ['$rootScope', '$scope', '$state', '$stateParams', '$timeout', '$translate', '$filter', 'localStorageService', '$modal', 'generalsService', 'folderFactory', 'userFactory', 'templateFactory', 'datasheetFactory', 'checkPermissionFactory', 'languageFactory', 'intercomFactory', newDatasheetCtrl]);
};
