var viewPdfCtrl = function ($rootScope, $scope, $stateParams, $sce, authFactory, pdfFactory, config) {
    //Fields 
    var options = {
        pdfOpenParams: {
            navpanes: 0,
            toolbar: 0,
            statusbar: 0,
            view: "FitV",
            pagemode: "thumbs",
            page: 1,
            locale: $rootScope.activeLang
        },
        forcePDFJS: true,
        PDFJS_URL: "/assets/js/pdfjs/web/viewer.html"
    };

    var sourceParameter = $stateParams.source;
    $scope.loaded = false;
    //Fields End

    //Inner Functions
    var getPDF = function (sourceParameter) {
        if (authFactory.authentication.isAuth) {

            if (!angular.isUndefined(sourceParameter) && sourceParameter !== null)
                source = sourceParameter;
            else
                source = "webApp";

            pdfFactory.getPDF($stateParams.id, $stateParams.datasheetLang, source).then(function (response) {
                $scope.model = response;
                $scope.model.url = config.api[config.mode].apiUrl + "/" + response.filePath.replace("app/", '') + response.fileName;
                $scope.loaded = true;

                var myPDF = PDFObject.embed($scope.model.url, "#pdf", options);
            });
        } else {
            if (!angular.isUndefined($stateParams.source))
                source = $stateParams.source;
            else
                source = "direct";

            pdfFactory.getPDFAnonymouse($stateParams.id, $stateParams.datasheetLang, source).then(function (response) {
                $scope.model = response;
                $scope.model.url = config.api[config.mode].apiUrl + "/" + response.filePath.replace("app/", '') + response.fileName;
                $scope.loaded = true;

                var myPDF = PDFObject.embed($scope.model.url, "#pdf", options);
            });
        }
    };
    //Inner Functions End

    //Functions 
    $scope.trustSrc = function (src) {
        return $sce.trustAsResourceUrl(src);
    };
    //Functions End

    //Events 
    //Events End

    //Initials     
    authFactory.fillAuthData();
    getPDF(sourceParameter);
    //Initials End
};

module.exports = function (app) {
    app.controller('viewPdfCtrl', ['$rootScope', '$scope', '$stateParams', '$sce', 'authFactory', 'pdfFactory', 'config', viewPdfCtrl]);
};
