var uiRouter = function($stateProvider, $urlRouterProvider, $locationProvider) {

    $stateProvider
        .state('errors.browserUpgrade', {
            url: '/browser-upgrade',
            templateUrl: "/app/components/errors/browserUpgrade/views/browserUpgrade.html",
            controller: "browserUpgradeCtrl"
        });

    //HTML 5 Modu aktif (diyez işaretinin görünmemesi için)
    //$locationProvider.html5Mode(true);
    // $urlRouterProvider.otherwise("/en/blank/loading");
};

module.exports = function(app) {
    app.config(['$stateProvider', '$urlRouterProvider', '$locationProvider', uiRouter]);
};
