var resetPasswordCtrl = function($rootScope, $scope, $state, $timeout, $translate, authFactory) {
    //Fields
    $scope.btnSendPassword = "AUTHENTICATION.RESETPASSWORD.BTN_SEND_PASSWORD.TEXT";
    

    $scope.errors = [];
    $scope.successStatus = false;
    //Fields End

    //Functions
    $scope.resetPassword = function() {
        $scope.errors = [];
        if ($scope.resetForm.$valid) {
            authFactory.resetPassword($scope.email).then(function(response) {
                if (response.status) {
                    var message = $translate.instant("AUTHENTICATION.RESETPASSWORD.SUCCESS_MESSAGES.NEW_PASSWORD_SENT");
                    toastr.success(message, '', { positionClass: 'toast-bottom-full-width', timeOut: 3000 });

                    $timeout(function() {
                        $state.go('auth.signIn', {
                            lang: $rootScope.activeLang
                        });
                    }, 500);
                } else {
                    $scope.errors.push(response.message);
                    // $timeout(function() {
                    //     $scope.errors = [];
                    // }, 5000);
                }
            });
        }
    };
    //Functions End

    //Inner Functions
    //Inner Functions End

    //Initials
    //Initials End
};

module.exports = function(app) {
    app.controller('resetPasswordCtrl', ['$rootScope', '$scope', '$state', '$timeout', '$translate', 'authFactory', resetPasswordCtrl]);
};
