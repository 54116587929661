var emailListService =  function(httpProvider) {
  //Fields
  var serviceModel = {};
  //Fields - End

  //Private Functions
  serviceModel.getAllEmailList = function() {
    return httpProvider.get('emailList', 'getAllEmailList');
  };

  serviceModel.getAllEmailTags = function() {
    return httpProvider.get('emailList', 'getAllEmailTags');
  };

  serviceModel.saveEmailList = function(model) {
    return httpProvider.post('emailList', 'saveEmailList', model);
  };

  serviceModel.deleteEmail = function(emailId) {
    return httpProvider.delete('emailList', 'deleteEmail', emailId);
  };

  serviceModel.updateEmail = function(model) {
    return httpProvider.put('emailList', 'updateEmail', model.emailId);
  };
  //Private Functions - End

  return serviceModel;
};

module.exports = function(app){
  app.service('emailListService', ['httpProvider', emailListService]);
};
