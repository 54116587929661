var selectListItemCtrl = function($scope, $modalInstance, generalsService, froalaEditorService, $translate, googleTranslate, element, model, selectedLanguage){
  //Private Functions
  var generateId = function(){
    var newId = generalsService.createGUID();
    newId = newId.toUpperCase();
    return newId;
  };

  //Fields
  $scope.element = element;
  $scope.selectedLanguage = selectedLanguage;
  $scope.newItemModel = {
    id : generateId()
  };
  
  $scope.froalaOptions = {
      language : activeLang,
      placeholderText : $translate.instant('TEMPLATE.EDIT_TEMPLATE.MODALS.SELECT_LIST_ITEMS.TITLE'),
      toolbarButtons : ["bold", "italic", "underline", "|", "subscript", "superscript"],
      theme : "custom-inline"
  }

  //Action Handlers
  $scope.cancel = function(){
    $modalInstance.dismiss('cancel');
  };
  $scope.ok = function(){
    $modalInstance.dismiss('cancel');
  };

  $scope.cancelEdit = function(){
    $scope.inEditMode = false;
    $scope.currentItem = void 0;
    $scope.newItemModel = {
      id : generateId()
    };
  }

  $scope.addSelectListItem = function(isNeedTrasnlate) {

    $scope.showError = false;

    var modelValue = $scope.newItemModel.value;
    var newItemId = $scope.newItemModel.id;

    if(angular.isUndefined(newItemId) || newItemId.length == 0)
      newItemId = generateId();

    if(isNeedTrasnlate){
      googleTranslate.translateText(modelValue, selectedLanguage, model.template.languages).then(function(response){
        for(var i in model.template.languages){
          var language = model.template.languages[i];
          var transferredTextItem = _.filter(response.TranslateList, { Language : language })[0];
          var newItem;
          if(language === selectedLanguage){
            newItem = {
              id : newItemId,
              value : modelValue
            };
          }else{
            newItem = {
              id : newItemId,
              value : transferredTextItem.Text
            };
          }
          element.source[language].push(newItem);
        }

        $.event.trigger({
          type : "updateSelectListItem",
          element : $scope.element
        });
      });
    }else{
      for(var i in model.template.languages){
        var language = model.template.languages[i];
        var newItem = {
          id : newItemId,
          value : modelValue
        };

        element.source[language].push(newItem);
      }
      $.event.trigger({
        type : "updateSelectListItem",
        element : $scope.element
      });
    }



    $scope.inEditMode = false;
    $scope.currentItem = void 0;
    $scope.newItemModel = {
      id : generateId()
    };
  };

  $scope.saveSelectListItem = function(isNeedTrasnlate){
    var modelValue = $scope.newItemModel.value;
    var itemId = $scope.currentItem.id;


    if(isNeedTrasnlate){
      googleTranslate.translateText(modelValue, selectedLanguage, model.template.languages).then(function(response){

        // var transferredTextItem = $filter('filter')(response.TranslateList, {Language : currentItemSource.Language}, true)[0];

        for(var i in model.template.languages){
          var language = model.template.languages[i];
          var source = element.source[language];

          if(angular.isDefined(source)){
            var relatedItem = _.filter(source, { id : itemId})[0];

            if(angular.isDefined(relatedItem)){
                if(language === selectedLanguage)
                  relatedItem.value = modelValue;
                else{
                  var transferredTextItem =_.filter(response.TranslateList, {Language : language})[0];
                  relatedItem.value = transferredTextItem.Text;
                }
            }
          }
        }
        $.event.trigger({
          type : "updateSelectListItem",
          element : $scope.element
        });
        $scope.inEditMode = false;
        $scope.currentItem = void 0;
        $scope.newItemModel = {
          id : generateId()
        };
      });

    }else{
      var source = element.source[selectedLanguage];
      var relatedItem = _.filter(source, { id : itemId})[0];
      relatedItem.value = modelValue;
      $.event.trigger({
        type : "updateSelectListItem",
        element : $scope.element
      });
      $scope.inEditMode = false;
      $scope.currentItem = void 0;
      $scope.newItemModel = {
        id : generateId()
      };
    }


  };

  $scope.removeItem = function(item){
    for(var i in model.template.languages){
      var language = model.template.languages[i];

      if(angular.isDefined(element.source[language])){
        var relatedItem = _.filter(element.source[language], { id : item.id})[0];
        if(angular.isDefined(relatedItem)){
          var indexOf = element.source[language].indexOf(relatedItem);
          element.source[language].splice(indexOf, 1);
        }

      }
    }

    $.event.trigger({
      type : "updateSelectListItem",
      element : $scope.element
    });
  }

  $scope.onEdit = function(item){
    $scope.currentItem = item;
    $scope.newItemModel.value = item.value;
    $scope.newItemModel.id = item.id;
    $scope.inEditMode = true;
  };
};

module.exports = function(app){
  app.controller('selectListItemCtrl', ['$scope', '$modalInstance', 'generalsService', 'froalaEditorService', '$translate', 'googleTranslate', 'element', 'model', 'selectedLanguage', selectListItemCtrl]);
};
