var pdfTable = function (pdfElementService, pdfViewService) {
    return {
        restrict: 'E',
        replace: true,
        templateUrl: 'app/components/pdf/shared/directives/elements/pdfTable/pdfTable/pdfTable.html',
        link: function (scope, elem, attrs) {
            //Fields 
            var containerElement = elem;
            var innerElement = elem.find(".inner-element")
            //Fields End

            //Elements
            //Elements End

            //Inner Functions
            var setElementStyle = function () {
                containerElement.css(scope.element.containerStyle);
                containerElement.css("border", "1px solid transparent");

                var newStyle = pdfViewService.arrangeElementBorder(scope.element.style);
                innerElement.css(newStyle);
            };
            //Inner Functions End


            //Initials
            setElementStyle();
            //Initials End
        }
    }
};

module.exports = function (app) {
    app.directive('pdfTable', ['pdfElementService', 'pdfViewService', pdfTable]);
};