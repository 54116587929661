var newFolderCtrl = function($rootScope, $scope, $translate, folderFactory) {
    //Fields
    $scope.okButton = "FOLDER.PARTIALS.NEWFOLDER.BTN_OK.TEXT";
    $scope.isProcessing = false;
    //Fields End

    //Events
    $rootScope.$on('newFolderOpenned', function(event, data) {
        $scope.newFolderName = "";
    });

    $rootScope.$on('closeNewFolderOrRenameFolder', function(event, data) {
        $('#newFolder').css("display", "none");
    });


    //Events End

    //Inner Functions
    //Inner Functions End

    //Functions
    $scope.cancel = function() {
        $('#newFolder').slideToggle();
    };

    $scope.createNewFolder = function() {
        if ($scope.newFolderForm.$invalid)
            return;

        $scope.okButton = "FOLDER.PARTIALS.NEWFOLDER.BTN_OK.ALTERNATE_TEXT";
        $scope.isProcessing = true;

        var newFolderModel = {};
        newFolderModel.FolderType = $scope.folderType;
        newFolderModel.Parent = $scope.selectedFolder.id;
        newFolderModel.Name = $scope.newFolderName;

        folderFactory.createNewFolder(newFolderModel)
            .then(function(response) {
                $scope.okButton = "FOLDER.PARTIALS.NEWFOLDER.BTN_OK.TEXT";
                $scope.isProcessing = false;

                $scope.newFolderName = "";
                $('#newFolder').slideToggle();

                var message = $translate.instant("FOLDER.PARTIALS.NEWFOLDER.SUCCESS_MESSAGES.CREATED_NEW_FOLDER");
                toastr.success(message, '', { positionClass: 'toast-bottom-full-width', timeOut: 3000 });

                $scope.$emit('createdNewFolder', {
                    parentFolderId: newFolderModel.Parent,
                    newFolderName: newFolderModel.Name
                });
            });
    };
    //Functions End

    //Initials
    $scope.newFolderName = "";
    //Initials End
};


module.exports = function(app) {
    app.controller('newFolderCtrl', ['$rootScope', '$scope', '$translate', 'folderFactory', newFolderCtrl]);
};
