var uiRouter = function($stateProvider, $urlRouterProvider, $locationProvider) {

    $stateProvider
        .state('blank.loading', {
            url: '/loading',
            templateUrl: "/app/components/core.loading/loading/views/loading.html",
            controller: "loadingCtrl"
        });

    //HTML 5 Modu aktif (diyez işaretinin görünmemesi için)
    $locationProvider.html5Mode(true);
    // $urlRouterProvider.otherwise("/en/blank/loading");
};

module.exports = function(app) {
    app.config(['$stateProvider', '$urlRouterProvider', '$locationProvider', uiRouter]);
};
