var makeDisableCtrl = function($scope, $timeout, $translate, $modalInstance, userFactory, userId) {
    //Fields
    $scope.disableButton = "USER.ALLUSERS.MODALS.MAKEDISABLE.BTN_DISABLE.TEXT";
    $scope.isProcessing = false;
    //Fields End

    //Functions
    $scope.cancel = function() {
        $modalInstance.dismiss('cancel');
    };

    $scope.disable = function() {
        $scope.disableButton = "USER.ALLUSERS.MODALS.MAKEDISABLE.BTN_DISABLE.ALTERNATE_TEXT";
        $scope.isProcessing = true;

        userFactory.makeDisable(userId)
            .then(function(response) {
                if (response.status) {
                    $scope.disableButton = "USER.ALLUSERS.MODALS.MAKEDISABLE.BTN_DISABLE.SUCCESS";

                    $timeout(function() {
                        var message = $translate.instant("USER.ALLUSERS.MODALS.MAKEDISABLE.SUCCESS_MESSAGES.USER_MADE_DISABLE");
                        toastr.success(message, '', { positionClass: 'toast-bottom-full-width', timeOut: 3000 });
                        $modalInstance.close({
                            status: true
                        });
                    }, 500);
                }
            });


    };
    //Functions End

    //Initials
    //Initials End
};

module.exports = function(app) {
    app.controller('makeDisableCtrl', ['$scope', '$timeout', '$translate', '$modalInstance', 'userFactory', 'userId', makeDisableCtrl]);
};
