var archiveFactory = function($http, $q, httpProvider) {
    var factoryModel = {};

    factoryModel.getAllIconGroups = function() {
        var deferred = $q.defer();
        var res = $http.get("/app/components/customArchives/shared/json/icons.json");

        res.success(function(data, status, headers, config) {
            deferred.resolve(data);
        });

        res.error(function(msg, code) {
            deferred.reject(msg);
        });

        return deferred.promise;
    };

    factoryModel.getAllArchives = function() {
        return httpProvider.get('customArchive', 'getAllArchives');
    };

    factoryModel.getCustomArchiveById = function(customArchiveId) {
        return httpProvider.get('customArchive', 'getCustomArchiveById', 'customArchiveId=' + customArchiveId);
    }

    factoryModel.getActiveCustomArchiveCount = function() {
        return httpProvider.get('customArchive', 'getActiveCustomArchiveCount');
    };

    factoryModel.saveArchive = function(archiveModel) {
        return httpProvider.post('customArchive', 'saveArchive', archiveModel);
    };

    factoryModel.changedArchiveFields = function(customArchiveId, newFields) {
        var deferred = $q.defer();

        factoryModel.getCustomArchiveById(customArchiveId)
            .then(function(response) {
                var oldFields = response.fields;

                if (oldFields.length != newFields.length) {
                    deferred.resolve(true);
                } else {
                    for (var i = 0; i < oldFields.length; i++) {
                        var oldField = oldFields[i];
                        var newField = newFields[i];

                        if (oldField.name != newField.name || oldField.type != newField.type) {
                            deferred.resolve(true);
                        }
                    }

                    deferred.resolve(false);
                }
            });

        return deferred.promise;
    };

    factoryModel.updateArchive = function(customArchive) {
        return httpProvider.post('customArchive', 'updateArchive', customArchive);
    };

    factoryModel.deleteArchive = function(customArchiveId) {
        return httpProvider.delete('customArchive', 'deleteArchive', customArchiveId);
    };

    return factoryModel;
};

module.exports = function(app) {
    app.factory('archiveFactory', ['$http', '$q', 'httpProvider', archiveFactory]);
};
