var rangeSlider = function($rootScope, $parse){
    return {
        restrict : "AE",
        require: 'ngModel',
        link : function(scope, elm, attrs, ngModel){
            var unregister = scope.$watch(function(){
                return ngModel.$modelValue;

            }, initialize);

            function initialize(value){
                var min = attrs.min;
                var max = attrs.max;
                var step = attrs.step;
                var postfix = angular.isDefined(attrs.postfix) ? " " + attrs.postfix : void 0;

                var values = attrs.values;

                if(angular.isDefined(values)){

                  values = $parse(values)(scope);

                  elm.ionRangeSlider({
                     
                     values : values,
                     step : step,
                     from : ngModel.$modelValue,
                     grid: true,
                     grid_num: 1
                 });
                }else{
                  elm.ionRangeSlider({
                     min: min,
                     max: max,
                     postfix : postfix,
                     step : step,
                     from : ngModel.$modelValue,
                     grid: true,
                     grid_num: 1
                 });
                }


                unregister();
            }
        }
    }
};

module.exports = function(app){
    return app.directive('rangeSlider', ['$rootScope', '$parse', rangeSlider]);
};
