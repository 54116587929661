var pdfFooter = function ($compile, pdfViewService, _) {
    return {
        restrict: 'E',
        replace: true,
        templateUrl: 'app/components/pdf/shared/directives/pdfFooter/pdfFooter.html',
        link: function (scope, elem, attrs) {
            //Fields 
            //Fields End

            //Inner Functions
            var setFooterElements = function () {
                var footerId = scope.footer._id;
                var elements = _.filter(scope.pdfModel.elements, {
                    "parentId": footerId
                });

                elements = _.sortBy(elements, 'order');
                for (var i = 0, len = elements.length; i < len; i++) {
                    var element = elements[i];
                    var elementHtml = pdfViewService.createPdfElement(element.type);

                    var childScope = scope.$new();
                    childScope.element = element;
                    $(elem).append($compile(elementHtml)(childScope));
                }
            };

            var setFooterStyle = function () {
                elem.css(scope.footer.style);
            };
            //Inner Functions End

            //Initials 
            setFooterElements();
            setFooterStyle();
            //Initials End





        }
    };
};

module.exports = function (app) {
    app.directive('pdfFooter', ['$compile', 'pdfViewService', '_', pdfFooter]);
};