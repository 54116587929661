var allPermissionsCtrl = function($scope, $translate, $modal, permissionFactory) {
    //Fields
    //Fields End
    
    //Inner Functions
    var getAllAccountPermissions = function() {
        $scope.tdsMakerPromise.allPermissions = {};
        $scope.tdsMakerPromise.allPermissions.message = $translate.instant("PERMISSION.ALLPERMISSIONS.INFO_MESSAGES.LOADING_PERMISSIONS");
        $scope.tdsMakerPromise.allPermissions.promise = permissionFactory.getAllAccountPermissions()
            .then(function(response) {
                $scope.accountPermissions = response;
            });
    };
    //Inner Functions End

    //Functions
    $scope.openGrantPermissionModal = function() {
        var modalInstance = $modal.open({
            animation: false,
            backdrop: 'static',
            controller: 'grantPermissionCtrl',
            templateUrl: 'app/components/permissions/shared/views/modals/grantPermission.html',
            resolve: {
                viewScope: function() {
                    return $scope;
                }
            }
        });

        modalInstance.result
            .then(function(response) {
                if (response.status) {
                    getAllAccountPermissions();
                }
            });
    };
    $scope.grantPermissionModalOkClicked = function(selectedGroupsOrUsers, selectedPermissionLevels, nextProcess) {
        var accountPermissions = [];
        for (var i = 0; i < selectedGroupsOrUsers.length; i++) {
            var selectedGroupOrUser = selectedGroupsOrUsers[i];

            var accountPermission = {};
            accountPermission.ReferanceId = selectedGroupOrUser._id;
            accountPermission.IsGroup = selectedGroupOrUser.IsGroup;
            accountPermission.PermissionLevels = [];
            angular.forEach(selectedPermissionLevels, function(value, key) {
                accountPermission.PermissionLevels.push(value._id);
            });

            accountPermissions.push(accountPermission);
        }

        permissionFactory.saveAccountPermissions(accountPermissions)
            .then(function(response) {
                nextProcess(response);
            });
    };

    $scope.openDeletePermissionModal = function(permissionId) {
        var modalInstance = $modal.open({
            animation: false,
            controller: 'deletePermissionCtrl',
            templateUrl: 'app/components/permissions/shared/views/modals/deletePermission.html',
            backdrop: 'static',
            resolve: {
                viewScope: function() {
                    return $scope;
                },
                permissionId: function() {
                    return permissionId;
                }
            }
        });

        modalInstance.result
            .then(function(response) {
                getAllAccountPermissions();
            });
    };
    $scope.deletePermissionModalDeleteClicked = function(permissionId, nextProcess) {
        permissionFactory.deleteAccountPermission(permissionId)
            .then(function(response) {
                nextProcess(response);
            });
    };

    //Edit Permission ı aç,
    $scope.openEditPermissionModal = function(permissionId) {
        var modalInstance = $modal.open({
            animation: false,
            controller: 'editPermissionCtrl',
            templateUrl: 'app/components/permissions/shared/views/modals/editPermission.html',
            backdrop: 'static',
            resolve: {
                viewScope: function() {
                    return $scope;
                },
                permissionId: function() {
                    return permissionId;
                }
            }
        });

        modalInstance.result
            .then(function(response) {
                getAllAccountPermissions();
            });
    };
    //Edit Permission Modal açıldıktan sonra,
    $scope.editPermissionModalOpened = function(permissionId, nextProcess) {
        permissionFactory.getAccountPermissionById(permissionId)
            .then(function(response) {
                var selectedGroupsOrUsers = [];
                if (response.IsGroup) {
                    selectedGroupsOrUsers.push({
                        "_id": response.Referance._id,
                        "Name": response.Referance.Name,
                        "IsGroup": response.IsGroup
                    });
                } else {
                    var itemName = "";
                    if (angular.isDefined(response.Referance.FirstName) && angular.isDefined(response.Referance.LastName)) {
                        itemName = response.Referance.Email + '(' + response.Referance.FirstName + ' ' + response.Referance.LastName + ')';
                    } else {
                        itemName = response.Referance.Email;
                    }

                    selectedGroupsOrUsers.push({
                        "_id": response.Referance._id,
                        "Name": itemName,
                        "IsGroup": response.IsGroup
                    });
                }

                var permissionLevels = response.PermissionLevels;
                nextProcess(selectedGroupsOrUsers, permissionLevels);
            });
    };
    //Edit Permission Modal'da OK e basıldığında,
    $scope.editPermissionModalOkClicked = function(selectedGroupsOrUsers, selectedPermissionLevels, nextProcess) {
        var accountPermissions = [];
        for (var i = 0; i < selectedGroupsOrUsers.length; i++) {
            var selectedGroupOrUser = selectedGroupsOrUsers[i];

            var accountPermission = {};
            accountPermission.ReferanceId = selectedGroupOrUser._id;
            accountPermission.IsGroup = selectedGroupOrUser.IsGroup;
            accountPermission.PermissionLevels = [];
            angular.forEach(selectedPermissionLevels, function(value, key) {
                accountPermission.PermissionLevels.push(value._id);
            });

            accountPermissions.push(accountPermission);
        }

        permissionFactory.saveAccountPermissions(accountPermissions)
            .then(function(response) {
                nextProcess(response);
            });
    };

    //Functions End

    //Initials
    getAllAccountPermissions();
    //Initials End
};

module.exports = function(app) {
    app.controller('allPermissionsCtrl', ['$scope', '$translate', '$modal', 'permissionFactory', allPermissionsCtrl]);
};
