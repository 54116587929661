var uiRooter = function ($stateProvider, $urlRouterProvider, $locationProvider) {
  $stateProvider
    .state('pdf.preparing', {
      url: '/preparing',
      templateUrl: "/app/components/pdf/preparing/views/index.html",
    })
    .state('pdf.previewTemplate', {
      url: '/template-preview/:id/:templateLang',
      templateUrl: "/app/components/pdf/previewTemplate/views/previewTemplate.html",
      controller: "previewTemplateCtrl"
    })
    .state('pdf.previewDatasheet', {
      url: '/datasheet-preview/:id/:datasheetLang',
      templateUrl: "/app/components/pdf/previewDatasheet/views/previewDatasheet.html",
      controller: "previewDatasheetCtrl"
    })
    .state('pdf.viewDatasheet', {
      url: '/datasheet-view/:id/:datasheetLang',
      templateUrl: "/app/components/pdf/viewDatasheet/views/viewDatasheet.html",
      controller: "viewDatasheetCtrl"
    })
    .state('pdf.view', {
      url: '/view/:id/:datasheetLang',
      templateUrl: "/app/components/pdf/viewPdf/views/viewPdf.html",
      controller: "viewPdfCtrl"
    })
    .state('pdf.viewPreview', {
      url: '/view-preview',
      templateUrl: "/app/components/pdf/viewPreview/views/viewPreview.html",
      controller: 'viewPreviewCtrl'
    })
    .state('pdf.viewCustomFile', {
      url: '/view-custom-file/:customFileId',
      templateUrl: "/app/components/pdf/viewCustomFile/views/viewCustomFile.html",
      controller: 'viewCustomFileCtrl'
    })
    .state('pdf.viewWithRoute', {
      url: '/view/:id/:datasheetLang/:source',
      templateUrl: "/app/components/pdf/viewPdf/views/viewPdf.html",
      controller: "viewPdfCtrl"
    });

  /* removeIfProduction */
  // $urlRouterProvider.otherwise("/en/pdf/datasheet-preview/57398392d78b17f40d2d3bf5/en");
  /* endRemoveIfProduction */
};

module.exports = function (app) {
  app.config(['$stateProvider', '$urlRouterProvider', '$locationProvider', uiRooter]);
};
