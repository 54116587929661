var pdfInputText = function($translate, pdfElementService,pdfViewService) {
    return {
        restrict: 'E',
        replace: true,
        templateUrl: 'app/components/pdf/shared/directives/elements/pdfInputText/pdfInputText.html',
        link: function(scope, elem, attrs) {
            //Fields
            var containerElement = elem;
            var innerElement = elem.find('.inner-element');
            //Fields End

            //Elements
            //Elements End

            //Inner Functions
            var setElementStyle = function() {
                containerElement.css(scope.element.containerStyle);

                var newStyle = pdfViewService.arrangeElementBorder(scope.element.style);
                innerElement.css(newStyle);
            };            

            var setElementValue = function() {
                var innerElementHtml = pdfElementService.getInputTextValue(scope.pdfModel.mode, scope.element,scope.selectionModel.selectedLanguage);
                innerElement.html(innerElementHtml);
            };
            //Inner Functions End

            //Events
            //Events End

            //Handlers
            //Handlers End

            //Initials
            setElementStyle();
            setElementValue();
            //Initials End
        }
    };
};

module.exports = function(app) {
    app.directive('pdfInputText', ['$translate', 'pdfElementService','pdfViewService', pdfInputText]);
};