var translateWarningCtrl = function ($scope, $modalInstance) {
    //Fields 
    //Fields End

    //Functions
    $scope.cancel = function () {
        $modalInstance.dismiss();
    };

    $scope.confirm = function () {
        $modalInstance.close({
            status: true
        });
    };
    //Functions

    //Initials 
    //Initials End
};

module.exports = function (app) {
    app.controller('translateWarningCtrl', ['$scope', '$modalInstance', translateWarningCtrl]);
};