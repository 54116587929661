var selectIconCtrlNew = function($scope, $translate, $modalInstance, $log, $filter, element, elementService) {

		elementService.iconList().then(function(response) {
			$scope.iconList = response;
		});

		//Fields

		$scope.categoryList = [{
			"Name": $translate.instant('TEMPLATE.EDIT_TEMPLATE.MODALS.SELECT_ICON.LBL_PAINT.TEXT'),
			"_id": 1
		}, {
			"Name": $translate.instant('TEMPLATE.EDIT_TEMPLATE.MODALS.SELECT_ICON.LBL_DIY.TEXT'),
			"_id": 2
		}, {
			"Name": $translate.instant('TEMPLATE.EDIT_TEMPLATE.MODALS.SELECT_ICON.LBL_ECO_FRIENDLY.TEXT'),
			"_id": 3
		}, {
			"Name": $translate.instant('TEMPLATE.EDIT_TEMPLATE.MODALS.SELECT_ICON.LBL_FIRE.TEXT'),
			"_id": 4
		}, {
			"Name": $translate.instant('TEMPLATE.EDIT_TEMPLATE.MODALS.SELECT_ICON.LBL_TOOLS.TEXT'),
			"_id": 5
		},{
			"Name": $translate.instant('TEMPLATE.EDIT_TEMPLATE.MODALS.SELECT_ICON.LBL_OTHER.TEXT'),
			"_id": 6
		}];

		$scope.iconList = [];
		$scope.optionTabId = $scope.categoryList[0]._id;
		//Fields - End

		//Functions
		$scope.changeTab = function(id) {
			$scope.optionTabId = id;
		};

		$scope.getIcons = function() {
			var list = $filter('filter')($scope.iconList, {
				categoryId: $scope.optionTabId
			}, true);
			return list;
		};

		$scope.selectIcon = function(icon) {
			element.value = icon.icon;
			$.event.trigger({
				type : 'updateIcon'
			});
			// for (var i = 0; i < element.elementValue.length; i++) {
			// 	var currentValue = element.elementValue[i];
			// 	currentValue.Value = icon.icon;
			// }
			// element.elementStyle.Size.Width = element.elementStyle.FontStyle.FontSize;
			// element.elementStyle.Size.WidthType = "px";
			// element.elementStyle.Size.Height = element.elementStyle.FontStyle.FontSize;
			// element.elementStyle.Size.HeightType = "px";
			$scope.cancel();
		};

		$scope.cancel = function() {
			$modalInstance.dismiss('cancel');
		};
		//Functions - End
	};

module.exports = function(app){
  app.controller('selectIconCtrlNew', ['$scope', '$translate', '$modalInstance', '$log', '$filter', 'element', 'elementService', selectIconCtrlNew]);
};
