var signUpCtrl = function ($rootScope, $scope, $state, $timeout, $translate, locales, authFactory, intercomFactory, config) {
    //Fields
    $scope.signUpButton = "AUTHENTICATION.SIGNUP.BTN_REGISTER_NOW.TEXT";
    $scope.disabled = false;
    $scope.status = true;

    $scope.LBL_PRIVACY_POLICY = $translate.instant('GENERAL.LBL_PRIVACY_POLICY.TEXT');

    $scope.locales = locales;
    //Fields - End

    //Watches
    $scope.$watch('email', function (newValue, oldValue) {
        if (newValue != null && newValue != undefined)
            $scope.email = newValue.toLowerCase();
    });
    //Watches End

    //Events
    $rootScope.$on('$translateChangeSuccess', function (event, current, previous) {
        $scope.LBL_PRIVACY_POLICY = $translate.instant('GENERAL.LBL_PRIVACY_POLICY.TEXT');
    });
    //Events End

    //Functions
    $scope.changeLanguage = function (language) {
        $rootScope.activeLang = language.LanguageCode;
        $rootScope.$emit('changedLanguage', {
            languageCode: language.LanguageCode
        });
        $state.go('auth.signUp', {
            lang: $rootScope.activeLang
        });
    };

    $scope.register = function () {
        if($scope.email !== "") {
            if (!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test($scope.email)) {
                $scope.status = false;
                $scope.customErrorMessage = $translate.instant("AUTHENTICATION.SIGNUP.ERROR_MESSAGES.EMAIL_NOT_VALID");
                return;
            } else {
                $scope.status = null;
                $scope.customErrorMessage = "";
            }
        }

        if ($scope.registerForm.$valid) {
            $scope.signUpButton = "AUTHENTICATION.SIGNUP.BTN_REGISTER_NOW.ALTERNATE_TEXT";
            $scope.disabled = true;

            var model = {
                companyName: $scope.companyName,
                email: $scope.email,
                language: $rootScope.activeLang
            };

            authFactory.signUp(model)
                .then(function (response) {
                    if (response.status) {
                        var user = response.user;
                        var account = response.account;

                        var validationLink = config.app[config.mode].appUrl + "/" + $rootScope.activeLang + "/auth/email-validation/" + response.user.EmailValidationCode;
                        var hash = CryptoJS.HmacSHA256(response.user._id, "O7B-0Ex_OM-j-GINwCn7Ok9qIoTykVSntBm4PzRM");
                        var base64 = hash.toString();

                        var addEventPromise = intercomFactory.addEventToPoll({
                            event_name: "Sign Up First Step"
                        }, user._id);

                        $state.go('auth.verifyEmail', {
                            lang: $rootScope.activeLang
                        });
                    } else {
                        $scope.status = response.status;
                        $scope.customErrorMessage = response.message;
                    }

                    $scope.signUpButton = "AUTHENTICATION.SIGNUP.BTN_REGISTER_NOW.TEXT";
                    $scope.disabled = false;
                });
        }
    };
    //Functions End

    //Inner Functions
    //Inner Functions End

    //Initials

    //Initials End
};

module.exports = function (app) {
    app.controller('signUpCtrl', ['$rootScope', '$scope', '$state', '$timeout', '$translate', 'locales', 'authFactory', 'intercomFactory', 'config', signUpCtrl]);
};
