var menuToggleFactory = function($rootScope, localStorageService) {
    var factoryModel = {};

    //Functions
    factoryModel.toggle = function() {
        toggleMenu(true);
    };

    factoryModel.expand = function() {
        var wrapper = $("#wrapper");
        if (wrapper.hasClass("enlarged")) {
            toggleMenu(false);
        }
    };

    factoryModel.expandAndKeep = function() {
        var wrapper = $("#wrapper");
        if (wrapper.hasClass("enlarged")) {
            toggleMenu(true);
        }
    };

    factoryModel.collapse = function() {
        var wrapper = $("#wrapper");
        if (!wrapper.hasClass("enlarged")) {
            toggleMenu(false);
        }
    };

    factoryModel.collapseAndKeep = function() {
        var wrapper = $("#wrapper");
        if (!wrapper.hasClass("enlarged")) {
            toggleMenu(true);
        }
    };

    factoryModel.doPreviousStatus = function() {
        var wrapper = $("#wrapper");

        var previousSideMenuStatus = localStorageService.get("sideMenuStatus");
        var currentSideMenuStatus = wrapper.hasClass("enlarged") ? "Collapse" : "Expand";

        if (previousSideMenuStatus != currentSideMenuStatus) {
            toggleMenu(true);
        }
    };

    factoryModel.isExpand = function() {
        var wrapper = $("#wrapper");
        if (wrapper.hasClass("enlarged")) {
            return false
        } else {
            return true;
        }
    };
    factoryModel.init = function(){
        init();
    }
    //Functions End

    //Inner Functions
    var toggleMenu = function(isKept) {
        $("#wrapper").toggleClass("enlarged");
        $("#wrapper").addClass("forced");

        if ($("#wrapper").hasClass("enlarged") && $("body").hasClass("fixed-left")) {
            $("body").removeClass("fixed-left").addClass("fixed-left-void");
        } else if (!$("#wrapper").hasClass("enlarged") && $("body").hasClass("fixed-left-void")) {
            $("body").removeClass("fixed-left-void").addClass("fixed-left");
        }

        if ($("#wrapper").hasClass("enlarged")) {
            $(".left ul").removeAttr("style");
        } else {
            $(".subdrop").siblings("ul:first").show();
        }

        if ($("#wrapper").hasClass("enlarged")) {
            $(".slimscrollleft").css("overflow", "inherit").parent().css("overflow", "inherit");
            $(".slimscrollleft").siblings(".slimScrollBar").css("visibility", "hidden");
        } else {
            $(".slimscrollleft").css("overflow", "initial").parent().css("overflow", "initial");
            $(".slimscrollleft").siblings(".slimScrollBar").css("visibility", "visible");
        }
        $("body").trigger("resize");

        if (isKept) {
            var sideMenuStatus = $("#wrapper").hasClass("enlarged") ? "Collapse" : "Expand";
            localStorageService.set("sideMenuStatus", sideMenuStatus);
        }

        init(0);


    };
    var init = function(delay) {
        $(document).ready(function(){
            setTimeout(function(){
                if ($("#wrapper").hasClass("enlarged")) {
                    $('#mobileAppIcons').hide();
                    $('#mobileAppIconsCollapsed').show();
                } else {
                    $('#mobileAppIconsCollapsed').hide();
                    $('#mobileAppIcons').show();
                }
            }, delay);
        });
    }
    //Inner Functions End

    return factoryModel;
};

module.exports = function(app) {
    app.factory('menuToggleFactory', ['$rootScope', 'localStorageService', menuToggleFactory]);
};
