var run = function ($rootScope, intercomFactory, userFactory, config, environment) {
    var firebaseConfig = environment.FIREBASE || config.firebase[config.mode];
    if (firebase && firebase.apps.length === 0)
        firebase.initializeApp(firebaseConfig);

    $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams, options) {
        if (toState !== undefined && toState.name === "blank.loading")
            return;

        //INTERCOM
        if (fromState.name != "" &&
            toState.name != "blank.loading" &&
            toState.name != "auth.signIn" &&
            toState.name != "auth.signInAuto" &&
            toState.name != "auth.signUp" &&
            toState.name != "auth.verifyEmail" &&
            toState.name != "auth.verifyChangeEmail" &&
            toState.name != "auth.lastStep" &&
            toState.name != "auth.confirmInvitation" &&
            toState.name != "auth.resetPassword" &&
            toState.name != "pdf.previewDatasheet" &&
            toState.name != "pdf.previewTemplate" &&
            toState.name != "pdf.viewDatasheet" &&
            toState.name != "pdf.view" &&
            toState.name != "pdf.viewWithRoute" &&
            toState.name != "pdf.viewPreview" &&
            toState.name != "pdf.viewCustomFile" &&
            toState.name != "pdf.preparing") {

            if (INTERCOM_SETTINGS.IS_SHOWN) {
                window.Intercom('shutdown');
                userFactory.loadUserInformation()
                    .then(function (user) {
                        window.Intercom('boot', {
                            app_id: INTERCOM_SETTINGS.APP_ID,
                            email: user.Email,
                            user_id: user._id,
                            user_hash: user.hash
                        });
                    });
            }
            intercomFactory.addEvent({
                event_name: toState.name + " Page Visited"
            });
        }
    });
};

module.exports = function (app) {
    app.run(['$rootScope', 'intercomFactory', 'userFactory', 'config', 'environment', run]);
};
