var moveFolderCtrl = function($scope, $filter, $timeout, $translate, $modalInstance, folderFactory, checkPermissionFactory, folders, folder) {
    //Fields
    $scope.selectedFolder = {};

    $scope.okButton = "FOLDER.MODALS.MOVEFOLDER.BTN_OK.TEXT";
    $scope.isProcessing = false;

    var copiedFolders = angular.copy(folders);
    //Fields End

    //Functions
    $scope.cancel = function() {
        $modalInstance.dismiss();
    };

    $scope.move = function() {
        $scope.okButton = "FOLDER.MODALS.MOVEFOLDER.BTN_OK.ALTERNATE_TEXT";
        $scope.isProcessing = true;

        var destinationFolderId = folder.id;
        var targetFolderId = $scope.selectedFolder.id;
        folderFactory.moveFolder(destinationFolderId, targetFolderId)
            .then(function(response) {
                $scope.okButton = "FOLDER.MODALS.MOVEFOLDER.BTN_OK.TEXT";
                $scope.isProcessing = false;
                if (response.status) {
                    var message = $translate.instant("FOLDER.MODALS.MOVEFOLDER.SUCCESS_MESSAGES.MOVED_FOLDER");
                    toastr.success(message, '', { positionClass: 'toast-bottom-full-width', timeOut: 3000 });

                    $modalInstance.close({
                        status: true
                    });
                }
            });
    };
    //Functions End

    //Inner Functions
    var initialize = function() {
        makeDisable(folder.id);
        $scope.folders = copiedFolders;
    };
    var makeDisable = function(folderId) {
        $filter('filter')(copiedFolders, {
            id: folderId
        })[0].state.disabled = true;

        var childFolders = $filter('filter')(copiedFolders, {
            parent: folderId
        });

        for (var f = 0; f < childFolders.length; f++) {
            var childFolder = childFolders[f];

            makeDisable(childFolder.id);
        }
    };
    //Inner Functions End

    //Initials
    initialize();

    //Initials End
};

module.exports = function(app) {
    app.controller('moveFolderCtrl', ['$scope', '$filter', '$timeout', '$translate', '$modalInstance', 'folderFactory', 'checkPermissionFactory', 'folders', 'folder', moveFolderCtrl]);
};
