var shareDatasheetWithEmailCtrl = function ($rootScope, $scope, $stateParams, $filter, $timeout, $translate, $modalInstance, localStorageService, emailListService, shareFactory, datasheetFactory, languageFactory, checkPermissionFactory, parentScope, datasheet) {
    //Fields
    $scope.currentStep = 1;
    $scope.emptyEmailGroupOption = $translate.instant("CUSTOMARCHIVES.YOURARCHIVES.MODALS.SHAREARCHIVEFILEWITHEMAIL.SLST_EMAIL_GROUPS.EMPTY_OPTION.TEXT");
    $scope.errors = [];

    $scope.shareButton = "DATASHEET.YOURDATASHEETS.MODALS.SHAREDATASHEETWITHEMAIL.BTN_SHARE.TEXT";
    $scope.isProcessing = false;
    //Fields - End

    //Events Handler
    //Events Handler End

    //Watches
    //Watches End

    //Functions
    $scope.previous = function () {
        $scope.formErrors = false;
        if ($scope.currentStep === 3)
            $scope.currentStep -= 2;
        else
            $scope.currentStep -= 1;
    };

    $scope.next = function () {
        $scope.errors = [];
        if ($scope.sharingModel.SelectedVersion === null) {
            $scope.errors.push("DATASHEET.YOURDATASHEETS.MODALS.SHAREDATASHEETWITHEMAIL.ERROR_MESSAGES.SELECT_VERSION");
        }

        if (($scope.sharingModel.SelectedEmailGroup === null || angular.isUndefined($scope.sharingModel.SelectedEmailGroup)) && ($scope.sharingModel.CustomEmails === null || angular.isUndefined($scope.sharingModel.CustomEmails) || $scope.sharingModel.CustomEmails === "")) {
            $scope.errors.push("DATASHEET.YOURDATASHEETS.MODALS.SHAREDATASHEETWITHEMAIL.ERROR_MESSAGES.ADD_ONE_EMAIL");
        }

        if ($scope.sharingModel.SelectedLanguages !== null) {
            var hasSelectedLanguage = false;
            angular.forEach($scope.sharingModel.SelectedLanguages, function (value, key) {
                if (value)
                    hasSelectedLanguage = true;

            });

            if (hasSelectedLanguage === false) {
                $scope.errors.push("DATASHEET.YOURDATASHEETS.MODALS.SHAREDATASHEETWITHEMAIL.ERROR_MESSAGES.SELECT_LANGUAGE");
            }
        }

        if ($scope.sharingModel.SelectedEmailGroup == $scope.emptyEmailGroupOption && ($scope.sharingModel.CustomEmails === null || angular.isUndefined($scope.sharingModel.CustomEmails) || $scope.sharingModel.CustomEmails === ""))
            $scope.errors.push("DATASHEET.YOURDATASHEETS.MODALS.SHAREDATASHEETWITHEMAIL.ERROR_MESSAGES.ADD_ONE_EMAIL");

        if ($scope.errors.length > 0) {
            return;
        }

        $scope.currentStep += 1;

        if ($scope.currentStep === 2) {
            if ($scope.mailTemplateMode === "custom")
                $scope.currentStep = 3;
        }
    };
    $scope.shareFromTemplate = function () {
        $scope.errors = [];
        if ($scope.sharingModel.SelectedVersion === null) {
            $scope.errors.push("DATASHEET.YOURDATASHEETS.MODALS.SHAREDATASHEETWITHEMAIL.ERROR_MESSAGES.SELECT_VERSION");
        }

        if (($scope.sharingModel.SelectedEmailGroup === null || angular.isUndefined($scope.sharingModel.SelectedEmailGroup)) && ($scope.sharingModel.CustomEmails === null || angular.isUndefined($scope.sharingModel.CustomEmails) || $scope.sharingModel.CustomEmails === "")) {
            $scope.errors.push("DATASHEET.YOURDATASHEETS.MODALS.SHAREDATASHEETWITHEMAIL.ERROR_MESSAGES.ADD_ONE_EMAIL");
        }

        if ($scope.sharingModel.SelectedLanguages !== null) {
            var hasSelectedLanguage = false;
            angular.forEach($scope.sharingModel.SelectedLanguages, function (value, key) {
                if (value)
                    hasSelectedLanguage = true;

            });

            if (hasSelectedLanguage === false) {
                $scope.errors.push("DATASHEET.YOURDATASHEETS.MODALS.SHAREDATASHEETWITHEMAIL.ERROR_MESSAGES.SELECT_LANGUAGE");
            }
        }

        if ($scope.sharingModel.SelectedEmailGroup == $scope.emptyEmailGroupOption && ($scope.sharingModel.CustomEmails === null || angular.isUndefined($scope.sharingModel.CustomEmails) || $scope.sharingModel.CustomEmails === ""))
            $scope.errors.push("DATASHEET.YOURDATASHEETS.MODALS.SHAREDATASHEETWITHEMAIL.ERROR_MESSAGES.ADD_ONE_EMAIL");

        if ($scope.errors.length > 0) {
            return;
        }

        $scope.share();
    };

    $scope.share = function () {
        $scope.shareButton = "DATASHEET.YOURDATASHEETS.MODALS.SHAREDATASHEETWITHEMAIL.BTN_SHARE.ALTERNATE_TEXT";
        $scope.isProcessing = true;

        var requestModel = {};
        requestModel.pubslihedId = $scope.sharingModel.SelectedVersion._id;
        requestModel.emailGroups = $scope.sharingModel.SelectedEmailGroup;
        requestModel.customEmails = $scope.sharingModel.CustomEmails;
        requestModel.languages = [];
        requestModel.templateId = $scope.sharingModel.selectedMailTemplateId;


        angular.forEach($scope.sharingModel.SelectedLanguages, function (value, key) {
            if (value) {
                var selectedLanguage = $filter('filter')($scope.languages, {
                    "isoCode": key
                })[0];

                var object = {};
                object.Language = key;
                object.DisplayName = $translate.instant(selectedLanguage.languageCode);
                requestModel.languages.push(object);
            }
        });
        requestModel.subject = $scope.sharingModel.Subject;
        requestModel.message = $scope.sharingModel.Message;

        shareFactory.shareDatasheetWithEmail(requestModel)
            .then(function(response) {
                $scope.shareButton = "DATASHEET.YOURDATASHEETS.MODALS.SHAREDATASHEETWITHEMAIL.BTN_SHARE.TEXT";
                $scope.isProcessing = false;

                var message = $translate.instant("DATASHEET.YOURDATASHEETS.MODALS.SHAREDATASHEETWITHEMAIL.SUCCESS_MESSAGES.SUCCESSFUL_SHARE");
                toastr.success(message, '', { positionClass: 'toast-bottom-full-width', timeOut: 3000 });

                $timeout(function () {
                    $modalInstance.dismiss('cancel');
                }, 500);
            });
    };

    $scope.cancel = function () {
        $modalInstance.dismiss('cancel');
    };

    $scope.getLanguageCode = function (language) {
        if (angular.isDefined($scope.languages)) {
            var selectedLanguage = $filter('filter')($scope.languages, {
                "isoCode": language
            })[0];

            return selectedLanguage.languageCode;
        }

    };

    $scope.templateLanguageChanged = function () {
        $scope.sharingModel.selectedMailTemplateId = _.find($scope.mailTemplates, { language: $scope.sharingModel.selectedTemplateLanguage })._id;
    };
    //Functions End

    //Inner Functions
    var intialize = function () {
        $scope.prepared = false;
        checkPermissionFactory.canSelectDatasheetVersion(datasheet._id)
            .then(function (canSelectDatasheetVersion) {
                console.timeEnd("check permission");
                $scope.canSelectDatasheetVersion = canSelectDatasheetVersion;

                //Datasheet de kullanılacak tüm diller sistemden çekilir.
                languageFactory.getLanguages()
                    .then(function (response) {
                        $scope.languages = response;
                    });

                initializeSharingModel(function () {
                    getPublishedDatasheets(datasheet._id, function () {
                        getAllEmailTags();
                        console.timeEnd("preparing");
                        $scope.prepared = true;
                    });
                });


                shareFactory.getMailTemplate('datasheetShare').then(function (response) {
                    console.log("response", response);
                    $scope.mailTemplates = response;
                    if (response[0].sysDefault) {
                        $scope.mailTemplateMode = "sysDefault";
                        $scope.sharingModel.selectedTemplateLanguage = $rootScope.activeLang;
                    }
                    else {
                        $scope.mailTemplateMode = "custom";
                        $scope.sharingModel.selectedTemplateLanguage = response[0].language;
                    }

                    $scope.templateLanguageChanged();
                });
            });
    };

    var initializeSharingModel = function (nextProcess) {
        $scope.sharingModel = {};
        $scope.sharingModel.Subject = $translate.instant("DATASHEET.YOURDATASHEETS.MODALS.SHAREDATASHEETWITHEMAIL.SHAREEMAIL.SUBJECT");
        $scope.sharingModel.Subject = $scope.sharingModel.Subject.replace("$ProductName$", datasheet.productName);
        $scope.sharingModel.Message = $translate.instant("DATASHEET.YOURDATASHEETS.MODALS.SHAREDATASHEETWITHEMAIL.SHAREEMAIL.MESSAGE");
        $scope.sharingModel.Message = $scope.sharingModel.Message.replace("$ProductName$", datasheet.productName);
        $scope.sharingModel.FormVersions = [];
        $scope.sharingModel.EmailGroups = [];
        $scope.sharingModel.SelectedLanguages = {};

        var userInformationData = localStorageService.get('userInformationData');
        if (userInformationData) {
            $scope.sharingModel.UserFullName = userInformationData.FirstName + ' ' + userInformationData.LastName;
            $scope.sharingModel.Message = $scope.sharingModel.Message.replace("$UserName$", $scope.sharingModel.UserFullName);
        }

        nextProcess();
    };

    var getPublishedDatasheets = function (datasheetId, nextProcess) {
        datasheetFactory.getPublishedDatasheets(datasheetId)
            .then(function (response) {
                if (response.length > 0) {
                    $scope.sharingModel.FormVersions = $filter('orderBy')(response, 'majorVersion', true);
                    $scope.sharingModel.SelectedVersion = $scope.sharingModel.FormVersions[0];

                    $scope.sharingModel.Languages = [];

                    for (var i = 0; i < $scope.sharingModel.SelectedVersion.pdfFiles.length; i++) {
                        $scope.sharingModel.Languages.push($scope.sharingModel.SelectedVersion.pdfFiles[i].language);

                        $scope.sharingModel.SelectedLanguages[$scope.sharingModel.SelectedVersion.pdfFiles[i].language] = true;
                    }

                    nextProcess();
                }
            });
    };

    var getAllEmailTags = function () {
        $scope.sharingModel.EmailGroups = [];

        $scope.sharingModel.EmailGroups.push($scope.emptyEmailGroupOption);
        emailListService.getAllEmailTags()
            .then(function (response) {
                var emailTags = response;
                for (var i = 0; i < emailTags.length; i++) {
                    var emailTag = emailTags[i];

                    $scope.sharingModel.EmailGroups.push('' + emailTag + '');
                }

                if ($scope.sharingModel.EmailGroups.length > 0)
                    $scope.sharingModel.SelectedEmailGroup = $scope.sharingModel.EmailGroups[0];
            });
    };

    //Inner Functions End

    //Initials
    intialize();
    //Initials - End
};

module.exports = function (app) {
    app.controller('shareDatasheetWithEmailCtrl', ['$rootScope', '$scope', '$stateParams', '$filter', '$timeout', '$translate', '$modalInstance', 'localStorageService', 'emailListService', 'shareFactory', 'datasheetFactory', 'languageFactory', 'checkPermissionFactory', 'parentScope', 'datasheet', shareDatasheetWithEmailCtrl]);
};
