//Required Packages
require('angular-ui-router');


require("../core/");

//Module
var coreLayoutModule = angular.module('tdsApp.core.layout', [
    'tdsApp.core',
    'ui.router'
]);

//Run

//Config

//Router
require("./routing.js")(coreLayoutModule);

//Controllers
require("./layouts/controllers/loginLayoutController")(coreLayoutModule);

//Partials Controllers
require("./layouts/controllers/_partials/topBarController.js")(coreLayoutModule);
require("./layouts/controllers/_partials/sideMenuController.js")(coreLayoutModule);

module.exports = coreLayoutModule;
