require("./../../../../bower_components/angular/angular.js");

//Module
var backupModule = angular.module('tdsApp.backup',[
  'tdsApp.core'
]);

//Routers
require('./routing.js')(backupModule);

//Factories
require('./shared/factories/backupFactory.js')(backupModule);
//Controllers
require('./backupOptions/controllers/backupOptionsController.js')(backupModule);

//Modals
require('./backupOptions/controllers/modals/ftpChannelModalController.js')(backupModule);
module.exports = backupModule;
