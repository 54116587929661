var viewDatasheetCtrl = function ($scope, $stateParams, datasheetFactory, datasheetDataEditingService) {
    //Fields 
    var publishedDatasheetId = $stateParams.id;
    var language = $stateParams.datasheetLang;

    $scope.pdfModel = {
        mode: 'datasheet-view'
    };
    $scope.selectionModel = {
        selectedLanguage: language
    };

    $scope.loading = {
        pdf: false
    };
    //Fields End

    //Events
    $(document).off("pdfGenerated").on('pdfGenerated', function (event, data) {
        setTimeout(function () {
            // $(window).load(function () {
            window.status = "printPage";

            //PhantomJS
            if (typeof window.callPhantom === 'function') {
                window.callPhantom({ printStatus: 'ready' });
            }
            // });
        }, 1000);
    });
    //Events End


    //Inner Functions
    var initializePdfModel = function () {
        $scope.loading.pdf = false;
        getPublishedDatasheetById(publishedDatasheetId, function (response) {
            $scope.pdfModel.datasheetModel = response.datasheetModel;
            $scope.pdfModel.templateModel = response.templateModel;
            $scope.pdfModel.elements = [];

            $scope.pdfModel.elements = [];
            var elements = datasheetDataEditingService.mergeDatasheetAndTemplateElements(response.datasheetModel.elements, response.templateModel.elements);

            for (var e = 0; e < elements.length; e++) {
                var element = elements[e];
                if (!element.isHidden) {
                    $scope.pdfModel.elements.push(element);
                }
                else {
                    if (element.type == "row") {
                        $scope.pdfModel.elements.push(element);
                    }
                }
            };

            $(document).trigger("pdfModelLoaded", { pdfModel: $scope.pdfModel, selectionModel: $scope.selectionModel });

            // var templateElements = response.templateModel.elements;
            // var datasheetElements = response.datasheetModel.elements;
            // var pdfElements = [];

            // //Template elementlerini ekle,
            // for (var te in templateElements) {
            //     var templateElement = templateElements[te];

            //     var pdfElement = templateElement;
            //     var datasheetElement = void 0;

            //     if (templateElement.type == "page" || templateElement.type == "row") {
            //         var templateElementId = templateElement._id;
            //         datasheetElement = _.find(datasheetElements, {
            //             templateElementId: templateElementId
            //         });
            //     } else if (templateElement.type == "inputimage" || templateElement.type == "inputicon") {
            //         datasheetElement = _.find(datasheetElements, {
            //             name: templateElement.name
            //         });

            //         if (datasheetElement != null && datasheetElement != undefined)
            //             pdfElement.value = datasheetElement.value;
            //     } else if (templateElement.type == "inputtext" || templateElement.type == "text" || templateElement.type == "selectlist") {
            //         datasheetElement = _.find(datasheetElements, {
            //             name: templateElement.name
            //         });

            //         if (templateElement.type == "inputtext") {
            //             pdfElement.values = datasheetElement.values;
            //         } else if (templateElement.type == "selectlist") {
            //             if (angular.isDefined(datasheetElement.values)) {
            //                 pdfElement.values = datasheetElement.values;
            //             }
            //             else {
            //                 pdfElement.values = {};
            //                 var datasheetElementValue = datasheetElement.value;
            //                 for (var language in datasheetElementValue) {
            //                     var selectedItem = _.find(pdfElement.source[language], {
            //                         id: datasheetElementValue[language]
            //                     });

            //                     pdfElement.values[language] = selectedItem;
            //                 }
            //             }
            //         }

            //         else
            //             pdfElement.values = templateElement.values;
            //     } else if (templateElement.type == "table" || templateElement.type == "box" || templateElement.type == "icon" || templateElement.type == "image") {
            //         datasheetElement = _.find(datasheetElements, {
            //             name: templateElement.name
            //         });
            //     }

            //     if (datasheetElement == null || datasheetElement == undefined || datasheetElement.isHidden == null || datasheetElement.isHidden == undefined || !datasheetElement.isHidden) {
            //         pdfElements.push(pdfElement);
            //     }
            // }

            // //Datasheet'in Dinamik Elementleri ekle
            // var dynamicElements = _.filter(datasheetElements, {
            //     isDynamic: true
            // });
            // for (var de in dynamicElements) {
            //     var dynamicElement = dynamicElements[de];

            //     if (dynamicElement.isHidden == null || dynamicElement.isHidden == undefined || !dynamicElement.isHidden) {
            //         pdfElements.push(dynamicElement);
            //     }
            // }


            // $scope.pdfModel.elements = pdfElements;
            // $scope.loading.pdf = true;
        });
    };

    var getPublishedDatasheetById = function (publishedDatasheetId, nextProcess) {
        datasheetFactory.getPublishedDatasheetAnounmously(publishedDatasheetId)
            .then(function (response) {
                nextProcess(response);
            });
    };
    //Inner Functions End

    //Functions
    //Functions End

    //Initials 
    initializePdfModel();
    //Initials End
};

module.exports = function (app) {
    app.controller('viewDatasheetCtrl', ['$scope', '$stateParams', 'datasheetFactory', 'datasheetDataEditingService', viewDatasheetCtrl]);
};