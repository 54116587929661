var pdfImage = function (pdfElementService,pdfViewService) {
    return {
        restrict: 'E',
        replace: true,
        templateUrl: 'app/components/pdf/shared/directives/elements/pdfImage/pdfImage.html',
        link: function (scope, elem, attrs) {
            //Fields
            var containerElement = elem;
            var innerElement = elem.find('.inner-element');
            //Fields End

            //Inner Functions
            var setElementStyle = function () {
                containerElement.css(scope.element.containerStyle);

                var newStyle = pdfViewService.arrangeElementBorder(scope.element.style);
                innerElement.css(newStyle);
            };

            // var setElementValue = function () {
            //     innerElement.html('');
            //     if (angular.isUndefined(scope.element.style.backgroundImage)) {
            //         var lblImage = pdfElementService.getImageDefaultText(scope.selectionModel.selectedLanguage).image;
            //         var lblImageWillBeHere = pdfElementService.getImageDefaultText(scope.selectionModel.selectedLanguage).imageWillBeHere;
            //         var imageInfo = {
            //             image: lblImage,
            //             imageWillBeHere: lblImageWillBeHere
            //         };
            //         var empty = pdfViewService.createEmptyPdfImage();
            //         empty = empty.replace("{{image}}",imageInfo.image);
            //         empty = empty.replace("{{imageWillBeHere}}",imageInfo.imageWillBeHere);

            //         innerElement.append($(empty));
            //     }
            // };
            //Inner Functions End

            //Handlers
            //Handlers End

            //Initials 
            setElementStyle();
            // setElementValue();
            //Initials End
        }
    }
};

module.exports = function (app) {
    app.directive('pdfImage', ['pdfElementService','pdfViewService', pdfImage]);
};