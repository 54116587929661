var jsTreeDirective = function($filter, localStorageService, _) {
    return {
        restrict: 'A',
        link: function(scope, elem) {
            //Events
            scope.$on('changedFolderSelection', function(event, data) {
                elem.jstree('deselect_all');
                elem.jstree('select_node', data.folderId);
                elem.jstree("open_node", data.folderId);
            });
            //Events End

            //Watches
            scope.$watch('folders', function() {
                if (angular.isDefined(scope.folders) && scope.folders != null && scope.folders != "") {
                    createTreeFolders(scope.folders);
                }
            });
            //Watches End

            //Inner Functions
            var createTreeFolders = function(jsonData) {
                elem.jstree('destroy');
                elem.jstree({
                    "types": {
                        'default': {
                            'icon': 'zmdi zmdi-folder'
                        },
                        'file': {
                            'icon': 'zmdi zmdi-file'
                        }
                    },
                    "plugins": ["cookies", "state", "types", "sort"],
                    "cookies": {
                        cookie_options: {
                            path: '/'
                        }
                    },
                    "auto_save": true,
                    'core': {
                        'data': jsonData,
                        'themes': {
                            'responsive': false
                        }
                    }
                });

                elem.on("select_node.jstree", function(e, data) {
                    if (data.selected.length) {
                        var selectedNode = data.selected[0];
                        localStorageService.set('treeSelectedFolderId', selectedNode);

                        scope.$apply(function() {
                            scope.selectedFolder.id = selectedNode;
                        });
                    }
                });

                elem.on("open_node.jstree", function(e, data) {
                    if (angular.isDefined(scope.jsTreeOpenedNode)) {
                        scope.jsTreeOpenedNode();
                    }
                });

                elem.on("close_node.jstree", function(e, data) {
                    if (angular.isDefined(scope.jsTreeClosedNode)) {
                        scope.jsTreeClosedNode();
                    }
                });

                elem.on('ready.jstree', function() {
                    var selectedNode;
                    var openedNode;

                    if (angular.isDefined(scope.newFolder) && angular.isDefined(scope.newFolder._id)) {
                        selectedNode = scope.newFolder._id;
                        openedNode = scope.newFolder._id;

                        scope.newFolder._id = undefined;
                    } else {
                        var firstActiveFolder = _.find(scope.folders, function(folder) {
                            return folder.state.disabled == false;
                        });


                        var treeSelectedFolderId = localStorageService.get('treeSelectedFolderId');
                        if (angular.isDefined(treeSelectedFolderId) && treeSelectedFolderId != null && treeSelectedFolderId != "") {
                            var selectedFolder = $filter('filter')(scope.folders, {
                                "id": treeSelectedFolderId
                            });

                            if (angular.isUndefined(selectedFolder) || selectedFolder == null || selectedFolder == "" || selectedFolder.length == 0 || selectedFolder[0].state.disabled) {
                                selectedNode = firstActiveFolder._id;
                                openedNode = firstActiveFolder._id;
                            } else {
                                selectedNode = treeSelectedFolderId;
                                openedNode = treeSelectedFolderId;
                            }
                        } else {
                            selectedNode = firstActiveFolder._id;
                            openedNode = firstActiveFolder._id;
                        }
                    }

                    elem.jstree('deselect_all');
                    elem.jstree('select_node', selectedNode);
                    elem.jstree("open_node", openedNode);

                    scope.$apply(function() {
                        scope.selectedFolder.id = selectedNode;
                    });
                });
            };
            //Inner Functions End
        }
    };
};

module.exports = function(app) {
    app.directive('jsTree', ['$filter', 'localStorageService', '_', jsTreeDirective]);
};
