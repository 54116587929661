var userActivitiesCtrl = function($scope, $stateParams, $filter, $timeout, $translate,localStorageService, analyticFactory) {
    //Fields
    $scope.authUserId = localStorageService.get('authorizationData')._id;
    $scope.userId = $stateParams.userId;
    //Fields End

    //Inner Functions
    var getActivitiesByUserId = function(userId) {
        analyticFactory.getActivitiesByUserId(userId)
            .then(function(response) {
                $scope.activities = response;

                $timeout(function() {
                    $('#user-activities-scroll-content').slimScroll({
                        height: '290px'
                    });
                }, 250);

            });
    };
    //Inner Functions End

    //Functions
    $scope.getActivityDate = function(activityCreatedDate) {
        var today = new Date();
        var activityDate = new Date(activityCreatedDate);
        var diffMs = (today - activityDate);
        var diffMins = Math.round((diffMs / 1000) / 60);
        var diffHrs = Math.round(((diffMs / 1000) / 60) / 60);


        if (diffMins > 1440) {
            return $filter('date')(activityCreatedDate, 'dd/MM/yyyy HH:MM:ss');
        } else if (diffMins > 60) {
            var hourAgo = "";
            if (diffHrs > 1) {
                hourAgo = $translate.instant('USER.USERPROFILE.PARTIALS.USERACTIVITIES.LBL_HOUR_AGO.PLURAL_TEXT');
            } else {
                hourAgo = $translate.instant('USER.USERPROFILE.PARTIALS.USERACTIVITIES.LBL_HOUR_AGO.TEXT');
            }
            return diffHrs + " " + hourAgo;
        } else {
            var minuteAgo = "";
            if (diffHrs > 1) {
                minuteAgo = $translate.instant('USER.USERPROFILE.PARTIALS.USERACTIVITIES.LBL_MINUTE_AGO.PLURAL_TEXT');
            } else {
                minuteAgo = $translate.instant('USER.USERPROFILE.PARTIALS.USERACTIVITIES.LBL_MINUTE_AGO.TEXT');
            }
            return diffMins + " " + minuteAgo;
        }

    };
    //Functions End

    //Initials
    getActivitiesByUserId($scope.userId);
    //Initials End
};

module.exports = function(app) {
    app.controller('userActivitiesCtrl', ['$scope', '$stateParams', '$filter', '$timeout', '$translate','localStorageService', 'analyticFactory', userActivitiesCtrl]);
};
