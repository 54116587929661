var intercomApiProvider = function ($http, $q, config, environment, localStorageService) {
    //Fields 
    var intercomApiUrl = "https://apiintercom.tdsmaker.com/v1";
    //Fields End

    //Functions
    this.post = function (route, body) {
        var deferred = $q.defer();

        if (checkIntercomSettings()) {
            var url = intercomApiUrl + '/' + route;
            var config = initializeRequest();

            var res = $http.post(url, body, config);

            res.success(function (data, status, headers, config) {
                deferred.resolve(data);
            });

            res.error(function (msg, code) {
                deferred.reject(msg);
            });
        }
        else {
            deferred.resolve({
                status: false,
                message: "intercom is not active!!"
            });
        }

        return deferred.promise;
    };
    //Functions End

    // Inner Functions 
    var initializeRequest = function () {
        var config = {
            headers: {

            }
        };
        var authData = localStorageService.get('authData');
        config.headers["Authorization"] = "Bearer " + authData;

        return config;
    };

    var checkIntercomSettings = function () {
        if (INTERCOM_SETTINGS.IS_SHOWN) {
            return true
        }
        else {
            return false;
        }
    }

    // Inner Functions End
};

module.exports = function (app) {
    app.service('intercomApiProvider', ['$http', '$q', 'config', 'environment', 'localStorageService', intercomApiProvider]);
};
