var deleteArchiveFileCtrl = function($scope, $timeout, $translate, $modalInstance, archiveFileFactory, archiveFile) {
    //Fields
    $scope.deleteButton = "CUSTOMARCHIVES.YOURARCHIVES.MODALS.DELETEARCHIVEFILE.BTN_DELETE.TEXT";
    $scope.isProcessing = false;
    //Fields End

    //Functions
    $scope.cancel = function() {
        $modalInstance.dismiss();
    };

    $scope.delete = function() {
        $scope.deleteButton = "CUSTOMARCHIVES.YOURARCHIVES.MODALS.DELETEARCHIVEFILE.BTN_DELETE.ALTERNATE_TEXT";
        $scope.isProcessing = true;

        archiveFileFactory.deleteArchiveFile(archiveFile._id)
            .then(function(response) {
                $scope.deleteButton = "CUSTOMARCHIVES.YOURARCHIVES.MODALS.DELETEARCHIVEFILE.BTN_DELETE.TEXT";
                $scope.isProcessing = false;
                var message = $translate.instant("CUSTOMARCHIVES.YOURARCHIVES.MODALS.DELETEARCHIVEFILE.SUCCESS_MESSAGES.DELETED_ARCHIVE_FILE");
                toastr.success(message, '', { positionClass: 'toast-bottom-full-width', timeOut: 3000 });
                $timeout(function() {
                    $modalInstance.close(true);
                }, 500);
            });
    };
    //Functions End

    //Initials
    //Initials End
};

module.exports = function(app) {
    app.controller('deleteArchiveFileCtrl', ['$scope', '$timeout', '$translate', '$modalInstance', 'archiveFileFactory', 'archiveFile', deleteArchiveFileCtrl]);
};
