var datasheetInputImage = function ($translate, datasheetViewService, datasheetElementService, datasheetContextMenuService, datasheetContextMenuHandlerService, uploadFactory) {
    return {
        restrict: 'E',
        replace: true,
        templateUrl: 'app/components/datasheet/shared/directives/elements/datasheetInputImage/datasheetInputImage.html',
        link: function (scope, elem, attrs) {
            //Fields 
            var containerElement = elem;
            var innerElement = containerElement.find('.inner-element');
            //Fields End

            //Elements       
            var element = scope.element;
            //Elements End

            //Inner Functions
            var init = function () {
                containerElement = $('#' + scope.element.type + '-' + scope.element._id);
                innerElement = containerElement.find(".inner-element");
            };

            var addUsefulClass = function () {
                containerElement.addClass("container-element inputimage-" + element._id + "");
                // containerElement.css("cursor", "pointer");
            };

            var setElementStyle = function () {
                containerElement.css(scope.element.containerStyle);
                innerElement.css(scope.element.style);

                //Elementler gizli ise ilgili class ı ekleyelim.
                if (element != null && element != undefined) {
                    if (element.isHidden) {
                        containerElement.addClass('hidden-blur');
                    }
                }
            };

            var setElementValue = function () {
                innerElement.html('');
                if (element.value != null && element.value != undefined && element.value != "") {
                    var inputImage = {
                        width: element.style.width,
                        height: element.style.height,
                        src: element.value
                    };
                    var inputImage = Mustache.render(datasheetViewService.createDatasheetInputImage(), inputImage);
                    innerElement.append(inputImage);
                }
                else {
                    var lblImage = datasheetElementService.getInputImageDefaultText(scope.selectionModel.selectedLanguage).image;
                    var lblClickToUpload = datasheetElementService.getInputImageDefaultText(scope.selectionModel.selectedLanguage).clickToUpload;
                    var imageInfo = {
                        image: lblImage,
                        clickToUpload: lblClickToUpload
                    };
                    var emptyInputImage = Mustache.render(datasheetViewService.createEmptyDatasheetInputImage(), imageInfo);
                    innerElement.append($(emptyInputImage));
                }
            };

            var setContextMenu = function () {
                $.contextMenu({
                    selector: "." + scope.element.type + "-" + scope.element._id,
                    reposition: false,
                    build: function ($trigger, e) {
                        var page = scope.page;
                        var table = scope.table;
                        var row = scope.row;
                        var cell = scope.cell;
                        var element = scope.element;

                        return {
                            callback: contextMenuClickHandler,
                            items: datasheetContextMenuService.createContextMenuItemsForInputImage(page, table, row, cell, element)
                        };
                    }
                });
            };

            var setTooltip = function () {
                var tooltip = datasheetElementService.getInputImageDefaultTooltip(scope.selectionModel.selectedLanguage);
                containerElement.children('.inner-element').tooltip('dispose');
                containerElement.children('.inner-element').tooltip({ position: "bottom left", opacity: 0.7, title: tooltip });
                containerElement.unbind('mouseenter');
                containerElement.bind('mouseenter', function () {
                    containerElement.children('.inner-element').tooltip({ trigger: 'manual' }).tooltip('show');
                });
                containerElement.unbind('mouseleave');
                containerElement.bind('mouseleave', function () {
                    containerElement.children('.inner-element').tooltip({ trigger: 'manual' }).tooltip('hide');
                });
            };
            //Inner Functions End

            //Handlers
            var contextMenuClickHandler = function (key, options) {
                datasheetContextMenuHandlerService
                    .handleContextMenuClick(key,
                    scope.selectionModel.showHiddenElements,
                    scope.templateModel,
                    scope.datasheetModel,
                    scope.page,
                    scope.table,
                    scope.row,
                    scope.cell,
                    scope.element);
            };
            //Handlers End

            //Events
            scope.$on('$destroy', function () {
                $(document).off('datasheetLanguageChanged');
                containerElement.unbind('click');
                containerElement.remove();
            });

            containerElement.click(function (e) {
                uploadFactory.uploadImage(function (imageSrc) {
                    var jsImageOnLoad = function () {
                        var width = this.width;
                        var height = this.height;

                        var holderWidth = parseFloat(element.style.width.replace("px", ""));
                        var holderHeight = parseFloat(element.style.height.replace("px", ""));

                        var whRatio = width / height;

                        if (width > holderWidth) {
                            height = (height * holderWidth) / width;
                            width = holderWidth;
                        }

                        if (height > holderHeight) {
                            width = (width * holderHeight) / height;
                            height = holderHeight;
                        }

                        element.style = {
                            width: width + "px",
                            height: height + "px"
                        };
                        element.value = imageSrc;

                        init();
                        setElementValue();
                    };

                    var img = new Image();
                    img.onload = jsImageOnLoad;
                    img.src = imageSrc;
                });
            });

            //Events End

            //Initials 
            addUsefulClass();
            setElementStyle();
            setElementValue();
            setContextMenu();
            setTooltip();
            //Initials End
        }
    };
};

module.exports = function (app) {
    app.directive('datasheetInputImage', ['$translate', 'datasheetViewService', 'datasheetElementService', 'datasheetContextMenuService', 'datasheetContextMenuHandlerService', 'uploadFactory', datasheetInputImage]);
}