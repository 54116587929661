var newTemplateCtrl = function ($rootScope, $scope, $state, $stateParams, $timeout, $filter, $translate, $modal, _, templateFactory, generalsService, languageFactory, folderFactory, checkPermissionFactory, intercomFactory, menuToggleFactory, config) {
    // Fields
    $scope.currentStep = 0;
    $scope.showCustomError = false;
    $scope.finishButtonDisabled = false;
    $scope.willTransferTemplate = false;
    $scope.selectedFolder = {};
    $scope.newFolder = {};
    $scope.translatedArchive = $translate.instant("TEMPLATE.YOURTEMPLATES.LBL_ARCHIVE.TEXT");
    $scope.folderType = "Template";
    $scope.industries = [];
    $scope.libraryTemplates = [];
    $scope.filteredLibraryTemplates = [];
    $scope.libraryTemplateCategory = "popular";
    $scope.selectedLibraryTemplate = {};
    $scope.libraryTemplateSearchResultNotFound = null;
    $scope.libraryTemplatesShown = 15;
    $scope.loaded = {
        industries: false,
        libraryTemplates: false
    }
    $scope.userLibraryTemplateRequestsForIndustry = [];
    $scope.userHasLibraryTemplateRequest = false;

    var industrySortOrder = [];
    //Fields End

    //Events
    $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
        $.each($('[select2]'), function( index, value ) {
            $(value).select2('destroy');
          });
    });
    $scope.$on('createdNewFolder', function (event, data) {
        getTemplateFolders(function () {
            var newFolder = $filter("filter")($scope.templateFolders, {
                parent: data.parentFolderId,
                text: data.newFolderName
            }, true)[0];

            openNewFolder(newFolder);
            $scope.folders = $scope.templateFolders;
        });
    });
    $scope.$on('$viewContentLoaded', function() {
        $timeout(function() {
            /* $('body').css('overflowY', 'scroll'); */
            menuToggleFactory.collapse();
        }, 100);
    });
    //Events End

    //Functions
    $scope.searchLibraryTemplates = function() {
        
        if ($scope.listSearchKey === "") {
            $scope.libraryTemplateCategoryChange();
            return;
        }

        if ($scope.libraryTemplateCategory === "all") {
            var searchArray =   _.groupBy($scope.libraryTemplates,
                                    function(tmp){ return tmp.industryId; }
                                );

            var searchResultArray = [];
            
            for (var key in searchArray) {
                if (searchArray.hasOwnProperty(key)) {                    
                    var result = searchArray[key].filter(function (libraryTemplate) {
                        var matched = false;
                        $scope.listSearchKey.split(' ').forEach(function (word) {
                            word = $scope.listSearchKey.toString().toLowerCase();
                            var libraryTemplateTagsValues = "";
            
                            if (libraryTemplate.tags !== null && typeof libraryTemplate.tags === 'object') {
                                libraryTemplateTagsValues += libraryTemplate.tags[$rootScope.activeLang].toString().toLowerCase();
                            } else if (libraryTemplate.tags !== null && typeof libraryTemplate.tags === 'string') {
                                libraryTemplateTagsValues += libraryTemplate.tags.toString().toLowerCase();
                            }
                            
                            if (libraryTemplateTagsValues.indexOf(word) != -1) {
                                if (matched === false) {
                                    $scope.libraryTemplateSearchResultNotFound = false;
                                    matched = true;
                                }
                            } else {
                                $scope.libraryTemplateSearchResultNotFound = true;
                            }
                        });
                        return matched;
                    });
                    if (result.length > 0) {
                        searchResultArray = searchResultArray.concat(result);
                    }
                }
            }

            $scope.filteredLibraryTemplates =   _.groupBy(searchResultArray,
                                                    function(tmp){ return tmp.industryId; }
                                                );

            $scope.$apply();
        } else if ($scope.libraryTemplateCategory === "popular") {

            var searchArray =   _.groupBy(
                                    _.filter(
                                        $scope.libraryTemplates,
                                        {isPopular: true}
                                    ),
                                    function(tmp){ return tmp.industryId; }
                                );
            var searchResultArray = [];
            
            for (var key in searchArray) {
                if (searchArray.hasOwnProperty(key)) {                    
                    var result = searchArray[key].filter(function (libraryTemplate) {
                        var matched = false;
                        $scope.listSearchKey.split(' ').forEach(function (word) {
                            word = $scope.listSearchKey.toString().toLowerCase();
                            var libraryTemplateTagsValues = "";
            
                            if (libraryTemplate.tags !== null && typeof libraryTemplate.tags === 'object') {
                                libraryTemplateTagsValues += libraryTemplate.tags[$rootScope.activeLang].toString().toLowerCase();
                            } else if (libraryTemplate.tags !== null && typeof libraryTemplate.tags === 'string') {
                                libraryTemplateTagsValues += libraryTemplate.tags.toString().toLowerCase();
                            }
                            
                            if (libraryTemplateTagsValues.indexOf(word) != -1) {
                                if (matched === false) {
                                    $scope.libraryTemplateSearchResultNotFound = false;
                                    matched = true;
                                }
                            } else {
                                $scope.libraryTemplateSearchResultNotFound = true;
                            }
                        });
                        return matched;
                    });
                    if (result.length > 0) {
                        searchResultArray = searchResultArray.concat(result);
                    }
                }
            }

            $scope.filteredLibraryTemplates =   _.groupBy(
                                                    _.filter(
                                                        searchResultArray,
                                                        {isPopular: true}
                                                    ),
                                                    function(tmp){ return tmp.industryId; }
                                                );
            $scope.$apply();
        } else {
            
            var searchArray = _.groupBy(
                                    _.sortBy(
                                        _.filter(
                                            $scope.libraryTemplates,
                                            {industryId: $scope.libraryTemplateCategory}
                                        ),
                                        'isPopular'
                                    ),
                                    function(tmp){ return tmp.industryId; }
                                );

            var searchResultArray = [];

            for (var key in searchArray) {
                if (searchArray.hasOwnProperty(key)) {                    
                    var result = searchArray[key].filter(function (libraryTemplate) {
                        var matched = false;
                        $scope.listSearchKey.split(' ').forEach(function (word) {
                            word = $scope.listSearchKey.toString().toLowerCase();
                            var libraryTemplateTagsValues = "";
            
                            if (libraryTemplate.tags !== null && typeof libraryTemplate.tags === 'object') {
                                libraryTemplateTagsValues += libraryTemplate.tags[$rootScope.activeLang].toString().toLowerCase();
                            } else if (libraryTemplate.tags !== null && typeof libraryTemplate.tags === 'string') {
                                libraryTemplateTagsValues += libraryTemplate.tags.toString().toLowerCase();
                            }
                            
                            if (libraryTemplateTagsValues.indexOf(word) != -1) {
                                if (matched === false) {
                                    $scope.libraryTemplateSearchResultNotFound = false;
                                    matched = true;
                                }
                            } else {
                                $scope.libraryTemplateSearchResultNotFound = true;
                            }
                        });
                        return matched;
                    });
                    if (result.length > 0) {
                        searchResultArray = searchResultArray.concat(result);
                    }
                }
            }
            $scope.filteredLibraryTemplates =   _.groupBy(
                                                    _.sortBy(
                                                        _.filter(
                                                            searchResultArray,
                                                            {industryId: $scope.libraryTemplateCategory}
                                                        ),
                                                        'isPopular'
                                                    ),
                                                    function(tmp){ return tmp.industryId; }
                                                );
            $scope.$apply();
        }

        
        if (_.isEmpty($scope.filteredLibraryTemplates)) {
            $scope.filteredLibraryTemplates = [];
        }
  
    };
    $scope.getIndustryLanguageCode = function(cat) {
        if (!cat) {
            if ($scope.libraryTemplateCategory == "all") {
                return "TEMPLATE.NEWTEMPLATE.LIBRARY_TEMPLATES_ALL"
            } else if ($scope.libraryTemplateCategory == "popular") {
                return "TEMPLATE.NEWTEMPLATE.LIBRARY_TEMPLATES_POPULAR"
            } else {
                return _.find($scope.industries, {_id: $scope.libraryTemplateCategory}).languageCode;
            }
        } else {
            return _.find($scope.industries, {_id: cat}).languageCode;
        }
    };
    $scope.libraryTemplateCategoryChange = function() {
        $scope.listSearchKey = "";
        $scope.libraryTemplatesShown = 15;
        $scope.libraryTemplateSearchResultNotFound = false;
        filterLibraryTemplates();
    };
    $scope.makeLibraryTemplateRequestForIndustry = function() {
        templateFactory.makeLibraryTemplateRequestForIndustry($scope.libraryTemplateCategory).then(function (response) {
            $scope.userLibraryTemplateRequestsForIndustry.push(response.model);
            $scope.libraryTemplateCategoryChange();
        });
    };
    $scope.getUserLibraryTemplateRequestsForIndustries = function() {
        templateFactory.getUserLibraryTemplateRequestsForIndustries().then(function (response) {
            $scope.userLibraryTemplateRequestsForIndustry = response;
        });
    };
    $scope.changeStep = function (index) {
        $scope.currentStep = index;
    };
    $scope.getLanguageCode = function (language) {
        if (angular.isDefined($scope.languagesArray)) {
            var selectedLanguage = _.filter($scope.languagesArray, {
                "isoCode": language
            })[0];
            return selectedLanguage.languageCode;
        }
    };
    $scope.languageChange = function (model) {
        
        if (model.selected) {
            $scope.templateModel.languages.push(model.isoCode);
            if ($scope.templateModel.primaryLanguage === "")
                $scope.templateModel.primaryLanguage = model.isoCode;
        } else {
            var index = $scope.templateModel.languages.indexOf(model.isoCode);
            $scope.templateModel.languages.splice(index, 1);

            if ($scope.templateModel.primaryLanguage === model.isoCode && $scope.templateModel.languages.length > 0)
                $scope.templateModel.primaryLanguage = $scope.templateModel.languages[0];

            if ($scope.templateModel.languages.length === 0)
                $scope.templateModel.primaryLanguage = "";
        }
        $('#new-template-language-select').select2();
    };

    $scope.loadMoreTemplates = function() {
        var topOffset = $(document).scrollTop() + 375;
        $scope.libraryTemplatesShown += 15;
        filterLibraryTemplates(function() {
            setTimeout(function() {
                $("html, body").animate({ scrollTop: topOffset + "px" }, 'slow');
                //$(document).scrollTop(topOffset);
            }, 0);
        });
    };
    $scope.goStep_1 = function () {
        if ($scope.templateModel.languages.length < 1) {
            $scope.showCustomError = true;
            $scope.frmStep1.$valid = false;
        }

        if ($scope.frmStep1.$valid) {
            $scope.showCustomError = false;
            $scope.changeStep(2);
        }
    };
    $scope.goStep_2 = function () {
        if ($scope.frmStep2.$valid) {
            $scope.showCustomError = false;
            $scope.changeStep(3);
        }
    };
    $scope.goStep_3 = function () {
        $scope.templateModel.folderId = $scope.selectedFolder.id;
        $scope.finishButtonDisabled = true;
        saveTemplate();
    };

    $scope.transferTemplate = function () {
        $scope.templateModel.folderId = $stateParams.moveSettings.targetTemplateFolderId;
        $scope.templateModel.accountId = $stateParams.moveSettings.targetAccountId;
        $scope.templateModel.userId = $stateParams.moveSettings.targetUserId;
        transferTemplate();
    };

    $scope.openSelectTemplateModal = function () {
        var modalInstance = $modal.open({
            animation: false,
            templateUrl: 'app/components/template/newTemplate/views/modals/selectTemplate.html',
            controller: 'selectTemplateCtrl',
            size: 'full-width',
            backdrop: 'static',
        });

        modalInstance.result
            .then(function (result) {
                if (result.status) {
                    getTemplateById(result.selectedTemplate._id, function (template) {
                        setTemplateModelFromCopiedTemplate(template);
                        $scope.changeStep(1);
                    });
                }
            });
    };

    $scope.selectNewTemplate = function (template) {
        getTemplateById(template.templateId, function (newCopyTemplate) {
            $scope.selectedLibraryTemplate = template;
            setTemplateModelFromCopiedTemplate(newCopyTemplate);
            $scope.changeStep(1);
            $('#new-template-language-select').select2();
        });
    }

    $scope.cancel = function () {
        $state.go('app.newTemplate', {
            'lang': $rootScope.activeLang
        }, {
                reload: true
            })
    };
    //Watches
    $scope.$watch('selectedFolder.id', function () {
        if (angular.isDefined($scope.selectedFolder.id) && $scope.selectedFolder.id != "") {
            $scope.hasFolderSelected = true;
            $scope.selectedFolderPath = folderFactory.getFolderPath($scope.templateFolders, $scope.selectedFolder.id);
            var selectedFolder = $filter('filter')($scope.templateFolders, {
                "_id": $scope.selectedFolder.id
            })[0];

            $scope.selectedFolder.text = selectedFolder.text;
            $scope.selectedFolder.Name = selectedFolder.Name;
            $scope.selectedFolder.parent = selectedFolder.parent;
            $scope.selectedFolder.Parent = selectedFolder.Parent;
        } else {
            $scope.hasFolderSelected = false;
        }
    });

    $scope.createTemplateTooltip = function (template) {
        function capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }
        return template['name' + capitalizeFirstLetter($rootScope.activeLang)]
    };
    $scope.enlargeLibraryTemplateImage = function ($event, libraryTemplate) {
        $event.stopPropagation()
        var image = $filter('image')(libraryTemplate.image);
        $.fancybox.open('<div class="message"><img src="' + image + '" /></div>',
            {
                closeClickOutside: true,
                beforeClose: function() {
                    $('html').removeClass('fancybox-enabled');
                }
            }
        );

    }
    //Private Functions
    var init = function () {
        //app.copyTemplate üzerinden gelindiyse ilk adımı hiç gösterme
        if ($stateParams.templateId != null && $stateParams.templateId != undefined && $stateParams.templateId.length > 0) {
            $scope.changeStep(-1);
        } else if ($stateParams.moveSettings != null) {
            $scope.willTransferTemplate = true;
            $scope.changeStep(-1);
        }

        $scope.templateModel = templateFactory.createEmptyTemplate();
        getIndustries(function () {
            $scope.loaded.industries = true;
            getLanguages(function () {
                if ($stateParams.templateId != null && $stateParams.templateId != undefined && $stateParams.templateId.length > 0) {
                    var templateId = $stateParams.templateId;
                    getTemplateById(templateId, function (template) {
                        setTemplateModelFromCopiedTemplate(template);
                        $scope.changeStep(1);
                    });
                } else if ($stateParams.moveSettings != null) {
                    var templateId = $stateParams.moveSettings.sourceTemplateId;
                    getTemplateById(templateId, function (template) {
                        setTemplateModelFromCopiedTemplate(template);
                        $scope.changeStep(1);
                    });
                }
            });
            getAllTemplatesFromLibrary(function() {
                $scope.getUserLibraryTemplateRequestsForIndustries();
                $scope.libraryTemplateCategoryChange();
                $scope.loaded.libraryTemplates = true;
            });

        });

        getTemplateFolders(function () {
            $scope.folders = $scope.templateFolders;
        });
    };

    var filterLibraryTemplates = function(nextProcess) {
        var lastCategory = _.find($scope.industries, 'isLast');
        var libraryTemplates = [];
        industrySortOrder.forEach(function(key) {
            $scope.libraryTemplates.filter(function(item) {
                if(item.industryId == key) {
                    libraryTemplates.push(item);
                    return false;
                } else 
                return true;
            });
        });
        
        if ($scope.libraryTemplateCategory == "all") {
            $scope.filteredLibraryTemplates =   _.groupBy(
                                                    libraryTemplates.slice(0, $scope.libraryTemplatesShown),
                                                    function(tmp){ return tmp.industryId; }
                                                );
            var tmpArray = [];
            var lastIndex = Object.keys($scope.filteredLibraryTemplates).length - 1;
            var currentIndex = 0;
            for (var key in $scope.filteredLibraryTemplates) {
                if (key === lastCategory._id) {
                    tmpArray[lastIndex] = $scope.filteredLibraryTemplates[key];
                } else {
                    tmpArray[currentIndex] = $scope.filteredLibraryTemplates[key];
                    currentIndex++;
                }
            }
            $scope.filteredLibraryTemplates = tmpArray;
            
            $scope.filteredLibraryTemplatesCount = $scope.libraryTemplatesCount;

        } else if ($scope.libraryTemplateCategory == "popular") {
            $scope.filteredLibraryTemplates =   _.groupBy(
                                                    _.filter(
                                                        libraryTemplates.slice(0, $scope.libraryTemplatesShown),
                                                        {isPopular: true}
                                                    ),
                                                    function(tmp){ return tmp.industryId; }
                                                );

            var tmpArray = [];
            var lastIndex = Object.keys($scope.filteredLibraryTemplates).length - 1;
            var currentIndex = 0;
            for (var key in $scope.filteredLibraryTemplates) {
                if (key === lastCategory._id) {
                    tmpArray[lastIndex] = $scope.filteredLibraryTemplates[key];
                } else {
                    tmpArray[currentIndex] = $scope.filteredLibraryTemplates[key];
                    currentIndex++;
                }
            }
            $scope.filteredLibraryTemplates = tmpArray;
            
            $scope.filteredLibraryTemplatesCount = _.filter(
                libraryTemplates,
                {isPopular: true}
            ).length;

        } else {
            $scope.filteredLibraryTemplates =   _.groupBy(
                                                    _.sortBy(
                                                        _.filter(
                                                            libraryTemplates.slice(0, $scope.libraryTemplatesShown),
                                                            {industryId: $scope.libraryTemplateCategory}
                                                        ),
                                                        'isPopular'
                                                    ),
                                                    function(tmp){ return tmp.industryId; }
                                                );

            var tmpArray = [];
            var lastIndex = Object.keys($scope.filteredLibraryTemplates).length - 1;
            var currentIndex = 0;
            for (var key in $scope.filteredLibraryTemplates) {
                if (key === lastCategory._id) {
                    tmpArray[lastIndex] = $scope.filteredLibraryTemplates[key];
                } else {
                    tmpArray[currentIndex] = $scope.filteredLibraryTemplates[key];
                    currentIndex++;
                }
            }
            $scope.filteredLibraryTemplates = tmpArray;
                                                            
            $scope.filteredLibraryTemplatesCount = _.filter(
                $scope.libraryTemplates,
                {industryId: $scope.libraryTemplateCategory}
            ).length;

            $scope.userHasLibraryTemplateRequest = _.find($scope.userLibraryTemplateRequestsForIndustry, function(req) {
                return req.industryId == $scope.libraryTemplateCategory;
            });
        }

        if (nextProcess) {
            nextProcess();
        }
    }

    var getAllTemplatesFromLibrary = function (nextProcess) {
        templateFactory.getAllTemplatesFromLibrary(false).then(function (response) {
            $scope.libraryTemplates = response.templates;
            $scope.libraryTemplatesCount = response.count;
            nextProcess();
        });
        templateFactory.getAllTemplatesFromLibrary(true).then(function (response) {
            $scope.blankTemplates = response.templates;
            nextProcess();
        });
    };

    var getIndustries = function (nextProcess) {
        templateFactory.getIndustries().then(function (response) {
            $scope.industries = response;
            $scope.selectedIndustry = $scope.industries[0]._id;

            _.sortBy($scope.industries, 'order').map(function(industry) {
                industrySortOrder.push(industry._id);
            });

            nextProcess();
        });
    };

    var getLanguages = function (nextProcess) {
        languageFactory.setLanguageModel(["en"])
            .then(function (response) {
                $scope.languagesArray = response;

                nextProcess();
            });
    };

    var getTemplateFolders = function (callback) {
        $scope.loadedFolders = false;
        folderFactory.getTemplateFolders().then(function (response) {
            var templateFolders = response;
            templateFolders = checkPermissionFactory.canSaveTemplateToFolders(templateFolders);

            $scope.templateFolders = templateFolders;
            for (var i = 0; i < $scope.templateFolders.length; i++) {
                if ($scope.templateFolders[i].Parent == "#") {
                    $scope.templateFolders[i].Name = $scope.translatedArchive;
                    $scope.rootFolder = $scope.templateFolders[i];
                    break;
                }
            }

            for (var i = 0; i < $scope.templateFolders.length; i++) {
                var templateFolder = $scope.templateFolders[i];
                templateFolder.id = templateFolder._id;
                templateFolder.text = templateFolder.Name;
                templateFolder.parent = templateFolder.Parent;
            }

            $scope.loadedFolders = true;
            callback();
        });
    };

    var saveTemplate = function () {
        $scope.finishButtonDisabled = true;
        $scope.templateModel._id = generalsService.createObjectId();
        templateFactory.createNew($scope.templateModel).then(function (response) {

            $scope.finishButtonDisabled = false;
            if (response.status) {
                var languages = "";
                for (var l = 0; l < $scope.templateModel.languages.length; l++) {
                    var language = $scope.templateModel.languages[l];

                    languages += language + ","
                };

                intercomFactory.addEvent({
                    event_name: "Template Created",
                    metadata: {
                        "templateName": $scope.templateModel.name,
                        "languages": languages
                    }
                });


                $state.go('editor.editTemplate', {
                    lang: $rootScope.activeLang,
                    id: response._id
                });
            }
        });
    };

    var transferTemplate = function () {
        $scope.templateModel._id = generalsService.createObjectId();
        templateFactory.copyAndTransferTemplate($scope.templateModel).then(function (response) {
            if (response.status) {
                var message = $translate.instant("ADMIN.PARTIALS.TEMPLATE_TRANSFER.LBL_TRANSFER_COMPLETED");
                toastr.success(message, '', { positionClass: 'toast-bottom-full-width', timeOut: 5000 });
                $state.go('admin.templateTransfer', {
                    lang: $rootScope.activeLang,
                });
            }
        });
    };

    var openNewFolder = function (folder) {
        $scope.newFolder._id = folder._id;
    };

    var getTemplateById = function (templateId, nextProcess) {
        templateFactory.getTemplateWithoutElements(templateId)
            .then(function (response) {
                nextProcess(response);
            });
    };

    var getLibraryTemplateById = function (templateId, nextProcess) {
        templateFactory.getLibraryTemplateWithoutElements(templateId)
            .then(function (response) {
                nextProcess(response);
            });
    };

    var setTemplateModelFromCopiedTemplate = function (copiedTemplate) {
        //Kopyalanan Template i TemplateModel e at.
        for (var l = 0; l < copiedTemplate.languages.length; l++) {
            var language = copiedTemplate.languages[l];
            var selectedLanguage = $filter('filter')($scope.languagesArray, {
                "isoCode": language
            })[0];

            if (selectedLanguage != null && angular.isDefined(selectedLanguage)) {
                selectedLanguage.selected = true;
            }
        }

        if (!_.isEmpty($scope.selectedLibraryTemplate))
            $scope.templateModel.createdFromLibraryTemplate = true;

        !_.isEmpty($scope.selectedLibraryTemplate) ? $scope.templateModel.name = "" : $scope.templateModel.name = copiedTemplate.name + " Copy";

        $scope.templateModel.description = copiedTemplate.description;
        $scope.templateModel.copyId = copiedTemplate._id;
        $scope.templateModel.majorVersion = copiedTemplate.majorVersion;
        $scope.templateModel.minorVersion = copiedTemplate.minorVersion;
        $scope.templateModel.hasProductNumber = copiedTemplate.hasProductNumber;
        $scope.templateModel.productNumberRequired = copiedTemplate.productNumberRequired;
        $scope.templateModel.hasStartupVersionNo = copiedTemplate.hasStartupVersionNo;
        $scope.templateModel.primaryLanguage = copiedTemplate.primaryLanguage;
        $scope.templateModel.languages = copiedTemplate.languages;

        console.log($scope.templateModel)
    };

    var adjustLibraryTemplateImageSlideEffect = function() {

        var containerHeight = $('.new-template-library-template-image-container').first().outerHeight();
        var imageHeight = $('.new-template-library-template-image').first().outerHeight();
        var translateY =  containerHeight - imageHeight > 0 ? imageHeight - containerHeight - 8 : containerHeight - imageHeight - 8;
        var style = '<style id="new-template-library-template-image-hover-style"></style>';

        var css =   '.new-template-library-templates .new-template-library-template:hover .new-template-library-template-image-container .new-template-library-template-image' +
                    '{' + 
                        'top: initial;' +
                        'bottom: 0;' +
                        'transform:translateY(' + translateY + 'px' + ');' +
                    '}';

        if ($('#new-template-library-template-image-hover-style').length > 0) {
            $('#new-template-library-template-image-hover-style').text(css);
        } else {
            $($(style).text(css)).appendTo('head');
        }
    }
    //Inner Functions End

    //Initials
    init();
    $(document).on('click', function (event) {
        if (!$(event.target).closest('.message').length) {
            $.fancybox.close('all');
        }
    });
    $(document).ready(function() {
        setTimeout(function() {
            adjustLibraryTemplateImageSlideEffect();
        }, 2000);
    });
    $(window).resize(function () {
        adjustLibraryTemplateImageSlideEffect();
    });
    //Initials End
};

module.exports = function (app) {
    app.controller('newTemplateCtrl', ['$rootScope', '$scope', '$state', '$stateParams', '$timeout', '$filter', '$translate', '$modal', '_', 'templateFactory', 'generalsService', 'languageFactory', 'folderFactory', 'checkPermissionFactory', 'intercomFactory', 'menuToggleFactory', 'config', newTemplateCtrl]);
}
