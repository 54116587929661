var uiRouter = function($stateProvider, $urlRouterProvider, $locationProvider) {
    $stateProvider
        .state('app.allGroups', {
            url: '/all-groups',
            templateUrl: 'app/components/groups/allGroups/views/allGroups.html',
            controller: "allGroupsCtrl",
            permission: {
                requiredPermissions: ['View Groups']
            }
        })
        .state('app.groupUsers', {
            url: '/group-users/:groupId',
            templateUrl: 'app/components/groups/groupUsers/views/groupUsers.html',
            controller: 'groupUsersCtrl',
            onEnter: ['menuToggleFactory', function(menuToggleFactory) {
                menuToggleFactory.collapse();
            }],
            onExit: ['menuToggleFactory', function(menuToggleFactory) {
                menuToggleFactory.doPreviousStatus();
            }]
        })
        .state('app.newGroup', {
            url: '/new-group',
            templateUrl: 'app/components/groups/newGroup/views/newGroup.html',
            controller: 'newGroupCtrl',
            onEnter: ['menuToggleFactory', function(menuToggleFactory) {
                menuToggleFactory.collapse();
            }],
            onExit: ['menuToggleFactory', function(menuToggleFactory) {
                menuToggleFactory.doPreviousStatus();
            }]
        })
        .state('app.editGroup', {
            url: '/edit-group/:groupId',
            templateUrl: 'app/components/groups/editGroup/views/editGroup.html',
            controller: 'editGroupCtrl',
            onEnter: ['menuToggleFactory', function(menuToggleFactory) {
                menuToggleFactory.collapse();
            }],
            onExit: ['menuToggleFactory', function(menuToggleFactory) {
                menuToggleFactory.doPreviousStatus();
            }]
        });
};

module.exports = function(app) {
    app.config(['$stateProvider', '$urlRouterProvider', '$locationProvider', uiRouter]);
};
