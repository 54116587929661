var makeEnableCtrl = function($scope, $timeout,$translate, $modalInstance, userFactory, userId) {
    //Fields
    $scope.enableButton = "USER.ALLUSERS.MODALS.MAKEENABLE.BTN_ENABLE.TEXT";
    $scope.isProcessing = false;
    //Fields End

    //Functions
    $scope.cancel = function() {
        $modalInstance.dismiss('cancel');
    };

    $scope.enable = function() {
        $scope.enableButton = "USER.ALLUSERS.MODALS.MAKEENABLE.BTN_ENABLE.ALTERNATE_TEXT";
        $scope.isProcessing = true;

        userFactory.makeEnable(userId)
            .then(function(response) {
                if (response.status) {
                    $scope.enableButton = "USER.ALLUSERS.MODALS.MAKEENABLE.BTN_ENABLE.SUCCESS";

                    $timeout(function() {
                        var message = $translate.instant("USER.ALLUSERS.MODALS.MAKEENABLE.SUCCESS_MESSAGES.USER_MADE_ENABLE");
                        toastr.success(message, '', { positionClass: 'toast-bottom-full-width', timeOut: 3000 });
                        $modalInstance.close({
                            status: true
                        });
                    }, 500);
                }
            });
    };
    //Functions End

    //Initials
    //Initials End
};

module.exports = function(app) {
    app.controller('makeEnableCtrl', ['$scope', '$timeout','$translate', '$modalInstance', 'userFactory', 'userId', makeEnableCtrl]);
};
