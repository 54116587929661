var userSummaryInformationsCtrl = function($scope, $stateParams, statisticFactory) {
    //Fields
    var userId = $stateParams.userId;
    $scope.summaryInformation = {};
    //Fields End

    //Inner Functions
    var getSummaryInformations = function(userId) {
        statisticFactory.getUserSummaryInformationsByUserId(userId)
            .then(function(response) {
                $scope.summaryInformation.datasheetCount = response.datasheetCount;
                $scope.summaryInformation.templateCount = response.templateCount;
            });
    };
    //Inner Functions End

    //Initials
    getSummaryInformations(userId);
    //Initials End
};

module.exports = function(app) {
    app.controller('userSummaryInformationsCtrl', ['$scope', '$stateParams', 'statisticFactory', userSummaryInformationsCtrl]);
};
