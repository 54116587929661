var pdfFactory = function(httpProvider){

  var factoryModel = {};

  factoryModel.getPDF = function(id, language, source) {
    return httpProvider.get('form', 'getPDF', "id=" + id + "&language=" + language + "&source=" + source);
  };

  factoryModel.getPDFAnonymouse = function(id, language, source) {
    return httpProvider.get('form', 'getPDFAnonymouse', "id=" + id + "&language=" + language + "&source=" + source);
  };

  return factoryModel;

};

module.exports = function(app){
  app.factory('pdfFactory', ['httpProvider', pdfFactory]);
};
