require("./../../../../bower_components/angular/angular.js");

//Module
var settingsModule = angular.module('tdsApp.settings', []);

//Router
require('./routing.js')(settingsModule);

//Factories

//Services

//Controllers
require("./shared/controllers/_partials/settingsMenuInSideMenuController.js")(settingsModule);
require("./shared/controllers/_partials/settingsNavTabsController.js")(settingsModule);

module.exports = settingsModule;
