var imageFactory = function(
  $rootScope,
  $q,
  config,
  filepickerService,
  localStorageService
) {
  var factoryModel = {}

  factoryModel.uploadWithCallback = function(customOptions, callback) {
    var userData = localStorageService.get('userInformationData')

    var unixTime = Date.now()
    var path = '/' + userData.AccountId._id + '/' + Date.now() + '.png'

    var options = {
      mimetype: 'image/*',
      language: $rootScope.activeLang,
      services: [
        'COMPUTER',
        'GOOGLE_DRIVE',
        'SKYDRIVE',
        'DROPBOX',
        'EVERNOTE',
        'BOX',
        'GMAIL',
      ],
      imageMax: [900, 1274],
    }

    if (angular.isDefined(customOptions))
      options = angular.merge(options, customOptions)

    // filepickerService.setKey('Am0vSpcsQti9yqRBdpQtuz');
    filepickerService.setKey(config.filepicker.live.apiKey)
    filepickerService.pickAndStore(
      options,
      { path: path },
      function(blobs) {
        if (angular.isUndefined(blobs) || blobs.length == 0) callback('', true)

        callback(blobs[0].key, true)
      },
      function() {
        callback('', false)
      }
    )
  }

  factoryModel.upload = function(customOptions) {
    var defer = $q.defer()
    var userData = localStorageService.get('userInformationData')

    var unixTime = Date.now()
    var path = '/' + userData.AccountId._id + '/' + Date.now() + '.png'

    var options = {
      mimetype: 'image/*',
      language: $rootScope.activeLang,
      services: [
        'COMPUTER',
        'GOOGLE_DRIVE',
        'SKYDRIVE',
        'DROPBOX',
        'EVERNOTE',
        'BOX',
        'GMAIL',
      ],
    }

    if (angular.isDefined(customOptions))
      options = angular.merge(options, customOptions)

    // filepickerService.setKey('Am0vSpcsQti9yqRBdpQtuz')
    filepickerService.setKey(config.filepicker.live.apiKey)
    filepickerService.pickAndStore(
      options,
      { path: path },
      function(blobs) {
        if (angular.isUndefined(blobs) || blobs.length == 0) defer.resolve('')

        defer.resolve(blobs[0].url)
      },
      function() {
        defer.reject()
      }
    )

    return defer.promise
  }

  factoryModel.image = function(url) {
    if (angular.isDefined(url) && url == 'assets/images/default-user.png')
      return url

    if (angular.isDefined(url) && url.length >= 4) {
      var subs = url.substring(0, 4)

      if (subs == 'http') {
        return url
      } else {
        return config.app.imageStorageUrl + url
      }
    } else {
      return url
    }
  }

  return factoryModel
}

module.exports = function(app) {
  app.factory('imageFactory', [
    '$rootScope',
    '$q',
    'config',
    'filepickerService',
    'localStorageService',
    imageFactory,
  ])
}
