var datasheetTopSingleLayout = function () {
    return {
        restrict: 'E',
        replace: true,
        templateUrl: 'app/components/datasheet/shared/directives/datasheetLayouts/tpl/datasheetTopSingleLayout.html',
        link: function (scope, elem, attrs) {

        }
    }
};

module.exports = function (app) {
    app.directive('datasheetTopSingleLayout', [datasheetTopSingleLayout]);
};