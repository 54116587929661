var run = function ($rootScope, $state, localStorageService, loadingFactory, generalsService) {




};

module.exports = function (app) {
    app.run(['$rootScope', '$state', 'localStorageService', 'loadingFactory', 'generalsService', run]);
};
