var userFolderPathFilter = function(config){
  return function(url) {
    if (url) {
      return config.api[config.mode].apiUrl + "/UserFolders/" + url ;
    } else {
      return "URL is empty"
    }
  }
};

module.exports = function(app){
  app.filter('userFolderPath', ['config', userFolderPathFilter]);
};
