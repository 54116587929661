var datasheetViewService = function () {
    //Fields 
    var serviceModel = {}
    //Fields End

    //Functions
    serviceModel.createDatasheetCommentItem = function () {
        return '<div class="inbox-item row m-0"><div class="col-xs-2 p-0"><img src="$profilePicture$" class="img-circle"></div><div class="col-xs-10 p-r-0"><p class="inbox-item-author" style="font-size:11px">$firstName$ $lastName$</p><p class="inbox-item-text">$comment$</p><p class="inbox-item-date">$dateCommented$</p></div></div>'
    };

    serviceModel.createDatasheetPage = function () {
        return '<datasheet-page></datasheet-page>';
    };

    serviceModel.createDatasheetHeader = function () {
        return '<datasheet-header></datasheet-header>';
    };

    serviceModel.createDatasheetBody = function () {
        return '<datasheet-body></datasheet-body>';
    };

    serviceModel.createDatasheetFooter = function () {
        return ' <datasheet-footer></datasheet-footer>';
    };

    serviceModel.createDatasheetElement = function (elementType) {
        var elementHtml = "";
        if (elementType == "text") {
            elementHtml = "<datasheet-text></datasheet-text>";
        } else if (elementType == "inputtext") {
            elementHtml = "<datasheet-input-text></datasheet-input-text>";
        } else if (elementType == "image") {
            elementHtml = "<datasheet-image></datasheet-image>";

        } else if (elementType == "inputimage") {
            elementHtml = "<datasheet-input-image element='element'></datasheet-input-image>";

        } else if (elementType == "icon") {
            elementHtml = "<datasheet-icon></datasheet-icon>";

        } else if (elementType == "inputicon") {
            elementHtml = "<datasheet-input-icon></datasheet-input-icon>";

        } else if (elementType == "box") {
            elementHtml = "<datasheet-box></datasheet-box>";

        } else if (elementType == "selectlist") {
            elementHtml = "<datasheet-select-list></datasheet-select-list>";
        } else if (elementType == "table") {
            elementHtml = "<datasheet-table></datasheet-table>";
        } else if (elementType == "headercell") {
            elementHtml = "<th></th>";
        } else if (elementType == "row") {
            elementHtml = "<tr datasheet-table-row></tr>";
        } else if (elementType == "cell") {
            elementHtml = "<td datasheet-table-cell></td>";
        }

        return elementHtml;
    };

    serviceModel.createDatasheetLayout = function (pageLayout) {
        var pageLayoutHtml = "";
        if (pageLayout == "single") {
            pageLayoutHtml = "<datasheet-single-layout></datasheet-single-layout>";
        } else if (pageLayout == "double") {
            pageLayoutHtml = "<datasheet-double-layout></datasheet-double-layout>";
        } else if (pageLayout == "triple") {
            pageLayoutHtml = "<datasheet-triple-layout></datasheet-triple-layout>";
        } else if (pageLayout == "4Pane") {
            pageLayoutHtml = "<datasheet-four-pane-layout></datasheet-four-pane-layout>";
        } else if (pageLayout == "topSingle") {
            pageLayoutHtml = "<datasheet-top-single-layout></datasheet-top-single-layout>";
        } else if (pageLayout == "bottomSingle") {
            pageLayoutHtml = "<datasheet-bottom-single-layout ></datasheet-bottom-single-layout>";
        }

        return pageLayoutHtml;
    };

    serviceModel.createEmptyDatasheetInputImage = function () {
        return '<div type="text" style="box-sizing:border-box;width:100%;height:100%;font-family:Arial; color:#000000;font-size:11pt;line-height:11pt; text-align:center;background-color:#f4f4f4;border:2px solid #00a0dd; padding-top:20px;overflow:hidden;" class="inline-element"> <i class="fa fa-picture-o" style="font-size:30px; color:#f58220"></i><br><span style="font-size:12px;">{{image}}</span> <br> <span style="font-size:10px;"> {{clickToUpload}}</span></div>';
    };

    serviceModel.createDatasheetInputImage = function () {
        return '<img style="width:{{width}};height:{{height}};" class="inline-element" src="{{src}}">';
    };




    //Functions End

    return serviceModel;
};

module.exports = function (app) {
    app.service('datasheetViewService', [datasheetViewService]);
};