var datasheetTable = function (datasheetElementService) {
    return {
        restrict: 'E',
        replace: true,
        templateUrl: 'app/components/datasheet/shared/directives/elements/datasheetTable/datasheetTable/datasheetTable.html',
        link: function (scope, elem, attrs) {
            //Fields 
            var containerElement = elem;
            var innerElement = elem.find(".inner-element")
            //Fields End

            //Elements
            var element = scope.element;
            //Elements End

            //Inner Functions
            var init = function () {

            };

            var addUsefulClass = function () {
                containerElement.addClass("container-element table-" + element._id + "");
            };

            var setElementStyle = function () {
                containerElement.css(element.containerStyle);
                containerElement.css("border","1px solid transparent");

                innerElement.css(element.style);

                //Elementler gizli ise ilgili class ı ekleyelim.
                if (element != null && element != undefined) {
                    if (element.isHidden) {
                        containerElement.addClass('hidden-blur');
                    }
                }
            };
            //Inner Functions End


            //Initials
            addUsefulClass();
            setElementStyle();
            //Initials End
        }
    }
};

module.exports = function (app) {
    app.directive('datasheetTable', ['datasheetElementService', datasheetTable]);
};