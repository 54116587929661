var pdfTableBody = function ($compile, pdfViewService, _) {
    return {
        restrict: 'A',
        link: function (scope, elem, attrs) {
            //Fields 
            //Fields End

            //Elements
            //Elements End

            //Inner Functions
            var setRows = function () {
                var table = scope.element;
                var tableId = scope.element._id;
                var rows = _.filter(scope.pdfModel.elements, {
                    parentId: tableId,
                    type: "row"
                });
                rows = _.sortBy(rows, 'order');


                for (var i = 0, len = rows.length; i < len; i++) {
                    var row = rows[i];
                    var rowHtml = pdfViewService.createPdfElement(row.type);

                    var childScope = scope.$new();
                    childScope.table = table;
                    childScope.row = row;

                    $(elem).append($compile(rowHtml)(childScope));
                }
            };
            //Inner Functions End

            //Events
            //Events End

            //Initials 
            setRows();
            //Initials End
        }
    }
};

module.exports = function (app) {
    app.directive('pdfTableBody', ['$compile', 'pdfViewService', '_', pdfTableBody]);
};