require("./../../../../bower_components/angular/angular.js");
require('angular-ui-router');
require('../core/');
require('../datasheet/');


//Module
var searchModule = angular.module('tdsApp.search', [
  'ui.router',
  'tdsApp.core',
  'tdsApp.datasheet'
]);

searchModule.run(['$rootScope', function($rootScope){
  $rootScope.searchModel = {
    headerSearch : true
  };
}]);

//Router
require('./routing.js')(searchModule);

//Factories
require('./shared/factories/searchFactory.js')(searchModule);

//Controllers
require('./searchResults/controllers/searchCtrl.js')(searchModule);
require('./shared/controllers/_partials/headerSearchCtrl.js')(searchModule);

//Export
module.exports = searchModule;
