var orderID  = function($filter){
  return function(order) {
    if(angular.isUndefined(order)) return "";
    var orderId = $filter('date')(order.orderDate, 'yyyy');
    orderId += "-" + order._id.toString().substring(0, 6).toUpperCase();
    return orderId;
  };
};

module.exports = function(app){
  app.filter('orderID', ['$filter', orderID]);
};
