var pdfSelectList = function (pdfElementService, pdfViewService) {
    return {
        restrict: 'E',
        replace: true,
        templateUrl: 'app/components/pdf/shared/directives/elements/pdfSelectList/pdfSelectList.html',
        link: function (scope, elem, attrs) {
            //Fields 
            var containerElement = elem;
            var innerElement = containerElement.find(".inner-element");
            //Fields End

            //Elements
            //Elements End

            //Inner Functions
            var setElementStyle = function () {
                containerElement.css(scope.element.containerStyle);
                
                var newStyle = pdfViewService.arrangeElementBorder(scope.element.style);
                innerElement.css(newStyle);
            };

            var setElementValue = function () {
                var selectListValue = pdfElementService.getSelectListValue(scope.pdfModel.mode, scope.element, scope.selectionModel.selectedLanguage);

                if (selectListValue != "") {
                    innerElement.html(selectListValue);
                }
                else {
                    innerElement.css("white-space", "nowrap");
                    // var lblAddItem = pdfElementService.getSelectListDefaultText(scope.selectionModel.selectedLanguage).addItem;
                    // var selectListInfo = {
                    //     addItem: lblAddItem
                    // };

                    // var empty = pdfViewService.createEmptyPdfSelectList();
                    // empty = empty.replace("{{addItem}}", selectListInfo.addItem);
                    // innerElement.append($(empty));
                }

            };
            //Inner Functions End

            //Initials 
            setElementStyle();
            setElementValue();
            //Initials End
        }
    }
};

module.exports = function (app) {
    app.directive('pdfSelectList', ['pdfElementService', 'pdfViewService', pdfSelectList]);
};