var groupFactory = function(httpProvider) {
    //Fields
    var factoryModel = {};
    //Fields - End

    //Functions
    factoryModel.ping = function() {
        return httpProvider.get('group', 'ping');
    };

    factoryModel.getAllGroups = function() {
        return httpProvider.get('group', 'getAllGroups');
    };

    factoryModel.getGroupById = function(id) {
        var params = "id=" + id;
        return httpProvider.get('group', 'getGroupById', params);
    };

    factoryModel.getGroupUsersById = function(id) {
        var params = "id=" + id;
        return httpProvider.get('group', 'getGroupUsersById', params);
    };

    factoryModel.addUsersToGroup = function(model) {
        return httpProvider.post('group', 'addUsersToGroup', model);
    };

    factoryModel.deleteUserFromGroup = function(model) {
        return httpProvider.post('group', 'deleteUserFromGroup', model);
    };

    factoryModel.createGroup = function(model) {
        return httpProvider.post('group', 'createGroup', model);
    };

    factoryModel.updateGroup = function(model) {
        return httpProvider.post('group', 'updateGroup', model);
    };

    factoryModel.deleteGroup = function(groupId) {
        return httpProvider.delete('group', 'deleteGroup', groupId);
    }

    //Functions End

    return factoryModel;

};

module.exports = function(app) {
    app.factory('groupFactory', ['httpProvider', groupFactory]);
};
