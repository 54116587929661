var counterUp = function() {
    return {
        require: 'ngModel',
        restrict: 'AE',
        /* optional */
        link: function(scope, el, attrs, ngModel) {
            scope.$watch(function() {
                return ngModel.$modelValue;

            }, initialize);

            function initialize(value) {

                $(el).html(ngModel.$modelValue);
                $(el).counterUp({
                    delay: 10,
                    time: 1000
                });
            }
        }
    };
};

module.exports = function(app) {
    app.directive('counterUp', counterUp);
};
