var folderOptionsCtrl = function($rootScope, $scope, $state, $filter, $modal, $translate, SweetAlert, folderFactory, checkPermissionFactory) {
    //Fields
    $scope.userPermissions = [];
    //Fields End

    //Functions

    $scope.openDeleteFolderModal = function() {
        var subFolders = $filter('filter')($scope.folders, {
            "Parent": $scope.selectedFolder.id
        });

        var folderFiles = $scope.files;

        var errorTitle = $translate.instant("FOLDER.MODALS.DELETEFOLDER.LBL_TITLE.TEXT");
        var errorMessage = $translate.instant("FOLDER.MODALS.DELETEFOLDER.LBL_HAS_FILES_INSIDE.TEXT");

        if ((angular.isDefined(subFolders) && subFolders.length > 0) || (angular.isDefined(folderFiles) && folderFiles.length > 0)) {
            SweetAlert.swal(errorTitle, errorMessage, "error")
            return;
        }

        var modalInstance = $modal.open({
            animation: false,
            controller: 'deleteFolderCtrl',
            templateUrl: '/app/components/folder/shared/views/modals/deleteFolder.html',
            backdropClass: "defaultModal",
            resolve: {
                selectedFolder: function() {
                    return $scope.selectedFolder;
                }
            }
        });

        modalInstance.result
            .then(function(response) {
                if (response.status) {
                    $scope.$emit('deletedFolder', {
                        parentFolderId: $scope.selectedFolder.parent
                    });
                }
            });
    };

    $scope.goToFolderPermissions = function() {
        $state.go('app.folderPermissions', {
            folderId: $scope.selectedFolder.id
        });
    };

    $scope.openCreateNewFolder = function() {
        $rootScope.$broadcast('newFolderOpenned', {});
        $('#renameFolder').slideUp();
        $('#newFolder').slideDown();
    };

    $scope.openRenameFolder = function() {
        $rootScope.$broadcast('renameFolderOpenned', {
            selectedFolderName: $scope.selectedFolder.name
        });
        $('#newFolder').slideUp();
        $('#renameFolder').slideDown();
    };

    $scope.openMoveFolderModal = function() {
        if ($scope.folderType == "Datasheet") {
            var folders = $scope.folders;
            $scope.folders = checkPermissionFactory.canMoveDatasheetFolderToFolders(folders);
        };
        var modalInstance = $modal.open({
            animation: false,
            controller: 'moveFolderCtrl',
            templateUrl: '/app/components/folder/shared/views/modals/moveFolder.html',
            backdrop: "static",
            resolve: {
                folders: function() {
                    return $scope.folders;
                },
                folder: function() {
                    return $scope.selectedFolder;
                }
            }
        });

        modalInstance.result
            .then(function(response) {
                if (response.status) {
                    $scope.$emit('movedFolder', {
                        parentFolderId: $scope.selectedFolder.parent
                    });
                }
            });
    };
    //Functions End

    //Initials
    //Initials End
};

module.exports = function(app) {
    app.controller('folderOptionsCtrl', ['$rootScope', '$scope', '$state', '$filter', '$modal', '$translate', 'SweetAlert', 'folderFactory', 'checkPermissionFactory', folderOptionsCtrl]);
};