var datasheetImage = function (datasheetElementService, datasheetContextMenuService, datasheetContextMenuHandlerService) {
    return {
        restrict: 'E',
        replace: true,
        templateUrl: 'app/components/datasheet/shared/directives/elements/datasheetImage/datasheetImage.html',
        link: function (scope, elem, attrs) {
            //Fields
            var containerElement = elem;
            var innerElement = elem.find('.inner-element');
            //Fields End

            //Elements
            var element = scope.element;
            //Elements End

            //Inner Functions
            var init = function () {
                containerElement = $('#' + element.type + '-' + element._id);
                innerElement = containerElement.find(".inner-element");
            };

            var addUsefulClass = function () {
                containerElement.addClass("container-element image-" + element._id + "");
            };

            var setElementStyle = function () {
                containerElement.css(element.containerStyle);
                innerElement.css(element.style);

                //Elementler gizli ise ilgili class ı ekleyelim.
                if (element != null && element != undefined) {
                    if (element.isHidden) {
                        containerElement.addClass('hidden-blur');
                    }
                }
            };

            var setContextMenu = function () {
                $.contextMenu({
                    selector: ".image-" + element._id,
                    build: function ($trigger, e) {
                        var page = scope.page;
                        var table = scope.table;
                        var row = scope.row;
                        var cell = scope.cell;
                        var element = scope.element;

                        return {
                            callback: contextMenuClickHandler,
                            items: datasheetContextMenuService.createContextMenuItemsForImage(page, table, row, cell, element)
                        };
                    }
                });
            };
            //Inner Functions End

            //Handlers
            var contextMenuClickHandler = function (key, options) {
                datasheetContextMenuHandlerService
                    .handleContextMenuClick(key,
                    scope.selectionModel.showHiddenElements,
                    scope.templateModel,
                    scope.datasheetModel,
                    scope.page,
                    scope.table,
                    scope.row,
                    scope.cell,
                    scope.element);
            };
            //Handlers End

            //Initials 
            addUsefulClass();
            setElementStyle();
            setContextMenu();
            //Initials End
        }
    }
};

module.exports = function (app) {
    app.directive('datasheetImage', ['datasheetElementService', 'datasheetContextMenuService', 'datasheetContextMenuHandlerService', datasheetImage]);
};