var loginLayoutCtrl = function ($scope) {
    //Fields 
    //Fields End

    //Events
    //Events End

    //Initials
    if (INTERCOM_SETTINGS.IS_SHOWN) {
        window.Intercom('boot', {
            app_id: INTERCOM_SETTINGS.APP_ID
        });
    }
    //Initials End

};

module.exports = function (app) {
    app.controller('loginLayoutCtrl', ['$scope', loginLayoutCtrl]);
}