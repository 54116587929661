var iconPickerCtrl = function($scope, $modalInstance, _, archiveFactory, parentScope) {
    //Fields
    $scope.allIconGroups = {};
    $scope.iconGroups = {};
    $scope.icons = [];

    $scope.selectedIcon = {};
    //Fields End

    //Functions
    $scope.cancel = function() {
        $modalInstance.dismiss();
    };

    $scope.selectIconGroup = function(selectedIconGroup) {
        $scope.selectedIconGroup = selectedIconGroup;

        $scope.icons = _.filter($scope.allIconGroups, function(iconGroup) {
            return iconGroup.code == selectedIconGroup;
        })[0].icons;
        $scope.selectedIcon = {};
    };

    $scope.selectIcon = function(icon) {
        $scope.selectedIcon = icon;
    };

    $scope.ok = function() {
        $modalInstance.close({
            status: true,
            selectedIcon: $scope.selectedIcon
        });
    };
    //Functions End

    //Inner Functions
    var getAllIconGroups = function() {
        archiveFactory.getAllIconGroups()
            .then(function(response) {
                $scope.allIconGroups = response;

                $scope.iconGroups = _.map($scope.allIconGroups, function(iconGroup) {
                    return {
                        name: iconGroup.name,
                        code: iconGroup.code
                    }
                });

                $scope.selectedIconGroup = $scope.allIconGroups[0].code;
                $scope.icons = $scope.allIconGroups[0].icons;
            });
    };
    //Inner Functions End

    //Initials
    getAllIconGroups();
    //Initials End
};

module.exports = function(app) {
    app.controller('iconPickerCtrl', ['$scope', '$modalInstance', '_', 'archiveFactory', 'parentScope', iconPickerCtrl]);
};
