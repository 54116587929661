require("./../../../../bower_components/angular/angular.js");
require('angular-ui-router');
require('../core/');
// require("./../../../../bower_components/angular-daterangepicker/js/angular-daterangepicker.js");

//Module
var reportsModule = angular.module('tdsApp.reports', [
  'tdsApp.core'
]);

//Router
require('./routing.js')(reportsModule);

//Factories
require('./shared/factories/reportFactory.js')(reportsModule);

//Services


//Directives


//Controllers
require('./datasheetReports/controllers/datasheetReportController.js')(reportsModule);
require('./datasheetReports/controllers/_partials/datasheetFilterController.js')(reportsModule);


//Directive Controllers


//Export
module.exports = reportsModule;
