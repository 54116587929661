var confirmDeleteFileCtrl = function($scope, $modalInstance, text) {
    //Fields
    $scope.message = text;
    //Fields End

    //Functions
    $scope.cancel = function() {
        $modalInstance.close(false);
    };

    $scope.delete = function() {
        $modalInstance.close(true);
    };
    //Functions End

    //Initials
    //Initials End
};

module.exports = function(app) {
    app.controller('confirmDeleteFileCtrl', ['$scope', '$modalInstance', 'text', confirmDeleteFileCtrl]);
};
