var pdfViewService = function ($translate) {
    //Fields 
    var serviceModel = {};
    //Fields End

    //Functions
    serviceModel.createPdfPage = function () {
        return '<pdf-page></pdf-page>';
    };

    serviceModel.createPdfHeader = function () {
        return '<pdf-header></pdf-header>';
    };

    serviceModel.createPdfBody = function () {
        return '<pdf-body></pdf-body>';
    };

    serviceModel.createPdfFooter = function () {
        return ' <pdf-footer></pdf-footer>';
    };

    serviceModel.createPdfLayout = function (pageLayout) {
        var pageLayoutHtml = "";
        if (pageLayout == "single") {
            pageLayoutHtml = "<pdf-single-layout></pdf-single-layout>";
        } else if (pageLayout == "double") {
            pageLayoutHtml = "<pdf-double-layout></pdf-double-layout>";
        } else if (pageLayout == "triple") {
            pageLayoutHtml = "<pdf-triple-layout></pdf-triple-layout>";
        } else if (pageLayout == "4Pane") {
            pageLayoutHtml = "<pdf-four-pane-layout></pdf-four-pane-layout>";
        } else if (pageLayout == "topSingle") {
            pageLayoutHtml = "<pdf-top-single-layout></pdf-top-single-layout>";
        } else if (pageLayout == "bottomSingle") {
            pageLayoutHtml = "<pdf-bottom-single-layout ></pdf-bottom-single-layout>";
        }

        return pageLayoutHtml;
    };

    serviceModel.createPdfElement = function (elementType) {
        var elementHtml = "";
        if (elementType == "text") {
            elementHtml = "<pdf-text></pdf-text>";
        } else if (elementType == "inputtext") {
            elementHtml = "<pdf-input-text></pdf-input-text>";
        } else if (elementType == "image") {
            elementHtml = "<pdf-image></pdf-image>";

        } else if (elementType == "inputimage") {
            elementHtml = "<pdf-input-image></pdf-input-image>";

        } else if (elementType == "icon") {
            elementHtml = "<pdf-icon></pdf-icon>";

        } else if (elementType == "inputicon") {
            elementHtml = "<pdf-input-icon></pdf-input-icon>";

        } else if (elementType == "box") {
            elementHtml = "<pdf-box></pdf-box>";

        } else if (elementType == "selectlist") {
            elementHtml = "<pdf-select-list></pdf-select-list>";
        } else if (elementType == "table") {
            elementHtml = "<pdf-table></pdf-table>";
        } else if (elementType == "headercell") {
            elementHtml = "<th></th>";
        } else if (elementType == "row") {
            elementHtml = "<tr pdf-table-row></tr>";
        } else if (elementType == "cell") {
            elementHtml = "<td pdf-table-cell><div style='width:100%;height:100%;'></div></td>";
        }

        return elementHtml;
    };

    serviceModel.createEmptyPdfImage = function () {
        return '<div type="text" style="box-sizing:border-box;width:100%;height:100%;font-family:Arial; color:#000000;font-size:11pt;line-height:11pt; text-align:center;background-color:#f4f4f4;border:2px solid #00a0dd; padding-top:3px;overflow:hidden;" class="inline-element"> <i class="fa fa-picture-o" style="font-size:30px; color:#f58220"></i><br><span style="font-size:12px;">{{image}}</span> <br> <span style="font-size:10px;"> {{imageWillBeHere}}</span></div>';
    };

    serviceModel.createPdfInputImage = function () {
        return '<img style="width:{{width}};height:{{height}};" class="inline-element" src="{{src}}">';
    };

    serviceModel.createEmptyPdfIcon = function () {
        return '<div type="text" style="box-sizing:border-box;width:100%;height:100%;font-family:Arial; color:#000000;font-size:11pt;line-height:11pt; text-align:center;background-color:#f4f4f4;border:2px solid #00a0dd; padding-top:3px;overflow:hidden;" class="inline-element"> <i class="fa fa-thumb-tack" style="font-size:30px; color:#f58220"></i><br><span style="font-size:12px;">{{icon}}</span> <br> <span style="font-size:10px;"> {{iconWillBeHere}}</span></div>';
    };

    serviceModel.createEmptyPdfSelectList = function () {
        return "<p style='overflow: hidden;text-overflow: ellipsis;'>{{addItem}}</p>";
    };

    serviceModel.arrangeElementBorder = function (style) {
        var newStyle = style;
        if (newStyle.borderLeftWidth == "0px") {
            newStyle.borderLeft = "none";
        }

        if (newStyle.borderTopWidth == "0px") {
            newStyle.borderTop = "none";
        }

        if (newStyle.borderRightWidth == "0px") {
            newStyle.borderRight = "none";
        }

        if (newStyle.borderBottomWidth == "0px") {
            newStyle.borderBottom = "none";
        }

        return newStyle;
    };
    //Functions End

    return serviceModel;
};

module.exports = function (app) {
    app.service('pdfViewService', ['$translate', pdfViewService]);
};