var welcomeCtrl = function($rootScope, $scope, localStorageService) {
    //Fields
    $scope.userPermissions = localStorageService.get('userPermissions');
    //Fields End

    //Events
    $rootScope.$on('loadedUserInformation', function(event, data) {
        var user = data.user;

        $scope.lblWelComeTranslationData = {
            firstName: user.FirstName
        };
    });

    //Initials
    var user = localStorageService.get('userInformationData');
    if (user != null && angular.isDefined(user)) {
        $scope.lblWelComeTranslationData = {
            firstName: user.FirstName
        };
    }
    //Initials End
};

module.exports = function(app) {
    app.controller('welcomeCtrl', ['$rootScope', '$scope', 'localStorageService', welcomeCtrl]);
};
