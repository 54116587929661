var pdfBody = function ($compile, pdfViewService) {
    return {
        restrict: 'E',
        replace: true,
        templateUrl: 'app/components/pdf/shared/directives/pdfBody/pdfBody.html',
        link: function (scope, elem, attrs) {
            //Fields 
            //Fields End

            //Inner Functions
            var setBodyStyle = function () {
                elem.css(scope.page.bodyStyle);
            };

            var setLayout = function () {
                var pageLayout = scope.page.layout;
                var pageLayoutHtml = pdfViewService.createPdfLayout(pageLayout);
                var childScope = scope.$new();

                $(elem).append($compile(pageLayoutHtml)(childScope));
            };
            //Inner Functions End

            //Initials
            setBodyStyle();
            setLayout();
            //Initials End
        }
    };
};

module.exports = function (app) {
    app.directive('pdfBody', ['$compile', 'pdfViewService', pdfBody]);
};