var yourDatasheetsCtrl = function ($rootScope, $scope, $q, $state, $filter, $timeout, $translate, $modal, SweetAlert, localStorageService, folderFactory, datasheetFactory, userFactory, checkPermissionFactory, firebaseFactory, intercomFactory, _) {
    //Fields
    var ctrlPressed = false;
    $scope.translatedArchive = $translate.instant("DATASHEET.YOURDATASHEETS.LBL_ARCHIVE.TEXT");
    $scope.rootFolder = {};
    $scope.selectedFolder = {};
    $scope.selectedDatasheets = [];
    $scope.hasDatasheet = false;

    $scope.newFolder = {};
    $scope.folderType = "Datasheet";
    $scope.folders = [];

    $scope.dataSheetFolderPermissions = [];
    $scope.editDatasheetYetkisiVarmi = true;
    $scope.sortModel = {
        field: "productName",
        reverse: false
    }
    $scope.loaded = {
        datasheet: {
            folders: false,
            datasheets: false
        }
    };
    //Field - End

    //Filters
    $scope.updateSearchResults = function () {
        $scope.filteredDatasheetFiles = $scope.datasheetFiles.filter(function (datasheet) {
            if (!$scope.listSearchKey) return true;
            var matched = false;

            $scope.listSearchKey.split(' ').forEach(function (word) {
                word = $scope.listSearchKey.toString().toLowerCase();
                var datasheetSearchFieldValues = datasheet.productNumber.toString().toLowerCase() + datasheet.productName.toString().toLowerCase();

                if (datasheetSearchFieldValues.indexOf(word) != -1) {
                    if (matched === false) {
                        matched = true;
                    }
                }
            });

            return matched;
        });

    };

    //Events
    $scope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
        notifyBar.clear();
    });
    $scope.$on('createdNewFolder', function (event, data) {
        getDatasheetFolders(function () {
            var newFolder = $filter("filter")($scope.datasheetFolders, {
                parent: data.parentFolderId,
                text: data.newFolderName
            }, true)[0];

            openNewFolder(newFolder);
            $scope.folders = $scope.datasheetFolders;
        });
    });

    $scope.$on('deletedFolder', function (event, data) {
        getDatasheetFolders(function () {
            var parentFolder = $filter("filter")($scope.datasheetFolders, {
                id: data.parentFolderId
            }, true)[0];

            openNewFolder(parentFolder);
            $scope.folders = $scope.datasheetFolders;
        });
    });

    $scope.$on('renamedFolder', function (event, data) {
        getDatasheetFolders(function () {
            $scope.folders = $scope.datasheetFolders;
            var selectedFolderNew = $filter('filter')($scope.folders, {
                "_id": $scope.selectedFolder.id
            });
            console.log(selectedFolderNew)
            $scope.selectedFolder = selectedFolderNew;
        });
    });

    $scope.$on('movedFolder', function (event, data) {
        getDatasheetFolders(function () {
            $scope.folders = $scope.datasheetFolders;
        });
    });

    $scope.$on('movedDatasheet', function (event, data) {
        refreshDatasheets();
    });

    $scope.$on('renamedDatasheet', function (event, data) {
        refreshDatasheets();
    });

    $scope.$on('deletedDatasheet', function (event, data) {
        refreshDatasheets();
    });

    $(window).resize(function () {
        setDivHeight();
    });

    $(document).on('keydown', function (e) {
        if (e.ctrlKey || e.keyCode == 91)
            ctrlPressed = true;
        else
            ctrlPressed = false;
    });

    $(document).on('keyup', function (e) {
        ctrlPressed = false;
    });

    $scope.$on('$destroy', function () {
        $(document).off('keydown');
        $(document).off('keyup');
        var accountId = localStorageService.get('userInformationData').AccountId._id;
        firebaseFactory.clearRefEvents(accountId + '/publishedTemplate');
    });
    //Events End

    //Event Handlers
    var publishedTemplateHandler = function (objProcessingDatasheets) {
        getProcessingDatasheets(objProcessingDatasheets);
    };
    //Event Handlers End

    //Watches
    $scope.$watch('selectedFolder.id', function () {
        if (angular.isDefined($scope.selectedFolder.id) && $scope.selectedFolder.id != "") {

            $scope.selectedFolderPath = folderFactory.getFolderPath($scope.datasheetFolders, $scope.selectedFolder.id);
            var selectedFolder = $filter('filter')($scope.datasheetFolders, {
                "_id": $scope.selectedFolder.id
            })[0];

            $scope.selectedFolder.text = selectedFolder.text;
            $scope.selectedFolder.name = selectedFolder.name;
            $scope.selectedFolder.parent = selectedFolder.parent;
            $scope.selectedFolder.folderPermissions = selectedFolder.itemPermissions;

            refreshDatasheets(function () {
                $timeout(function () {
                    $rootScope.$broadcast('reRenderNiceScroll', {
                        "id": "datasheetFolderTree"
                    });
                }, 100);
            });

            $rootScope.$broadcast('closeNewFolderOrRenameFolder', {});
        }
    });
    //Watches - End

    //Inner Functions


    var setDivHeight = function () {
        var height = $('#divYourDatasheetListContainer').height() - 150;
        $('#divYourFolderListContainer').height($('#divYourDatasheetListContainer').height() - 60);
        $timeout(function () {
            $('#divYourDatasheetList').slimscroll({
                height: height,
                railVisible: true,
                alwaysVisible: false
            });
        }, 250);
        // $('body').css('overflow', 'hidden');
    };

    var getDatasheetFolders = function (nextProcess) {
        $scope.loaded.datasheet.folders = false;
        folderFactory.getDatasheetFolders()
            .then(function (response) {
                $scope.loaded.datasheet.folders = true;

                $scope.datasheetFolders = response;
                for (var i = 0; i < $scope.datasheetFolders.length; i++) {
                    if ($scope.datasheetFolders[i].Parent == "#") {
                        $scope.datasheetFolders[i].Name = $scope.translatedArchive;
                        $scope.rootFolder = $scope.datasheetFolders[i];
                        break;
                    }
                }

                for (var i = 0; i < $scope.datasheetFolders.length; i++) {
                    var datasheetFolder = $scope.datasheetFolders[i];
                    datasheetFolder.id = datasheetFolder._id;
                    datasheetFolder.text = datasheetFolder.Name;
                    datasheetFolder.parent = datasheetFolder.Parent;
                }

                nextProcess();
            });
        // $scope.loaded.datasheet.folders = false;
        // folderFactory.getFolders("?filter[folderType]=Datasheet&limit=1000&fields=name,parent,_id,itemPermissions,state,text")
        //     .then(function (response) {
        //         $scope.loaded.datasheet.folders = true;

        //         $scope.datasheetFolders = response.data;
        //         for (var i = 0; i < $scope.datasheetFolders.length; i++) {
        //             if ($scope.datasheetFolders[i].parent == "#") {
        //                 $scope.datasheetFolders[i].name = $scope.translatedArchive;
        //                 $scope.rootFolder = $scope.datasheetFolders[i];
        //                 break;
        //             }
        //         }

        //         for (var i = 0; i < $scope.datasheetFolders.length; i++) {
        //             var datasheetFolder = $scope.datasheetFolders[i];
        //             datasheetFolder.id = datasheetFolder._id;
        //             datasheetFolder.text = datasheetFolder.name;
        //             datasheetFolder.parent = datasheetFolder.parent;
        //         }

        //         nextProcess();
        //     });
    };

    var hasAnyDatasheet = function () {
        var accountId = localStorageService.get('userInformationData').AccountId._id;
        datasheetFactory.hasAnyDatasheet(accountId)
            .then(function (response) {
                $scope.hasDatasheet = response;
            });
    };

    var openNewFolder = function (folder) {
        $scope.newFolder._id = folder._id;
    };

    var getDatasheets = function (nextProcess) {
        $scope.listSearchKey = "";
        $scope.loaded.datasheet.datasheets = false;
        datasheetFactory.getDatasheetsByFolderId($scope.selectedFolder.id)
            .then(function (response) {
                $scope.loaded.datasheet.datasheets = true;

                $scope.datasheetFiles = response;
                $scope.updateSearchResults();
                $scope.files = $scope.datasheetFiles;

                var disabledDatasheets = _.filter(response, {
                    isDisabled: true
                });

                if (disabledDatasheets.length > 0)
                    $scope.hasDisabledDatasheets = true;

                //Klasöre göre datasheetler yüklendikten sonra işlem yapılan ürün bilgi forumlarını al.
                var accountId = localStorageService.get('userInformationData').AccountId._id;
                firebaseFactory.checkOnce(accountId + "/publishedTemplate", function (value) {
                    getProcessingDatasheets(value);

                    //İşlem yapılan ürün bilgi formlarını sürekli izle
                    firebaseFactory.checkAlways(accountId + '/publishedTemplate', publishedTemplateHandler);
                });

                setDivHeight();
                nextProcess();
            });
    };

    var refreshDatasheets = function () {
        getDatasheets(function () {
            $scope.selectedDatasheets = [];
        });
    };

    var getProcessingDatasheets = function (objProcessingDatasheets) {
        //Klasörlere göre yüklenen datasheetlerden isProcessing alanını sil.
        _.each($scope.datasheetFiles, function (datasheetFile) {
            delete datasheetFile.isProcessing
        })

        if (objProcessingDatasheets == null || objProcessingDatasheets == undefined) {
            notifyBar.clear();
            return;
        }

        var hasProcessing = false;
        var publishedTemplates = Object.keys(objProcessingDatasheets);

        for (var pt = 0; pt < publishedTemplates.length; pt++) {
            var publishedTemplate = publishedTemplates[pt];
            var processingDatasheets = _.filter(objProcessingDatasheets[publishedTemplate].datasheets, {
                folderId: $scope.selectedFolder.id
            });

            for (var d = 0; d < processingDatasheets.length; d++) {
                var processingDatasheet = processingDatasheets[d];

                var datasheet = _.find($scope.datasheetFiles, {
                    _id: processingDatasheet._id
                });

                if (datasheet) {
                    datasheet.isProcessing = true;
                    hasProcessing = true;
                }
            }
        }

        //İşlem yapılan datasheet varsa notification bar'da uyarı verelim.
        if (hasProcessing) {
            var message = $translate.instant("Şablon yayınlandığı için ürün bilgi formları yeniden oluşturuluyor...");
            notifyBar.warning(message);
        }
        //İşlem yapılan datasheet yoksa notification barı silelim.
        else {
            notifyBar.clear();
        }

        if (!$scope.$$phase)
            $scope.$apply();
    }
    //Inner Functions End

    //Functions
    $scope.jsTreeOpenedNode = function () {
        //Rerender Nice scroll
        $rootScope.$broadcast('reRenderNiceScroll', {
            "id": "datasheetFolderTree"
        });
    };

    $scope.jsTreeClosedNode = function () {
        //Rerender Nice scroll
        $rootScope.$broadcast('reRenderNiceScroll', {
            "id": "datasheetFolderTree"
        });
    };

    $scope.reOrder = function (field) {
        $scope.sortModel.reverse = ($scope.sortModel.field === field) ? !$scope.sortModel.reverse : false;
        $scope.sortModel.field = field;
    };

    $scope.selectDatasheet = function (datasheet) {
        if (datasheet.isDisabled) return;
        if (datasheet.isProcessing) return;

        if (ctrlPressed) {
            if ($scope.selectedDatasheets.indexOf(datasheet) >= 0) {
                $scope.selectedDatasheets.splice($scope.selectedDatasheets.indexOf(datasheet), 1);
            } else {
                $scope.selectedDatasheets.push(datasheet);
            }
        }
        else {
            $scope.selectedDatasheets = [];
            $scope.selectedDatasheets.push(datasheet);
        }
    };

    $scope.editDatasheet = function (datasheet) {
        //Disabled edildiyse açma,
        if (angular.isDefined(datasheet) && datasheet.isDisabled) return;
        if (angular.isDefined(datasheet) && datasheet.isProcessing) return;

        var editDatasheetPermission = $filter('filter')(datasheet.datasheetPermissions, {
            "Name": "Edit Datasheet"
        })[0];
        if (editDatasheetPermission == null || editDatasheetPermission == undefined) {
            return;
        }

        $state.go('editor.editDatasheet', {
            lang: $rootScope.activeLang,
            id: datasheet._id
        });
    };

    $scope.openShareFolderWithEmailModal = function () {
        if (!angular.isUndefined($scope.selectedFolder) && angular.equals($scope.selectedFolder, {}) === false) {
            datasheetFactory.hasPublishedDatasheetsByFolderId($scope.selectedFolder.id)
                .then(function (response) {
                    if (response.status) {
                        var modalInstance = $modal.open({
                            animation: true,
                            controller: 'shareDatasheetFolderWithEmailCtrl',
                            templateUrl: '/app/components/datasheet/yourDatasheets/views/modals/shareDatasheetFolderWithEmail.html',
                            resolve: {
                                parentScope: function () {
                                    return $scope;
                                },
                                folder: function () {
                                    return $scope.selectedFolder;
                                }
                            }
                        });
                    } else {
                        var title = $translate.instant("DATASHEET.YOURDATASHEETS.ERROR_MESSAGES.NOT_PUBLISHED_FOLDER.LBL_TITLE.TEXT");
                        var notPublishedFolder = $translate.instant("DATASHEET.YOURDATASHEETS.ERROR_MESSAGES.NOT_PUBLISHED_FOLDER.LBL_EXPLANATION.TEXT");

                        SweetAlert.swal({
                            title: title,
                            text: notPublishedFolder,
                            type: 'error'
                        }, function () {
                        });

                    }
                });

        }
    };

    $scope.openShareOnSocialMediaModal = function () {
        var datasheet = $scope.selectedDatasheets[0];
        datasheet.showButtons = false;
        var modalInstance = $modal.open({
            animation: false,
            controller: 'shareOnSocialMediaCtrl',
            templateUrl: '/app/components/datasheet/shared/views/modals/shareOnSocialMedia.html',
            backdropClass: "defaultModal",
            size: "sm",
            resolve: {
                datasheet: function () {
                    return datasheet;
                }
            }
        });

        modalInstance.result.then(function (response) {
        }).finally(function () {
            modalInstance.$destroy();
        });
    }

    //Functions - End

    //Initials
    hasAnyDatasheet();

    getDatasheetFolders(function () {
        $scope.folders = $scope.datasheetFolders;
    });

    intercomFactory.addEvent({
        user_id: $rootScope.userId,
        event_name: "Your Datasheets Viewed"
    });
    //Initials End
};


module.exports = function (app) {
    app.controller('yourDatasheetsCtrl', ['$rootScope', '$scope', '$q', '$state', '$filter', '$timeout', '$translate', '$modal', 'SweetAlert', 'localStorageService', 'folderFactory', 'datasheetFactory', 'userFactory', 'checkPermissionFactory', 'firebaseFactory', 'intercomFactory', '_', yourDatasheetsCtrl]);
};