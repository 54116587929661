var editEmailCtrl = function($scope, $timeout, $modalInstance, mailGroupFactory, email) {
    //Fields
    $scope.updateButton = "MAILGROUP.ALLEMAILS.MODALS.EDITEMAIL.BTN_UPDATE.TEXT";
    $scope.isProccessing = false;

    $scope.emailFieldDisabled = true;

    $scope.newRequest = {};
    $scope.newRequest.emailId = email.id;
    $scope.newRequest.email = email.email;
    $scope.newRequest.tags = email.tags;

    $scope.errors = [];
    //Fields-End

    //Events
    //Events-End

    //Functions
    $scope.cancel = function() {
        $modalInstance.dismiss('cancel');
    };

    $scope.update = function() {
        $scope.updateButton = "MAILGROUP.ALLEMAILS.MODALS.EDITEMAIL.BTN_UPDATE.ALTERNATE_TEXT";;
        $scope.isProccessing = true;

        $scope.errors = [];
        if ($scope.newRequest.tags.length === 0) {
            $scope.errors.push("MAILGROUP.ALLEMAILS.MODALS.EDITEMAIL.ERROR_MESSAGES.ADD_ONE_TAG");
        }

        if ($scope.errors.length > 0) {
            $scope.updateButton = "MAILGROUP.ALLEMAILS.MODALS.EDITEMAIL.BTN_UPDATE.TEXT";
            $scope.isProccessing = false;

            $timeout(function() {
                $scope.errors = [];
            }, 5000);

            return;
        }

        var newTags = [];
        for (var i = 0; i < $scope.newRequest.tags.length; i++) {
            var tag = $scope.newRequest.tags[i];

            newTags.push(tag.text);
        }
        $scope.newRequest.tags = newTags;

        mailGroupFactory.updateEmail($scope.newRequest)
            .then(function(response) {
                $scope.updateButton = "MAILGROUP.ALLEMAILS.MODALS.EDITEMAIL.BTN_UPDATE.TEXT";
                $scope.isProccessing = false;

                $timeout(function() {
                    $modalInstance.close({
                        status: true
                    });
                }, 500);
            });
    };
    //Functions-End
};

module.exports = function(app) {
    app.controller('editEmailCtrl', ['$scope', '$timeout', '$modalInstance', 'mailGroupFactory', 'email', editEmailCtrl]);
};
