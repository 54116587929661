var confirmVersionUpgrade = function($scope, $translate, version, $modalInstance) {

  var translateModel = {
    currentVersion : version,
    nextVersion : parseInt(version) + 1
  };

  $scope.confirmText = $translate.instant('DATASHEET.EDIT_DATASHEET.MODALS.CONFIRM_VERSION_UPGRADE.LBL_CONFIRM_TEXT.TEXT', translateModel);

  $scope.cancel = function() {
      $modalInstance.dismiss('cancel');
  };

  $scope.yesClick = function() {
      $modalInstance.close(true);
  };

  $scope.noClick = function() {
      $modalInstance.close(false);

  };
};

module.exports = function(app){
  app.controller('confirmVersionUpgrade', ['$scope', '$translate', 'version', '$modalInstance', confirmVersionUpgrade]);
};
