var renameDatasheetCtrl = function($rootScope, $scope, $filter, $translate, $timeout, $modalInstance, $modal, datasheetFactory, templateFactory, selectedDatasheet, languageFactory, generalsService) {
    //Fields
    $scope.isDatasheetLoaded = false;
    $scope.hasError = false;
    $scope.toogleLink = "DATASHEET.YOURDATASHEETS.MODALS.RENAMEDATASHEET.BTN_HIDE_NAMES_BY_LANGUAGE.TEXT";
    $scope.okButton = "DATASHEET.YOURDATASHEETS.MODALS.RENAMEDATASHEET.BTN_OK.TEXT";
    $scope.publishButton = $translate.instant("DATASHEET.YOURDATASHEETS.MODALS.RENAMEDATASHEET.BTN_PUBLISH.TEXT");
    $scope.isProcessing = false;
    $scope.step = 1;
    $scope.namesByLanguage = true;
    //Fields End
    //Functions
    $scope.cancel = function() {
        $modalInstance.dismiss('cancel');
    };

    $scope.productNameChange = function() {
        angular.forEach($scope.datasheetModel.datasheet.names, function(name, index) {
            if (name.language == $scope.datasheetModel.datasheet.primaryLanguage)
                name.name = $scope.datasheetModel.datasheet.productName;
        });
    };

    $scope.toggleNames = function() {
        if ($scope.namesByLanguage) {
            $scope.toogleLink = $filter('translate')("DATASHEET.YOURDATASHEETS.MODALS.RENAMEDATASHEET.BTN_SHOW_NAMES_BY_LANGUAGE.TEXT");
            $scope.namesByLanguage = false;
        } else {
            $scope.namesByLanguage = true;
            $scope.toogleLink = $filter('translate')("DATASHEET.YOURDATASHEETS.MODALS.RENAMEDATASHEET.BTN_HIDE_NAMES_BY_LANGUAGE.TEXT");
        }

        $timeout(function() {
            $rootScope.$broadcast('reRenderNiceScroll', {});
        }, 100);

    };

    $scope.ok = function() {
        $scope.hasError = false;

         if (angular.isUndefined($scope.datasheetModel.datasheet.productName) || $scope.datasheetModel.datasheet.productName === null || $scope.datasheetModel.datasheet.productName === "") {
            $scope.hasError = true;
        }

        for (var i = 0; i < $scope.datasheetModel.datasheet.names.length; i++) {
            if (angular.isUndefined($scope.datasheetModel.datasheet.names[i].name) || $scope.datasheetModel.datasheet.names[i].name === null || $scope.datasheetModel.datasheet.names[i].name === "") {
                $scope.hasError = true;
            }
        }


         if (($scope.templateModel.template.hasProductNumber) && (angular.isUndefined($scope.datasheetModel.datasheet.productNumber) || $scope.datasheetModel.datasheet.productNumber === null || $scope.datasheetModel.datasheet.productNumber === "")) {
            $scope.hasError = true;
        }

        if ($scope.hasError)
            return;


        $scope.okButton = "DATASHEET.YOURDATASHEETS.MODALS.RENAMEDATASHEET.BTN_OK.ALTERNATE_TEXT";
        $scope.isProcessing = true;
        datasheetFactory.saveDatasheet($scope.datasheetModel)
            .then(function(response) {
                $scope.okButton = "DATASHEET.YOURDATASHEETS.MODALS.RENAMEDATASHEET.BTN_OK.TEXT";
                $scope.isProcessing = false;
                $scope.datasheetModel.datasheet.minorVersion = response.minorVersion;
                $scope.step = 2;
            });
    };

    $scope.cancelPublish = function() {
        var message = $translate.instant("DATASHEET.YOURDATASHEETS.MODALS.RENAMEDATASHEET.SUCCESS_MESSAGES.RENAMED_SUCCESSFULLY");

        toastr.success(message, '', { positionClass: 'toast-bottom-full-width ', timeOut: 3000 });
        $modalInstance.close({
            status: true
        });
    };

    $scope.publish = function() {
        $scope.publishButton = $translate.instant("DATASHEET.YOURDATASHEETS.MODALS.RENAMEDATASHEET.BTN_PUBLISH.ALTERNATE_TEXT");

        datasheetFactory.publishDataSheet($scope.datasheetModel, $scope.templateModel)
            .then(function(response) {
                var message = $translate.instant("DATASHEET.EDIT_DATASHEET.MESSAGES.SUCCESS.PUBLISHED_SUCCESSFULLY");
                toastr.success(message, '', { positionClass: 'toast-bottom-full-width ', timeOut: 3000 });
                $scope.publishButton = $translate.instant("DATASHEET.YOURDATASHEETS.MODALS.RENAMEDATASHEET.BTN_PUBLISH.TEXT");
                $modalInstance.close({
                    status: true
                });
            });
    };

    $scope.getLanguageCode = function(language) {
        if (angular.isDefined($scope.languages)) {
            var selectedLanguage = $filter('filter')($scope.languages, {
                "isoCode": language
            })[0];

            return selectedLanguage.languageCode;
        }

    };

    $scope.translateName = function(referanceLang, controlText) {
        if (angular.isUndefined(controlText) || controlText === "" || controlText.length <= 1) return;
        var requestModel = {};
        requestModel.ControlLanguage = referanceLang;
        requestModel.ControlText = controlText;
        requestModel.TranslateList = [];

        angular.forEach($scope.datasheetModel.datasheet.names, function(nameItem, key) {
            if (nameItem.language != referanceLang) {
                var requestListModel = {};
                requestListModel.Language = nameItem.language;
                requestListModel.Text = nameItem.name;

                requestModel.TranslateList.push(requestListModel);
            }
        });

        if (referanceLang != $scope.datasheetModel.datasheet.primaryLanguage) {
            var listModelForPrimaryLanguage = {};
            listModelForPrimaryLanguage.Language = $scope.datasheetModel.datasheet.primaryLanguage;
            listModelForPrimaryLanguage.Text = $scope.datasheetModel.datasheet.productName;
            requestModel.TranslateList.push(listModelForPrimaryLanguage);
        }

        generalsService.translateTextFrom(requestModel)
            .then(function(response) {
                for (var i = 0; i < response.TranslateList.length; i++) {
                    var currentItem = response.TranslateList[i];

                    if (currentItem.Language == $scope.datasheetModel.datasheet.primaryLanguage) {
                        $scope.datasheetModel.datasheet.productName = currentItem.Text;
                    } else {
                        var filteredItem = $filter('filter')($scope.datasheetModel.datasheet.names, {
                            language: currentItem.Language
                        }, true)[0];
                        filteredItem.name = currentItem.Text;
                    }

                }
                var successMessage = $translate.instant("DATASHEET.YOURDATASHEETS.MODALS.RENAMEDATASHEET.SUCCESS_MESSAGES.TRANSLATE_SUCCESS");
                toastr.success(successMessage, '', { positionClass: 'toast-bottom-full-width ', timeOut: 3000 });
            });
    };
    //Functions End

    //Initials
    //Datasheet de kullanılacak tüm diller sistemden çekilir.
    languageFactory.getLanguages()
        .then(function(response) {
            $scope.languages = response;

            //Datasheet in tüm bilgileri çekilir.
            $scope.isDatasheetLoaded = false;
            datasheetFactory.getDatasheetById(selectedDatasheet._id)
                .then(function(response) {
                    $scope.datasheetModel = response.datasheetModel;
                    $scope.templateModel = response.templateModel;
                    //Product number enable-disable mı öğrenmek için template getiriliyor
                    $scope.hasProductNumber = response.templateModel.template.hasProductNumber;

                    $scope.isDatasheetLoaded = true;

                });
        });
    //Initials End
};

module.exports = function(app) {
    app.controller('renameDatasheetCtrl', ['$rootScope', '$scope', '$filter', '$translate', '$timeout', '$modalInstance', '$modal', 'datasheetFactory', 'templateFactory', 'selectedDatasheet', 'languageFactory', 'generalsService', renameDatasheetCtrl])
};
