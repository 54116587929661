var datasheetRightMenu = function ($compile, $modal) {
    return {
        restrict: 'E',
        replace: true,
        templateUrl: 'app/components/datasheet/shared/directives/datasheetRightMenu/datasheetRightMenu.html',
        link: function (scope, elem, attrs) {
            //Fields 
            var btnDatasheetComments = elem.find("#btnDatasheetComments");
            var btnShareWithEmail = elem.find("#btnShareWithEmail");
            //Fields End

            //Elements
            var menuIsOpen = false;
            var childScope;
            //Elements End

            //Inner Functions
            var init = function () {
                $('#rightMenuPanel').hide();
                if (scope.datasheetModel.datasheet.majorVersion == 0) {
                    btnShareWithEmail.hide();
                }
            };

            var closeMenu = function () {
                menuIsOpen = false;

                if (childScope) {
                    childScope.$destroy();
                }
                $('#rightMenuPanel').html('');
                $('#rightMenuPanel').hide();
                $('#rightMenuPanel').slimScroll({ destroy: true });

                $('.template-design').css('width', '50px');
                $('.template-container').css('padding-right', '51px');

            }
            var openMenu = function () {
                menuIsOpen = true;

                childScope = scope.$new();
                var datasheetCommentsHtml = $compile('<datasheet-comments></datasheetComments>')(childScope);
                $('#rightMenuPanel').html(datasheetCommentsHtml);
                $('#rightMenuPanel').show();
                $('#rightMenuPanel').slimScroll({
                    width: '290px',
                    height: 'auto',
                });

                $('.template-design').css('width', '350px');
                $('.template-container').css('padding-right', '341px');

            };
            //Inner Functions End

            //Events
            btnDatasheetComments.bind('click', function (event) {
                if (menuIsOpen) {
                    $(this).removeClass("active");
                    closeMenu();
                }
                else {
                    $(this).addClass("active");
                    openMenu();
                }
            });

            btnShareWithEmail.unbind('click').bind('click', function(e){
                if (scope.datasheetModel.datasheet.majorVersion > 0) {
                    var modalInstance = $modal.open({
                        animation: true,
                        controller: 'shareDatasheetWithEmailCtrl',
                        templateUrl: '/app/components/datasheet/yourDatasheets/views/modals/shareDatasheetWithEmail.html',
                        resolve: {
                            parentScope: function() {
                                return scope;
                            },
                            datasheet: function() {
                                return scope.datasheetModel.datasheet;
                            }
                        }
                    });
                }else{
                    var title = $translate.instant("DATASHEET.YOURDATASHEETS.PARTIALS.DATASHEETOPTIONS.ERROR_MESSAGES.NOT_PUBLISHED_FOR_SHARE.LBL_TITLE.TEXT");
                    var notPublished = $translate.instant("DATASHEET.YOURDATASHEETS.PARTIALS.DATASHEETOPTIONS.ERROR_MESSAGES.NOT_PUBLISHED_FOR_SHARE.LBL_EXPLANATION.TEXT");

                    SweetAlert.swal({
                        title: title,
                        text: notPublished,
                        type: 'error'
                    }, function() {});
                }
            });

            //Events End

            //Initials 
            init();
            //Initials End


        }
    }
};

module.exports = function (app) {
    app.directive('datasheetRightMenu', ['$compile', '$modal', datasheetRightMenu]);
}