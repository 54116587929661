var accountSettingsFactory = function($rootScope, httpProvider, localStorageService, userFactory) {
    //Fields
    var factoryModel = {};
    //Fields - End

    //Function

    factoryModel.getMailSettings = function() {
        var accountId = localStorageService.get('userInformationData').AccountId._id;
        return httpProvider.get('account', 'getMailSettings', "accountId=" +  accountId);
    };

    factoryModel.saveMailSettings = function(settings) {
        var accountId = localStorageService.get('userInformationData').AccountId._id;
        settings.accountId = accountId;
        return httpProvider.put('account', 'saveMailSettings', settings);
    };

    factoryModel.sendTestMail = function(mailSettings) {
        return httpProvider.post('account', 'sendTestMail', mailSettings);
    };
    //Functions End


    return factoryModel;
};

module.exports = function(app) {
    app.factory('accountSettingsFactory', ['$rootScope', 'httpProvider', 'localStorageService', 'userFactory', accountSettingsFactory]);
};
