var deleteEmailCtrl = function($scope, $timeout, $modalInstance, mailGroupFactory, emailId) {
    //Fields
    $scope.deleteButton = "MAILGROUP.ALLEMAILS.MODALS.DELETEEMAIL.BTN_DELETE.TEXT";
    $scope.isProccessing = false;
    //Fields-End

    //Events
    //Events-End

    //Functions
    $scope.delete = function() {
        $scope.isProcessing = true;
        $scope.deleteButton = "MAILGROUP.ALLEMAILS.MODALS.DELETEEMAIL.BTN_DELETE.ALTERNATE_TEXT";

        mailGroupFactory.deleteEmail(emailId)
            .then(function(response) {
                $scope.deleteButton = "MAILGROUP.ALLEMAILS.MODALS.DELETEEMAIL.BTN_DELETE.SUCCESS";

                $timeout(function() {
                    $modalInstance.close({
                        status: true
                    });
                }, 500);

            });
    };

    $scope.cancel = function() {
        $modalInstance.dismiss('cancel');
    };
    //Functions-End
};

module.exports = function(app) {
    app.controller('deleteEmailCtrl', ['$scope', '$timeout', '$modalInstance', 'mailGroupFactory', 'emailId', deleteEmailCtrl]);
};
