var confirmAutomaticTranslaterCtrl = function($scope, $modalInstance, mode) {
    //Fields
    $scope.mode = mode;
    //Fields End

    //Functions
    $scope.cancel = function() {
        $modalInstance.dismiss();
    };

    $scope.confirm = function() {
        $modalInstance.close({
            status: true
        });
    };
    //Functions

    //Initials
    //Initials End
};

module.exports = function(app) {
    app.controller('confirmAutomaticTranslaterCtrl', ['$scope', '$modalInstance', 'mode', confirmAutomaticTranslaterCtrl]);
};
