var sideMenuCtrl = function($rootScope, $scope, $state, $filter, menuToggleFactory, adminFactory, localStorageService) {
    //Fields
    $scope.currentState = $state.current;
    $scope.isUserAdmin = false;
    $scope.appStoreIconImage = 'assets/images/app-store-2017-' + $rootScope.activeLang + '.png';
    $scope.googlePlayIconImage = 'assets/images/google-play-2017-' + $rootScope.activeLang + '.png';
    //Fields End

    //Events
    $rootScope.$on('$stateChangeSuccess', function(event, toState, toParams, fromState, fromParams) {
        $scope.currentState = toState;
    });
    //Events End

    //Functions
    $scope.getInnerMenuStyle = function(availableCurrentStates) {
        if ((availableCurrentStates.indexOf($scope.currentState.name) >= 0) && menuToggleFactory.isExpand()) {
            return {
                "display": "block"
            }
        } else return {

        }
    };
    var authData = localStorageService.get('authorizationData');
    adminFactory.isUserAdmin(authData._id)
    .then(function(response) {
        $scope.isUserAdmin = response;
    });
    //Functions End
};

module.exports = function(app) {
    app.controller('sideMenuCtrl', ['$rootScope', '$scope', '$state', '$filter', 'menuToggleFactory', 'adminFactory', 'localStorageService', sideMenuCtrl]);
};
