var config = function ($httpProvider, filepickerProvider) {
    // filepickerProvider.setKey('Am0vSpcsQti9yqRBdpQtuz');

    // $.FroalaEditor.DEFAULTS.key = '3cA-16jwzwxlE2cno==';

    //Set http interceptor
    $httpProvider.interceptors.push('httpInterceptor');

}

module.exports = function (app) {
    app.config(['$httpProvider', 'filepickerProvider', config]);
};
