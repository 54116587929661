var allGroupsCtrl = function ($scope, $state, $modal, groupFactory, intercomFactory) {
    //Fields
    //Fields End

    //Functions
    $scope.createNewGroup = function () {
        $state.go('app.newGroup');
    };

    $scope.openDeleteGroupModal = function (groupId) {
        var modalInstance = $modal.open({
            animation: false,
            templateUrl: 'app/components/groups/allGroups/views/modals/deleteGroup.html',
            controller: 'deleteGroupCtrl',
            backdrop: 'static',
            resolve: {
                groupId: function () {
                    return groupId;
                }
            }
        });

        modalInstance.result
            .then(function (result) {
                if (result.status) {
                    getAllGroups();
                }
            });
    };
    //Functions End

    //Inner Functions
    var getAllGroups = function () {
        $scope.tdsMakerPromise.allGroups = {};
        $scope.tdsMakerPromise.allGroups.message = "Loading Groups";
        $scope.tdsMakerPromise.allGroups.promise = groupFactory.getAllGroups()
            .then(function (response) {
                $scope.groups = response;
            });
    };
    //Inner Functions End

    //Initials
    getAllGroups();

    intercomFactory.addEvent({
        event_name: "User Groups Visited"
    });
    //Initials End
};

module.exports = function (app) {
    app.controller('allGroupsCtrl', ['$scope', '$state', '$modal', 'groupFactory', 'intercomFactory', allGroupsCtrl]);
};
