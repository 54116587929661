var deletePermissionCtrl = function($scope, $timeout,$translate, $modalInstance, permissionFactory, viewScope, permissionId) {
    //Fields
    $scope.deleteButton = "PERMISSION.MODALS.DELETEPERMISSION.BTN_DELETE.TEXT";
    $scope.isProcessing = false;
    //Fields End

    //Functions
    $scope.cancel = function() {
        $modalInstance.dismiss('cancel');
    };

    $scope.delete = function() {
        $scope.deleteButton = "PERMISSION.MODALS.DELETEPERMISSION.BTN_DELETE.ALTERNATE_TEXT";
        $scope.isProcessing = true;

        viewScope.deletePermissionModalDeleteClicked(permissionId, function(response) {
            if (response.data.status) {
                $scope.deleteButton = "PERMISSION.MODALS.DELETEPERMISSION.BTN_DELETE.TEXT";
                $scope.isProcessing = false;

                $timeout(function() {
                    var message = $translate.instant("PERMISSION.MODALS.DELETEPERMISSION.SUCCESS_MESSAGES.PERMISSION_DELETED");
                    toastr.success(message, '', { positionClass: 'toast-bottom-full-width', timeOut: 3000 });
                    $modalInstance.close({
                        status: true
                    });
                }, 500);
            }
        });


    };
    //Functions End

    //Initials
    //Initials End
};

module.exports = function(app) {
    app.controller('deletePermissionCtrl', ['$scope', '$timeout','$translate', '$modalInstance', 'permissionFactory', 'viewScope', 'permissionId', deletePermissionCtrl]);
};
