var allEmailsCtrl = function ($scope, mailGroupFactory, intercomFactory, $modal) {
    //Fields
    $scope.emailGroups = [];
    $scope.selectedEmail = {};
    //Fields-End

    //Functions
    $scope.addEmailList = function () {
        var modalInstance = $modal.open({
            animation: false,
            controller: 'addEmailListCtrl',
            templateUrl: '/app/components/mailGroups/allEmails/views/modals/addEmailList.html',
            resolve: {
                parentScope: function () {
                    return $scope;
                }
            }
        });

        modalInstance.result.then(function (result) {
            if (result.status) {
                getAllEmails();
            }
        });
    };

    $scope.editEmail = function (email) {
        var modalInstance = $modal.open({
            animation: false,
            controller: 'editEmailCtrl',
            templateUrl: '/app/components/mailGroups/allEmails/views/modals/editEmail.html',
            resolve: {
                email: function () {
                    return {
                        id: email._id,
                        email: email.Email,
                        tags: email.Tags
                    };
                }
            }
        });

        modalInstance.result.then(function (result) {
            if (result.status) {
                getAllEmails();
            }
        });
    };

    $scope.deleteEmail = function (email) {
        var modalInstance = $modal.open({
            animation: false,
            controller: 'deleteEmailCtrl',
            templateUrl: '/app/components/mailGroups/allEmails/views/modals/deleteEmail.html',
            resolve: {
                emailId: function () {
                    return email._id;
                }
            }
        });

        modalInstance.result.then(function (result) {
            if (result.status) {
                getAllEmails();
            }
        });
    };



    //Functions End

    //Inner Functions
    var getAllEmails = function () {
        $scope.tdsMakerPromise.allEmails = {};
        $scope.tdsMakerPromise.allEmails.message = "Loading All Email Groups";
        $scope.tdsMakerPromise.allEmails.promise = mailGroupFactory.getAllEmailList()
            .then(function (response) {
                $scope.emailGroups = response;
                $scope.selectedEmail = {};
            });
    };
    //Inner Functions End

    //Initials
    getAllEmails();
    intercomFactory.addEvent({
        event_name: "Email Groups Visited"
    });
    //Initials-End
};

module.exports = function (app) {
    app.controller('allEmailsCtrl', ['$scope', 'mailGroupFactory', 'intercomFactory', '$modal', allEmailsCtrl]);
};
