var datasheetInputIcon = function ($modal, datasheetElementService, datasheetContextMenuService, datasheetContextMenuHandlerService) {
    return {
        restrict: 'E',
        replace: true,
        templateUrl: 'app/components/datasheet/shared/directives/elements/datasheetInputIcon/views/datasheetInputIcon.html',

        link: function (scope, elem, attrs) {
            //Fields 
            var containerElement = elem;
            var innerElement = containerElement.find('.inner-element');
            //Fields End

            //Elements       
            var element = scope.element;
            //Elements End

            //Inner Functions
            var init = function () {
                containerElement = $('#' + scope.element.type + '-' + scope.element._id);
                innerElement = containerElement.find(".inner-element");
            };

            var addUsefulClass = function () {
                containerElement.addClass("container-element inputicon-" + element._id + "");
            };

            var setElementStyle = function () {
                containerElement.css(scope.element.containerStyle);
                innerElement.css(scope.element.style);

                //Elementler gizli ise ilgili class ı ekleyelim.
                if (element != null && element != undefined) {
                    if (element.isHidden) {
                        containerElement.addClass('hidden-blur');
                    }
                }
            };

            var setElementValue = function () {
                innerElement.html('');
                innerElement.append($('<i></i>').addClass(element.value));
            };

            var setContextMenu = function () {
                $.contextMenu({
                    selector: "." + scope.element.type + "-" + scope.element._id,
                    reposition: false,
                    build: function ($trigger, e) {
                        var page = scope.page;
                        var table = scope.table;
                        var row = scope.row;
                        var cell = scope.cell;
                        var element = scope.element;

                        return {
                            callback: contextMenuClickHandler,
                            items: datasheetContextMenuService.createContextMenuItemsForInputIcon(page, table, row, cell, element)
                        };
                    }
                });
            };
            //Inner Functions End

            //Handlers
            var contextMenuClickHandler = function (key, options) {
                datasheetContextMenuHandlerService
                    .handleContextMenuClick(key,
                    scope.selectionModel.showHiddenElements,
                    scope.templateModel,
                    scope.datasheetModel,
                    scope.page,
                    scope.table,
                    scope.row,
                    scope.cell,
                    scope.element);
            };
            //Handlers End

            //Events
            scope.$on('$destroy', function () {
                containerElement.remove();
                containerElement.unbind('click');
            });

            containerElement.click(function (e) {
                var modalInstance = $modal.open({
                    animation: false,
                    controller: 'datasheetInputIconPickerCtrl',
                    templateUrl: '/app/components/datasheet/shared/directives/elements/datasheetInputIcon/views/modals/datasheetInputIconPicker.html',
                    backdropClass: "defaultModal",
                    size: "lg",
                    resolve: {
                        element: function () {
                            return element;
                        }
                    }
                });

                modalInstance.result.then(function (response) {
                    if (response.status) {
                        element.value = response.selectedIcon;

                        init();
                        setElementValue();
                    }
                }).finally(function () {
                    modalInstance.$destroy();
                });
            });
            //Events End

            //Initials 
            addUsefulClass();
            setElementStyle();
            setElementValue();
            setContextMenu();
            //Initials End
        }
    };
};

module.exports = function (app) {
    app.directive('datasheetInputIcon', ['$modal', 'datasheetElementService', 'datasheetContextMenuService', 'datasheetContextMenuHandlerService', datasheetInputIcon]);
}