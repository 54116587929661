var uiRouter = function ($stateProvider, $urlRouterProvider, $locationProvider) {

    $stateProvider
        .state('app', {
            url: '/{lang:(?:en|tr|ru)}/app',
            templateUrl: "/app/components/core.layout/layouts/views/appLayout.html",
            abstract: true,
            onEnter: function () {
                $('body').css('overflow-y', 'auto');
            }
        })
        .state('auth', {
            url: '/{lang:(?:en|tr|ru)}/auth',
            abstract: true,
            templateUrl: "/app/components/core.layout/layouts/views/loginLayout.html",
            controller: "loginLayoutCtrl"
        })
        .state('pdf', {
            url: '/{lang:(?:en|tr|ru)}/pdf',
            abstract: true,
            templateUrl: "/app/components/core.layout/layouts/views/emptyLayout.html"
        })
        .state('blank', {
            url: '/{lang:(?:en|tr|ru)}/blank',
            abstract: true,
            templateUrl: "/app/components/core.layout/layouts/views/emptyLayout.html"
        })
        .state('errors', {
            url: '/{lang:(?:en|tr|ru)}/errors',
            abstract: true,
            templateUrl: "/app/components/core.layout/layouts/views/emptyLayout.html"
        })
        .state('editor', {
            url: '/{lang:(?:en|tr|ru)}/editor',
            abstract: true,
            templateUrl: "/app/components/core.layout/layouts/views/editorLayout.html"
        })
        .state('admin', {
            url: '/{lang:(?:en|tr|ru)}/admin',
            abstract: true,
            templateUrl: "/app/components/core.layout/layouts/views/appLayout.html"
        });

    //HTML 5 Modu aktif (diyez işaretinin görünmemesi için)
    // $locationProvider.html5Mode(true);
};

module.exports = function (app) {
    app.config(['$stateProvider', '$urlRouterProvider', '$locationProvider', uiRouter]);
};
