var quoteFactory = function(httpProvider) {
    //Fields
    var factoryModel = {};
    //Fields End

    //Functions
    factoryModel.getRandomOneQuote = function(language) {
        return httpProvider.get('quote', 'getRandomOneQuote', 'language=' + language);
    };
    //Functions End

    //Inner Functions
    //Inner Functions End

    return factoryModel;
};

module.exports = function(app) {
    app.factory('quoteFactory', ['httpProvider', quoteFactory]);
};
