/*
    Oluşturan           : Enis GÜLTEKİN - 05.03.2017
    Son Güncelleme      : Enis GÜLTEKİN - 10.03.2017
*/

var datasheetDataEditingService = function (generalsService, _, datasheetInputIconService) {
    //Fields
    var serviceModel = {};
    //Fields End

    //Functions
    serviceModel.updateDatasheetNames = function (datasheet, publishedTemplate) {
        for (var pt = 0; pt < publishedTemplate.languages.length; pt++) {
            var language = publishedTemplate.languages[pt];

            var datasheetName = _.find(datasheet.names, {
                language: language
            });

            if (datasheetName == null || datasheetName == undefined) {
                var primaryDatasheetName = _.find(datasheet.names, {
                    language: datasheet.primaryLanguage
                });

                var newDatasheetName = "";
                if (primaryDatasheetName == null || primaryDatasheetName == undefined) {
                    newDatasheetName = datasheet.names[0];
                }
                else {
                    newDatasheetName = primaryDatasheetName.name;
                }

                datasheet.names.push({
                    language: language,
                    name: newDatasheetName
                });
            }
        }
    };

    serviceModel.updateStaticDatasheetElements = function (datasheet, datasheetElements, publishedTemplate, publishedTemplateElements) {
        for (var pte = 0; pte < publishedTemplateElements.length; pte++) {
            var publishedTemplateElement = publishedTemplateElements[pte];

            switch (publishedTemplateElement.type) {
                case "page": {
                    updateStaticPageElement(datasheet, datasheetElements, publishedTemplate, publishedTemplateElement);
                }; break;
                case "table": {
                    updateStaticTableElement(datasheet, datasheetElements, publishedTemplate, publishedTemplateElement);
                }; break;
                case "row": {
                    updateStaticRowElement(datasheet, datasheetElements, publishedTemplate, publishedTemplateElement);
                }; break;
                case "text": {
                    updateStaticTextElement(datasheet, datasheetElements, publishedTemplate, publishedTemplateElement);
                }; break;
                case "image": {
                    updateStaticImageElement(datasheet, datasheetElements, publishedTemplate, publishedTemplateElement);
                }; break;
                case "icon": {
                    updateStaticIconElement(datasheet, datasheetElements, publishedTemplate, publishedTemplateElement);
                }; break;
                case "box": {
                    updateStaticBoxElement(datasheet, datasheetElements, publishedTemplate, publishedTemplateElement);
                }; break;
                case "inputtext": {
                    updateStaticInputTextElement(datasheet, datasheetElements, publishedTemplate, publishedTemplateElement);
                }; break;
                case "inputicon": {
                    updateStaticInputIconElement(datasheet, datasheetElements, publishedTemplate, publishedTemplateElement);
                }; break;
                case "inputimage": {
                    updateStaticInputImageElement(datasheet, datasheetElements, publishedTemplate, publishedTemplateElement);
                }; break;
                case "selectlist": {
                    updateStaticSelectListElement(datasheet, datasheetElements, publishedTemplate, publishedTemplateElement);
                }; break;
            }

        }
    };

    serviceModel.updateDynamicDatasheetElements = function (datasheet, datasheetElements, publishedTemplate, publishedTemplateElements) {
        var dynamicElements = _.filter(datasheetElements, {
            isDynamic: true
        });

        for (var de = 0; de < dynamicElements.length; de++) {
            var dynamicElement = dynamicElements[de];

            switch (dynamicElement.type) {
                case "row": {
                    updateDynamicRowElement(datasheet, datasheetElements, publishedTemplate, publishedTemplateElements, dynamicElement)
                }; break;
                case "cell": {
                    updateDynamicCellElement(datasheet, datasheetElements, publishedTemplate, publishedTemplateElements, dynamicElement);
                }; break;
                case "text": {
                    updateDynamicTextElement(datasheet, datasheetElements, publishedTemplate, publishedTemplateElements, dynamicElement);
                }; break;
                case "image": {
                    updateDynamicImageElement(datasheet, datasheetElements, publishedTemplate, publishedTemplateElements, dynamicElement);
                }; break;
                case "icon": {
                    updateDynamicIconElement(datasheet, datasheetElements, publishedTemplate, publishedTemplateElements, dynamicElement);
                }; break;
                case "box": {
                    updateDynamicBoxElement(datasheet, datasheetElements, publishedTemplate, publishedTemplateElements, dynamicElement);
                }; break;
                case "inputtext": {
                    updateDynamicInputTextElement(datasheet, datasheetElements, publishedTemplate, publishedTemplateElements, dynamicElement);
                }; break;
                case "inputicon": {
                    updateDynamicInputIconElement(datasheet, datasheetElements, publishedTemplate, publishedTemplateElements, dynamicElement);
                }; break;
                case "inputimage": {
                    updateDynamicInputImageElement(datasheet, datasheetElements, publishedTemplate, publishedTemplateElements, dynamicElement);
                }; break;
                case "selectlist": {
                    updateDynamicSelectListElement(datasheet, datasheetElements, publishedTemplate, publishedTemplateElements, dynamicElement);
                }; break;
            }
        };
    };

    /*
    Datasheet in doğası gereği 2 ayaklı bir yapı mevcut. Template (Şablon) tarafında hazırlanan elementler, ve Datasheet tarafında hazırlanan elementler şeklinde.
    Aşağıda yer alan kod bloğu 2 yapıyı merge ederek(birleştirerek) datasheet in kullanabileceği tek bir elements objesine çevirir.
    */
    serviceModel.mergeDatasheetAndTemplateElements = function (datasheetElements, templateElements) {
        var elements = [];

        for (var pte = 0; pte < templateElements.length; pte++) {
            var templateElement = templateElements[pte];
            var element = {};

            if (templateElement.type == "page" || templateElement.type == "row") {
                var templateElementId = templateElement._id;
                var datasheetElement = _.find(datasheetElements, {
                    templateElementId: templateElementId
                });

                angular.extend(element, datasheetElement, templateElement);
                element.datasheetElementId = datasheetElement._id;
            }
            else if (templateElement.type == "table" || templateElement.type == "text" || templateElement.type == "icon" || templateElement.type == "image" || templateElement.type == "box" || templateElement.type == "inputtext" || templateElement.type == "selectlist" || templateElement.type == "inputicon" || templateElement.type == "inputimage") {
                var elementName = templateElement.name;
                var datasheetElement = _.find(datasheetElements, {
                    name: elementName
                });

                angular.extend(element, datasheetElement, templateElement);
                element.datasheetElementId = datasheetElement._id;
            }
            else {
                angular.extend(element, templateElement);
            }

            elements.push(element);
        }

        var dynamicElements = _.filter(datasheetElements, {
            isDynamic: true
        });
        for (var de = 0; de < dynamicElements.length; de++) {
            var dynamicElement = dynamicElements[de];

            elements.push(dynamicElement);

        }

        return elements;
    }
    //Functions End

    //Inner Functions
    var updateStaticPageElement = function (datasheet, datasheetElements, publishedTemplate, publishedTemplateElement) {
        var templateElementId = publishedTemplateElement._id;
        var datasheetElement = _.find(datasheetElements, {
            templateElementId: templateElementId
        });

        if (datasheetElement == null || datasheetElement == undefined) {
            datasheetElement = {};
            datasheetElement._id = generalsService.createObjectId();
            datasheetElement.datasheetId = datasheet._id;
            datasheetElement.templateElementId = templateElementId;
            datasheetElement.type = publishedTemplateElement.type;
            datasheetElement.isHidden = false;

            datasheetElements.push(datasheetElement);
        }

        if (datasheetElement.isHidden == null || datasheetElement.isHidden == undefined) {
            datasheetElement.isHidden = false;
        }
    };

    var updateStaticTableElement = function (datasheet, datasheetElements, publishedTemplate, publishedTemplateElement) {
        var name = publishedTemplateElement.name;
        var datasheetElement = _.find(datasheetElements, {
            name: name
        });

        if (datasheetElement == null || datasheetElement == undefined) {
            datasheetElement = {};
            datasheetElement._id = generalsService.createObjectId();
            datasheetElement.datasheetId = datasheet._id;
            datasheetElement.name = publishedTemplateElement.name;
            datasheetElement.type = publishedTemplateElement.type;
            datasheetElement.isHidden = false;

            datasheetElements.push(datasheetElement);
        }

        if (datasheetElement.isHidden == null || datasheetElement.isHidden == undefined) {
            datasheetElement.isHidden = false;
        }
    };

    var updateStaticRowElement = function (datasheet, datasheetElements, publishedTemplate, publishedTemplateElement) {
        var templateElementId = publishedTemplateElement._id;
        var datasheetElement = _.find(datasheetElements, {
            templateElementId: templateElementId
        });

        if (datasheetElement == null || datasheetElement == undefined) {
            datasheetElement = {};
            datasheetElement._id = generalsService.createObjectId();
            datasheetElement.datasheetId = datasheet._id;
            datasheetElement.templateElementId = templateElementId;
            datasheetElement.type = publishedTemplateElement.type;
            datasheetElement.isHidden = false;

            datasheetElements.push(datasheetElement);
        }

        if (datasheetElement.isHidden == null || datasheetElement.isHidden == undefined) {
            datasheetElement.isHidden = false;
        }
    };

    var updateStaticTextElement = function (datasheet, datasheetElements, publishedTemplate, publishedTemplateElement) {
        var name = publishedTemplateElement.name;
        var datasheetElement = _.find(datasheetElements, {
            name: name
        });

        if (datasheetElement == null || datasheetElement == undefined) {
            datasheetElement = {};
            datasheetElement._id = generalsService.createObjectId();
            datasheetElement.datasheetId = datasheet._id;
            datasheetElement.name = publishedTemplateElement.name;
            datasheetElement.type = publishedTemplateElement.type;
            datasheetElement.isHidden = false;

            datasheetElements.push(datasheetElement);
        }

        if (datasheetElement.isHidden == null || datasheetElement.isHidden == undefined) {
            datasheetElement.isHidden = false;
        }
    };

    var updateStaticIconElement = function (datasheet, datasheetElements, publishedTemplate, publishedTemplateElement) {
        var name = publishedTemplateElement.name;
        var datasheetElement = _.find(datasheetElements, {
            name: name
        });

        if (datasheetElement == null || datasheetElement == undefined) {
            datasheetElement = {};
            datasheetElement._id = generalsService.createObjectId();
            datasheetElement.datasheetId = datasheet._id;
            datasheetElement.name = publishedTemplateElement.name;
            datasheetElement.type = publishedTemplateElement.type;
            datasheetElement.isHidden = false;

            datasheetElements.push(datasheetElement);
        }

        if (datasheetElement.isHidden == null || datasheetElement.isHidden == undefined) {
            datasheetElement.isHidden = false;
        }
    };

    var updateStaticImageElement = function (datasheet, datasheetElements, publishedTemplate, publishedTemplateElement) {
        var name = publishedTemplateElement.name;
        var datasheetElement = _.find(datasheetElements, {
            name: name
        });

        if (datasheetElement == null || datasheetElement == undefined) {
            datasheetElement = {};
            datasheetElement._id = generalsService.createObjectId();
            datasheetElement.datasheetId = datasheet._id;
            datasheetElement.name = publishedTemplateElement.name;
            datasheetElement.type = publishedTemplateElement.type;
            datasheetElement.isHidden = false;

            datasheetElements.push(datasheetElement);
        }

        if (datasheetElement.isHidden == null || datasheetElement.isHidden == undefined) {
            datasheetElement.isHidden = false;
        }
    };

    var updateStaticBoxElement = function (datasheet, datasheetElements, publishedTemplate, publishedTemplateElement) {
        var name = publishedTemplateElement.name;
        var datasheetElement = _.find(datasheetElements, {
            name: name
        });

        if (datasheetElement == null || datasheetElement == undefined) {
            datasheetElement = {};
            datasheetElement._id = generalsService.createObjectId();
            datasheetElement.datasheetId = datasheet._id;
            datasheetElement.name = publishedTemplateElement.name;
            datasheetElement.type = publishedTemplateElement.type;
            datasheetElement.isHidden = false;

            datasheetElements.push(datasheetElement);
        }

        if (datasheetElement.isHidden == null || datasheetElement.isHidden == undefined) {
            datasheetElement.isHidden = false;
        }
    };

    var updateStaticInputTextElement = function (datasheet, datasheetElements, publishedTemplate, publishedTemplateElement) {
        var name = publishedTemplateElement.name;

        var datasheetElement = _.find(datasheetElements, {
            name: name
        });
        if (datasheetElement == null || datasheetElement == undefined) {
            datasheetElement = {};
            datasheetElement._id = generalsService.createObjectId();
            datasheetElement.datasheetId = datasheet._id;
            datasheetElement.name = publishedTemplateElement.name;
            datasheetElement.type = publishedTemplateElement.type;
            datasheetElement.isHidden = false;

            datasheetElements.push(datasheetElement);
        }

        if (datasheetElement.isHidden == null || datasheetElement.isHidden == undefined) {
            datasheetElement.isHidden = false;
        }

        //InputText in element values kısmını oluştur.
        if (datasheetElement.values == null || datasheetElement.values == undefined) {
            datasheetElement.values = {};
        }

        for (var ptl = 0; ptl < publishedTemplate.languages.length; ptl++) {
            var language = publishedTemplate.languages[ptl];

            if (datasheetElement.values[language] == null || datasheetElement.values[language] == undefined)
                datasheetElement.values[language] = "";
        };

        delete publishedTemplateElement.values;
    };

    var updateStaticInputIconElement = function (datasheet, datasheetElements, publishedTemplate, publishedTemplateElement) {
        var name = publishedTemplateElement.name;

        var datasheetElement = _.find(datasheetElements, {
            name: name
        });
        if (datasheetElement == null || datasheetElement == undefined) {
            datasheetElement = {};
            datasheetElement._id = generalsService.createObjectId();
            datasheetElement.datasheetId = datasheet._id;
            datasheetElement.name = publishedTemplateElement.name;
            datasheetElement.type = publishedTemplateElement.type;
            datasheetElement.isHidden = false;

            datasheetElements.push(datasheetElement);
        }

        if (datasheetElement.isHidden == null || datasheetElement.isHidden == undefined) {
            datasheetElement.isHidden = false;
        }

        //Elementin Value Kısmını Oluştur.
        datasheetInputIconService.editValue(datasheetElement, publishedTemplateElement.source);
        delete publishedTemplateElement.value;
    };

    var updateStaticInputImageElement = function (datasheet, datasheetElements, publishedTemplate, publishedTemplateElement) {
        var name = publishedTemplateElement.name;

        var datasheetElement = _.find(datasheetElements, {
            name: name
        });
        if (datasheetElement == null || datasheetElement == undefined) {
            datasheetElement = {};
            datasheetElement._id = generalsService.createObjectId();
            datasheetElement.datasheetId = datasheet._id;
            datasheetElement.name = publishedTemplateElement.name;
            datasheetElement.type = publishedTemplateElement.type;
            datasheetElement.size = {
                width: '100%',
                height: '100%'
            };
            datasheetElement.isHidden = false;

            datasheetElements.push(datasheetElement);
        }

        if (datasheetElement.isHidden == null || datasheetElement.isHidden == undefined) {
            datasheetElement.isHidden = false;
        }

        if (datasheetElement.size == null || datasheetElement.size == undefined) {
            datasheetElement.size = {
                width: '100%',
                height: '100%'
            };
        }

        //Elementin Value Kısmını Oluştur.
        if (datasheetElement.value == null || datasheetElement.value == undefined || datasheetElement.value == '') {
            datasheetElement.value = '';
        }

        //TODO : İçeri yüklenen image holder boyutları dısında ise ayarla.
    };

    var updateStaticSelectListElement = function (datasheet, datasheetElements, publishedTemplate, publishedTemplateElement) {
        var name = publishedTemplateElement.name;
        var datasheetElement = _.find(datasheetElements, {
            name: name
        });

        //Daha önce datasheetElement oluşturulmamışsa oluştur.
        if (datasheetElement == null || datasheetElement == undefined) {
            datasheetElement = {};
            datasheetElement._id = generalsService.createObjectId();
            datasheetElement.datasheetId = datasheet._id;
            datasheetElement.name = publishedTemplateElement.name;
            datasheetElement.type = publishedTemplateElement.type;
            datasheetElement.isHidden = false;

            datasheetElements.push(datasheetElement);
        }

        if (datasheetElement.isHidden == null || datasheetElement.isHidden == undefined) {
            datasheetElement.isHidden = false;
        }

        //Element Values
        if (datasheetElement.values == null || datasheetElement.values == undefined) {
            datasheetElement.values = {};
        }

        for (var ptl = 0; ptl < publishedTemplate.languages.length; ptl++) {
            var language = publishedTemplate.languages[ptl];
            var publishedTemplateElementSource = publishedTemplateElement.source[language];

            //Template Element de source (item) eklendiyse bu işlemleri yapalım....
            if (publishedTemplateElementSource.length > 0) {
                if (datasheetElement.values[language] == null || datasheetElement.values[language] == undefined || datasheetElement.values[language] == "") {
                    //values boşsa value kısmında değer var mı?
                    if (datasheetElement.value && datasheetElement.value[language]) {
                        var selectedValueId = datasheetElement.value[language];
                        var selectedValue = _.find(publishedTemplateElementSource, {
                            id: selectedValueId
                        });

                        if (selectedValue) {
                            datasheetElement.values[language] = selectedValue;
                        }
                        else {
                            setStaticSelectListValueFromOtherChoice(datasheetElement, publishedTemplate, publishedTemplateElement, publishedTemplateElementSource, language);
                        }
                    }
                    else {
                        setStaticSelectListValueFromOtherChoice(datasheetElement, publishedTemplate, publishedTemplateElement, publishedTemplateElementSource, language);
                    }
                }
                else {
                    var selectedValueId = datasheetElement.values[language].id;
                    var selectedValue = _.find(publishedTemplateElementSource, {
                        id: selectedValueId
                    });

                    if (selectedValue) {
                        datasheetElement.values[language] = selectedValue;
                    }
                    else {
                        setStaticSelectListValueFromOtherChoice(datasheetElement, publishedTemplate, publishedTemplateElement, publishedTemplateElementSource, language);
                    }
                }
            }
            //Template Element de source(item) eklenmediyse...
            else {
                datasheetElement.values[language] = { id: "", value: "" };
            }
        }

        delete publishedTemplateElement.values;
    };

    var getStaticSelectListOtherChoice = function (datasheetElement, publishedTemplate, publishedTemplateElement) {
        var otherChoice = null;
        for (var ptl = 0; ptl < publishedTemplate.languages.length; ptl++) {

            var language = publishedTemplate.languages[ptl];
            var pteSource = publishedTemplateElement.source[language];

            var choice = datasheetElement.values[language];
            if (choice != null && choice != undefined) {
                var selectedValue = _.find(pteSource, {
                    id: choice.id
                });

                if (selectedValue) {
                    otherChoice = choice;
                    break;
                }
            }
        }
        return otherChoice;
    };

    var setStaticSelectListValueFromOtherChoice = function (datasheetElement, publishedTemplate, publishedTemplateElement, publishedTemplateElementSource, language) {
        var otherChoice = getStaticSelectListOtherChoice(datasheetElement, publishedTemplate, publishedTemplateElement);
        //Bu selectlist in diğer dillerde herhangi bir seçeneği yok.
        if (otherChoice == null) {
            datasheetElement.values[language] = publishedTemplateElementSource[0];
        }
        else {
            var otherChoiceId = otherChoice.id;
            var selectedValue = _.find(publishedTemplateElementSource, {
                id: otherChoiceId
            });

            datasheetElement.values[language] = selectedValue;
        }
    };

    var updateDynamicRowElement = function (datasheet, datasheetElements, publishedTemplate, publishedTemplateElements, dynamicElement) {
        if (!dynamicElement.parentOrder) {
            dynamicElement.parentOrder = parseInt(dynamicElement.order.toString().split(".")[0]);
            dynamicElement.order = parseInt(dynamicElement.order.toString().split(".")[1].substr(0, 1));
            dynamicElement.direction = "down";
        }


        var referansElement = _.find(publishedTemplateElements, {
            _id: dynamicElement.referansElement
        });

        if (referansElement) {
            dynamicElement.style = referansElement.style;
        }
        else {
            var rows = _.filter(publishedTemplateElements, {
                parentId: dynamicElement.parentId,
                type: "row"
            });

            var nearestRow = findNearestRow(rows, dynamicElement.parentOrder);
            if (nearestRow) {
                dynamicElement.referansElement = nearestRow._id

                dynamicElement.style = nearestRow.style;
                dynamicElement.parentOrder = nearestRow.order;
            }
            else {
                dynamicElement.referansElement = undefined;
            }
        }
    };

    var findNearestRow = function (rows, parentOrder) {
        var copiedRows = [];
        angular.copy(rows, copiedRows);
        var sortedRows = _.sortBy(copiedRows, 'order');
        for (var sr = 0; sr < sortedRows.length; sr++) {
            var sortedRow = sortedRows[sr];

            var actualDistance = (sortedRow.order - parentOrder);
            if (actualDistance < 0) {
                sortedRow.positiveDistance = actualDistance * (-1)
            }
            else {
                sortedRow.positiveDistance = actualDistance;
            }
        }

        sortedRows = _.sortBy(sortedRows, 'positiveDistance');
        if (sortedRows.length > 0) {
            var foundRow = _.find(rows, {
                _id: sortedRows[0]._id
            });

            return foundRow;
        }
        else {
            return null;
        }
    };

    var updateDynamicCellElement = function (datasheet, datasheetElements, publishedTemplate, publishedTemplateElements, dynamicElement) {
        var referansElement = _.find(publishedTemplateElements, {
            _id: dynamicElement.referansElement
        });

        if (referansElement) {
            dynamicElement.style = referansElement.style;
        }
        else {
            var dynamicParentRow = _.find(datasheetElements, {
                _id: dynamicElement.parentId
            });
            var rowReferansElement = _.find(publishedTemplateElements, {
                _id: dynamicParentRow.referansElement
            });

            if (rowReferansElement) {
                var newReferansElement = _.find(publishedTemplateElements, {
                    parentId: rowReferansElement._id,
                    type: "cell",
                    order: dynamicElement.order
                });

                if (newReferansElement) {
                    dynamicElement.referansElement = newReferansElement._id;
                    dynamicElement.style = newReferansElement.style;
                    dynamicElement.containerStyle = newReferansElement.containerStyle;
                }
                else {
                    newReferansElement.referansElement = undefined;
                }
            }
        }
    };

    var updateDynamicTextElement = function (datasheet, datasheetElements, publishedTemplate, publishedTemplateElements, dynamicElement) {
        var referansElement = _.find(publishedTemplateElements, {
            _id: dynamicElement.referansElement
        });

        if (referansElement) {
            dynamicElement.style = referansElement.style;
            dynamicElement.values = referansElement.values;
        }
        else {
            //TODO: Silip dil arttırırsa values kısmını dile göre düzenle.
        }
    };

    var updateDynamicIconElement = function (datasheet, datasheetElements, publishedTemplate, publishedTemplateElements, dynamicElement) {
        var referansElement = _.find(publishedTemplateElements, {
            _id: dynamicElement.referansElement
        });

        if (referansElement) {
            dynamicElement.style = referansElement.style;
            dynamicElement.value = referansElement.value;
        }
    };

    var updateDynamicImageElement = function (datasheet, datasheetElements, publishedTemplate, publishedTemplateElements, dynamicElement) {
        var referansElement = _.find(publishedTemplateElements, {
            _id: dynamicElement.referansElement
        });

        if (referansElement) {
            dynamicElement.style = referansElement.style;
            dynamicElement.value = referansElement.value;
        }
    };

    var updateDynamicBoxElement = function (datasheet, datasheetElements, publishedTemplate, publishedTemplateElements, dynamicElement) {
        var referansElement = _.find(publishedTemplateElements, {
            _id: dynamicElement.referansElement
        });

        if (referansElement) {
            dynamicElement.style = referansElement.style;
        }
    };

    var updateDynamicInputTextElement = function (datasheet, datasheetElements, publishedTemplate, publishedTemplateElements, dynamicElement) {
        var referansElement = _.find(publishedTemplateElements, {
            _id: dynamicElement.referansElement
        });

        if (referansElement) {
            dynamicElement.style = referansElement.style;
            dynamicElement.containerStyle = referansElement.containerStyle;
        }
        else {
            referansElement = _.find(publishedTemplateElements, {
                name: dynamicElement.referansElementName
            });

            if (referansElement) {
                dynamicElement.style = referansElement.style;
                dynamicElement.containerStyle = referansElement.containerStyle;
            }
            else {
                dynamicElement.referansElement = null;
                dynamicElement.referansElementName = null;
            }
        }
    };

    var updateDynamicInputIconElement = function (datasheet, datasheetElements, publishedTemplate, publishedTemplateElements, dynamicElement) {
        var referansElement = _.find(publishedTemplateElements, {
            _id: dynamicElement.referansElement
        });

        if (referansElement) {
            dynamicElement.style = referansElement.style;
            dynamicElement.containerStyle = referansElement.containerStyle;

            dynamicElement.source = referansElement.source;
        }
        else {
            referansElement = _.find(publishedTemplateElements, {
                name: dynamicElement.referansElementName
            });

            if (referansElement) {
                dynamicElement.style = referansElement.style;
                dynamicElement.containerStyle = referansElement.containerStyle;
                dynamicElement.source = referansElement.source;
            }
        }

        datasheetInputIconService.editValue(dynamicElement, dynamicElement.source);
    };

    var updateDynamicInputImageElement = function (datasheet, datasheetElements, publishedTemplate, publishedTemplateElements, dynamicElement) {
        var referansElement = _.find(publishedTemplateElements, {
            _id: dynamicElement.referansElement
        });

        if (referansElement) {
            dynamicElement.style = referansElement.style;
        }
    };

    var updateDynamicSelectListElement = function (datasheet, datasheetElements, publishedTemplate, publishedTemplateElements, dynamicElement) {
        var referansElement = _.find(publishedTemplateElements, {
            _id: dynamicElement.referansElement
        });

        if (referansElement) {
            dynamicElement.source = referansElement.source;
            dynamicElement.style = referansElement.style;
            dynamicElement.containerStyle = referansElement.containerStyle;
        }
        else {
            referansElement = _.find(publishedTemplateElements, {
                name: dynamicElement.referansElementName
            });

            if (referansElement) {
                dynamicElement.source = referansElement.source;
                dynamicElement.style = referansElement.style;
                dynamicElement.containerStyle = referansElement.containerStyle;
            }
            else {
                dynamicElement.referansElement = null;
                dynamicElement.referansElementName = null;
            }
        }
    };
    //Inner Functions End

    return serviceModel;
};

module.exports = function (app) {
    app.service('datasheetDataEditingService', ['generalsService', '_', 'datasheetInputIconService', datasheetDataEditingService]);
};