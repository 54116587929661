var intercomFactory = function ($q, localStorageService, intercomApiProvider) {
    //Fields 
    var factoryModel = {};
    var eventPoll = [];
    //Fields End

    //Functions
    factoryModel.updateUser = function (userModel) {
        return intercomApiProvider.post('users', userModel);
    };

    factoryModel.updateCompany = function (companyModel) {
        return intercomApiProvider.post('companies', companyModel);
    };

    factoryModel.addEvent = function (eventModel, userId) {
        var userInfo;
        if (userId == null || userId == undefined) {
            userInfo = localStorageService.get('userInformationData');
        }

        if (userInfo != null && userInfo != undefined) {
            userId = userInfo._id;
        }

        if (userId != undefined && userId != null) {
            eventModel.user_id = userId;
            eventModel.created_at = Math.floor(Date.now() / 1000);

            return intercomApiProvider.post('events', eventModel);
        }
        else {
            var deferred = $q.defer();
            setTimeout(function () {
                deferred.resolve({
                    status: false
                });
            }, 250);
            return deferred.promise;
        }
    };

    factoryModel.addEventToPoll = function (eventModel, userId) {
        var deferred = $q.defer();

        setTimeout(function () {
            eventModel.user_id = userId;
            eventModel.created_at = Math.floor(Date.now() / 1000);

            eventPoll.push(eventModel);
            deferred.resolve({
                status: true
            });
        }, 500);

        return deferred.promise;

    };

    factoryModel.flushEvents = function () {
        var deferred = $q.defer();
        var promises = [];

        for (var epi = 0; epi < eventPoll.length; epi++) {
            var event = eventPoll[epi];

            var promise = intercomApiProvider.post('events', event);
            promises.push(promise);
        }

        $q.all(promises)
            .then(function (response) {
                eventPoll = [];

                deferred.resolve({
                    status: true
                });
            });



        return deferred.promise;
    };
    //Functions End

    //Inner Functions
    //Inner Functions End

    return factoryModel;
};

module.exports = function (app) {
    app.factory('intercomFactory', ['$q', 'localStorageService', 'intercomApiProvider', intercomFactory]);
}