var datasheetDoubleLayout = function () {
    return {
        restrict: 'E',
        replace: true,
        templateUrl: 'app/components/datasheet/shared/directives/datasheetLayouts/tpl/datasheetDoubleLayout.html',
        link: function (scope, elem, attrs) {

        }
    }
};

module.exports = function (app) {
    app.directive('datasheetDoubleLayout', [datasheetDoubleLayout]);
};