var searchCtrl = function ($scope, $rootScope, searchFactory, $filter, $state, $modal) {
  //Fields
  $rootScope.searchModel.headerSearch = false;
  $scope.isSearching = false;
  //Fields-End

  //Private Functions
  var init = function () {
    if (
      angular.isUndefined($rootScope.searchModel.keys) ||
      $rootScope.searchModel.keys.length === 0
    ) {
      $state.go("app.dashboard", {
        lang: $rootScope.activeLang,
      });
    }
  };
  init();
  var search = function () {
    if (
      !angular.isUndefined($rootScope.searchModel) &&
      !angular.isUndefined($rootScope.searchModel.keys) &&
      $rootScope.searchModel.keys.length > 0
    ) {
      $scope.hasNoResult = null;
      $scope.isSearching = true;
      $scope.titleTranslationData = {
        searchKey: $rootScope.searchModel.keys,
      };
      $scope.searchResults = "";

      searchFactory
        .search($rootScope.searchModel.keys)
        .then(function (response) {
          if (response.length !== 0) {
            $scope.searchResults = response;
            $scope.hasNoResult = false;
          } else {
            $scope.hasNoResult = true;
          }
          $scope.isSearching = false;
          $rootScope.searchModel.keys = "";
        });
    }
  };
  //Private Funcstion - End

  //Initials
  search();
  //Initials-End

  //Functions
  $scope.getMaxMajorItem = function (items) {
    var response = $filter("orderBy")(items, "-majorVersion");
    return response[0];
  };

  $scope.searchItems = function () {
    searchFactory.search($scope.searchKeys).then(function (response) {
      $scope.searchResults = response[0];
      $scope.searchKey = $scope.searchKeys;
      $scope.searchKeys = "";
    });
  };

  $scope.editTemplate = function (id) {
    // if(angular.isUndefined($rootScope.activeLang))
    //   $rootScope.activeLang = "en";

    $state.go("app.editTemplate", {
      lang: $rootScope.activeLang,
      id: id,
    });
  };

  $scope.editForm = function (datasheetId) {
    // if(angular.isUndefined($rootScope.activeLang))
    //   $rootScope.activeLang = "en";

    $state.go("editor.editDatasheet", {
      lang: $rootScope.activeLang,
      id: datasheetId,
    });
  };

  $scope.viewPdf = function (currentForm, version) {
    if (
      !angular.isUndefined(currentForm) &&
      angular.equals(currentForm, {}) === false
    ) {
      var modalInstance = $modal.open({
        animation: false,
        controller: "viewDatasheetPdfCtrl",
        templateUrl:
          "/app/components/datasheet/yourDatasheets/views/modals/viewDatasheetPdf.html",
        resolve: {
          datasheet: function () {
            return {
              _id: currentForm.datasheetId,
              majorVersion: currentForm.majorVersion,
            };
          },
          version: function () {
            return currentForm.majorVersion;
          },
        },
      });
    }
  };
  //Functions-End

  //Events
  $scope.$on("search", function (e) {
    search();
  });
  //Events - End
};

module.exports = function (app) {
  app.controller("searchCtrl", [
    "$scope",
    "$rootScope",
    "searchFactory",
    "$filter",
    "$state",
    "$modal",
    searchCtrl,
  ]);
};
