var authApiProvider = function ($http, $q, config, environment) {

    var authApiUrl = environment.AUTH_API_URL || config.auth_api[config.mode].url;

    this.post = function (route, params) {
        var deferred = $q.defer();
        var url = authApiUrl + route;

        var res = $http.post(url, params);

        res.success(function (data, status, headers, config) {
            deferred.resolve(data);
        });

        res.error(function (msg, code) {
            deferred.reject(msg);
        });

        return deferred.promise;
    };
};

module.exports = function (app) {
    app.service('authApiProvider', ['$http', '$q', 'config', 'environment', authApiProvider]);
};
