var datasheetSelectList = function ($modal, datasheetElementService, datasheetContextMenuService, datasheetContextMenuHandlerService) {
    return {
        restrict: 'E',
        replace: true,
        templateUrl: 'app/components/datasheet/shared/directives/elements/datasheetSelectList/views/datasheetSelectList.html',
        link: function (scope, elem, attrs) {
            //Fields 
            var containerElement = elem;
            var innerElement = containerElement.find(".inner-element");
            var selectElement = elem.find("select");
            //Fields End

            //Elements
            var element = scope.element;
            //Elements End

            //Inner Functions
            var init = function () {
                containerElement = $('#' + scope.element.type + '-' + scope.element._id);
                innerElement = containerElement.find(".inner-element");
                selectElement = innerElement.find("select");
            };

            var addUsefulClass = function () {
                containerElement.addClass("container-element changeable-content selectlist-" + element._id + "");
            };

            var setElementStyle = function () {
                containerElement.css(element.containerStyle);
                innerElement.css(element.style);

                //Elementler gizli ise ilgili class ı ekleyelim.
                if (element != null && element != undefined) {
                    if (element.isHidden) {
                        containerElement.addClass('hidden-blur');
                    }
                }
            };

            var convertToDiv = function () {
                //Inner Element Div içindeki tüm html datayı temizle,
                innerElement.html('');
                selectElement = $('<select style="display:none;"></select>');
                innerElement.append(selectElement);

                var source = element.source[scope.selectionModel.selectedLanguage];
                if (source.length == 0)
                    return false;


                var numberOfOptions = source.length;
                selectElement.wrap('<div class="datasheet-select-list-container"></div>');
                selectElement.after('<div class="datasheet-styled-select-list"></div>');

                var $styledSelect = selectElement.next('div.datasheet-styled-select-list');
                var $list = $('<ul />', {
                    'class': 'datasheet-styled-options'
                }).insertAfter($styledSelect);

                for (var i = 0; i < numberOfOptions; i++) {
                    $('<li />', {
                        html: source[i].value,
                        rel: source[i].id
                    }).appendTo($list);
                }

                var $listItems = $list.children('li');

                $styledSelect.click(function (e) {
                    e.stopPropagation();
                    $('div.datasheet-styled-select-list.active').each(function () {
                        $(this).removeClass('active').next('ul.datasheet-styled-options').hide();
                    });
                    $(this).toggleClass('active').next('ul.datasheet-styled-options').toggle();

                    //Seçili element e scrollla,
                    if ($list.find(".active").length > 0) {
                        var scrollTop = $list.find(".active").offset().top - $list.offset().top + $list.scrollTop()
                        $list.scrollTop(scrollTop);
                    }
                });

                $list.bind('mousewheel', function (e) {
                    //Sayfada scroll yapmasın,
                    event.stopPropagation();
                });

                $listItems.click(function (e) {
                    e.stopPropagation();
                    $styledSelect.html($(this).html()).removeClass('active');
                    for (var i = 0; i < scope.templateModel.template.languages.length; i++) {
                        var language = scope.templateModel.template.languages[i];

                        var selectedItemId = $(this).attr('rel');
                        var selectedItem = _.find(element.source[language], {
                            id: selectedItemId
                        });

                        element.values[language] = selectedItem
                    };

                    setElementValue();
                    $list.hide();
                });

                $listItems.bind('mousedown', function (e) {
                    if (e.which == 3) {
                        $styledSelect.removeClass('active');
                        $list.hide();
                    }
                });

                $styledSelect.bind('mousedown', function (e) {
                    if (e.which == 3) {
                        $styledSelect.removeClass('active');
                        $list.hide();
                    }
                });

                $(document).click(function () {
                    $styledSelect.removeClass('active');
                    $list.hide();
                });
            };

            var setElementValue = function () {
                var source = element.source[scope.selectionModel.selectedLanguage];
                if (source.length == 0)
                    return false;

                var selectedItem = _.find(source, {
                    id: element.values[scope.selectionModel.selectedLanguage].id
                });
                if (selectedItem == null || selectedItem == undefined) {
                    selectedItem = source[0];
                    element.values[scope.selectionModel.selectedLanguage] = selectedItem
                }

                var $styledSelect = innerElement.find("select").next('div.datasheet-styled-select-list');
                var $list = $styledSelect.next('ul.datasheet-styled-options');

                $styledSelect.html(selectedItem.value);

                $list.find('.active').removeClass("active");
                $list.find('.fa-check').remove();

                var $selectedListItem = $list.find('li[rel="' + selectedItem.id + '"]');
                $selectedListItem.addClass('active').prepend('<i class="fa fa-check" aria-hidden="true"></i> ');

            };

            var setContextMenu = function () {
                $.contextMenu({
                    selector: ".selectlist-" + element._id,
                    build: function ($trigger, e) {
                        var page = scope.page;
                        var table = scope.table;
                        var row = scope.row;
                        var cell = scope.cell;
                        var element = scope.element;

                        return {
                            callback: contextMenuClickHandler,
                            items: datasheetContextMenuService.createContextMenuItemsForSelectList(page, table, row, cell, element)
                        };
                    }
                });
            };
            //Inner Functions End

            //Handlers
            var contextMenuClickHandler = function (key, options) {
                if (key == "addItemToElement") {
                    var modalInstance = $modal.open({
                        animation: false,
                        controller: 'datasheetSelectListAddItemCtrl',
                        templateUrl: '/app/components/datasheet/shared/directives/elements/datasheetSelectList/views/modals/datasheetSelectListAddItem.html',
                        backdropClass: "defaultModal",
                        size: "lg",
                        resolve: {
                            element: function () {
                                return element;
                            }
                        }
                    });

                    modalInstance.result.then(function (response) {
                        if (response.status) {
                            var newItems = response.newItems;

                            var templateLanguages = scope.templateModel.template.languages;
                            for (var i = 0, len = templateLanguages.length; i < len; i++) {
                                var templateLanguage = templateLanguages[i];

                                var newItem = _.find(newItems, {
                                    language: templateLanguage
                                });

                                element.source[templateLanguage].push({
                                    id: newItem.id,
                                    value: newItem.value
                                });
                                element.values[templateLanguage] = newItem;
                            }

                            init();
                            convertToDiv();
                            setElementValue();
                        }
                    });
                } else {
                    datasheetContextMenuHandlerService
                        .handleContextMenuClick(
                        key,
                        scope.selectionModel.showHiddenElements,
                        scope.templateModel,
                        scope.datasheetModel,
                        scope.page,
                        scope.table,
                        scope.row,
                        scope.cell,
                        scope.element);
                }
            };
            //Handlers End

            //Events
            containerElement.on('datasheetLanguageChanged', function (e, data) {
                _.defer(function (text) {
                    convertToDiv();
                    setElementValue();
                });
            });
            //Events End

            //Initials 
            addUsefulClass();
            setElementStyle();
            convertToDiv();
            setElementValue();
            setContextMenu();
            //Initials End
        }
    }
};

module.exports = function (app) {
    app.directive('datasheetSelectList', ['$modal', 'datasheetElementService', 'datasheetContextMenuService', 'datasheetContextMenuHandlerService', datasheetSelectList]);
};