var datasheetTableBody = function ($compile, datasheetViewService, _) {
    return {
        restrict: 'A',
        link: function (scope, elem, attrs) {
            //Fields 
            var childScopes = [];
            //Fields End

            //Elements
            //Elements End

            //Inner Functions
            var init = function () {

            };

            var destroyChildScopes = function () {
                //Tüm Alt Scope ları destroy et.
                if (childScopes.length > 0) {
                    for (var cs = 0, len = childScopes.length; cs < len; cs++) {
                        var childScope = childScopes[cs];

                        childScope.$destroy();
                    }
                }
            }

            var setRows = function () {
                var table = scope.element;
                var tableId = scope.element._id;
                var rows = _.filter(scope.datasheetModel.elements, {
                    parentId: tableId,
                    type: "row"
                });
                rows = _.sortBy(rows, 'order');


                for (var i = 0, len = rows.length; i < len; i++) {
                    var row = rows[i];
                    var rowHtml = datasheetViewService.createDatasheetElement(row.type);
                    rowHtml = $(rowHtml).attr("id", row.type + "-" + row._id);
                    rowHtml = $(rowHtml).addClass(row.type + "-" + row._id);

                    var childScope = scope.$new();
                    childScope.table = table;
                    childScope.row = row;

                    childScopes.push(childScope);
                    $(elem).append($compile(rowHtml)(childScope));
                }
            };
            //Inner Functions End

            //Events
            elem.on('reRenderTable', function (e, data) {
                $(this).find('.fr-basic').each(function () {
                    var editor = $(this).data('froala.editor');
                    editor.destroy();
                });
                destroyChildScopes();
                $(this).empty();
                setRows();
            });
            //Events End

            //Initials 
            setRows();
            //Initials End
        }
    }
};

module.exports = function (app) {
    app.directive('datasheetTableBody', ['$compile', 'datasheetViewService', '_', datasheetTableBody]);
};